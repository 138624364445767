import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  WithSubscriptionComponent,
  FlightJourney,
  FlightCabinClass,
  FlightItinerary
} from '@sabstravtech/obtservices/angular';
import {
  FlightTempParams,
  FlightSearchType,
  FlightEnterpriseSearchInterface,
  ServiceProvider
} from '@sabstravtech/obtservices/base';

enum TabType {
  FareDetails,
  FlightLegTimes
}
@Component({
  selector: 'app-flight-journey-detail',
  templateUrl: './flight-journey-detail.html',
  styleUrls: ['./flight-journey-detail.scss']
})
export class FlightJourneyDetail extends WithSubscriptionComponent implements OnInit {
    @Input() isDualFlight: boolean;
    @Input() flightDetail: FlightItinerary = null;
    @Input() fares: FlightItinerary[];
    @Input() search: FlightTempParams;
    @Input() searchParams: FlightEnterpriseSearchInterface;
    @Input() flightIndex: number;
    @Input() selectedItemsId: number[] = [];
    @Input() disableItems: boolean = false;
    @Output() selectedMultiCityFlight: EventEmitter<FlightItinerary> = new EventEmitter();
    @Output() deselectMultiCityFlight: EventEmitter<FlightItinerary> = new EventEmitter();
    @Input() isExchange = false;
    @Input() isPricingExchange = false;
    @Output() addToBasketEvent: EventEmitter<FlightItinerary> = new EventEmitter();
    debug = false;
    isTravelfusion = false;
    selectedTab = TabType.FareDetails;
    TabType: typeof TabType = TabType;
    legs: FlightJourney[] = null;
    FlightSearchType: typeof FlightSearchType = FlightSearchType;
    constructor() {
        super();
    }

  ngOnInit() {
    if (this.flightDetail.source === ServiceProvider.Travelfusion) {
      this.isTravelfusion = true;
    }

    this.debug = (window as any).showDebug;
  }

  addToBasket(flight: FlightItinerary) {
    this.addToBasketEvent.emit(flight);
  }
}

