import { Component, Input, OnInit } from '@angular/core';
import { AncillaryStatus } from '../../../../../../vendor/enum/ancillary-status.enum';

@Component({
  selector: 'app-ancillary-status-icon',
  templateUrl: './ancillary-status-icon.component.html',
  styleUrls: ['./ancillary-status-icon.component.scss']
})
export class AncillaryStatusIconComponent implements OnInit {
  constructor() {}
  @Input() ancillaryStatus: AncillaryStatus;
  @Input() isReturnFlight: Boolean;
  AncillaryStatus: typeof AncillaryStatus = AncillaryStatus;
  ngOnInit(): void {}

  getAncillaryIconSrc(status: AncillaryStatus) {
    switch (status) {
      case AncillaryStatus.Excluded:
        return 'https://images.sabscorp.com/images/enterprise/lightUK/assets/png/not-included-fare.png';
      case AncillaryStatus.Included:
      case AncillaryStatus.Chargeable:
        return 'https://images.sabscorp.com/images/enterprise/lightUK/assets/png/included-fare.png';
      case AncillaryStatus.Unknown:
        return 'https://images.sabscorp.com/images/enterprise/lightUK/assets/svg/question-mark-circle.svg';

      default:
        break;
    }
  }
}
