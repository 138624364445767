import { LanguageEnum, Language } from '../vendor/interfaces/language-types';
import localeFr from '@angular/common/locales/en';
import localeFrExtra from '@angular/common/locales/extra/en';

export default class FrenchLanguage extends Language {
  protected locale = localeFr;
  protected localeExtra = localeFrExtra;
  protected details = {
    image: 'images/flags/fr.png',
    title: 'Français',
    enum: LanguageEnum.French,
    aira: 'Langue française choisie',
    select: 'Choisir la langue française',
    abre: 'fr',
  };
}
