<div cdkTrapFocus>
    <div class="modal-header">
      <h3 class="modal-title">
        <span i18n="@@boltAdditionalInformation">Additional Information</span>
      </h3>
      <button
        type="button"
        class="close"
        aria-label="Close"
        ngbAutoFocus
        (click)="modal.close()">
        <span aria-hidden="true"><i class="ico-cancel modal_exit_cross"></i></span>
    </button>
    </div>
    <div class="modal-body">
      <ul class="modal-list" *ngIf="termsAndCond.length; else notDate">
        <li class="modal-list-item" *ngFor="let rule of termsAndCond">
          <span class="rule-title" [ngClass]="{'note-message': rule.name?.toLowerCase() === 'cancellation'}" >{{rule.name | titlecase}}</span>
          <span class="rule-value" >
            <div *ngIf="isObject | memoize:rule.value; else showValue">
              <li *ngFor="let item of value">
                <strong>{{ item.name }}</strong>
                <span>{{ item.remark }}</span>
              </li>
            </div>
            <ng-template #showValue>
              <span class="rule-value" [ngClass]="{'note-message': rule.name?.toLowerCase() === 'cancellation'}" [innerHTML]="rule.value"></span>
            </ng-template>
          </span>
        </li>
      </ul>

      <ng-template #notDate >
        <div *ngIf="loadingFareRules">Loading...</div>
        <span *ngIf="!loadingFareRules" i18n="@@TermsAndConditionsNoData">No data to display</span>
      </ng-template>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="small-button small-button--no"
        i18n="@@fareruleOK"
        (click)="modal.close()"
      >
        Ok
      </button>
    </div>
  </div>