<ng-template #rt
             let-r="result"
             let-t="term">
  {{ r.name }}
</ng-template>
<div id="irlSearchForm1"
     class="irl-search-form">
  <p class="tab_icons">
    <app-light-toggle-group tabindex="-1">
      <app-light-toggle-btn #onewayBtn
                            [selected]="searchParams.journeyType ===  IrlJourneyType.SingleJourney"
                            (click)="searchParams.journeyType =  IrlJourneyType.SingleJourney"
                            iconClass="ico-oneway"
                            tabindex="-1"
                            i18n="@@irlSearchTravellingOneWay">One Way Only</app-light-toggle-btn>
      <app-light-toggle-btn #returnBtn
                            [selected]="searchParams.journeyType ===  IrlJourneyType.ReturnJourney"
                            (click)="searchParams.journeyType =  IrlJourneyType.ReturnJourney"
                            iconClass="ico-return"
                            tabindex="-1"
                            i18n="@@irlSearchTravellingReturn">Return trip</app-light-toggle-btn>
    </app-light-toggle-group>
    <!--! offline  -->
    <button type="checkbox"
            id="offline"
            name="offline"
            (click)="searchParams.offlineRequest = !searchParams.offlineRequest"
            class="offline_button"
            [ngClass]="{'selected_offline': searchParams.offlineRequest, 'offline': !searchParams.offlineRequest}"><a
         for="offline"
         i18n="@@irlSearchTravellingOffline">Offline requests</a></button>
  </p>
  <div class="inputs-container">
    <div *ngIf="searchParams.offlineRequest"
         i18n="@@irlSearchTravellingOfflineMessage">
      Please fill out this form if the journey you wish to book is not available online. When you submit the request a
      member of the CTM team will be in touch with a quote. Please call the CTM team if your request is for travel
      within 48 hours.
      <br />
      Please note – for any irl pass requests please contact your offline team
    </div>
    <div class="half-container">
      <div class="half-block">
        <label id="irl-travelling-from"
               class="header-label"
               for="travelFrom1"
               i18n="@@irlSearchTravellingFrom">Travelling from:</label>
        <i class='ico-rail combo-box-icon'></i>
        <div class="flex-row-container">
          <input #tf
                 autocomplete="off"
                 type="text"
                 id="travelFrom1"
                 i18n-placeholder
                 placeholder='Enter a location'
                 onClick='this.select()'
                 class="input-box"
                 name="travellingFrom"
                 [ngbTypeahead]="irlLocations"
                 [(ngModel)]="searchParams.travellingFrom"
                 [resultTemplate]="rt"
                 [inputFormatter]="formatter"
                 aria-labelledby="irl-travelling-from"
                 tabindex="0"
                 (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
                 (keyup.arrowup)="ensureElementIsScrolledTo($event)"
                 [ngClass]='{"error-box": searchParams.validationMessages?.includes("Please Enter a From Location.")}' />
          <!-- <i *ngIf='srch.tempDestArray[5].loading'
             class='button_spinner input-loading-spinner-left'></i> -->
        </div>
      </div>
      <div class="half-block">
        <label id="irl-travelling-to"
               class="header-label"
               for="travelFrom2"
               i18n="@@irlSearchTravellngTo">Travelling to:</label>
        <i class='ico-rail combo-box-icon'></i>
        <div class="flex-row-container">
          <input #tt
                 autocomplete="off"
                 type="text"
                 id="travelFrom2"
                 i18n-placeholder
                 placeholder='Enter a location'
                 onClick='this.select()'
                 class="input-box"
                 name="travellingTo"
                 [ngbTypeahead]="irlLocations2"
                 [(ngModel)]="searchParams.travellingTo"
                 [resultTemplate]="rt"
                 [inputFormatter]="formatter"
                 aria-labelledby="irl-travelling-to"
                 tabindex="0"
                 (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
                 (keyup.arrowup)="ensureElementIsScrolledTo($event)"
                 [ngClass]='{"error-box": searchParams.validationMessages?.includes("Please Enter a To Location.")}' />
          <!-- <i *ngIf='srch.tempDestArray[6].loading'
             class='button_spinner input-loading-spinner-left'></i> -->
        </div>
      </div>
    </div>
    <div class="half-container">
      <div class="half-block">
        <div class="flex-row-container clearfix"
        [ngClass]='{"error-box": searchParams.validationMessages?.includes("Departure Date/Time cannot be in the past.")}'>
          <ngbd-datepicker-i18n>
            <div class="flex-column-container combo-box-left">
              <label class="header-label date-label"
                for="irl-outbound-date"
                i18n="@@irlSearchOutboundDate">Outbound Date (DD/MM/YYYY)</label>
              <i class='ico-calendar combo-box-icon cursor_pointer'
               ></i>
              <app-datepicker
                [minDate]='searchParams.minOutboundDate'
                [startDate]='searchParams.outbound_date'
                [model]="searchParams.outbound_date"
                [class]="'combo-box'"
                [componentId]="'irl-outbound-date'"
                (newDate)="searchParams.outbound_date = $event"
              >
              </app-datepicker>
            </div>
            <div class="flex-column-container combo-box-right">
              <label class="header-label"
                for="irl-outbound-time"
                i18n="@@irlSearchOutboundTime">Outbound Time</label>
              <i class='ico-clock combo-box-icon'></i>
              <select class='combo-box'
                      id="irl-outbound-time"
                      [(ngModel)]='searchParams.outbound_time'>
                <option *ngFor='let time of searchParams.timeArray'
                        [ngValue]="time.value">{{time.display}}</option>
              </select>
            </div>
          </ngbd-datepicker-i18n>
        </div>
      </div>
      <div class="half-block irl_return_container"
           [ngClass]="{
        irl_return_disabled: searchParams.journeyType === IrlJourneyType.SingleJourney
        }">
        <div class="flex-row-container clearfix">
          <ngbd-datepicker-i18n>
            <div class="flex-column-container combo-box-left">
              <label class='header-label date-label'
                 for="irl-return-date"
                 id="returndateandtime"
                 i18n="@@irlSearchReturnDate">Return Date (DD/MM/YYYY)</label>
              <i class='ico-calendar combo-box-icon cursor_pointer'
               ></i>
               <app-datepicker
                  [minDate]='searchParams.minInboundDate'
                  [startDate]='searchParams.return_date'
                  [model]="searchParams.return_date"
                  [class]="'combo-box'"
                  [componentId]="'irl-return-date'"
                  [disabled]="!(searchParams.journeyType === IrlJourneyType.ReturnJourney)"
                  (newDate)="searchParams.return_date = $event"
                >
                </app-datepicker>
            </div>
            <div class="flex-column-container select-box-right">
              <i class='ico-clock select-box-icon'></i>
              <label class="header-label"
                    i18n="@@AriaIrlReturnDate"
                    for="irl_return_time">Return Time</label>
              <select class='select-box'
                      id="irl_return_time"
                      [disabled]='!(searchParams.journeyType === IrlJourneyType.ReturnJourney)'
                      [(ngModel)]='searchParams.return_time'>
                <option *ngFor="let time of searchParams.timeArray"
                        [ngValue]="time.value">{{time.display}}</option>
              </select>
            </div>
          </ngbd-datepicker-i18n>
        </div>
      </div>
    </div>
    <div class="flex-column-container">
      <div *ngIf="!searchParams.offlineRequest">
        <h2 class="header-label rem-1-5"
            i18n="@@irlSearchOptions">Options</h2>
        <div class="quarter-container">
          <div class="quarter-block">
            <label id="irl-service-class"
                   i18n="@@IrlSearchMaxClass"
                   for="irl_class">Class of Service</label>
            <select class="combo-box"
                    [(ngModel)]="searchParams.irlClass"
                    aria-labelledby="irl-service-class"
                    id="irl_class">
              <option *ngFor="let serviceClass of searchParams.classTypes"
                      value="{{serviceClass.value}}">{{serviceClass.display | translate}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class='traveller_information_container'>
        <label class="container_label">
          <h3 i18n="@@irlSearchTravellerInformation">Traveller Information
          </h3>
        </label>
        <p *ngIf="travellers.length === 0"
           i18n="@@irlSearchNoTravellers">
          No travellers selected
        </p>
        <div class='traveller_select_container quarter-container'
             *ngFor='let traveller of travellers; let i= index; let first = first'>
          <div class="traveller_info quarter-block">
            <label class="container_label"
                   i18n="@@irlSearchName"
                   for="traveller_name">Name</label>
            <input name='name{{i}}'
                   id="tickets{{traveller.forename}} {{traveller.surname}}"
                   readonly
                   value="{{traveller.forename}} {{traveller.surname}}" />
          </div>
          <div class="traveller_info quarter-block">
            <label class="container_label"
                   i18n="@@irlSearchAgeRange"
                   for="traveller_name">Age Range
              <section (mouseleave)="tt1.close()"
                       class="container">
                <i class='ico ico-info-circle'
                   placement="top"
                   i18n-ngbTooltip
                   triggers="manual"
                   #tt1="ngbTooltip"
                   (mouseenter)="tt1.open()"
                   (focus)="tt1.open()"
                   (blur)="tt1.close()"
                   tabindex="0"
                   [ngbTooltip]="travellerTooltip">
                </i>
              </section>
            </label>

            <select name='age_type_select_{{i}}'
                    id="tickets{{traveller.name}}"
                    [(ngModel)]='travellers[i].ageType'
                    required>
              <option *ngFor="let age of searchParams.ageRanges"
                      [value]="age.key">{{age.value}}</option>
            </select>
          </div>
          <!-- ! discount cards and loyalty cards -->
          <div *ngIf="searchParams.irlDiscountCardsArray.length"
               class="traveller_info quarter-block">
            <label class="container_label"
                   i18n="@@irlSearchDiscountCard"
                   for="traveller_name">Discount Card
            </label>
            <select name="discount_card_select_{{i}}"
                    id="tickets{{traveller.name}}"
                    [(ngModel)]="selectedDiscountCards[i]"
                    (change)="changeIrlcardOption()"
                    [compareWith]="cardComparator">
              <option [value]="undefined"
                      i18n="@@discountCardProvider">(None)</option>
              <option *ngFor="let card of searchParams.irlDiscountCardsArray"
                      [ngValue]="card">{{card.name}}</option>
            </select>
          </div>
          <!--    <div *ngIf="loyaltyCardProviders.length && searchParams.offlineRequest" class="traveller_info quarter-block">
            <label class="container_label">Loyalty Card</label>
            <select
            panelClass="sort-select"
            [(ngModel)]='travellers[i].loyaltyCard'
            (change)="changeProvider(i, $event.target.value, traveller)"
            class="combo-box combo-box--no-icon combo-box--border"
            name="operator-{{j}}"
            aria-label="Loyalty Provider"
            >
            <option [value]="undefined"
            i18n="@@loyaltyCardProvider">(None)</option>
              <option *ngFor='let prov of loyaltyCardProviders'
              [value]="prov.code">{{prov.display | titlecase}}</option>
            </select>
          </div>
          <div *ngIf="loyaltyCardProviders.length && searchParams.offlineRequest" class="traveller_info quarter-block">
            <label class="container_label">Loyalty Number</label>
            <input class="combo-box--border input-box input-box--no-icon"
            type="text"
            [disabled]="!travellers[i].loyaltyCard"
            [required]="travellers[i].loyaltyCard"
            [(ngModel)]="travellers[i].loyaltyNumber"
            pattern="[a-zA-Z0-9]+"
            name="cardnum-{{j}}"
            />
          </div>
        </div> -->
        </div>
        <!-- !offline -->
        <div class="additional_info_container"
             *ngIf="searchParams.offlineRequest">
          <div class="half-container offline_info_boxes">
            <div class="">
              <label class="container_label">
                <h3 i18n="@@IrlSearchAdditionalInformation">Additional Information</h3>
              </label>
              <div class="traveller_info quarter-block">
                <div class="quarter-block offline_info_boxes">
                  <label id="irl-service-class"
                         i18n="@@IrlSearchTrainNumber"
                         for="irl_class">Train Number
                  </label>
                  <input [(ngModel)]="searchParams.trainNumber"
                         class="combo-box" />
                </div>
              </div>
              <div class="traveller_info quarter-block">
                <div class="quarter-block">
                  <label id="irl-service-class"
                         i18n="@@IrlSearchMaxClass"
                         for="irl_class">Class of Service</label>
                  <select class="combo-box"
                          [(ngModel)]="searchParams.irlClass"
                          aria-labelledby="irl-service-class"
                          id="irl_class">
                    <option *ngFor="let serviceClass of searchParams.classTypes"
                            value="{{serviceClass.value}}">{{serviceClass.display | translate}}
                    </option>
                  </select>

                </div>
              </div>
            </div>
          </div>
          <div class="half-block addition_instructions_container">
            <label id="irl-service-class"
                   i18n="@@IrlSearchInstructions"
                   for="irl_class">Additional instructions</label>
            <textarea [(ngModel)]="searchParams.additionalInstructions"
                      class="addition_instructions">Notes</textarea>

          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #travellerTooltip>
    <table style="width: 500px; text-align:left">
      <tr>
        <th>Country</th>
        <th>Child</th>
        <th>Youth</th>
        <th>Senior</th>
      </tr>
      <tr>
        <td>Eurostar</td>
        <td>4-11y</td>
        <td>12-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Austria</td>
        <td>6-15y</td>
        <td>16-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Belgium</td>
        <td>6-15y</td>
        <td>16-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Bosnia</td>
        <td>4-11y</td>
        <td>12-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Bulgaria</td>
        <td>6-15y</td>
        <td>16-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Croatia</td>
        <td>4-11y</td>
        <td>12-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Denmark</td>
        <td>6-15y</td>
        <td>16-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Finland</td>
        <td>6-16y</td>
        <td>17-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>France</td>
        <td>4-11y</td>
        <td>12-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Germany</td>
        <td>6-14y</td>
        <td>15-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Greece</td>
        <td>4-11y</td>
        <td>12-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Hungary</td>
        <td>6-13y</td>
        <td>14-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Italy</td>
        <td>4-11y</td>
        <td>12-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Luxemburg</td>
        <td>6-11y</td>
        <td>12-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Macedonia</td>
        <td>4-11y</td>
        <td>12-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Netherlands</td>
        <td>4-11y</td>
        <td>12-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Poland</td>
        <td>4-11y</td>
        <td>12-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Portugal</td>
        <td>4-11y</td>
        <td>12-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Romania</td>
        <td>4-11y</td>
        <td>12-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Serbia</td>
        <td>4-11y</td>
        <td>12-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Slovakia</td>
        <td>6-11y</td>
        <td>12-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Slovenia</td>
        <td>6-11y</td>
        <td>12-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Spain</td>
        <td>4-13y</td>
        <td>14-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Switzerland</td>
        <td>6-15y</td>
        <td>16-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>UK</td>
        <td>6-16y</td>
        <td>17-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Australia</td>
        <td>4-15y</td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>New Zealand</td>
        <td>2-14y</td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>USA</td>
        <td>2-12y</td>
        <td></td>
        <td>62+</td>
      </tr>
      <tr>
        <td>Canada</td>
        <td>2-11y</td>
        <td></td>
        <td>60+</td>
      </tr>
    </table>
  </ng-template>
  <app-irl-loading-screen></app-irl-loading-screen>