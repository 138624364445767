<ng-template #approverEmailTemp let-r="result">
    {{ r.email }}
</ng-template>

<div class="profile_information--content">
    <div class="profile_information--sub-header" i18n="@@PleaseChooseSearchType">
        Please choose search type
    </div>
    <div class="approver-type-content">
        <input type="radio" id="profile" name="profile" value="profile" checked="">
        <label for="profile" i18n="@@Profile"> Profile</label>
    </div>
    <mat-accordion [multi]="true">
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title i18n="@@PleaseSearchAndSelectApprovers">
                    Please search and select approvers
                </mat-panel-title>
            </mat-expansion-panel-header>
       
            <div class="profile_information--input-wrapper">
                    <input 
                    [disabled]="!isEditable"
                    i18n-placeholder
                    placeholder="Start typing a value..."
                    type="text" 
                    class="profile_information--input" 
                    [resultTemplate]="approverEmailTemp"
                    [(ngModel)]="approverEmail"
                    (selectItem)="selectApprover($event)"
                    [inputFormatter]="formatterApproverEmail"
                    [ngbTypeahead]="searchApproverEmail"/>
                    <fa-icon [icon]="faChevronDown"></fa-icon>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title i18n="@@OrderTheApproversByDraggingAndDropping">
                    Order the approvers by dragging and dropping
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div [formGroup]="approvalForm" *ngIf="user && approvalForm">
                <div formArrayName="userApprovers" class="user-approvers-table-wrapper">
                    <table *ngIf="userApprovers.length; else noApprovers" class="user-approvers-table">
                        <thead class="profile_information--table-thead">
                            <tr>
                                <th i18n="@@ProfileInformation-Sort">Sort</th>
                                <th i18n="@@ProfileInformation-Order">Order</th>
                                <th i18n="@@ProfileInformation-ApproverEmail">Approver Email</th>
                                <th i18n="@@ProfileInformation-Delete">Delete</th>
                            </tr>
                        </thead>
                        <tbody class="profile_information--table-tbody" cdkDropList [cdkDropListData]="rows" (cdkDropListDropped)="dropRow($event)">
                            <tr *ngFor="let approver of userApprovers.controls; let i = index" [formGroupName]="i" cdkDrag [cdkDragDisabled]="!isEditable">
                                <td cdkDragHandle [ngClass]="{'drag-icon': isEditable}" ><fa-icon [icon]="faBars"></fa-icon></td>
                                <td>{{i+1}}</td>
                                <td>{{approver.get('email').value}}</td>
                                <td><button class="mat-stroked-button" [disabled]="!isEditable"  [class.disable-button]="!isEditable" (click)="deleteApprover(i)" i18n="@@ProfileInformation-Delete">Delete</button></td>
                            </tr>
                        </tbody>
                    </table>
        
                    <ng-template #noApprovers>
                        <p class="no-data-container" i18n="@@ProfileInformation-No-approvers-to-display">No approvers to display</p>
                    </ng-template>
                </div>
            </div>
            
        </mat-expansion-panel>
    </mat-accordion>
</div>