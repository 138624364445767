<div cdkTrapFocus aria-live="assertive">
    <div class="modal-header">
      <h3 class="modal-title"
          id="modal-header" i18n="deleteAddresses">Delete Saved Addresses</h3>
      <button type="button"
              class="close"
              attr.aria-label="Close"
              ngbAutoFocus
              (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true"><i class='ico-cancel modal_exit_cross'></i></span>
      </button>
    </div>
    <div class="modal-body">
      <div *ngFor="let address of addressList" class="flex-row-container address_row">
        <span>{{address.House}} {{address.Street}} {{address.Town}} {{address.PostCode}}</span>
        <section (mouseleave)="tt.close()"
                class="container">
              <i 
                *ngIf="!address.Company && !address.Office"
                container="body"
                 ngbTooltip="Remove address"
                 attr.aria-label="Remove address {{address.House}} {{address.Street}} {{address.Town}} {{address.PostCode}}"
                 (click)="removeAddress(address, $event)"
                 role="button"
                 #tt="ngbTooltip"
                 (mouseenter)="tt.open()"
                 (focus)="tt.open()"
                 (blur)="tt.close()"
                 tabindex="0"
                 class="light-color-warning ico-cancel"><span class="visuallyhidden">Remove address</span>
              </i>
              <i 
                *ngIf="address.Company || address.Office"
                container="body"
                 ngbTooltip="Cannot remove address - address is set at company or office level"
                 attr.aria-label="Cannot remove address - address is set at company or office level"
                 #tt="ngbTooltip"
                 (mouseenter)="tt.open()"
                 (focus)="tt.open()"
                 (blur)="tt.close()"
                 class="ico-info"><span class="visuallyhidden">Cannot remove address - address is set at company or office level</span>
              </i>
       </section>
      </div>
    </div>
  </div>