import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HotelsComponent } from './components/hotel/hotels/hotels.component';
import { LoungesComponent } from './components/lounges/lounge.component';
import { ParkingComponent } from './components/parking/parking.component';
import { FlightsComponent } from './components/flights/flights.component';
import { EurostarComponent } from './components/eurostar/eurostar.component';
import { ResultsComponent } from './components/results/results.component';
import { TaxisComponent } from './components/taxis/taxis.component';
import { CarsComponent } from './components/cars/cars.component';
import { RailResultsComponent } from './components/rail/rail-results.component';
import { WellbeingComponent } from './components/wellbeing/wellbeing.component';
import { IrlResultsComponent } from './components/irl-results/irl-results.component';
import { FastTrackComponent } from './components/fast-track/fast-track.component';
import { FerryComponent } from './components/ferry/ferry.component';

const routes: Routes = [
  {
    path: '',
    component: ResultsComponent,
    /*
     * TODO: SearchParamsGuard could be created and used to determinate if user actually,
     * have got search params in sessionStorage or memory to go to search results
     */
    children: [
      { path: 'hotels', component: HotelsComponent },
      { path: 'cars', component: CarsComponent },
      { path: 'flights', component: FlightsComponent },
      { path: 'rail', component: RailResultsComponent },
      { path: 'europeanrail', component: IrlResultsComponent },
      { path: 'parkings', component: ParkingComponent },
      { path: 'lounges', component: LoungesComponent },
      { path: 'fast-track', component: FastTrackComponent },
      { path: 'ferry', component: FerryComponent },
      { path: 'taxis', component: TaxisComponent },
      {
        path: 'wellbeing', component: WellbeingComponent
      },
      { path: 'eurostar', component: EurostarComponent },
      // { path: 'coach', component: CoachComponent },
      // { path: 'getmethere', component: GetmethereResultsComponent, canActivate: [GetMeThereAuthGuardService] }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ResultsRoutingModule {}
