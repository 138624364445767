<div cdkTrapFocus
     aria-live="assertive">
  <div class="modal-header">
    <h3 class="modal-title"
        i18n="@@savePreferredLanguageModalTitle"
        id="modal-header">
      <em></em> Save Preferred Language </h3>
    <button type="button"
            class="close"
            title='Close Modal'
            aria-label="Save Preffered Lanuage Close"
            ngbAutoFocus
            i18n-aria-label
            (click)="activeModal.close(false)">
      <span aria-hidden="true">
        <i class='ico-cancel modal_exit_cross'></i>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <p ><span i18n="@@savePreferredLanguageModalText_1">Would you like to save</span> <b>{{ Language }}</b> <span i18n="@@savePreferredLanguageModalText_2">as your preferred Language?</span>
    </p>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="small-button small-button--no"
            title='Cancel Choice'
            (click)="close()"
            i18n="@@cancelLanguageOnlyModalButton"> Cancel </button>

    <button type="button"
            class="small-button"
            title='Select Language Only Choice'
            (click)="onlySelect()"
            i18n="@@selectLanguageOnlyModalButton"> No </button>

    <button type="button"
            class="small-button"
            title='Save Choice'
            (click)="save()"
            i18n="@@saveLanguageModalButton"> Yes </button>

  </div>
</div>