import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService, User, EnterpriseSearchService, ServiceType } from '@sabstravtech/obtservices/angular';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../base_components/base-componet';
import { LanguageService } from '../../../vendor/services/language.service';
import { LightningUserFavorurite } from '../../classes/user-favourite.enum';
import { VERSION } from '../../../../environments/version';
@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent extends BaseComponent implements OnInit {
    showRobot = false;
    sub = new Subscription();
    data: string;
    LandingPage: boolean = false;
    // isAbleEditHomepage: boolean | FavouriteDetails;
    storeLP: boolean;

    companyIdToUpdate: string;
    isAgency: boolean;
    canBookGetMeThere: boolean = false;
    hasHomepage: boolean = true;
    homepage: string = '';
    currentVersion = VERSION;
    ssoCall: boolean = true;
    loadingSSO: boolean = false;

    constructor(
        private userService: UserService,
        // private getMeThereAuthGuard: GetMeThereAuthGuardService,
        private router: Router,
        // private landingPageService: LandingPageService,
        private languageService: LanguageService,
        title: Title,
        public translateService: TranslateService,
        public searchService: EnterpriseSearchService

    ) {
        super(title, translateService);
    }

    ngOnInit(): void {

        let ssoFlightQuery = window.location.href.split('?')[1]?.split('#')[0];

        if (ssoFlightQuery && this.searchService.ssoCall) {
            console.warn('+++ LOADING SSO CALL +++');
            this.loadingSSO = true;
        }

        this.subscribe(this.userService.fullUserDetails.allDetails, (fullUser: User) => {
            console.log(fullUser);
            if (fullUser) {
                console.log('+++ Searches: ',this.searchService.searches[ServiceType.Flight], ' +++');

                    setTimeout(() => {
                        if (ssoFlightQuery && this.searchService.ssoCall && this.searchService.searches[ServiceType.Flight]) {
                            ssoFlightQuery = ssoFlightQuery.replace(/%3A/g, '');
                            const splitQuery = ssoFlightQuery.split('&');
                            const ssoObject = splitQuery.reduce((acc, param) => {
                                const splitParam = param.split('=');
                                acc[splitParam[0]] = splitParam[1];
                                return acc;
                            }, {});
                            this.searchService.ssoCall = false;
                            this.searchService.makePortalFlightCall(ssoObject);

                        } else if (!this.searchService.searches[ServiceType.Flight]) {
                            console.error('+++ SEARCH NOT KICKED OFF - SEARCHES OBJECT MAY NOT HAVE LOADED CORRECTLY +++');
                            this.loadingSSO = false;
                        }
                    }, 2000);
                const selectedLanguage = fullUser?.selectedLanguage;
                const isSupprtAgent = this.userService.userIsSupportAgent();
                if (selectedLanguage && !isSupprtAgent) {
                    const availableLanguages = fullUser.availableLanguages.map(
                        (lang) => lang.isoCode
                    );

                    this.languageService.changeLanguageFromUserDetails(
                        selectedLanguage.name,
                        selectedLanguage.isoCode,
                        availableLanguages
                    );
                }
                this.homepage = this.userService.getUserFavoriteValue(
                    LightningUserFavorurite.homepage
                );
                if (this.homepage && this.currentVersion) {
                    this.homepage = this.homepage + '\n' + ` **Version:**  ${this.currentVersion}`;
                }

                this.hasHomepage = this.router.url.includes('main') && !!this.homepage;
            }
        });
        // this.companyIdToUpdate = this.userService.getUsersCompany();
        this.isAgency = this.userService.userIsAgent();

        // if (!this.userService.becomingUser) {
        // we also need to do a check for admin which we aren't doing at all right now
        // this.subscribe(
        //   this.landingPageService.getLandingPageTemplateMarkUp('corporate-homepage', this.companyIdToUpdate, this.isAgency),
        //   data => {
        //     if (data) {
        //       this.storageService.setSessionItem('LandingPage:', 'true');
        //       this.storeLP = true;
        //     } else {
        //       this.storageService.removeSessionItem('LandingPage:');
        //       this.storeLP = false;
        //     }
        //     this.LandingPage = this.storeLP || (this.isAbleEditHomepage && this.isAgency);
        //     if (this.LandingPage) {
        //       this.redirect('/landingPageBrand');
        //     } else {
        //       if (this.languageService.getUserLanguages().length > 0 && !this.userService.getAgencyUser()) {
        //         this.languageService.applyLanguage();
        //       }
        //     }
        //   }
        // );
        this.setTitle(this.translateService.instant('Main Landing Page - LightUk'));
        // }
    }

    becomingUser(): boolean {
        // return this.userService.becomingUser;
        return false;
    }

    redirect(page: string): void {
        this.router.navigate([page]);
    }
}
