import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { UserDefaultManagementInfoValue, ManagementInfoValue } from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-mi-default-select',
  templateUrl: './mi-default-select.component.html',
  styleUrls: ['./mi-default-select.component.scss']
})
export class MiDefaultSelectComponent implements OnInit {
  @Input() placeholder: string;
  @Input() miItem: UserDefaultManagementInfoValue;
  @Output() clickOutside = new EventEmitter();
  faChevronDown = faChevronDown;
  title: string = '';
  data: UserDefaultManagementInfoValue;

  value: string = '';

  ngOnInit() {
    this.title = this.miItem.name;
    this.data = this.miItem;
  }

  addNewMiValue() {
    // const managementInfoValue: ManagementInfoValue = this.miItem.find((val: ManagementInfoValue) => val.value === this.value);

    // this.miItem.managementInfo.defaultValue = {
    //   valueId: managementInfoValue.id,
    //   value: managementInfoValue.label || managementInfoValue.value,
    // };
    // this.miItem.managementInfo.dbId = +this.miItem.managementInfo.id;
    // this.value = '';
    // this.clickOutside.emit(this.miItem);
  }
}