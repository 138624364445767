import { LanguageEnum, Language } from '../vendor/interfaces/language-types';
import localeNl from '@angular/common/locales/nl';
import localeNlExtra from '@angular/common/locales/extra/nl';

export default class DutchLanguage extends Language {
  protected locale = localeNl;
  protected localeExtra = localeNlExtra;
  protected details = {
    image: 'images/flags/nl.png',
    title: 'Nederlandse',
    enum: LanguageEnum.Dutch,
    aira: 'Nederlandse taal gekozen',
    select: 'Selecteer nederlandse taal',
    abre: 'nl',
  };
}
