import { Component, Input } from '@angular/core';
import { Traveller } from '@sabstravtech/obtservices/base';

@Component({
  selector: 'app-recent-search-travellers',
  templateUrl: './recent-search-travellers.html',
  styleUrls: ['./recent-search-travellers.scss']
})
export class RecentSearchTravellers {
  @Input() travellers: Traveller[];

  constructor() { }
}
