import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-encrypted-date-input',
  templateUrl: './encrypted-date-input.component.html',
  styleUrls: ['./encrypted-date-input.component.scss'],
  providers: [
    {
      provide: ControlContainer,
      multi: true,
      useExisting: FormGroupDirective 
    }
  ]
})
export class EncryptedDateInputComponent implements OnInit {

  control:FormControl
   @Input('control') set _control(value){
       this.control=value as FormControl
   }
   @Input() name: string;
   @Input() minDate: NgbDate;
   @Input() maxDate: NgbDate;


   editMode: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
