import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keyValue',
})
export class KeyValuePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      const keys = [];
      const keyObject = args ? Object.keys(value).sort((a: string, b: string) => {
        return +a - +b;
      }) : Object.keys(value);

      for (let i = 0; i < keyObject.length; i++) {
        keys.push({
          key: keyObject[i],
          value: value[keyObject[i]],
        });
      }

      return keys;
    }

  }
}
