import { QueryList } from '@angular/core';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { FlightBasketDetails, Journey } from '../interfaces/flight-basket-details';

export class UiHelpers {
  static closeOpenCalendars(datepickerList: QueryList<NgbInputDatepicker>, event: MouseEvent) {
    (datepickerList || []).forEach(datepickerInput => {
      if (!datepickerInput.isOpen()) {
        datepickerInput['readyToClose'] = false;
        return;
      }
      if (
        !datepickerInput['_cRef'].location.nativeElement.contains(event.target) &&
        datepickerInput['readyToClose']
      ) {
        datepickerInput.close();
        datepickerInput['readyToClose'] = false;
      } else {
        setTimeout(() => {
          datepickerInput['readyToClose'] = true;
        });
      }
    });
  }

  static makeJourneys(flights: FlightBasketDetails[]): Journey[] {
    return Object.values(
      flights.reduce((current: any, flight: FlightBasketDetails) => {
        const journey = current[
          flight.id
        ] || {
          cabinClass: flight.cabinClass,
          cabinCode: flight.cabinCode,
          bookingCode: flight.bookingCode,
          dateTimeArriving: flight.dateTimeArriving,
          dateTimeArrivingUTC: flight.dateTimeArrivingUTC,
          dateTimeDeparting: flight.dateTimeDeparting,
          dateTimeDepartingUTC: flight.dateTimeDepartingUTC,
          destinationAirport: flight.destinationAirport,
          marketingCarrier: flight.marketingCarrier,
          marketingFlightNumber: flight.marketingFlightNumber,
          operatingCarrier: flight.operatingCarrier,
          operatingFlightNumber: flight.operatingFlightNumber,
          originAirport: flight.originAirport,
          totalDuration: flight.totalDuration,
          originTerminal: flight.originTerminal,
          destinationTerminal: flight.destinationTerminal,
          id: flight.id,
          equipmentCode: flight.equipmentCode,
          equipmentName: flight.equipmentName,
          fareConditions: flight.fareConditions,
          fareBasisCode: flight.fareBasisCode,
          fareInfo: flight.fareInfo,
          additional: flight.additional,
          originAirportDetail: flight.originAirportDetail,
          destinationAirportDetail: flight.destinationAirportDetail,
          baggageAllowanceAmount: flight?.additional?.baggageAllowanceAmount
        };

        current[flight.id] = {
          ...journey,
          dateTimeArriving: flight.dateTimeArriving,
          dateTimeArrivingUTC: flight.dateTimeArrivingUTC,
          destinationAirport: flight.destinationAirport
        };
        return current;
      }, {})
    );
  }
}

