<ng-template #loader>
  <div class="recent_searches_no_recent">
    <i class="button_spinner light-center-absolute"></i>
    <span i18n="@@YLoadingRecentSearchesMessage">Loading recent searches...</span>
  </div>
</ng-template>
<ng-template #loaderSingle>
  <i class="button_spinner light-center-absolute"></i>
</ng-template>
<section>
  <div class="recent_searches_content background-40">
    <ng-container *ngIf="recentSearches; else loader">
      <div class="recent_searches_recent" *ngIf="recentSearches.length">
        <ul>
          <li *ngFor="let item of recentSearches; let i = index" class="recent-search-item" [@fadeInOut]>
            <ng-container *ngIf="item; else loader">
              <!-- ! BOLT ITEM -->
              <div
                aria-label="Perfrom Recent Bolt Search"
                class="storageItem"
                [@fadeInOut]
                tabindex="0"
                role="button"
                (keydown.enter)="performRecentSearch(item)"
                (click)="performRecentSearch(item)">
                <div class="icons">
                  <i class="ico-bolt input_icon" aria-hidden="true"></i>
                </div>
                <div class="details">

                  <span class="title" id="recent_search_{{i}}">
                    {{ item.searchArgs.departure.title}} -
                    {{ item.searchArgs.destination.title}}
                  </span>
                  <!--<span class="Title" *ngIf="item.searchArgs.isAirportExpress" tabindex="0" (keydown.enter)="performRecentSearch(item)" role="button" id="recent_search_{{i}}">
                    {{ item.searchArgs.airportExpressStation | airportExpress }}
                  </span>-->
                  <span class="Date"
                    >{{
                      item.searchArgs.departureDate | sabsDate: 1
                    }}
                    <!-- if return jorney  -->
                    {{
                      item.searchArgs.returnDate
                        ? ' - ' + (item.searchArgs.returnDate | sabsDate: 1)
                        : ''
                    }}

                  </span>
                  <br />
                  <br />
                  <app-recent-search-travellers
                    [travellers]="item.travellers"></app-recent-search-travellers>
                </div>
                <div class="right" (click)="removeRecentSearchItem(item, $event)" (keydown.enter)="removeRecentSearchItem(item, $event)" tabindex="0" role="button" attr.aria-labelledby="remove_recent_search_hint recent_search_{{i}}">
                  <i class="ico-cancel recentSearch_remove" aria-hidden="true"></i>
                </div>
              </div>
            </ng-container>
          </li>
        </ul>
      </div>
      <div class="recent_searches_no_recent" *ngIf="recentSearches.length === 0">
        <i class="ico-search no_recent_icon" aria-hidden="true"></i>
        <h2 class="no_recent_header" i18n="@@YouHaveNoRecentSearches">
          You have no recent searches
        </h2>
        <p class="no_recent_tag_line" i18n="@@YouHaveNoRecentSearchesMessage">
          All searches you perform will appear here so you can quickly pick up where you left off
          anytime
        </p>

        <p></p>
      </div>
    </ng-container>
    <span id="remove_recent_search_hint" hidden>Remove recent search</span>
  </div>
</section>

