import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'policyChanger',
})
export class PolicyChangerPipe implements PipeTransform {
  replacements = [
    {
      reg: /^Disallowed - Price > (\d*)/,
      replace: 'ReasonRequired_NotAllowed',
    },
    {
      reg: /^Reason Req - Price > (\d*)/,
      replace: 'ReasonRequired_Reason',
    },
    {
      reg: /\(Default-N\)/,
      replace: '',
    },
  ];

  currencySymbols = {
    GBP: '£',
  };

  constructor(private translate: TranslateService) {}

  transform(value: string, currency?): string {
    currency = currency
      ? this.currencySymbols[currency]
        ? this.currencySymbols[currency]
        : currency
      : '£';
    return this.replacements.reduce((val, item) => {
      const values = val.match(item.reg);
      return values
        ? this.translate.instant(item.replace, {
            curr: currency,
            price: values[1],
          })
        : val;
    }, value || '');
  }
}
