import { Pipe, PipeTransform } from '@angular/core';
import { Traveller } from '@sabstravtech/obtservices/base';
import { UserOrGuest } from '@sabstravtech/obtservices/angular';
@Pipe({
  name: 'checkFrequentTraveller'
})
export class CheckFrequentTravellerPipe implements PipeTransform {
  transform(traveller: Traveller, favouriteUsers: UserOrGuest[]): UserOrGuest {
    if(!favouriteUsers?.length) return null;
    return favouriteUsers.find((favourite: UserOrGuest) => favourite.id === traveller.id)
  }
}
