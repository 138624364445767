import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkPassengerAmount'
})
export class CheckPassengerAmountPipe implements PipeTransform {

  transform(passengers: any[], filterType: string, isNumberOnly = false): string |number {
    if(!passengers?.length) return;
    const passengersFilter = passengers.filter(passenger => passenger.type.toLowerCase() === filterType)
    const result = `${passengersFilter.length} ${filterType}`;
    if(isNumberOnly)return passengersFilter.length;
    if (passengersFilter.length === 1){
      return result;
    } else{
      return `${result}s`;
    }
  }

}
