import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
// import { NouiFormatter, NouisliderComponent } from 'nouislider';
import { Options } from 'ngx-slider-v2';
import {
  DEFAULT_FLIGHT_FILTERS,
  FlightEnterpriseSearchInterface,
  FlightFilters,
  FlightSortTypes,
  FlightDisplayTypes,
  FlightSearchType
} from '@sabstravtech/obtservices/base';
import {
  ServiceType,
  EnterpriseSearchService,
  WithSubscriptionComponent,
  UserService
} from '@sabstravtech/obtservices/angular';
import { Helpers } from '../../../../vendor/classes/helpers';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LightningUserFavorurite } from '../../../../vendor/classes/user-favourite.enum';

interface RangeFilterValue {
  value: number;
  highValue: number;
}

interface FilterValue {
  key: string;
  title: string;
  checked: boolean;
}

@Component({
  selector: 'app-filter-flights',
  templateUrl: './filter-flights.component.html',
  styleUrls: ['./filter-flights.component.scss']
})
export class FilterFlightsComponent extends WithSubscriptionComponent implements OnInit, OnChanges {
  @Input() filters: FlightFilters;
  @Input() display: FlightDisplayTypes;
  public ourFilters: FlightFilters = DEFAULT_FLIGHT_FILTERS;
  public priceOptions: Options = { floor: 0, ceil: 0 };
  public depTimeOptions: Options = { floor: 0, ceil: 0 };
  public arrTimeOptions: Options = { floor: 0, ceil: 0 };

  public sortType = FlightSortTypes.Cheapest;
  public searchParams: FlightEnterpriseSearchInterface;
  public FlightSearchType = FlightSearchType;
  public selectableFilters;
  public standardFilters = [
    {
      title: 'Preferred',
      sortType: FlightSortTypes.Preferred,
      icon: 'ico-heart input_icon',
      ariaLabel: 'Sort by Preferred'
    },
    {
      title: 'Price',
      sortType: FlightSortTypes.Cheapest,
      icon: 'ico-gbp',
      ariaLabel: 'Sort by Price'
    },
    {
      title: 'Lowest Emissions',
      sortType: FlightSortTypes.LowestEmissions,
      icon: 'ico-co2 input_icon',
      ariaLabel: 'Sort by Co2 Emissions'
    },
    {
      title: 'Quickest',
      sortType: FlightSortTypes.Quickest,
      icon: 'ico-clock input_icon',
      ariaLabel: 'Sort by Quickest'
    },
    {
      title: 'Most Direct',
      sortType: FlightSortTypes.MostDirect,
      icon: 'ico-chevron-right input_icon',
      ariaLabel: 'Sort by Most Direct'
    },
    {
      title: 'Departure Time',
      sortType: FlightSortTypes.DepartureTime,
      icon: 'ico-icon-takeoff input_icon',
      ariaLabel: 'Sort by Departure Time'
    },
    {
      title: 'Arrival Time',
      sortType: FlightSortTypes.ArrivalTime,
      icon: 'ico-icon-landing input_icon',
      ariaLabel: 'Sort by Arrival Time'
    }
  ];

  public $triggerApplyFilter = new Subject<FlightFilters>();

  constructor(public searchService: EnterpriseSearchService, private userService: UserService) {
    super();
    this.searchParams = this.searchService.searches[ServiceType.Flight];
  }

  ngOnInit() {
    if (this.searchParams.bySchedule) {
      const priceFilterIndex = this.standardFilters.findIndex(filter => filter.title === 'Price');
      if (priceFilterIndex !== -1) {
        this.standardFilters.splice(priceFilterIndex, 1);
      }
    }
    this.selectableFilters = this.standardFilters;
    const defaultSortType = FlightSortTypes.Preferred;
    this.sortType =
      this.userService.getUserFavoriteObject<{ sortOrder: FlightSortTypes }>(
        LightningUserFavorurite.FlightResultsConfiguration
      )?.sortOrder || defaultSortType;
    this.setSortby(this.sortType);

    this.subscribe(this.$triggerApplyFilter.pipe(debounceTime(200)), (filters: FlightFilters) => {
      this.searchParams.applyFilters(filters, this.sortType);
    });
    this.addAriaLabels();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['filters']) {
      this.ourFilters = this.clearFlightFilters(this.filters);
      if (this.searchParams.bySchedule || changes.display) {
        const defaultSortType = this.searchParams.bySchedule ? FlightSortTypes.Quickest : FlightSortTypes.Cheapest;
        this.sortType =
          this.userService.getUserFavoriteObject<{ sortOrder: FlightSortTypes }>(
            LightningUserFavorurite.FlightResultsConfiguration
          )?.sortOrder || defaultSortType;
        this.searchParams.applyFilters(this.filters, this.sortType);
      }
    }
  }

  clear(): void {
    this.ourFilters = this.clearFlightFilters(this.filters);
    this.applyFilter(this.ourFilters);
  }

  clearFlightFilters(original: FlightFilters): FlightFilters {
    const filters = Helpers.clone(original);
    this.priceOptions = {
      floor: Math.floor(filters.price.min === Number.MAX_SAFE_INTEGER ? 0 : filters.price.min),
      ceil: Math.ceil(filters.price.max === Number.MIN_SAFE_INTEGER ? 0 : filters.price.max),
      step: 1
    };
    filters.price.min = this.priceOptions.floor;
    filters.price.max = this.priceOptions.ceil;
    this.depTimeOptions = {
      floor: filters.departureTime.min,
      ceil: filters.departureTime.max
    };
    this.arrTimeOptions = {
      floor: filters.arrivalTime.min,
      ceil: filters.arrivalTime.max
    };

    return filters;
  }

  applyFilter(filters: FlightFilters) {
    this.$triggerApplyFilter.next(filters);
  }

  moveArrowDown(e): void {
    e.preventDefault(); //Stops page scrolling
    (<HTMLElement>document.activeElement.nextElementSibling).focus();
  }
  moveArrowUp(e): void {
    e.preventDefault(); //Stops page scrolling
    (<HTMLElement>document.activeElement.previousElementSibling).focus();
  }
  setSortby(sortType: FlightSortTypes): void {
    this.sortType = sortType;
    this.applyFilter(this.ourFilters);
  }

  addAriaLabels() {
    setTimeout(function () {
      const sliders = document.querySelectorAll('[role="slider"]');
      for (let x = 0; x < sliders.length; x++) {
        switch (x) {
          case 0:
            sliders[x].setAttribute('aria-label', 'Price Range Lower Bound');
            break;

          case 1:
            sliders[x].setAttribute('aria-label', 'Price Range Higher Bound');
            break;

          case 2:
            sliders[x].setAttribute('aria-label', 'Outbound Departure Time Lower Bound');
            break;

          case 3:
            sliders[x].setAttribute('aria-label', 'Outbound Departure Time Higher Bound');
            break;

          case 4:
            sliders[x].setAttribute('aria-label', 'Outbound Arrival Time Lower Bound');
            break;

          case 5:
            sliders[x].setAttribute('aria-label', 'Outbound Arrival Time Higher Bound');
            break;

          case 6:
            sliders[x].setAttribute('aria-label', 'Inbound Departure Time Lower Bound');
            break;

          case 7:
            sliders[x].setAttribute('aria-label', 'Inbound Departure Time Higher Bound');
            break;

          case 8:
            sliders[x].setAttribute('aria-label', 'Inbound Arrival Time Lower Bound');
            break;

          case 9:
            sliders[x].setAttribute('aria-label', 'Inbound Arrival Time Higher Bound');
            break;
        }
      }
    }, 0);
  }
}

