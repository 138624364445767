import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import {
  EnterpriseSearchService,
  WithSubscriptionComponent,
  ServiceType,
  HotelAvalibilityService,
  HelperRoutines,
  RailJourneyOption,
  RailTicketOption
} from '@sabstravtech/obtservices/angular';
import {
  HotelAvalibilityQuoteResult,
  HotelDetails,
  HotelEnterpriseSearchInterface,
  HotelQuoteResult,
  HotelViewController,
  OBTRailJourneyFare,
  OBTRailJourneyOption,
  RailEnterpriseSearchInterface,
  SearchObject
} from '@sabstravtech/obtservices/base';
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'app-wellbeing',
  templateUrl: './wellbeing.component.html',
  styleUrls: ['./wellbeing.component.scss']
})
export class WellbeingComponent extends WithSubscriptionComponent implements OnInit, OnDestroy {

  public hotelViewController: HotelViewController;
  public ServiceType: typeof ServiceType = ServiceType;

  public hotelAvalibility: {
    [key: number]: BehaviorSubject<HotelAvalibilityQuoteResult>;
  } = {};
  public searchParamsHotel: HotelEnterpriseSearchInterface;
  public searchParamsRail: RailEnterpriseSearchInterface;
  public cheapestHotel: HotelQuoteResult = null;
  public cheapestHotelAvailability: HotelAvalibilityQuoteResult = null;
  public cheapestRail: RailJourneyOption = null;
  public cheapestReturnRail: OBTRailJourneyOption = null;
  public cheapestTicket: RailTicketOption = null;
  public loadingHotels = false;


  constructor(
    public searchService: EnterpriseSearchService,
    public hotelAvalibilityService: HotelAvalibilityService,
    private helpers: HelperRoutines,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    localStorage.setItem("prevURL", window.location.href);
    this.hotelAvalibility = {};
    this.searchParamsHotel = this.searchService.searches[ServiceType.Hotel];
    this.searchParamsRail = this.searchService.searches[ServiceType.Rail];
    this.subscribe(this.searchService.searchLoadingDone, (loaded: boolean) => {
      // console.log('+++ Loading finished - grab the results +++');
      // console.log(this.searchService.searches[ServiceType.Rail].results.value);
      // console.log(this.searchService.searches[ServiceType.Hotel].results.value);
      this.findCheapestResults();
    });

    this.hotelViewController = {
      showMap: false,
      ratesUpdated: new EventEmitter(false),
      filterChange: new EventEmitter<HotelQuoteResult[]>(),
      hotelChosen: false,
      filtered: false,
      filterArray: {},
      showFilterOptions: false,
      selectedVals: [],
      nr: 0,
      hotelCodeChosen: null,
      single: false,
      closeFilterOptions: () => {
        // nada
      }
    };

    this.subscribe(this.searchParamsHotel.selectItemMapView, (hotel: any) => {
      this.hotelViewController.hotelChosen = hotel;
      this.hotelViewController.hotelCodeChosen = hotel.id;
      this.hotelViewController.showMap = true;
      this.hotelViewController.single = true;
    });
  }

  ngOnDestroy(): void {
    this.searchService.search_objects[ServiceType.Hotel].chosen = false;
    this.searchService.search_objects[ServiceType.Rail].chosen = false;
    this.searchService.search_objects[ServiceType.Rail].resultUrl = '/results/rail';
    this.searchService.search_objects[ServiceType.Hotel].resultUrl = '/results/hotels';
    this.searchService.search_objects[ServiceType.Hotel].priority = 4;
  }

  findCheapestResults(): void {
    this.loadingHotels = true;
    this.splitHotelResultsUp();
    this.findCheapestRail();

  }

  splitHotelResultsUp(startPos = 0) {
    if (startPos >= 100 || startPos >= this.searchService.searches[ServiceType.Hotel].results.value.length) {  // only ever have 100 results max
      this.loadingHotels = false;
      return;
    }

    let hotelResultsCopy = this.helpers.clone(this.searchService.searches[ServiceType.Hotel].results.value);

    hotelResultsCopy = hotelResultsCopy.slice(startPos);
    let splitHotels = this.helpers.splitUpArray(hotelResultsCopy, 5);

    splitHotels = splitHotels.slice(0, 1);
    let completedNo = 0;
    if (!splitHotels[0].length) {
      this.loadingHotels = false;
      return;
    }
    splitHotels.forEach((hotels: HotelDetails[]) => {
      hotels.forEach((hotel: HotelDetails) => {

        this.subscribe(this.hotelAvalibilityService.getAvailabilityForId(
          this.searchParamsHotel.makeHotelAvalilityObject(hotel),
          hotel
        ), data => {
          // console.log(data);
          this.hotelAvalibility[hotel.id] = data;
          completedNo++;
          if (!this.cheapestHotel && data && data.rooms.length) {
            this.cheapestHotelAvailability = data;
            this.setCheapestHotel(data.hotelId);
          } else {
            if (data?.rooms.length && data.prpn < this.cheapestHotelAvailability.prpn) {
              this.cheapestHotelAvailability = data;
              this.setCheapestHotel(data.hotelId);
            }
          }
          // console.log(this.cheapestHotelAvailability)
          // Not the most elegant solution, but it works
          if (completedNo < hotels.length) {
            let that = this;
            setTimeout(function () {
              that.splitHotelResultsUp(startPos += 10)
            }, 3000);
          }

        }, error => {
          console.error(`+++ Error loading hotel details: ${error} +++`);
        })
      });
    });
  }

  setCheapestHotel(hotelId: string): void {
    const fullHotelResults = this.searchService.searches[ServiceType.Hotel].results.value;
    this.cheapestHotel = fullHotelResults.find(hotel => {
      return hotel.id === hotelId;
    });
  }

  navigateTo(serviceType: ServiceType) {
    const searchObject: SearchObject =
      this.searchService.search_objects[serviceType];
    searchObject.booking_chosen = false;
    searchObject.chosen = true;
    localStorage.prevURL = window.location.href;
    switch (serviceType) {
      case ServiceType.Rail:
        this.searchService.highest_search_priority = 2;
        this.searchService.search_objects[ServiceType.Hotel].chosen = false;
        this.router.navigate(['/results/rail']);
        break;
      case ServiceType.Hotel:
        this.searchService.highest_search_priority = 4;
        this.searchService.search_objects[ServiceType.Rail].chosen = false;
        this.router.navigate(['/results/hotels'])
        break;
    }
  }

  findCheapestRail(): void {

    // try {
    //   const railResults = this.searchService.searches[ServiceType.Rail].results.value;

    //   let cheapest = null;
    //   let cheapestJourney = null;

    //   // console.log(railResults);

    //   railResults[0]?.outbound.forEach(outboundRail => {
    //     // console.log(outboundRail);
    //     outboundRail.providers.forEach(provider => {
    //       if (!cheapest || provider.cheapestTicket.price < cheapest.price || (provider.cheapestTicket.type.includes('Off-Peak') && !cheapest.type.includes('Off-Peak'))) {
    //         cheapest = provider.cheapestTicket;
    //         cheapestJourney = outboundRail;
    //       }

    //       provider.ticketOptions.forEach(tocket => {
    //         if (!cheapest || tocket.price < cheapest.price || (tocket.type.includes('Off-Peak') && !cheapest.type.includes('Off-Peak'))) {
    //           cheapest = tocket;
    //           cheapestJourney = outboundRail;
    //         }
    //       })
    //     });
    //   });

    //   // console.log('+++ Cheapest outbound ticket: ', cheapest, ' +++');
    //   // console.log('+++ Cheapest outbound journey: ', cheapestJourney, ' +++');
    //   // now find the appropriate return jounrey

    //   const matchingReturn = railResults[0].return.find(returnTicket => {
    //     return returnTicket.providers[0].ticketOptions.find(ticketOpt => {
    //       return ticketOpt.identifiers.fareIdentifier ===  cheapest.identifiers.fareIdentifier;
    //     })
    //   });

    //   // console.log('+++ Matching Return: ', matchingReturn, ' +++');

    //   this.cheapestRail = cheapestJourney;
    //   this.cheapestReturnRail = <OBTRailJourneyOption>matchingReturn;
    //   this.cheapestTicket = cheapest;
    // } catch (error) {
    //   console.error(`+++ Error finding cheapest rail options: `, error, ` +++`);
    // }

  }
}
