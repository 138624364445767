import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../vendor/components/base_components/base-componet';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { User, Document, } from '@sabstravtech/obtservices/angular';
import { DocTypes, Genders, PreferenceValue } from '@sabstravtech/obtservices/base';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DocType } from '@sabstravtech/obtservices/angular';
import { Helpers } from '../../vendor/classes/helpers';

@Component({
  selector: 'app-profile-information-docs',
  templateUrl: './profile-information-docs.component.html',
  styleUrls: ['./profile-information-docs.component.scss']
})
export class ProfileInformationDocsComponent extends BaseComponent implements OnInit {
  tabSelected = 0;
  faChevronDown = faChevronDown;
  faCalendar = faCalendar;
  @Input() user: User;
  @Input() docsForm: FormGroup;
  @Input() isEditable: boolean;

  newDoc = {} as Document;
  showDocBlock: number = 0;
  docType = DocType;
  docTypes: PreferenceValue[] = DocTypes;
  genders: PreferenceValue[] = Genders;

  constructor(
    title: Title,
    translateService: TranslateService,
    private fb: FormBuilder
  ) {
    super(title, translateService);
  }

  get documents() {
    return this.docsForm.controls["documents"] as FormArray;
  }

  ngOnInit(): void {
    if (this.user && !this.documents.value.length) {
      this.setValue();
    }
    if (!this.isEditable) this.docsForm.disable();
  }

  setValue() {
    this.docsForm.setValue({
      documents: []
    });

    if (this.user.documents?.length) {
      this.user.documents.forEach((document: Document) => {
        this.addDocument(document);
      });
    } else {
      // add primary Document
      this.addDocument(this.newDoc);
    }
  }

  addDocument(document: Document) {
    this.showDocBlock = this.documents.length;
    const docsForm = this.fb.group({
      docType: [document.docType ? document.docType : '', Validators.required],
      number: [document.number, Validators.required],
      dateOfIssue: [document.dateOfIssue ? Helpers.dateToNgDate(new Date(document.dateOfIssue)) : '', Validators.required],
      dateOfExpiry: [document.dateOfExpiry ? Helpers.dateToNgDate(new Date(document.dateOfExpiry)) : null, Validators.required],
      codeOfIssuingState: [document.codeOfIssuingState],
      nationality: [document.nationality],
      gender: [document.gender ? document.gender : ''],
      country: [document.country],
      primary: [document.primary ? document.primary : false]
    });
    this.documents.push(docsForm);
  }

  docTypeSelected(i: number) {
    switch (this.documents.at(i).get('docType').value) {
      case DocType.Passport:
        this.documents.at(i).get('gender').setValidators([Validators.required]);
        this.documents.at(i).get('codeOfIssuingState').setValidators([Validators.required]);
        this.documents.at(i).get('nationality').setValidators([Validators.required]);
        // clearValidators
        this.documents.at(i).get('country').clearValidators();
        break;
      case DocType.Visa:
      case DocType.IdCard:
        this.documents.at(i).get("country").setValidators([Validators.required]);
        // clearValidators
        this.documents.at(i).get('gender').clearValidators();
        this.documents.at(i).get('codeOfIssuingState').clearValidators();
        this.documents.at(i).get('nationality').clearValidators();
      default:
        this.documents.at(i).get('country').clearValidators();
        this.documents.at(i).get('gender').clearValidators();
        this.documents.at(i).get('codeOfIssuingState').clearValidators();
        this.documents.at(i).get('nationality').clearValidators();
    }

    this.documents.at(i).get('gender').updateValueAndValidity();
    this.documents.at(i).get('codeOfIssuingState').updateValueAndValidity();
    this.documents.at(i).get('nationality').updateValueAndValidity();
    this.documents.at(i).get("country").updateValueAndValidity();
  }

  deleteDocument(documentId: number) {
    this.documents.removeAt(documentId);
    this.docsForm.markAsDirty();
  }

  editDocument(documentId: number) {
    this.showDocBlock = documentId;
  }
}
