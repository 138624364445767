<!-- Flight Extras Block -->
<div class="flight-extras-container" [formGroup]="form">
  <div class="half-container">
    <div class="half-block" *ngIf="showExtras">
      <label class="header-label" id="flightExtrasLabel" i18n="@@flightExtras" for="flightExtras">
        Flight Extras:
        <i
          class="ico-info"
          *ngIf="hideExtras && extraOptions?.extras"
          i18n-ngbTooltip
          [ngbTooltip]="'Added extras is per whole booking'"></i>
      </label>
      <select
        class="select-box select-box--no-icon select-box--border"
        aria-labelledby="flightExtrasLabel"
        id="flightExtras"
        formControlName="flightExtras"
        (change)="updateFlightExtras('flightExtras')"
        [ngModel]="selectedFlightExtras.flightExtras">
        <option [ngValue]="emptyOption" i18n="@@none">None</option>
        <option *ngFor="let extra of extraOptions?.extras" [ngValue]="extra">
          {{ extra.text }} {{ extra.price | currency : extra.currency }}
        </option>
      </select>
    </div>
    <div class="half-block" *ngIf="showExtras">
      <label
        class="header-label"
        id="luggageOptionsLabel"
        i18n="@@luggageOptions"
        for="luggageOptions">
        Luggage Options:
        <i
          class="ico-info"
          *ngIf="!showTFXLuggage && extraOptions?.luggage"
          i18n-ngbTooltip
          [ngbTooltip]="'Added extras is per whole booking'"></i>
      </label>
      <select
        class="select-box select-box--no-icon select-box--border"
        aria-labelledby="luggageOptionsLabel"
        id="luggageOptions"
        formControlName="baggageAllowance"
        (change)="updateFlightExtras('baggageAllowance')"
        [ngModel]="selectedFlightExtras.baggageAllowance">
        <option [ngValue]="emptyOption" i18n="@@none">None</option>
        <option *ngFor="let luggage of extraOptions?.luggage" [ngValue]="luggage">
          {{ luggage.text }} {{ luggage.price | currency : luggage.currency }}
        </option>
      </select>
    </div>
  </div>
  <div class="half-container">
    <div class="half-block" *ngIf="showExtras">
      <label
        class="header-label"
        id="advancedAncillaryOptionsLabel"
        i18n="@@advancedAncillaryOptions"
        for="advancedAncillaryOptions">
        Advanced Ancillary Options:
        <i
          class="ico-info"
          *ngIf="hideAncillary"
          i18n-ngbTooltip
          [ngbTooltip]="'Added extras is per whole booking'"></i>
      </label>
      <select
        class="select-box select-box--no-icon select-box--border"
        aria-labelledby="advancedAncillaryOptionsLabel"
        id="advancedAncillaryOptions"
        formControlName="advancedAncillaryOptions"
        (change)="updateFlightExtras('advancedAncillaryOptions')"
        [ngModel]="selectedFlightExtras.advancedAncillaryOptions">
        <option [ngValue]="emptyOption" i18n="@@none">None</option>
        <option *ngFor="let ancillary of extraOptions?.ancillary" [ngValue]="ancillary">
          {{ ancillary.text }} {{ ancillary.price | currency : ancillary.currency }}
        </option>
      </select>
    </div>
    <div class="half-block">
      <label
        class="header-label"
        id="reducedMobillityAssistanceLabel"
        i18n="@@reducedMobillityAssistance"
        for="reducedMobillityAssistance">
        Reduced Mobillity Assistance:
      </label>
      <select
        class="select-box select-box--no-icon select-box--border"
        aria-labelledby="reducedMobillityAssistanceLabel"
        id="reducedMobillityAssistance"
        formControlName="reducedMobillityAssistance"
        (change)="updateFlightExtras('reducedMobillityAssistance')"
        [ngModel]="selectedFlightExtras.reducedMobillityAssistance">
        <option [ngValue]="emptyOption" i18n="@@none">None</option>
        <option *ngFor="let mobility of extraOptions?.mobility" [ngValue]="mobility">
          {{ mobility.text }}
          {{ mobility.price ? (mobility.price | currency : mobility.currency) : '' }}
        </option>
      </select>
    </div>
  </div>
</div>
