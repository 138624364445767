<div cdkTrapFocus>
    <div class="modal-header">
      <h3 class="modal-title">
        <span><span i18n="@@boltAdditionalInformationFor">Additional Information for </span> {{ lounge.lounge.name }}</span>
      </h3>
      <button
        type="button"
        class="close"
        aria-label="Close"
        ngbAutoFocus
        (click)="modal.close()">
        <span aria-hidden="true"><i class="ico-cancel modal_exit_cross"></i></span>
    </button>
    </div>
    <div class="modal-body">
      <mat-tab-group dynamicHeight [(selectedIndex)]="index" class="lounge-tabs">
        <mat-tab label="Lounge Info" i18n-label>
          <app-lounge-details [lounge]="lounge" [title]="title"></app-lounge-details>
        </mat-tab>
        <mat-tab label="See Photos" i18n-label>
          <app-gallery [photos]="lounge.lounge.furtherImages" [title]="title"></app-gallery>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="small-button small-button--no"
        i18n="@@fareruleOK"
        (click)="modal.close()"
      >
        Ok
      </button>
    </div>
  </div>