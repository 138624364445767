import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';
import { UserService } from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-taxi-warning-modal',
  templateUrl: './taxi-warning-modal.component.html',
  styleUrls: ['./taxi-warning-modal.component.scss']
})
export class TaxiWarningModalComponent implements OnInit {

  phoneNo = null;
  email = null;

  constructor(
    public activeModal: NgbActiveModal,
    public userService: UserService
  ) { }

  ngOnInit(): void {
    const office = this.userService.fullUserDetails.allDetails.value.office;
    this.phoneNo = `+${office.supportPhoneNumber.countryCode}${office.supportPhoneNumber.areaCode}${office.supportPhoneNumber.number}`;
    console.log(this.phoneNo);
    this.email = office.supportEmail;
  }

  
}
LightningModalTypes.TaxiWarningModalComponent.component = TaxiWarningModalComponent;