<div class="modal-header">
  <h3 class="modal-title" id="modal-header" i18n="@@offlineRequest">Offline request</h3>
  <button type="button" class="light-icon-button close" aria-label="Close" (click)="activeModal.close(false)" ngbAutoFocus>
    <i class="ico-cancel AA-standard-grey" aria-hidden="true"></i>
  </button>
</div>
<div class="modal-body">
  <p i18n="@@theOfflineBookingRequestHasBeenSent">
   {{text}} <a *ngIf="jrfReference">Reference: {{jrfReference}}</a>
  </p>
</div>
<div class="modal-footer">
  <button type="button"
          class="modal_button_okay"
          (click)="activeModal.close(true)"
          i18n="@@modalConfirmCardOK"
          aria-label="Confirm"
          ngbAutoFocus>Ok</button>
</div>