import { Pipe, PipeTransform } from '@angular/core';
import { SearchObjects } from '@sabstravtech/obtservices/base';
import { ServiceType } from '@sabstravtech/obtservices/angular';
@Pipe({
  name: 'selectedSearchObject',
})
export class SelectedSearchObjectPipe implements PipeTransform {

  transform(searches: SearchObjects): ServiceType[] {
    return Object.values(searches || {}).reduce((acc, item) => {
      if (item.originalChosen) {
        acc.push(item.type);
      }
      return acc;
    }, []);
  }
}
