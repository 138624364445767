import { ProductLoadingScreen } from './product-loading-screen';
import { EnterpriseSearchService,ServiceType } from '@sabstravtech/obtservices/angular'
import { HotelEnterpriseSearchInterface} from '@sabstravtech/obtservices/base'
import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-hotel-loading-screen',
    templateUrl: './hotel-loading-screen.component.html',
    styleUrls: [],
  })
export class HotelLoadingScreenComponent extends ProductLoadingScreen<HotelEnterpriseSearchInterface> {
    @Input() animated: boolean = true;
    constructor(service: EnterpriseSearchService, public translateService: TranslateService) {
        super(service, service.searches[ServiceType.Hotel]);
    }

    getLoadingMessage(): string {
        if (this.searchParams.location_type_select === 'Airport' && this.searchParams.location && this.searchParams.location.name) {
            return `${this.translateService.instant('Hotels near')} ${this.searchParams.location.name}`;
        } else if (this.searchParams.location_type_select === 'City') {
            if (this.searchParams.location && this.searchParams.location.name) {
                return `${this.translateService.instant('Hotels near')} ${this.searchParams.location.name}`;
                //@ts-ignore
            } else if (this.searchParams.postcode && this.searchParams.postcode.name) {
                //@ts-ignore
                return `${this.translateService.instant('Hotels near')} ${this.searchParams.postcode.name}`;
            } else {
                return `${this.translateService.instant('Hotels near your chosen')} ${this.searchParams.location_type_select}`;
            }
        }
        else {
            return `${this.translateService.instant('Hotels near your chosen')} ${this.searchParams.location_type_select}`;
        }
    }}