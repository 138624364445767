<div class="search-toggle">
  <a href="javascript:void(0)" (click)="toggle()"
  [attr.aria-label]="toggleState ? 'Hide ' + text : 'Show ' + text">
    <span>
      <span i18n="@@searchToggleState_hide" *ngIf="toggleState">Hide</span>
      <span i18n="@@searchToggleState_show" *ngIf="!toggleState">Show</span>
       {{ text | translate }}</span>
    <i [ngClass]="toggleState ? 'ico-tri-up' : 'ico-tri-down'"
        aria-hidden="true"></i>
  </a>
</div>
<ng-content *ngIf="toggleState"></ng-content>
