<div cdkTrapFocus
     aria-live="assertive">
    <div class="modal-header">
        <h3 class="modal-title"
            i18n="@@basketPriceChangeTite"
            id="modal-header">Important Information</h3>
        <button type="button"
                class="close"
                aria-label="Auto Sign Out Close"
                ngbAutoFocus
                (click)="activeModal.close()"
                (keydown.enter)="activeModal.close()"
                (keydown.space)="activeModal.close()"
                aria-describedby="signout-message">
            <span aria-hidden="true"><i class='ico-cancel modal_exit_cross'></i></span>
        </button>
    </div>
    <div class="modal-body">
      <div>
       <p >
        <span i18n="@@basketPriceChangeMessagePrice">
          During the booking process the price of your selected {{type}} has changed from
        </span>
        {{item.price | currency: item.currency}}
        <span i18n="@@basketPriceChangeMessagePriceTo">to</span>
        {{item.latestValidationPrice | currency: item.currency}}.
       </p>
       <p i18n="@@basketPriceChangeMessageContact">
        If you agree to this change of price, please select ‘Continue’ to proceed with your booking at the revised price.<br /><br/>
        If you would like to check other price options, please select ‘Cancel’ to return to the search screen.<br /><br/>
        For help please contact your CTM support team</p>
      </div>
    </div>
    <div class="modal-footer">
        <button type="button"
                class="small-button small-button--no"
                (click)="close()"
                (keydown.enter)="close()"
                (keydown.space)="close()"
                i18n="@@basketPriceChangeCancel">Cancel</button>
        <button type="button"
                id='button_ok'
                class="small-button"
                aria-label="Stay Signed In"
                aria-live="false"
                (click)="activeModal.close(true)"
                (keydown.enter)="activeModal.close(true)"
                (keydown.space)="activeModal.close(true)"
                i18n="@@basketPriceChangeContinue">Continue</button>
    </div>
</div>
