import { Pipe, PipeTransform } from '@angular/core';
import { CarHireAvailabilityDetailFetcher, InputCarHireDepot } from '@sabstravtech/obtservices/angular';
import { CarHireDetailRequestResponse } from '@sabstravtech/obtservices/angular';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Pipe({
  name: 'depotName',
  pure: true
})

export class DepotNamePipe implements PipeTransform {
  constructor(private getCarHireAvailabilityDetailFetcher: CarHireAvailabilityDetailFetcher) {}

  transform(locationCode: string, extendedLocationCode: string, vendor: string ): Observable<string> {

    let code = locationCode + extendedLocationCode;

    if (vendor && code) {
      let locationDetails = {
        extendedLocationCode: code,
        vendor: vendor
      }
      return this.getCarHireAvailabilityDetailFetcher.fetchList({ locationDetails })
      .pipe(
        take(1),
        map((results: CarHireDetailRequestResponse) => {
          if (results) {
            return ` ${results.address.name} (${results.address.city}), ${results.address.topLine}`
          }
        })
      );
    } else {
      return of('Converting...');
    }
  }

}