import { LanguageEnum, Language } from '../vendor/interfaces/language-types';
import localeEl from '@angular/common/locales/el';
import localeElExtra from '@angular/common/locales/extra/el';

export default class GreekLanguage extends Language {
  protected locale = localeEl;
  protected localeExtra = localeElExtra;
  protected details = {
    image: 'images/flags/el.png',
    title: 'Greek',
    enum: LanguageEnum.Greek,
    aira: 'Greek Selected',
    select: 'Choose Greek Language',
    abre: 'el',
  };
}
