import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import {
  FlightDirectionEnum,
  FlightEnterpriseSearchInterface,
  FlightGroupedQuoteResult,
  FlightItineraryWithExtensions,
  FlightSearchType,
  FlightTempParams
} from '@sabstravtech/obtservices/base';
import {
  UserService, WithSubscriptionComponent, EnterpriseBasketService,
  ModalOpenerService
} from '@sabstravtech/obtservices/angular';
import { resultIcons } from '../../../../vendor/enum/result-icons.enum';
import { FlightItinerary } from '@sabstravtech/obtservices/base/lib/vendor/types/graphql.types';
import { LightningModalTypes } from '../../../../vendor/classes/modal-types.enum';
import { CurrencyPipe } from '@angular/common';
import moment from 'moment';
@Component({
  selector: 'app-flight-class',
  templateUrl: './flight-class.component.html',
  styleUrls: ['./flight-class.component.scss'],
})
export class FlightClassComponent
  extends WithSubscriptionComponent
  implements OnInit {
  @Input() flightDiscriptor: FlightItineraryWithExtensions;
  @Input() ShowSelectButton: boolean = true;
  @Input() direction: FlightDirectionEnum;
  @Input() flightDetails: FlightGroupedQuoteResult;
  @Input() search: FlightTempParams;
  @Input() searchParams: FlightEnterpriseSearchInterface;
  @Output() showFlight: EventEmitter<any> = new EventEmitter<any>();

  negotiatedRate: string;
  negotiatedRateClass: string;
  resultItemType = resultIcons;
  ticketAriaLabel: string = '';
  dissableCabinClass: boolean;
  FlightDirectionEnum: typeof FlightDirectionEnum = FlightDirectionEnum;

  constructor(
    private userService: UserService,
    private basketService: EnterpriseBasketService,
    private modalService: ModalOpenerService,
    private currencyPipe: CurrencyPipe,
  ) {
    super();
  }

  ngOnInit() {
    this.ticketAriaLabel = this.getTicketAriaLabel();
    this.checkCabinClass();
  }

  checkCabinClass() {
    if (this.searchParams?.outboundCabinClass?.length && this.searchParams?.outboundCabinClass !== this.flightDiscriptor.outboundFlights[0]?.cabinClass) {
      this.dissableCabinClass = true;
    } else {
      this.dissableCabinClass = false;
    }
  }

  getTicketAriaLabel(): string {
    try {
      let flightOne = this.flightDiscriptor.outboundFlights[0];
      let flightLast = this.flightDiscriptor.outboundFlights[this.flightDiscriptor.outboundFlights.length - 1];
      const price = this.flightDiscriptor.total.price.toFixed(2);
      const priceWithCurrency = this.currencyPipe.transform(price, this.flightDiscriptor.total.currency);
      const outTime = moment(flightOne.dateTimeDeparting).format("HH:mm");
      const inTime = moment(flightLast.dateTimeArriving).format("HH:mm");
      const tktClass = flightOne.cabinClass;
      return `${priceWithCurrency} ${flightOne.marketingCarrierName} ${tktClass}. ${flightOne.originAirport} ${outTime} to ${flightLast.destinationAirport} ${inTime}`;
    } catch {
      return "Select Flight Ticket"; //Just incase it errors this is better than nothing
    }
  }

  async selectFlight(flightDiscriptor: FlightItinerary) {
    // If a different outbound and inbound fare is selected then a dialog modal should appear on screen before adding to the basket 
    if (this.direction === FlightDirectionEnum.Inbound && this.searchParams.outboundCabinClass !== flightDiscriptor.inboundFlights[0].cabinClass) {
      this.modalService.open(LightningModalTypes.UpdateSelectionDialogComponent, { centered: true }).then((result) => {
        if (result) {
          this.selectGroupedFlight(flightDiscriptor);
        }
      });
    } else {
      await this.selectGroupedFlight(flightDiscriptor);
    }
  }

  async selectGroupedFlight(flightDiscriptor: FlightItinerary) {
    // this is added for seatmaps as they are currently only available on LU
    await this.searchParams.selectGroupedFlight(
      flightDiscriptor,
      this.direction,
      this.flightDetails.gid,
      true
    );
    if (this.searchParams.chosenSearchType === FlightSearchType.oneWay || this.searchParams.chosenSearchType === FlightSearchType.multiCity || (this.searchParams.chosenSearchType === FlightSearchType.return && this.direction === FlightDirectionEnum.DualSingleInbound) || (this.searchParams.chosenSearchType === FlightSearchType.return && this.direction === FlightDirectionEnum.Inbound)) {
      this.basketService.toggleMenu();
    }
  }

  get showDump(): boolean {
    return (window as any).showDebug;
  }

  getSeatsRemaining(flight: FlightItineraryWithExtensions, direction: FlightDirectionEnum): number {
    if ((flight.outboundFlights && flight.outboundFlights[0]?.seatsAvailable) || (flight.inboundFlights && flight.inboundFlights[0]?.seatsAvailable)) {
      switch (direction) {
        case FlightDirectionEnum.DualSingleInbound:
        case FlightDirectionEnum.DualSingleOutbound:
        case FlightDirectionEnum.Outbound:
          return flight.outboundFlights[0].seatsAvailable;
        case FlightDirectionEnum.Inbound:
          return flight.inboundFlights[0].seatsAvailable;
      }
    }
  }

  showSpecialFares(flight: FlightItineraryWithExtensions, direction: FlightDirectionEnum): boolean {
    if ((flight.outboundFlights && flight.outboundFlights[0]?.fareInfo.type === 'Private') || (flight.inboundFlights && flight.inboundFlights[0]?.fareInfo.type === 'Private')) {
      switch (direction) {
        case FlightDirectionEnum.DualSingleInbound:
        case FlightDirectionEnum.DualSingleOutbound:
        case FlightDirectionEnum.Outbound:
          return flight.outboundFlights[0].fareInfo.type === 'Private';
        case FlightDirectionEnum.Inbound:
          return flight.inboundFlights[0].fareInfo.type === 'Private';
        default:
          console.log('nothing to show here');
          return false;
      }
    }
  }

}
