<div cdkTrapFocus
     aria-live="assertive">
  <div class="modal-header">
    <h3 class="modal-title"
    id="modal-header">{{title}}</h3>
    <button type="button"
            class="close"
            aria-label="Recover Password"
            id="recover-password-close"
            ngbAutoFocus
            i18n-aria-label
            (click)="activeModal.close(true)">
      <span aria-hidden="true"><i class='ico-cancel modal_exit_cross'></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p i18n="@@recoverPasswordInstructions">
      If the email address supplied corresponds to a valid account, an email containing instructions
      for setting your password will have been sent. Please wait five minutes and if you have not received it contact your support team.
    </p>

  </div>
  <div class="modal-footer">
    <button type="submit" aria-labelledby="reset-password"
            class="modal_button_okay"
            id="recover-password-ok"
            (click)="activeModal.close()"
            i18n="@@closeModal">Close
          </button>
  </div>
</div>
