import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';
import { UserService } from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-taxi-wheelchair-modal',
  templateUrl: './taxi-wheelchair-modal.component.html',
  styleUrls: ['./taxi-wheelchair-modal.component.scss']
})
export class TaxiWheelchairModalComponent implements OnInit {

  specialRequirementsText: string = '';

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  
}
LightningModalTypes.TaxiWheelchairModalComponent.component = TaxiWheelchairModalComponent;