import { registerLocaleData } from '@angular/common';

export enum LanguageEnum {
    Dutch,
    English,
    French,
    German,
    Itilian,
    Polish,
    Swedish,
    Norwegian,
    Danish,
    Greek,
    Tagalog,
    Spanish
}

export interface LanguageStruct {
    image: string;
    title: string;
    enum: LanguageEnum;
    aira: string;
    select: string;
    abre: string;
}

export abstract class Language {
    get image(): string {
        return this.details.image;
    }
    get title(): string {
        return this.details.title;
    }
    get aira(): string {
        return this.details.aira;
    }
    get select(): string {
        return this.details.select;
    }
    get abre(): string {
        return this.details.abre;
    }
    get enum(): LanguageEnum {
        return this.details.enum;
    }
    protected abstract locale: {}[];
    protected abstract localeExtra: {}[];

    register(): Language {
        registerLocaleData(this.locale, this.details.abre, this.localeExtra);
        return this;
    }
    protected abstract details: LanguageStruct;
    isLanguage(toCheck: string[]) {
        return toCheck.includes(this.details.abre);
    }
}
