import { Component, Input, OnInit } from '@angular/core';
import {
  ServiceType,
  EnterpriseSearchService
} from '@sabstravtech/obtservices/angular';

import { CarhireEnterpriseSearchInterface } from '@sabstravtech/obtservices/base';
@Component({
  selector: 'app-show-details-car',
  templateUrl: './show-details-car.component.html',
  styleUrls: ['./show-details-car.component.scss']
})
export class ShowDetailsCarComponent implements OnInit {
  @Input() detail: any;
  
  public searchParams: CarhireEnterpriseSearchInterface;
  constructor( private searchService: EnterpriseSearchService) { }

  ngOnInit(): void {
    this.searchParams = this.searchService.searches[ServiceType.Car];
  }

}
