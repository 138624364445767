import { Component, Input, OnInit } from '@angular/core';
import { CompanyType, UserService } from '@sabstravtech/obtservices/angular';
import { ErmTravellerType } from '@sabstravtech/obtservices/base';

@Component({
  selector: 'app-erm-traveller',
  templateUrl: './erm-traveller.component.html',
  styleUrls: ['./erm-traveller.component.scss']
})
export class ErmTravellerComponent implements OnInit {
  @Input() joinerOrLeaver: ErmTravellerType;
  @Input() style: string;
  ermTravellerType = ErmTravellerType;
  ermCompanyType: CompanyType;
  CompanyType: typeof CompanyType = CompanyType;
  showShip = false;
  showRig = false;
  showWindmill = false;

  constructor( 
    public userService: UserService) {}

  ngOnInit(): void {
    this.ermCompanyType = this.userService.ermCompanyType();
    if (this.ermCompanyType === CompanyType.CruiseLine || this.ermCompanyType === CompanyType.Marine) {
      this.showShip = true;
    } else if (this.ermCompanyType === CompanyType.OilGas) {
      this.showRig = true;
    } else if (this.ermCompanyType === CompanyType.Mining || this.ermCompanyType === CompanyType.Wind) {
      this.showWindmill = true;
    }
  }
}
