<section class="container custom-tooltip" (mouseleave)="tt.close()">
    <i class='ico ico-info-circle'
        i18n-ngbTooltip
        triggers="manual"
        (mouseenter)="tt.open()"
        (focus)="tt.open()"
        (blur)="tt.close()"
        #tt="ngbTooltip"
        tabindex="0"
        [ngbTooltip]="tooltipContent">
        <ng-template #tooltipContent>
            <div class="tooltiptext" [innerHTML]='tooltipText'></div>
        </ng-template>
        <div class="visuallyhidden" [innerHTML]='tooltipText'></div>
    </i>
</section>