import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'basketSorter',
  pure: false,
})
export class BasketSorterPipe implements PipeTransform {
  transform(
    value: any[],
    usePayLoad: boolean,
    property1: string,
    property2: string
  ): any {
    value = value || [];
    value.sort((fst, snd) => {
      const fstProp1 = this.getValue(fst, usePayLoad, property1);
      const sndProp1 = this.getValue(snd, usePayLoad, property1);
      if (fstProp1 < sndProp1) {
        return -1;
      }
      if (fstProp1 > sndProp1) {
        return 1;
      }
      if (property2) {
        const fstProp2 = this.getValue(fst, usePayLoad, property2);
        const sndProp2 = this.getValue(snd, usePayLoad, property2);
        if (fstProp2 < sndProp2) {
          return -1;
        }
        if (fstProp2 > sndProp2) {
          return 1;
        }
      }
      return 0;
    });
    return value;
  }

  getValue(obj, usePayLoad, property) {
    if (usePayLoad) {
      return obj.payload[property];
    } else {
      return obj[property];
    }
  }
}
