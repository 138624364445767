import { LanguageEnum, Language } from '../vendor/interfaces/language-types';
import localeDa from '@angular/common/locales/da';
import localeDaExtra from '@angular/common/locales/extra/da';

export default class DanishLanguage extends Language {
  protected locale = localeDa;
  protected localeExtra = localeDaExtra;
  protected details = {
    image: 'images/flags/da.png',
    title: 'Danish',
    enum: LanguageEnum.Danish,
    aira: 'Danish Selected',
    select: 'Choose Danish Language',
    abre: 'da',
  };
}
