<div cdkTrapFocus
     aria-live="assertive">
  <div class="modal-header">
    <h3 class="modal-title"
        i18n="@@modalWarningTitle"
        id="modal-header">Warning</h3>
    <button type="button"
            class="close"
            aria-label="An Error Occured Close"
            ngbAutoFocus
            i18n-aria-label
            (click)="activeModal.close('Cross click')">
      <span aria-hidden="true"><i class='ico-cancel modal_exit_cross'></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p i18n="@@modalWizzairWarning">Your booking has been processed and a reply is pending from Wizz Air. Response times from "Wizz Air" can be slow and the booking status will be updated accordingly once confirmation has been received. Please remain on this page until the booking status has changed. If you leave this page you can check in 10 minutes to see if the booking has been made successfully with the airline or alternatively you can view booking statuses in the My Bookings section.</p>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="modal_button_okay"
            (click)="activeModal.close(true)"
            i18n="@@modalErrorOK">Ok</button>
  </div>
</div>