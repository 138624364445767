import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../vendor/components/base_components/base-componet';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { User, UserService, ApexxCard, ApexxCardStatus, ModalOpenerService } from '@sabstravtech/obtservices/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';

@Component({
  selector: 'app-profile-information-payment',
  templateUrl: './profile-information-payment.component.html',
  styleUrls: ['./profile-information-payment.component.scss']
})
export class ProfileInformationPaymentComponent extends BaseComponent implements OnInit {
  tabSelected = 0;
  faChevronDown = faChevronDown;
  ApexxCardStatus = ApexxCardStatus;
  @Input() user: User;
  @Input() isEditable: boolean;
  cards: ApexxCard[] = [];
  editCardItem = null;
  paymentForm: FormGroup;
  constructor(
    title: Title,
    translateService: TranslateService,
    private fb: FormBuilder,
    private userService: UserService,
    private modalService: ModalOpenerService,
  ) {
    super(title, translateService);
  }

  ngOnInit(): void {
    this.getCards();
    this.subscribe(this.userService.updateApexxCard, (result:boolean)=> {
      if (result) {
        this.getCards();
      }
    })
  }

  getCards() {
    console.log('+++ getting cards: ', this.user);
    this.subscribe(this.userService.getApexxListCards(this.user.company.id, this.user.office.id, this.user.id), (cards:ApexxCard[]) => {
      if (cards?.length) {
        this.cards = cards;
      } 
    }) 
  }

  addCard(){
    this.userService.addNewApexxCard();
  }

  deleteCard(cardId: string) {
    this.subscribe( this.userService.deleteCardApexx(cardId), (result:boolean) => {
      if (result) {
        this.cards = this.cards.filter((card:ApexxCard) => card.id !== cardId);
        this.modalService.open(LightningModalTypes.ModalSuccessComponent, { centered: true }, { success: 'The card has been deleted'});
      } else {
        this.modalService.open(LightningModalTypes.ModalSuccessComponent, { centered: true }, { success: 'The card has not been deleted'});
      }
    }, () => {
      this.modalService.open(LightningModalTypes.ModalSuccessComponent, { centered: true }, { success: 'The card has not been deleted!'});
    })
  }

  editCard(card: ApexxCard) {
    this.editCardItem = card;
    this.paymentForm = this.fb.group({
      id: [card.id],
      label: [card.label ? card.label : '', Validators.required],
      status: [card.status, Validators.required],
      expires: [ card.expires ]
    });
  }

  saveCard() {
    const {id, label, status} = this.paymentForm.value;
    this.subscribe( this.userService.updateCardApexx(id, label, status), (result:ApexxCard) => {
      if (result) {
        this.modalService.open(LightningModalTypes.ModalSuccessComponent, { centered: true }, { success: 'New card details have been updated'});
        this.editCardItem = null;
        this.userService.updateApexxCard.next(true);
      } 
    })
  }
}

