import { Pipe, PipeTransform } from '@angular/core';
import { UserDefaultManagementInfoValue } from '@sabstravtech/obtservices/angular';


@Pipe({ name: 'orderMi' })
export class OrderMi implements PipeTransform {
    constructor() { }
    transform(userMis: UserDefaultManagementInfoValue[], ...args: any[]): UserDefaultManagementInfoValue[] {
        userMis.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });
        return userMis;
    }
}
