import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  calculateTimeDifference(startTimeStr: string, endTimeStr: string): string {
    const startTime = moment(startTimeStr);
    const endTime = moment(endTimeStr);
    const diff = moment.duration(startTime.diff(endTime));
    const days = Math.abs(diff.days());
    const hours = Math.abs(diff.hours());
    const minutes = Math.abs(diff.minutes());

    return `${days > 0 ? days + 'D ': ''}${hours > 0 ? hours + 'hr ': ''}${ minutes > 0 ? minutes + 'm ': ''}`;
  }
}
