import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';

@Component({
  templateUrl: './modal-rail-journey-ticket-terms.component.html',
  styleUrls: ['./modal-rail-journey-ticket-terms.component.scss']
})
export class ModalRailJourneyTicketTermsComponent {
  constructor(public activeModal: NgbActiveModal) {

  }

  public ticketTerms = [];

}
LightningModalTypes.ModalRailJourneyTicketTermsComponent.component = ModalRailJourneyTicketTermsComponent;
