<div class="modal-rail-journey-details" *ngIf="segments.length">
  <table class="modal-rail-journey-details__table">
    <thead>
      <tr>
        <th>&nbsp;</th>
        <th i18n="@@arrive">Arrive</th>
        <th i18n="@@depart">Depart</th>
        <th i18n="@@operator">Operator</th>
        <th i18n="@@callingPoints" id="calling-points-label">Calling Points</th>
      </tr>
    </thead>
    <tbody>
      <!-- loop through each segment -->

      <ng-container *ngFor="let segment of segments; let i = index">
        <tr>
          <th class="segment_depart" id="station-label-{{ i }}" scope="row">
            {{ segment.depart }}
          </th>
          <td>
            {{
              i > 0
                ? (removeTimeZone(segments[i - 1].arriveDateTime) | sabsTimePipe : 'timeString')
                : ''
            }}
          </td>
          <td>{{ removeTimeZone(segment.departDateTime) | sabsTimePipe : 'timeString' }}</td>
          <td>{{ segment.operator }}</td>
          <td>
            <button
              class="toggle-stop"
              *ngIf="segment.callingPoints.length"
              [class.active]="segment.toggleStop"
              mat-button
              (click)="segment.toggleStop = !segment.toggleStop"
              [attr.aria-expanded]="segment.toggleStop"
              id="calling-points-{{ i }}"
              attr.aria-labelledby="calling-points-{{ i }} station-label-{{
                i
              }} calling-points-label">
              {{ segment.callingPoints.length - 1 }}
            </button>
          </td>
        </tr>
        <ng-container *ngFor="let callingPoint of segment.callingPoints; index as i">
          <tr
            *ngIf="
              segment.toggleStop &&
              callingPoint.stationName !== segment.depart &&
              callingPoint.stationName !== segment.arrive
            ">
            <th scope="row" class="segment_stop">&nbsp;-&nbsp;{{ callingPoint.stationName }}</th>
            <td>
              {{ removeTimeZone(callingPoint.arrivalDateTime) | sabsTimePipe : 'timeString' }}
            </td>
            <td>
              {{ removeTimeZone(callingPoint.departureDateTime) | sabsTimePipe : 'timeString' }}
            </td>
            <td>{{ segment.operator }}</td>
            <td>&nbsp;</td>
          </tr>
        </ng-container>
      </ng-container>

      <!-- use the final segment to output the arrival name and time-->
      <tr *ngIf="segments[segments.length - 1] as finalSegment">
        <th class="segment_arrive">{{ finalSegment.arrive }}</th>
        <td>{{ removeTimeZone(finalSegment.arriveDateTime) | sabsTimePipe : 'timeString' }}</td>
        <td>&nbsp;</td>
        <td></td>
        <td>&nbsp;</td>
      </tr>
    </tbody>
  </table>
</div>
