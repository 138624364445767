<app-loading-screen *ngIf="searchService.search_loading" icon="ico-rail" aria-hidden="true" [animated]="animated">
    <span title i18n="@@irlloading_title">Just loading your search results</span>
    <div msgOne>
        <span i18n="@@irlloading_msgOne">Train For</span>
        {{ searchParams.travellingFrom.name || searchParams.travellingFrom | titlecase }}
        <span i18m="@@irlloading_from">to</span>
        {{ searchParams.travellingTo.name || searchParams.travellingTo | titlecase }}
    </div>
    <span msgTwo i18n="@@irlloading_msgTwo">Just loading your search results</span>
</app-loading-screen>
