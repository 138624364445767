<ng-template #rt let-r="result" let-t="term">
    {{ r.Name }}
</ng-template>
<ng-template #railairtemp let-r="result" let-t="term">
    {{ r.destination }}
</ng-template>

<app-flight-loading-screen [animated]="false"></app-flight-loading-screen>
<div>
    
    <app-flights-search-tabs *ngIf="!searchService.search_loading && !searchParams.isLoadingSSO"> </app-flights-search-tabs>

</div>
