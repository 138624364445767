import { Component, OnInit, Input } from '@angular/core';
import { DiscountType } from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-rail-cards',
  templateUrl: './rail-cards.component.html',
  styleUrls: ['./rail-cards.component.scss']
})
export class RailCardsComponent implements OnInit {
  DiscountType: typeof DiscountType = DiscountType;

  @Input() railcards = [];
  @Input() basketPanel = false;

  constructor() { }

  ngOnInit(): void {
    console.log(this);
  }

}
