import { Component, Inject, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';
import { RiskAlert } from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-risk-alert-detail-modal',
  templateUrl: './risk-alert-detail-modal.component.html',
  styleUrls: ['./risk-alert-detail-modal.component.scss']
})
export class RiskAlertDetailModalComponent implements OnInit {

  alert: RiskAlert = null;

  constructor(private activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
    // console.log(this.alert);
  }

  close(): void {
    this.activeModal.close();
  }

}
LightningModalTypes.RiskAlertDetail.component = RiskAlertDetailModalComponent;
