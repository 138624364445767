<div cdkTrapFocus
     aria-live="assertive"
     class="hotel-reviews-modal">
  <div class="modal-header">
    <h3 class="modal-title"
        id="modal-header"
        i18n="@@hotelReviews">Reviews</h3>
    <button type="button"
            class="close"
            attr.aria-label="Reviews Close"
            ngbAutoFocus
            (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true"><i class='ico-cancel modal_exit_cross'></i></span>
    </button>
  </div>
  <div class="modal-body" *ngIf="hotelRatingsInfo">
    <section (mouseleave)="tt.close()"
             class="container">
      <div class="hotel-rating"
           tabindex="0"
           i18n-ngbTooltip
           triggers="manual"
           ngbTooltip="This score is the average based on users who stayed at this property from your company."
           #tt="ngbTooltip"
           (mouseenter)="tt.open()"
           (focus)="tt.open()"
           (blur)="tt.close()">
        {{hotelRatingsInfo.rating?.toFixed(1).replace(".0","")}}/5
      </div>
    </section>
    <div class="review-bar-container">
      <div class="flex-row-container flex-row-container--between">
        <span class="review-bar-text" i18n="@@hotelReviewsRoom">Room</span>
        <span class="review-bar-text">{{hotelRatingsInfo.roomRating}}</span>
      </div>
      <ngb-progressbar class="hotel-review-bar" type="success" [showValue]="false" [height]="'1rem'" [value]="hotelRatingsInfo.roomRating" [max]="5"></ngb-progressbar>
    </div>
    <div class="review-bar-container">
      <div class="flex-row-container flex-row-container--between">
        <span class="review-bar-text" i18n="@@hotelReviewsValueForMoney">Value for Money</span>
        <span class="review-bar-text">{{hotelRatingsInfo.valueForMoneyRating}}</span>
      </div>
      <ngb-progressbar class="hotel-review-bar" type="success" [showValue]="false" [height]="'1rem'" [value]="hotelRatingsInfo.valueForMoneyRating" [max]="5"></ngb-progressbar>
    </div>
    <div class="review-bar-container">
      <div class="flex-row-container flex-row-container--between">
        <span class="review-bar-text" i18n="@@hotelReviewsService">Service</span>
        <span class="review-bar-text">{{hotelRatingsInfo.serviceRating}}</span>
      </div>
      <ngb-progressbar class="hotel-review-bar" type="success" [showValue]="false" [height]="'1rem'" [value]="hotelRatingsInfo.serviceRating" [max]="5"></ngb-progressbar>
    </div>
    <div class="review-bar-container">
      <div class="flex-row-container flex-row-container--between">
        <span class="review-bar-text" i18n="@@hotelReviewsCleanliness">Cleanliness</span>
        <span class="review-bar-text">{{hotelRatingsInfo.cleanlinessRating}}</span>
      </div>
      <ngb-progressbar class="hotel-review-bar" type="success" [showValue]="false" [height]="'1rem'" [value]="hotelRatingsInfo.cleanlinessRating" [max]="5"></ngb-progressbar>
    </div>
    <div class="review-bar-container">
      <div class="flex-row-container flex-row-container--between">
        <span class="review-bar-text" i18n="@@hotelReviewsFacilities">Facilities</span>
        <span class="review-bar-text">{{hotelRatingsInfo.facilitiesRating}}</span>
      </div>
      <ngb-progressbar class="hotel-review-bar" type="success" [showValue]="false" [height]="'1rem'" [value]="hotelRatingsInfo.facilitiesRating" [max]="5"></ngb-progressbar>
    </div>
    <ng-container *ngIf="hotelRatingsInfo.userTags?.length">
      <div class="hotel-tags-header" i18n="@@hotelReviewsPeopleSaidThisHotelIsGoodFor">People said this hotel is good for:</div>
      <div class="flex-row-container tag-container" *ngFor="let tag of hotelRatingsInfo.userTags">
        <fa-icon [icon]="iconLibrary.definitions.far[tag.icon]"></fa-icon>
        <span class="hotel-tag">{{tag.name}} <b>x{{tag.count}}</b></span>
      </div>
    </ng-container>
  </div>
  <div class="modal-body" *ngIf="!hotelRatingsInfo">
    Hotel ratings info unavailable. <!--The option to open this modal shouldn't be available if the hotel ratings info is undefined, but better safe than sorry-->
  </div>
</div>