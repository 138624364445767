<div cdkTrapFocus aria-live="assertive">
  <div class="modal-header">
    <h3>
      <span i18n="@@hotelSuggestionHaveYouConsidered">Have you considered a hotel?</span>
      <i  class="ico-info-circle"
          i18n-ngbTooltip
          ngbTooltip="Recommended hotel shown can be based on Company preferred, CTM preferred or the cheapest available rates."></i>
    </h3>
    <button
      type="button"
      class="close"
      aria-label="Close hotel suggestion modal"
      ngbAutoFocus
      (click)="close(false)">
      <span aria-hidden="true"><i class="ico-cancel modal_exit_cross"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <ngb-carousel [pauseOnHover]="true" [interval]="2000" *ngIf="suggestedHotels?.length">
      <ng-template ngbSlide *ngFor="let hotelDetail of suggestedHotels; let i = index">
        <div class="hotel-slide">
          <div class="flex-column-container">
            <div class="hotel-name">
              {{ hotelDetail.name }}
              <app-preferred-icons
                  [tmcPreferred]="hotelDetail.preferred?.consumer"
                  [clientPreferred]="hotelDetail.preferred?.client">
                </app-preferred-icons>
            </div>
            <p class="hotel-address">{{ getAddress(hotelDetail) }}</p>
            <p class="stars_container" *ngIf="hotelDetail.stars">
              <ng-container>
                <i
                  class="ico-star"
                  *ngIf="hotelDetail.stars >= 0.5"
                  [ngClass]="{ half_star: hotelDetail.stars === 0.5 }"
                  aria-hidden="true"></i>
                <i
                  class="ico-star"
                  *ngIf="hotelDetail.stars >= 1.5"
                  [ngClass]="{ half_star: hotelDetail.stars === 1.5 }"
                  aria-hidden="true"></i>
                <i
                  class="ico-star"
                  *ngIf="hotelDetail.stars >= 2.5"
                  [ngClass]="{ half_star: hotelDetail.stars === 2.5 }"
                  aria-hidden="true"></i>
                <i
                  class="ico-star"
                  *ngIf="hotelDetail.stars >= 3.5"
                  [ngClass]="{ half_star: hotelDetail.stars === 3.5 }"
                  aria-hidden="true"></i>
                <i
                  class="ico-star"
                  *ngIf="hotelDetail.stars >= 4.5"
                  [ngClass]="{ half_star: hotelDetail.stars === 4.5 }"
                  aria-hidden="true"></i>
                <span class="sr-only"
                  >{{ hotelDetail.stars }}&nbsp;<span i18n="@@itemHotelsStars">stars</span></span
                >
              </ng-container>
            </p>
          </div>
          <div class="image_container">
            <app-image-with-error
              title="Hotel Image"
              [logoUrl]="imageUrls[hotelDetail.id]"
              alt=""
              class="hotel_image"
              [errorUrl]="thumbs[hotelDetail.id]">
            </app-image-with-error>
            <div class="hotel-item-distance">
              <span i18n="@@itemHotelsDistance2" class="visuallyhidden">Distance</span>
              <fa-icon aria-hidden="true" [icon]="faLocationDot"> </fa-icon>
              <span class="hotel-item-distance-details"
                >{{ hotelDetail.location | switchDistanceFormat : userDistanceUnit | number : '1.1-1' }}
                <span i18n="@@itemHotelsDistanceTypes">{{ userDistanceUnit | lowercase }}</span>
              </span>
            </div>
          </div>
          <div class="flex-row-container flex-row-container--between ">
            <div class="preferred-container">
              <span
                class="flex-row-container flex-row-container--v-center"
                *ngFor="let tag of hotelDetail.sortedTags | slice : 0 : 3; let i = index">
                <section (mouseleave)="tt6.close()" class="container">
                  <img
                    src="{{ tag.icon }}"
                    height="24"
                    width="24"
                    class="tag-img"
                    ngbTooltip="{{ tag.display }}"
                    triggers="manual"
                    #tt6="ngbTooltip"
                    (mouseenter)="tt6.open()"
                    (focus)="tt6.open()"
                    (blur)="tt6.close()"
                    tabindex="0"
                    i18n-ngbTooltip
                    attr.alt="{{ tag.Text }}" />
                </section>
                <div
                  *ngIf="i == 2"
                  class="preferred-container__more"
                  (click)="open(hotelDetail)"
                  i18n="@@itemHotelsMore"
                  role="button"
                  tabindex="0"
                  (keyup.enter)="open(hotelDetail)">
                  more&hellip;
                </div>
              </span>
            </div>
            <div class="flex-column-container">
              <div class="from">From</div>
              <span class="hotel-item-price" id="price{{ hotelDetail.id }}" *ngIf="hotelDetail.availableRates"
                >{{
                  getPrice(hotelDetail.availableRates)
                    | number : '1.2-2'
                    | currency : hotelDetail.availableRates?.currencyCode : 'symbol' : '0.0-0'
                }}
              </span>
              <span *ngIf="!hotelDetail.availableRates" class="hotel-item-ico">
                <i class="button_spinner light-icon-small light-icon-inline"></i>
              </span>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-carousel>
    <!--Kicks off all the rates loading so the rates update within this modal-->
    <app-hotels hidden></app-hotels>
  </div>
  <div class="modal-footer">
    <button class="small-button margin-right-5" (click)="close(true)" id="view-all-hotel">
      View All Hotels
    </button>
    <button class="small-button" (click)="close(false)" id="skip-hotel">Skip</button>
  </div>
</div>
