import { Component, Input } from '@angular/core';
import { OBTRailJourneyOption } from '@sabstravtech/obtservices/base';
import { ModalOpenerService, WithSubscriptionComponent, HelperRoutines, RailJourneyLeg } from '@sabstravtech/obtservices/angular';
import { Helpers } from '../../../../vendor/classes/helpers';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { LightningModalTypes } from '../../../../vendor/classes/modal-types.enum';

@Component({
    selector: 'app-rail-results-header[results][departStation]',
    templateUrl: './rail-results-header.component.html',
    styleUrls: ['./rail-results-header.component.scss']
})
export class RailResultsHeaderComponent extends WithSubscriptionComponent {
    @Input() results: OBTRailJourneyOption[];
    @Input() departStation: string;
    initialDepTime: moment.Moment;
    datesAfterInitial: boolean[] = [];
    busyTimes = {};
    
    constructor(
        public translate: TranslateService, 
        private modalService: ModalOpenerService,
        private helperRoutines: HelperRoutines
    ) {
        super();
        this.makeAriaLabel = this.makeAriaLabel.bind(this);
        this.journeyTime = this.journeyTime.bind(this);
        
    }

    ngOnInit(): void { 
        this.initialDepTime = moment(this.results[0]?.departDateTime);
        console.log('initialDepTime: ', this.initialDepTime);
        this.checkDatesAfterInitial();
    }

    checkDatesAfterInitial(): void {
        this.results.forEach((result, index) => {
            // const diff = this.helperRoutines.dayDifference(moment(this.results[0].departDateTime).format('dd/mm/yyyy'), moment(result.departDateTime).format('dd/mm/yyyy'));
            const diff = moment(result.departDateTime).startOf('day').diff(moment(this.results[0].departDateTime).startOf('day'), 'day');
            console.log(`+++ Day Diff: ${diff} +++`);
            this.datesAfterInitial.push(!!diff);
            const departDateTime = moment(result.departDateTime); //Gets the time but on the current day for checking purposes
            const departTimeToday = moment().clone().hour(departDateTime.hour()).minute(departDateTime.minute()).second(departDateTime.second());
            const sixThirty = moment('06:30','HH:mm');
            const eightThirty = moment('08:30','HH:mm');
            const sixteenThirty = moment('16:30', 'HH:mm');
            const eighteen = moment('18:00','HH:mm');
            if (departTimeToday.isBetween(sixThirty, eightThirty) || departTimeToday.isBetween(sixteenThirty, eighteen)) {
                this.busyTimes[result.journeyHash] = true;
            }
        });
    }

    makeAriaLabel(journey: OBTRailJourneyOption): string {
        return [
            this.translate.instant('Depart:'),
            Helpers.formatTimeColon(journey.departDateTime),
            this.translate.instant('Arrive:'),
            Helpers.formatTimeColon(journey.arriveDateTime),
            this.translate.instant('Changes:'),
            journey.changes,
            this.translate.instant('co2 Emmissions Per passenager (kg)'),
            journey.co2PerPassenger
        ].join();
    }

    journeyTime(startTime: string, endtime: string): string {
        const duration = moment.duration(moment(endtime).diff(moment(startTime)));
        const hours = duration.hours();
        const minutes = duration.minutes();

        const toReturn = [];

        if (hours < 10) {
            toReturn.push('0' + hours);
        } else {
            toReturn.push(hours);
        }
        toReturn.push(':');

        if (minutes < 10) {
            toReturn.push('0' + minutes);
        } else {
            toReturn.push(minutes.toString());
        }

        return toReturn.join(' ');
    }

    openDetailsModal(journey: OBTRailJourneyOption) {
        this.modalService.open(
            LightningModalTypes.ModalRailJourneyDetailsComponent,
            {},
            { result: journey }
        );
    }

    checkForBus(journey: OBTRailJourneyOption) {
        let busLegs = journey.journeyLegs.filter((journeyLeg) => journeyLeg.operator === "Alternative service - BUS");
        return busLegs.length > 0;
    }
}
