import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { passwordMismatchValidator } from '../../shared/directives/password-mismatch.directive';
import { Observable } from 'rxjs';
import { LogonService } from '@sabstravtech/obtservices/angular';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ThemeService } from '../../vendor/services/theme.service';

@Component({
  selector: 'app-reset-password-modal',
  templateUrl: './reset-password-modal.component.html',
  styleUrls: ['./reset-password-modal.component.scss']
})
export class ResetPasswordModal implements OnInit {
  constructor(
    private fb: FormBuilder,
    private logonService: LogonService,
    public activeModal: NgbActiveModal,
    public themeService: ThemeService
  ) { }

  token: string = '';
  source: string = '';
  resetPasswordForm: FormGroup;
  tokenParamsSub$: Observable<string>;
  error_message: string;
  error = false;
  loading = false;
  hasTenchars = false;
  hasLowercase = false;
  hasUppercase = false;
  hasNumber = false;
  hasSpecial = false;

  get isPasswordMatching(): boolean {
    return !(
      this.resetPasswordForm.errors?.passwordMismatch &&
      (this.resetPasswordForm.touched || this.resetPasswordForm.dirty)
    );
  }

  get hasMinLengthError(): boolean {
    const passwordInput = this.resetPasswordForm.get('newPassword');
    if (this.isPasswordMatching && passwordInput.errors?.minlength) {
      return true;
    }
    return false;
  }

  get hasPatternError(): boolean {
    const passwordInput = this.resetPasswordForm.get('newPassword');
    if (this.isPasswordMatching && passwordInput.errors?.pattern) {
      return true;
    }
    return false;
  }

  ngOnInit(): void {
    this.resetPasswordForm = this.fb.group(
      {
        newPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(10),
            Validators.pattern(
              '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*-_+=\`~/\\|:;\"\'<>,.?])[A-Za-zd!@#$%^&*-_+=\`~/\\|:;\"\'<>,.?].{8,}'
            )
          ]
        ],
        newPasswordCheck: ['', [Validators.required]]
      },
      { validators: passwordMismatchValidator }
    );
  }

  resetPassword(): void {
    this.loading = true;
    const resetSuccessfully = this.logonService.resetPasswordDialog(
      this.token,
      this.resetPasswordForm.controls['newPassword'].value,
      this.source
    );

    resetSuccessfully.subscribe((data: { jwt: string; source: string; }) => {
      this.loading = false;
      if (data) {
        this.activeModal.close(data);
      }
    });
  }

  checkValidation(event: Event) {
    const password = (event.target as HTMLInputElement).value;
    this.hasTenchars = password?.length >= 10;
    this.hasLowercase = /[a-z]/.test(password);
    this.hasUppercase = /[A-Z]/.test(password);
    this.hasNumber = /\d/.test(password);
    this.hasSpecial = /[!@#$%^&*\-_+=\`~\\|:;\"\'<>,.?]/.test(password);
  }
}

LightningModalTypes.ModalPasswordResetComponent.component = ResetPasswordModal;

