import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BasketItem, IrlJourneyType } from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-basket-irl',
  templateUrl: './basket-irl.component.html',
  styleUrls: ['./basket-irl.component.scss'],
})

export class BasketIrlComponent implements OnInit {
  @Input() rail: BasketItem;
  @Input() duplicateBooking = false;
  @Output() remove: EventEmitter<void> = new EventEmitter<void>();
  @Output() openChangeModal: EventEmitter<void> = new EventEmitter<void>();
  @Input() displayItinerary = false;

  IrlJourneyType: typeof IrlJourneyType = IrlJourneyType
  constructor() { }


  ngOnInit(): void { console.log(this.rail)}
}
