import { TranslationWidth } from '@angular/common';
import { Component, Inject, Injectable, LOCALE_ID } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

const I18N_VALUES = {
  weekdays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  weekdaysFull: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  shortMonthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
};

// documentation https://ng-bootstrap.github.io/#/components/datepicker/examples#i18n
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(
    public translateService: TranslateService,
    @Inject(LOCALE_ID) public locale: string) { super(); }
  getWeekdayLabel(weekday: number): string {
    return this.translateService.instant(I18N_VALUES.weekdays[weekday - 1]);
  }
  getMonthFullName(month: number): string { return this.translateService.instant(I18N_VALUES.months[month - 1]); }
  getWeekdayShortName(weekday: number): string { return this.translateService.instant(I18N_VALUES.weekdays[weekday - 1]); }
  getMonthShortName(month: number): string { return this.translateService.instant(I18N_VALUES.shortMonthNames[month - 1]); }
  getDayAriaLabel(date: NgbDateStruct): string { return `${date.day}-${date.month}-${date.year}`; }
}

@Component({
  selector: 'ngbd-datepicker-i18n',
  templateUrl: './datepicker-i18n.html',
  providers:
    [{ provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }]  // define custom NgbDatepickerI18n provider
})
export class NgbdDatepickerI18n {
  model: NgbDateStruct;
}