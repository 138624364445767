import {
    ServiceType,
    EnterpriseSearchService,
    FlightCabinClass,
    WithSubscriptionComponent,
    FlightItinerary,
    RailSearchJourneyType,
    LocationType
} from '@sabstravtech/obtservices/angular';
import {
    FlightDisplayTypes,
    FlightSearchType,
    FlightTempParams,
    FlightEnterpriseSearchInterface,
    FlightDirectionEnum,
    FlightGroupedQuoteResult,
    EurostarEnterpriseSearchInterface,
    FlightItineraryWithExtensions,
    OBTAirportDetails
} from '@sabstravtech/obtservices/base';
import { Component, OnInit, HostListener, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { getCurrencySymbol } from '@angular/common';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { Helpers } from '../../../../vendor/classes/helpers';
import { DeviceDetector } from '../../../../vendor/services/device-detector.service';
import { Router } from '@angular/router';

import { LightningUserFavorurite } from '../../../../vendor/classes/user-favourite.enum';
import { ShowClass } from '../../../../vendor/interfaces/flight-interfaces';
import { MenuSlideEnum, FlightQuoteResultArray } from '@sabstravtech/obtservices/base';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'app-flights-search-tabs',
    templateUrl: './flight-search-tabs.component.html',
    styleUrls: ['./flight-search-tabs.component.scss'],
    // providers: [FilterFlightResultsPipe],
    animations: [
        trigger('slideUpAndDown', [
            state(MenuSlideEnum.IN, style({ transform: 'translateY(0)' })),
            transition('void => *', [style({ transform: 'translateY(100%)' }), animate(200)]),
            transition('* => void', [animate(200, style({ transform: 'translateY(50%)' }))])
        ])
    ]
})
export class FlightsSearchTabsComponent extends WithSubscriptionComponent implements OnInit {
    ServiceType: typeof ServiceType = ServiceType;
    FlightDirectionEnum: typeof FlightDirectionEnum = FlightDirectionEnum;
    show_errors: boolean;
    errorsList: any;
    flightResults$: Observable<FlightItineraryWithExtensions[]>;
    dualFlightResults$: Observable<FlightItineraryWithExtensions[]>;
    search: FlightTempParams;
    searchParams: FlightEnterpriseSearchInterface;
    eurostarSearchParams: EurostarEnterpriseSearchInterface;
    FlightDisplayTypes = FlightDisplayTypes;
    FlightSearchType = FlightSearchType;
    // public allowedClasses: any[] = [];
    // @ViewChildren(NgbInputDatepicker) datepickerList: QueryList<NgbInputDatepicker>;
    // isAllValid: boolean;
    // live_tab = false;
    // flightResultsStep = 10;
    // selectedClasses: ShowClass;
    // filter_options = {
    //     ArrTimeRange: [0, 24],
    //     DepTimeRange: [0, 24],
    //     selectedPrice: [0, 0],
    //     operators: [],
    //     stops: [],
    //     Currency: '',
    //     ArrAirports: [],
    //     DepAirports: [],
    //     onlyGreener: false
    // };

    // public filteredOutResults: FlightGroupedQuoteResult[];
    // public filteredInResults: FlightGroupedQuoteResult[];
    returnsCheapest: string = '';
    dualCheapest: string = '';

    eurostarResultsCount: number = 0;
    cheapestEurostar: number = 0;

    railResultsCount: number = 0;
    cheapestRail: number = 0;
    carbonPercentage: number = 0;
    expandedFilters: boolean = true;
    isFastTrackEnable: boolean;
    isParkingEnable: boolean;

    constructor(
        private searchService: EnterpriseSearchService,
        public deviceDetector: DeviceDetector,
        private router: Router
    ) {
        super();
    }

    ngOnInit(): void {
        this.search = this.searchService.searches[ServiceType.Flight].originalUserSearch;
        this.searchParams = this.searchService.searches[ServiceType.Flight];
        this.searchParams.bySchedule = false;
        this.eurostarSearchParams = this.searchService.searches[ServiceType.Eurostar];
        this.initResults();
        this.isFastTrackEnable = this.searchService.search_objects[ServiceType.FastTrack]?.enabled;
        //Don't display parking when departing from a city hub
        this.isParkingEnable = this.searchService.search_objects[ServiceType.Parking]?.enabled && ((this.search.departLocation as OBTAirportDetails)?.locationType === LocationType.A);

        this.subscribe(this.searchService.searches[ServiceType.Eurostar].results, ((results: any) => {
            console.log(`+++ We have ${results[0]?.outbound?.length} eurostar results +++`);
            this.eurostarResultsCount = results[0]?.outbound?.length;
            this.cheapestEurostar = results[0]?.outbound[0]?.total?.price;
            // if (results[0].inbound.length) {
            //     this.cheapestEurostar += results[0].inbound[0].total.price;
            // }
        }));

        this.subscribe(this.searchService.searches[ServiceType.Rail].results, ((results: any) => {
            console.log(results);
            this.railResultsCount = results[0]?.outbound.length;

        }));

        this.subscribe(this.searchService.searches[ServiceType.Rail].cheapest, cheapest => {

            let cheapestVar = 0;

            if (this.searchService.searches[ServiceType.Rail].chosenSearchType === RailSearchJourneyType.SingleJourney) { // single
                if (cheapest.cheapestClassTicket) {
                    const keys = Object.keys(cheapest.cheapestClassTicket);


                    keys.forEach(key => {
                        const ticket = cheapest.cheapestClassTicket[key];

                        if (!cheapestVar || (ticket.price < cheapestVar && ticket.price)) {
                            cheapestVar = ticket.price;
                        }
                    });


                }
            } else {
                // returns - shouldn't get open returns on these searches
                let cheapestDual = 0;
                let cheapestRet = cheapest.outboundCheapest.returnJourneyFares.price;   // ! - return in out/inboundcheapest is correct, but duals are not, correct in cheapestClassTicket

                try {
                    cheapestDual = cheapest.cheapestClassTicket.standardClassFare.price + cheapest.cheapestClassTicket.inboundStandardClassFare.price;
                    const cheapestFirstDual = cheapest.cheapestClassTicket.firstClassFare.price + cheapest.cheapestClassTicket.inboundFirstClassFare.price;
                    if ((cheapestFirstDual > 0 && cheapestFirstDual < cheapestDual) || cheapestFirstDual && !cheapestDual) {
                        cheapestDual = cheapestFirstDual;
                    }

                } catch (error) {
                    console.error(`+++ Error determining cheapest dual: ${error} +++`);
                }

                if (cheapestRet != 0) {
                    (cheapestDual && cheapestDual < cheapestRet) ? cheapestVar = cheapestDual : cheapestVar = cheapestRet;
                } else {
                    cheapestVar = cheapestDual;
                }

            }
            this.cheapestRail = cheapestVar;
        });
    }

    private initResults() {
       this.getFlightResults();
        this.dualFlightResults$ = this.searchParams.results.pipe(
            switchMap((results: FlightQuoteResultArray): Observable<FlightItineraryWithExtensions[]> => {
                if (!results.length) return of([]);
                const flights = results[0] ? results[0]['multi'] : results['multi'];
                if (flights[0]?.minPrice && flights[1]?.minPrice) {
                    this.dualCheapest = this.getCheapest(FlightDisplayTypes.dualSingles);
                }
                return of(flights);
            })
        );
    }

    getFlightResults() {
        this.flightResults$ = this.searchParams.results.pipe(
            switchMap((results: FlightQuoteResultArray): Observable<FlightItineraryWithExtensions[]> => {
                if (!results.length) return of([]);
                const displayType = this.searchParams.choosenDisplayType.value;
                this.searchParams.bySchedule = (displayType === FlightDisplayTypes.bySchedule);

                let flights: FlightItineraryWithExtensions[];
                if (this.searchParams.choosenDisplayType.value === FlightDisplayTypes.bySchedule) { 
                    flights = results[0] ? results[0]['searchBySchedule'] : results['searchBySchedule'];
                } else {
                    flights = results[0] ? results[0]['journeys'] : results['journeys'];
                }
                if (flights.length) {
                    this.returnsCheapest = this.getCheapest(FlightDisplayTypes.returns);
                }
                return of(flights);
            })
        );
    }

    filterResults(results: FlightGroupedQuoteResult[], direction: FlightDirectionEnum) {
        return (results || []);
    }

    closeValidationErrors(): void {
        this.show_errors = false;
    }

    async reSearch() {
        this.searchParams.resetSelectedFlight();
        this.searchService.search_objects[ServiceType.Flight].chosen = true;

        this.searchService.determineHighestSearchPriority();
        await this.searchService.simultaneousSearchesCheck(true);
        this.searchService.startSearches();
        this.search = this.searchService.searches[ServiceType.Flight].originalUserSearch;
    }

    valueCalculated(percentage: number): void {
        this.carbonPercentage = percentage;
    }

    getCheapest(type: FlightDisplayTypes): string {
        const cheapestPriceObject = this.searchParams.cheapestPriceObject;
        switch (type) {
            case FlightDisplayTypes.returns:
                return (cheapestPriceObject.returns?.amount !== Number.MAX_SAFE_INTEGER ?
                    ' ' + getCurrencySymbol(cheapestPriceObject.returns?.currencyCode, 'narrow')
                    + cheapestPriceObject.returns?.amount.toFixed(2) : '');
            case FlightDisplayTypes.dualSingles:
                let currencySymbol = '';
                if (cheapestPriceObject.dualInbound.currencyCode === cheapestPriceObject.dualOutbound.currencyCode) { //Don't display currency if it's mixed for dual singles
                    currencySymbol = ' ' + getCurrencySymbol(cheapestPriceObject.dualOutbound?.currencyCode, 'narrow');
                }
                if (cheapestPriceObject.dualOutbound.amount !== Number.MAX_SAFE_INTEGER && cheapestPriceObject.dualInbound.amount !== Number.MAX_SAFE_INTEGER) {
                    return currencySymbol + (cheapestPriceObject.dualOutbound.amount + cheapestPriceObject.dualInbound.amount).toFixed(2);
                } else if (cheapestPriceObject.dualOutbound.amount !== Number.MAX_SAFE_INTEGER) {
                    return currencySymbol + cheapestPriceObject.dualOutbound.amount.toFixed(2);
                }
                return '';
            default:
                return '';
        }
    }

    // old

    // ngOnInit(): void {
    //         this.selectedClasses = this.getClassesToShow();//old
    //         this.subscribe(this.searchParams.results, (results: FlightQuoteResultArray) => {

    //                 let haveDualOut = false;
    //                 let haveDualIn = false;
    //                 for (let i = 0; i < results.length; i++) {
    //                     const returnResult = <FlightGroupedQuoteResult>results[i];
    //                     if (returnResult.direction === FlightDirectionEnum.DualSingleOutbound) {
    //                         haveDualOut = true;
    //                     }

    //                     if (returnResult.direction === FlightDirectionEnum.DualSingleInbound) {
    //                         haveDualIn = true;
    //                     }
    //                 }

    //                 if (this.searchParams.chosenSearchType === FlightSearchType.return) {
    //                     this.returnsCheapest = this.getCheapest(FlightDisplayTypes.returns);
    //                     if (haveDualOut && haveDualIn) {
    //                         this.dualCheapest = this.getCheapest(FlightDisplayTypes.dualSingles);
    //                     }

    //                 }
    //             this.selectedClasses = this.getClassesToShow();//old
    //         });
    // getCheapest(type: FlightDisplayTypes): string {
    //     const cheapestPriceObject = this.searchParams.cheapestPriceObject;

    //     switch (type) {
    //         case FlightDisplayTypes.returns:
    //             return (cheapestPriceObject.returns.amount !== Number.MAX_SAFE_INTEGER ? ' ' + getCurrencySymbol(cheapestPriceObject.returns.currencyCode, 'narrow')
    //                 + cheapestPriceObject.returns.amount : '');
    //         case FlightDisplayTypes.dualSingles:
    //             let currencySymbol = '';
    //             if (cheapestPriceObject.dualInbound.currencyCode === cheapestPriceObject.dualOutbound.currencyCode) { //Don't display currency if it's mixed for dual singles
    //                 currencySymbol = ' ' + getCurrencySymbol(cheapestPriceObject.returns.currencyCode, 'narrow');
    //             }

    //             if (cheapestPriceObject.dualOutbound.amount !== Number.MAX_SAFE_INTEGER && cheapestPriceObject.dualInbound.amount !== Number.MAX_SAFE_INTEGER) {
    //                 return currencySymbol
    //                     + (cheapestPriceObject.dualOutbound.amount + cheapestPriceObject.dualInbound.amount);
    //             } else if (cheapestPriceObject.dualOutbound.amount !== Number.MAX_SAFE_INTEGER) {
    //                 return currencySymbol
    //                     + cheapestPriceObject.dualOutbound.amount;
    //             }

    //             return '';
    //         default:
    //             return '';
    //     }
    // }
    // getClassesToShow(): ShowClass {
    //     const selectedOutboundClass = this.search.cabinClass as unknown as FlightCabinClass;
    //     const selectedInboundClass = this.search.returnCabinClass as unknown as FlightCabinClass;

    //     const ioShowClassObj: ShowClass = {
    //         out: {
    //             showFirst:
    //                 selectedOutboundClass === FlightCabinClass.First ||
    //                 selectedOutboundClass === FlightCabinClass.Any,
    //             showBusiness:
    //                 selectedOutboundClass === FlightCabinClass.Business ||
    //                 selectedOutboundClass === FlightCabinClass.Any,
    //             showPremium:
    //                 selectedOutboundClass === FlightCabinClass.PremiumEconomy ||
    //                 selectedOutboundClass === FlightCabinClass.Any,
    //             showEconomyNoBags:
    //                 selectedOutboundClass === FlightCabinClass.Economy ||
    //                 selectedOutboundClass === FlightCabinClass.Any,
    //             showEconomyFlexi:
    //                 selectedOutboundClass === FlightCabinClass.Economy ||
    //                 selectedOutboundClass === FlightCabinClass.Any,
    //             showEconomyRestricted:
    //                 selectedOutboundClass === FlightCabinClass.Economy ||
    //                 selectedOutboundClass === FlightCabinClass.Any
    //         },
    //         in: {
    //             showFirst:
    //                 selectedInboundClass === FlightCabinClass.First ||
    //                 selectedInboundClass === FlightCabinClass.Any,
    //             showBusiness:
    //                 selectedInboundClass === FlightCabinClass.Business ||
    //                 selectedInboundClass === FlightCabinClass.Any,
    //             showPremium:
    //                 selectedInboundClass === FlightCabinClass.PremiumEconomy ||
    //                 selectedInboundClass === FlightCabinClass.Any,
    //             showEconomyNoBags:
    //                 selectedInboundClass === FlightCabinClass.Economy ||
    //                 selectedInboundClass === FlightCabinClass.Any,
    //             showEconomyFlexi:
    //                 selectedInboundClass === FlightCabinClass.Economy ||
    //                 selectedInboundClass === FlightCabinClass.Any,
    //             showEconomyRestricted:
    //                 selectedInboundClass === FlightCabinClass.Economy ||
    //                 selectedInboundClass === FlightCabinClass.Any
    //         }
    //     };

    //     ioShowClassObj.in.numToShow =
    //         (ioShowClassObj.in.showFirst ? 1 : 0) +
    //         (ioShowClassObj.in.showBusiness ? 1 : 0) +
    //         (ioShowClassObj.in.showPremium ? 1 : 0) +
    //         (ioShowClassObj.in.showEconomyNoBags ? 1 : 0) +
    //         (ioShowClassObj.in.showEconomyRestricted ? 1 : 0) +
    //         (ioShowClassObj.in.showEconomyFlexi ? 1 : 0);
    //     ioShowClassObj.out.numToShow =
    //         (ioShowClassObj.out.showFirst ? 1 : 0) +
    //         (ioShowClassObj.out.showBusiness ? 1 : 0) +
    //         (ioShowClassObj.out.showPremium ? 1 : 0) +
    //         (ioShowClassObj.out.showEconomyNoBags ? 1 : 0) +
    //         (ioShowClassObj.out.showEconomyRestricted ? 1 : 0) +
    //         (ioShowClassObj.out.showEconomyFlexi ? 1 : 0);

    //     return ioShowClassObj;
    // }

    // filterResults(results: FlightGroupedQuoteResult[], direction: FlightDirectionEnum) {
    //     return (results || []).filter(
    //         (result: FlightGroupedQuoteResult) => result.direction === direction
    //     );
    // }

    // skipToResultContent() {
    //     if (document.getElementById('flight-container-1')) {
    //         const focusable = document
    //             .getElementById('flight-container-1')
    //             .querySelectorAll(
    //                 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    //             );
    //         // @ts-ignore
    //         focusable[0].focus();
    //     } else if (document.getElementById('flight-container-2')) {
    //         const focusable = document
    //             .getElementById('flight-container-2')
    //             .querySelectorAll(
    //                 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    //             );
    //         // @ts-ignore
    //         focusable[0].focus();
    //         return false;
    //     }
    //     return false;
    // }


    // dont use

    // switchToRailResults(): void {
    //     this.router.navigate(['results', ServiceType.Rail]);
    // }

    // findLowestRailPrice(list, ticketType: string): number {
    //     const value = list.reduce((currentInner, item) => {
    //         return item.Tickets.reduce((current, ticket) => {
    //             if (ticket.SingleOrReturn === ticketType) {
    //                 return Math.min(current, Number(ticket.AdtPrice));
    //             }
    //             return current;
    //         }, currentInner);
    //     }, Number.MAX_SAFE_INTEGER);

    //     return value === Number.MAX_SAFE_INTEGER ? 0 : value;
    // }

    // validPrice(price): boolean {
    //     return price < Number.MAX_SAFE_INTEGER && price > 0;
    // }

    // @HostListener('document:click', ['$event'])
    // public onClick(event) {
    //     Helpers.closeOpenCalendars(this.datepickerList, event);
    // }

    // switchTab(): void {
    //     // TODO: code for controlling nested tabs Outbound/Inbound & Legs
    // }

    // switchTicketType(type): void { }

    // ensureElementIsScrolledTo(event) {
    //     try {
    //         const typeAheadList = event.target.nextElementSibling;
    //         const activeButton = typeAheadList.getElementsByClassName('active')[0];
    //         if (
    //             activeButton.offsetTop + activeButton.clientHeight >
    //             typeAheadList.clientHeight + typeAheadList.scrollTop
    //         ) {
    //             typeAheadList.scrollTop =
    //                 activeButton.offsetTop + activeButton.clientHeight - typeAheadList.clientHeight;
    //         } else if (activeButton.offsetTop < typeAheadList.scrollTop) {
    //             typeAheadList.scrollTop = activeButton.offsetTop;
    //         }
    //     } catch (e) {
    //         // tslint:disable-next-line: quotemark
    //         console.log("Couldn't find elements to scroll");
    //     }
    // }

    // clearFilters() {
    //     const clearButton = document.getElementById('flight-filters-clear');
    //     if (clearButton) {
    //         clearButton.click();
    //     }
    // }

    switchToRail() {
        this.searchService.search_objects[ServiceType.Flight].chosen = false;
        const searchObj = this.searchService.init_search_objects();
        const railResultUrl = searchObj.RAIL.resultUrl;
        this.searchService.search_objects[ServiceType.Rail].priority = searchObj.RAIL.priority;
        this.searchService.search_objects[ServiceType.Rail].resultUrl = railResultUrl;
        this.router.navigate([railResultUrl]);
    }

    applyFastTrackSearch() {
        this.searchService.search_objects[ServiceType.Flight].chosen = false;
        const searchObj = this.searchService.init_search_objects();
        this.updateFastTrackSearchParams();
        this.searchService.updateTravellerInformation();
        const fastTrackResultURL = searchObj.FAST_TRACK.resultUrl;
        this.searchParams.fasttrackFromFlightSearchPerformed = true;
        this.searchService.searches[ServiceType.FastTrack].startSearch().then(() => {

            this.router.navigate([fastTrackResultURL]);
        });
    }

    applyParkingSearch() {
        this.searchService.search_objects[ServiceType.Flight].chosen = false;
        const searchObj = this.searchService.init_search_objects();
        this.updateParkingSearchParams();
        this.searchService.updateTravellerInformation();
        const parkingResultURL = searchObj.PARKING.resultUrl;
        this.searchParams.parkingFromFlightSearchPerformed = true;
        this.searchService.searches[ServiceType.Parking].startSearch().then(() => {

            this.router.navigate([parkingResultURL]);
        });
    }

    updateFastTrackSearchParams() {
        const fastTrackSearchParams = this.searchService.searches[ServiceType.FastTrack];
        fastTrackSearchParams.reset();
        fastTrackSearchParams.locationCode = this.searchParams.departLocation;
        fastTrackSearchParams.arrivalDatetime = this.searchParams.outBoundDate;
        this.searchService.search_objects[ServiceType.FastTrack].chosen = true;
    }

    updateParkingSearchParams() {
        const parkingSearchParams = this.searchService.searches[ServiceType.Parking];
        parkingSearchParams.reset();
        parkingSearchParams.locationCode = this.searchParams.departLocation;
        parkingSearchParams.arrivalDatetime = this.searchParams.outBoundDate;
        parkingSearchParams.departDatetime = this.searchParams.inboundDate;
        this.searchService.search_objects[ServiceType.Parking].chosen = true;
    }

    switchToHotel() {
        this.searchService.searches[ServiceType.Hotel].backToType = ServiceType.Flight;
        this.searchService.search_objects[ServiceType.Flight].chosen = false;
        const searchObj = this.searchService.init_search_objects();
        const hotelResultUrl = searchObj.HOTEL.resultUrl;
        this.searchService.search_objects[ServiceType.Hotel].priority = searchObj.HOTEL.priority;
        this.searchService.search_objects[ServiceType.Hotel].resultUrl = hotelResultUrl;
        this.router.navigate([hotelResultUrl]);
    }

}
