<div class="profile_information--mi-container">  
        <div class="profile_information--input-container">
                <div class="profile_information--input-label">{{title}}</div>
                <input placeholder="{{placeholder}}" i18n-placeholder [(ngModel)]="value" class="profile_information--input"/>
               
        </div>
        <!-- <button type="button" *ngIf="value.length && (miItem.managementInfo?.valueMask && miItem.managementInfo?.valueMask === value || !miItem.managementInfo?.valueMask)" (click)="addNewMiValue()" class="small-button" i18n="@@ProfileInformation-AddNew">Add New</button> -->
</div>
 <!-- <div *ngIf="miItem.managementInfo?.valueMask && value.length && miItem.managementInfo?.valueMask !== value" class="profile_information--error-message" >
        <span i18n="@@profile-information-value-does-not-match-with-the-regex">Value does not match with the regex </span>{{miItem.managementInfo?.valueMask}}
</div> -->
