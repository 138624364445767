<div
  class="cookie-law-wrapper"
  learnMore="{{ ctmPrivacyUrl }}"
  *ngIf="!isRadius && !consentGiven && themeService.isLightningEnv">
  <div class="copy">
    <span>
      <span i18n="@@cookie-1"
        >We use cookies to enable us to provide visitors with the best user experience possible.
        Read more about our
      </span>
      <a
        class="cookie_link"
        href="https://www.travelctm.co.uk/cookie-policy/"
        target="_blank"
        i18n="@@cookieB-1"
        id="aria-sabstt-cookie-policy-button"
        >Cookie Policy</a
      >
      or
      <a
        [href]="ctmPrivacyUrl"
        class="cookie_link"
        target="_blank"
        i18n="@@cookieB-2"
        id="aria-sabstt-privacy-policy-button"
        >Privacy Policy.</a
      >
      <span i18n="@@cookie-2">
        To continue using this site please provide your consent by clicking the 'OK' button.
      </span>
      <button
        class="button myDismiss"
        id="cookie-close-button"
        aria-label="OK - Close cookie policy layer"
        (click)="dismiss()">
        OK
      </button>
    </span>
  </div>
</div>

<div
  learnMore="https://www.radiustravel.com/privacy-policy/"
  *ngIf="isRadius && !consentGiven && themeService.isLightningEnv">
  <span i18n="@@cookie-1"
    >We use cookies to enable us to provide visitors with the best user experience possible. Read
    more about our
  </span>
  <a
    href="https://www.radiustravel.com/privacy-policy/"
    target="_blank"
    style="color: #99caff"
    i18n="@@cookieB-3"
    id="aria-sabstt-cookie-policy-button"
    >Privacy Policy</a
  >
  <span i18n="@@cookie-2">
    To continue using this site please provide your consent by clicking the 'OK' button.
  </span>
  <button
    class="button"
    id="cookie-close-button"
    aria-label="OK - Close cookie policy layer"
    (click)="dismiss()">
    OK
  </button>
</div>

<!-- show when logged in only -->
<div
  *ngIf="themeService.isScionEnv && !consentGiven && canShowCookieScion"
  class="cookie-law-wrapper">
  <div class="copy">
    <span name="cookie_law_1" id="logon-cookie-law">
      <span i18n="@@cookie-1"
        >We use cookies to enable us to provide visitors with the best user experience possible.
        Read more about our
      </span>
      <span>
        <a
          [href]="privacyURL"
          target="_blank"
          style="color: #99caff"
          i18n="@@cookieB-2"
          id="aria-sabstt-privacy-policy-button"
          >Privacy Policy.</a
        ></span
      >
      <span i18n="@@cookie-2">
        To continue using this site please provide your consent by clicking the 'OK' button.
      </span>
    </span>
  </div>
  <button
    class="button"
    id="cookie-close-button"
    aria-label="Close cookie policy layer"
    (click)="dismiss()">
    OK
  </button>
</div>
