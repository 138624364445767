import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectFilter } from '@sabstravtech/obtservices/base'
import { WithSubscriptionComponent } from '@sabstravtech/obtservices/angular'

@Component({
  selector: 'app-select-filter',
  templateUrl: './select-filter.component.html',
  styleUrls: ['./select-filter.component.scss']
})
export class SelectFilterComponent extends WithSubscriptionComponent {
  @Input() items: Record<number | string, SelectFilter> = {}
  @Input() alphabetical: boolean = false;
  @Input() inbound: boolean = false;
  @Output() change = new EventEmitter();

  constructor() { super() }

  ngOnInit(): void {
  }

  arrangeAlphabetically(filters): any[] {
    return (filters.sort((a: any, b: any) => {
      if (a.value.display < b.value.display) {
        return -1;
      } else if (a.value.display > b.value.display) {
        return 1;
      } else {
        return 0;
      }
    }))
  }

}
