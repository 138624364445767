import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {
  Traveller,
  BookableBasket,
  BookingStatus,
  HotelEnterpriseSearchInterface,
  CarhireEnterpriseSearchInterface,
  CabHireEnterpriseSearchInterface,
  FlightEnterpriseSearchInterface,
  RailEnterpriseSearchInterface,
  EurostarEnterpriseSearchInterface,
  IRLEnterpriseSearchInterface,
  LoungesEnterpriseSearchInterface,
  ParkingEnterpriseSearchInterface,
  FastTrackEnterpriseSearchInterface
} from '@sabstravtech/obtservices/base';
import {
  EnterpriseSearchService,
  CarbonAllowance,
  EnterpriseBasketService,
  HelperRoutines,
  User,
  WithSubscriptionComponent,
  ServiceType,
  CarbonPolicySource
} from '@sabstravtech/obtservices/angular';
import { Subscription, zip } from 'rxjs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import moment from 'moment';

@Component({
  selector: 'app-carbon-allowance-container',
  templateUrl: './carbon-allowance-container.component.html',
  styleUrls: ['./carbon-allowance-container.component.scss']
})
export class CarbonAllowanceContainerComponent extends WithSubscriptionComponent implements OnInit, OnDestroy {
  @Input() travellers: Traveller[] | User[] = [];
  @Input() serviceTypeProvided: ServiceType = null;
  @Input() outboundDate: string;
  @Input() co2PerItem: number;
  @Input() basket = null;
  hideAllowance = false;
  policies: CarbonAllowance[] = [];
  routeSub: Subscription;
  bookableBasket: BookableBasket;
  currentId: string;
  CarbonPolicySource: typeof CarbonPolicySource = CarbonPolicySource;
  constructor(
    private searchService: EnterpriseSearchService,
    private basketService: EnterpriseBasketService,
    private helpers: HelperRoutines,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super();
    this.bookableBasket = new BookableBasket(basketService, helpers);
  }

  ngOnInit(): void {
    console.log(this);
    this.routeSub = this.route.paramMap.subscribe((params: ParamMap) => {
      console.log('CarbonComponent route data: ', params);
      const id = params.get('id');
      const oldId = this.currentId;
      this.currentId = id;
      if (id && id !== oldId && !this.serviceTypeProvided && !this.outboundDate) {
        // we are on the itinerary page - grab the basket and subscribe to the complete events
        this.bookableBasket.loadBasket(this.currentId);
        this.subscribe(this.bookableBasket.status, (status: BookingStatus) => {
          if (status === BookingStatus.Complete) {
            // reload the details
            console.warn('+++ Booking Complete - Reload the policies +++');
            this.loadPolicies();
          }
        });


      }
    });
    this.subscribe(this.searchService.newSearchStarted, (search: boolean) => {
      if (search) {
        // reload the details
        console.warn('+++ new search started - Reload the policies +++');
        this.loadPolicies();
      }
    });

    // need to add all of the subs together
    this.loadPolicies();
  }

  hidePolicy() {
    this.hideAllowance = !this.hideAllowance;
  }

  get displayBackToWellbeing(): boolean {
    return this.router.url.toLowerCase().includes('/results/') && !this.router.url.toLowerCase().includes('wellbeing') && localStorage.prevURL && localStorage.prevURL.includes('wellbeing');
  }

  get displayBackToPrevious(): boolean {
    return window.location.href.includes('/results/') && window.location.href.includes('wellbeing');
  }

  back(toWellbeing = false) {

    if (toWellbeing) {
      this.router.navigate(['/results/wellbeing']);
      return; // break out to prevent going back to the search page
    }

    if (this.router.url.toLowerCase().includes('wellbeing')) {
      localStorage.prevURL = window.location.href;
      this.searchService.searches[ServiceType.Rail].outBoundDateTime = this.searchService.searches[ServiceType.Rail].originalUserSearch.outBoundDateTime;
      this.searchService.searches[ServiceType.Rail].inBoundDateTime.set('date', this.searchService.searches[ServiceType.Rail].originalUserSearch.outBoundDateTime.date());

      this.searchService.startSearches();
    }

    window.history.back();
  }

  loadPolicies(): void {
    const calls = [];
    const rawPolicies = [];
    let serviceType: ServiceType = this.serviceTypeProvided ? this.serviceTypeProvided : this.searchService.searchTypes.find(type => this.router.url.includes(type.toLowerCase().split('_')[0]));
    // only taxi isn't cab
    if (this.router.url.includes('taxis')) {
      serviceType = ServiceType.Cab;
    }
    if (this.router.url.includes('europeanrail')) {
      serviceType = ServiceType.InternationalRail;
    }
    const userSearchDate: string = this.outboundDate ? moment(this.outboundDate).format('YYYY-MM-DD') : this.getSearchDate(serviceType);

    this.travellers.forEach(traveller => {
      if (!traveller.guest && userSearchDate) {
        calls.push(this.searchService.getUserCarbonAllowance(traveller.id, userSearchDate));
      }
    });

    this.subscribe(zip(...calls), (data: CarbonAllowance[]) => {
      rawPolicies.push(...data.filter(policy => { return policy !== null; }));
    },
      error => {
        console.error(`+++ Error recovering carbon policies: ${error} +++`);
      },
      () => {
        console.log('done');
        console.log(rawPolicies);

        // to do - account for multiples of the same policy

        const companyPolicies = {};
        const officePolicies = {};
        const userPolicies = {};
        const divisionPolicies = {};

        rawPolicies.forEach((policy: CarbonAllowance) => {
          switch (policy.carbonPolicySource) {
            case CarbonPolicySource.Company:
              companyPolicies[policy.company.id] = policy;
              break;
            case CarbonPolicySource.Office:
              officePolicies[policy.office.id] = policy;
              break;
            case CarbonPolicySource.User:
              userPolicies[policy.user.id] = policy;
              break;
            case CarbonPolicySource.Division:
              divisionPolicies[policy.division.id] = policy;
              break;
            default:
              console.warn(`+++ Unknown policy source: ${policy.carbonPolicySource} +++`);
          }
        });

        this.policies = <CarbonAllowance[]>Object.values(companyPolicies)
          .concat(Object.values(officePolicies))
          .concat(Object.values(divisionPolicies))
          .concat(Object.values(userPolicies));
      }
    );

    // zip(...calls).subscribe(

    // )
  }
  getSearchDate(serviceType: ServiceType): string {
    switch (serviceType) {
      case ServiceType.Hotel:
        return (<HotelEnterpriseSearchInterface>this.searchService.searches[serviceType]).checkin_date.format('YYYY-MM-DD');
      case ServiceType.Car:
        return (<CarhireEnterpriseSearchInterface>this.searchService.searches[serviceType]).pickup_datetime.format('YYYY-MM-DD');
      case ServiceType.Cab:
        return (<CabHireEnterpriseSearchInterface>this.searchService.searches[serviceType]).departs.format('YYYY-MM-DD');
      case ServiceType.Flight:
        return (<FlightEnterpriseSearchInterface>this.searchService.searches[serviceType]).outBoundDate.format('YYYY-MM-DD');
      case ServiceType.Rail:
        return (<RailEnterpriseSearchInterface>this.searchService.searches[serviceType]).outBoundDateTime.format('YYYY-MM-DD');
      case ServiceType.Eurostar:
        return (<EurostarEnterpriseSearchInterface>this.searchService.searches[serviceType]).outBoundDate.format('YYYY-MM-DD');
      case ServiceType.InternationalRail:
        return (<IRLEnterpriseSearchInterface>this.searchService.searches[serviceType]).outBoundDateTime.format('YYYY-MM-DD');
      case ServiceType.Lounge:
        return (<LoungesEnterpriseSearchInterface>this.searchService.searches[serviceType]).arrivalDatetime.format('YYYY-MM-DD');
      case ServiceType.Parking:
        return (<ParkingEnterpriseSearchInterface>this.searchService.searches[serviceType]).arrivalDatetime.format('YYYY-MM-DD');
      case ServiceType.FastTrack:
        return (<FastTrackEnterpriseSearchInterface>this.searchService.searches[serviceType]).arrivalDatetime.format('YYYY-MM-DD');
      default:
        console.warn('unknown travel type');
        break;
    }
  }
}
