import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloLink, DefaultOptions, InMemoryCache } from '@apollo/client/core';
import { EnterpriseSearchService, Environment, WebTokenService } from '@sabstravtech/obtservices/angular';
import { environment } from '../../environments/environment';
import { HttpLink } from 'apollo-angular/http';
import { LightningEnterpriseSearchService } from '../vendor/services/search.service';

const PROVIDERS = [
  { provide: Environment, useValue: environment },
  {
    provide: EnterpriseSearchService,
    useClass: LightningEnterpriseSearchService
  },
  {
    provide: APOLLO_OPTIONS,

    useFactory: (httpLink: HttpLink, webTokenService: WebTokenService) => {
      const defaultOptions: DefaultOptions = {
        watchQuery: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'ignore'
        },
        query: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all'
        }
      };

      const http = httpLink.create({
        uri: environment.requestURL
      });
      const middleware = new ApolloLink((operation, forward) => {
        operation.setContext({
          headers: webTokenService.getHeaders()
        });
        return forward(operation);
      });

      const link = middleware.concat(http);
      return {
        cache: new InMemoryCache(),
        link,
        connectToDevTools: true,
        defaultOptions: defaultOptions
      };
    },
    deps: [HttpLink, WebTokenService]
  }
];

@NgModule({
  providers: PROVIDERS
})
export class OverRiddenModule {}
