<div cdkTrapFocus aria-live="assertive">
    <div class="modal-header">
        <h3 class="modal-title" i18n="@@NewVersionTitle" id="modal-header">
            New Version of Lightning
        </h3>
        <button
            type="button"
            class="close"
            aria-label="Close"
            ngbAutoFocus
            (click)="activeModal.close()">
            <span aria-hidden="true"><i class="ico-cancel modal_exit_cross"></i></span>
        </button>
    </div>
    <div class="modal-body modal_body_ticket" >
        <span i18n="@@NewVersionbody1">Great news!!! Your Lightning version has been updated. </span>
        <span i18n="@@NewVersionbody2">Select "OK" to reload the app.</span>
    </div>
    <div class="modal-footer">
        <button type="button"
        (click)="activeModal.close()"
                class="modal_button_okay"
                i18n="@@newversion">Ok</button>
    </div>
</div>
