import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';
import { WithSubscriptionComponent } from '@sabstravtech/obtservices/angular';

@Component({
    selector: 'app-update-selection',
    templateUrl: './update-selection-dialog.component.html',
    styleUrls: ['./update-selection-dialog.component.scss']
})
export class UpdateSelectionDialogComponent extends WithSubscriptionComponent {
    constructor(
        public activeModal: NgbActiveModal) {
        super()
    }
}

LightningModalTypes.UpdateSelectionDialogComponent.component = UpdateSelectionDialogComponent;

