<div cdkTrapFocus
     aria-live="assertive">
  <div class="modal-header">
    <h3 class="modal-title"
        i18n="
        @@modalRoutehappyFlightDetail"
        id="modal-header">Flight Details</h3>
    <button
      type="button"
      class="light-icon-button close"
      aria-label="Close"
      (click)="activeModal.close(false)"
      ngbAutoFocus>
      <i class="ico-cancel AA-standard-grey" aria-hidden="true"></i>
    </button>
  </div>
  <div class="modal-body">
    <mat-tab-group dynamicHeight>
      <mat-tab label="Product Info" i18n-label>
          <app-routehappy-flight-modal-upas [results]="results"></app-routehappy-flight-modal-upas>
      </mat-tab>
      <mat-tab label="Amenities & Ticket Features" i18n-label>
          <app-routehappy-flight-amenities [results]="results"></app-routehappy-flight-amenities>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
