import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';


@Component({
    templateUrl: './modal-irl-fare-rules.component.html',
    styleUrls: ['./modal-irl-fare-rules.component.scss']
})
export class ModalIrlFareComponent implements OnInit {
    public data = null;
    public parsedRules = ''
    constructor(public activeModal: NgbActiveModal) { }

    ngOnInit() {
        this.parsedRules = this.data;
    }
}

LightningModalTypes.ModalIrlFareComponent.component = ModalIrlFareComponent;
