<div class="modal-header">
  <h3 class="modal-title">
    <span i18n="@@duplicateBookingHeaderYouHave">You have </span>
    <span *ngIf="!displayItinerary" i18n="@@duplicateBookingHeaderBaskests"> trip(s) baskets </span>
    <span *ngIf="displayItinerary" i18n="@@duplicateBookingHeaderIntinerary"> an itinerary </span>
    <span i18n="@@duplicateBookingHeaderRest"> for this date already, here are the details: </span>
  </h3>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
    <span aria-hidden="true"><i class="ico-cancel modal_exit_cross"></i></span>
  </button>
</div>
<div class="modal-body">
  <ng-container *ngFor="let basketItem of userBooking">
    <p>
      <ng-container *ngFor="let user of basketItem.users; let i = index">
        <b
          >{{ user.title }}. {{ user.forename }} {{ user.surname
          }}<span *ngIf="i < basketItem.users.length + basketItem.guests?.length - 1">, </span></b
        >
      </ng-container>
      <ng-container *ngFor="let user of basketItem.guests; let i = index">
        <b
          >{{ user.title }}. {{ user.forename }} {{ user.surname
          }}<span *ngIf="i < basketItem.guests?.length">, </span></b
        >
      </ng-container>
    </p>
    <app-duplicate-booking-modal-body
      [basketItem]="basketItem"
      [user]="user"></app-duplicate-booking-modal-body>
  </ng-container>
</div>
<div class="modal-footer">
  <div
    class="flex-row-container flex-row-container--between flex-row-container--full-width flex-row-container--v-center">
    <div class="flex-row-container" i18n="@@duplicate-booking-warning">
      Would you like to continue with this booking?
    </div>
    <div class="flex-row-container button-container">
      <button (click)="activeModal.close(true)" class="small-button">
        <span i18n="@@duplicate-booking-cancel">Cancel</span>
      </button>
      <button (click)="activeModal.close()" class="small-button">
        <span i18n="@@duplicate-booking-ok">Ok</span>
      </button>
    </div>
  </div>
</div>
