import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-pane',
  styles: [
    `
      .pane {
        display: flex;
        flex-direction: column;
        background-color: rgba(0, 0, 0, 0.48);
        padding: 2rem;
      }
    `,
  ],
  template: `
    <div [hidden]="!active" class="pane background-46">
      <ng-content></ng-content>
    </div>
  `,
})
export class PaneComponent {
  public id: number;
  @Input() tabArr: string;
  @Input() tabDep: string;
  @Input() tabDate: string;
  @Input() active = false;
}
