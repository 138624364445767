<ul class="pnl-details-container">
    <li *ngIf="parking.carPark.name">
        <span class="light-fat"
              i18n="@@parkingdetails_name">Name:</span>
        <span [innerHTML]="parking.carPark.name"></span>
    </li>
    <li *ngIf="parking.carPark.telephone">
        <span class="light-fat"
              i18n="@@parkingdetails_telephone">Telephone:</span>
        <span [innerHTML]="parking.carPark.telephone"></span>
    </li>
    <li *ngIf="parking.carPark.longitude||parking.carPark.latitude ">
        <span class="light-fat"
              i18n="@@parkingdetails_geo">Geo-position:</span>
        <span>{{parking.carPark.latitude}} {{parking.carPark.longitude}}</span>
    </li>
    <li *ngIf="parking.carPark.introduction">
        <span class="light-fat"
              i18n="@@parkingdetails_intro">Introduction:</span>
        <span [innerHTML]="parking.carPark.introduction"></span>
    </li>
    <li *ngIf="parking.carPark.arrivalProcedure">
        <span class="light-fat"
              i18n="@@parkingdetails_arrival">Arrival Procedures:</span>
        <span [innerHTML]="parking.carPark.arrivalProcedure"></span>
    </li>
    <li *ngIf="parking.carPark.departureProcedure">
        <span class="light-fat"
              i18n="@@parkingdetails_depart">Departure Procedure:</span>
        <span [innerHTML]="parking.carPark.name"></span>
    </li>
    <li *ngIf="parking.carPark.information">
        <span class="light-fat"
              i18n="@@parkingdetails_info">Information:</span>
        <span [innerHTML]="parking.carPark.information"></span>
    </li>
    <li *ngIf="parking.carPark.misc.covidCopy">
        <span class="light-fat"
              i18n="@@parkingdetails_covid">Covid Information:</span>
        <span [innerHTML]="parking.carPark.misc.covidCopy"></span>
    </li>
    <li *ngIf="parking.carPark.misc.address">
        <span class="light-fat"
              i18n="@@parkingdetails_address">Address:</span>
        <span [innerHTML]="parking.carPark.address"></span>
    </li>
    <li *ngIf="parking.misc.cancellationFeeExceptions">
        <span class="light-fat"
              i18n="@@parkingdetails_cancellation">Cancellation Policy:</span>
        <span [innerHTML]="parking.carPark.address"></span>
    </li>
</ul>