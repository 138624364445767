import { LanguageEnum, Language } from '../vendor/interfaces/language-types';
import localeEn from '@angular/common/locales/en';
import localeEnExtra from '@angular/common/locales/extra/en';

export default class EnglishLanguage extends Language {
  protected locale = localeEn;
  protected localeExtra = localeEnExtra;
  protected details = {
    image: 'images/flags/en.png',
    title: 'English',
    enum: LanguageEnum.English,
    aira: 'English language chosen',
    select: 'Select English Language',
    abre: 'en',
  };
}
