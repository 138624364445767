 <div *ngIf="results && results.length">
  <app-sorting-toggle
                      (sortingSelected)="onSortingSelected($event)"
                      [sortingTypes]="searchParams.sortDropdownValues">
  </app-sorting-toggle>
  <div class="flex-row-container--tb-margin"
       *ngFor="let item of results">
    <div class="irl-list_item half-container">
      <div class="half-block flex-row-container">

        <img *ngIf="getOperator(item) !== 'EUR'"
             src="https://images.sabscorp.com/images/enterprise/lightUK/assets/images/{{getOperator(item)}}.png"
             class="irl-list_item-logo"
             alt="{{getOperator(item)}} Logo">
        <img title=''
             style="min-width: 45px"
             *ngIf="getOperator(item) === 'EUR'"
             src="./assets/svg/eurostar.svg"
             alt="eurostar"
             class="irl-list_item-logo">
        <div class="irl-list_schedule flex-row-container">
          <div class="flex-column-container">
            <div class="irl-list_schedule"><span i18n="@@railDepart">Depart:</span>
              {{item.from}}</div>
            <div class="irl-list_schedule"><span i18n="@@railArrive">Arrive:</span>{{item.to}}</div>
          </div>
          <div class="flex-column-container">

            <div class="irl-list_schedule">{{item.departDateTime | sabsTimePipe: 'timeString'}}</div>
            <div class="irl-list_schedule">{{item.arriveDateTime | sabsTimePipe: 'timeString'}}</div>
          </div>
          <div class="flex-column-container">

            <div class="irl-list_schedule">{{ isDirect(item) | translate}}</div>
            <div class="irl-list_schedule">Duration: {{calculateDuration(item) | sabsTimePipe:
              "min_duration"}}</div>
          </div>
          <!-- <div class="irl-list_legs">
            <span class="irl-list_leg"
                  i18n="@@irlListDirect">{{'Direct' | translate}}</span>
          </div> -->
        </div>
      </div>
      <div class="half-block flex-row-container">
        <button (click)="toggleFare(cheapestSecond)"
                *ngIf="getCheapestClassTicket(item, ClassType.Class_2, ClassType.Second_Class) || []; let cheapestSecond"
                [disabled]="disabledCheck()"
                aria-labelledby=""
                class="irl-list_item-fare">
          <span>
            <span class="sr-only"
                  i18n="@@irl2ndclassCheapest">2nd Class
            </span>
            <div *ngIf="!cheapestSecond.selected">
              <span *ngIf="cheapestSecond.price" class="price-container">
                <span><span i18n="@@itineraryFromCheapest">From:</span> {{cheapestSecond.price | currency:cheapestSecond.currency}}</span>
                <span class="percentage-icon" i18n-ngbTooltip placement="top" tooltipClass="custom-tooltip"  *ngIf="searchParams.discountCard[0]?.name" [ngbTooltip]="searchParams.discountCard[0]?.name">%</span>
              </span>
            </div>
            <div>
              <i class="ico-star"
                *ngIf="cheapestSecond?.fareInfo?.code"
                ngbTooltip="Company Negotiated Rate"
                i18n-ngbTooltip
                tabindex="0">
              </i>
            </div>
            <div *ngIf="cheapestSecond.selected">
              <span i18n="@@Deselect">Deselect</span>
            </div>
          </span>
          <!-- <section (mouseleave)="tt.close()"
                   *ngIf="!cheapestSecond.CanBook"
                   class="container">
            <img src="{{resultItemType.minus}}"
                 class='error_icon'
                 ngbTooltip="{{cheapestSecond.CannotBookReason}}"
                 attr.aria-label="{{cheapestSecond.CannotBookReason}}"
                 container="body"
                 placement="top"
                 triggers="manual"
                 #tt="ngbTooltip"
                 (mouseenter)="tt.open()"
                 (focus)="tt.open()"
                 (blur)="tt.close()"
                 tabindex="0"><span class="visuallyhidden">{{cheapestSecond.CannotBookReason}}</span>
          </section> -->
          <!-- <section (mouseleave)="tt2.close()"
                   *ngIf="cheapestSecond.CanBook && cheapestSecond.RequiresReason"
                   class="container">
            <img src="{{resultItemType.exclamation}}"
                 class='error_icon'
                 ngbTooltip="{{cheapestSecond.ReasonMessage | translate}}"
                 attr.aria-label="{{cheapestSecond.ReasonMessage | translate}}"
                 container="body"
                 placement="top"
                 triggers="manual"
                 #tt2="ngbTooltip"
                 (mouseenter)="tt2.open()"
                 (focus)="tt2.open()"
                 (blur)="tt2.close()"
                 tabindex="0"
                 [class.can-book-overridden]="cheapestSecond.CanBookOverridden"><span
                  class="visuallyhidden">{{cheapestSecond.ReasonMessage | translate}}</span>
          </section> -->
          <!-- <section *ngIf="cheapestSecond.CanBook && !cheapestSecond.RequiresReason"
                   class="container">
            <img src="{{resultItemType.check}}"
                 class='error_icon'>
          </section> -->
        </button>
        <button *ngIf="getCheapestClassTicket(item, ClassType.Class_1, ClassType.First_Class) || []; let cheapestFirst"
                [disabled]="disabledCheck()"
                (click)="toggleFare(cheapestFirst)"
                class="irl-list_item-fare">
          <span *ngIf="cheapestFirst.price">
            <div *ngIf="!cheapestFirst.selected">
              <span class="sr-only"
                    i18n="@@irl1stclass">1st Class
              </span>
              <span class="price-container">
                <span><span i18n="@@itineraryFromCheapest">From:</span> {{cheapestFirst.selected ? 'Selected' : cheapestFirst.price | currency:cheapestFirst.currency}}</span>
                <span class="percentage-icon" tooltipClass="custom-tooltip" i18n-ngbTooltip  *ngIf="searchParams.discountCard[0]?.name" [ngbTooltip]="searchParams.discountCard[0]?.name">%</span>
              </span>
            </div>
            <div>
              <i class="ico-star"
                *ngIf="cheapestFirst.fareInfo.code"
                ngbTooltip="Company Negotiated Rate"
                i18n-ngbTooltip
                trigger="manual"
                tabindex="0">
              </i>
            </div>
            <div *ngIf="cheapestFirst.selected">
              <span i18n="@@Deselect">Deselect</span>
            </div>
          </span>
          <!-- <section (mouseleave)="tt.close()"
                   *ngIf="!cheapestFirst.CanBook"
                   class="container">
            <img src="{{resultItemType.minus}}"
                 class='error_icon'
                 ngbTooltip="{{cheapestFirst.CannotBookReason}}"
                 attr.aria-label="{{cheapestFirst.CannotBookReason}}"
                 container="body"
                 placement="top"
                 triggers="manual"
                 #tt="ngbTooltip"
                 (mouseenter)="tt.open()"
                 (focus)="tt.open()"
                 (blur)="tt.close()"
                 tabindex="0"><span class="visuallyhidden">{{cheapestFirst.CannotBookReason}}</span>
          </section>
          <section (mouseleave)="tt2.close()"
                   *ngIf="cheapestFirst.RequiresReason && cheapestFirst.CanBook"
                   class="container">
            <img src="{{resultItemType.exclamation}}"
                 class='error_icon'
                 ngbTooltip="{{cheapestFirst.ReasonMessage | translate}}"
                 attr.aria-label="{{cheapestFirst.ReasonMessage | translate}}"
                 container="body"
                 placement="top"
                 triggers="manual"
                 #tt2="ngbTooltip"
                 (mouseenter)="tt2.open()"
                 (focus)="tt2.open()"
                 (blur)="tt2.close()"
                 tabindex="0"
                 [class.can-book-overridden]="cheapestFirst.CanBookOverridden"><span
                  class="visuallyhidden">{{cheapestFirst.ReasonMessage | translate}}</span>
          </section>
          <section *ngIf="cheapestFirst.CanBook && !cheapestFirst.RequiresReason"
                   class="container">
            <img src="{{resultItemType.check}}"
                 class='error_icon'>
          </section> -->
        </button>
      </div>
    </div>
    <div class="irl_detail_container">
      <div class="irl_detail_title">
        <b><span i18n="@@IrlListSearchTrainNumber">&nbsp;&nbsp;Train number</span> {{item.identifiers.journeyIdentifier}}</b>
        • &nbsp;&nbsp;
        <b>&nbsp;&nbsp;<span i18n="@@flightLegsOperatedBy">Operated By</span> {{item.segments[0].operator ? item.segments[0].operator : 'not provided'}}</b>

        • &nbsp;&nbsp;
        &nbsp;&nbsp;
      </div>
      <div class="irl_detail_info"
           (click)="item.ShowDetail = !item.ShowDetail"
           role="button">
        <b [attr.aria-expanded]="item.ShowDetail"
           attr.aria-controls="irl-detail-expended-{{item.OutBoundUid}}"
           tabindex="0"
           (keydown.enter)="item.ShowDetail = !item.ShowDetail"
           class="detail-accordion">{{ (item?.ShowDetail ? "Collapse" : "Show trip details & fares") |
          translate}}</b>
      </div>
    </div>
    <div class="irl_detail_showdetail"
         *ngIf="item.ShowDetail"
         id="irl-detail-expended-{{item.identifiers.journeyIdentifier}}">

      <app-irl-item-detail
        [item]="item"
        (selectIrl)="toggleFare($event)"
        [first]="getClassTickets(item,ClassType.Class_1, ClassType.First_Class)"
        [second]="getClassTickets(item,ClassType.Class_2, ClassType.Second_Class)"
        [legs]="item.segments"
        [currency]="item.providers[0].ticketOptions[0].currency"
        [trainNumber]="item.identifiers.journeyIdentifier"
        [isReturnTrip]="resType === 'inbound'"
        [discountCard]="searchParams.discountCard[0]?.name"
        >
      </app-irl-item-detail>
    </div>
  </div>
</div>
<div *ngIf="!results?.length">
  <div class="no_results_message background-10 no_results_irl">
    <p><strong i18n="@@irlResultsNoMatch">This specific itinerary searched requires an offline booking, please use the
        link below to submit your offline request</strong>
    </p>
  </div>
  <div class="no_results_message no_results_irl">
    <p class="no_results_link"><a (click)="goOfflineForm()" i18n="@@irlResultsContact">Click here to contact the offline request form. When you
        submit the request a member of the CTM team will be in touch with a quote.</a></p>
  </div>
</div>
