/**
 * Sorting type can be passed to sorting-toggle component.
 *
 * @export
 * @interface SortType
 */
 import { SortTypesEurostar } from '@sabstravtech/obtservices/base'
export enum SortMethod {
  sortByPrice = 'sortByPrice',
  sortByName = 'sortByName',
  sortByDate = 'sortByDate',
}
export interface SortType {
  /** Title displayed on dropdown */
  title: string;
  /** Method name used by component to sort results */
  method: SortMethod;
  /** Icon displayed on drop down */
  icon: string;
  /** Sorting direction true for asc, false for dsc */
  dir: boolean;

  code?: SortTypesEurostar
}
