<div class="flight-extras-container">
  <div class="half-container">
    <div class="half-block">
      <ng-container *ngIf="loyaltyCode;else enterLoyaltyNumber">
        <label *ngIf="loyaltyCode">Loyalty Number</label>
      </ng-container>
      <ng-template #enterLoyaltyNumber>
        <label *ngIf="!loyaltyCode">Enter {{operatingCarrierName}} Loyalty Number </label>
      </ng-template>
      <input
        class="input-box input-box--border input-box--no-iconed"
        type="text"
        [value]="loyaltyCode"
        (input)="updateLoyaltyCode($event)" />
    </div>
    <div class="half-block">
      <button
        type="button"
        class="small-button"
        [disabled]="!recalcActive"
        id="recalculate-button"
        (click)="recalcuateSeats()">
        Recalculate
      </button>
      <i
        class="ico ico-info-circle info-button"
        i18n-ngbTooltip
        ngbTooltip="A change has been made to the Loyalty input field, click on the recalculate button to ensure your membership benefits are applied"
        attr.aria-label="A change has been made to the Loyalty input field, click on the recalculate button to ensure your membership benefits are applied"
        triggers="manual"
        #ttHint="ngbTooltip"
        (mouseenter)="ttHint.open()"
        (focus)="ttHint.open()"
        (blur)="ttHint.close()"
        tabindex="0"
        ><span class="visuallyhidden"
          >A change has been made to the Loyalty input field, click on the recalculate button to
          ensure your membership benefits are applied</span
        ></i
      >
    </div>
  </div>
</div>
