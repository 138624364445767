import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RouteHappyResults } from '@sabstravtech/obtservices/base';
import {
  faChair,
  faWifi,
  faPlug,
  faPlayCircle,
  faUtensils,
  faGlassWhiskey,
  faPlane,
  faCloud
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-routehappy-flight-amenities',
  templateUrl: './routehappy-flight-amenities.component.html',
  styleUrls: ['./routehappy-flight-amenities.component.scss']
})
export class RoutehappyFlightAmenitiesComponent implements OnInit {
  @Input() results: RouteHappyResults = null;
  faChair = faChair;
  faWifi = faWifi;
  faPlug = faPlug;
  faPlayCircle = faPlayCircle;
  faUtensils = faUtensils;
  faGlassWhiskey = faGlassWhiskey;
  faPlane = faPlane;
  faCloud = faCloud;
  displayAmenities = false;
  displayTicketFeatures = false;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    if (this.results) {
      this.displayAmenities =
        this.results.data?.amenitySummary &&
        (this.results.data?.amenitySummary[0].aircraft ||
          this.results.data?.amenitySummary[0].beverage ||
          this.results.data?.amenitySummary[0].entertainment ||
          this.results.data?.amenitySummary[0].freshFood ||
          this.results.data?.amenitySummary[0].layout ||
          this.results.data?.amenitySummary[0].power ||
          this.results.data?.amenitySummary[0].seat ||
          this.results.data?.amenitySummary[0].wifi);
      this.displayTicketFeatures =
        this.results?.data.checkedBag?.length ||
        this.results?.data.carryOnBag?.length ||
        this.results?.data.seatSelection?.length ||
        this.results?.data.boardingPriority?.length ||
        this.results?.data.advanceChange?.length ||
        this.results?.data.cancellation?.length ||
        this.results?.data.sameDayChange?.length ||
        this.results?.data.checkInPriority?.length ||
        this.results?.data.loungeAccess?.length;
    }
  }
}

