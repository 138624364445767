import { TitleCasePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbTooltip, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, of, BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, map, catchError } from 'rxjs/operators';
import { EnterpriseSearchService, UserService, ServiceType,  RailSearchJourneyType, IrlJourneyType, IrlTravelCard } from '@sabstravtech/obtservices/angular';
import { IRLEnterpriseSearchInterface, Traveller, LocationDetails } from '@sabstravtech/obtservices/base';
import { LightningUserFavorurite } from '../../../vendor/classes/user-favourite.enum';

import { DeviceDetector } from '../../../vendor/services/device-detector.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../../vendor/components/base_components/base-componet';

// import { SetDefaultService } from '../../obtservices/vendor/services/set-default.service';
// import { UserFavorurite } from '../../obtservices/vendor/enums/user-favourite.enum';

@Component({
  selector: 'app-irl-search',
  templateUrl: './irl-search.component.html',
  styleUrls: ['./irl-search.component.scss']
})
export class IrlSearchComponent extends BaseComponent implements OnInit {
  @ViewChild('instance') instance: NgbTypeahead;
  @ViewChild('ttip') ttip: NgbTooltip;
  focus$ = new Subject<string>();
  focus2$ = new Subject<string>();
  focus3$ = new Subject<string>();
  public input1Moment: any;

  allowOpenReturn = true;
  ServiceType: typeof ServiceType = ServiceType;
  IrlJourneyType: typeof IrlJourneyType = IrlJourneyType
  travellers: Traveller[];

  searchParams: IRLEnterpriseSearchInterface;
  typeaHeadLimit = Number.MAX_SAFE_INTEGER;

  travellingFrom: Observable<any[]> = of([]);
  travellingTo: Observable<any[]> = of([]);
  travellingVia: Observable<any[]> = of([]);
  // ! this will probably got to obts as part of offline
  loyaltyCardProviders: { code: string; display: string; value: string; }[] = [
    { code: 'CC', value: '', display: 'CartaFRECCIA' },
    { code: 'REN', value: '', display: '+ Renfe Card' },
    { code: 'SNC', value: '', display: 'Carta Grand Voyageur' },
    { code: '2H', value: '', display: 'My Thalys World' },
    { code: 'ES', value: '', display: 'Club Eurostar' }];
  codeToName = {
    CC: 'CartaFRECCIA',
    REN: '+ Renfe Card',
    SNC: 'Carta Grand Voyageur',
    '2H': 'My Thalys World',
    ES: 'Club Eurostar'
  }
  discountCards: any = [];
  isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public selectedDiscountCards: IrlTravelCard[] = [];


  constructor(
    private userService: UserService,
    public deviceDetector: DeviceDetector,
    public searchService: EnterpriseSearchService,
    private titleCasePipe: TitleCasePipe,
    public title: Title,
    public translateService: TranslateService
  ) {
    super(title, translateService);
  }

  ngOnInit(): void {
    this.allowOpenReturn = !this.userService.isUserFavoriteSet(LightningUserFavorurite.no_open_returns);
    this.searchParams = this.searchService.searches[ServiceType.InternationalRail];
    this.searchService.travellerInformation.subscribe((travellers: Traveller[]) => {
      this.travellers = travellers;
    })
  }

  formatter2 = (x: { name: string }) => x.name;

  irlLocations = (text$: Observable<string>): Observable<LocationDetails[]> => this.locationTypeahead(text$);
  irlLocations2 = (text$: Observable<string>) => this.locationTypeahead(text$);
  irlLocations3 = (text$: Observable<string>) => this.locationTypeahead(text$);

  private locationTypeahead(text$: Observable<string>): Observable<LocationDetails[]> {
    return text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(
        (term: string): Observable<LocationDetails[]> =>
          term.length <= 2
            ? of([])
            : this.searchService.searchIrlStationsLocations(term, this.isLoading).pipe(
              map((res: any[]) => {
                console.log(res);
                return res.slice(0, this.typeaHeadLimit);
              }),
              catchError(() => of([]))
            )
      )
    );
  }

  formatter = (x: { name: string }): string => this.titleCasePipe.transform(x.name);

  ensureElementIsScrolledTo(event) {
    try {
      const typeAheadList = event.target.nextElementSibling;
      const activeButton = typeAheadList.getElementsByClassName('active')[0];
      if (activeButton.offsetTop + activeButton.clientHeight > typeAheadList.clientHeight + typeAheadList.scrollTop) {
        typeAheadList.scrollTop = activeButton.offsetTop + activeButton.clientHeight - typeAheadList.clientHeight;
      } else if (activeButton.offsetTop < typeAheadList.scrollTop) {
        typeAheadList.scrollTop = activeButton.offsetTop;
      }
    } catch (e) {
      // tslint:disable-next-line: quotemark
      console.log("Couldn't find elements to scroll");
    }
  }

  isValidTime(favouriteTime: string): boolean {
    return !!favouriteTime.match(/^(\d{2}):(\d{2})$/);
  }

  changeIrlcardOption():void {
    this.searchParams.discountCard = this.selectedDiscountCards;
  }

  cardComparator(a:IrlTravelCard,b:IrlTravelCard):boolean {
    return a?.name === b?.name
}
}
