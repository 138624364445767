import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, merge, switchMap } from 'rxjs/operators';
import { FlightPipe } from '../../pipes/flight-pipe';
import { EnterpriseSearchService } from '@sabstravtech/obtservices/angular';
import { LocationDetails } from '@sabstravtech/obtservices/base';

@Component({
  selector: 'app-search-rail-location-typeahead',
  templateUrl: './search-rail-location-typeahead-component.component.html',
  styleUrls: ['./search-rail-location-typeahead-component.component.scss']
})
export class SearchRailLocationTypeaheadComponent implements OnInit {
  @Input() model;
  @Input() isTrain?: boolean = false;
  @Output() modelChange = new EventEmitter<any>();
  @Output() updateElement = new EventEmitter<any>();

  public focus$ = new Subject<string>();
  searchLocationLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private searchService: EnterpriseSearchService, private flightPipe: FlightPipe) { }

  ngOnInit(): void { }

  formatter_rail_air = (x: LocationDetails) => x.name;

  searchLocations = (text$: Observable<string>): Observable<LocationDetails[]> =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focus$),
      switchMap(
        (term: string): Observable<LocationDetails[]> => {
          if (term && term.length > 2) {
            return this.searchService.getStationLocations(term, this.searchLocationLoading, null, null, null, null, true);
          } else {
            return of([]);
          }
        }
      )
    );

  onStationChange(stations: any) {
    const staionsObject = {
      name: stations?.item?.name,
      stationCode: stations?.item?.railstation?.stationCode,
      latitude: stations?.item?.railstation?.locationDetails?.coordinates?.latitude,
      longitude: stations?.item?.railstation?.locationDetails?.coordinates?.longitude,
      countryCode: stations?.item?.railstation?.locationDetails?.countryCode
    };
    this.modelChange.emit(staionsObject);
  }
}
