import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { Location } from '@angular/common';
import moment from 'moment';

import {
  EnterpriseBasketService,
  EnterpriseSearchService,
  UserService,
  ServiceType,
  IrlJourneyOptions,
  IrlTicket,
  IrlJourneyOption,
  IrlTicketOption,
  IrlSupplier
} from '@sabstravtech/obtservices/angular';

import {
  IRLEnterpriseSearchInterface,
  ClassType,
  SortTypeIrl,
  IrlTicketOptionWithSelected
} from '@sabstravtech/obtservices/base';


@Component({
  selector: 'app-irl-list',
  templateUrl: './irl-list.component.html',
  styleUrls: ['./irl-list.component.scss']
})
export class IrlListComponent implements OnInit {
  @Input() results: IrlJourneyOptions[];
  @Input() from: string;
  @Input() to: string;
  @Input() date: NgbDateStruct;
  @Input() resType: 'outbound' | 'inbound';
  @Input() isReturn: boolean = false;
  @Input() cheapestOutboundPrice: number;
  @Input() selectedOutboundPrice: number;
  @Input() cheapestReturnPrice: number;
  @Input() selectedReturnPrice: number;
  @Input() cheapestDualPrice: number;
  /* Type of results passed to component */
  @Output() selectTicket: EventEmitter<any> = new EventEmitter<any>();
  /* logo used to display */
  irlLogo = 'https://images.sabscorp.com/images/TourOp/svg/9F.svg';
  preferredLogoUrl: string;
  searchParams: IRLEnterpriseSearchInterface;
  ClassType: typeof ClassType = ClassType;
  // resultItemType: typeof resultIcons = resultIcons;
  selectedTicket: IrlTicket;


  constructor(
    public searchService: EnterpriseSearchService,
    public basketService: EnterpriseBasketService,
    public modalService: NgbModal,
    public userService: UserService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.searchParams = this.searchService.searches[ServiceType.InternationalRail];
  }

  /**
   * Select fare if all selected adds fares to basket
   * also filter fares to display only available for match
   * @param item
   * @param fareClass
   */
  toggleFare(ticket: IrlTicketOptionWithSelected): void {
    this.selectTicket.emit(ticket);

  }


  onSortingSelected(sortType: SortTypeIrl): void {
    this.searchParams[sortType.methodRef](false);
  }

  getCheapestClassTicket(item: IrlJourneyOption, type: ClassType, altType: ClassType): IrlTicketOption {
    const returnItem = item.providers[0].ticketOptions.reduce((acc, ticket) => {
      if (ticket.class === type || ticket.class === altType) {
        if (!Object.keys(acc).length || (+acc.price > +ticket.price)) {
          acc = ticket;
        }
      }
      return acc;
    }, <IrlTicketOption>{});
    return Object.keys(returnItem).length ? returnItem : null;
  }

  getClassTickets(results: IrlJourneyOption, type: ClassType, altType: ClassType): IrlTicketOption[] {
    return results.providers[0].ticketOptions.filter((ticket: IrlTicketOption) =>
      ticket.class === type || ticket.class === altType
    ).sort((a, b) => a.price - b.price);

  }

  calculateDuration(item: IrlJourneyOption): string {
    let duration = 0;
    const depMoment = moment(item.departDateTime, 'YYYYMMDDHHmm');
    const arrMoment = moment(item.arriveDateTime, 'YYYYMMDDHHmm');

    const diff = depMoment.diff(arrMoment, 'minutes');
    duration += diff;

    return Math.abs(duration).toString();
  }

  isDirect(item: IrlJourneyOption) {
    return item.segments.length === 1 ? 'Direct' : '';
  }

  goOfflineForm(): void {
    //! sort with offline form
    this.location.back();
    this.searchParams.offlineRequest = true;
  }

  getOperator(item: IrlJourneyOption): string | IrlSupplier {
    const provider = item.providers[0].providerId as IrlSupplier;
    switch (provider) {
      case IrlSupplier.Ntv: {
        return "Italo";
      }
      case IrlSupplier.BeNe: {
        if (item.segments[0]?.operator?.includes("Intercity") || item.segments[0]?.operator?.includes("Eurocity")) {
          return "Deutschebahn";
        }
        else {
          return provider;
        }
      }
      default:
        return provider;
    }
  }
  // image urls: Deutschebahn.png Italo.png Renfe.png Trenitalia.png
  // ntv == italo -- works
  // bene intercity == deutschebahn -- think it works, willing to be proven wrong
  // renfe should just == renfe -- WORKS
  // trenitalia isn't implemented I think

  disabledCheck(): boolean {
    return !!(this.resType === 'inbound' && !this.searchParams.outboundTicket);
  }
}
