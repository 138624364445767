import { Component, OnInit } from '@angular/core';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tod-booking-error-modal',
  templateUrl: './tod-booking-error-modal.component.html',
  styleUrls: ['./tod-booking-error-modal.component.scss']
})

export class TodBookingErrorModalComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal
  ) {}

  stationCode: string = null;
  stationName: string = null;

  ngOnInit(): void { }

}

LightningModalTypes.TodBookingErrorModalComponent.component = TodBookingErrorModalComponent;

