<div [formGroup]="addressForm" *ngIf="user && addressForm" class="profile_information--content--large" >
    <div formArrayName="addresses">
        <div  *ngFor="let addressForm of addresses.controls; let i = index" [formGroupName]="i">
            <div [formGroup]="addressForm" *ngIf="i===showAddressBlock">
                <div class="profile_information--input-container">
                    <div class="profile_information--input-label">
                        <div class="profile_information--asterisk">*</div>
                        <span i18n="@@profile-information-description">Description</span>:
                    </div>
                    <div class="profile_information--input-wrapper">
                        <input class="profile_information--input" formControlName="description"/>
                        <div *ngIf="addressForm.get('description').invalid || addressForm.get('description').touched">
                            <div *ngIf="addressForm.get('description').errors?.required" class="profile_information--error-message" i18n="@@profile-information-description-required">Description required</div>
                        </div>
                    </div>
                </div>
                <div class="profile_information--input-container">
                    <div class="profile_information--input-label">
                        <div class="profile_information--asterisk">*</div>
                        <span i18n="@@profile-information-number-or-name">Number or Name</span>:
                    </div>
                    <div class="profile_information--input-wrapper">
                        <input class="profile_information--input" formControlName="numberOrName"/>
                        <div *ngIf="addressForm.get('numberOrName').invalid || addressForm.get('numberOrName').touched">
                            <div *ngIf="addressForm.get('numberOrName').errors?.required" class="profile_information--error-message" i18n="@@profile-information-number-or-name-required">Number or Name required</div>
                        </div>
                    </div>
                </div>
                <div class="profile_information--input-container">
                    <div class="profile_information--input-label">
                        <div class="profile_information--asterisk">*</div>
                        <span i18n="@@profile-information-street">Street</span>:
                    </div>
                    <div class="profile_information--input-wrapper">
                        <input class="profile_information--input" formControlName="street"/>
                        <div *ngIf="addressForm.get('street').invalid || addressForm.get('street').touched">
                            <div *ngIf="addressForm.get('street').errors?.required" class="profile_information--error-message" i18n="@@profile-information-street-required">Street required</div>
                        </div>
                    </div>
                </div>
                <div class="profile_information--input-container">
                    <div class="profile_information--input-label">
                        <div class="profile_information--asterisk">*</div>
                        <span i18n="@@profile-information-town">Town</span>:
                    </div>
                    <div class="profile_information--input-wrapper">
                        <input class="profile_information--input" formControlName="town"/>
                        <div *ngIf="addressForm.get('town').invalid || addressForm.get('town').touched">
                            <div *ngIf="addressForm.get('town').errors?.required" class="profile_information--error-message" i18n="@@profile-information-town-required">Town required</div>
                        </div>
                    </div>
                </div>
                <div class="profile_information--input-container">
                    <div class="profile_information--input-label">
                        <span i18n="@@profile-information-county">County</span>:
                    </div>
                    <div class="profile_information--input-wrapper">
                        <input class="profile_information--input" formControlName="county"/>
                    </div>
                </div>
                <div class="profile_information--input-container">
                    <div class="profile_information--input-label">
                        <span i18n="@@profile-information-postcode">Postcode</span>:
                    </div>
                    <div class="profile_information--input-wrapper">
                        <input class="profile_information--input" formControlName="postcode"/>
                    </div>
                </div>
                <div class="profile_information--input-container">
                    <div class="profile_information--input-label">
                        <div class="profile_information--asterisk">*</div>
                        <span i18n="@@profile-information-country-code">Country Code</span>:
                    </div>
                    <div class="profile_information--input-wrapper">
                        <input class="profile_information--input" formControlName="countryCode"/>
                        <div *ngIf="addressForm.get('countryCode').invalid || addressForm.get('countryCode').touched">
                            <div *ngIf="addressForm.get('countryCode').errors?.required" class="profile_information--error-message" i18n="@@profile-information-country-code-required">Country Code required</div>
                        </div>
                    </div>
                </div>
                <div class="profile_information--input-container">
                    <div class="profile_information--input-label">
                        <span i18n="@@profile-information-primary">Primary</span>:
                    </div>
                    <div class="profile_information--input-checkbox">
                        <input type="checkbox" formControlName="userPrimary"/>
                    </div>
                </div>
            </div>
        </div>

        <table class="profile_information--table-container">
            <thead class="profile_information--table-thead">
                <tr>
                    <th colspan="3">
                        <span i18n="@@profile-information-address">Address</span>:
                    </th>
                </tr>
            </thead>
            <tbody class="profile_information--table-tbody">
                <tr  *ngFor="let addressForm of addresses.controls; let i = index" [formGroupName]="i">
                    <td>
                        <b>{{addressForm.get('description').value}}
                        <span *ngIf="addressForm.get('userPrimary').value === true" i18n="@@profile-information-add-primary">(Primary)</span>: </b>
                        {{addressForm.get('numberOrName').value}}
                        {{addressForm.get('street').value}}
                        {{addressForm.get('town').value}}
                        {{addressForm.get('county').value}}
                        {{addressForm.get('postcode').value}}
                    </td>
                    <td><button [disabled]="!isEditable" [class.disable-button]="!isEditable" class="mat-stroked-button" (click)="editAddress(i)" i18n="@@Edit">Edit</button></td>
                    <td><button [disabled]="!isEditable" [class.disable-button]="!isEditable" class="mat-stroked-button" (click)="deleteAddress(i)" i18n="@@Delete">Delete</button></td>
                </tr>
            </tbody>
            <tfoot>
                <button class="small-button add-address-button profile_information--add-button" [disabled]="addressForm.invalid && isEditable" [class.disable-button]="!isEditable" (click)="addAddress(newAddress)" i18n="@@Add-new-address">Add new address</button>
            </tfoot>
        </table>
    </div>
</div>
