import { Pipe, PipeTransform } from '@angular/core';
import { EurostarClassEnum, EuroClass } from '@sabstravtech/obtservices/base';
@Pipe({
  name: 'eurostarClassPipe',
  pure: true,
})
export class EurostarClassPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {

      case EuroClass.PremiumEconomy:
      case EuroClass.Premium:
        return EurostarClassEnum.STANDARD_PREMIER;
      case EuroClass.Business:
        return EurostarClassEnum.BUSINESS_PREMIER;
      case EuroClass.EconomyFlexi:
      case EuroClass.EconomyNoBags:
      case EuroClass.EconomyRestricted:
      default:
        return EurostarClassEnum.STANDARD;
    }
  }
}
