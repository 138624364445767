import { Component, Input, OnInit } from "@angular/core";
import {
  BasketItem,
  User,
  ServiceType,
} from "@sabstravtech/obtservices/angular";
@Component({
  selector: "app-duplicate-booking-modal-body",
  templateUrl: "./duplicate-booking-modal-body.component.html",
  styleUrls: ["./duplicate-booking-modal-body.component.scss"],
})
export class DuplicateBookingModalBodyComponent implements OnInit {
  @Input() basketItem: BasketItem = null;
  @Input()  user: User = null;
  serviceType = ServiceType;
  userBooking: BasketItem[];
  isDuplicate = true;
  constructor() {}

  ngOnInit() {
    // this.userBooking = this.filterUserBasketItem();
  }

  // filterUserBasketItem(): BasketItem[]{
  //   return this.booking.reduce(
  //     (bookedBooking: BasketItem[], item: BasketItem) => {
  //       item.users.forEach((user:User ) => {
  //         if(user.id === this.user.id){
  //           bookedBooking.push(item)
  //         }
  //       })
  //       return bookedBooking;
  //     },
  //     []
  //   );
  // }
}
