import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { LightningModalTypes } from '../../../../../vendor/classes/modal-types.enum';
import {
  EnterpriseSearchService,
  EnterpriseBasketService,
  ServiceType,
  RailJourneyFare,
  ModalOpenerService,
  RailJourneyOption,
  RailClass,
  HelperRoutines,
  RailProvider
} from '@sabstravtech/obtservices/angular';
import { flatten, isArray } from 'lodash';

export enum TabNames {
  RailLegTimes = 'Rail Leg Times',
  FareDetails = 'Fare Details'
}

@Component({
  selector: 'app-trainline-eu-item-detail',
  templateUrl: './trainline-eu-item-detail.component.html',
  styleUrls: ['./trainline-eu-item-detail.component.scss']
})
export class TrainlineEuItemDetailComponent implements OnInit, OnChanges {
  @Input() isReturnTrip: boolean;
  @Input() currency: string;
  @Input() legs; //: LegTKT;
  @Input() trainNumber: string;
  @Input() item: RailJourneyOption;
  @Input() discountCard: string;
  @Input() isInbound: boolean = false;
  @Input() isCancelled: boolean = false;
  @Input() selectedRail: RailJourneyFare[] = [];
  @Output() selectRail: EventEmitter<RailJourneyFare[]> = new EventEmitter<RailJourneyFare[]>();
  TabNames: typeof TabNames = TabNames;
  selectedTab: TabNames = TabNames.FareDetails;
  ClassType: typeof RailClass = RailClass;
  // second: RailJourneyFare[] = [];
  // first: RailJourneyFare[] = [];
  providerRailJourney : RailJourneyFare[] = [];
  searchParams = this.searchService.searches[ServiceType.Rail];
  // resultItemType: typeof resultIcons = resultIcons;
  currentLeg = 0;
  providers: RailProvider[] = [];
  selectedFare:RailJourneyFare[] = [];
  constructor(
    private basketService: EnterpriseBasketService,
    public searchService: EnterpriseSearchService,
    private modalService: ModalOpenerService,
    private helpers: HelperRoutines
  ) {}

  ngOnInit(): void {

    // ! - Trainline does not have proper returns - only dual singles, will need to test this properly when I get onto return journeys
    this.providers = this.item.providers;
    this.providerRailJourney = this.providers.reduce((fares, provider)=>{
      const second = this.getClassTickets(
        Object.values(
          this.isReturnTrip
            ? this.isInbound
              ? this.item.dualSingleJourneyFares || this.item.returnJourneyFares
              : this.item.dualSingleJourneyFares
            : this.item.singleJourneyFares
        ),
        RailClass.Standard,
        provider
      );
      const first = this.getClassTickets(
        Object.values(
          this.isReturnTrip
            ? this.isInbound
              ? this.item.dualSingleJourneyFares || this.item.returnJourneyFares
              : this.item.dualSingleJourneyFares
            : this.item.singleJourneyFares
        ),
        RailClass.First,
        provider
      );
      let railClass = {
        second: second,
        first: first
      }

      fares.push(railClass);
      return fares;
    }, [])
  }

  ngOnChanges(){
    this.selectedFare = this.selectedRail;
  }

  getClassTickets(allTickets: RailJourneyFare[], ticketClass: RailClass, provider): RailJourneyFare[] {
    const ticketsCopy = flatten(this.helpers.clone(allTickets)).filter(x => x);
    let filteredTickets = [];

    for (let ticket of ticketsCopy) {
      if (ticket?.class === ticketClass && ticket.providerId === provider.providerId) {
        filteredTickets.push(ticket);
      }
    }

    filteredTickets = filteredTickets.filter(n => n).sort((a, b) => a.price - b.price);

    return filteredTickets;
  }
  getTimeDifference(date1: string, time1: string, date2: string, time2: string): string {
    const tempDate1 = date1.match(/.{1,2}/g); // splits every 2 characters
    const tempTime1 = time1.match(/.{1,2}/g);

    const tempDate2 = date2.match(/.{1,2}/g); // splits every 2 characters
    const tempTime2 = time2.match(/.{1,2}/g);

    const jsDate1 = new Date(
      Number(tempDate1[0]) + 2000,
      Number(tempDate1[1]) - 1,
      Number(tempDate1[2]),
      Number(tempTime1[0]),
      Number(tempTime1[1])
    );
    const jsDate2 = new Date(
      Number(tempDate2[0]) + 2000,
      Number(tempDate2[1]) - 1,
      Number(tempDate2[2]),
      Number(tempTime2[0]),
      Number(tempTime2[1])
    );

    const diff = new Date(jsDate2.getTime() - jsDate1.getTime());
    return (
      diff.getHours().toString().padStart(2, '0') + diff.getMinutes().toString().padStart(2, '0')
    );
  }

  /**
   * @description - change the selected tab - called from view
   * @param tabString  - the tab to select
   */
  selectTab(tabString: TabNames): void {
    this.selectedTab = tabString;
  }

  /**
   * @description - Open the fare rules modal with the data retrieved from jsonCAllHBR
   * @param ticket -  RailTicketOption
   */
  openFareRulesModal(ticket: RailJourneyFare): void {
    this.modalService.open(
      LightningModalTypes.RailFareTermsComponent,
      {
        centered: true
      },
      { ticketDetails: this.helpers.clone(ticket.terms[0].terms),
        includedExtras: ticket.includedExtras }
    );
  }

  addToBasket(item: RailJourneyFare, leg: number): void {
    if(!this.isInbound && this.searchParams.selectedTicket && this.selectedRail.length){
      this.selectRail.emit(this.selectedFare);
    } else{
      if(this.selectedFare[leg]){
        this.selectedFare[leg] =  null;
      } else {
        this.selectedFare[leg] = item;
      }
      const isSelected = this.selectedFare.filter(fare => fare);
      if(this.providers.length === isSelected.length){
        this.selectRail.emit(this.selectedFare);
      }
   }
  }
}

