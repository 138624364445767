import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  // tslint:disable-next-line: typedef-whitespace
  private scripts: { [key: string]: boolean; } = {};
  constructor() { }

  addScript(url: string): Promise<boolean> {
    if (!this.scripts[url]) {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        this.scripts[url] = true;
        if ((<any>script).readyState) {
          // For IE
          (<any>script).onreadystatechange = () => {
            if (
              (<any>script).readyState === 'loaded' ||
              (<any>script).readyState === 'complete'
            ) {
              (<any>script).onreadystatechange = null;
              resolve(true);
            }
          };
        } else {
          script.onload = () => resolve(true);
        }
        script.onerror = (error: any) => {
          console.error(error);
          reject(error);
        };
        document.getElementsByTagName('head')[0].appendChild(script);
      });
    } else {
      return Promise.resolve(true);
    }
  }
}
