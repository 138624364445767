import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RailSearchJourneyType, WithSubscriptionComponent } from '@sabstravtech/obtservices/angular'
@Component({
  selector: 'app-cheapest-button',
  templateUrl: './cheapest-button.component.html',
  styleUrls: ['./cheapest-button.component.scss']
})
export class CheapestButtonComponent extends WithSubscriptionComponent {

  @Input() message: string;
  @Input() id: string = '';
  @Input() price: number[];
  @Output() hightLight = new EventEmitter<number>();
  lowestPrice:number

  constructor() { super() }

  ngOnInit(): void {
    const positiveArray = this.price.filter(item => item > 0)
    this.lowestPrice = Math.min(...positiveArray);
  }

}
