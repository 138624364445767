import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LightningModalTypes } from '../../../../../vendor/classes/modal-types.enum';
import { EnterpriseSearchService, WithSubscriptionComponent } from '@sabstravtech/obtservices/angular';
@Component({
  selector: 'app-hotel-photos-dialog',
  templateUrl: './hotel-photos-dialog.component.html',
  styleUrls: ['./hotel-photos-dialog.component.scss']
})
export class SeePhotosDialogComponent extends WithSubscriptionComponent implements OnInit {
  // Input
  @Input() public hotelId: string = null;
  @Input() public hotelImages: string[] = [];
  public loading = false;
  @Input() public isConferma = false;
  public selectedHotel: string;

  constructor(
    public activeModal: NgbActiveModal,
    private searchService: EnterpriseSearchService,
  ) {
    super();
  }

  ngOnInit() {
    if (this.hotelId && this.isConferma) {
      this.loading = true;

      this.subscribe(this.searchService.getConfermaRoomImages(this.hotelId), images => {
        this.hotelImages = images;
        if (images && images.length) {
          this.selectedHotel = images[0];
        }
        this.loading = false;
      })
    } else if (this.hotelImages.length) {
      this.selectedHotel = this.hotelImages[0];
    }
  }
}

LightningModalTypes.SeePhotosDialogComponent.component = SeePhotosDialogComponent;