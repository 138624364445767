import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';

@Component({
  selector: 'app-alternative-rail-modal',
  templateUrl: './alternative-rail-modal.component.html'
})
export class AlternativeRailModalComponent {

  constructor(private activeModal: NgbActiveModal) {
  }

  close(): void {
    this.activeModal.close(false);
  }

  ok() {
    this.activeModal.close(true);
  }

}
LightningModalTypes.AlternativeRailModalComponent.component = AlternativeRailModalComponent;
