import { Injectable, EventEmitter } from '@angular/core';
import { DeviceType } from '../enum/device-type.enum';

@Injectable()
export class DeviceDetector {
  static readonly MOBILE_MAX_WIDTH = 768;
  static readonly TABLET_MAX_WIDTH = 1023;
  deviceType: DeviceType = DeviceType.DESKTOP;
  displayMonths: 1 | 2 = 2;
  currWidth: Number = 0;

  constructor() {
    this.adaptToWidth();
    window.addEventListener('resize', () => {
      let width = document.documentElement.clientWidth
      this.currWidth = width;
      const currentDevice = this.deviceType;
      if (width < 768) {
        this.deviceType = DeviceType.MOBILE;
        this.displayMonths = 1;
      } else if (width < DeviceDetector.TABLET_MAX_WIDTH) {
        this.deviceType = DeviceType.TABLET;
        this.displayMonths = 1;
      } else {
        this.deviceType = DeviceType.DESKTOP;
        this.displayMonths = 2;
      }
    });
  }

  adaptToWidth(): void {
    let width = document.documentElement.clientWidth
    this.currWidth = width;
    const currentDevice = this.deviceType;
    if (width < 768) {
      this.deviceType = DeviceType.MOBILE;
      this.displayMonths = 1;
    } else if (width < DeviceDetector.TABLET_MAX_WIDTH) {
      this.deviceType = DeviceType.TABLET;
      this.displayMonths = 1;
    } else {
      this.deviceType = DeviceType.DESKTOP;
      this.displayMonths = 2;
    }
  }

  getCurrentWidth(): Number {
    this.currWidth = this.currWidth || document.documentElement.clientWidth;
    return this.currWidth;
  }
}
