import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LightningModalTypes } from '..//../vendor/classes/modal-types.enum';
import { BaseModalComponent } from '../base-modal.component';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.css'],
})
export class InfoModalComponent extends BaseModalComponent {
  public info = '';
  title = 'Information';
  showIcon = true;
  buttonText: string = 'Cancel';

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal
  ) {
    super(activeModal);
  }

  closeModal(): void {
    console.log('Closing Modal...');
    this.activeModal.close(true);
  }
}

LightningModalTypes.InfoModalComponent.component = InfoModalComponent;
