import { Pipe, PipeTransform } from '@angular/core';
import { LoungeQuote } from '@sabstravtech/obtservices/angular';

@Pipe({
  name: 'loungesPriceSort',
  pure: true
})
export class LoungesPriceSortPipe implements PipeTransform {
  transform(value: LoungeQuote[], ...args: any[]): LoungeQuote[] {
    let sortedResults: LoungeQuote[] = [];
    if (value.length > 0) {
      sortedResults = value.slice().sort((a, b) => a.price - b.price);
    }
    return sortedResults;
  }
}
