<div cdkTrapFocus
     aria-live="assertive">
  <div class="modal-header">
    <h3 class="modal-title"
        i18n="@@modalSupportEmailTitle"
        id="modal-header">Support Email</h3>
    <button type="button"
            class="close"
            aria-label="Support Email Close"
            ngbAutoFocus
            (click)="activeModal.close(true)">
      <span aria-hidden="true"><i class='ico-cancel modal_exit_cross'></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p *ngIf="message">{{ message }}</p>
    <p *ngIf="!message">
      <span i18n="@@modalSupportEmailMessage">
        For assistance, please email EU Lightning Agent at
      </span>
      <a href="mailto:EU.LightningAgent@travelctm.com">EU.LightningAgent&#64;travelctm.com</a>
    </p>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="modal_button_okay"
            (click)="activeModal.close(true)"
            i18n="@@modalSupportEmailOK">Ok</button>
  </div>
</div>