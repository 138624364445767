import { Injectable } from '@angular/core';
import { StorageService } from '@sabstravtech/obtservices/angular';
import { Helpers } from '../classes/helpers';
import { LightningUserFavorurite } from '../../../app/vendor/classes/user-favourite.enum';
import { UserService } from '@sabstravtech/obtservices/angular';
import { LanguageEnum, Language } from '../interfaces/language-types';
import EnglishLanguage from '../../languages/en';
import FrenchLanguage from '../../languages/fr';
import GermanLanguage from '../../languages/de';
import ItialianLanguage from '../../languages/it';
import PolishLanguage from '../../languages/pl';
import SwedishLanguage from '../../languages/sv';
import DutchLanguage from '../../languages/nl';
import NorwegianLanguage from '../../languages/nn';
import DanishLanguage from '../../languages/da';
import GreekLanguage from '../../languages/el';
import TagalogLanguage from '../../languages/fil';
import SpanishLanguage from '../../languages/es';

@Injectable()
export class LanguageService {
    public chosenLanguage: Language;
    public languages: Language[] = null;
    public languageList: Language[] = [];


    constructor(
        private userService: UserService,
        public storageService: StorageService
    ) {
        this.languageList.push(new EnglishLanguage());
        this.languageList.push(new DanishLanguage());
        this.languageList.push(new DutchLanguage());
        this.languageList.push(new FrenchLanguage());
        this.languageList.push(new GermanLanguage());
        this.languageList.push(new ItialianLanguage());
        this.languageList.push(new NorwegianLanguage());
        this.languageList.push(new PolishLanguage());
        this.languageList.push(new SwedishLanguage());
        this.languageList.push(new GreekLanguage());
        this.languageList.push(new TagalogLanguage());
        this.languageList.push(new SpanishLanguage());
    }

    getDefaultLanguage(): LanguageEnum {
        const languages: Language[] = this.getUserLanguages();
        // tslint:disable-next-line: quotemark
        const defaultLanguage = Helpers.replaceAll(
            this.userService.getUserFavoriteValue(LightningUserFavorurite.defaultLanguage) || 'en',
            '`',
            ''
        );
        const language = languages.find((lang: Language) => {
            return lang.isLanguage([defaultLanguage]);
        });

        return language ? language.enum : LanguageEnum.English;
    }

    getUserLanguages(): Language[] {
        const languages = (
            this.userService.getUserFavoriteValue(
                LightningUserFavorurite.languages
                // TODO rework for enterprise this.userService.getBaseUserProfile()
            ) || ''
        ).split('|');

        return this.languageList.filter((language: Language): boolean => {
            return language.isLanguage(languages);
        });
    }

    getLanguageFromRoute(): Language {
        const pathname = window.location.pathname.split('/');
        let languageUrl = pathname.pop();
        do {
            console.log('Testing language', languageUrl);
            const language: Language = this.findLanguageByAbbreviation(languageUrl);

            if (language) {
                return language.register();
            }

            languageUrl = pathname.pop();
        } while (languageUrl);
        return this.languageList[0];
    }

    alternateJSONStringify(jsonObject: any): string {
        let jsonString = JSON.stringify(jsonObject);
        jsonString = jsonString.replace(/"/g, '`');

        return jsonString;
    }

    findLanguageByAbbreviation(
        languageUrl: string,
        languages: Language[] = this.languageList
    ): Language {
        return languages.find((lang: Language): boolean => {
            return lang.isLanguage([languageUrl]);
        });
    }
    findLanguageByEnum(
        languageEnum: LanguageEnum,
        languages: Language[] = this.languageList
    ): Language {
        return languages.find((lang: Language): boolean => {
            return lang.enum === languageEnum;
        });
    }

    changeLanguage(language: LanguageEnum): void {
        const languages = this.getUserLanguages();
        const languageIsoCode = this.languages.map((language: Language) => language.abre);

        this.storageService.setSessionItem(
            'choosenLanguage:',
            this.findLanguageByEnum(language).abre
        );

        let pathname = window.location.pathname.split('/').filter((component) => !!component);
        if (pathname.length === 0 && /^http:\/\/localhost/.test(document.URL)) {
            return;
        }
        let languageIndex = pathname.findIndex((path: string) =>
            ['current', ...languageIsoCode].includes(path)
        );

        if (languageIndex > 0) {
            if (languageIsoCode.indexOf(pathname[languageIndex - 1]) !== -1) {
                languageIndex -= 1;
            }
        }
        if (languageIndex >= 0) {
            pathname = pathname.slice(0, languageIndex);
        }
        if (languageIndex === -1) {
            pathname = [];
        }
        const extraPath = this.findLanguageByEnum(language, languages)?.abre;

        if (extraPath) {
            pathname.push(extraPath);
        }

        const newPath = `/${pathname.filter((part) => part !== '').join('/')}/`;
        window.location.pathname = newPath;
    }

    applyLanguage(): void {
        const languages = this.getUserLanguages();
        if (languages.length === 0) {
            return;
        }
        const choosenLanguage: LanguageEnum = this.getLanguageFromRoute().enum;
        const sessionLanguage: LanguageEnum = this.findLanguageByAbbreviation(
            this.storageService.getSessionItem('choosenLanguage:')
        )?.enum;

        if (sessionLanguage && choosenLanguage === sessionLanguage) {
            this.chosenLanguage = this.findLanguageByEnum(sessionLanguage, languages);
            return;
        }

        if (this.userService.getUserFavoriteValue(LightningUserFavorurite.defaultLanguage)) {
            this.chosenLanguage = this.findLanguageByAbbreviation(
                this.userService
                    .getUserFavoriteValue(LightningUserFavorurite.defaultLanguage)
                    .replace(/`/g, '')
                    ?.toLowerCase(),
                languages
            );

            if (this.chosenLanguage) {
                this.changeLanguage(this.chosenLanguage.enum);
            } else {
                this.chosenLanguage = languages[0];
                this.changeLanguage(this.chosenLanguage.enum);
            }
        } else {
            this.changeLanguage(LanguageEnum.English);
        }
    }

    getLanguagesAbbreviations(): string[] {
        return this.languageList.map((language: Language) => language.abre);
    }

    changeLanguageFromUserDetails(
        name: string,
        iso: string,
        availableLanguages: string[]
    ): LanguageEnum {
        if (iso === 'tl') {
            iso = 'fil';
        }
        availableLanguages = availableLanguages.map((lang) => {
            if (lang === 'tl') {
                lang = 'fil';
            }
            return lang;
        });

        const currentLanguage: Language = this.getLanguageFromRoute();
        if (currentLanguage.abre === iso) {
            return currentLanguage.enum;
        }

        this.storageService.setSessionItem('choosenLanguage:', name);
        this.userService.fullUserDetails.allDetails.value.availableLanguages;
        let pathname = window.location.pathname.split('/').filter((component) => !!component);
        if (pathname.length === 0 && /^http:\/\/localhost/.test(document.URL)) {
            return;
        }
        let languageIndex = pathname.findIndex((path: string) =>
            ['current', ...availableLanguages].includes(path)
        );

        if (languageIndex > 0) {
            if (iso.indexOf(pathname[languageIndex - 1]) !== -1) {
                languageIndex -= 1;
            }
        }
        if (languageIndex >= 0) {
            pathname = pathname.slice(0, languageIndex);
        }
        if (languageIndex === -1) {
            pathname = [];
        }
        const extraPath = iso === 'en' ? 'current' : iso;

        if (extraPath) {
            pathname.push(extraPath);
        }

        const newPath = `/${pathname.filter((part) => part !== '').join('/')}/`;
        window.location.pathname = newPath;
    }
}
