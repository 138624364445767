export class Geolocation {
  static locations: { [key: string]: Geolocation } = {};
  lat: number = 0;
  lng: number = 0;

  static getLocation(geoLoc: string): Geolocation {
    if (!geoLoc) {
      geoLoc = '0,0';
    }
    this.locations[geoLoc] = this.locations[geoLoc] || new Geolocation(geoLoc);
    return this.locations[geoLoc];
  }

  constructor(geoLoc: string) {
    try {
      const locSplit = geoLoc.split(',');
      this.lat = +locSplit[0];
      this.lng = +locSplit[1];
    } catch (error) {
      console.error(
        'Can not parse your given string, returns 0,0 geolocation.',
        error
      );
    }
  }
}
