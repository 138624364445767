<div class="profile_information--mi-container">   
        <div class="profile_information--input-container">
                <div class="profile_information--input-label">
                       {{title}}
                </div>
                <select class="profile_information--wrapped-input" [(ngModel)]="value">
                        <option value="">{{placeholder | translate}}</option>
                        <option  *ngFor="let item of data" [value]="item.value">
                                {{item.label || item.value}}
                        </option>
                </select>
                <fa-icon [icon]="faChevronDown"></fa-icon>
        </div>
        <button type="button" *ngIf="value.length" (click)="addNewMiValue()" class="small-button" i18n="@@ProfileInformation-AddNew">Add New</button>
</div>