<span class="Traveller" *ngFor='let traveller of travellers; let i = index'>
    <div *ngIf='i < 2'>
      {{traveller.forename}} {{traveller.surname}}
    </div>
  </span>
  <ng-template #tipContent>
    <span *ngFor='let traveller of travellers; let i = index'>
      <div *ngIf='i >= 2'>{{traveller.forename}} {{traveller.surname}}&nbsp;</div>
    </span>
  </ng-template> 
  <section (mouseleave)="tt2.close()" *ngIf='travellers?.length > 2' class="container">
    <span class="Traveller additionalTravellers"
          container="body"
          [ngbTooltip]="tipContent"
          triggers="manual"
          #tt2="ngbTooltip"
          (mouseenter)="tt2.open()"
          (focus)="tt2.open()"
          (blur)="tt2.close()"
          tabindex="0">
      + {{travellers.length - 2}}</span>
  </section>