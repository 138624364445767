import { Pipe, PipeTransform } from '@angular/core';
import * as AirportList from '../data/air_postcodes.json';

interface AirportPostCode {
  ACode: string;
  PostCode: string;
}
@Pipe({
  name: 'airportPostcode',
})
export class AirportPostcodePipe implements PipeTransform {
  transform(value: string): string {
    const test = (<any>AirportList).default
      ? <AirportPostCode[]>(<any>AirportList).default
      : <AirportPostCode[]>AirportList;

    return (
      test.find((item: AirportPostCode) => {
        return item.ACode === value;
      }) || { Postcode: null }
    ).PostCode;
  }
}
