<div *ngFor="let option of (alphabetical ? arrangeAlphabetically(items | keyvalue) : (items | keyvalue))">
    <mat-checkbox color="primary"
                  class="light-primary"
                  name="{{option.value.key}}"
                  [(ngModel)]="option.value.selected"
                  id="filter-checkbox-{{option.value.key || option.value.display | removeSpaces}}{{inbound ? '-inbound' : ''}}"
                  (change)="change.emit()"
                  (keydown.enter)="change.emit">
        {{option.value.display ? (option.value.display | translate) : (option.key | translate) }}
    </mat-checkbox>
</div>
