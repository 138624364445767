import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../vendor/components/base_components/base-componet';
import { ActivatedRoute } from '@angular/router';
import { LogonService } from '@sabstravtech/obtservices/angular';
@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent extends BaseComponent implements OnInit, AfterViewInit {
  token: string;
  source: string;

  constructor(
    private logonService: LogonService,
    private route: ActivatedRoute,
    title: Title,
    public translator: TranslateService
  ) {
    super(title, translator);
    this.setTitle(this.translator.instant('Password reset'));
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
      this.source = params['source'];
    });
  }

  ngAfterViewInit() {
    this.logonService.openResetPasswordDialog(this.token, this.source);
  }
}

