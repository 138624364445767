
<ng-container *ngIf="displayAmenities">
  <h4 i18n="@@modalRoutehappyAmenities">Amenities</h4>
  <div class="flight-amenity" *ngIf="results.data?.amenitySummary[0].seat">
    <h5>
      <i class="ico-plain-seat" aria-hidden="true"></i>
      <span i18n="@@modalRoutehappyAmenitiesSeat">Seat</span>
    </h5>
    <div class="amenity-details">
        <span>&nbsp;-&nbsp;</span>{{results?.data?.amenitySummary[0].seat.displayText}}
    </div>
  </div>
  <div class="flight-amenity" *ngIf="results.data?.amenitySummary[0].wifi">
    <h5>
      <fa-icon 
        aria-hidden="true"
        [icon]="faWifi">
      </fa-icon>
      <span i18n="@@modalRoutehappyAmenitiesWifi">Wi-Fi</span>
    </h5>
    <div class="amenity-details">
        <span>&nbsp;-&nbsp;</span>{{results?.data?.amenitySummary[0].wifi.displayText}}
    </div>
  </div>
  <div class="flight-amenity" *ngIf="results.data?.amenitySummary[0].power">
    <h5>
      <fa-icon 
        aria-hidden="true"
        [icon]="faPlug">
      </fa-icon>
      <span i18n="@@modalRoutehappyAmenitiesPower">Power</span>
    </h5>
    <div class="amenity-details">
        <span>&nbsp;-&nbsp;</span>{{results?.data?.amenitySummary[0].power.displayText}} {{results?.data?.amenity?.powers[0].type ? ("- Type: " + results?.data.amenity?.powers[0].type) : ""}}
    </div>
  </div>
  <div class="flight-amenity" *ngIf="results.data?.amenitySummary[0].entertainment">
    <h5>
      <fa-icon 
        aria-hidden="true"
        [icon]="faPlayCircle">
      </fa-icon>
      <span i18n="@@modalRoutehappyAmenitiesEntertainment">Entertainment</span>
    </h5>
    <div class="amenity-details">
        <span>&nbsp;-&nbsp;</span>{{results?.data?.amenitySummary[0].entertainment.displayText}}
    </div>
  </div>
  <div class="flight-amenity" *ngIf="results.data?.amenitySummary[0].freshFood">
    <h5>
      <fa-icon 
        aria-hidden="true"
        [icon]="faUtensils">
      </fa-icon>
      <span i18n="@@modalRoutehappyAmenitiesFood">Food</span>
    </h5>
    <div class="amenity-details">
        <span>&nbsp;-&nbsp;</span>{{results?.data?.amenitySummary[0].freshFood.displayText}}
    </div>
  </div>
  <div class="flight-amenity" *ngIf="results.data?.amenitySummary[0].beverage">
    <h5>
      <fa-icon 
        aria-hidden="true"
        [icon]="faGlassWhiskey">
      </fa-icon>
      <span i18n="@@modalRoutehappyAmenitiesBeverage">Beverage</span>
    </h5>
    <div class="amenity-details">
        <span>&nbsp;-&nbsp;</span>{{results?.data?.amenitySummary[0].beverage.displayText}}
    </div>
  </div>
  <div class="flight-amenity" *ngIf="results.data?.amenitySummary[0].aircraft">
    <h5>
      <fa-icon 
        aria-hidden="true"
        [icon]="faPlane">
      </fa-icon>
      <span i18n="@@modalRoutehappyAmenitiesAircraftLayout">Aircraft Layout</span>
    </h5>
    <div class="amenity-details">
        <span>&nbsp;-&nbsp;</span>{{results?.data?.amenitySummary[0].aircraft.displayText}}, {{results?.data?.amenitySummary[0].layout.displayText}}
    </div>
  </div>
</ng-container>
<div class="ticket-features" *ngIf="displayTicketFeatures">
  <h4 i18n="@@modalRoutehappyAmenitiesTicketFeatures">Ticket Features</h4>
  <div class="flight-amenity" *ngIf="results?.data.checkedBag.length">
    <h5>
      <span i18n="@@modalRoutehappyAmenitiesCheckedBaggage">Checked Baggage</span>
    </h5>
    <div class="amenity-details">
        {{results?.data.checkedBag[0].description}}
    </div>
  </div>
  <div class="flight-amenity" *ngIf="results?.data.carryOnBag.length">
    <h5>
      <span i18n="@@modalRoutehappyAmenitiesCarryonBaggage">Carry-on Baggage</span>
    </h5>
    <div class="amenity-details">
        {{results?.data.carryOnBag[0].description}}
    </div>
  </div>
  <div class="flight-amenity" *ngIf="results?.data.seatSelection.length">
    <h5>
      <span i18n="@@modalRoutehappyAmenitiesSeatSelection">Seat Selection</span>
    </h5>
    <div class="amenity-details">
        {{results?.data.seatSelection[0].description}}
    </div>
  </div>
  <div class="flight-amenity" *ngIf="results?.data.boardingPriority.length">
    <h5>
      <span i18n="@@modalRoutehappyAmenitiesBoardingPriority">Boarding Priority</span>
    </h5>
    <div class="amenity-details">
        {{results?.data.boardingPriority[0].description}}
    </div>
  </div>
  <div class="flight-amenity" *ngIf="results?.data.advanceChange.length && (results?.data.advanceChange[0].anytime || results?.data.advanceChange[0].beforeDeparture || results?.data.advanceChange[0].afterDeparture)">
    <h5>
      <span i18n="@@modalRoutehappyAmenitiesAdvanceChange">Advance Change</span>
    </h5>
    <div class="amenity-details" *ngIf="results?.data.advanceChange[0].anytime">
        {{results?.data.advanceChange[0].anytime.description}} <span i18n="@@modalRoutehappyAmenitiesAnytime">(Anytime)</span>
    </div>
    <div class="amenity-details" *ngIf="results?.data.advanceChange[0].beforeDeparture">
      {{results?.data.advanceChange[0].beforeDeparture.description}} <span i18n="@@modalRoutehappyAmenitiesBeforeDeparture">(Before Departure)</span>
    </div>
    <div class="amenity-details" *ngIf="results?.data.advanceChange[0].afterDeparture">
      {{results?.data.advanceChange[0].afterDeparture.description}} <span i18n="@@modalRoutehappyAmenitiesAfterDeparture">(After Departure)</span>
    </div>
  </div>
  <div class="flight-amenity" *ngIf="results?.data.cancellation.length && results?.data.cancellation[0].anytime">
    <h5>
      <span i18n="@@modalRoutehappyAmenitiesCancellation">Cancellation</span>
    </h5>
    <div class="amenity-details">
        {{results?.data.cancellation[0].anytime.description}}
    </div>
  </div>
  <div class="flight-amenity" *ngIf="results?.data.sameDayChange.length">
    <h5>
      <span i18n="@@modalRoutehappyAmenitiesSameDayChange">Same Day Change</span>
    </h5>
    <div class="amenity-details">
        {{results?.data.sameDayChange[0].description}}
    </div>
  </div>
  <div class="flight-amenity" *ngIf="results?.data.checkInPriority.length">
    <h5>
      <span i18n="@@modalRoutehappyAmenitiesCheckinPriority">Check-in Priority</span>
    </h5>
    <div class="amenity-details">
        {{results?.data.checkInPriority[0].description}}
    </div>
  </div>
  <div class="flight-amenity" *ngIf="results?.data.loungeAccess.length">
    <h5>
      <span i18n="@@modalRoutehappyAmenitiesLoungeAccess">Lounge Access</span>
    </h5>
    <div class="amenity-details">
        {{results?.data.loungeAccess[0].description}}
    </div>
  </div>
</div>
<ng-container *ngIf="!displayAmenities && !displayTicketFeatures">
  <div class="error-message" i18n="@@modalRoutehappyAmenitiesUnavailable">Amenities & Ticket Features unavailable for this flight.</div>
</ng-container>
