import { Pipe, PipeTransform } from '@angular/core';
import { GetRailStationFetcher } from '@sabstravtech/obtservices/angular';
import { RailStation} from '@sabstravtech/obtservices/angular';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Pipe({
  name: 'railStationNameByCode',
  pure: true
})

export class RailStationNameByCodePipe implements PipeTransform {
  constructor(private getRailStation: GetRailStationFetcher) {}

  transform(value: string): Observable<string> {
    if (value) {
      return this.getRailStation.fetchList({ code: value })
      .pipe(
        take(1),
        map((results: RailStation) => {
          return ` ${results.name}`
        })
      );
    } else {
      return of('Converting...');
    }
  }
}