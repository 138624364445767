import { Pipe, PipeTransform } from '@angular/core';
import { Helpers } from '../../vendor/classes/helpers';

@Pipe({
  name: 'toHtml'
})
export class ToHtmlPipe implements PipeTransform {
  transform(value: string): string {
    return Helpers.replaceAll(value, '\n', '<br>');
  }
}
