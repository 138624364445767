import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class WCAGFocusHandler {
  //Used to handle changing focus across components for WCAG reasons
  public skipToBasketClicked: EventEmitter<null> = new EventEmitter();;

  constructor() {
  }
}
