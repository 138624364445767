import { Component, OnInit} from '@angular/core';
import { LightningModalTypes } from '../../../vendor/classes/modal-types.enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WithSubscriptionComponent , EnterpriseBasketService} from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-scrathpad-modal',
  templateUrl: './scratchpad-modal.component.html',
  styleUrls: ['./scratchpad-modal.component.scss']
})
export class ScratchpadModalComponent extends WithSubscriptionComponent implements OnInit {

  newNote = "";
  savedNotes = [];
  basketId = '';
  basketRef = '';
  saving = false;
  loading = false;

  constructor(
    private activeModal: NgbActiveModal,
    private basketService: EnterpriseBasketService
  ) {
    super();
  }

  ngOnInit(): void {
    // grab the saved notes
    console.log(this.basketId);
    this.loadBasketNotes();
  }

  close(): void {
    this.activeModal.close(null);
  }

  valid(): boolean {
    return this.newNote.length > 0;
  }

  submit(): void {
    console.log('+++ Submit the note - clear the variable - load the list of notes +++');

    this.saving = true;

    this.subscribe(this.basketService.createBasketScratchpadNote(this.basketId, this.newNote), data => {
      this.saving = false;
      this.newNote = '';
      this.loadBasketNotes();
      console.log('+++ Note saved: ', data, ' +++')
    },
    error => {
      this.saving = false;
      console.error(`+++ Error saving basket: ${error} +++`);
    });
  }

  loadBasketNotes(): void {
    this.loading = true;
    this.subscribe(this.basketService.getBasketScratchpadNotes(this.basketId), data => {
      console.log(data);
      this.savedNotes = data.sort((a,b) => {
        return Number(b.id) - Number(a.id);   // since the id is auto incrementing, we can just sort by it
      });
      this.loading = false;
    }, error => {
      console.error(`+++ Error loading basket notes: ${error} +++`);
      this.loading = false;
    })
  }

}

LightningModalTypes.ScratchpadModalComponent.component = ScratchpadModalComponent;
