<div cdkTrapFocus aria-live="assertive">
  <div class="modal-body">
    <p i18n="@@modalWheelchairTaxi">
      You have requested a Wheelchair Assisted Vehicle, please provide as much information as
      possible in the special requirements fields. Such as:
    </p>
    <ul>
      <li i18n="@@modalWheelchairExampleType">Wheelchair Type - manual, powered or scooter</li>
      <li i18n="@@modalWheelchairExampleStorage">
        Wheelchair Storage - folded, unfolded or no storage is required
      </li>
      <li i18n="@@modalWheelchairExampleRamp">Ramp Required - yes or no</li>
      <li i18n="@@modalWheelchairExampleMobility">
        Passenger Mobility - stay in chair or transfer to car seat
      </li>
    </ul>

    <br /><br />

    <form #wheelchairForm="ngForm">
      <label for="specialRequirements" i18n="@@modalWheelchairSpecialRequirements"
        >Special Requirements</label
      ><br />
      <textarea
        [(ngModel)]="specialRequirementsText"
        name="specialRequirementsTextbox"
        maxlength="200"
        placeholder="Max 200 Characters"
        class="specialRequirementsText input-box input-box--border"
        id="specialRequirements"
        required></textarea>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="modal_button_okay small-button"
      (click)="activeModal.close(specialRequirementsText)"
      i18n="@@modalErrorSave"
      [disabled]="!wheelchairForm.valid">
      Save
    </button>
  </div>
</div>
