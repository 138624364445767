import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import {
  FlightJourney,
  EnterpriseSearchService,
  HelperRoutines
} from '@sabstravtech/obtservices/angular';
import moment from 'moment';
import { FlightSearchType, PreferredFlags } from '@sabstravtech/obtservices/base';

@Component({
  selector: 'app-flight-header',
  templateUrl: './flight-header.component.html',
  styleUrls: ['./flight-header.component.scss']
})
export class FlightHeaderComponent implements OnInit {
  @Input() flightJourney: FlightJourney;
  @Input() lastFlightJourney: FlightJourney;
  @Input() allJourneys: FlightJourney[];
  @Input() isReturn = false;
  @Input() source: string;
  @Input() duration: number;
  @Output() displayLegs: EventEmitter<boolean> = new EventEmitter();
  @Input() searchType: any;
  @Input() preferred: number = PreferredFlags.none;
  @Input() preferredMessages: Record<PreferredFlags, string>;
  @Input() preferenceLevel = null;
  @Input() tmcPreferredLogoUrl = null;
  PreferredFlags = PreferredFlags;
  public FlightSearchType = FlightSearchType;
  public logoUrl: string = '';
  constructor(public searchService: EnterpriseSearchService, private helpers: HelperRoutines) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.flightJourney) {
      this.getLogo(changes.flightJourney.currentValue);
    }
  }

  ngOnInit(): void {
    if (this.flightJourney) {
      this.getLogo(this.flightJourney);
      this.flightJourney.dateTimeDeparting = this.helpers.sanitiseDateTime(
        this.flightJourney.dateTimeDeparting
      );
      if (this.lastFlightJourney) {
        this.lastFlightJourney.dateTimeArriving = this.helpers.sanitiseDateTime(
          this.lastFlightJourney?.dateTimeArriving
        );
      }
    }
   
  }

  getLogo(flightJourney) {
    this.logoUrl = 'https://images.sabscorp.com/images/TourOp/svg/' + flightJourney?.marketingCarrier + '.svg';
  }

  parseDateToMoment(dateString: string): moment.Moment {
    return moment(this.helpers.sanitiseDateTime(dateString));
  }

  dayDifference(dept: string, arr: string): string {
    const diff = this.helpers.dayDifference(
      this.helpers.sanitiseDateTime(dept),
      this.helpers.sanitiseDateTime(arr)
    );
    return diff ? '+' + diff : '';
  }

}

