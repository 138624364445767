<div class="pricePoint"
     [ngStyle]="{minHeight: flightDetails.greenerChoice ? '110px': '80px'}">
      <div class="pricePoint-wrapper">
            <div *ngIf='ShowSelectButton && !flightDiscriptor?.unavailable else disabled_price'
                 class="pricePill"
                 [ngClass]="{'dissable-cabin-class': !flightDiscriptor.selected && direction === FlightDirectionEnum.Outbound && dissableCabinClass }"
                 (click)="selectFlight(flightDiscriptor)"
                 (keydown.enter)="selectFlight(flightDiscriptor)"
                 tabindex="0"
                 [attr.aria-label]="ticketAriaLabel"
                 role="button">
                  <!-- <span class="sr-only">{{flightDiscriptor.TicketClass}}</span> -->
                  <!-- <span *ngIf="flightDiscriptor.BrandSelected"
                  class="brandSelected">{{flightDiscriptor.BrandSelected}}</span> -->
                  <!-- <br *ngIf="flightDiscriptor.BrandSelected"> -->
                  <div class="price"
                       *ngIf="flightDiscriptor">
                        <!-- <span *ngIf='(!flightDiscriptor.FlightSelected || !basketService.selected_flights.outbound )&& flightDiscriptor.Header.FlightType === "RO"'
                        i18n="@@flightClassFrom">From:</span> -->
                        <span
                              *ngIf="!flightDiscriptor.selected || (direction!==FlightDirectionEnum.Outbound && direction!==FlightDirectionEnum.DualSingleOutbound)">{{flightDiscriptor.total.price
                              |currency:flightDiscriptor.total.currency:'symbol'
                              : '0.0-0'}}
                        </span>
                        <app-warning-tags [ticket]="flightDiscriptor"></app-warning-tags>

                        <span *ngIf="flightDiscriptor.selected &&( direction===FlightDirectionEnum.Outbound || direction===FlightDirectionEnum.DualSingleOutbound)"
                              i18n="@@flightClassDeselect">Deselect</span>
                        <button *ngIf="showDump"
                                (click)="alwaysLog(flightDiscriptor)"
                                i18n="@@dump">Dump</button>
                  </div>
            </div>
            <div class="seats_remaining"
                 *ngIf="getSeatsRemaining(flightDiscriptor, direction) < 9">
                  <div *ngIf="getSeatsRemaining(flightDiscriptor, direction) > 1; else oneSeatLeft">
                        {{ getSeatsRemaining(flightDiscriptor, direction) }} <span i18n="@@flightSeatsLeft">Seats
                              left</span>
                  </div>
                  <ng-template #oneSeatLeft>
                        {{ getSeatsRemaining(flightDiscriptor, direction) }} <span i18n="@@flightSeatLeft">Seat
                              left</span>
                  </ng-template>
            </div>
            <ng-template #disabled_price>
                  <div class="pricePill pill_disabled"
                       aria-disabled="true">
                        <!-- <span class="sr-only">{{flightDiscriptor.TicketClass}}</span> -->
                        <!-- <span *ngIf="flightDiscriptor.BrandSelected">{{flightDiscriptor.BrandSelected}}</span> -->
                        <br>
                        <div class="price"
                             tabindex="-1">
                              <!-- <span *ngIf='flightDiscriptor.Header.FlightType === "RO"'
                              i18n="@@flightClassFrom2">From:</span> -->
                              <span *ngIf="!selected">{{flightDiscriptor.total.price|currency:flightDiscriptor.total.currency:'symbol'
                                    :
                                    '0.0-0'}}
                              </span>
                              <app-warning-tags [ticket]="flightDiscriptor"></app-warning-tags>
                        </div>
                  </div>
            </ng-template>
            <div class="special-fares-container" *ngIf="showSpecialFares(flightDiscriptor, direction)">
                  <app-special-fare-icon [flight]="flightDiscriptor" [direction]="direction"></app-special-fare-icon>
            </div>
      </div>

      <div *ngIf="flightDiscriptor.co2PerPassenger"
           class="c02-wrapper">
            {{flightDiscriptor.co2PerPassenger}}<span i18n="@flight_kg">kg</span>&nbsp;<span
                  i18n="@flight_leg_co2">CO</span><sub i18n="@flight_leg_2">2</sub>&nbsp;<span
                  i18n="@flight_pp">pp</span>
      </div>
</div>