import { Component, OnInit } from '@angular/core';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ResendConfirmationEmailOption } from '@sabstravtech/obtservices/base'

@Component({
  selector: 'app-resend-confirmation',
  templateUrl: './resend-confirmation.component.html',
  styleUrls: ['./resend-confirmation.component.scss']
})
export class ResendConfirmationComponent implements OnInit {

  resendOptions = Object.values(ResendConfirmationEmailOption);
  sendTo:ResendConfirmationEmailOption;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  cancel(): void {
    this.activeModal.close();
  }

  ok(): void {
    this.activeModal.close(this.sendTo);
  }

}
LightningModalTypes.ResendConfirmationComponent.component = ResendConfirmationComponent;