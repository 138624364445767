import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
// import { BaseModalComponent } from './base-modal.component';
import { Subscription, Subject, Observable, of, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import {
  UserService,
  WithSubscriptionComponent,
  HelperRoutines,
  Company,
  User,
  LogonService,
  EnterpriseBasketService,
  EnterpriseMyBookingsService,
  EnterpriseSearchService,
  BookerType,
  Office
} from '@sabstravtech/obtservices/angular';

import { Traveller } from '@sabstravtech/obtservices/base';
import { LanguageService } from '../../../../vendor/services/language.service';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

interface UserQuery {
  email?: string;
  forename?: string;
  surname?: string;
}

interface UserResponse {
  Admin: number;
  ApprovalProcess: number;
  canBookFor: string;
  disabled: number;
  email: string;
  employeeNumber: string;
  forename: string;
  surname: string;
  username: string;
}

interface ListUserResponse {
  limited: boolean;
  results: UserResponse[];
  total: number;
}

@Component({
  selector: 'app-become-user',
  templateUrl: './become-user.component.html',
  styleUrls: ['./become-user.component.scss']
})
export class BecomeUserComponent extends WithSubscriptionComponent implements OnInit {
  /******************************************
   *	Variables							                  *
   ******************************************/
  isLoading: boolean = false;
  request: Subscription;
  limited: boolean = false;
  total: number = 0;
  errorMessage: string = '';
  companyList: any = null;
  filteredCompanyList: any = null;
  userList: any = null;
  filteredUserList: any = null;
  companySearchString: string;
  userEmailSearchString = '';
  userForenameSearchString = '';
  userSurnameSearchString = '';
  office: { id: string; } = { id: undefined };
  formatter = (result: any) => result.value;
  findUserModel: string;
  search$: Subject<UserQuery> = new Subject<UserQuery>();
  searchSubscription = null;
  openOfficeCompany: any = null;
  isSearchUserCanBookForLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  /*****************************************
   *	Functions							                 *
   *****************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private userService: UserService,
    private logonService: LogonService,
    private helpers: HelperRoutines,
    private languageService: LanguageService,
    public myBookingsService: EnterpriseMyBookingsService,
    public searchService: EnterpriseSearchService,
    public readonly basketService: EnterpriseBasketService
  ) {
    super();
  }

  /**
    @desc - will need to get list of companies and users on init - so that they can be displayed
  **/
  ngOnInit(): void {
    this.isLoading = true;
    const isRoot = this.userService.userIsRoot();
    const isTmcAdmin = this.userService.userIsTmcAdmin();
    const userTmcId = this.userService.userTmcId();

    this.subscribe(
      this.userService.getUserCompanies(),
      result => {
        this.companyList =
          isTmcAdmin && !isRoot
            ? result.filter(company => {
              if (+company.tmc.id === userTmcId) return company;
            })
            : result;

        this.filteredCompanyList = this.companyList.sort((a: Company, b: Company) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        this.isLoading = false;
      },
      error => {
        console.error('+++ Error loading companies: ', error, ' +++');
        this.isLoading = false;
      }
    );
  }

  resetUserFilters(): void {
    this.userEmailSearchString = '';
    this.userForenameSearchString = '';
    this.userSurnameSearchString = '';
    this.filterUsers();
  }

  filterUsers() {
    this.filteredUserList = this.userList.filter(user =>
      user.email.toLowerCase().includes(this.userEmailSearchString.toLowerCase())
    );
    this.filteredUserList = this.filteredUserList.filter(user =>
      user.forename.toLowerCase().includes(this.userForenameSearchString.toLowerCase())
    );
    this.filteredUserList = this.filteredUserList.filter(user =>
      user.surname.toLowerCase().includes(this.userSurnameSearchString.toLowerCase())
    );
  }

  /**
   * @description - filter the companies according to the inputted string
   */
  filterCompanies(companyName: string): void {
    console.log(`+++ Filter company by name: ${companyName} +++`);
    this.filteredCompanyList = this.helpers
      .clone(this.companyList)
      .filter(company => company.name.toLowerCase().includes(companyName.toLowerCase()));
  }

  /**
    @desc - open the office list for a passed in company
  **/
  openOffices(company: any): void {
    if (this.openOfficeCompany && this.openOfficeCompany.id === company.id) {
      this.openOfficeCompany = null;
    } else {
      this.openOfficeCompany = company;
    }
  }

  /**
  @desc: load the users for a selected office
**/
  loadUsers(office: Office) {
    this.emptyUsers();
    this.subscribe(this.userService.getOfficeUsersById(office.id), (result: Office) => {
      if (result) {
        this.userList = result.users?.filter((user: User) => user.bookerType !== BookerType.Traveller);
        this.filteredUserList = this.userList.sort((a: User, b: User) => {
          if (a.email.toLowerCase() > b.email.toLowerCase()) {
            return 1;
          } else if (b.email.toLowerCase() > a.email.toLowerCase()) {
            return -1;
          } else {
            return 0;
          }
        });
      }
    });
  }
  /**
    @desc - empty out the user list
  **/
  emptyUsers(): void {
    this.userList = [];
    this.filteredUserList = [];
  }

  resetCompany() {
    this.emptyUsers();
    this.openOfficeCompany = false;
  }
  /**
   @desc - select a user to become
 **/
  async selectUser(user: User): Promise<void> {
    console.log(user);
    await this.logonService.becomeUser(user.email);
    this.basketService.resetBaskets();
    this.searchService.removeAllTravellers();
  }

  /**
    @desc - become a user, call become user, close the modal, start a loading screen, start the login process for the selected user and reset all user data in session storage
  **/
  becomeUser(user: UserResponse): void {
    //   console.log('Become User', user);
    //   this.userService.becomeUser(user.username).subscribe(
    //     data => {
    //       console.log('Become User', data);
    //       if (data.Success) {
    //         // now we need to start the login process again for this user
    //         // first of all save the current users details to session storage
    //         // ensure to clear out results,params and other guff first
    //         this.basketService.startAgain(false);
    //         // now save the current users data so we can login back in as them
    //         this.userService.saveCurrentUserData();
    //         // start the login process for this new user  - overwrite session storage  - call search service user start thing  - display a loading doohickey - redirect to main
    //         this.userService.becomingUser = true;
    //         // start the process to login as the new user
    //         // get a number once token
    //         this.request = this.userService.getTokenNumberOnce().subscribe(
    //           data => {
    //             console.log('data', data);
    //             // now we have a new token - call list users for the list of companies
    //             if (data.token) {
    //               const token = data.token;
    //               this.logonService.callListUserData(token, user.email);
    //               this.userService.setUserAgent(user.email);
    //               const language = this.languageService.getDefaultLanguage();
    //               const languages = this.languageService.getUserLanguages();
    //               if (languages.length) {
    //                 this.userService.setUserAgentLanguage(language);
    //               }
    //               this.router.navigate(['search']);
    //               this.activeModal.close();
    //             } else {
    //               this.isLoading = false;
    //               this.errorMessage = 'Failed to become selected user, please try again.';
    //             }
    //           },
    //           error => {
    //             console.log('error', error);
    //             // error out
    //             this.isLoading = false;
    //             this.errorMessage = 'Failed to become selected user, please try again.';
    //           }
    //         );
    //         // this.logonService.
    //       } else {
    //         this.isLoading = false;
    //         this.errorMessage = 'Failed to become selected user, please try again.';
    //       }
    //     },
    //     error => {
    //       console.log('error', error);
    //       // error out
    //       this.isLoading = false;
    //       this.errorMessage = 'Failed to become selected user, please try again.';
    //     }
    //   );
  }
  // findUser = (term$: Observable<string>): Observable<Traveller[]> => {
  //   console.log(`+++ Term: ${term$} +++`);
  //   return of(null);
  // };

  findUser = (text$: Observable<string>): Observable<any[]> => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    return debouncedText$.pipe(
      switchMap(
        (search: string): Observable<string[]> =>
          search.length <= 2
            ? of([])
            : this.myBookingsService.getSearchUserCanBookFor(
              search,
              this.isSearchUserCanBookForLoading,
              true
            )
      )
    );
  };
}

