<div cdkTrapFocus
     aria-live="assertive">
  <div class="modal-header">
    <h3 class="modal-title"
        i18n="
        @@modalCardErrorTitle"
        id="modal-header">Unable to find card!</h3>
    <button type="button"
            class="close"
            aria-label="Unable to Find Card Close"
            ngbAutoFocus
            i18n-aria-label
            (click)="activeModal.close(true)">
      <span aria-hidden="true"><i class='ico-cancel modal_exit_cross'></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p i18n="
       @@modalCardErrorUnableToFindCard">
      Unable to find the added card.
    </p>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="modal_button_okay"
            (click)="activeModal.close(true)"
            i18n="@@modalCardErrorOK">Ok</button>
  </div>
</div>