import { LanguageEnum, Language } from '../vendor/interfaces/language-types';
import localeSe from '@angular/common/locales/se';
import localeSeExtra from '@angular/common/locales/extra/se';

export default class SwedishLanguage extends Language {
  protected locale = localeSe;
  protected localeExtra = localeSeExtra;
  protected details = {
    image: 'images/flags/sv.png',
    title: 'Svenska',
    enum: LanguageEnum.Swedish,
    aira: 'Svenska språket valt',
    select: 'Välj svenska språket',
    abre: 'sv',
  };
}
