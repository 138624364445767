import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import {
    EnterpriseBasketService,
    EnterpriseSearchService,
    ServiceType,
    ModalOpenerService,
    IrlJourneyType,
    IrlJourneyOptions,
    IrlJourneyOption
} from '@sabstravtech/obtservices/angular';

import {
    IrlTempParams,
    IRLEnterpriseSearchInterface,
    IrlTicketOptionWithSelected
} from '@sabstravtech/obtservices/base';

import { DeviceDetector } from '../../../../vendor/services/device-detector.service';

import { LightningModalTypes } from '../../../../vendor/classes/modal-types.enum';
import { BaseComponent } from '../../../../vendor/components/base_components/base-componet';
import moment from 'moment';

@Component({
    selector: 'app-irl',
    templateUrl: './irl.component.html',
    styleUrls: ['./irl.component.scss']
})
export class IrlComponent extends BaseComponent implements OnInit {
    focus$ = new Subject<string>();
    searchParams: IRLEnterpriseSearchInterface;
    outboundDateTime: string;
    inboundDateTime: string;
    isReturn: boolean;
    results: IrlJourneyOptions[];
    IrlJourneyType: typeof IrlJourneyType = IrlJourneyType;

    constructor(
        public deviceDetector: DeviceDetector,
        public searchService: EnterpriseSearchService,
        public title: Title,
        private basketService: EnterpriseBasketService,
        private modalService: ModalOpenerService,
        public translateService: TranslateService
    ) {
        super(title, translateService);
    }

    ngOnInit(): void {
        this.setTitle(this.translateService.instant('European Rail Results - LightUk'));
        this.subscribe(
            this.searchService.searches[ServiceType.InternationalRail].results,
            (results: IrlJourneyOptions[]) => {
                if (results.length) {
                    // ! this is needed for setticket function, which will be moved to obts
                    this.results = results;
                }
            }
        );
        this.searchParams = this.searchService.searches[ServiceType.InternationalRail];

        this.outboundDateTime = moment(this.searchParams.originalUserSearch.outboundDepartureDateTime).format(
            'ddd MMM DD YYYY'
        );
        this.inboundDateTime = moment(this.searchParams.originalUserSearch.returnDepartureDateTime).format(
            'ddd MMM DD YYYY'
        );
        this.isReturn = this.searchParams.journeyType === IrlJourneyType.ReturnJourney;
    }

    /**
 @desc - do another search - based off of the input at the top of the screen and the option entered in the initial search
**/
    reSearch(): void {
        this.searchService.search_objects[ServiceType.InternationalRail].chosen = true;
        this.isReturn = this.searchParams.journeyType === IrlJourneyType.ReturnJourney;
        if (this.searchParams.offlineRequest) {
            this.searchService.startSearches('search');
        } else {
            this.searchService.startSearches();
        }
    }

    /**
 @desc - sets the outbound ticket on obts or add to basket
 @param - ticket: IrlTicketOptionWithSelected
 @param - isOutbound: boolean
**/
    setTicket(ticket: IrlTicketOptionWithSelected, isOutbound: boolean): void {
        const searchQuery = this.searchParams.getSearchQuery();
        const fullTicketObject: IrlJourneyOption = this.results[0].outbound.find(
            (item: IrlJourneyOption) =>
                item.identifiers.journeyIdentifier === ticket.identifiers.journeyIdentifier
        );
        if (isOutbound && !this.isReturn) {
            this.searchParams.addSingleJourneyToBasket(
                this.results[0].sourceId.toString(),
                searchQuery,
                ticket,
                fullTicketObject
            );
            this.basketService.toggleMenu();
        } else if (isOutbound && this.isReturn) {
            if (!ticket.selected) {
                this.searchParams.addOutboundAndFilter(ticket, fullTicketObject);
            } else {
                this.searchParams.filterSelected();
            }
        } else {
            const inboundJourneyOption: IrlJourneyOption = this.results[0].return.find(
                (item: IrlJourneyOption) =>
                    item.identifiers.journeyIdentifier === ticket.identifiers.journeyIdentifier
            );
            this.searchParams.addReturnJourneyToBasket(
                this.results[0].sourceId.toString(),
                searchQuery,
                ticket,
                inboundJourneyOption
            );
            this.basketService.toggleMenu();
        }
    }

    /**
 @desc - get the next page of results based on the journeyType and later params
 **/
    getNextResultsPage(journeyType: IrlJourneyType, later: boolean = true): void {
        //! this is probs correct functionality but arrivebefore/depart after doesnt work
        switch (journeyType) {
            case IrlJourneyType.SingleJourney:
                if (later) {
                    const departureTime =
                        this.results[0].outbound[this.results[0].outbound.length - 1]
                            .departDateTime;
                    this.searchParams.outboundDepartureDateTime = moment(departureTime);
                } else {
                    const arrivalTime = moment(this.results[0].outbound[0].departDateTime).subtract(
                        { hours: 2 }
                    );
                    this.searchParams.outboundDepartureDateTime = arrivalTime;
                }
                break;
            case IrlJourneyType.ReturnJourney:
                if (later) {
                    const departureTime =
                        this.results[0].return[this.results[0].return.length - 1].departDateTime;
                    this.searchParams.returnDepartureDateTime = moment(departureTime);
                } else {
                    const arrivalTime = moment(this.results[0].return[0].departDateTime).subtract({
                        hours: 2
                    });
                    this.searchParams.returnDepartureDateTime = moment(arrivalTime);
                }
                break;
        }

        if (this.searchParams.isValid.value) {
            this.searchService.search_objects[ServiceType.InternationalRail].chosen = true;
            this.searchService.determineHighestSearchPriority()
            this.searchService.startSearches();
        } else {
            this.modalService.open(
                LightningModalTypes.ModalErrorComponent,
                { centered: true },
                { error: 'Search params appear to be invalid' }
            );
        }
    }
}
