import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EnterpriseSearchService, ServiceType } from '@sabstravtech/obtservices/angular';
import { Geolocation } from '../../../../vendor/classes/geolocation';

@Component({
  selector: 'app-parking-map-view',
  templateUrl: './parking-map-view.component.html',
  styleUrls: ['./parking-map-view.component.scss']
})
export class ParkingMapViewComponent implements OnInit, OnChanges {
  @Input() mapData: any; // ParkingQuote;

  geolocation: Geolocation;
  pins: (Geolocation & { message: string })[];
  public zoom: number = 15;
  public height: number = 600;

  constructor(public searchService: EnterpriseSearchService) {}

  ngOnInit(): void {}

  onCloseMapView() {
    this.searchService.searches[ServiceType.Parking].listViewToggle.next(null);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.geolocation = new Geolocation(`${this.mapData.misc.latitude},${this.mapData.misc.longitude}`);
    this.pins = [
      {
        ...new Geolocation(`${this.mapData.misc.latitude},${this.mapData.misc.longitude}`),
        message: this.mapData.carPark.name
      }
    ];
  }
}
