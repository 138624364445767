import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeSpaces',
})
export class RemoveSpacesPipe implements PipeTransform {
  transform(value): any {
    if (value) {
      const newValue = value.replace(/\s/g, "");
      return newValue;
    } else {
      return "";
    }
  }
}