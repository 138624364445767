<app-loading-screen [hidden]="!searchService.search_loading && !searchParams?.isLoadingSSO" icon="ico-flight" [animated]="animated">
      <span title
            i18n="@@flightLoading_title">Just loading your search results</span>
      <div msgOne>
            <span *ngIf="searchParams?.departLocation"
                  i18n="@@flightLoading_from">Flights from</span>
            <span>&nbsp;</span>
            <ng-container *ngIf="searchParams?.departLocation">{{ searchParams.departLocation['destination'] | titlecase
                  }}
                  ({{
                  searchParams.departLocation['gateway']
                  }}), {{ searchParams.departLocation['country'] }}</ng-container>
            <span>&nbsp;</span>
            <span *ngIf="searchParams?.arriveLocation"
                  i18n="@@flightLoading_to">to</span>
            <span>&nbsp;</span>
            <ng-container *ngIf="searchParams?.arriveLocation">{{ searchParams.arriveLocation['destination'] }} ({{
                  searchParams.arriveLocation['gateway']
                  }}), {{ searchParams.arriveLocation['country'] }}</ng-container>
      </div>
      <span msgTwo
            i18n="@@flightLoading_msgTqo">Checking for availability</span>
</app-loading-screen>