import { LocationTypes, Country } from '@sabstravtech/obtservices/base';

export interface HotelSearchFormConfiguration {
  distanceUnit: DistanceUnit;
  distanceValue: DistanceValue;
  hotelDefaults: HotelDefaults;
  defaultSearchOption: DefaultSearchOption;
}
export interface DistanceUnit {
  unit: UnitType;
}

export interface DistanceValue {
  value: string;
}

export interface HotelDefaults {
  country: Country;
}

export interface DefaultSearchOption {
  product: LocationTypes;
}

export enum UnitType {
  Miles = 'Miles',
  Meters = 'Meters',
  Kilometers = 'Kilometers'
}

