<div cdkTrapFocus aria-live="assertive">
  <div class="modal-header">
    <h3 class="modal-title" i18n="
        @@modalAgentMarkupTitle" id="modal-header">Agent Markup</h3>
    <button type="button" class="close" aria-label="Agent Markup" ngbAutoFocus i18n-aria-label
      (click)="activeModal.close(true)">
      <span aria-hidden="true"><i class='ico-cancel modal_exit_cross'></i></span>
    </button>
  </div>
  <div class="modal-body ">
    <div class="markupItem">
      <label for="amount" class="modal_label flex-column-container" i18n="
            @@modalAgentMarkupAmount">
        Please enter the MU amount PP
      </label>
      <input type="string" 
      (change)="checkValue($event.target.value, 'amount')"
      (ngModelChange)="checkValue($event, 'amount')" 
      id="amount">
    </div>
    <div class="markupItem">
      <label for="cost" class="modal_label flex-column-container" i18n="
            @@modalAgentMarkupCost">
        Please enter TOTAL TRANSACTION COST PP
      </label>
      <input type="string" 
      (change)="checkValue($event.target.value, 'cost')"
      (ngModelChange)="checkValue($event, 'cost')" 
      id="cost">
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" aria-labelledby="reset-password" class="modal_button_okay" (click)="applyMarkup()"
      i18n="@@AddMarkUp">Add Mark-up
    </button>
    <button type="submit" aria-labelledby="reset-password" class="modal_button_okay" (click)="activeModal.close(false)"
      i18n="@@CancelModal">Cancel
    </button>
  </div>
</div>