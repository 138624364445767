<ng-container>
  <div class="cart_item" [ngSwitch]="basketItem.service.type">
    <app-basket-flight
      *ngSwitchCase="serviceType.Flight"
      [flight]="basketItem"
      [duplicateBooking]="isDuplicate"
    ></app-basket-flight>
    <app-basket-hotel
      *ngSwitchCase="serviceType.Hotel"
      [hotel]="basketItem"
      [duplicateBooking]="isDuplicate"
    ></app-basket-hotel>
    <app-basket-rail
      *ngSwitchCase="serviceType.Rail"
      [rail]="basketItem"
      [duplicateBooking]="isDuplicate"
    ></app-basket-rail>
    <app-basket-parking
      *ngSwitchCase="serviceType.Parking"
      [parking]="basketItem"
      [duplicateBooking]="isDuplicate"
    ></app-basket-parking>
    <app-basket-lounge
      *ngSwitchCase="serviceType.Lounge"
      [lounge]="basketItem"
      [duplicateBooking]="isDuplicate"
    ></app-basket-lounge>
    <app-basket-taxi
      *ngSwitchCase="serviceType.Cab"
      [taxi]="basketItem"
      [duplicateBooking]="isDuplicate"
    ></app-basket-taxi>
    <app-basket-car-hire
      *ngSwitchCase="serviceType.Car"
      [car]="basketItem"
      [duplicateBooking]="isDuplicate"
    ></app-basket-car-hire>
    <app-basket-eurostar
      *ngSwitchCase="serviceType.Eurostar"
      [eurostar]="basketItem"
      [duplicateBooking]="isDuplicate"
    ></app-basket-eurostar>
    <app-basket-fast-track
      *ngSwitchCase="serviceType.FastTrack"
      [fastTrack]="basketItem"
      [duplicateBooking]="isDuplicate"
    ></app-basket-fast-track>
    <app-basket-irl
    *ngSwitchCase="serviceType.InternationalRail"
    [rail]="basketItem"
    [duplicateBooking]="isDuplicate"></app-basket-irl>
  </div>
</ng-container>
