import { Component, Inject, OnInit } from '@angular/core';
import { WithSubscriptionComponent, UserService } from '@sabstravtech/obtservices/angular';
import { CookieService } from 'ngx-cookie-service';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { ThemeService } from '../../services/theme.service';
import { UserData } from '@sabstravtech/obtservices/base';
import { LightningUserFavorurite } from '../../../vendor/classes/user-favourite.enum';
import { IDashboardInfo } from '../../../vendor/interfaces/dashboard.interface';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'cookie-component',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.scss']
})
export class CookieComponent extends WithSubscriptionComponent implements OnInit {
  isRadius = false;
  public privacyURL: string;
  consentGiven = false;
  faTimesCircle = faTimesCircle;
  ctmPrivacyUrl: string = 'https://www.travelctm.co.uk/privacy/';
  canShowCookieScion: boolean = false;
  constructor(
    private userService: UserService,
    private cookieService: CookieService,
    protected themeService: ThemeService,
    @Inject(DOCUMENT) private document: Document
  ) {
    super();
    this.consentGiven = this.cookieService.check('cookie-consent');
    if (!this.consentGiven) {
      this.document.documentElement.style.scrollPaddingBottom = "200px";
    }
  }


  getDashboardPrivacyURL() {
    const dashboardInfo: IDashboardInfo = this.userService.getUserFavoriteObject(LightningUserFavorurite.scionDashboardInfo);
    this.privacyURL = (dashboardInfo && dashboardInfo.privacyPolicyURL) ? dashboardInfo.privacyPolicyURL : ''; //If not uiconfig, no link
  }

  public dismiss(): void {
    this.cookieService.set('cookie-consent', 'true');
    this.consentGiven = true;
    this.document.documentElement.style.scrollPaddingBottom = "auto";
  }

  ngOnInit(): void {
    this.isRadius = (window.location.href.indexOf('radius') !== -1);
    this.userService.fullUserDetails.userConfigs.subscribe(data => {
      this.getDashboardPrivacyURL();
    });

    this.subscribe(this.userService.fullUserDetails.userData, (data: UserData) => {
      this.canShowCookieScion = !!data;
    });
  }
}
