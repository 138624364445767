import { Pipe, PipeTransform } from '@angular/core';
import { GetRailStationFetcher } from '@sabstravtech/obtservices/angular';
import { RailStation} from '@sabstravtech/obtservices/angular';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Pipe({
  name: 'railStationNameById',
  pure: true
})

export class RailStationNameByIdPipe implements PipeTransform {
  constructor(private getRailStation: GetRailStationFetcher) {}

  transform(value: string): Observable<string> {
    if (value) {
      return this.getRailStation.fetchList({ id: value })
      .pipe(
        take(1),
        map((results: RailStation) => {
          return ` ${results.name} (${results.stationCode}), ${results.locationDetails.country}`
        })
      );
    } else {
      return of('Converting...');
    }
  }
}