<div class="flex-row-container flex-row-container--center mb-2 bg-theme">
  <section (mouseleave)="tt5.close()" *ngIf="tmcPreferred && tmcPreferredLogoUrl">
    <img
      class="heart-img"
      [src]="tmcPreferredLogoUrl"
      alt="Preferred supplier logo"
      [ngbTooltip]="tmcPreferredMessage"
      attr.aria-label="{{ tmcPreferredMessage }}"
      triggers="manual"
      #tt5="ngbTooltip"
      (mouseenter)="tt5.open()"
      (focus)="tt5.open()"
      (blur)="tt5.close()"
      tabindex="0"
      i18n-ngbTooltip="@@CTMPreferred" />
  </section>
  <i
    *ngIf="clientPreferred || (tmcPreferred && !tmcPreferredLogoUrl)"
    class="ico-heart pref_icon"
    [ngbTooltip]="preferredMessage"
    placement="right"></i>
</div>
