<div cdkTrapFocus
     aria-live="assertive">
  <div class="modal-header">
    <h3 class="modal-title"
        id="modal-header">
      <i *ngIf="showIcon" class="ico ico-info"></i> {{ title | translate }}
    </h3>
  </div>
  <div class="modal-body">
    {{ info | translate }}
  </div>
  <div class="modal-footer">
    <button type="button"
            id="button_ok"
            class="modal_button_okay"
            (click)="closeModal()"
            attr.aria-label="{{title}} Close"
            i18n="@@infoModalClose">
      {{buttonText}}
    </button>
  </div>
</div>