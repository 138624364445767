import { Component, OnInit } from '@angular/core';
import {
    Company,
    EnterpriseSearchService,
    User,
    UserService,
    WithSubscriptionComponent
} from '@sabstravtech/obtservices/angular';

import { Traveller, TravellerSearchTypes } from '@sabstravtech/obtservices/base';
import { TravellerObject } from '@sabstravtech/obtservices/base';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, take } from 'rxjs/operators';
@Component({
    selector: 'app-invoke-user',
    templateUrl: './invoke-user.component.html',
    styleUrls: ['./invoke-user.component.scss']
})
export class InvokeUserComponent extends WithSubscriptionComponent implements OnInit {
    invokeuser: Traveller = null;
    company: Company = null;
    users: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
    userIsAgent: boolean = false;
    constructor(
        private userService: UserService,
        private searchService: EnterpriseSearchService
    ) {
        super();
    }

    ngOnInit(): void {
        this.userIsAgent = this.userService.userIsAgent();
        this.invokeuser = this.userService.bobo;
    }

    userFormatter = (x: Traveller) => x.email;
    companyFormatter = (x: Company) => x.name;

    searchTravellers = (text$: Observable<string>) => {
        return text$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            switchMap((term: string): Observable<Traveller[]> => {
                if (term && term.length > 2) {
                    return this.searchService.searchTravellers(term, TravellerSearchTypes.email, this.company?.id);
                } else {
                    return of([]);
                }
            })
        );
    };

    change() {
        if (this.invokeuser?.id) {
            this.userService.setBobo(this.invokeuser);
            this.searchService.addTraveller(
                TravellerObject.makeTravellerObjectFromUser(this.invokeuser)
            );
        }
    }

    removeBobo() {
        this.userService.setBobo(null);
        this.searchService.removeAllTravellers();
        this.invokeuser = null;
    }

    searchCompanies = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            switchMap((term: string): Observable<Company[]> => {
                if (term && term.length > 2) {
                    return this.userService.getUserCompanies(term);
                } else {
                    return of([]);
                }
            })
        );
}
