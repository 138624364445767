import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'encrypt'
})
export class EncryptPipe implements PipeTransform {

  transform(value: string, hiddenChar: string = 'X', amount: number = 3, padAmount = 0): string {
    try {

      if (padAmount) {
        value = value.toString().padStart(2, '0');
      }

      return value.toString().split('').map((char, i) => (i) >= amount ? hiddenChar : char).join('');
    } catch (e) {
      console.error(e)
      return '';
    }
  }

}
