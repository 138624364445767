import { Component, OnInit, HostListener, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { DeviceDetector } from '../../..//vendor/services/device-detector.service';
import { ModalOpenerService } from '@sabstravtech/obtservices/angular';
import { BaseComponent } from '../../../vendor/components/base_components/base-componet';
import { TranslateService } from '@ngx-translate/core';
import { EnterpriseSearchService, ServiceType } from '@sabstravtech/obtservices/angular';
import {
  EurostarEnterpriseSearchInterface,
  EurostarTempParams,
  EurostarResults,
  SortTypesEurostar,
  FlightEnterpriseSearchInterface
} from '@sabstravtech/obtservices/base';
import { SortMethod, SortType } from '../../interfaces/sort-type.interface';
import { LightningModalTypes } from '../../../vendor/classes/modal-types.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'app-eurostar',
  templateUrl: './eurostar.component.html',
  styleUrls: ['./eurostar.component.scss']
  // providers: [FilterFlightResultsPipe],
  // animations: [SLIDE_UP_AND_DOWN]
})
export class EurostarComponent extends BaseComponent implements OnInit {
    searchType: ServiceType = ServiceType.Eurostar;
    ServiceType: typeof ServiceType = ServiceType;
    searchResults: any = {
        outbound: [],
        inbound: []
    };
    $margin: Observable<number> = new Observable();
    showClasses: any;
    public searchParams: EurostarEnterpriseSearchInterface;
    public sortOption: SortTypesEurostar = SortTypesEurostar.CheapestFirst;
    public search: EurostarTempParams;
    public results: EurostarResults[] = [{ outbound: [], inbound: [] }];
    public SortTypesEurostar: typeof SortTypesEurostar = SortTypesEurostar;
    public flightSearchParams: FlightEnterpriseSearchInterface;
    public destination: string;

    constructor(
        public searchService: EnterpriseSearchService,
        // public eurostarService: EurostarService,
        public deviceDetector: DeviceDetector,
        public modalService: ModalOpenerService,
        titleService: Title,
        public translateService: TranslateService,
        private router: Router
    ) {
        super(titleService, translateService);
        this.searchParams = searchService.searches[ServiceType.Eurostar];
        this.flightSearchParams = searchService.searches[ServiceType.Flight];
    }

  ngOnInit(): void {
    this.setTitle(this.translateService.instant('Eurostar results - LightUk'));
    this.search = this.searchService.searches[ServiceType.Eurostar].originalUserSearch;

    this.subscribe(this.searchParams.results, (results: EurostarResults[]) => {
      if (results.length) {
        this.results = results;
        this.destination =
          this.searchParams.rawResults.journeys[0].outboundFlights[0].destinationAirportDetail.countryCode;
        this.setShowClasses();
      } else {
        this.destination = null;
        this.results = [{ outbound: [], inbound: [] }];
        this.setShowClasses();
      }
      console.log(this.showClasses);
    });

    // this might need sorting
    // this.$margin = this.deviceDetector.deviceChange.pipe(map(this.setMargin));
  }

  // setMargin = (device: DeviceType) => (device === DeviceType.MOBILE ? 30 : 100);

  openFareRules(): void {
    this.modalService.open(
      LightningModalTypes.ModalFareRulesComponent,
      {
        windowClass: 'fare-rules-modal',
        centered: true
      }
    );
  }

  openFareRulesAndFocus(): void {
    this.openFareRules();
    document.getElementById('standardHeader').focus();
  }

  onSortingSelected(sortType: SortType): void {
    try {
      this.searchResults = this.searchParams.sortResults(sortType.code, sortType.dir);
    } catch (e) {
      console.error(
        `Sorting function ${sortType} does not exists in Eurostar model, results will not be sorted.`,
        e
      );
    }
  }

  setShowClasses(): void {
    if (this.results[0].inbound.length === 0 && this.results[0].outbound.length === 0) {
      this.showClasses = {
        standard: true,
        standardPremier: true,
        businessPremier: true
      };
    } else {
      const classes: any = {
        standard: false,
        standardPremier: false,
        businessPremier: false
      };
      _.each(['inbound', 'outbound'], dir => {
        this.results[0][dir] = this.results[0][dir].filter(result => {
          return (
            (!result.standardFare.isDisabled && Object.keys(result.standardFare.fare).length > 0) ||
            (!result.standardPremierFare.isDisabled &&
              Object.keys(result.standardPremierFare.fare).length > 0) ||
            (!result.businessPremierFare.isDisabled &&
              Object.keys(result.businessPremierFare.fare).length > 0)
          );
        });
        _.each(this.results[0][dir], result => {
          if (!result.standardFare.isDisabled) {
            classes.standard = true;
          }
          if (!result.standardPremierFare.isDisabled) {
            classes.standardPremier = true;
          }
          if (!result.businessPremierFare.isDisabled) {
            classes.businessPremier = true;
          }
        });
      });
      this.showClasses = classes;
      console.log('classes: ', this.showClasses);
    }
  }

  skipToResultContent() {
    const focusable = document
      .getElementById('eurostart')
      .querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
    // @ts-ignore
    focusable[0].focus();
    return false;
  }
  /**
@desc - do another search - based off of the input at the top of the screen and the option entered in the initial search
**/
  reSearch(): void {
    this.searchService.search_objects[ServiceType.Eurostar].chosen = true;
    this.searchService.determineHighestSearchPriority();

        this.searchService.startSearches();
        this.search = this.searchService.searches[ServiceType.Eurostar].originalUserSearch;
    }

    switchToHotel(){
      this.searchService.searches[ServiceType.Hotel].backToType = ServiceType.Eurostar;
        this.searchService.search_objects[ServiceType.Flight].chosen = false;
        const searchObj = this.searchService.init_search_objects();
        const hotelResultUrl = searchObj.HOTEL.resultUrl;
        this.searchService.search_objects[ServiceType.Hotel].priority = searchObj.HOTEL.priority;
        this.searchService.search_objects[ServiceType.Hotel].resultUrl = hotelResultUrl;
        this.router.navigate([hotelResultUrl]);
      }
}

