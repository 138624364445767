import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';
import { Basket, EnterpriseBasketService } from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-change-basket-modal',
  templateUrl: './change-basket-modal.component.html',
  styleUrls: ['./change-basket-modal.component.scss']
})
export class ModalChangeBasketComponent implements OnInit {
  baskets: [] = null;
  basketItem: any = null;
  basketService: EnterpriseBasketService = null;
  originalBasket: Basket = null;
  selectedBasketId: string = null;
  displayItinerary: boolean = false;

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

  close(): void {
    this.activeModal.close(false);
  }

  moveItemToDifferentBasket(basket: Basket): void {
    this.basketService.moveBasketItem(this.originalBasket.id, this.basketItem, basket.id);
    this.basketService.reset();
    this.close();
  }
}
LightningModalTypes.ModalChangeBasketComponent.component = ModalChangeBasketComponent;
