import { Component, OnInit, Inject } from '@angular/core';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';
import {
    Required,
    GetUserBasketItemResult,
    GetUserBasketResult
} from '@sabstravtech/obtservices/base';
import {
    BasketItem,
    WithSubscriptionComponent,
    ManagementInfo,
    ModalOpenerService,
    BasketItemManagementInfo
} from '@sabstravtech/obtservices/angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';

type KeyedAbstractControl = { [key: string]: AbstractControl; };

@Component({
    selector: 'app-requires-reason-dialog',
    templateUrl: './requires-reason-dialog.component.html',
    styleUrls: ['./requires-reason-dialog.component.scss']
})
export class RequiresReasonDialogComponent extends WithSubscriptionComponent implements OnInit {
    required: Required = null;
    basket: GetUserBasketResult = null;
    form: FormGroup = null;
    splitTickets: boolean = false;
    constructor(private activeModal: NgbActiveModal, private formBuilder: FormBuilder, private modalOpenerService: ModalOpenerService, @Inject(DOCUMENT) private document: Document) {
        super();
        this.getBasketItem = this.getBasketItem.bind(this);
        this.getMI = this.getMI.bind(this);
    }

    getBasketItem(miId: string): GetUserBasketItemResult {
        return this.basket.basketItems.find(
            (basketItem: BasketItem) =>
                !!basketItem.managementInfo.find((mi: BasketItemManagementInfo) => mi.managementInfo.id === miId)
        );
    }

    getMI(miId: string): BasketItemManagementInfo {
        let result: BasketItemManagementInfo;
        this.basket.basketItems.forEach((basketItem: BasketItem) => {
            const matchingMI = this.required[basketItem.id]?.find((basketMI: BasketItemManagementInfo) => {
                return (basketMI.managementInfo.id === miId);
            });
            if (matchingMI) {
                const mi = matchingMI.managementInfo;
                if (mi.availableValues) {
                    mi.availableValues = mi.availableValues.sort((a, b) => a.label.localeCompare(b.label));
                }
                result = matchingMI;
            }
        });
        return result;
    }

    ngOnInit() {
        this.form = this.formBuilder.group(
            Object.values(this.required).reduce(
                (current: KeyedAbstractControl, mis: BasketItemManagementInfo[]) => {
                    return mis.reduce((current: KeyedAbstractControl, mi: BasketItemManagementInfo) => {
                        current[mi.managementInfo.id] = new FormControl('', Validators.required);
                        return current;
                    }, current);
                },
                {}
            )
        );
        this.splitTickets = !!this.basket.basketItems.every(item => item.detail?.outwardDetail?.splitID);
    }
    cancel() {
        this.activeModal.close(null);
    }

    ok() {
        this.activeModal.close(this.form.getRawValue());
        if (this.document.body.classList.contains('user-is-tabbing')) {
            let context = this;
            setTimeout(function () {
                context.modalOpenerService.open(LightningModalTypes.ModalScreenreaderBasketComponent, { centered: true });
            }, 0);

        }
    }

    valid() {
        return this.form.valid;
    }

    validate() {
        if (this.splitTickets) {
            const formKeys = Object.keys(this.form.controls);
            let validKey = formKeys.find(key => this.form.controls[key].valid);
            const value = this.form.controls[validKey].value;

            return this.form.controls = formKeys.reduce((acc, control) => {
                const formControl = this.form.controls[control];
                if (!formControl.valid || control !== validKey) {
                    this.form.controls[control].setValue(value);
                }
                acc[control] = this.form.controls[control];
                return acc;
            }, {});
        }
    }

    splitCheck(index: number): boolean {
        return this.splitTickets ? !index : false;
    }
}

LightningModalTypes.RequiresReasonDialogComponent.component = RequiresReasonDialogComponent;

