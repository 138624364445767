import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

// Snag 885
// As we currently do not use 'Mobile Queue' for tickets we need to hide the word "MOB" in tickets.
// Once we support 'Mobile Queue', then replace the empty strings with the items in the commetns.
const toolTip = ''; //"This is a ticket that may be used on your mobile if you have a Mobile Queue set up.";
const html = ''; //`<i class="info_icon" ></i>`;
@Pipe({
  name: 'mobileReplacement',
})
export class MobileReplacementPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value: string, showToolTip?: boolean): any {
    if (!value) {
      return '';
    }
    if (showToolTip) {
      if (value.indexOf('MOB') >= 0 || value.indexOf('Mob') >= 0) {
        return toolTip;
      }
      return '';
    } else {
      return this.sanitized.bypassSecurityTrustHtml(
        value.replace(/MOB/gi, html).replace(' Etkt', '')
      );
    }
  }
}
