<app-eurostar-loading-screen [animated]="false"></app-eurostar-loading-screen>
<ng-container
  *ngIf="{
    results: (searchParams.results | async) || [],
    messages: searchParams.messages | async
  } as data">
  <div>
    <h2 class="search_content_titles" i18n="@@searchCriteria">Search Criteria</h2>
    <a href="#eurostart" (click)="skipToResultContent()" class="sr-only sr-only-focusable"
      >Skip to Result Content</a
    >
    <app-invoked-user></app-invoked-user>
  </div>
  <div class="eurostar_research_form background-46">
    <app-search-toggle>
      <app-validate-errors [messages]="data.messages"></app-validate-errors>
      <app-eurostar-search></app-eurostar-search>
      <div class="flex-row-container flex-row-container--right eurostar_research_row">
        <button
          i18n-title
          class="large-button large-button--search"
          title="Start another search"
          (click)="reSearch()"
          i18n="@@eurostarSearch">
          Search
        </button>
      </div>
    </app-search-toggle>
    <app-risk-alerts *ngIf="destination" [countryCode]="destination"> </app-risk-alerts>
  </div>
  <!-- <app-validate-errors for="eurostar"></app-validate-errors> -->
  <!-- <app-eurostar-search-results></app-eurostar-search-results> -->
  <div
    class="flex-row-container flex-row-container--between flex-row-container--tb-margin"
    [ngClass]="{
      'flex-row-container--right': flightSearchParams.eurostarFromFlightSearchPerformed
    }">
    <button
      class="large-button"
      *ngIf="flightSearchParams.eurostarFromFlightSearchPerformed"
      routerLink="/results/flights"
      id="eurostar-button-back-to-flights">
      Back to Flights
    </button>
    <app-carbon-comparison-eurostar *ngIf="flightSearchParams.eurostarFromFlightSearchPerformed">
    </app-carbon-comparison-eurostar>
  </div>
  <div class="flex-row-container switchrail_container">
    <button
      *ngIf="searchService.searches[ServiceType.Hotel]?.cheapestPrice?.amount"
      (click)="switchToHotel()"
      class="small-button add-hotel-to-search-button"
      id="eurostarresults-button-switchtoHotel">
      <i class="ico-bed" aria-hidden="true"></i>
      <span i18n="@@flightHotelsFrom">Hotels From:</span>&nbsp;
      {{
        searchService.searches[ServiceType.Hotel].cheapestPrice.amount
          | currency : searchService.searches[ServiceType.Hotel].cheapestPrice.currencyCode
      }}
    </button>
  </div>
  <app-sorting-toggle
    id="eurostart"
    [sortingTypes]="searchParams.sortDropdownValuesLu"
    (sortingSelected)="onSortingSelected($event)"></app-sorting-toggle>
  <div
    appStickyBlock
    [attr.margin]="70"
    [attr.marginFix]="30"
    class="eurostar-class-header half-container flex-row-container">
    <div class="half-block"></div>
    <label
      id="eurostar-open-fare-rules-modal"
      class="visuallyhidden"
      i18n="@@EurostarOpenFareRulesModal"
      >Open fare rules modal to see details</label
    >
    <div
      aria-labelledby="eurostar-open-fare-rules-modal"
      (click)="openFareRules()"
      role="button"
      class="half-block flex-row-container">
      <div
        *ngIf="showClasses?.standard"
        (keydown.enter)="openFareRulesAndFocus()"
        tabindex="0"
        id="fareRulesStand"
        alt="For more information regarding the fare conditions select here"
        class="eurostar-class-header_item-class eurostar-class-header_item-class--standard">
        <span i18n="@@eurostarStandard">Standard</span>
        <section (mouseleave)="tt.close()" class="container">
          <i
            class="ico-info"
            ngbTooltip="For more information regarding the fare conditions select here"
            aria-label="For more information regarding the fare conditions select here"
            i18n-ngbTooltip
            i18n-aria-label
            placement="bottom"
            triggers="manual"
            #tt="ngbTooltip"
            (mouseenter)="tt.open()"
            (focus)="tt.open()"
            (blur)="tt.close()"
            tabindex="0">
            <span class="visuallyhidden"
              >For more information regarding the fare conditions select here</span
            ></i
          >
        </section>
      </div>
      <div
        *ngIf="showClasses?.standardPremier"
        (keydown.enter)="openFareRulesAndFocus()"
        tabindex="0"
        id="fareRulesStandPrem"
        alt="For more information regarding the fare conditions select here"
        class="eurostar-class-header_item-class eurostar-class-header_item-class--standardPremier">
        <span i18n="@@eurostarStandardPremier">Standard Premier</span>
        <section (mouseleave)="tt2.close()" class="container">
          <i
            class="ico-info"
            triggers="manual"
            #tt2="ngbTooltip"
            (mouseenter)="tt2.open()"
            (focus)="tt2.open()"
            (blur)="tt2.close()"
            tabindex="0"
            ngbTooltip="For more information regarding the fare conditions select here"
            i18n-ngbTooltip
            i18n-aria-label
            aria-label="For more information regarding the fare conditions select here"
            placement="bottom"
            ><span class="visuallyhidden"
              >For more information regarding the fare conditions select here</span
            ></i
          >
        </section>
      </div>
      <div
        *ngIf="showClasses?.businessPremier"
        (keydown.enter)="openFareRulesAndFocus()"
        tabindex="0"
        id="fareRulesBus"
        alt="For more information regarding the fare conditions select here"
        class="eurostar-class-header_item-class eurostar-class-header_item-class--businessPremier">
        <span i18n="@@eurostarBusinessPremier">Business Premier</span>
        <section (mouseleave)="tt3.close()" class="container">
          <i
            class="ico-info"
            ngbTooltip="For more information regarding the fare conditions select here"
            i18n-ngbTooltip
            i18n-aria-label
            aria-label="For more information regarding the fare conditions select here"
            triggers="manual"
            #tt3="ngbTooltip"
            (mouseenter)="tt3.open()"
            (focus)="tt3.open()"
            (blur)="tt3.close()"
            tabindex="0"
            placement="bottom">
            <span class="visuallyhidden"
              >For more information regarding the fare conditions select here</span
            ></i
          >
        </section>
      </div>
    </div>
  </div>
  <app-eurostar-list
    resType="outbound"
    [from]="search.departLocation"
    [to]="search.arriveLocation"
    [date]="search.outBoundDate"
    [results]="data.results[0]?.outbound"
    [showClasses]="showClasses"></app-eurostar-list>
  <app-eurostar-list
    resType="inbound"
    [from]="search.arriveLocation"
    [to]="search.departLocation"
    [date]="search.inboundDate"
    [results]="data.results[0]?.inbound"
    [showClasses]="showClasses"></app-eurostar-list>

  <div class="no_results_message background-10" *ngIf="!data.results[0]?.outbound.length" role="status">
    <h2 class="no_results_message_heading"><i class="no_results_icon ico-info"></i>Oh, no!</h2>
    <p>
      <span i18n="@@eurostarCouldntMatchCriteria">
        We couldn't find any fares that match your current search criteria. If no fares have been
        returned try
      </span>
      <strong i18n="@@eurostarCouldntMatchCriteriaB">updating your search criteria</strong>.
    </p>
    <p i18n="@@eurostarContactConsultant">
      If you can't find exactly what you're looking for, please contact your Travel Manager or
      Online Support Team for further assistance.
    </p>
  </div>
</ng-container>
