<div cdkTrapFocus
     aria-live="assertive">
  <div class="modal-header">
    <h3 class="modal-title"
        i18n="@@modalTicketTermsTitle"
        id="modal-header">Train Ticket Terms</h3>
    <button type="button"
            class="close"
            aria-label="Train Ticket Terms Close"
            ngbAutoFocus
            (click)="activeModal.close(true)">
      <span aria-hidden="true"><i class='ico-cancel modal_exit_cross'></i></span>
    </button>
  </div>
  <div class="modal-body modal_body_ticket">
    <div class="modal-rail-journey-ticket-terms">
      <table class="modal-rail-journey-ticket-terms__table">
        <tbody>
          <tr *ngFor="let term of ticketTerms">
            <td><strong>{{ term.key | splitCamelCase | titlecase }}</strong></td>
            <td>
              <ng-container *ngIf="term.key === 'startDate' || term.key === 'endDate'; else notDate">
                {{ term?.value ? (term.value | sabsDate: 5 ) : 'Unknown' }}
              </ng-container>
              <ng-template #notDate>
                {{ term?.value ? term.value : 'Unknown' }}
              </ng-template>
            </td>
          </tr>
          <tr *ngIf="!ticketTerms" class="error_message">
            <td class="center_text" i18n="@@modalTicketTermsUnableToRetrieve">
              Unable to retrieve ticket terms!
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="modal_button_okay"
            (click)="activeModal.close(true)"
            i18n="@@modalTicketTermsOK">Ok</button>
  </div>
</div>
