<section
  (mouseleave)="tt.close()"
  [attr.role]="isLink ? 'link' : 'radio'"
  [attr.aria-live]="isLink ? null : 'polite'"
  (keydown.enter)="click($event)"
  (keydown.space)="click($event)"
  [attr.aria-checked]="isLink ? null : selected"
  tabindex="0"
  (click)="click($event)">
  <div
    class="light-toggle-btn"
    [class.light-toggle-btn-disabled]="disabled"
    [class.selected]="selected"
    placement="bottom-left"
    ngbTooltip="{{ tooltipText }}"
    attr.aria-label="{{ tooltipText }}"
    triggers="manual"
    #tt="ngbTooltip"
    (mouseenter)="tt.open()">
    <i class="light-toggle-btn-icon ico {{ iconClass }}" *ngIf="iconClass" aria-hidden="true"></i>
    <div class="light-toggle-btn-content">
      <ng-content></ng-content>
    </div>
  </div>
</section>
