import { Pipe, PipeTransform } from '@angular/core';
import { HotelRoom } from '@sabstravtech/obtservices/angular';
import { HotelRoomStatus } from '../../vendor/enum/hotel-room-status.enum';

interface IRoomStatus {
  messages: string;
  status: HotelRoomStatus;
  message: string;
}

@Pipe({
  name: 'hotelRoomsStatus',
})
export class HotelRoomsStatusPipe implements PipeTransform {
  transform(rooms: HotelRoom[]): IRoomStatus {
    return rooms.reduce((roomRateStatus: IRoomStatus, room: HotelRoom): IRoomStatus => {
      if (!room.requiresReasonKeys?.length && !room.unavailable) {
        roomRateStatus.status = HotelRoomStatus.Available;
        roomRateStatus.message = null;
      } else if (room.unavailable && roomRateStatus.status !== HotelRoomStatus.Reason && roomRateStatus.status !== HotelRoomStatus.Available) {
        roomRateStatus.status = HotelRoomStatus.Unavailable;
        roomRateStatus.message = room.unavailableMessage;
      } else if (roomRateStatus.status !== HotelRoomStatus.Available && room.requiresReasonKeys.length) {
        roomRateStatus.status = HotelRoomStatus.Reason;
        roomRateStatus.message = room.requiresReasonMessages.join(', ');
      }
      return roomRateStatus;
    }, {} as IRoomStatus);
  }
}
