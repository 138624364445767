import { ProductLoadingScreen } from './product-loading-screen';
import { EnterpriseSearchService,ServiceType } from '@sabstravtech/obtservices/angular'
import { LocationTypes, CarhireEnterpriseSearchInterface} from '@sabstravtech/obtservices/base'
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-car-loading-screen',
    templateUrl: './car-loading-screen.component.html',
    styleUrls: [],
  })
export class CarLoadingScreenComponent extends ProductLoadingScreen<CarhireEnterpriseSearchInterface> implements OnInit {
  @Input() animated: boolean = true;
  originalSearchDestination: string;
  constructor(service: EnterpriseSearchService) {
      super(service, service.searches[ServiceType.Car]);
  }
  ngOnInit(): void {
    this.getLocationName()
  }
  get locationName(){
    switch (this.searchParams.locationType) {
      case LocationTypes.Airport:
        this.originalSearchDestination = `
          ${this.searchParams.pickup_location?.destination}
          (${this.searchParams.pickup_location?.gateway}),
          ${this.searchParams.pickup_location?.country}
        `;
        break;
      case LocationTypes.City:
        this.originalSearchDestination = this.searchParams?.cityPickupDepot ?
          this.searchParams?.cityPickupDepot?.addressLines[0] :
          this.searchParams.cityPickup?.name;
        break;
      case LocationTypes.Postcode:
        this.originalSearchDestination = this.searchParams?.postcodeDropoff ?
          this.searchParams?.postcodeDropoff?.addressLines[0] :
          this.searchParams.postcodePickupRaw?.name;
        break;
      default:
        console.warn(
          `Unhandled location type: '${this.searchParams.locationType}' in setOriginalSearchDestination()`
        );
      }
      return this.originalSearchDestination
  }
  getLocationName(){
    switch (this.searchParams.locationType) {
      case LocationTypes.Airport:
        this.originalSearchDestination = `
          ${this.searchParams.pickup_location?.destination}
          (${this.searchParams.pickup_location?.gateway}),
          ${this.searchParams.pickup_location?.country}
        `;
        break;
      case LocationTypes.City:
        this.originalSearchDestination = this.searchParams?.cityPickupDepot ?
          this.searchParams?.cityPickupDepot?.addressLines[0] :
          this.searchParams.cityPickup?.name;
        break;
      case LocationTypes.Postcode:
        this.originalSearchDestination = this.searchParams?.postcodeDropoff ?
          this.searchParams?.postcodeDropoff?.addressLines[0] :
          this.searchParams.postcodePickupRaw?.name;
        break;
      default:
        console.warn(
          `Unhandled location type: '${this.searchParams.locationType}' in setOriginalSearchDestination()`
        );
    }
  }
}