import { Component, Input, OnInit } from '@angular/core';
import {
  EnterpriseBasketService,
} from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-price-breakdown',
  templateUrl: './price-breakdown.component.html',
  styleUrls: ['./price-breakdown.component.scss'],
})
export class PriceBreakdownComponent implements OnInit {
  @Input() index: number;
  tooltipPlacement: string = 'top';
  constructor(public basketService: EnterpriseBasketService) { }
  ngOnInit(): void {
    this.setTooltipPlacement();
  }

  hasMultipleCurrencies(items: any[]) {
    let currency = items[0]?.currency;
    return !items.every((item) => {
      return item.currency === currency;
    });
  }

  setTooltipPlacement() {
    const screenWidth = window.innerWidth;
    if (screenWidth < 500) {
      this.tooltipPlacement = 'left';
    } else {
      this.tooltipPlacement = 'top';
    }
  }

}
