import { Pipe, PipeTransform } from '@angular/core';
import { BasketItemManagementInfo } from '@sabstravtech/obtservices/angular';


@Pipe({
  name: 'removeDuplicateSplits',
  pure: true
})

export class RemoveDuplicateSplits implements PipeTransform {

  splitsArr: string[] = [];
  constructor() { }

  transform(basketItems: BasketItemManagementInfo[]): BasketItemManagementInfo[] {
    return basketItems.reduce((acc, currentValue: BasketItemManagementInfo) => {
      // const splitID = currentValue.managementInfo?.outwardDetail?.splitID;
      // if (splitID) {
      //   if (!this.splitsArr.includes(splitID)) {
      //     this.splitsArr.push(splitID);
      //     acc.push(currentValue)
      //   }
      // } else {
      acc.push(currentValue);
      // }
      return acc;

    }, []);

  }
}