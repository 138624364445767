import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { WithSubscriptionComponent, ModalOpenerService } from '@sabstravtech/obtservices/angular';
import { LightningModalTypes } from '../../../../vendor/classes/modal-types.enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-iframe',
  templateUrl: './modal-iframe.component.html',
  styleUrls: ['./modal-iframe.component.scss']
})
export class ModalIframeComponent extends WithSubscriptionComponent implements OnInit, AfterViewInit {

  @ViewChild('iframe') iframe: ElementRef;
  public loaded: any;
  public title: string = '';
  public urlForIframe: string = '';

  constructor(
    public modalOpenerService: ModalOpenerService,
    public dialogref: NgbActiveModal) {
    super();
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.iframe.nativeElement.addEventListener(
      'load',
      this.iframeTest.bind(this)
    );
  }

  iframeTest(e): void {
    console.log('=== Iframe Loaded ===');
    this.loaded = true;
  }

}
LightningModalTypes.ModalIframeComponent.component = ModalIframeComponent;