<div class="app">
  <app-results-header page="become user" [isSubHidden]="true"></app-results-header>
  <div class="app-body"
       id="main"
       #main>
    <section class="app-center app-center--padded secondary-text-colour background-40">
      <p>
        <!-- <app-light-toggle-group>
          <app-light-toggle-btn *ngIf="canBecomeUser"
                                (click)="changeUserSearch.showType ='become'"
                                iconClass="ico-oneway"
                                [selected]="changeUserSearch.showType == 'become'"
                                tabindex="-1">Become User</app-light-toggle-btn>
          <app-light-toggle-btn *ngIf="canBookOnBehalf"
                                (click)="changeUserSearch.showType ='book'"
                                iconClass="ico-return"
                                [selected]="changeUserSearch.showType == 'book'"
                                tabindex="-1">Book on behalf of a user</app-light-toggle-btn>
        </app-light-toggle-group> -->
        <app-light-toggle-group>
          <!-- <app-light-toggle-btn
                                (click)="showType ='become'"
                                iconClass="ico-oneway"
                                [selected]="showType == 'become'"
                                tabindex="-1">Become User</app-light-toggle-btn>
          <app-light-toggle-btn
                                (click)="showType ='book'"
                                iconClass="ico-return"
                                [selected]="showType === 'book'"
                                tabindex="-1">Book on behalf of a user</app-light-toggle-btn> -->
        </app-light-toggle-group>
      </p>
      <div *ngIf="showType == 'become'">
        <app-become-user></app-become-user>
    </div>
      <!-- <div *ngIf="changeUserSearch.showType == 'book'">
        <app-book-as-main></app-book-as-main>
      </div> -->
    </section>
  </div>
