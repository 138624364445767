import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UserDefaultManagementInfoValue, ManagementInfoUiPresentation } from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-mi-default-item',
  templateUrl: './mi-default-item.component.html',
  styleUrls: ['./mi-default-item.component.scss']
})
export class MiDefaultItemComponent {
  @Input() miItem: UserDefaultManagementInfoValue;
  @Output() clickOutside = new EventEmitter();
  ManagementInfoUiPresentation: typeof ManagementInfoUiPresentation = ManagementInfoUiPresentation;

  addNewMiValue() {
    this.clickOutside.emit(this.miItem);
  }
}