import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../vendor/components/base_components/base-componet';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { User, ServiceType, UserLoyaltyCode } from '@sabstravtech/obtservices/angular';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Airlines, RailCompanies, HotelChains, CarCompanies, LoyaltyValue } from '@sabstravtech/obtservices/base';

@Component({
  selector: 'app-profile-information-loyalty',
  templateUrl: './profile-information-loyalty.component.html',
  styleUrls: ['./profile-information-loyalty.component.scss']
})
export class ProfileInformationLoyaltyComponent extends BaseComponent implements OnInit {
  tabSelected = 0;
  faChevronDown = faChevronDown;
  @Input() user: User;
  @Input() loyaltiesForm: FormGroup;
  @Input() isEditable: boolean;
  newLoyalty = {} as UserLoyaltyCode;
  serviceType = ServiceType;
  tabData: LoyaltyValue[] = Airlines;
  addNewLoyaltyForm: FormGroup;
  constructor(
    title: Title,
    translateService: TranslateService,
    private fb: FormBuilder
  ) {
    super(title, translateService);

    this.addNewLoyaltyForm = new FormGroup ({
      type: new FormControl (ServiceType.Flight),
      carrier: new FormControl (''),
      code: new FormControl ('')
    })
  }

  get loyaltyCodes() {
    return this.loyaltiesForm.controls["loyaltyCodes"] as FormArray;
  }

  ngOnInit(): void {
    console.log(this.user);
    if (this.user && !this.loyaltyCodes.value.length) {
      this.setValue();
    }
    if(!this.isEditable){ this.loyaltiesForm.disable();
    this.addNewLoyaltyForm.disable();}
  }

  setValue() {
    this.loyaltiesForm.setValue({
      loyaltyCodes: []
    });

    if (this.user.loyaltyCodes?.length) {
      this.user.loyaltyCodes.forEach((loyalty: UserLoyaltyCode) => {
        this.addLoyalty(loyalty);
      });
    }
    this.showTabContent(this.tabSelected);
  }

  addLoyalty(loyalty: UserLoyaltyCode){
    const loyaltiesForm = this.fb.group({
      type: [loyalty.type],
      carrier: [loyalty.carrier],
      code: [loyalty.code]
    });
    this.loyaltyCodes.push(loyaltiesForm);
  }

  deleteLoyalty(loyaltyId: number){
    this.loyaltyCodes.removeAt(loyaltyId);
    this.loyaltiesForm.markAsDirty();
  }

  showTabContent(tabNumber: number){
    this.tabSelected = tabNumber;
    switch (this.tabSelected) {
      case 0:
        this.tabData = Airlines;
        this.addNewLoyaltyForm.setValue({type: ServiceType.Flight, carrier: '', code : ''});
        break;
      case 1:
        this.addNewLoyaltyForm.setValue({type: ServiceType.Rail, carrier: '', code : ''});
        this.tabData = RailCompanies;
        break;
      case 2:
        this.tabData = HotelChains;
        this.addNewLoyaltyForm.setValue({type: ServiceType.Hotel, carrier: '', code : ''});
        break;
      case 3:
        this.tabData = CarCompanies;
        this.addNewLoyaltyForm.setValue({type: ServiceType.Car, carrier: '', code : ''});
        break;
      default:
        break;
    }
  }

  addNewLoyalty(){
    const loyaltiesForm = this.fb.group({
      type:  [this.addNewLoyaltyForm.get('type').value ],
      carrier:  [this.addNewLoyaltyForm.get('carrier').value],
      code: [this.addNewLoyaltyForm.get('code').value]
    });
    this.loyaltyCodes.push(loyaltiesForm);
    this.loyaltiesForm.markAsDirty();
    // set default value
    this.addNewLoyaltyForm.setValue({type: ServiceType.Flight, carrier: '', code : ''});
    this.tabSelected = 0;
    this.tabData = Airlines;
  }
}
