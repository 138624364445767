import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../vendor/components/base_components/base-componet';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { EnterpriseSearchService, User, PhoneNumber, PhoneType } from '@sabstravtech/obtservices/angular';
import { PersonTitles, PersonTitle } from '@sabstravtech/obtservices/base';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Helpers } from '../../vendor/classes/helpers';

@Component({
  selector: 'app-profile-information-profile',
  templateUrl: './profile-information-profile.component.html',
  styleUrls: ['./profile-information-profile.component.scss']
})
export class ProfileInformationProfileComponent extends BaseComponent implements OnInit {
  faChevronDown = faChevronDown;
  faCalendar = faCalendar;
  @Input() user: User;
  @Input() form: FormGroup;
  @Input() isEditable: boolean;
  personTitles: PersonTitle[];
  countries: { cCode: string, cName: string; dCode: string; }[];
  PhoneType: typeof PhoneType = PhoneType;
  phoneTypes: PhoneType[];

  constructor(
    title: Title,
    translateService: TranslateService,
    public searchService: EnterpriseSearchService,
    private fb: FormBuilder
  ) {
    super(title, translateService);
  }

  get phoneNumbers() {
    return this.form.controls["phoneNumbers"] as FormArray;
  }

  get additionalEmail() {
    return this.form.controls["additionalEmail"] as FormArray;
  }

  ngOnInit(): void {
    this.getCountries();
    this.phoneTypes = this.searchService.phoneTypes;
    this.personTitles = PersonTitles;
    if (this.user && !this.phoneNumbers.value.length && !this.additionalEmail.value.length) {
      this.setValue();
    }
    if (!this.isEditable) this.form.disable();
  }

  getCountries() {
    this.countries = this.searchService.countries;
    const ukCountry = this.countries.find(country => country.cName === 'United Kingdom');
    this.countries = this.countries.filter(country => country.cName !== 'United Kingdom');
    this.countries.unshift(ukCountry);
  }

  setValue() {
    this.form.setValue({
      title: this.user.title ? this.user.title.charAt(0).toUpperCase() + this.user.title.substring(1).toLowerCase() : '',
      forename: this.user.forename || '',
      surname: this.user.surname || '',
      dob: this.user.dob ? Helpers.dateToNgDate(new Date(this.user.dob)) : null,
      email: this.user.email || '',
      additionalEmail: [],
      selectedLanguage: this.user.selectedLanguage.id || '',
      emergencyContactName: this.user.emergencyContactName || '',
      emergencyContactPhone: this.user.emergencyContactPhone || '',
      phoneNumbers: []
    });

    if (this.user.phoneNumbers.length) {
      this.user.phoneNumbers.forEach((phone: PhoneNumber) => {
        this.addPhone(phone);
      });
    } else {
      // add primary phone, we cant seve user without primary phone
      const newPhone = {} as PhoneNumber;
      newPhone.userPrimary = true;
      newPhone.description = 'New number';
      this.addPhone(newPhone);
    }

    if (this.user.additionalEmail.length) {
      this.user.additionalEmail.forEach((email: string) => {
        this.addEmail(email);
      });
    }
    // always need 3 additional email fields
    while (this.additionalEmail.length < 3) {
      this.addEmail('');
    }
  }

  addPhone(phone: PhoneNumber) {
    let number = (phone.areaCode ? phone.areaCode : '') + (phone.number ? phone.number : '');
    const phoneForm = this.fb.group({
      countryCode: [phone.countryCode],
      areaCode: [''],
      number: [number, [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(8), Validators.maxLength(15)]],
      userPrimary: [phone.userPrimary],
      description: [phone.description],
      type: phone.type
    });
    this.phoneNumbers.push(phoneForm);
  }

  addEmail(email: string) {
    const emailForm = this.fb.group({
      value: [email, Validators.email]
    });
    this.additionalEmail.push(emailForm);
  }
}
