import { Component, Inject, Injectable, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LightningModalTypes } from '../../../../../vendor/classes/modal-types.enum';

@Component({
  selector: 'app-warning-hotel-remove',
  templateUrl: './warning-remove-modal.component.html',
  styleUrls: ['./warning-remove-modal.component.scss'],
})
@Injectable()
export class WarningRemoveModalComponent {
  constructor(public activeModal: NgbActiveModal) {}

  onAccept(): void {
    this.activeModal.close(true);
  }

  onCancel(): void {
    this.activeModal.close();
  }
}
LightningModalTypes.WarningRemoveModalComponent.component = WarningRemoveModalComponent;