import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'standardDate'
})
export class BasketDateFormatterPipe implements PipeTransform {
  constructor(private translate: TranslateService) { }
  transform(value: string): any {
    if (value) {
      let newDate = new Date(value);
      // const offset = newDate.getTimezoneOffset();
      // console.log(`+++ Offset ${newDate}: ${offset} +++`);
      // console.log(`+++ Hours ${newDate}: ${newDate.getHours()} +++`);
      const currentDate = newDate.getDate();
      let months = ["January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"];
      return `${this.translate.instant(months[newDate.getMonth()])} ${currentDate}${this.translate.instant(this.nth(currentDate))}  ${newDate.getFullYear()};  ${newDate.getUTCHours()}:${this.leadingZero(newDate.getMinutes())}`;
    }
    return value;
  }

  nth(d: number) {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  }

  leadingZero(minutes: number) {
    return (minutes < 10 ? '0' : '') + minutes;
  }
}
