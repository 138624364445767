import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../vendor/components/base_components/base-componet';
import { User, Address } from '@sabstravtech/obtservices/angular';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-profile-information-address',
  templateUrl: './profile-information-address.component.html',
  styleUrls: ['./profile-information-address.component.scss']
})
export class ProfileInformationAddressComponent extends BaseComponent implements OnInit {
  @Input() user: User;
  @Input() addressForm: FormGroup;
  @Input() isEditable: boolean;
  newAddress = {} as Address;
  showAddressBlock: number;
  constructor(
    title: Title,
    translateService: TranslateService,
    private fb: FormBuilder
  ) {
    super(title, translateService);
  }

  get addresses() {
    return this.addressForm.controls["addresses"] as FormArray;
  }

  ngOnInit(): void {
    if (this.user && !this.addresses.value.length) {
      this.setValue();
    }
    if(!this.isEditable) this.addressForm.disable();
  }
  setValue() {
    this.addressForm.setValue({
      addresses: []
    });

    if (this.user.addresses.length) {
      this.user.addresses.forEach((address: Address) => {
        this.addAddress(address);
      });
    } else {
      // add primary Address
      this.newAddress.userPrimary = true;
      this.addAddress(this.newAddress);
    }
  }

  addAddress(address: Address){
    this.showAddressBlock = this.addresses.length;
    const addressForm = this.fb.group({
      description: [address.description, Validators.required],
      numberOrName: [address.numberOrName, Validators.required],
      street: [address.street, Validators.required],
      town: [address.town, Validators.required],
      county: [address.county],
      postcode: [address.postcode],
      countryCode: [address.countryCode, Validators.required],
      userPrimary: [address.userPrimary ? address.userPrimary : false]
    });
    this.addresses.push(addressForm);
  }

  deleteAddress(addressId: number) {
    this.addresses.removeAt(addressId);
    this.addressForm.markAsDirty();
  }

  editAddress(addressId: number) {
    this.showAddressBlock = addressId;
  }

}
