import { Component } from '@angular/core';
import { WithSubscriptionComponent } from '@sabstravtech/obtservices/angular';
import { EnterpriseSearchService } from '@sabstravtech/obtservices/angular';
@Component({ template: '' })
export class BaseSearchComponent extends WithSubscriptionComponent {
  constructor(protected searchService: EnterpriseSearchService) {
    super();
  }

  public performSearch() {
    if (this.searchService.validateForms()) {
      this.searchService.startSearches();
    }
  }
}
