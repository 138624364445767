import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { WithSubscriptionComponent } from '@sabstravtech/obtservices/angular';
import { FADE_IN_OUT } from '../../data/animations';
import {
  ServiceType,
  SearchUsersCanBookForFetcher,
  EnterpriseSearchService,
  UserService,
  HelperRoutines,
  IrlJourneyType
} from '@sabstravtech/obtservices/angular';
import { Traveller, BoltSearch, LocationTypes } from '@sabstravtech/obtservices/base';

@Component({
  selector: 'app-recent-bolt-searches',
  templateUrl: './recent-bolt-searches.component.html',
  styleUrls: ['./recent-bolt-searches.component.scss'],
  animations: [FADE_IN_OUT]
})
export class RecentBoltSearchesComponent extends WithSubscriptionComponent implements OnInit, OnDestroy{
  public serviceType: typeof ServiceType = ServiceType;
  public irlJourneyType: typeof IrlJourneyType = IrlJourneyType;
  public recentSearches: BoltSearch[];
  searchParams: any; // BaseEnterpriseSearch;
  public locationTypes: typeof LocationTypes = LocationTypes;
  @Input() isBolt: boolean = true;

  constructor(
    public searchUsersCanBookForFetcher: SearchUsersCanBookForFetcher,
    public searchService: EnterpriseSearchService,
    public userService: UserService,
    private helpers: HelperRoutines
  ) {
    super();
  }

  ngOnInit() {
    this.getUserRecentSearches();
  }

  private getUserRecentSearches() {
    this.subscribe(this.searchService.getUserRecentBoltSearches(this.isBolt), (data: BoltSearch[]) => {
      console.log(`Recent Bolt Searches: `, data);
      this.recentSearches = data.sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt));
    });
  }

  public performRecentSearch(recentSearch: BoltSearch) {
    console.log(recentSearch);
    this.searchService.search_objects = this.helpers.clone(
      this.searchService.init_search_objects()
    );
    this.addToTravellerSelection(recentSearch.travellers);
    this.searchService.performBoltSearchFromRecentBoltSearch.next(recentSearch.searchArgs);
  }

  ngOnDestroy(): void {
    this.searchService.performBoltSearchFromRecentBoltSearch.next(null);
  }

  private addToTravellerSelection(travellers: Traveller[]): void {
    // remove exist Travellers if they are
    if (this.searchService.getCurrentNoOfTravellers() >= 1) {
      this.searchService.removeAllTravellers();
    }
    // add recent search Travellers
    if (travellers) {
      travellers.forEach((traveller: Traveller) => {
        this.searchService.addTraveller(traveller);
      });
    }

    
  }


  public removeRecentSearchItem(item: BoltSearch, $event) {
    $event.stopPropagation();

    this.searchService.deleteRecentSearchByID(item.id as string, true).subscribe(response => {
      if (response) {
        this.recentSearches.splice(this.recentSearches.indexOf(item), 1);
      }
    });
  }
}

