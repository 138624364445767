<div class="no-results-container clearfix" role="status">
  <i class="no-results-ico {{icon}}"></i>
  <h2 i18n="@@noResultsTitle">Oh, no!</h2>
  <p><span i18n="@@noResultsCouldntFind">We couldn't find any</span> {{type}} <span
          i18n="@@noResultsCouldntFindB">journeys that match your criteria. Try </span>
    <strong>
      <span i18n="@@noResultsCouldntFindC">updating your search</span>
      <span *ngIf="extraUpdateText">{{extraUpdateText}}</span>
    </strong>.
  </p>
  <p i18n="@@noResultsContactConsultant">If you can't find exactly what you're looking for, contact a consultant.</p>
</div>