@if (bannerImage && showBanner) {
    <div class="banner-container" >
        <div class="info-container">
                <i aria-hidden="true" class='ico ico-info-circle info-icon'></i>
        </div>
        <div class="banner-image-container">
            
            <img alt="" class="banner-image" [src]="bannerImage.changingThisBreaksApplicationSecurity">
        
        </div>
        <div class="close-container">

            <button type="button"
                    class="close"
                    ngbAutoFocus
                    aria-label="Hide Banner"
                    (click)="close()"
                    (keydown.enter)="close()"
                    (keydown.space)="close()"
                    >
                <span aria-hidden="true"><i class='ico-cancel modal_exit_cross'></i></span>
            </button>
            
        </div>
    </div>
} 