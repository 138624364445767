import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';
import { FastTrackQuoteResult } from '@sabstravtech/obtservices/base';
import { UserService } from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-gmt-fast-track-fare-rules-modal',
  templateUrl: './gmt-fast-track-fare-rules-modal.html',
  styleUrls: ['./gmt-fast-track-fare-rules-modal.scss']
})
export class GmtFastTrackModalFareRulesComponent implements OnInit {
  fastTrack: FastTrackQuoteResult = null;
  constructor(
    public modal: NgbActiveModal,
    public userService: UserService) {
  }

  ngOnInit(): void {}
}

LightningModalTypes.GmtFastTrackModalFareRulesComponent.component = GmtFastTrackModalFareRulesComponent;