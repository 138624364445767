import { Component, OnInit, Input, OnDestroy, SimpleChange } from '@angular/core';
import { RiskAlert, RiskLevel, EnterpriseSearchService, ModalOpenerService, UserService, WithSubscriptionComponent } from '@sabstravtech/obtservices/angular';
import { RiskLevelRanking, RiskObjectInterface } from '@sabstravtech/obtservices/base';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { LightningModalTypes } from '../../../vendor/classes/modal-types.enum';
import { LightningUserFavorurite } from '../../../vendor/classes/user-favourite.enum';


@Component({
  selector: 'app-risk-alerts',
  templateUrl: './risk-alerts.component.html',
  styleUrls: ['./risk-alerts.component.scss']
})
export class RiskAlertsComponent extends WithSubscriptionComponent implements OnInit {
  _countryCode: string;
  @Input() set countryCode(value: string) {
    this._countryCode = value;
  }
  get countryCode(): string {
    return this._countryCode;
  }
  @Input() wellBeing: boolean = false;
  riskAlerts: RiskAlert[];
  isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  highestRiskLevel: RiskLevel;
  fullCountryName: string;
  expanded: boolean = false;
  fullyExpanded: boolean = false;
  text = 'Risk Information';
  riskMinimumLevel: string;
  filteredRiskAlerts: RiskAlert[];
  constructor(
    private searchService: EnterpriseSearchService,
    private modalService: ModalOpenerService,
    private userService: UserService
  ) {
    super();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if( changes['countryCode'] && changes['countryCode'].previousValue != changes['countryCode'].currentValue ) {
      this.checkRiskAlerts();
    }
  }

  ngOnInit(): void {
    this.checkRiskAlerts();
  }

  checkRiskAlerts() {
    this.riskAlerts = [];
    this.highestRiskLevel = null;
    const riskObject: RiskObjectInterface = this.userService.getUserFavoriteObject<any>(LightningUserFavorurite.ShowRiskAlert) || {
      enable: false,
      level: 0
    };
    if (this.countryCode && riskObject.enable && riskObject.level) {
      this.fullCountryName = this.searchService.getCountryFullNameFromCountryCode(this.countryCode);
      this.subscribe(this.getRiskAlerts(this.countryCode, riskObject.level), data => {
        this.riskAlerts = data;
        this.filteredRiskAlerts = this.riskAlerts;
        this.highestRiskLevel = this.findHighestRiskLevel();
      }, error => {
        console.error(error);
      });
    }
  }

  findHighestRiskLevel(): RiskLevel {
    let highestRiskLevel = 0;
    let highestRiskLevelText = RiskLevel.Low;
    this.riskAlerts.forEach((alert: RiskAlert) => {
      const riskLevel = RiskLevelRanking[alert.risk];
      if (Number(riskLevel) > highestRiskLevel) {
        highestRiskLevel = Number(riskLevel);
        highestRiskLevelText = alert.risk;
      }
    });

    return highestRiskLevelText;
  }

  getRiskAlerts(countryCode: string, minRiskLevel: string): Observable<RiskAlert[]> {
    return this.searchService.getRiskAlerts(minRiskLevel as RiskLevel, RiskLevel.Extreme, countryCode, this.isLoading);
  }

  showDetails(alert: RiskAlert): void {
    this.modalService.open(LightningModalTypes.RiskAlertDetail, null, { alert: alert });
  }

  removeAlert(index: number) {
    console.log(`remove alert at index ${index}`);
    this.riskAlerts.splice(index, 1);
  }

  filterAlerts(searchInput: Event): void {
    if (!((searchInput.target) as HTMLInputElement)?.value) {
      this.filteredRiskAlerts = this.riskAlerts;
    } else {
        const searchArray = ((searchInput.target) as HTMLInputElement)?.value.split(" ");
        this.filteredRiskAlerts = this.riskAlerts.filter((riskAlert) => {
          let hasSearchTerm = false;
          searchArray.forEach((searchTerm) => {
            if (riskAlert.risk.toLowerCase().includes(searchTerm.toLowerCase()) || riskAlert.title.toLowerCase().includes(searchTerm.toLowerCase())) {
              hasSearchTerm = true;
            }
          });
          return hasSearchTerm;
        });
    }
  }

}
