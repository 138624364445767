import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  EnterpriseBasketService,
  ServiceType,
  LoungeQuote,
  EnterpriseSearchService,
  UserService,
  ModalOpenerService
} from '@sabstravtech/obtservices/angular';
import { BaseSearchComponent } from '../../../vendor/components/base-search/base-search.component';
import { LightningModalTypes } from '../../../vendor/classes/modal-types.enum';
import { FlightPipe } from '../../../startup/pipes/flight-pipe';
import { LoungesEnterpriseSearchInterface } from '@sabstravtech/obtservices/base';
import { resultIcons } from '../../../vendor/enum/result-icons.enum';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-lounges',
  templateUrl: './lounge.component.html',
  styleUrls: ['./lounge.component.scss']
})
export class LoungesComponent extends BaseSearchComponent implements OnInit {
  public ServiceType: typeof ServiceType = ServiceType;
  mapView = false;
  itemPhotos = false;
  resultItemType = resultIcons;
  public searchParams: LoungesEnterpriseSearchInterface = null;
  canOverride: boolean = false;
  displayItinerary = false;

  constructor(
    public searchService: EnterpriseSearchService,
    public userService: UserService,
    private route: ActivatedRoute,
    private basketService: EnterpriseBasketService,
    private modalService: ModalOpenerService,
    protected flightPipe: FlightPipe,
    public translateService: TranslateService,
    public title: Title
  ) {
    super(searchService);
    this.title.setTitle(this.translateService.instant('Lounge Results - LightUk'));
  }

  ngOnInit(): void {
    this.canOverride = this.userService.canOverride();
    this.searchParams = this.searchService.searches[ServiceType.Lounge];
    this.subscribe(this.route.queryParams, ({ center }) => {
      this.mapView = !!center;
    });

    this.subscribe(this.route.data, ({ type }) => {
      console.log(`Detected search type: ${type}`, 'PNL');
    });
    this.displayItinerary = this.userService.isBasketReplacementUserFavouriteSet();
  }

  toggleItemDetails(
    item: LoungeQuote & {
      showDetails: boolean;
    }
  ): void {
    item.showDetails = !item.showDetails;
  }

  toggleItemPhotos(photos: string[]): void {
    const data = {
      photos
    };
    this.modalService.open(LightningModalTypes.ModalLoungeImagesComponent, { centered: true }, data);
  }

  performSearch() {
    this.searchService.search_objects[ServiceType.Lounge].chosen = true;
    this.searchService.determineHighestSearchPriority();
    this.searchService.startSearches();
  }

  addToCart(item: LoungeQuote) {
    this.searchService.searches[ServiceType.Lounge].addLoungeToBasket(
      item,
      this.searchService.searches[ServiceType.Lounge].getSearchQuery()
    );
    this.basketService.toggleMenu();
  }

  skipToResultContent() {
    const focusable = document
      .getElementById('parking')
      .querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
    // @ts-ignore
    focusable[0].focus();
    return false;
  }

  removeSpaces(path: string) {
    return path.replace(/\s/g, "");
  }
}
