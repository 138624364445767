import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../vendor/components/base_components/base-componet';
import { faChevronDown, faBars } from '@fortawesome/free-solid-svg-icons';
import { User, UserService } from '@sabstravtech/obtservices/angular';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { UserApprover, UserApproverInput } from '@sabstravtech/obtservices/angular';
@Component({
  selector: 'app-profile-information-approval',
  templateUrl: './profile-information-approval.component.html',
  styleUrls: ['./profile-information-approval.component.scss']
})
export class ProfileInformationApprovalComponent extends BaseComponent implements OnInit {
  tabSelected = 0;
  faChevronDown = faChevronDown;
  faBars = faBars;
  @Input() user: User;
  @Input() approvalForm: FormGroup;
  @Input() isEditable: boolean;
  approverEmail: '';

  constructor(
    title: Title,
    translateService: TranslateService,
    public userService: UserService,
    private fb: FormBuilder
  ) {
    super(title, translateService);
  }

  get userApprovers() {
    return this.approvalForm.controls["userApprovers"] as FormArray;
  }

  ngOnInit(): void {
    if (this.user) {
      this.setValue();
    }
    if(!this.isEditable) { 
      this.approvalForm.disable();
    }
  }

  formatterApproverEmail = (obj: { email: string }) => obj.email; // used for formatting approverEmail typeahead output

  searchApproverEmail = (text$: Observable<string>): Observable<any[]> => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    return debouncedText$.pipe(switchMap((search: string): Observable<string[]> =>
      search.length <= 2 ? of([]) : this.userService.getSearchCompanyApprovers(search)
    ));
  }

  setValue() {
    this.approvalForm.setValue({
      userApprovers: []
    });

    if (this.user?.userApprovers?.length) {
      this.user.userApprovers.forEach((item: UserApprover) => {
        if (item) {
          const approver = {
            item: {
              email: item.approver.email,
              id: item.approver.id,
              order: item.order,
            }
          }
          this.selectApprover(approver);
        }
      });
    }    
  }

  selectApprover(approver) {
    // before add item check if itemId exist in the list. if exist remove old record
    let userApprovers = this.userApprovers.value;
    let filteredUserMiDefaultValues = userApprovers.filter((item: UserApproverInput) => item.approverId === approver?.item?.id);

    if (!filteredUserMiDefaultValues.length) {
      this.approverEmail = '';
      if (approver.item) {
        const approvalForm = this.fb.group({
          email: approver.item.email,
          order: this.userApprovers.length + 1,
          approverId: approver.item.id,
          user: this.user.id
        });
        this.userApprovers.push(approvalForm);
        this.approvalForm.markAsDirty();
      }
    } 
  }

  deleteApprover(approver: number){
    this.userApprovers.removeAt(approver);
    this.approvalForm.markAsDirty();
  }

  dropRow(event: CdkDragDrop<FormGroup[]>) {
    const dir = event.currentIndex > event.previousIndex ? 1 : -1;
    const from = event.previousIndex;
    const to = event.currentIndex;
    const temp = this.userApprovers.at(from);

    for (let i = from; i * dir < to * dir; i = i + dir) {
      const current = this.userApprovers.at(i + dir);
      this.userApprovers.setControl(i, current);
    }

    this.userApprovers.setControl(to, temp);
    this.approvalForm.markAsDirty();
  }
}