<div id="loading-screen" class="loading-screen-container">
    <div class="loading-screen-box" [ngClass]="{'loading-screen-box--animated': animated}">
        <div class="loading-indicator">
            <i class="{{ icon }} fade_in_one"></i>
            <i class="{{ icon }} fade_in_two"></i>
            <i class="{{ icon }} fade_in_three"></i>
            <i class="{{ icon }} fade_in_four"></i>
            <i class="{{ icon }} fade_in_five"></i>
        </div>
        <h2 class="loading-title" role="alert" aria-atomic="true" tabindex="0">
            <ng-content select="[title]"></ng-content>
        </h2>
        <p class="loading-message">
            <ng-content select="[msgOne]"></ng-content>
        </p>
        <p class="loading-message" role="alert" aria-atomic="true">
            <ng-content select="[msgTwo]"></ng-content>
        </p>
    </div>
</div>
