import { Pipe, PipeTransform } from '@angular/core';
import { EnterpriseSearchService, ServiceType } from '@sabstravtech/obtservices/angular';

@Pipe({
  name: 'parkingFilters'
})
export class ParkingFiltersPipe implements PipeTransform {

  public parkingFilters = this.searchService.searches[ServiceType.Parking].parkingFiltersText;
  constructor(public searchService: EnterpriseSearchService) { }

  transform(value: {}): string[] {
    let itemsList: string[] = [];
    if (!value) {
      return [];
    }
    Object.keys(this.parkingFilters).forEach(
      (item: string) => {
        if (value.hasOwnProperty(item)) {
          if (value[item]) {
            itemsList.push(this.parkingFilters[item]);
          }
        }
      }
    );
    return itemsList;
  }
}