<div class="modal-header">
  <h3 class="modal-title" id="modal-header" i18n="@@apartmentRequest">Apartment request</h3>
  <button type="button" class="light-icon-button close" aria-label="Close" (click)="activeModal.close(false)" ngbAutoFocus>
    <i class="ico-cancel AA-standard-grey" aria-hidden="true"></i>
  </button>
</div>
<div class="modal-body">
  <p i18n="@@theApartmentBookingRequestHasBeenSent">
    Thank you for submitting your Serviced Apartment request booking enquiry. One of our travel
    consultants will check the relevant options that are available and respond to you shortly. If
    you are travelling within the next 48 hours, please contact the relevant travel team by
    telephone
  </p>
</div>
<div class="modal-footer">
  <button type="button"
          class="modal_button_okay"
          (click)="activeModal.close(true)"
          i18n="@@modalConfirmCardOK"
          aria-label="Confirm"
          ngbAutoFocus>Ok</button>
</div>