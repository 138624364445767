import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'flightRuleSorter',
})
export class FlightRuleSorterPipe implements PipeTransform {
  transform(values: string[]): string[] {
    console.log('Original Value: ', values);
    let penaltiesSection = -1;
    let temp = '';
    const regex = /^(\d+)/g;
    let newValues;
    if (values) {
      newValues = values.reduce(
        (current: { sections: any; index: number }, item: any) => {
          // remove 'jargon' LU-400
          if (item.Text) {
            temp = item.Text;
            if (!+item.SortNo) {
              temp = `50. ${item.Text.split('50.')[1]}`;
              if (!item.Text.split('50.')[1]) {
                temp = item.Text;
              }
            }
            // temp = temp.replace('<br>', '<br/><br/>');  // put in a space
            const splits = temp.split('<br>');
            splits.forEach((line) => {
              const matches = line.match(regex);
              if (matches) {
                current.index = Number(matches[0]);
                current.sections[current.index] =
                  current.sections[current.index] || [];
                if (line.indexOf('PENALTIES') >= 0) {
                  penaltiesSection = current.index;
                }
              }
              current.sections[current.index].push(line);
            });
          }
          return current;
        },
        { sections: [[]], index: 0 }
      );
    }

    /*if (!newValues[0] && item.Text) {
      return item.Text;
    }*/

    const toReturn = [];
    if (newValues) {
      for (let index = 0; index < newValues.sections.length; index += 1) {
        if (index === 1 && penaltiesSection >= 0) {
          console.log('penalties section: ', penaltiesSection);
          console.log('All values: ', newValues.sections);
          toReturn.push(
            newValues.sections[penaltiesSection].join('<br/><br/>')
          );
        }
        if (index !== penaltiesSection && newValues.sections[index]) {
          toReturn.push(newValues.sections[index].join('<br/><br/>'));
        }
      }
      for (let i = 0; i < toReturn.length; i++) {
        if (toReturn[i]) {
          // check we actually have some text to append to
          toReturn[i] += '<hr/>';
        }
      }
    }

    return toReturn;
  }
}
