import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { Message, UserService, MessageTarget, EnterpriseSearchService, ServiceType, WithSubscriptionComponent, Airport, BasketItem, MessagesService } from '@sabstravtech/obtservices/angular';
import { Subscription } from 'rxjs';
import { BookingStatus, OBTAirportDetails, Country } from '@sabstravtech/obtservices/base';
import { Router } from '@angular/router';
import { LightningUserFavorurite } from '../../classes/user-favourite.enum';

@Component({
  selector: 'app-announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.scss']
})
export class AnnouncementsComponent extends WithSubscriptionComponent implements OnInit {
  @Input() public page = '';
  @Input() basket: BasketItem[] = null;
  // giving this in case we are on itin pages
  @Input() basketItemTypes: ServiceType[] = [];
  public announcements: Message[] = [];
  @Output() udpateAnnCount: EventEmitter<{ annCount: number, announcements: Message[]; }> = new EventEmitter<{ annCount: number, announcements: Message[]; }>(true);
  private announcementsSubscription: Subscription;
  private messages: Message[];
  private _basketStatus: number;
  public showClearAllButton: boolean = false;
  public isTravelAdvice: boolean = false;
  public travelAdvice: {
    link: string,
    country: string;
  } = null;
  public open: boolean = false;
  private currentServiceType: ServiceType;
  private boltSearchResultsServiceTypes: ServiceType[] = [];
  @Input() set basketStatus(value: number) {
    this._basketStatus = value;
    this.filterAnnouncements(this.messages);
  }

  get basketStatus(): number {
    return this._basketStatus;
  }

  private _serviceType: ServiceType[] = [];

  @Input() set serviceType(value: ServiceType[]) {
    let previousServiceTypeValue = this._serviceType;
    this._serviceType = value || [];
    if (previousServiceTypeValue?.length !== this._serviceType?.length) {
      this.filterAnnouncements(this.messages);
    }
  }
  get serviceType(): ServiceType[] {
    return this._serviceType;
  }

  private focusableElements: HTMLElement[];
  private firstFocusableElement: HTMLElement;
  private lastFocusableElement: HTMLElement;
  private keydownListener: () => void;
  @ViewChild('announcementDropdown', { static: false }) announcementDropdown: ElementRef;

  constructor(
    private readonly userService: UserService,
    private router: Router,
    public searchService: EnterpriseSearchService,
    private messagesService: MessagesService,
    private renderer: Renderer2
  ) {
    super();
  }

  // //Since announcements are no longer in a popup we don't need to trap focus
  /*
  ngOnChanges(changes: SimpleChanges) {
    if (changes.open && changes.open.currentValue) {
      this.setFocusTrap();
    }
  }*/

  ngOnInit(): void {
    if (this.userService.fullUserDetails.userData.value) {
      this.getAnnouncements();
    }
    this.subscribe(this.searchService.searchLoadingDone, (bool: boolean) => {
      this.getAnnouncements();
      this.setTravelAdvice();
    });

    this.subscribe(this.searchService.boltSearchResultsServiceTypes, (boltSearchResultsServiceTypes: ServiceType[]) => {
      this.boltSearchResultsServiceTypes = boltSearchResultsServiceTypes;
      this.filterAnnouncementsBolt(this.messages, this.page);
    });

    this.subscribe(this.searchService.boltSearchServiceTypes, (boltSearchServiceTypes: ServiceType) => {
      this.boltSearchResultsServiceTypes = [boltSearchServiceTypes];
      this.filterAnnouncementsBolt(this.messages, 'search');
    });
  }

  getAnnouncements() {
    this.announcementsSubscription = this.userService.getUserMessages().subscribe((messages: Message[]) => {
      this.messages = messages;
      this.filterAnnouncements(messages);
    });
  }

  filterAnnouncements(messages: Message[]) {
    let messageList: Message[] = [];
    if (messages) {
      messages.forEach((message: Message) => {
        const checkedMessage: Message = this.checkMessage(message);
        switch (this.page.toLowerCase()) {
          case 'search':
            if (message.displayTarget === MessageTarget.SearchForm) {
              if (checkedMessage) {
                messageList.push(checkedMessage);
              }
            }
            break;
          case 'results':
            if (message.displayTarget === MessageTarget.Results) {
              if (checkedMessage) {
                messageList.push(checkedMessage);
              }
            }
            break;
          case 'itinerary':
            if (this.basketStatus === BookingStatus.ChooseMIGroup || this.basketStatus === BookingStatus.ProvideMi) {
              if (message.displayTarget === MessageTarget.MiForm) {
                if (checkedMessage) {
                  messageList.push(checkedMessage);
                }
              }
            } else if (this.basketStatus === BookingStatus.ProvideBi || this.basketStatus === BookingStatus.ApprovedBook) {
              if (message.displayTarget === MessageTarget.BasketBeforeConfirm) {
                if (checkedMessage) {
                  messageList.push(checkedMessage);
                }
              }
            }
            break;
          default:
            break;
        }
      });

      this.updateAnnouncements(messageList);
    }
  }

  updateAnnouncements(messageList) {
    this.showClearAllButton = this.checkClearAllButton(messageList);
    this.announcements = messageList.reduce(function (r: Message, a: Message) {
      r[a.key] = r[a.key] || [];
      r[a.key].push(a);
      return r;
    }, Object.create(null));
    this.countAnn();
  }

  checkClearAllButton(messageList: Message[]): boolean {
    return messageList.some((message: Message) => message.clickToConfirm === true);
  }

  private parseServiceTypeFromURL(url: string, message: any): ServiceType | null {
    const lastPart = url?.toLowerCase();
    switch (lastPart) {
      case 'hotels':
        return ServiceType.Hotel;
      case 'cars':
        return ServiceType.Car;
      case 'flights':
        return ServiceType.Flight;
      case 'rail':
        if (message.key === 'message_rail') {
          return ServiceType.Rail;
        }
        break;
      case 'europeanrail':
        return ServiceType.InternationalRail;
      case 'parkings':
        return ServiceType.Parking;
      case 'lounges':
        return ServiceType.Lounge;
      case 'fast-track':
        return ServiceType.FastTrack;
      case 'ferry':
        return ServiceType.Ferry;
      case 'taxis':
        return ServiceType.Cab;
      case 'eurostar':
        return ServiceType.Eurostar;
      default:
        return null;
    }
  }

  private isServiceTypeRecognizedInURL(message: any, url: string): boolean {
    const serviceType = this.parseServiceTypeFromURL(url, message);
    if (serviceType !== null) {
      this.currentServiceType = serviceType;
      return true;
    }
    return false;
  }

  filterAnnouncementsBolt(messages: Message[], page: string) {
    let messageList: Message[] = [];
    if (messages) {
      messages.forEach((message: Message) => {
        const checkedMessage = this.checkMessageBolt(message);
        switch (page.toLowerCase()) {
          case 'bolt':
            if (message.displayTarget === MessageTarget.Results) {
              if (checkedMessage) {
                messageList.push(checkedMessage);
              }
            }
            break;
          case 'search':
            if (message.displayTarget === MessageTarget.SearchForm) {
              if (checkedMessage) {
                messageList.push(checkedMessage);
              }
            }
            break;
          default:
            break;
        }
      });
      this.updateAnnouncements(messageList);
    }
  }

  checkMessageBolt(message: Message): Message {
    message = this.updateMessageDescription(message);
    const serviceType = this.boltSearchResultsServiceTypes?.find(service => message.description.toUpperCase().includes(service));
    if (serviceType) {
      return this.messagesService.filterAnnouncementsConfig(message, serviceType, null, null, true) ? message : null;
    }
  }

  checkMessage(message: Message): Message {
    message = this.updateMessageDescription(message);
    const serviceType = this.serviceType?.find(service => message.description.toUpperCase().includes(service));
    const url = this.router.url.split('/');
    const lastPart = url[url.length - 1].toLowerCase();
    if (this.serviceType.length && (serviceType && serviceType === this.parseServiceTypeFromURL(lastPart, message) ||
      (serviceType && this.page === 'search' && this.searchService.search_objects[serviceType]?.chosen) ||
      message.description.toUpperCase().includes('SUPPORT') ||
      message.description.toUpperCase().includes('SYSTEM') ||
      message.description.toUpperCase().includes('GENERIC'))
    ) {
      // return this.filterAnnouncementsConfig(message, serviceType) ? message : null;
      return this.messagesService.filterAnnouncementsConfig(message, serviceType, this.page, this.basket) ? message : null;
    } else if (!this.serviceType.length) {
      if (this.basketItemTypes.length &&
        !message.description.toUpperCase().includes('SUPPORT') &&
        !message.description.toUpperCase().includes('SYSTEM') &&
        !message.description.toUpperCase().includes('GENERIC') && this.page === 'itinerary') {
        return !!this.basketItemTypes.find((type) => message.description.toUpperCase().includes(type)) ? message : null;
      } else if ((this.page !== 'search' && !this.isServiceTypeRecognizedInURL(message, lastPart)) ||
        message.description.toUpperCase().includes('SUPPORT') ||
        message.description.toUpperCase().includes('SYSTEM') ||
        message.description.toUpperCase().includes('GENERIC')
      ) {
        return message;
      } else if (this.isServiceTypeRecognizedInURL(message, lastPart)) {
        const serviceType = message.description.toUpperCase().includes(this.currentServiceType);
        if (serviceType) {
          return this.messagesService.filterAnnouncementsConfig(message, this.currentServiceType, this.page, this.basket) ? message : null;

          // return this.filterAnnouncementsConfig(message, this.currentServiceType) ? message : null;
        }
      }
    } else if (this.basketItemTypes.length) {
      return !!this.basketItemTypes.find((type) => message.description.toUpperCase().includes(type)) ? message : null;
    }
  }

  updateMessageDescription(message): Message {
    if (message.description === 'Taxi Message') {
      message.description = 'Cab Message';
    }
    if (message.description === 'IRL Message') {
      message.description = 'INTERNATIONAL_RAIL Message';
    }
    if (message.description === 'Fast Track Message') {
      message.description = 'FAST_TRACK Message';
    }

    return message;
  }

  countAnn() {
    let annCount = 0;
    for (const annKey of Object.keys(this.announcements)) {
      const annPage = this.announcements[annKey];
      if (!annPage) {
        continue;
      }
      annCount += Object.values(annPage).length;
    }
    if (annCount > 0) {
      this.open = true;
    }
  }

  clearAllAnnouncements() {
    let announcementsList: string[] = [];
    const keys = Object.keys(this.announcements);
    for (let j = 0; j < keys.length; j++) {
      for (let k = 0; k < this.announcements[keys[j]].length; k++) {
        if (this.announcements[keys[j]][k].clickToConfirm) {
          announcementsList.push(this.announcements[keys[j]][k].id);
        }
      }
    }
    this.deleteAnnouncement(announcementsList);
  }

  deleteAnnouncement(id: string | string[]) {
    this.userService.confirmMessages(id).subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.getAnnouncements();
      } else {
        throw new Error("Can't confirm the message");
      }
    });
  }

  setTravelAdvice() {
    const travelAdviceFlag: any = this.userService.getUserFavoriteObject(LightningUserFavorurite.DisplayFCDOAdvice);
    if (this.router.url.includes('/results/flights') && travelAdviceFlag && travelAdviceFlag.value) {
      const searchParams = this.searchService.searches[ServiceType.Flight];
      const destination = searchParams.arriveLocation as OBTAirportDetails;
      const country = this.formatCountry(destination);
      if (destination.country !== "United Kingdom") {
        this.isTravelAdvice = true;
        this.travelAdvice = {
          country: destination.country,
          link: `https://www.gov.uk/foreign-travel-advice/${country}`
        };
        this.open = true;
      }
    }
    else if (this.router.url.includes('/results/hotels') && travelAdviceFlag && travelAdviceFlag.value) {
      const searchParams = this.searchService.searches[ServiceType.Hotel];
      const country = searchParams.country;
      let countryName = this.formatCountryHotels(country);
      if (country.cCode !== "GB") {
        this.isTravelAdvice = true;
        this.travelAdvice = {
          country: countryName,
          link: `https://www.gov.uk/foreign-travel-advice/${countryName.replaceAll(' ', '-').toLowerCase()}`
        };
      }
    }
  }

  formatCountry(destination: OBTAirportDetails) {
    const countryList = this.searchService.getCountries();
    const currentCountry = countryList.find(country => country.cCode === destination.countryCode);
    let countryName =
      currentCountry?.cName === destination.country ? currentCountry?.cName : destination.country;
    if (currentCountry?.altName) {
      countryName = currentCountry?.altName;
    }

    return countryName.replaceAll(' ', '-').toLowerCase();
  }

  formatCountryHotels(country: Country) {
    const countryList = this.searchService.getCountries();
    const currentCountry = countryList.find(listCountry => listCountry.cCode === country.cCode);
    let countryName =
      currentCountry?.cName;
    if (currentCountry?.altName) {
      countryName = currentCountry?.altName;
    }

    return countryName;
  }


  ngOnDestroy(): void {
    if (this.announcementsSubscription) {
      this.announcementsSubscription.unsubscribe();
    }
     //Since announcements are no longer in a popup we don't need to trap focus
    //this.removeFocusTrap();

  }

  async suppressAnnouncement(id: string): Promise<void> {
    await this.userService.confirmMessages(id).toPromise();
  }

  // //Since announcements are no longer in a popup we don't need to trap focus
  /*setFocusTrap() {
    if (this.announcementDropdown) {
      this.focusableElements = Array.from(this.announcementDropdown.nativeElement.querySelectorAll(
        'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex]:not([tabindex="-1"]), [contenteditable]'
      ));

      if (this.focusableElements.length > 0) {
        this.firstFocusableElement = this.focusableElements[0];
        this.lastFocusableElement = this.focusableElements[this.focusableElements.length - 1];

        this.focusableElements.forEach(element => {
          this.renderer.listen(element, 'keydown', (event: KeyboardEvent) => this.handleTabKey(event));
        });

        // Focus the first focusable element
        this.firstFocusableElement.focus();
      }
    }
  }*/

  // //Since announcements are no longer in a popup we don't need to trap focus
  /*handleTabKey(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      if (event.shiftKey) {
        if (document.activeElement === this.firstFocusableElement) {
          event.preventDefault();
          this.lastFocusableElement.focus();
        }
      } else {
        if (document.activeElement === this.lastFocusableElement) {
          event.preventDefault();
          this.firstFocusableElement.focus();
        }
      }
    }
  }*/

  /* //Since announcements are no longer in a popup we don't need to trap focus
  removeFocusTrap() {
    if (this.open && this.focusableElements.length) {
      this.focusableElements.forEach(element => {
        this.renderer.listen(element, 'keydown', null);
      });
    }
  }*/
}

