import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, } from '@angular/core';
import {
  EnterpriseSearchService,
  FlightJourney,
  FlightSeatMap,
  FlightSeatMapSeats,
} from '@sabstravtech/obtservices/angular';
import { SeatMapType } from '../../../../../vendor/enum/seat-map-type.enum';
import { FlightSeat } from './flight-set.interface';

@Component({
  selector: 'app-flight-seat-map',
  templateUrl: './flight-seat-map.component.html',
  styleUrls: ['./flight-seat-map.component.scss']
})
export class FlightSeatMapComponent implements OnInit {
  @Input() requestActive: boolean = true;
  @Input() flight: FlightJourney = null;
  @Input() flightSeats: FlightSeatMap = null;
  @Input() supplier: string = '';
  @Input() travellerId: string = '';
  @Output() seat: EventEmitter<{ selectedSeat: FlightSeatMapSeats; id: string; }> =
    new EventEmitter();
  @Output() loyaltyRetrieved: EventEmitter<string> = new EventEmitter<string>();
  @Output() setSeatsReceived: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isLoading = false;
  seatKeys: FlightSeat[][];
  seats: FlightSeat[];
  seatsReceived: boolean = false;
  localSelectedSeat: FlightSeat;
  loyaltyCode: string = '';
  aisleSeatLocation = [];

  constructor(public searchService: EnterpriseSearchService) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.flightSeats?.currentValue) {
      this.getFlightSeatMap();
    }
  }

  getFlightSeatMap(): void {
    this.isLoading = true;
    this.seats = this.flightSeats.seats;

    for (let seat of this.seats) {
      const seatCode = seat.attributes.map(r => r.code);
      const isAisleSeat = seatCode.includes(SeatMapType.AisleSeat) && !seatCode.includes(SeatMapType.CenterSeat);
      if (isAisleSeat) {
        this.aisleSeatLocation.push(seat.column);
      }
    }

    // this.seats.map((seat: FlightSeat) => (seat.selected = false));
    this.getSeatKeys();
  }

  getSeatKeys(): void {
    this.seatKeys = this.seats.reduce((prev, current) => {
      prev[current.row] = prev[current.row] || [];
      prev[current.row].push(current);
      return prev;
    }, Object.create(null));
    this.seatsReceived = !!Object.keys(this.seatKeys).length;
    this.setSeatsReceived.emit(this.seatsReceived);
    this.fillEmptySpaces();
  }

  fillEmptySpaces(): void {
    //Fills in any blank spaces for UX reasons
    let fullSeatKey = [];
    const context = this;
    Object.keys(this.seatKeys).forEach(function (key, i) {
      //Get a row with all available seats
      let seatKey = context.seatKeys[key];
      if (seatKey.length > fullSeatKey.length) {
        fullSeatKey = seatKey;
      }
    });
    Object.keys(this.seatKeys).forEach(function (key, i) {
      //Go through each seatkey, and fill in any blank spaces
      let seatKey = context.seatKeys[key];
      if (seatKey.length < fullSeatKey.length) {
        for (let x = 0; x < fullSeatKey.length; x++) {
          if (!seatKey[x]) {
            //If this index of this seat doesn't exist on the seatkey, add a blank seat
            let blankSeat = {} as FlightSeat;
            blankSeat.column = fullSeatKey[x].column;
            blankSeat.row = seatKey[0].row;
            blankSeat.attributes = fullSeatKey[x].attributes;
            blankSeat.isFake = true;
            seatKey.push(blankSeat); //Blank seat will show as unavailable so won't affect functionality
          } else if (seatKey[x].column !== fullSeatKey[x].column) {
            //If the column is incorrect, add a blank seat with the correct column
            let blankSeat = {} as FlightSeat;
            blankSeat.column = fullSeatKey[x].column;
            blankSeat.row = seatKey[0].row;
            blankSeat.attributes = fullSeatKey[x].attributes;
            blankSeat.isFake = true;
            seatKey.splice(x, 0, blankSeat); //Blank seat will show as unavailable so won't affect functionality
          }
        }
      }
    });
    this.isLoading = false;
  }

  selectedSeat(selectedSeat: FlightSeatMapSeats): void {
    // this is the only update we have
    const beforeUpdateSeat = this.localSelectedSeat;
    this.localSelectedSeat = selectedSeat;

    if (this.flightSeats.loyaltyCode) {
      selectedSeat.attributes.push({
        code: this.flightSeats.loyaltyCode,
        description: 'Loyalty: ' + this.flight.operatingCarrier
      });
    }

    this.seats.forEach((seat: FlightSeat) => {
      if (!seat.selected && seat.row === selectedSeat.row && seat.column === selectedSeat.column) {
        seat.selected = true;
        seat.userId = this.travellerId;
        this.seat.emit({ selectedSeat, id: this.flight.id });
        // we should make anything false that is handled by a different person/flight
      } else if (
        seat.selected &&
        seat.row === selectedSeat.row &&
        seat.column === selectedSeat.column
      ) {
        this.seat.emit({ selectedSeat: null, id: this.flight.id });
        seat.selected = false;
        this.localSelectedSeat = null;
      } else if (seat.row === beforeUpdateSeat?.row && seat.column === beforeUpdateSeat?.column) {
        seat.selected = false;
      }
    });
    this.getSeatKeys();
  }

  clearSelectedSeat(): void {
    this.localSelectedSeat = null;
    this.seat.emit({ selectedSeat: null, id: this.flight.id });
    this.seats.forEach((seat: FlightSeat) => {
      seat.selected = false;
      this.getSeatKeys();
    });
  }

  isSelected(seat: FlightSeat): boolean {
    return (
      seat.selected === true ||
      (seat.row === this.localSelectedSeat?.row && seat.column === this.localSelectedSeat?.column)
    );
  }

  isNotAvailable(seat: FlightSeat): boolean {
    return (
      !seat.available &&
      !(seat.row === this.localSelectedSeat?.row && seat.column === this.localSelectedSeat?.column)
    );
  }
}

