import { Component, Input, OnInit } from '@angular/core';
import { RouteHappyResults } from '@sabstravtech/obtservices/base';

@Component({
  selector: 'app-routehappy-flight-modal-upas',
  templateUrl: './routehappy-flight-modal-upas.component.html',
  styleUrls: ['./routehappy-flight-modal-upas.component.scss']
})
export class RoutehappyFlightModalUPAsComponent implements OnInit {
  @Input() results: RouteHappyResults = null;
  constructor(
  ) {}

  ngOnInit(): void {
  }
}

