<span class="tooltip_wrap" *ngIf="ancillaryStatus === AncillaryStatus.Unknown; else normalIcons">
  <section (mouseleave)="tt.close()" class="container">
    <img
      class="icon unknown"
      placement="top"
      triggers="manual"
      #tt="ngbTooltip"
      (mouseenter)="tt.open()"
      (focus)="tt.open()"
      (blur)="tt.close()"
      i18n-ngbTooltip
      tabindex="0"
      container="body"
      ngbTooltip="Information unknown, please check the airline fare rules"
      aria-label="Information unknown, please check the airline fare rules"
      alt="Ancillary Unknown"
      [src]="getAncillaryIconSrc(ancillaryStatus)" /></section
></span>
<ng-template #normalIcons>
  <div
    [ngClass]="{ 'chargeable': ancillaryStatus === AncillaryStatus.Chargeable, 'return-flight': isReturnFlight }">
    <img
      class="icon"
      alt="Ancillary {{ ancillaryStatus }}"
      [src]="getAncillaryIconSrc(ancillaryStatus)" />
  </div>
</ng-template>

