<app-loading-screen
    *ngIf="searchService.search_loading || loadingHotels"
    icon="ico-rail">
    <span title i18n="@@wellbeingloading_title">Just loading your search results</span>
    <div msgOne>
        <span i18n="@@wellbeingloading_msgOne">Loading rail and hotel results...</span>
    </div>
    <span msgTwo i18n="wellbeingloading_msgTwo">Checking for availability</span>
</app-loading-screen>
<ng-container
    *ngIf="{
        cheapest: searchParamsRail.cheapest | async,
        isLoading: (searchParamsRail.isLoading | async),
        jitRulesReceived: (searchParamsHotel.jitRulesReceived | async)
    } as data">
    <div class="background-60">
        <app-risk-alerts [countryCode]="'GB'" [wellBeing]="true"></app-risk-alerts>
    </div>
    <div *ngIf="!data.isLoading">
        <div style="margin-bottom: 2rem; margin-top: 2rem">
            <div class="title">
                <h3>Recommended Rail Result</h3>
                <a (click)="navigateTo(ServiceType.Rail)" class="view_all_link"
                    >View All Rail Results</a
                >
            </div>
            <app-wellbeing-chosen-rail
                *ngIf="
                    data.cheapest.outboundCheapest.dualSingleJourneyFares.price ||
                    data.cheapest.outboundCheapest.returnJourneyFares.price
                "
                [outboundCheapest]="data.cheapest.outboundCheapest"
                [inboundCheapest]="data.cheapest.inboundCheapest"></app-wellbeing-chosen-rail>
            <p
                class="error"
                *ngIf="
                    !data.cheapest.outboundCheapest.dualSingleJourneyFares.price &&
                    !data.cheapest.outboundCheapest.returnJourneyFares.price
                ">
                No Rail Found!
            </p>
        </div>
        <div>
            <div class="title">
                <h3>Recommended Hotel Result</h3>
                <a (click)="navigateTo(ServiceType.Hotel)" class="view_all_link"
                    >View All Hotel Results</a
                >
            </div>
            <!-- MAP VIEW-->
            <ng-container *ngIf="hotelViewController.showMap">
                <div class="hotel-map-results" *ngIf="hotelViewController.single">
                    <app-map-hotels
                        [noOfRooms]="noOfRooms"
                        (afterBackToList)="hotelViewController.showMap = false"
                        [arrayOfHotels]="this.hotelViewController.hotelChosen"
                        [mapControl]="hotelViewController">
                    </app-map-hotels>
                </div>
                <div class="hotel-map-results" *ngIf="!hotelViewController.single">
                    <app-map-hotels
                        [noOfRooms]="noOfRooms"
                        (afterBackToList)="hotelViewController.showMap = false"
                        [arrayOfHotels]="data.results"
                        [mapControl]="hotelViewController">
                    </app-map-hotels>
                </div>
            </ng-container>
            <app-item-hotels
                *ngIf="cheapestHotel && !hotelViewController.showMap"
                [hotelDetail]="cheapestHotel"
                [jitRulesReceived]="data.jitRulesReceived"
                [rooms]="cheapestHotelAvailability">
            </app-item-hotels>
            <p class="error" *ngIf="!cheapestHotel">No Hotel Found!</p>
        </div>
    </div>
</ng-container>
