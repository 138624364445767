import { Pipe, PipeTransform } from '@angular/core';
import { AirportResults, SearchAirportsFetcher } from '@sabstravtech/obtservices/angular';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

export enum AirportIataFormat {
  iataonly,
  nameandiata,
  nameonly
}
@Pipe({
  name: 'airportIata',
  pure: true
})
export class AirportIataPipe implements PipeTransform {
  constructor(private searchAirportsFetcher: SearchAirportsFetcher) {}

  transform(value: string, format: AirportIataFormat = AirportIataFormat.nameandiata): Observable<string> {
    if (value) {
      value = value.substring(0, 3);
      return this.searchAirportsFetcher.fetchOne({ query: value }).pipe(
        take(1),
        map((results: AirportResults) => {
          if (results.iataMatch) {
            switch (format) {
              case AirportIataFormat.nameandiata:
                return results.iataMatch.name + ' (' + results.iataMatch.iata + ')';
              case AirportIataFormat.nameonly:
                return results.iataMatch.name;
              case AirportIataFormat.iataonly:
                return results.iataMatch.iata;
              default:
                const tmp: never = format;
                console.log(tmp);
            }
          }
          return 'Not Found: ' + value;
        })
      );
    } else {
      return of('Converting...');
    }
  }
}
