import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import {
  FlightItineraryWithExtensions,
  ServiceProvider,
  UserFavorurite
} from '@sabstravtech/obtservices/base';
import {
  FlightSeatMapSeats,
  FlightExtrasOptions,
  UserService,
  FlightSeatMap,
  EnterpriseSearchService,
  InputFlightJourney,
  ServiceType,
  FlightOptions,
  ModalOpenerService,
  User,
  PreferenceKey,
  UserPreference,
  HelperRoutines
} from '@sabstravtech/obtservices/angular';
import { LightningUserFavorurite } from '../../../../../vendor/classes/user-favourite.enum';
import _ from 'lodash';
import { LightningModalTypes } from '../../../../../vendor/classes/modal-types.enum';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-flight-seat-map-tab',
  templateUrl: './flight-seat-map-tab.component.html',
  styleUrls: ['./flight-seat-map-tab.component.scss']
})
export class FlightSeatMapTabComponent {
  permittedServicesLCC: string[] = [ServiceProvider.Travelfusion];
  permittedServicesGDS: string[] = [
    ServiceProvider.SabreFlight,
    ServiceProvider.TravelportFlight,
    ServiceProvider.AmadeusFlight
  ];
  permittedServicesExtras: string[] = [
    ServiceProvider.Travelfusion,
    ServiceProvider.TravelportFlight,
    ServiceProvider.AmadeusFlight
  ];
  showSeatmap = false;
  showAllExtras = false;
  loyaltyCodes: string[] = [];
  activeRequests: boolean[] = [];
  showLoyalty = false;
  disableLoyalty = false;
  luggagePerBooking = false;
  luggagePerPax = false;
  ancillaryPerBooking = false;
  activeTab: string;
  active;
  seatsReceived = false;
  @Input() parentTabId: string = '';
  @Input() flight: FlightItineraryWithExtensions = null;
  @Input() supplier: string = '';
  @Input() travellerId: string = '';
  @Input() travellerIndex: number = 0;
  @Input() isGuest = false;
  @Output() seat: EventEmitter<{ selectedSeat: FlightSeatMapSeats; id: string; }> =
    new EventEmitter();
  @Output() onNotValid: EventEmitter<boolean> = new EventEmitter();
  @Output() flightExtras: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedFlightExtrasChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() loyaltyCode: EventEmitter<any> = new EventEmitter<any>();
  @Output() processed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() loyaltyCodesChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  inboundFlightsSeats: FlightSeatMap[] = [];
  outboundFlightsSeats: FlightSeatMap[] = [];
  inboundFlightsExtras: FlightOptions[] = [];
  outboundFlightsExtras: FlightOptions[] = [];
  @Input() selectedSeatWithId: { [x: string]: FlightSeatMapSeats[]; } = {};
  ServiceProvider = ServiceProvider;
  loadOnce = false;
  user: User = null;
  seatingPreference: string = null;
  outboundSeats: FlightSeatMap[];
  inboundSeats: FlightSeatMap[];
  constructor(
    public userService: UserService,
    public searchService: EnterpriseSearchService,
    private modalService: ModalOpenerService,
    private helpers: HelperRoutines
  ) { }

  ngOnInit() {
    const seatMapConfigs = this.userService.getUserFavoriteObject<{
      GDS: { showSeatMaps: boolean; };
      LCC: { showSeatMaps: boolean; showExtras: boolean; };
      NDC: { showSeatMaps: boolean; };
    }>(LightningUserFavorurite.FlightResultsConfiguration);

    const allowAgentToViewSeatMaps = this.userService.getUserFavoriteObject<{
      GDS: { showSeatMaps: boolean; };
      LCC: { showSeatMaps: boolean; };
      NDC: { showSeatMaps: boolean; };
    }>(LightningUserFavorurite.AllowAgentToViewSeatMaps);

    if (this.permittedServicesLCC.includes(this.supplier)) {
      this.showSeatmap = seatMapConfigs?.LCC?.showSeatMaps || (this.userService.userIsAgent() && allowAgentToViewSeatMaps?.LCC?.showSeatMaps);
    } else if (this.permittedServicesGDS.includes(this.supplier)) {
      this.showSeatmap = seatMapConfigs?.GDS?.showSeatMaps || (this.userService.userIsAgent() && allowAgentToViewSeatMaps?.GDS?.showSeatMaps);
    }

    this.disableLoyalty = this.userService.getUserFavoriteObject<{ disableLoyalty: boolean; }>(
      UserFavorurite.DisableLoyalty
    )?.disableLoyalty;

    this.showAllExtras = this.permittedServicesExtras.includes(this.flight.source);

    // * For now load all the seat map once for TFX
    // TODO this component needs to be refactored to be the same as Scion
    this.loadOnce = this.permittedServicesLCC.includes(this.supplier);

    if (this.showSeatmap) {
      this.showLoyalty =
        (this.permittedServicesGDS.includes(this.supplier) ||
          this.permittedServicesLCC.includes(this.supplier)) &&
        !this.disableLoyalty;

      let flights = [...this.flight.outboundFlights, this.flight.inboundFlights];
      flights.forEach(f => {
        this.loyaltyCodes.push('');
        this.activeRequests.push(false);
      });

      this.loadFlightsSeats(this.loadOnce)
        .then(async () => {
          if (this.showAllExtras) {
            await this.loadFlightsExtras(this.loyaltyCodes[0], this.loadOnce).catch(() =>
              this.onNotValid.emit(true)
            );
          }
        })
        .then(() => this.processed.emit(true))
        .catch(() => this.onNotValid.emit(true));
    } else if (this.showAllExtras) {
      this.loadFlightsExtras(this.loyaltyCodes[0], this.loadOnce)
        .catch(() => this.onNotValid.emit(true))
        .then(() => this.processed.emit(true));
    }
    if (this.travellerId && !this.isGuest) {
      this.userService.getUser(this.travellerId).subscribe((user: User) => {
        if (user) {
          this.user = user;
        }
      });
      this.userService.getUserMi(this.travellerId).subscribe((user: User) => {
        if (user && user.preferences) {
          this.seatingPreference = user.preferences.filter((preference: UserPreference) => preference.preferenceKey === PreferenceKey.SeatingPreference)?.[0]?.preferenceValue;
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.parentTabId?.currentValue === this.parentTabId) {
      this.disableSelectedSeats();
    }
  }

  getSeat(seat: { selectedSeat: FlightSeatMapSeats; id: string; }) {
    this.seat.emit(seat);
  }

  disableSelectedSeats() {
    this.outboundSeats = JSON.parse(JSON.stringify(this.outboundFlightsSeats));;
    this.inboundSeats = JSON.parse(JSON.stringify(this.inboundFlightsSeats));;
    this.outboundSeats.forEach((outboundFlight: FlightSeatMap, index) => {
      outboundFlight?.seats.forEach((seat: FlightSeatMapSeats) => {
        if (this.selectedSeatWithId[this.flight.outboundFlights[index].id]) {
          this.selectedSeatWithId[this.flight.outboundFlights[index].id].forEach(item => {
            if (item) {
              if (!item.available) {
                item.available = true;
              }
              if (item.column === seat.column && item.row === seat.row) {
                seat.available = false;
                seat.selected = true;
              }
            }
          });
        }
      });
    });

    this.inboundSeats.forEach((inboundFlight: FlightSeatMap, index) => {
      inboundFlight?.seats.forEach((seat: FlightSeatMapSeats) => {
        if (this.selectedSeatWithId[this.flight.inboundFlights[index].id]) {
          this.selectedSeatWithId[this.flight.inboundFlights[index].id].forEach(item => {
            if (!item.available) {
              item.available = true;
            }
            if (item.column === seat.column && item.row === seat.row) {
              seat.available = false;
              seat.selected = true;
            }
          });
        }
      });
    });
  }

  getLoyalty(loyalty: string, id: string, loyaltyProgram: string) {
    this.loyaltyCode.emit({ loyalty, id, loyaltyProgram });
  }

  /**
   * Load flights seats
   * TODO we definitely need to refactor this function to reduce the complexity
   * @param loadOnce
   */
  async loadFlightsSeats(loadOnce: boolean = false) {
    let errorMessage: { code: string; message: string; }[] = [];
    if (loadOnce) {
      delete this.flight.__typename;

      const legs = [
        ...this.flight.outboundFlights,
        ...(this.flight.inboundFlights || []).map(x => ({
          ...x,
          additional: { ...x.additional, isIn: true }
        }))
      ];

      const { results: seats, errors } = await this.searchService
        .getFlightSeatMap(legs.map(leg => this.helpers.convertFlightJourneyToInputFlightJourney(leg)), this.supplier, '', this.travellerId)
        .toPromise();

      if (errors.length) {
        errorMessage = errors.map(error => ({
          code: error.errorCode,
          message: error.errorMessage
        }));
      }

      legs.forEach((leg, index) => {
        const seatMap = seats.find(
          s =>
            s?.aircraft?.carrier === leg.operatingCarrier &&
            s?.aircraft?.number == leg.operatingFlightNumber
        );

        this.setLoyaltyCode(seatMap?.loyaltyCode || '', index, false);

        if (!leg.additional?.isIn) {
          this.getLoyalty(
            seatMap?.loyaltyCode,
            this.flight.outboundFlights[index]?.id,
            seatMap?.loyaltyProgram
          );
          this.outboundFlightsSeats.push(seatMap);
        } else {
          this.getLoyalty(
            seatMap?.loyaltyCode,
            this.flight.inboundFlights[index - this.flight.outboundFlights.length + 1]?.id,
            seatMap?.loyaltyProgram
          );
          this.inboundFlightsSeats.push(seatMap);
        }
      });
    } else {
      for (const [index, f] of this.flight.outboundFlights.entries()) {
        delete this.flight.__typename;
        const { results: seats, errors } = await this.searchService
          .getFlightSeatMap([this.helpers.convertFlightJourneyToInputFlightJourney(f)], this.supplier, '', this.travellerId)
          .toPromise();

        if (errors.length) {
          errorMessage = errors.map(error => ({
            code: error.errorCode,
            message: error.errorMessage
          }));
        }

        this.setLoyaltyCode(seats[0].loyaltyCode, index, false);
        this.getLoyalty(seats[0].loyaltyCode, f.id, seats[0].loyaltyProgram);
        this.outboundFlightsSeats.push(seats[0]);
      }

      if (this.flight.inboundFlights?.length) {
        for (const [index, f] of this.flight.inboundFlights.entries()) {
          delete this.flight.__typename;
          const { results: seats, errors } = await this.searchService
            .getFlightSeatMap([this.helpers.convertFlightJourneyToInputFlightJourney(f)], this.supplier, '', this.travellerId)
            .toPromise();

          if (errors.length) {
            errorMessage = errors.map(error => ({
              code: error.errorCode,
              message: error.errorMessage
            }));
          }

          this.setLoyaltyCode(seats[0].loyaltyCode, index, true);
          this.getLoyalty(seats[0].loyaltyCode, f.id, seats[0].loyaltyProgram);
          this.inboundFlightsSeats.push(seats[0]);
        }
      }
    }
    this.inboundSeats = JSON.parse(JSON.stringify(this.inboundFlightsSeats));;
    this.outboundSeats = JSON.parse(JSON.stringify(this.outboundFlightsSeats));;
    await this.checkError(errorMessage);
  }

  async checkError(errorMessage: { code: string; message: string; }[]) {
    const noDOBError = errorMessage.find(x => x.code === 'NO_DOB_WITH_FFN');
    if (noDOBError) {
      await this.modalService.open(
        LightningModalTypes.ModalErrorComponent,
        {},
        { error: noDOBError.message }
      );
    } else if (errorMessage.some(x => x.message)) {
      await Promise.all(
        errorMessage.map(
          async error =>
            await this.modalService.open(
              LightningModalTypes.ModalErrorComponent,
              {},
              { error: error.message }
            )
        )
      );
    }
  }

  async loadFlightsExtras(loyaltyCode: string = '', loadOnce: boolean = false) {
    if (loadOnce) {
      const legs = [
        ...this.flight.outboundFlights,
        ...(this.flight.inboundFlights || []).map(x => ({
          ...x,
          additional: { ...x.additional, isIn: true }
        }))
      ];

      const extras = await this.searchService.searches[ServiceType.Flight]
        .getFlightExtras(legs.map(leg => this.helpers.convertFlightJourneyToInputFlightJourney(leg)), this.supplier, loyaltyCode, this.travellerId)
        .toPromise();

      legs.forEach((leg, index) => {
        if (!leg.additional?.isIn) {
          this.outboundFlightsExtras.push(extras[index]);
        } else {
          this.inboundFlightsExtras.push(extras[index]);
        }
      });

      if (this.supplier === ServiceProvider.Travelfusion) {
        this.luggagePerBooking = extras?.[0]?.luggagePerBooking;
        this.ancillaryPerBooking = extras?.[0]?.ancillaryPerBooking;
        this.luggagePerPax = extras?.[0]?.luggagePerPax;
      }
    } else {
      const [extras] = await this.searchService.searches[ServiceType.Flight]
        .getFlightExtras(
          this.flight.outboundFlights.map(leg => this.helpers.convertFlightJourneyToInputFlightJourney(leg)),
          this.supplier,
          loyaltyCode,
          this.travellerId
        )
        .toPromise();
      this.outboundFlightsExtras.push(extras);

      if (this.supplier === ServiceProvider.Travelfusion) {
        this.luggagePerBooking = extras?.luggagePerBooking;
        this.ancillaryPerBooking = extras?.ancillaryPerBooking;
        this.luggagePerPax = extras?.luggagePerPax;
      }

      if (this.flight.inboundFlights.length) {
        const [extras] = await this.searchService.searches[ServiceType.Flight]
          .getFlightExtras(
            this.flight.inboundFlights.map(leg => this.helpers.convertFlightJourneyToInputFlightJourney(leg)),
            this.supplier,
            loyaltyCode,
            this.travellerId
          )
          .toPromise();
        this.inboundFlightsExtras.push(extras);
      }
    }
  }

  /**
   * Recalculate flight seats based on loyalty code
   * TODO we definitely need to refactor this function to reduce the complexity
   * @param index
   * @param inbound
   */
  async recalculateFlightSeat(index: number, inbound: boolean = false) {
    let errorMessage: { code: string; message: string; }[] = [];
    if (this.loadOnce) {
      delete this.flight.__typename;

      const legs = [
        ...this.flight.outboundFlights,
        ...(this.flight.inboundFlights || []).map(x => ({
          ...x,
          additional: { ...x.additional, isIn: true }
        }))
      ];
      legs.forEach((_, legIdx) => {
        this.activeRequests[legIdx] = true;
      });

      this.loyaltyCodesChange.emit(!!this.loyaltyCodes[index]);

      const { results: seats, errors } = await this.searchService
        .getFlightSeatMap(
          legs.map(leg => this.helpers.convertFlightJourneyToInputFlightJourney(leg)),
          this.supplier,
          this.loyaltyCodes[index],
          this.travellerId
        )
        .toPromise();

      if (errors.length) {
        errorMessage = errors.map(error => ({
          code: error.errorCode,
          message: error.errorMessage
        }));
      }

      legs.forEach((leg, legIdx) => {
        const seatMap = seats.find(
          s =>
            s?.aircraft?.carrier === leg.operatingCarrier &&
            s?.aircraft?.number == leg.operatingFlightNumber
        );

        this.setLoyaltyCode(seatMap?.loyaltyCode || '', legIdx, false);

        if (!leg.additional?.isIn) {
          this.getLoyalty(
            seatMap?.loyaltyCode,
            this.flight.outboundFlights[legIdx]?.id,
            seatMap?.loyaltyProgram
          );
          this.outboundFlightsSeats[legIdx] = seats[0];
          this.activeRequests[legIdx] = false;
        } else {
          this.getLoyalty(
            seatMap?.loyaltyCode,
            this.flight.inboundFlights[legIdx - this.flight.outboundFlights.length + 1]?.id,
            seatMap?.loyaltyProgram
          );
          this.inboundFlightsSeats[legIdx] = seats[0];
          this.activeRequests[legIdx] = false;
        }
      });
    } else {
      if (inbound) {
        this.activeRequests[this.flight.outboundFlights.length + index] = true;
        const { results: seats, errors } = await this.searchService
          .getFlightSeatMap(
            [this.helpers.convertFlightJourneyToInputFlightJourney(this.flight.inboundFlights[index])],
            this.supplier,
            this.loyaltyCodes[this.flight.outboundFlights.length + index],
            this.travellerId
          )
          .toPromise();

        if (errors.length) {
          errorMessage = errors.map(error => ({
            code: error.errorCode,
            message: error.errorMessage
          }));
        }

        if (seats[0].loyaltyCode) {
          this.setLoyaltyCode(seats[0].loyaltyCode, index, true);
          this.getLoyalty(
            seats[0].loyaltyCode,
            this.flight.inboundFlights[index].id,
            seats[0].loyaltyProgram
          );
        }

        this.inboundFlightsSeats[index] = seats[0];
        this.activeRequests[this.flight.outboundFlights.length + index] = false;
      } else {
        this.activeRequests[index] = true;
        const { results: seats, errors } = await this.searchService
          .getFlightSeatMap(
            [this.helpers.convertFlightJourneyToInputFlightJourney(this.flight.outboundFlights[index])],
            this.supplier,
            this.loyaltyCodes[index],
            this.travellerId
          )
          .toPromise();

        if (errors.length) {
          errorMessage = errors.map(error => ({
            code: error.errorCode,
            message: error.errorMessage
          }));
        }

        if (seats[0].loyaltyCode) {
          this.setLoyaltyCode(seats[0].loyaltyCode, index);
          this.getLoyalty(
            seats[0].loyaltyCode,
            this.flight.outboundFlights[index].id,
            seats[0].loyaltyProgram
          );
        }
        this.outboundFlightsSeats[index] = seats[0];
        this.activeRequests[index] = false;
      }
    }

    if (this.showAllExtras) {
      // Reload flight extras
      this.outboundFlightsExtras = [];
      this.inboundFlightsExtras = [];
      this.loadFlightsExtras(this.loyaltyCodes[index], this.loadOnce);
    }
    this.inboundSeats = JSON.parse(JSON.stringify(this.inboundFlightsSeats));;
    this.outboundSeats = JSON.parse(JSON.stringify(this.outboundFlightsSeats));;
    await this.checkError(errorMessage);
  }

  getExtras(extras: {
    data: {
      advancedAncillaryOptions: FlightExtrasOptions;
      baggageAllowance: FlightExtrasOptions;
      reducedMobillityAssistance: FlightExtrasOptions;
      flightExtras: FlightExtrasOptions;
    };
    flightIds: [string]; // List of flightIds on the same journey
    updatingFlightId: string;
    travellerId: string;
    key: string; // Updating object key
    luggagePerBooking: boolean;
    ancillaryPerBooking: boolean;
    luggagePerPax: boolean;
  }) {
    this.flightExtras.emit({
      luggagePerBooking: this.luggagePerBooking,
      ancillaryPerBooking: this.ancillaryPerBooking,
      luggagePerPax: this.luggagePerPax,
      ...extras
    });
    this.selectedFlightExtrasChange.emit({
      luggagePerBooking: this.luggagePerBooking,
      ancillaryPerBooking: this.ancillaryPerBooking,
      luggagePerPax: this.luggagePerPax,
      ...extras
    });
  }

  setLoyaltyCode(lc: string, index: number, inbound: boolean = false) {
    if (inbound) {
      this.loyaltyCodes[this.flight.outboundFlights.length + index] = lc;
    } else {
      this.loyaltyCodes[index] = lc;
    }
  }

  setSeatsReceived(received: boolean) {
    this.seatsReceived = received;
  }

  onNavChange(event: NgbNavChangeEvent): void {
    this.disableSelectedSeats();
    this.activeTab = event.nextId;
  }
}

