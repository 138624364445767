<div cdkTrapFocus
     aria-live="assertive">
    <div class="modal-header">
        <h3 class="modal-title"
            id="modal-header">Risk Alert Detail</h3>
        <button type="button"
                class="close"
                ngbAutoFocus
                (click)="close()"
                (keydown.enter)="close()"
                (keydown.space)="close()"
                aria-label="Close Risk Alert Detail Modal">
            <span aria-hidden="true"><i class='ico-cancel modal_exit_cross'></i></span>
        </button>
    </div>
    <div class="modal-body">
        <h4>{{alert.title}}</h4>
        Category: <h5>{{alert.category}}</h5>
        Risk Level: <h5>{{alert.risk}}</h5>
        <p>{{alert.description}}</p>
    </div>
</div>