<div cdkTrapFocus
     aria-live="assertive">
    <div class="modal-header">
        <h3 class="modal-title"
            i18n="@@autologoutTitle"
            id="modal-header">Auto Sign out</h3>
        <button type="button"
                class="close"
                aria-label="Auto Sign Out Close"
                ngbAutoFocus
                (click)="close(logout.LOGOUT)"
                (keydown.enter)="close(logout.LOGOUT)"
                (keydown.space)="close(logout.LOGOUT)"
                aria-describedby="signout-message">
            <span aria-hidden="true"><i class='ico-cancel modal_exit_cross'></i></span>
        </button>
    </div>
    <div class="modal-body"
         id="signout-message">
         <span i18n="@@autologoutBody">You have been inactive for a while and will be signed out in </span>{{timeOutDuration}}<span i18n="@@autologoutBody2">seconds, in order to prevent this click the
            Stay Signed In button.</span>
    </div>
    <div class="modal-footer">
        <button type="button"
                class="small-button small-button--no"
                (click)="close(logout.LOGOUT)"
                (keydown.enter)="close(logout.LOGOUT)"
                (keydown.space)="close(logout.LOGOUT)"
                i18n="@@autologoutClose">Log Out</button>
        <button type="button"
                id='button_ok'
                class="small-button"
                aria-label="Stay Signed In"
                aria-live="false"
                (click)="close(logout.STAYIN)"
                (keydown.enter)="close(logout.STAYIN)"
                (keydown.space)="close(logout.STAYIN)"
                i18n="@@modalStayedSignedIn">Stay Signed In</button>
    </div>
</div>