import { Pipe, PipeTransform } from '@angular/core';
import * as COUNTRIES from '../data/countries.json';

@Pipe({
  name: 'countryCode',
})
export class CountryCodePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return null;
  }

  /**
   * @description - get a country based on it's ISO code
   * @param code - the country's ISO code e.g. FR for France
   */
  getCountryFromCode(code: String): String | null {
    let found = null;

    try {
      found = COUNTRIES[code.toUpperCase()];
    } catch (error) {
      console.error(error);
    }

    return found;
  }
}
/*
..........,,,,,,,,,,,,,,,,,,,::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::,,,:::::::::::::::::::;;;;;;;;;;;;;;;;i
........,,,,,,,,,,,,,,,,,,,,:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::;;;;;;;;;;;;;;;;i
.......,,,,,,,,,,,,,,,,,,::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::;;;;;;;;;;;;;;;
,,,,,,,,,,,,,,,,,,,,,,,::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::;;;;;;;;;;;;;
,,,,,,,,,,,,,,,,,,,,,::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::;;;;;;;;;;;;;
,,,,,,,,,,,,,,,,,,,::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::;;;;;;;;;;;
,,,,,,,,,,,,,,,,::::::::::::::::::::::::::::::::::::::::::::::::::::::::;;;;:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::;;;;;;;;;;;
,,,,,,,,,,,::::::::::::::::::::::::::::::::;;;;;;;;;;;;;;;;;;;;;;;;:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::;;;;;;;;
,,,,,,,:::::::::::::::::::::::::::;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;:::::::::::::::::::::::::::::::::::::::::::::::::::::::;:::;;;;
,,,,,,:::::::::::::::::::::::;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;::::::::::::::::::::::::::::::::::::::::::::::;;;
::::::::::::::::::::::::::::;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;::::;::::::::::::::::::::::::::::::::::::::::;;;;
::::::::::::::::::::::::::;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;::::::::::::::::::::::::::::::::::::;;;;
::::::::::::::::::::;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;:::::::::::::::::::::::::::::::::;;;;
:::::::::::::;;:;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;:::::::::::::::::::::::::::::;;;;:
::::::::;:::;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;:::::::::::::::::::::::;:;
::::;;;;;;:;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;::::::::::::::::::::::::;
;;;;;;;;;;;;;;;;;;ii;;ii;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;::::::::::::::::::::::::;
;;;;;;;;;;;;;;iiiiiiiiiiiiiiii;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;::::;::::::::::::::;
;;;;;;;;;iiiiiiiiiiiiiiiii;iiii;;;;;;;;;;;;;;;;;;;;;;iiii;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;:::::::::::
;;;;;iiiiiiiiiiiiiiiiiiiiii;iiiii;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;:::::::::::
;;iiiiiii*iiiiiiiiiiiiiiiiiiiiii;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;::::;;;;;;:
;iii************iiiiiiiiiiiiiiiiiiiiiiiiiiiii;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;:::;;;;;;;:
;ii***************iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;:
;ii****************iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;:
;ii****************iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii;;;iiiiiiiiiiiiii;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;::;
;ii****************iiiiiiiiiiiiiiiiiiiiiiiiiii;;;;;;;;;;;;;;;;;iiiiiiiii;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
;ii******************iiiiiiiiiiiiiiiii;;;;;ii;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;i;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
;ii***********************iiiiiiiiiiiiiiiiiiiii;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;iii;ii;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
;ii************************iiiiiiiiiiiiiiiiiiiiiiiiii;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;iiiiiiiiiiiiii;;ii;;iiiiiiii;;;;;;;;;;;;;;;;;;;;;;;;;;;
;ii******************************************iiiiiiiiiii;iii;;;;;;;;;iii;;;;;;;;;;;;;;;iiiiiiii************iiiiiiiiiiiiiiiiii;;;;;;;;;;;;;;;;;;;;;;;;;
;i*****************************++++########+++***iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii**+++###########++***iiiiiiiiiiiiiii;;;;;;;;;;;;;;;;;;;;;;;;
;i*************************+++###zzznnnnnnzzz#++**iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii*++#zznnnnnnnnnnzz##+++***iiiiiiiiiiiiii;;;;;;;;;;;;;;;;;;;;;
:i***********************++##zznnxxxxxxMxxxxxnz##+***iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii**+#znxxMMMMMMMMMxxxnnnzz#++**iiiiiiiiiiiii;;;;;;;;;;;;;;;;;;;;
:i**+******************++##znnxMMMMMMWMMMMMMMxxnz#+****iiiiiiiiiiiiiiiiiiiiiiiiiiiiiii**+#nxMMMWWWWWWMMMMMMMMxxnzz#++**iiiiiiiiiii;;;;;;;;;;;;;;;;;;;;
:i**+****************++##znxxMMWWWWWWWWWWWWWWMxxnz#+*******iiiiiiiiiiiiiiiiiiiiiiiiii**+#zxMMWWWWWWWWWWWWWWMMMMMxnnz##+**iiiiiiiii;;;;;;;;;;;;;;;;;;;;
:i**+***************++#znnxMMMMWWWWWWWWWWWWWWWMxnz#+*********iiiiiiiiiiiiiiiiiiiiiii***+#nxMWWWWWWMMMMMMMMMMMMMMMMxxnz##+**iiiiiiii;;;;;;;;;;;;;;;;;;;
:i**+**************++#znxMMMMMMMMMxxxxxMMMMMMMMxxn##++********iiiiiiiiiiiiiii*********+#znxMMMMMMMMxxxxxxxxxxxMMMMMMxxnz#++**iiiiiii;;;;;;;;;;;;;;;;;;
:i**+************++#znnxMMMMxxxxxnnnnnnnnxxxMMxxnzz#+++**********iiiiiii***i*********++##znxxxxxxxnnnnnnnnnnnnnxxxxMxxxnnz#+**iiiiii;;;;;;;;;;;;;;;;;;
:i**+***********++#znxxMMMxxnnnzzzzzzzzzzznnnnnnnzz##++++**********iiiii*************+++#zznnnnnzzzzz########zzzznnxxxxxxnn##+*iiiiiii;;;;;;;;;;;;;;;;
:i**++*********++#znnxMxxxnnzzz##########zzzzzzzzz###+++++********i******************+++##zzzzzz#################zznnnxxxxnzz#+*iiiii;;;;;;;;;;;;;;;;;
:i**++*********+#znxxxxxnzzz##########+################++++*************************+++++##########+++++++++++++#####zznnxxnnz#+*iii;;;;;;;;;;;;;;;;;;
:i**++********++#znxxnnzzz########+++++++###############++++************************+++++++++##+#++++++++++++++++++####zznnnnz##+*iii;;;;;;;;;;;;;;;;i
:i**++********++#znnnzz##########+++++++###+#############++++***********************+++++++++++++++++++++++++++++++++#####zzzzz#+**iii;;;;;;;;;;;;;;ii
:i**++********++##zzz######++####+++++####+###############+++******iiiiiii**********++++++++++++++++++++++++++++++++++++####zz##+**iiii;;;;;;;;;;;;;ii
:i**++********++#######+##+###############################+++******iiiiiiiiii*******++++++++++++++++++#########++++++++++++#####+**iiii;;;;;;;;;;;;;i*
:i**++********++++++++++###########################zzzzz###+++****iiiiiiiiiiii*****+++++++++##########################+++++++++++**iiii;;;;;;;;;;;;;i*
:i**++********+++++++++######zzzzzzzz##############zzzzzz###++****iiiiiiiiiiiii****++++++################################+++++++***iiii;;;;;;;;;;;;;i*
:i***+***********++++++####zzzzzzz###+++++++#####zzzzzzzzz##++****iiiiiiiiiiiii****++++#########z######+++++##############++++++***iiii;;;;;;;;;;;;;i*
:i**++***********+++++###zzzzzzz##++********+++###zzzzzzzzz##++***iiiiiiiiiiiii****+++#####zzzzzzz##+++******+++#####zzz###+++++****iiii;;;;;;;;;;;;i*
:i**++***********+++++###zzzz#++*i;;::::::::;ii**+##zznzzzzz#++***iiiiii;;iiiii***++++###zzzzzz##+**i;;;::::::;;ii*++###z###++++****iiii;;;;;;;;;;;;i*
:i**++***********++++###zzzz#+i;:,,.........,,::;i*+#zznnnzz##++**iii;;;;;;iiii***+++###zzzzzz#+*i;::,.........,,,:;i*+##zz###+++***iiii;;;;;;;;;;;;i*
:i**++***********++++###z##+i;:,.````````````...,:;i*+#znnnzz##+**iii;;;;;;iiii***++###zzzzzz#+i;:,..````````````..,::i*+##z###+++***iiii;;;;;;;;;;;i*
:i**+************++++####+*i:,.```     ``````````.,:;*+#znnnzz#+**iii;;;;;;;iii***++##zznnzz#*i;,.````       ````````.,;i*+####+++***iiii;;;;;;;;;;;i*
:i****************+++###+*i:,.``      ```......````.,;i*#zznzz#+**iii;;;;;;;iii***++##zznzz#*i:,.```      ````...``````,:;*+####++***iiii;;;;;;;;;;;i*
:i****************++++++*i:,.``      ``.,:;;::.``` `.,:i*#zzzz#++*iii;;;;;;;iii***++##zzzz#*;:,..```    ```.,:::,,.`   `.,;i+####++***iii;;;;;;;;;;;i*
:i*****************++++*i;,.```    ``.,;*+##+i:.`` ``.,:i*#zzz##+*ii;;;;;;;;;iii**++##zzz#*i;,..````   ``.:;**++i;,.`   `.,;i+###++***iii;;;;;;;;;;;i*
:i******************++*i;:,.```   ``.:i+znxxz+i,.````..,;i*##z##+*ii;;;;;;;;;iii***+##zz#+i;:,.````` ``.,;i+znnn#*;,``   `.,;*+###++**iiii;;;;;;;;;;i*
:i*********************i;,..```  ``,:*#nxMWMn#*:,...,,,::;i+####+*ii;;;;;;;;;iii***++#z##*i:,,.```` ``.,i+#nxMMxz+;,.`````.,:;*+#+++**iiii;;;;;;;;;;i*
:i**+*****************i;:,.```` ``.:i#nxWWWMxz+i;:::;;;::;;*+###+*ii;;::::;;;;iii**++###+*;:,,..``` `.,i+zxMWWWMn#*:,...,,,,,;i+++++**iiii;;;;;;;;;;i*
:i********************i;:,..`````.,i+nMWWWWWMxz++**++**;;;;i+##++*i;;::::::;;;iiii*++##++i;:,,..````.,;+zxMWWWWMxz#*i;;ii;::,:;*++++*iiii;;;;;;;;;;;i*
:i**************i****ii;:,..````.,:*zxWWWWWWMMxnzzzzz#+*;;;i*+++*ii;;::::::;;;;iii**++++*i;:,,...```.:*#xMWWWWWMMxnz#++##*i;,,;i*++**iiii;;;;;;;;;;;i*
:;*************iiiiiiii;:,....``.,i#nMWWWWWWWMMMMxxxxnz+i;;;*+++*ii;;::::::;;;;;iii**+++*i;:,,,..``.,;+zxMWWWWWWMMxxxxnxnz+i:,:;*+++*iiii;;;;;;;;;;;i*
:i************iiiiiiiii;:,,,....,:i#nMWWWWWWWWWWWWMWMxn#i;;;i*++*i;;::,,,:::;;;;iii**+++*i;::,,..`..:i#nMWWWWWMWMMMMMMMMMn#i:,:;*++**iiii;;;;;;;;;;;i*
:i***********iiiiiiiii;;::,,,...,;*zxMWWWWWWWWWWWWWWMxn#*i;;i*+**i;::,,,,,::;;;;iii**+++*i;;::,,...,:*#nMWWWWMMWWWWWWWWMMn#*:,:;i****iii;;;;;;;;;;;;i*
:i***********iiiiiiiiii;;::,,,,,:;*zxM@@WWWWWWWWWWWWWxn#*i;ii***i;;::,,,,,:::;;;;iii**++*ii;:::,,.,,;*#xMWWWWWWWWWWWWWWWWxz*:,:;i****iii;;;;;;;;;;;;i*
:i***********iiiiiiiiii;;:::,,,,:;*zxW@@@@@@@@@@@@@@Wxn#*i;ii***i;::,,,,,,:::;;;;;iii*+***i;;::,,,,,;*zxWW@@@WWWWWWW@@@WWxz*:,::i****iii;;;;;;;;;;;;i*
:i***********iii;;;iiiii;;;:::,,:;*#nW@@##@@@@@@@@@@Wxz+ii;ii***i;::,,,,,,,:::;;;;iii*****ii;;::,,,:;*#xWW@@@@@@@@@@@@@@Wx#*:,::i***iiii;;;;;;;;;;;;i*
:i**********iiii;;;;iiii;;;;::::::i#nM@@###########@Wxz+iiiii**i;;:,,....,,::::;;;;ii***+**ii;;::,,:;*#nW@#####@@@@###@@Wn#i:,:;i***iiii;;;;;;;;;;;;i*
:i**********iiii;;;;;iiiii;;;;:::;i+#xW@#########@@Wxz#*iiiii*ii;::,,....,,::::;;;;;ii**+***ii;;::::;i+zMW@###########@WMz+;:,:;i***iiii;;;;;;;;;;;;i*
:i**********iii;;;;;;;;;iiii;;;;;;;i+zxM@@######@@Wxn#*iiiiiiii;;::,,.....,:::::::;;;ii*+****ii;;;:::;*#nMW@@########@WMn+i;::;;i*iiii;;;;;;;;;;;;;;i*
:i**********iii;;;;;;;;;iiiiii;;;;;i*+znMWW@@@@@WMxz#+iiiiiiiii;;::,......,:::::::::;;i******iiii;;;;;i+#nMW@@@#####@WMn#*;:::;iiiiii;;;;;;;;;;;;;;;i*
:i**********iii;;;;::;;;iiiiiiii;;;;i*+#zxMMMWMMxnz+*iiiiii*ii;;::,,..`...,::::::::::;;i***+***iii;;;;;i+#nxMWW@@@@WMxn#*;:::;iiiiii;;;;;;;;;;;;;;;;i*
:i**********iii;;;::::;;iiiiiiiiiiiiiii*+#zzznzz#+**iiiiii*ii;;;::,,.....,,:::::::::::;;i**++**iiiiii;;ii*+#nxxxMxxnz#+i;:::;;;iiii;;;;;;;:;:;;;;;;;i*
:i**********iii;;;::::;;;iiiiiiiiiiiiiiii***++***iii;iiii*iii;;::,,......,,,::::::::,::;ii**++****iiii;;iii*+#zzzz#+*i;:::::;;;ii;;;;;;;;;::;;;;;;;;i*
:i**********iii;;;:::::;;;iiiii*iiiiiiiiiiiiiiiiiiiiiiii**ii;;;::,,......,,,:::::::,,,,:;;i********iiiiiiiii****+**ii;;:::;;;;;;;;;;;;;;::::::;;;;;;i*
:i*********iiii;;;:::::::;;iiii******iiiiiiiiiiiiiiiiiiiiii;;;::,,,......,,,:::::::,,,,,:;;i***********iiiiiiiiii;;;;:::;;;;;;;;;;;;;;;;::::::;;::;;i*
:i*********iiii;;;::::::::;;;iiiiii******iiiiiiiiiiiiiiiiii;;::,,,.......,,,:::::::,,,,,::;;ii***********iiiiiii;;;;;;::;;;;;;;;;:::::::::::::::::;;i*
:i*********iiii;;;;::::::::;;;;iii*****iiiiiiiiiii;iiiiiii;;:::,,,......,,,,:::::::,,,,,:::;;;iii*********iiiiiii;;;;;;;;;::::::::::::::::::::::::;;i*
:i*********iiii;;;;:::::::::::;;;;;iiiiiiiiiii;;;;;iiiiii;;;::,,,,......,,,,,:::::,,,,,,:::::;;;;iiiiiiiiiiiiiii;;;;;;;:::::::::::::::::::::::::::;;i*
:i**********iiii;;;:::::::::::::;;;;;;;;;;;;;;;;;;;iiiii;;;::,,,,,......,,,,,::::::,,,:::::::::;;;;;;iiiiiiiii;;;;;;::::::::::::,,::::::::::::::::;;i*
:i**********iiii;;;;:::::::::::::::;;;;;;;;;;;;;;;iiiii;;;::,,,,,........,,,,,:::::,,,::::;;:::::::;;;;;;;;;;;;;:::::::::::,,,,,,,,::::::::::::::::;i*
:i**********iiii;;;;::::::::::::::::::::::::;;;;;;iiii;;;:::,,,,,.........,,,,,::::,::::;;;;;:::::::::::::::::::::::::::,,,,,,,,,,,,:::::::::::::::;i*
:i**********iiii;;;;;::::::::::::::::::::::;;;;;;;iiii;;;:::,,,,..........,,,,,,,,,,::::;;;;;;:::::::::::::::::::::::,,,,,,,,,,,,,,,:::::::::::::::;i*
:i***********iiii;;;;;::::::::::::::::::::;;;;;iiiiiii;;::::,,,,....````...,,,,,,,,,:::;;;ii;;;::::::::::::::::::::,,,,,,,,,,,,,,,,,:::::::::::::::;i*
:i***********iiiii;;;;;;::::::::::::::::::;;;;iiiiiiii;;;::::,,,,..........,,,,,,,,,::;;iiiii;;;;;:::::::::::,,,,,,,,,,,,,,,,,,,,,,::::::::::::::::;i*
:i***********iiiiii;;;;;;;:::::::::::::::;;;;iii***iiii;;;;::::,,,,......,,,,,,,.,,,::;;ii***ii;;;;;:::::::::::,,,,,,,,,,,,,,,,,,,,::::::::::::::::;i*
:i*************iiii;;;;;;;;;::::::::::;;;;;iii******iii;;;;;:::,,,,.....,,,,,,,,,,,,::;;i*****iii;;;;::::::::::::,,,,,,,,,,,,,,,,,,,:::::::::::::::;;i
:;**************iiiii;;;;;;;;;;;;;;;;;;;;iii****+*****iii;;;;::::,,,,,,,,,,,,,,,,,,:::;;i*++++**ii;;;;;::::::::::::::,,,,,,,,,,,,,,::::::::::::::::;;i
:i******++*******iiiiiiii;;;;;;;;;;;;;;iiii**+++++++***iii;;;;:::::,,,,,:::::::::::::;;ii*+##++**iii;;;;;;;;::::::::::,,,,,,,,,,,::::::::::::::::::;;*
:i***+++++++++*****iiiiiiiiiiiiii;;iiiiii**+++####+++***iii;;;;;;:::::::::::::::::::;;;i*+#####+**iii;;;;;;;;;:::::::::::::,,,,::::::::::::;::;;::;;i*
:i**++++++++++++*******iiiiiiiiiiiiiiiii**++###z####++****iiii;;;;;;:::;:;;;;;;;;;;;;;ii*+#zzz##+***iiiii;;;;;;;;:::::::::::::::::::::::::;;;;;;;:;;i*
:i**++++++###++++++********iiiiiiii******++##zzzzzz###++****iiii;;;;;;;;;;;;;;;;;;iiiii*+##zznzz#++***iiiiii;;;;;;;;;::::::::::::::::::;;;;;;;;;;;;;i*
:i**+++++########+++++***************++++##zzznnnzzz###+++*****iiiii;;;iiiiiiiiiiiiii**++#znnnnz##+++***iiiiiiii;;;;;;;;;;;;:::;;;;;;;;;;;;;;;;;;;;;i*
:i**++++############+++++++++++++++++++###zznnnnnnnzzzz##++++******iiiiiiiiiii******+++##zznnnnnzz##+++****iiiiiiii;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;i*
:i**++++###zzzzz##########++++++++#####zzznnxxxxxxnnnnzzz####+++++*************+++++##zzzzznnxxxnzz###+++*******iiiiiiiiii;;;;;;;;;;;;;;;;;;;;;;;;;;i*
:i**+++####zzzzzzzzzzz##############zzzznnxxxxxxxxxnnnnnzzzz######+++++++++++++####zzzznnnzznnnxxnnzz###+++++*********iiiiiiiii;;;;;;;;;;;;;;;;;;;;;i*
:i**+++####zzzzzzzzzzzzzzzzzzzzzzzzznnnnxxxxMMxxxxxxxxxnnnnnzzzzzzz###########zzzznnnnnnnzzzznnxxxnnnzzz####++++++*********iiiiiiiiiiiiii;;i;;;;;;;;i*
:i**+++####zzznnnnnnnnnnnnnnnnnnnnnnnxxxxxMMMMxxnnxxxxxxxxxxnnnnnnnnnzzzzzznnnnnnxxxxnnzz#+##znnxxxnnnnzzzzz####++++++++*******iiiiiiiiiiiiii;;;;;;;i*
:i**++++###zznnnnnnnnnnnnnnnnnnnnxxxxxxxMMMMMxxnnnnnxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxnz##+**+#zznxxxxxnnnnnzzzz#######++++++*******iiiiiiiiiiii;;;;;i*
:i**++++###zznnnnnnnnnnnxxxxxxxxxxxxxMMMMMMxxnnnzznnnnxxxxxxxxMMMMMMMMMMxxxxxxxxxxnnz##+******+#znxxxxxxnnnnnnzzzzzzz#####++++++*********iiiiiii;;;;i*
:i**++++###zznnnnnxxxxxxxxxxxxxxxxMMMMMMMMxxnnzzzzzznnnnxxxxMMMMMMMMMMMMMMxxxnnnnzz##++**iiii**+#znxxxxxxxxxnnnnnnnzzzzzzz####++++++*******iiiiii;;;i*
:i**++++###zznnnnxxxxxxxxxxxxxMMMMMMMMMMMxxnnzzzzzzzzzzznnnnxxxxxMMMMMMxxxnnnzz###++***iiiiiii**+##znxxxxxxxxxxnnnnnnnnzzzzzz####++++********iiii;;;i*
:i**++++###zzznnnxxxxxxxxxMMMMMMMMMMMMMMxxnnzzzzzzz#zzzzzzzznnnnxxxxxxxnnnzz##+++***iiiiiiiiii***++#znxxxMMMxxxxxxxxnnnnnnnnzzzz###++++******iiiiii;i*
:i**++++++##zznnnnxxxxxxMMMMMMMMMMMMMMMxxnzzzzzzzz#########zzzzzznnnnnzzz##+++**iiiii;;;;;;iii**+++##zznxxMMMMMxxxxxxxxxnnnnnnzzzz###+++******iiiiiii*
:i**++++++###zzznnnxxxxxxxMMMMMMMMMMxxxnnnzzzzzzzz######################+++**iiii;;;;;;;;iii***++++++##znxxMMMMMMMxxxxxxxxnnnnnzzzz###+++******iiiiii*
:i**+++++++###zzznnnnxxxxxxxxxxxxxxxnnnnnzzz###############++++++++++++++**iiiii;;;;;iiiiii***++++++++##znnxxMMMMMMMMxxxxxxxnnnnnzzz###+++*****iiiiii*
:i**+**+++++####zzznnnnnnnnnnnnnnnnnznzzzzzz################++++++++++*****iiiiiiiiiiiiii*****+++++++++##znnxxxMMMMMMxxxxxxxxnnnnzzz###+++*****iiiiii*
:i******+++++#####zzzzzznnnnnnzzzzzzzzzzzzzzz######+++########+++++++++****************************++++++#zznnnxxxxxxxxxxxxxxnnnzzzz###+++*****iiiiii*
:i*******+++++######zzzzzzzzzzzzzzzzzzzzzzzzz#####++++++#######++++++++++++*************iiiiiiii****+++++###zznnnnxxxxxxxxxnnnnzzz####++++*****iiiiii*
:i********+++++++######################zzzzzzzzz###++*****++++++++++++++++++******iiiiiiiiiiiiii******++++###zzzznnnnnnnnnnnnzzzz###+++++******iiiiii*
:i**********+++++++#####################zzzzzzzzzz#++*iiii**********+++*******iiii;;;;;;;iiiiiii*******+++++####zzzzzzzzzzzzzz######+++********iiiiii*
:i**********++++++++++###################zzzzzzzzzz#++*ii;;;;;;;iii;;iii;;;;;;:::::::;;;iiiiiiiii********++++####################+++++********iiiiii**
:i************++++++++++++++++++++++#####zzzzzzzzzzz##+*i;;;;;;;;:::::::::::::,:::::;;iiii***iiii*********+++++++##########+++++++++*********iiiiiiii*
:i***************+++++++++++++++++++++####zzzzzzzzzzzz#+*ii;;;:::::::::,,,,,,,:::;;ii********iii************+++++++++++++++++++++++**********iiiiiiii*
:i****************+++++++++++++++++++++####zzzzzzzzzzzz##+**ii;;;;;::::::::::;;ii***+++++***iiii*************++++++++++++++***************iiiiiiiiiii*
:i*****************************+++++++++####zzzzzzzznnnzz##++***iiiiii;;;iiii**+++####++***iiiiiiii*************+++++++********************iiiiiiiiii*
:i*******************************+++++++####zzzzzzzzzznnzzzz###+++++******++++########++**iiiiiiiiiii**************************************iiiiiiiiii*
:i*********************************++++++####zzz####zzznnnnnnnzzzzzz#####zzzzzzzzzz##+***iiiiiiiiiiiiiii*********************************iiiiiiiiiiii*
:i***********************************++++#####z#######zzznnnnnnnnnnnnnnnnnnnnnzzz##++**iiii;;;;iiiiiiiiii********************************iiiiiiiiiiii*
:i***********************************++++#####z#########zzznnnnnnxxxxxxxxnnnnzz##++**iiii;;;;;;;iiiiiiiiiii*********************i****i*i*iiiiiiiiiiii*
:i*****ii*****************************+++#####z####++######zzzznnnnnnnnnnnzzz##++**ii;;;;;;;;;;;iiiiiiiiiii***********************iii*iiiiiiiiiiiiii**
:i*****ii*****************************++++####zz###++++++++#####zzzzzzzzz###++**iiii;;;;;;;;;;;;iiiiiiiiiii**********************iiiiiiiiiiiiiiiiiii**
:i*****iii*****************************+++####zz###+++++++++++++########++++***iii;;;;;;;;;;;;;;iiiiiiiiiiiii***************iiiiiiiiiiiiiiiiiiiiiiii**
:i****iiiiii***i***********************++++###zz###++++********+++++++++****iiii;;;;;;;;;;;;;;;;iiiiiiiiiiiiiiii**i**i**i****iiiiiiiiiiiiiiiiiiiiiii**
:i*****iiiiiiiiii***********************+++###zzz##+++********************iiiii;;;;;;;;;;;;;;;;;iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii;i**
:i****iiiiiiiiiiiiiiiiiiii**************+++###zzz##+++*****iiiiiiiiiiiiiiiiii;;;;;;;;::;;;;;;;;iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii;ii*
:i****iiiiiiiiiiiiiiiiiiii***************++###zzzz#+++***iiiiiiiiiiiiiiiiii;;;;;;;;;;;;;;;;;;;;iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii;ii*
:i***iiiiiiiiiiiiiiiiiiiiiii*************+++##zzzz##++**iiiiiiiiiiiiiiii;;;;;;;;;;;;;;;;;;;;;;iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii;ii*
:i***iiiiiiiiiiiiiiiiiiiiiiiii***********+++##zzzzz#++***iiiiiiiiii;;;;;;;;;;;;;;;;;;;;;;;;;;iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii;ii*
:i***iiiiiiiiiiiiiiiiiiiiiiiii************+++##zzzz##++**iiiiii;;;;;;;;;;;;;;;;;;;;;;;;;;;;iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii;ii*
;i***iiiiiiiiiiiiiiiiiiiiiiiiiii**********+++##zzzzz#++**iiiiii;;;;;;;;;;;;;;;;;;;;;;;;;;;iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii;iii*
;i***iiiiiiiiiiiiiiiiiiiiiiiiii***********++++##zzzz##++***iiii;;;;;;;;;;;;;;;;;;;;;;;;;;iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii;;;ii*
;i***iiiiiiiiiiiiiiiiiiiiiiiiiii**i********+++##zzzzz##++**iiiiiii;;;;;;;;;;;;;;;;;;;;iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii;;;;i*
;i***iiiiiiiiiiiiiiiiiiiiiiiiiii**i********+++##zzzzzz##++***iiiiiiiiiii;;;;;;;;;i;iiiiiiii**i**iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii;;;;i*
;i*i*iiiiiiiiiiiiiiiiiiiiiiiiiiiiii*********+++##znnnzz##+++***iiiiiiiiiiiiiiiiiiiiiiiii**********iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii;;;;i*
;i***iiiiiiiiiiiiiiiiiiiiiiiiiiiiii**********++##zznnnzz###++******iiiiiiiiiiiiiiiiii*************iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii;;;;i*
;i***iiiiiiiiiiiiiiiiiiiiiiiiiiiiii**********+++##zznnnnzz##++++*************i**********+++********iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii;;;;i*
;i***iiiiiiiiiiiiiiiiiiiiiiiiiiiiii***********++##zznnnnnzzz###++++++*************+++++++++++******iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii;;;;i*
;i****iiiiiiiiiiiiiiiiiiiiiiiiiiiii***********+++##znnnnnnnnzzz#####++++++++++++++#########++*******iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii;;;;i*
;i****iiiiiiiiiiiiiiiiiiiiiiiii***************+++##zznnxxxnnnnzzzzzz##############zzzzzzz##+++*******iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii;;;;i*
;i*****iiiiiiiiiiiiiiiiiiiiii*****************++++##zznnxxxxxnnnnnnnnnzzzzzzzznnnnnnnnnzzz##++*******iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii;;;;i*
;********iiiiiiiiiiiiiii***********************+++###zznnxxxxxxxxxxnnnnnnnnnnnnnnnxxnnnnzz#++*********iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii;;;;i*
;**********************************************++++###zznnxxxxMMMxxxxxxxxxxxxxxxxxxxxnnnz##++***********iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii;;;;i*
;***+******************************************+++++###zznnnxxxxxMMMMMMMMMMMMMMMMxxxnnnz##+++***********iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii;;;;i*
;*+++******************************************+++++####zzznnxxxxMMMMMMMMMMMMMMxxxxnnzz##++++************iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii;;;;i+
;*+++++****************************************++++++####zzzznnnnxxxxxxxxxxxxxxxxnnzzz###+++*************iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii;;;i+
i*++++++++++++++++++++++*********************+++++++++#####zzzzznnnnnnnnxxnnnnnnnzzz###+++***********************iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii;ii*+
+###############++++++++++++++++++++++++++++++++++++++#######zzzzzzzzznnnnzzzzzzzz####++++***********************************iiiiiiiiiiiiiiiiiiiiiii*+
#zzzzzzzzzzz#####################++++++++++++++++++##############zzzzzzzzzzzz########++++++********************************************iiiiiiiiiii**+#
xxxxxxnnnnnnnnnnnnnzzzzzzzzzzzzzz###########################z#zzzzzzzzzzzz##########++++++++++++************************************************++##zz
*/
