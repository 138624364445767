import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EnterpriseSearchService } from '@sabstravtech/obtservices/angular';
import { LocationDetails } from '@sabstravtech/obtservices/base';
import { of, Observable, Subject, BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, merge, switchMap } from 'rxjs/operators';
import { FlightPipe } from '../../pipes/flight-pipe';

@Component({
  selector: 'app-search-flight-location-typeahead',
  templateUrl: './search-flight-location-typeahead.html',
  styleUrls: ['./search-flight-location-typeahead.scss']
})
export class SearchFlightLocationTypeahead implements OnInit {
  @Input() model;
  @Input() countryCode;
  @Output() modelChange = new EventEmitter<any>();
  @Output() updateElement = new EventEmitter<any>();

  public focus$ = new Subject<string>();
  searchLocationLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private searchService: EnterpriseSearchService, private flightPipe: FlightPipe) {}

  modelChanged(): void {
    this.modelChange.emit(this.model);
  }

  ngOnInit(): void {}

  formatter_rail_air = (x: LocationDetails) => x.name;

  searchLocations = (text$: Observable<string>): Observable<LocationDetails[]> =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focus$),
      switchMap(
        (term: string): Observable<LocationDetails[]> =>
          term.length <= 2 ? of([]) : this.searchService.getAirportLocations(term, this.countryCode, this.searchLocationLoading, null, null, null, true)
      )
    );
}
