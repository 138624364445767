import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Helpers } from '../../vendor/classes/helpers';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const full_months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const full_weekdays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
];

@Pipe({
    name: 'sabsDate'
})
export class SabsDatePipe implements PipeTransform {
    constructor(private translate: TranslateService) {}
    transform(value, full = 0, offset: string): any {
        if (full === 5) {

            if (offset) {
                console.log(offset);
                const numoff = Number(offset.split(':')[0]);
                // console.log(numoff)

                if (numoff) {
                    value = moment(value).add(offset, 'hours');
                }
            }

            return Helpers.bookingDateTime(value, this.translate);
        }
        if (full === 8) {
            return Helpers.bookingDateAnytime(value, this.translate);
        }

        if (full === 9) {
            return Helpers.toScratchpadDate(value);
        }

        if (value) {
            let jsDate = null;

            if (moment.isMoment(value)) {
                jsDate = value.toDate();
            } else {
                let newValue: string;
                if (value.length > 6) {
                    newValue = value.slice(2, 10).replace(/-/g, '');
                }

                const temp = newValue ? newValue.match(/.{1,2}/g) : value.match(/.{1,2}/g); // splits every 2 characters

                jsDate = new Date(Number(temp[0]) + 2000, Number(temp[1]) - 1, temp[2]);
            }
            // console.log(jsDate);

            if (full === 1) {
                return (
                    this.translate.instant(weekdays[jsDate.getDay()]) +
                    ' ' +
                    jsDate.getDate() +
                    ' ' +
                    this.translate.instant(months[jsDate.getMonth()])
                );
            }
            if (full === 2) {
                return (
                    this.translate.instant(full_weekdays[jsDate.getDay()]) +
                    ' ' +
                    jsDate.getDate() +
                    ' ' +
                    this.translate.instant(full_months[jsDate.getMonth()]) +
                    ' ' +
                    jsDate.getFullYear()
                );
            }
            if (full === 4) {
                let str = jsDate.getDate() < 10 ? '0' + jsDate.getDate() : jsDate.getDate();
                str =
                    str +
                    '' +
                    this.translate.instant(months[jsDate.getMonth()]).toUpperCase() +
                    '' +
                    jsDate.getFullYear().toString().substring(2);
                // console.log('str date');
                // console.log(str);
                return str;
            }
            if (full === 6) {
                jsDate = new Date(
                    Number(value.substring(0, 4)),
                    Number(value.substring(4, 6)) - 1,
                    Number(value.substring(6, 8))
                );
                return (
                    jsDate.getDate() +
                    ' ' +
                    this.translate.instant(months[jsDate.getMonth()]) +
                    ' ' +
                    jsDate.getFullYear()
                );
            }
            if (full === 7) {
                const day = jsDate.getDate();
                const month = jsDate.getMonth() + 1;
                const year = jsDate.getFullYear();
                return `${day}/${month}/${year}`;
            }
            if (full === 10) {
                return `${this.translate.instant(full_months[jsDate.getMonth()])} ${jsDate.getDate()}${this.translate.instant(this.nth(jsDate.getDate()))}  ${jsDate.getFullYear()}`
            }
            return (
                this.translate.instant(weekdays[jsDate.getDay()]) +
                ' ' +
                jsDate.getDate() +
                ' ' +
                this.translate.instant(months[jsDate.getMonth()]) +
                ' ' +
                jsDate.getFullYear()
            );
        }
        return '';
    }
    
    nth(d: number) {
        if (d > 3 && d < 21) return 'th';
        switch (d % 10) {
          case 1: return "st";
          case 2: return "nd";
          case 3: return "rd";
          default: return "th";
        }
      }
}
