<div cdkTrapFocus
     aria-live="assertive">
  <div class="modal-header">
    <h3 class="modal-title"
        i18n="@@modalSuccessTitle"
        id="modal-header">Success</h3>
    <button type="button"
            class="close"
            aria-label="An Error Occured Close"
            ngbAutoFocus
            i18n-aria-label
            (click)="activeModal.close('Cross click')">
      <span aria-hidden="true"><i class='ico-cancel modal_exit_cross'></i></span>
    </button>
  </div>
  <div class="modal-body">

    <p><span i18n="@@modalSuccessMessageHeader">Message</span>: <span>{{success}}</span></p>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="modal_button_okay"
            (click)="activeModal.close(true)"
            i18n="@@modalErrorOK">Ok</button>
  </div>
</div>