import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LightningModalTypes } from '../..//vendor/classes/modal-types.enum';
import { OBTRailJourneyOption } from '@sabstravtech/obtservices/base';
import { RailJourneyLeg, HelperRoutines } from '@sabstravtech/obtservices/angular'
@Component({
    templateUrl: './modal-rail-journey-details.component.html',
    styleUrls: ['./modal-rail-journey-details.component.scss']
})
export class ModalRailJourneyDetailsComponent implements OnInit {
    public result: OBTRailJourneyOption  = null;
    public segments: (RailJourneyLeg & { toggleStop: boolean })[];

    constructor(public activeModal: NgbActiveModal, private helpers: HelperRoutines) {}

    ngOnInit() {
      this.segments = this.result.journeyLegs.map(
            (journeyLeg: RailJourneyLeg) : RailJourneyLeg & { toggleStop: boolean }=> {
                return { ...journeyLeg, toggleStop: false };
            }
        );
    }
}
LightningModalTypes.ModalRailJourneyDetailsComponent.component = ModalRailJourneyDetailsComponent;
