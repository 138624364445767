<app-loading-screen
    *ngIf="searchService.search_loading"
    [animated]="animated"
    icon="ico-parking ico-material">
    <span title i18n="@@parkingsearchloading_title">Just loading your search results</span>
    <div msgOne>
        <span i18n="@@parkingsearchloading_msgOne">Parkings for</span>
        {{
          searchParams.locationCode.destination
      }} ({{ searchParams.locationCode.gateway.toUpperCase() }}) ({{
          searchParams.selectedTerminal
      }})
    </div>
    <span msgTwo i18n="parkingsearchloading_msgTwo">Checking for availability</span>
</app-loading-screen>