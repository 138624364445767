import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UserDefaultManagementInfoValue } from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-mi-default-free-text-value',
  templateUrl: './mi-default-free-text-value.component.html',
  styleUrls: ['./mi-default-free-text-value.component.scss']
})
export class MiDefaultFreeTextValueComponent implements OnInit {
  @Input() miItem: UserDefaultManagementInfoValue;
  @Input() placeholder: string;
  @Output() clickOutside = new EventEmitter();
  value: string = '';
  title: string = '';

  ngOnInit() {
    // this.title = this.miItem.managementInfo?.name;
  }

  addNewMiValue() {
    // this.miItem.managementInfo.defaultValue = {
    //   value: this.value,
    // };
    // this.miItem.managementInfo.dbId = +this.miItem.managementInfo.id;
    this.value = '';
    this.clickOutside.emit(this.miItem);
  }
}