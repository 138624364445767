import { Component, OnInit } from '@angular/core';
import { EnterpriseSearchService, ServiceType } from '@sabstravtech/obtservices/angular';
import { FlightEnterpriseSearchInterface, FlightQuoteResultArray } from '@sabstravtech/obtservices/base';
import { Title } from '@angular/platform-browser';

import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../../vendor/components/base_components/base-componet';

@Component({
  selector: 'app-flights',
  templateUrl: './flights.component.html',
  styleUrls: ['./flights.component.css']
})
export class FlightsComponent extends BaseComponent implements OnInit {
  flightsViewController: any;
  srchFromResults = false;
  searchParams: FlightEnterpriseSearchInterface;

  constructor(
    public searchService: EnterpriseSearchService,
    private titleService: Title,
    translateService:TranslateService) {
    super(titleService, translateService)
  }

  ngOnInit(): void {
    this.titleService.setTitle('Flight results - LightUk');
    this.searchParams = this.searchService.searches[ServiceType.Flight];
    // this.travelService.getSearchResults(ServiceType.Flight);
  }
}
