<div cdkTrapFocus
     aria-live="assertive">
    <div class="modal-header">
        <h3 class="modal-title"
            i18n="@@modalSearchTimeoutTitle"
            id="modal-header">Search Timeout!</h3>
        <button type="button"
                class="close"
                aria-label="Search Timeout Close"
                ngbAutoFocus
                (click)="activeModal.close(true)">
            <span aria-hidden="true"><i class='ico-cancel modal_exit_cross'></i></span>
        </button>
    </div>
    <div class="modal-body">
        <p i18n="@@modalSearchTimeout">Unfortunately not all suppliers have been able to return results.
        </p>
    </div>
    <div class="modal-footer">
        <button type="button"
                class="modal_button_okay"
                (click)="activeModal.close(true)"
                i18n="@@modalSearchErrorOK">Ok</button>
    </div>
</div>