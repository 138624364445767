import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';
import { HotelPolicyEnum, ServiceProvider, HotelRoomWithLocalPrice } from '@sabstravtech/obtservices/base';
@Component({
  selector: 'app-gmt-hotel-fare-rules-modal',
  templateUrl: './gmt-hotel-fare-rules-modal.html',
  styleUrls: ['./gmt-hotel-fare-rules-modal.scss']
})
export class GmtHotelModalFareRulesComponent implements OnInit {
  room: HotelRoomWithLocalPrice = null;
  source: string = '';
  name: string = '';
  HotelPolicyEnum: typeof HotelPolicyEnum = HotelPolicyEnum;
  listOfHotelPolicies: HotelPolicyEnum[] = Object.values(HotelPolicyEnum);
  warningSources: string[] = ['Booking.com'];
  ServiceProvider = ServiceProvider;
  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void { 
  }

  warnAboutSource(source: string): boolean {
    return this.warningSources.includes(source);
  }
}

LightningModalTypes.GmtHotelModalFareRulesComponent.component = GmtHotelModalFareRulesComponent;
