import { LanguageEnum, Language } from '../vendor/interfaces/language-types';
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';

export default class SpanishLanguage extends Language {
  protected locale = localeEs;
  protected localeExtra = localeEsExtra;
  protected details = {
    image: 'images/flags/es.png',
    title: 'Spanish',
    enum: LanguageEnum.Spanish,
    aira: 'Spanish Selected',
    select: 'Choose Spanish Language',
    abre: 'es',
  };
}

