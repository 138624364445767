import { ProductLoadingScreen } from './product-loading-screen';
import { EnterpriseSearchService,ServiceType } from '@sabstravtech/obtservices/angular'
import { FerryEnterpriseSearchInterface} from '@sabstravtech/obtservices/base'
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-ferry-loading-screen',
    templateUrl: './ferry-loading-screen.component.html',
    styleUrls: [],
  })
export class FerryLoadingScreenComponent extends ProductLoadingScreen<FerryEnterpriseSearchInterface> {
    @Input() animated: boolean = true;
    constructor(service: EnterpriseSearchService) {
        super(service, service.searches[ServiceType.Ferry]);
    }
}