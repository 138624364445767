import { Component, Input, OnInit } from '@angular/core';
import { DateService } from '../../../../../shared/services/date.service';
import { BasketItem, RailJourneyLeg, UserService } from '@sabstravtech/obtservices/angular';
import { WardDetail, ServiceProvider } from '@sabstravtech/obtservices/base';

@Component({
  selector: 'app-rail-journey-details',
  templateUrl: './rail-journey-details.component.html',
  styleUrls: ['./rail-journey-details.component.scss']
})
export class RailJourneyDetailsComponent implements OnInit {
  @Input() basketItem: BasketItem;
  @Input() darkMode = false;
  hideCallingPointTime = false;

  get railOutwardStops(): RailJourneyLeg[] {
    return this.basketItem.detail.outbound?.journeyLegs;
  }

  get railInwardStops(): [] {
    return this.basketItem.detail.return?.journeyLegs;
  }

  get railInwardSummary(): WardDetail {
    const basketInwardDetail = this.basketItem.detail.inwardDetail;
    return {
      arrivalDateTime: basketInwardDetail?.arrivalDateTime,
      arrivalStationName: basketInwardDetail?.arrivalStationName,
      departureDateTime: basketInwardDetail?.departureDateTime,
      departureStationName: basketInwardDetail?.departureStationName,
      supplierTickets: basketInwardDetail?.supplierTickets
    };
  }

  get railOutwardSummary(): WardDetail {
    const basketOutwardDetail = this.basketItem.detail.outwardDetail;
    return {
      arrivalDateTime: basketOutwardDetail?.arrivalDateTime,
      arrivalStationName: basketOutwardDetail?.arrivalStationName,
      departureDateTime: basketOutwardDetail?.departureDateTime,
      departureStationName: basketOutwardDetail?.departureStationName,
      supplierTickets: basketOutwardDetail?.supplierTickets
    };
  }

  get hasOutwardStops(): boolean {
    return !!(this.railOutwardStops && this.railOutwardSummary);
  }

  get hasInwardStops(): boolean {
    return !!(this.railInwardStops && this.railInwardSummary);
  }

  constructor(private date: DateService, private userService: UserService) {}

  totalTimeJourney(departureDateTime: string, arrivalDateTime: string): string {
    return this.date.calculateTimeDifference(departureDateTime, arrivalDateTime);
  }

  ngOnInit(): void {
    this.hideCallingPointTime =
      this.userService.findService(this.basketItem.service.id)?.name === ServiceProvider.Trainline;
  }

  getSeats(index: number, direction: 'outward' | 'inward'): string {
    return this.basketItem?.bookingDetail?.supplierResp?.book?.details?.orderItems
      ?.reduce((acc: string[], item) => {
        const line = item.lines.find(line => line.name === 'itinerary');
        const journey = line.orderJourneys.find(
          journey => journey.identifiers.direction === direction
        );
        journey.segments[index].seats?.forEach(seat => {
          const { number, coach, properties, position } = seat;
          if (number && coach) {
            acc.push(`${coach}/${number} ${properties?.join(' ') || position || ''}`);
          }
        });
        return acc;
      }, [])
      .join('-');
  }
}

