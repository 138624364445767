<section id="warning-modal">
    <div>
        <h2 i18n="@@hotelWarningTitle">Warning<a class="close"
               (click)="onCancel()"
               role="button"
               aria-label="Close Warning Hotel Modal Box">&times;</a></h2>
    </div>
    <p id="warning-body"
       i18n="@@hotelWarningRemoveMessage"><i class='ico-exclaim-1 ico-exclaim-1--mild ico-float-left'></i><b>PLEASE NOTE:</b>
        All linked hotel bookings will also be removed from the basket.</p>
    <div id="confirm-button"><button (click)="onAccept()"
                i18n="@@hotelWarningOK">OK</button></div>

</section>