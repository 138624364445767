import { LanguageEnum, Language } from '../vendor/interfaces/language-types';
import localeTl from '@angular/common/locales/fil';
import localeTlExtra from '@angular/common/locales/extra/fil';

export default class TagalogLanguage extends Language {
  protected locale = localeTl;
  protected localeExtra = localeTlExtra;
  protected details = {
    image: 'images/flags/fil.png',
    title: 'Tagalog',
    enum: LanguageEnum.Tagalog,
    aira: 'Tagalog Selected',
    select: 'Choose Tagalog Language',
    abre: 'fil',
  };
}

