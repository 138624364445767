<ng-template #iconWarn>
  <i class="ico-cancel light-color-warning"></i>
</ng-template>
<ng-template #noFeatureInfo>
  <div class="pnl-feature">
    <i class="ico-exclaim-1 light-color-info" aria-hidden="true"></i>
    <span i18n="@@pnlInformationUnavailable">Information unavailable</span>
  </div>
</ng-template>
<ng-container
  *ngIf="{
    results: searchService.searches[ServiceType.Parking].results | async,
    isvalid: searchService.searches[ServiceType.Parking].isValid | async,
    messages: searchService.searches[ServiceType.Parking].messages | async,
    listViewToggle: searchService.searches[ServiceType.Parking].listViewToggle | async
  } as data">
  <h2 class="search_content_titles" i18n="@@parking_searchCriteria">Search Criteria</h2>
  <div class="background-30">
    <app-validate-errors [messages]="data.messages"></app-validate-errors>
    <a href="#parking" (click)="skipToResultContent()" class="sr-only sr-only-focusable"
      >Skip to Result Content</a
    >
    <app-invoked-user></app-invoked-user>
    <app-search-toggle>
      <app-parking-search></app-parking-search>
      <div class="flex-row-container flex-row-container--right">
        <button
          type="button"
          (click)="performSearch()"
          [disabled]="!data.isvalid"
          class="large-button large-button--search"
          type="button"
          i18n="@@parking_search">
          Search
        </button>
      </div>
    </app-search-toggle>
    <app-risk-alerts
      *ngIf="!searchService.search_loading"
      [countryCode]="
        searchService.searches[ServiceType.Parking].locationCode?.countryCode
      "></app-risk-alerts>
  </div>


  <button
    class="large-button"
    *ngIf="flightSearchParams.parkingFromFlightSearchPerformed"
    routerLink="/results/flights"
    id="parking-button-back-to-flights">
    Back to Flights
  </button>

  <div id="parking" class="pnl-results-container">
    <app-no-results
      *ngIf="data.results && data.results.length === 0"
      [type]="searchType"></app-no-results>
    <ng-container [ngSwitch]="data.listViewToggle">
      <div *ngSwitchCase="null">
        <app-parking-list-view [canOverride]="canOverride" [data]="data"></app-parking-list-view>
      </div>
      <div *ngSwitchDefault>
        <app-parking-map-view [mapData]="data.listViewToggle"> </app-parking-map-view>
      </div>
    </ng-container>
  </div>
</ng-container>
<app-parking-loading-screen [animated]="false"></app-parking-loading-screen>
