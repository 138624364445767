import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  RailEnterpriseSearchInterface,
  RailSearchCriteria,
  OBTRailQuoteResult,
  FlightEnterpriseSearchInterface,
  ServiceProvider
} from '@sabstravtech/obtservices/base';
import {
  EnterpriseSearchService,
  RailSearchJourneyType,
  ServiceType,
  RailJourneyFare,
  RailJourneyOptions,
  HelperRoutines,
  UserService
} from '@sabstravtech/obtservices/angular';
import { flatten } from 'lodash';
@Component({
  selector: 'app-trainline-eu-results',
  templateUrl: './trainline-eu-results.component.html',
  styleUrls: ['./trainline-eu-results.component.scss']
})
export class TrainlineEuResultsComponent implements OnInit {
  searchParams: RailEnterpriseSearchInterface;
  RailJourneyType = RailSearchJourneyType;
  RailSearchCriteria = RailSearchCriteria;
  hideNonSelectedTickets = false;
  inboundResults: OBTRailQuoteResult[];
  loadingInbound: boolean = false;
  flightSearchParams: FlightEnterpriseSearchInterface;
  index: number;
  clearOutbound = false;
  constructor(
    private searchService: EnterpriseSearchService,
    private helpers: HelperRoutines,
    private router: Router,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.searchParams = this.searchService.searches[ServiceType.Rail];
    this.flightSearchParams = this.searchService.searches[ServiceType.Flight];
    this.index = this.userService.userHasServiceProvider(ServiceType.Rail, ServiceProvider.Evolvi) ? 1 : 0;
  }

  reSearch(): void {
    this.searchParams.selectedTicket = null;
    localStorage.setItem('prevURL', window.location.href); // prevent going back to wellbeing which will no longer have correct results
    if (
      !this.searchService.search_objects ||
      !this.searchService.search_objects[ServiceType.Rail]
    ) {
      this.searchService.search_objects = this.searchService.init_search_objects();
    }
    this.searchService.search_objects[ServiceType.Rail].chosen = true;
    this.searchService.determineHighestSearchPriority();
    this.searchService.startSearches();
  }

  async getInboundSearchResults(
    chosenJourney: { journeyHash: string; chosenFare: RailJourneyFare[] },
    outboundData: RailJourneyOptions
  ): Promise<void> {
    this.inboundResults = null;

    if (chosenJourney?.chosenFare[0]) {
      const journeyFareTypes = [
        'openReturnJourneyFares',
        'dualSingleJourneyFares',
        'returnJourneyFares',
        'singleJourneyFares',
        'splitFares'
      ];

      const outboundResults = this.helpers.clone(outboundData);
      outboundResults.outbound = outboundResults.outbound
        .filter(x => x.journeyHash === chosenJourney.journeyHash)
        .map(outbound => {
          journeyFareTypes.forEach(fare => {
            delete outbound[fare];
          });

          outbound['fares'] = chosenJourney.chosenFare;

          return outbound;
        });
        const multiFares = chosenJourney.chosenFare.map(fare => fare.identifiers.fareIdentifier);
      this.loadingInbound = true;
      this.inboundResults = await this.searchService
        .getInwardRailResults(
          outboundResults,
          chosenJourney.chosenFare[0].identifiers.journeyIdentifier,
          multiFares
        )
        .toPromise();

      // Parse inbound fares again after processing rules
      this.inboundResults = this.inboundResults.map(results => {
        results.return = results.return.map(returnResult => {
          journeyFareTypes.forEach(fare => {
            returnResult[fare] = flatten(
              Object.keys(returnResult[fare]).map(fareName => returnResult[fare][fareName])
            ).filter(x => x);

            if (!returnResult[fare]?.length) {
              delete returnResult[fare];
            }
          });
          return returnResult;
        });

        return results;
      });

      this.loadingInbound = false;
    }
    this.clearOutbound = chosenJourney === null;
  }

  switchToFlight() {
    this.searchService.search_objects[ServiceType.Rail].chosen = false;
    this.router.navigate([this.searchService.search_objects[ServiceType.Flight].resultUrl]);
  }
}

