import { Pipe, PipeTransform } from '@angular/core';
import * as railStationAddresses from '../data/rail_addresses.json';

@Pipe({
  name: 'railstationAddress',
})
export class RailstationAddressPipe implements PipeTransform {
  railStationAddresses = railStationAddresses
  transform(value: any, args?: any): any {
    const test = this.railStationAddresses;

    for (let i = 0; i < test.length; i++) {
      if (test[i].code === value) {
        return test[i].Address;
      }
    }

    return null;
  }
}
