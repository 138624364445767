<div cdkTrapFocus
     aria-live="assertive">
    <div class="modal-header">
        <h3 class="modal-title"
            id="modal-header">Item added to cart</h3>
        <button type="button"
                class="close"
                aria-label="Item added to basket Close"
                ngbAutoFocus
                (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true"><i class='ico-cancel modal_exit_cross'></i></span>
        </button>
    </div>
    <div class="modal-body">
        <div class="safe-html">An item has been added to your cart.</div>
    </div>
    <div class="modal-footer">
        <button type="button"
                class="small-button small-button--no"
                (click)="continue()">Continue Browsing Results</button>
        <button type="button"
                id='button_ok'
                class="small-button"
                (click)="skipToBasket($event)">Continue To Basket</button>
    </div>
</div>