<div class="chosen-rail-container">
  <div class="main-details-container">
    <!-- Details Container -->
    <div>
      <h4 class="title"><i class="ico-rail"></i>Outbound</h4>
      <p class="details">
        <b>{{ chosenOutboundRail.departDateTime | sabsDate }}</b>
      </p>
      <p class="details">
        <b>{{ chosenOutboundRail.from }}</b> to <b>{{ chosenOutboundRail.to }}</b>
      </p>
      <p class="details">
        <span
          >Depart:
          <b>{{ chosenOutboundRail.departDateTime | sabsTimePipe : 'timeString' }}</b></span
        >
        &nbsp;
        <span
          >Arrive:
          <b>{{ chosenOutboundRail.arriveDateTime | sabsTimePipe : 'timeString' }}</b></span
        >
      </p>
      <a (click)="openDetailsModal(chosenOutboundRail)">Outbound Journey Details</a>
    </div>
  </div>
  <div class="main-details-container">
    <div>
      <h4 class="title"><i class="ico-rail"></i>Return</h4>
      <p class="details">
        <b>{{ chosenReturnRail.departDateTime | sabsDate }}</b>
      </p>
      <p class="details">
        <b>{{ chosenReturnRail.from }}</b> to <b>{{ chosenReturnRail.to }}</b>
      </p>
      <p class="details">
        <span
          >Depart: <b>{{ chosenReturnRail.departDateTime | sabsTimePipe : 'timeString' }}</b></span
        >
        &nbsp;
        <span
          >Arrive: <b>{{ chosenReturnRail.arriveDateTime | sabsTimePipe : 'timeString' }}</b></span
        >
      </p>
      <a (click)="openDetailsModal(chosenReturnRail)">Return Journey Details</a>
    </div>
  </div>
  <div class="booking-controls">
    <div class="flex-column-container">
      <h4 class="title">Summary</h4>
      <p class="details">
        <span i18n="@@wellbeingTicket">Ticket</span>:
        <span *ngIf="!dualSingle">
          <b>{{ chosenTicket.type }}</b>
        </span>
        <span *ngIf="dualSingle">
          <b
            >{{ outboundCheapest.dualSingleJourneyFares.fare.type }} /
            {{ inboundCheapest.dualSingleJourneyFares.fare.type }}</b
          >
        </span>
      </p>
      <p class="details">
        <span i18n="@@wellbeingPrice">Price</span>:
        <span *ngIf="!dualSingle">
          <b>{{ chosenTicket.price | currency : 'GBP' }}</b>
        </span>
        <span *ngIf="dualSingle">
          <b>{{
            outboundCheapest.dualSingleJourneyFares.fare.price +
              inboundCheapest.dualSingleJourneyFares.fare.price | currency : 'GBP'
          }}</b>
        </span>
      </p>
      <div class="flex-row-container">
        <a class="wellbeing_fare_rules" (click)="openTicketTermsModal(chosenTicket)">Fare Rules</a>
        <button
          (click)="addToBasket()"
          class="small-button"
          i18n="@@wellbeingAddToBasket"
          *ngIf="!displayItinerary">
          Add To Basket
        </button>
        <button
          (click)="addToBasket()"
          class="small-button"
          i18n="@@wellbeingAddToItinerary"
          *ngIf="displayItinerary">
          Add To Itinerary
        </button>
      </div>
    </div>
  </div>
</div>
