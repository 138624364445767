import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'app-search-toggle',
  templateUrl: './search-toggle.component.html',
  styleUrls: ['./search-toggle.component.scss']
})
export class SearchToggleComponent {
  @Input() text = 'Search Form';
  @Input() toggleState = false;
  @Output() newToggleState: EventEmitter<boolean> = new EventEmitter();

  toggle(): void {
    this.toggleState = !this.toggleState;
    this.newToggleState.emit(this.toggleState);
  }
}
