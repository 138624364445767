<div cdkTrapFocus
     aria-live="assertive">
    <div class="modal-header">
        <h3 class="modal-title"
            i18n="@@modalUserErrorTitleHotelBooking"
            id="modal-header">Hotel Booking</h3>
        <button type="button"
                class="close"
                aria-label="Hotel Booking Close"
                ngbAutoFocus
                (click)="activeModal.close(true)">
            <span aria-hidden="true"><i class='ico-cancel modal_exit_cross'></i></span>
        </button>
    </div>
    <div class="modal-body">
        <p><span i18n="@@SuggestHotelBooking1">You have selected a return trip to</span><strong> {{ location }}
            </strong><span i18n="@@SuggesthotelBooking2">and will be staying for</span>
            <strong> {{ nights }} </strong> <span i18n="@@SuggestHotelBooking">{nights, plural, =1 {night} other
                {nights}}.</span>
        </p>
        <p i18n="@@SuggestHotelBookingAsk">Would you like to Book a Hotel?</p>

    </div>
    <div class="modal-footer">

        <button type="button"
                class="modal_button_okay"
                (click)="reject()"
                id="hotel-check-no"
                i18n="@@modalUserErrorNoThanks">No Thanks</button>

        <button type="button"
                class="modal_button_okay"
                (click)="accept()"
                id="hotel-check-yes"
                i18n="@@modalUserErrorOK">Ok</button>
    </div>
</div>