<ng-template #railairtemp
             let-r="result"
             let-t="term">
    {{ r.name|flightFormat}} ({{r.airport?.gateway}})
</ng-template>
<div>
    <i class='ico-flight combo-box-icon' aria-hidden="true"></i>
    <input autocomplete="off"
           i18n-placeholder
           placeholder='Airport'
           id="hotel-input-airport"
           onClick='this.select()'
           type="text"
           class="input-box"
           [(ngModel)]="model"
           (ngModelChange)="modelChanged()"
           [ngbTypeahead]="searchLocations"
           [resultTemplate]="railairtemp"
           [inputFormatter]="formatter_rail_air"
           (focus)="focus$.next($event.target.value);"
           aria-labelledby="airport-hotel-search"
           (keyup.arrowdown)="updateElement.emit($event)"
           (keyup.arrowup)="updateElement.emit($event)" />
    <i *ngIf='searchLocationLoading | async'
       class='button_spinner input-loading-spinner-left'></i>
    <span id="airport-hotel-search"
          hidden
          i18n="@@AriaLocationSearchAirport">Select an airport</span>
</div>