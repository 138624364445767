<div [ngSwitch]="fareTitle">
     <div *ngSwitchCase="ermFareTypes.MarineFare">
          <div 
          i18n-ngbTooltip
          rel="icon" type="image/x-icon" 
          ngbTooltip="Marine fare" 
          class="fare-icon ship-marine-icon">
          </div>
     </div>
     <div *ngSwitchCase="ermFareTypes.OffshoreFare">
          <div
          i18n-ngbTooltip
          rel="icon" type="image/x-icon" 
          ngbTooltip="Offshore Fare" 
          class="fare-icon rig-offshore-icon">
          </div>
     </div>
     <div *ngSwitchCase="ermFareTypes.AirFare">
          <div
          i18n-ngbTooltip
          rel="icon" type="image/x-icon" 
          ngbTooltip="Air Fare" 
          class="fare-icon renewable-energy-icon">
          </div>
     </div>
     <div *ngSwitchCase="ermFareTypes.SeaFare">
          <div
          i18n-ngbTooltip
          rel="icon" type="image/x-icon" 
          ngbTooltip="Sea Fare" 
          class="fare-icon renewable-energy-icon">
          </div>
     </div>

     <div *ngSwitchDefault>
          <section (mouseleave)="tt5.close()">
               <i class="ico-star {{getSpecialFareCode()}}"
                  ngbTooltip="Company Negotiated Rate"
                  i18n-ngbTooltip
                  triggers="manual"
                  #tt5="ngbTooltip"
                  (mouseenter)="tt5.open()"
                  (focus)="tt5.open()"
                  (blur)="tt5.close()"
                  tabindex="0"></i>
         </section>
     </div>
</div>


