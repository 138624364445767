import { Component } from '@angular/core';

@Component({
  selector: 'app-change-user',
  templateUrl: './change-user.component.html',
  styleUrls: ['./change-user.component.scss']
})
export class ChangeUserComponent {
  public canBecomeUser: boolean = true;
  public canBookOnBehalf: boolean = true;
  public showType = 'become';
  constructor() { }

  log(data): void {
    console.log(data);
  }
}
