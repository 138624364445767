import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalOpenerService } from '@sabstravtech/obtservices/angular';
import { ModalEvent } from '@sabstravtech/obtservices/base';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WithSubscriptionComponent } from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-modal-opener',
  templateUrl: './modal-opener.component.html',
  styleUrls: ['./modal-opener.component.scss']
})
export class ModalOpenerComponent extends WithSubscriptionComponent implements OnInit, OnDestroy {
  private subscription: Subscription = null;
  constructor(
    private modalOpenerService: ModalOpenerService,
    private modalService: NgbModal) {
    super();
  }

  ngOnInit(): void {
    this.subscription = this.modalOpenerService.subscribe((event: ModalEvent) => {
      const modal: any = event.type.component;
      if (modal) {
        const instance = this.modalService.open(modal, event.options);
        Object.keys(event.data || {}).forEach((key: string) => {
          if (instance.componentInstance.hasOwnProperty(key)) {
            instance.componentInstance[key] = event.data[key];
          }
        });
        if (event.results) {
          instance.result.then(event.results).catch(() => { event.results(null); });
        }
      } else {
        console.warn('Unable to find Modal : ' + event.type);
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }
}
