import {
  Component,
  ContentChildren,
  QueryList,
  AfterViewInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  HostListener,
  Renderer2,
  DoCheck,
} from '@angular/core';
import { LightToggleBtnComponent } from '../light-toggle-btn/light-toggle-btn.component';
import { WithSubscriptionComponent } from '@sabstravtech/obtservices/angular';
import { LightToggleBtn } from '../../vendor/interfaces/light-toggle-btn.interface';

@Component({
  selector: 'app-light-toggle-group',
  templateUrl: './light-toggle-group.component.html',
  styleUrls: ['./light-toggle-group.component.scss'],
})
export class LightToggleGroupComponent
  extends WithSubscriptionComponent
  implements AfterViewInit, DoCheck
{
  @ContentChildren(LightToggleBtnComponent, { read: LightToggleBtnComponent })
  lightToggleBtns: QueryList<LightToggleBtnComponent>;

  @ViewChild('floater') floater: ElementRef;

  @Output() public change: EventEmitter<LightToggleBtn> = new EventEmitter();
  activeIndex = 1;
  finishResize: any;
  width = -1;

  constructor(private renderer: Renderer2) {
    super();
  }

  ngDoCheck(): void {
    if (
      this.lightToggleBtns &&
      this.lightToggleBtns.first &&
      this.width !==
        this.lightToggleBtns.first.elementRef.nativeElement.clientWidth
    ) {
      this.ngAfterViewInit();
    }
  }

  ngAfterViewInit(): void {
    let beenSet = false;
    (this.lightToggleBtns || []).forEach((btn, index) => {
      if (btn.selected) {
        this.pinFloaterTo(index);
        beenSet = true;
      }
      if (!beenSet) {
        this.pinFloaterTo(0);
      }
      this.subscribe(btn.clicked, (btnRef: LightToggleBtn) => {
        (this.lightToggleBtns || []).forEach((ltb) => ltb.setActive(false));
        btn.toggle();
        this.change.emit(btnRef);
        this.activeIndex = index;
        this.pinFloaterTo();
      });
    });
  }

  @HostListener('window:resize', [])
  pinFloaterTo(index: number = this.activeIndex) {
    if (this.lightToggleBtns) {
      this.width =
        this.lightToggleBtns.first.elementRef.nativeElement.clientWidth;
      this.renderer.setStyle(this.floater.nativeElement, 'transition', `none`);
      this.renderer.setStyle(
        this.floater.nativeElement,
        'width',
        `${this.lightToggleBtns.first.elementRef.nativeElement.clientWidth}px`
      );
      this.renderer.setStyle(
        this.floater.nativeElement,
        'width',
        `${this.lightToggleBtns.first.elementRef.nativeElement.clientWidth}px`
      );
      this.renderer.setStyle(
        this.floater.nativeElement,
        'left',
        `${index * this.floater.nativeElement.clientWidth}px`
      );
      this.renderer.setStyle(
        this.floater.nativeElement,
        'transition',
        `ease-in-out .2s`
      );
    }
  }
}
