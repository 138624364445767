<div class="app">
  <app-results-header
    [hidden]="searchService.search_loading"
    [page]="'results'"></app-results-header>
  <div class="app-body wcag-checkbox" id="main">
    <div class="app-left-results secondary-text-colour" #main>
      <app-banner *ngIf="userIsAgent" [target]="displayTarget" [agentOnly]="true"></app-banner>
      <app-banner [target]="displayTarget"></app-banner>
      <app-announcements
        [basketStatus]="0"
        page="results"
        [serviceType]="searchService.searchTypes">
    </app-announcements>
      <h1 class="search_content_titles" i18n="@@searchResults">Search Results</h1>
      <app-carbon-allowance-container
        [hidden]="searchService.search_loading"
        [travellers]="searchService.getSelectedtravellers()">
      </app-carbon-allowance-container>
      <router-outlet></router-outlet>
    </div>

    <div
      appOutside
      *ngIf="!searchService.search_loading"
      id="leftContainer"
      [@slideInOut]="basketService.menuState"
      class="app-right secondary-text-colour app-right-basket"
      [class.active]="'active'"
      id="basket">
      <section (mouseleave)="tt.close()" class="container">
        <button
          (click)="toggleMenu()"
          class="basket-button"
          [ngbTooltip]="
            !toolTipClicked ? (('View your ' + basketTerm) | translate) : (('Hide your ' + basketTerm) | translate)
          "
          triggers="manual"
          id="basket_expander_button"
          [attr.aria-expanded]="basketService.menuState === MenuSlideEnum.IN"
          #tt="ngbTooltip"
          #basketExpanderButton
          i18n-ngbTooltip
          (mouseenter)="tt.open()"
          (focus)="tt.open()"
          (blur)="tt.close()"
          [placement]="toolTipClicked ? 'right': 'left'"
          aria-label="basket">
          <div class="basket">
            <span class="counter">{{ basketCounter }}</span>
            <img
              class="cart"
              src="https://images.sabscorp.com/images/enterprise/lightUK/assets/images/empty-cart.svg"
              alt="Cart" />
          </div>
        </button>
      </section>
      <app-basket-panel
        class="app-basket-panel menu"
        [ngClass]="{ show: basketService.menuState === MenuSlideEnum.IN }"
        (click)="onPanelClick($event)"></app-basket-panel>
    </div>
  </div>
</div>
