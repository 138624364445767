import { Component, Input } from '@angular/core';
import {
  FareConditionEntry,
  WithSubscriptionComponent,
} from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-farerule-items',
  templateUrl: './farerule-items.component.html'
})
export class FareruleItemsComponent extends WithSubscriptionComponent {
  @Input() items: FareConditionEntry[];
  constructor() {
    super();
  }
}
