<div cdkTrapFocus>
    <div class="modal-header">
      <h3 class="modal-title">
        <span i18n="@@repriceHeader">Select class for repricing</span>
      </h3>
      <i class="fare-rules_exit-icon ico-cancel"
        (click)="close()"
        (keydown.enter)="close()"
        role="button"
        aria-label="close"
        tabindex="0">
        </i>
    </div>
    <div class="modal-body">
        <form #repriceForm="ngForm">
            <table>
                <tr class="header-row">
                    <th i18n="@@from">From</th>
                    <th i18n="@@To">To</th>
                    <th i18n="@@currentClass">Current Class</th>
                    <th i18n="@@newClass">New Class</th>
                </tr>
                <tr *ngFor="let leg of legCopies; let i = index">
                    <td>{{leg.originAirport}}</td>
                    <td>{{leg.destinationAirport}}</td>
                    <td>{{ flight.source === serviceProvider.TravelportFlight ? leg.bookingCode : leg.cabinRaw}}</td>
                    <td>
                        <select [(ngModel)]="leg.newClass"  class="select-box select-box--border" name="class{{i}}" aria-label="New Class" required>
                            <option *ngFor="let seatclass of availability" [value]="seatclass.resBookDesigCode"> {{seatclass.resBookDesigCode}}{{seatclass.availability}} </option>
                        </select>
                    </td>
                </tr>
            </table>
        </form>
    </div>
    <div class="modal-footer">
      <button
        [disabled]="repriceForm.form.invalid"
        type="button"
        class="small-button"
        i18n="@@fareruleReprice"
        (click)="activeModal.close(legCopies)"
      >
        OK
      </button>
    </div>
  </div>
  