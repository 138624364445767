import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitIfCamelCase',
})
export class SplitIfCamelCasePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (typeof value !== 'string') {
      return value;
    }
    try {
      if (/\b[a-z]*([A-Z][a-z]*)*\b/.test(value)) {
        let str = value.split(/(?=[A-Z])/).join(' ');
        // check if there is double space in the value
        let doubleSpace = /\s{2}/.test(str);
        if (doubleSpace) {
          str = str.replace(/\s{2}/g, ' ');
        } 
        return str[0].toUpperCase() + str.slice(1);
      } else {
        return value;
      }
    } catch {
      return value;
    }
  }
}
