import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'memoize',
  pure: true, //can be omitted as default value
})
export class MemoizePipe implements PipeTransform {
  transform(fn: Function, ...args: any[]): any {
    if (fn) {
      return fn(...args);
    }
  }
}
