import { TitleCasePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbTooltip, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, of, BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, map, catchError } from 'rxjs/operators';
import {
    EnterpriseSearchService,
    UserService,
    ServiceType,
    RailSearchJourneyType,
    IrlJourneyType,
    IrlTravelCard
} from '@sabstravtech/obtservices/angular';
import {
    ApartmentEnterpriseSearchInterface,
    Traveller,
    LocationDetails
} from '@sabstravtech/obtservices/base';
import { LightningUserFavorurite } from '../../../vendor/classes/user-favourite.enum';

import { DeviceDetector } from '../../../vendor/services/device-detector.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../../vendor/components/base_components/base-componet';

@Component({
  selector: 'app-apartment-search',
  templateUrl: './apartment-search.component.html',
  styleUrls: ['./apartment-search.component.scss']
})
export class ApartmentSearchComponent implements OnInit {

  searchParams: ApartmentEnterpriseSearchInterface;
  isLocationLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);

  formatter = (x: { name: string; countryCode?: string; }) => `${x.name}`;
  postCodeFormatter = (x: { name: string; }) => `${x.name}`;

  constructor(
    private userService: UserService,
    public deviceDetector: DeviceDetector,
    public searchService: EnterpriseSearchService,
    private titleCasePipe: TitleCasePipe,
    public title: Title,
    public translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.searchParams = this.searchService.searches[ServiceType.Apartment];
  }

  // Search location in city
  searchLocations = (text$: Observable<string>): Observable<any[]> => {
    let isConfirma = false;
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    return debouncedText$.pipe(
      switchMap(
        (term: string): Observable<any[]> =>
          term.length <= 2
            ? of([])
            : this.searchService.getCityLocations(
              term,
              this.searchParams.country.cCode,
              this.isLocationLoading,
              isConfirma
            )
      )
    );
  };

  searchPostCode = (text$: Observable<string>): Observable<any[]> => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    return debouncedText$.pipe(
      switchMap(
        (term: string): Observable<any[]> =>
          term.length < 2
            ? of([])
            : this.searchService.getPostcodeList(term, this.searchParams.country.cCode)
      )
    );
  };

  ensureElementIsScrolledTo(event) {
    try {
      const typeAheadList = event.target.nextElementSibling;
      const activeButton = typeAheadList.getElementsByClassName('active')[0];
      if ((activeButton.offsetTop + activeButton.clientHeight) > (typeAheadList.clientHeight + typeAheadList.scrollTop)) {
        typeAheadList.scrollTop = (activeButton.offsetTop + activeButton.clientHeight) - typeAheadList.clientHeight;
      }
      else if ((activeButton.offsetTop) < (typeAheadList.scrollTop)) {
        typeAheadList.scrollTop = activeButton.offsetTop;
      }
    } catch (e) {
      console.log('Couldn\'t find elements to scroll');
    }
  }
}
