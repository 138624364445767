<div class="modal-header">
    <h3 class="modal-title light-color-primary">{{title}}</h3>
    <button type="button"
            class="close"
            aria-label="Close"
            (click)="dialogref.close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <i class='button_spinner iframe_spinner'
       *ngIf='!loaded'></i>
    <iframe height="400px"
            #iframe
            width="550px"
            scrolling="yes"
            [src]="urlForIframe | safe"
            target="_parent"
            tabindex="-1"
            title="Pictures"></iframe>

</div>