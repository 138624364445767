import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { EnterpriseSearchService, ServiceType } from '@sabstravtech/obtservices/angular';
import {
    FlightEnterpriseSearchInterface,
    EurostarEnterpriseSearchInterface,
    FlightDirectionEnum
} from '@sabstravtech/obtservices/base';

@Component({
    selector: 'app-carbon-comparison-eurostar',
    templateUrl: './carbon-comparison-eurostar.component.html',
    styleUrls: ['./carbon-comparison-eurostar.component.scss']
})
export class CarbonComparisonEurostarComponent implements OnInit {
    flightSearchParams: FlightEnterpriseSearchInterface;
    eurostarSearchParams: EurostarEnterpriseSearchInterface; 
    lowestCarbonFlight: number;
    lowestCarbonEuroStar: number;
    @Output() valueCalculated: EventEmitter<number> = new EventEmitter<number>();

    constructor(private readonly searchService: EnterpriseSearchService) {

    }

    ngOnInit(): void {
        this.flightSearchParams = this.searchService.searches[ServiceType.Flight];
        this.eurostarSearchParams = this.searchService.searches[ServiceType.Eurostar];
        this.lowestCarbonFlight = this.getLowestCarbonFlight();
        this.lowestCarbonEuroStar = this.getLowestCarbonEuroStar();
        console.log(this.lowestCarbonFlight, this.lowestCarbonEuroStar);
        
        if (this.lowestCarbonFlight > this.lowestCarbonEuroStar) {
            let percentageSaved = (1 - (this.lowestCarbonEuroStar / this.lowestCarbonFlight)) * 100;
            this.valueCalculated.emit(percentageSaved);
        }
    }

    getLowestCarbonFlight(): number {
        try {
            const journeys = this.flightSearchParams.fullResults.map((flight) => {
                return flight?.journeys;
            })

            let lowestJourneys = journeys[0].length ? journeys[0].reduce(
                (prev, curr) => prev.co2PerItem < curr.co2PerItem ? prev : curr
            ).co2PerItem : 0;
            return lowestJourneys;
        } catch {
            return 0;
        }

    }

    getLowestCarbonEuroStar(): number {
        let joruneys = this.eurostarSearchParams.rawResults.journeys.filter(joruney=>joruney.co2PerItem > 0);
        try {
            return joruneys.reduce((prev, curr) => prev.co2PerItem < curr.co2PerItem ? prev : curr).co2PerItem;
        } catch {
            return 0;
        }
    }

    getCarbonStyle(): string {
        let lowerValue = Math.min(this.lowestCarbonFlight, this.lowestCarbonEuroStar);
        let higherValue = Math.max(this.lowestCarbonFlight, this.lowestCarbonEuroStar);  
        let percentage = ((lowerValue / higherValue) * 100).toFixed();
        return 'calc(' + percentage.toString() + '% - ' + (60 * (lowerValue / higherValue)).toFixed() + 'px)';
    }
}
