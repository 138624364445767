import { Component, EventEmitter, OnInit, Output } from '@angular/core';


@Component({
  selector: 'core-map-content',
  template: '',
})
export class MapContentComponent implements OnInit {
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onMapLoad: EventEmitter<{}> = new EventEmitter<{}>();
  center: google.maps.LatLngLiteral;

  constructor(
    // public gMaps: GoogleMapsAPIWrapper
  ) { }

  ngOnInit(): void {
    // this.gMaps.setMapOptions({ streetViewControl: false });
    // this.gMaps.getNativeMap().then((map) => {
    //   this.onMapLoad.emit(map);
    // });
    navigator.geolocation.getCurrentPosition((position) => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
    });
  }
}
