import { Component } from '@angular/core';
import { BaseModalComponent } from '../../modal-module/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FlightFareRulesResponse, FlightFareRulesResult, FlightItinerary, EnterpriseSearchService, FlightJourney, FareConditions, FareConditionEntry } from '@sabstravtech/obtservices/angular';
import { IFareRulesKey } from '@sabstravtech/obtservices/base';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';

@Component({
  selector: 'app-farerules',
  templateUrl: './farerules.component.html',
  styleUrls: ['./farerules.component.scss'],
})
export class FarerulesComponent extends BaseModalComponent {
  rules: FlightFareRulesResponse | FareConditions = null;
  from: string = '';
  to: string = '';
  flight: FlightItinerary = null;
  sourceId: number = null;
  loading = false;
  selectedId: string;
  inboundRules: FlightFareRulesResponse = null;
  fareRulesKey: IFareRulesKey = null;
  constructor(activeModal: NgbActiveModal, private searchService: EnterpriseSearchService) {
    super(activeModal);
    this.title = 'Flight Fare Rules';
  }

  selectSegment(segment: FlightJourney) {
    this.selectedId = segment.id;
    this.from = segment.originAirport;
    this.to = segment.destinationAirport;
    this.loading = true;
    delete segment.fareConditions?.__typename;
    const doCall = !Object.values(segment.fareConditions).find((rule: FareConditionEntry[]): number => rule.length);
    if(doCall){
      const rulesFromCall = this.searchService.getFlightFareRules(segment, this.flight.sourceId || this.sourceId);
      this.subscribe(rulesFromCall, (data: FlightFareRulesResult) => {
        this.rules = data.results[0];
        this.loading = false;
      });
    } else {
      this.rules = segment.fareConditions;
      this.loading = false;
    }


  }
}

LightningModalTypes.ModalFlightFareComponent.component = FarerulesComponent;
