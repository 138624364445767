import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import _, { uniqWith } from 'lodash';
import { LightningModalTypes } from '../../../vendor/classes/modal-types.enum';
import {
  RailTicketTermsList,
  ModalOpenerService,
  Fares,
  RailSearchComposition
} from '@sabstravtech/obtservices/angular';
import { TicketType, ServiceProvider } from '@sabstravtech/obtservices/base';

@Component({
  selector: 'app-rail-fare-list',
  templateUrl: './rail-fare-list.component.html',
  styleUrls: ['./rail-fare-list.component.scss']
})
export class RailFareListComponent implements OnInit {
  public outboundFareTermsList: RailTicketTermsList[] = [];
  public inboundFareTermsList: RailTicketTermsList[] = [];
  public inboundFares: Fares[] = [];
  public outboundFares: Fares[] = [];
  public singleOrReturn: TicketType = TicketType.Single;
  public splitType: RailSearchComposition = RailSearchComposition.DirectSplit;
  public inboundSplitType: RailSearchComposition = RailSearchComposition.DirectSplit;
  public origin: string = '';
  public destination: string = '';
  public inboundOrigin: string = '';
  public inboundDestination: string = '';
  public serviceName: string = '';
  public splitsURL: string = '';
  RailSearchComposition = RailSearchComposition;
  outbound: (RailTicketTermsList | Fares)[];
  inbound: (RailTicketTermsList | Fares)[];
  isOutboundSplit: boolean = false;
  isInboundSplit: boolean = false;
  isDualSingleReturn: boolean = false;
  isEvolvi = false;

  showReturn: boolean = true;
  constructor(public activeModal: NgbActiveModal, public modalService: ModalOpenerService) {}

  ngOnInit() {
    console.log(this.outboundFareTermsList);

    this.isEvolvi = this.serviceName === ServiceProvider.Evolvi;
    this.isOutboundSplit = this.splitType && this.splitType !== RailSearchComposition.Through;
    this.isInboundSplit =
      this.inboundSplitType && this.inboundSplitType !== RailSearchComposition.Through;
    this.showReturn = this.singleOrReturn === TicketType.Single || this.isInboundSplit;

    // Get uniq fares due to multiple passengers fares
    if (this.isDualSingleReturn && this.isEvolvi && !this.isOutboundSplit && !this.isInboundSplit) {
      this.outboundFares = [
        {
          name: (this.outboundFares as any).supplierTickets[0].ticketDescription,
          id: (this.outboundFares as any).supplierTickets[0].ticketDescription
        }
      ];
      this.inboundFares = [
        {
          name: (this.inboundFares as any).supplierTickets[0].ticketDescription,
          id: (this.inboundFares as any).supplierTickets[0].ticketDescription
        }
      ];
    } else {
      this.outboundFares = uniqWith(
        this.outboundFares,
        (a, b) => a.name === b.name && a.destination === b.destination && a.origin === b.origin
      );
      this.inboundFares = uniqWith(
        this.inboundFares,
        (a, b) => a.name === b.name && a.destination === b.destination && a.origin === b.origin
      );
    }

    this.outbound = this.outboundFares.map(x => {
      const fareTerm = this.outboundFareTermsList.find(f => f.fareId === x.id);
      return {
        ...x,
        ...fareTerm
      };
    });
    console.log('outbound', this.outbound);
    this.inbound = this.inboundFares.map(x => {
      const fareTerm = this.inboundFareTermsList.find(f => f.fareId === x.id);
      return {
        ...x,
        ...fareTerm
      };
    });

    if (this.serviceName === ServiceProvider.Trainline) {
      this.splitsURL =
        'https://support.thetrainline.com/en/support/solutions/articles/78000000442-splitsave';
    } else if (this.serviceName === ServiceProvider.Evolvi) {
      this.splitsURL = 'https://redfern.evolviuat.com/help/splittixfaq.html';
    }
  }

  async openTicketCondition(id: string) {
    const ticket = [...this.outboundFareTermsList, ...(this.inboundFareTermsList || [])].find(
      x => x.fareId === id
    );
    await this.modalService.open(
      LightningModalTypes.RailFareTermsComponent,
      {},
      {
        ticketDetails: ticket.terms
      }
    );
  }
}

LightningModalTypes.RailFareListComponent.component = RailFareListComponent;

