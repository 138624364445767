import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BasketItem } from '@sabstravtech/obtservices/angular';
import { EnterpriseSearchService, ServiceType } from '@sabstravtech/obtservices/angular';
import { ParkingEnterpriseSearchInterface, TerminalsType } from '@sabstravtech/obtservices/base';

@Component({
  selector: 'app-basket-parking',
  templateUrl: './basket-parking.component.html',
  styleUrls: ['./basket-parking.component.scss']
})
export class BasketParkingComponent implements OnInit {
  @Input() parking: BasketItem;
  @Input() duplicateBooking = false;
  @Input() itemIndex: number = 0;
  @Output() remove: EventEmitter<void> = new EventEmitter<void>();
  @Output() openChangeModal: EventEmitter<void> = new EventEmitter<void>();
  @Input() displayItinerary = false;

  public searchParameters: ParkingEnterpriseSearchInterface = null;

  constructor(private searchService: EnterpriseSearchService) { }

  ngOnInit() {
    this.searchParameters = this.searchService.searches[ServiceType.Parking];
  }

  getTerminal(terminal: string) {
    if (terminal) {
      let newTerminals: TerminalsType[] = this.searchService.processTerminals([terminal]);
      if (newTerminals.length > 1) {
        return this.searchService.processTerminals([terminal])[1]?.key;
      } else {
        return this.searchService.processTerminals([terminal])[0]?.key;
      }
    } else {
      return 'Any Terminal';
    }
  }
}
