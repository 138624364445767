import { TitleCasePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbTooltip, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, of, BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, map, catchError } from 'rxjs/operators';
import {
    EnterpriseSearchService,
    UserService,
    ServiceType,
    RailSearchJourneyType,
    IrlJourneyType,
    IrlTravelCard,
    MeetingRoomDuration,
    MeetingRoomLayout,
    MeetingRoomCatering,
    MeetingRoomEquipment,
    Country
} from '@sabstravtech/obtservices/angular';
import {
    Traveller,
    LocationDetails
} from '@sabstravtech/obtservices/base';
import { LightningUserFavorurite } from '../../../vendor/classes/user-favourite.enum';

import { DeviceDetector } from '../../../vendor/services/device-detector.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../../vendor/components/base_components/base-componet';
import moment from 'moment';

// import { SetDefaultService } from '../../obtservices/vendor/services/set-default.service';
// import { UserFavorurite } from '../../obtservices/vendor/enums/user-favourite.enum';

@Component({
    selector: 'app-meeting-room-search',
    templateUrl: './meeting-room-search.component.html',
    styleUrls: ['./meeting-room-search.component.scss']
})
export class MeetingRoomSearchComponent extends BaseComponent implements OnInit {
    public focus$ = new Subject<string>();

    ServiceType: typeof ServiceType = ServiceType;
    travellers: Traveller[];

    searchParams: any;
    isLocationLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    typeaHeadLimit = Number.MAX_SAFE_INTEGER;

    min_date = {
        year: moment().year(),
        month: moment().month() + 1, // ngbDate months are not 0 indexed
        day: moment().date()
    };

    isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private userService: UserService,
        public deviceDetector: DeviceDetector,
        public searchService: EnterpriseSearchService,
        private titleCasePipe: TitleCasePipe,
        public title: Title,
        public translateService: TranslateService
    ) {
        super(title, translateService);
    }

    ngOnInit(): void {
        this.searchParams = this.searchService.searches[ServiceType.MeetingRoom];
        this.searchService.travellerInformation.subscribe((travellers: Traveller[]) => {
            this.travellers = travellers;
        });
    }

    formatter = (x: { name: string; countryCode?: string; }) => `${x.name}`;

    postCodeFormatter = (x: { name: string; }) => `${x.name}`;


    searchLocations = (text$: Observable<string>): Observable<any[]> => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    return debouncedText$.pipe(
        switchMap(
        (term: string): Observable<any[]> =>
            term.length <= 2
            ? of([])
            : this.searchService.getCityLocations(
                term,
                this.searchParams.country.cCode,
                this.isLocationLoading
            )
        )
    );
    };

    searchPostCode = (text$: Observable<string>): Observable<any[]> => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        return debouncedText$.pipe(
            switchMap(
            (term: string): Observable<any[]> =>
                term.length < 2
                ? of([])
                : this.searchService.getPostcodeList(term, this.searchParams.country.cCode)
            )
        );
    };    

    ensureElementIsScrolledTo(event) {
        try {
            const typeAheadList = event.target.nextElementSibling;
            const activeButton = typeAheadList.getElementsByClassName('active')[0];
            if (
                activeButton.offsetTop + activeButton.clientHeight >
                typeAheadList.clientHeight + typeAheadList.scrollTop
            ) {
                typeAheadList.scrollTop =
                    activeButton.offsetTop + activeButton.clientHeight - typeAheadList.clientHeight;
            } else if (activeButton.offsetTop < typeAheadList.scrollTop) {
                typeAheadList.scrollTop = activeButton.offsetTop;
            }
        } catch (e) {
            // tslint:disable-next-line: quotemark
            console.log("Couldn't find elements to scroll");
        }
    }

    isValidTime(favouriteTime: string): boolean {
        return !!favouriteTime.match(/^(\d{2}):(\d{2})$/);
    }

    cardComparator(a: IrlTravelCard, b: IrlTravelCard): boolean {
        return a?.name === b?.name;
    }

    countryChange(country: Country) {
        // error when I'm doing a build, added missing method
    }
}
