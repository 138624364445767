<div [formGroup]="travelPreferencesForm" *ngIf="user && travelPreferencesForm">
    <div formArrayName="preferences" class="profile_information--content" >
        <div class="profile_information--sub-header" i18n="@@ProfileInformation-Air">Air</div>
        <div  *ngFor="let preferenceForm of preferences.controls; let i = index" [formGroupName]="i">
            <div class="profile_information--input-container" *ngIf="preferenceForm.get('preferenceKey').value === preferenceKeyEnum.SpecialNeed">
                <div class="profile_information--input-label">
                    <span i18n="@@ProfileInformation-SpecialNeed">Special Need</span>:
                </div>
                <div class="profile_information--input-wrapper">
                    <select class="profile_information--input" formControlName="preferenceValue">
                        <option value="" i18n="@@ProfileInformation-None">None</option>
                        <option *ngFor="let special of specialNeed" [value]="special.label">
                            {{special.label}}
                        </option>
                    </select>
                    <fa-icon [icon]="faChevronDown"></fa-icon>
                </div>
            </div>

            <div class="profile_information--input-container" *ngIf="preferenceForm.get('preferenceKey').value === preferenceKeyEnum.MealRequest">
                <div class="profile_information--input-label">
                    <span i18n="@@ProfileInformation-MealRequests">Meal Requests</span>:
                </div>
                <div class="profile_information--input-wrapper">
                    <select class="profile_information--input" formControlName="preferenceValue">
                        <option value="" i18n="@@ProfileInformation-None">None</option>
                        <option *ngFor="let meal of mealRequest" [value]="meal.label">
                            {{meal.label}}
                        </option>
                    </select>
                    <fa-icon [icon]="faChevronDown"></fa-icon>
                </div>
            </div>

            <div class="profile_information--input-container" *ngIf="preferenceForm.get('preferenceKey').value === preferenceKeyEnum.SeatingPreference">
                <div class="profile_information--input-label">
                    <span i18n="@@ProfileInformation-SeatingPreference">Seating Preference</span>:
                </div>
                <div class="profile_information--input-wrapper">
                    <select class="profile_information--input" formControlName="preferenceValue">
                        <option value="" i18n="@@ProfileInformation-None">None</option>
                        <option *ngFor="let seat of seatingPreference" [value]="seat.label">
                            {{seat.label}}
                        </option>
                    </select>
                    <fa-icon [icon]="faChevronDown"></fa-icon>
                </div>
            </div>
        </div>
        <div class="profile_information--sub-header" i18n="@@ProfileInformation-Rail">Rail</div>
        <div  *ngFor="let preferenceForm of preferences.controls; let i = index" [formGroupName]="i">
            <div class="profile_information--input-container"  *ngIf="preferenceForm.get('preferenceKey').value === preferenceKeyEnum.SeatDirection">
                <div class="profile_information--input-label">
                    <span i18n="@@ProfileInformation-SeatDirection">Seat Direction</span>:
                </div>
                <div class="profile_information--input-wrapper">
                    <select class="profile_information--input" formControlName="preferenceValue">
                        <option value="" i18n="@@ProfileInformation-None">None</option>
                        <option *ngFor="let seat of seatDirection" [value]="seat.label">
                            {{seat.label}}
                        </option>
                    </select>
                    <fa-icon [icon]="faChevronDown"></fa-icon>
                </div>
            </div>
            <div class="profile_information--input-container" *ngIf="preferenceForm.get('preferenceKey').value === preferenceKeyEnum.SeatPosition">
                <div class="profile_information--input-label">
                    <span i18n="@@ProfileInformation-SeatPosition">Seat Position</span>:
                </div>
                <div class="profile_information--input-wrapper">
                    <select class="profile_information--input" formControlName="preferenceValue">
                        <option value="" i18n="@@ProfileInformation-None">None</option>
                        <option *ngFor="let seat of seatPosition" [value]="seat.label">
                            {{seat.label}}
                        </option>
                    </select>
                    <fa-icon [icon]="faChevronDown"></fa-icon>
                </div>
            </div>
            <div class="profile_information--input-container" *ngIf="preferenceForm.get('preferenceKey').value === preferenceKeyEnum.SeatLocation">
                <div class="profile_information--input-label">
                    <span i18n="@@ProfileInformation-SeatLocation">Seat Location</span>:
                </div>
                <div class="profile_information--input-wrapper">
                    <select class="profile_information--input" formControlName="preferenceValue">
                        <option value="" i18n="@@ProfileInformation-None">None</option>
                        <option *ngFor="let seat of seatLocation" [value]="seat.label">
                            {{seat.label}}
                        </option>
                    </select>
                    <fa-icon [icon]="faChevronDown"></fa-icon>
                </div>
            </div> 
        </div>
    </div>
</div> 
 
