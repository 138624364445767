<app-loading-screen
    *ngIf="searchService.search_loading"
    [animated]="animated"
    icon="ico-rail" aria-hidden="true">
    <span title i18n="@@railloading_title">Just loading your search results</span>
    <div msgOne *ngIf="searchParams.airportExpressStation">
        {{ searchParams.airportExpressStation | airportExpress }}
    </div>
    <div msgOne *ngIf="!searchParams.airportExpressStation">
        <span i18n="@@railloading_msgOne">Trains for</span>
        {{ searchParams.travellingFrom.name | titlecase }}
        <span i18m="@@railloading_from">to</span>
        {{ searchParams.travellingTo.name | titlecase }}
    </div>
    <span msgTwo i18n="@@railloading_msgTwo">Checking for availability</span>
</app-loading-screen>