import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AirlinesSearchResult } from '@sabstravtech/obtservices/base';
import { SearchAirlinesFetcher } from '@sabstravtech/obtservices/angular';
import { map, take } from 'rxjs/operators';

@Pipe({
  name: 'airlinePipe',
  pure: true,
})
export class AirlinePipePipe implements PipeTransform {
  constructor(private searchAirlineFetcher: SearchAirlinesFetcher) {}

  transform(value: string): Observable<string> {
    if (value) {
      return this.searchAirlineFetcher.fetchOne({ query: value }).pipe(
        take(1),
        map((result: AirlinesSearchResult) => {
          return result.iataMatch.name;
        })
      );
    }
    return of('Converting');
  }
}
