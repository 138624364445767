import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { WithSubscriptionComponent, UserService, LogonService } from '@sabstravtech/obtservices/angular';
import { LightningUserFavorurite } from '../../vendor/classes/user-favourite.enum';
import { IDashboardInfo } from '../../vendor/interfaces/dashboard.interface';

const DEFAULT_THEME_NAME: string = 'theme--default';


@Injectable({
  providedIn: 'root'
})
export class ThemeService extends WithSubscriptionComponent {
  isLightningEnv: boolean = true;
  isScionEnv: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private userService: UserService,
    private logonService: LogonService) {
    super();
    this.isLightningEnv = this.logonService.isLightningEnv;
    this.isScionEnv = this.logonService.isScionEnv;
  }

  setTheme(): void {
    this.subscribe(this.userService.changeUser, this.updateTheme.bind(this));
    this.updateTheme();
  }

  updateTheme(): void {
    const SET_THEME_NAME = this.isScionEnv ? 'theme--scion' :
      this.userService.getUserFavoriteValue(LightningUserFavorurite.themename) || DEFAULT_THEME_NAME;
    const htmlTag = this.document.getElementsByTagName('html')[0];
    htmlTag.className = '';
    htmlTag.className = SET_THEME_NAME;
    this.updateFavicon();
    // this.updateBackgroundImage();
  }

  private updateFavicon(): void {
    const faviconUrl = this.isLightningEnv ? 'favicon.ico' : 'https://images.sabscorp.com/images/logo/favicon-scion.ico';
    const link: HTMLLinkElement = this.document.querySelector('link[rel="icon"]') || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'icon';
    link.href = faviconUrl;
    document.head.appendChild(link);
  }

  getLogoMyBookings(): string {
    let defaultLogoUrl: string;
    if (this.isLightningEnv) {

      defaultLogoUrl = 'https://images.sabscorp.com/images/enterprise/lightUK/assets/images/lightning_logo.svg';
    } else {
      defaultLogoUrl = 'https://images.sabscorp.com/images/sabsscion/sabstt/SABS-logo.png';
    }
    return defaultLogoUrl;

  }

  getLogo(): string {
    const logoUrl: { imageSrc: string; } = this.userService.getUserFavoriteObject(LightningUserFavorurite.ClientCompanyLogo);
    let defaultLogoUrl: string;
    if (this.isLightningEnv) {

      defaultLogoUrl = 'https://images.sabscorp.com/images/enterprise/lightUK/lightningLogo.svg';
    } else {
      this.userService.fullUserDetails.userConfigs.subscribe(data => {
        const dashboardInfo: IDashboardInfo = this.userService.getUserFavoriteObject(LightningUserFavorurite.scionDashboardInfo);
        defaultLogoUrl = (dashboardInfo && dashboardInfo.dashboardImage) ? dashboardInfo.dashboardImage : 'https://images.sabscorp.com/images/sabsscion/sabstt/SABS-logo.png';
      });
    }
    return logoUrl?.imageSrc || defaultLogoUrl;
  }

  private updateBackgroundImage(): void { // in progress
    let backgroundImageUrl: string;
    let backgroundColor: string;
    if (this.isScionEnv) {
      backgroundImageUrl = '';
      backgroundColor = '#f5f5f5';
    } else {
      backgroundImageUrl = 'https://images.sabscorp.com/images/enterprise/lightUK/assets/images/bg_img.jpg';
      backgroundColor = '#19514e';
    }
    const loadingScreenContainer = this.document.querySelector('.loading-screen-container') as HTMLElement;
    if (loadingScreenContainer) {
      loadingScreenContainer.style.backgroundImage = `url('${backgroundImageUrl}')`;
      loadingScreenContainer.style.backgroundColor = `url('${backgroundColor}')`;
    }
  }
}