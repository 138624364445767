<div cdkTrapFocus
     aria-live="assertive">
  <div class="modal-header">
    <h3 class="modal-title"
        i18n="@@BasketSeatWarningHeader"
        id="modal-header">Seat booking warning.</h3>
  </div>
  <div class="modal-body">
   
    <div class="error">
        <strong i18n="@@BasketRailSeatAutoWarningBody">
          The specific seat selection you have chosen, has failed to be booked, this may be because it is unaviailable, seats may have been automatically selected for you, you will not be charged. 
        </strong>
    </div>

  </div>
  <div class="modal-footer">
    <button 
        type="button"
        id='button_ok'
        class="modal_button_okay"
        (click)="closeModal(true)"
        (keydown.enter)="closeModal(true)"
        (keydown.space)="closeModal(true)"
        i18n="@@ok">
        Ok
    </button>
  </div>
</div>