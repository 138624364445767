<div class="map-actions">
  <a class="light-btn-link light-color-primary imp back-to-map-link"
     (click)="backToList()"
     (keydown.enter)="backToList()"
     tabindex="0"
     role="link"
     i18n="@@maphotelsBackToList">Back to list
    view
    <span class="visuallyhidden">&nbsp; for hotel results</span>
  </a> 
    <div *ngIf="hotelAvailabilityService.fetching|async" i18n="@@loadingHotels">Loading...</div>
</div>
<div class="hotel-map-container">
  <div class="hotel-map-search-container" [hidden]="!directionsOrigin">
    <div class="flex-column-container hotel-map-search-inputs">
      <div class="flex-row-container flex-row-container--right">
        <button type="button"
                class="close"
                title='Close Hotel Map Directions'
                attr.aria-label="Close Hotel Map Directions"
                i18n-aria-label
                (click)="clearHotelDirections()">
          <span aria-hidden="true">
            <i class='ico-cancel modal_exit_cross'></i>
          </span>
        </button>
      </div>
      <b><span i18n="@@mapHotelsGet">Get</span>&nbsp;{{directionsMode.toLowerCase()}}&nbsp;<span i18n="@@mapHotelsDirectionsFrom">directions from:</span> </b>
      <span class="directions-origin-name">{{directionsOriginName}}</span>
      <input #search autocorrect="off" autocapitalize="off" spellcheck="off" type="text" class="form-control">
      <div class="directions-mode-toggle">
        <span role="button" 
              aria-label="Select driving transport mode" 
              tabindex="0"
              (click)="setupAddressSearch('DRIVING')"
              (keydown.enter)="setupAddressSearch('DRIVING')"
              i18n="@@mapHotelsDriving"
              [ngClass]="{ 'selected-directions-mode': directionsMode === 'DRIVING' }">driving,</span>
        <span role="button" 
              aria-label="Select walking transport mode" 
              tabindex="0"
              (click)="setupAddressSearch('WALKING')"
              (keydown.enter)="setupAddressSearch('WALKING')"
              i18n="@@mapHotelsWalking"
              [ngClass]="{ 'selected-directions-mode': directionsMode === 'WALKING' }">&nbsp;walking</span>
      </div>
    </div>
    <div class="directions-panel">
      <div #directionsPanel></div>
      <a i18n="@@mapHotelViewGoogleMaps" target="_blank" class="directions-download" *ngIf="directionsOrigin && directionsDestination && directionsURL" [href]="directionsURL">
        Click here to view in Google Maps
      </a>
    </div>
  </div>
  <google-map
    height="600px"
    width="100%"
    [options]="mapOptions" 
    *ngIf='!error'>
    <map-marker-clusterer [styles]="clusterStyles">
      @for (location of locations; track location) {
        <map-marker
          #marker="mapMarker"
          [position]="location"
          [title]='location.title'
          [icon]='location.iconUrl | async'
          (mapClick)='markerClicked(location)'
          (mapMouseover)="openInfoWindow(marker, infoWindow)"
          >
          <map-info-window #infoWindow="mapInfoWindow"> 
            <div class="hotel-snazzy-window">
              <div class="flex-row-container">
                <img [src]="location.ThumbUrl"
                    class="hotel-item-image"
                    alt="image of {{ location.title ? location.title : 'hotel' }}" />
                <div class="flex-column-container snazzy-window-details">
                  <p class="hotel-item-name"
                    [innerHTML]="location.title"></p>
                  <div *ngIf="location.stars">
                    <p class="stars_container">
                          <i class="ico-star"
                          *ngIf="location.stars >= 0.5"
                          [ngClass]="{half_star: location.stars === 0.5}"
                          aria-hidden="true"></i>
                      <i class="ico-star"
                          *ngIf="location.stars >= 1.5"
                          [ngClass]="{half_star: location.stars === 1.5}"
                          aria-hidden="true"></i>
                      <i class="ico-star"
                          *ngIf="location.stars >= 2.5"
                          [ngClass]="{half_star: location.stars === 2.5}"
                          aria-hidden="true"></i>
                      <i class="ico-star"
                          *ngIf="location.stars >= 3.5"
                          [ngClass]="{half_star: location.stars === 3.5}"
                          aria-hidden="true"></i>
                      <i class="ico-star"
                          *ngIf="location.stars >= 4.5"
                          [ngClass]="{half_star: location.stars === 4.5}"
                          aria-hidden="true"></i>
                      <span class="sr-only">{{location.stars}}&nbsp;<span i18n="@@mapHotelsStars">stars</span></span>
                  </p>
                  </div>
                  <div class="flex-row-container hotel-snazzy-price" *ngIf="location.price">
                    <span class="hotel-item-labels"
                          i18n="@@mapHotelsFrom">From</span>
                      <span class="hotel-item-price">{{
                        location.price | number: '1.2-2'
                    }}</span>
                    <span class="hotel-item-price-type">{{ location.currency }} </span>
                  </div>
                  <div class="flex-row-container flex-row-container--right map_options">
                    <button i18n="@@mapHotelDetails" class="map_hotel_details" (click)="openDetails(location)">Hotel Details</button>
                    <button i18n="@@mapHotelDirections" class="map_hotel_directions" (click)="openDirections(location)">Directions</button>
                  </div>
                </div>
              </div>
            </div>
          </map-info-window>
        </map-marker>
      }
    </map-marker-clusterer>
    <map-marker 
      [position]="referencePoint"
      [title]="'Search Location'"
      id="search-location-marker"
      i18n-title="@@hotelMapSearchLocation">
    </map-marker>

    <core-map-content (onMapLoad)='loadAPIWrapper($event)'></core-map-content>
    @if (directionsResults$ | async; as directionsResults) {
      <map-directions-renderer [directions]="directionsResults" [options]="{
        panel: directionsPanel, suppressMarkers: true }"/>
    }
  </google-map>
</div>

<div class='no_results_message background-10'
     *ngIf='error'>
  <h2 class='no_results_message_heading'>
    <i class='ico-map-1 no_results_icon'></i>
    <span i18n="@@maphotelsMapNotAvail">Map not available!</span>
  </h2>
</div>