<div class="profile_information--content--large" *ngIf="user && form" [formGroup]="form">
    <div class="profile_information--input-container">
        <div class="profile_information--input-label">
            <div class="profile_information--asterisk">*</div>
            <span i18n="@@ProfileInformation-Title">Title</span>:
        </div>
        <div class="profile_information--input-wrapper">
            <select class="profile_information--input" formControlName="title">
                <option *ngFor="let personTitle of personTitles" [ngValue]="personTitle.title">
                {{personTitle.title}}</option>
            </select>
            <fa-icon [icon]="faChevronDown"></fa-icon>
            <div *ngIf="form.get('title').invalid || form.get('title').touched">
                <div *ngIf="form.get('title').errors?.required" class="profile_information--error-message" i18n="@@ProfileInformation-Title-required">Title required</div>
            </div>
        </div>
    </div>

    <div class="profile_information--input-container">
        <div class="profile_information--input-label">
            <div class="profile_information--asterisk">*</div>
            <span i18n="@@ProfileInformation-Fornames">Forename(s)</span>:
        </div>
        <div class="profile_information--input-wrapper">
            <input class="profile_information--input" formControlName="forename"/>
            <div *ngIf="form.get('forename').invalid || form.get('forename').touched">
                <div *ngIf="form.get('forename').errors?.required" class="profile_information--error-message" i18n="@@ProfileInformation-Forname-required">Forname required</div>
            </div>
        </div>

    </div>
    <div class="profile_information--input-container">
        <div class="profile_information--input-label">
            <div class="profile_information--asterisk">*</div>
            <span i18n="@@ProfileInformation-Surname">Surname</span>:
        </div>
        <div class="profile_information--input-wrapper">
            <input class="profile_information--input" formControlName="surname"/>
            <div *ngIf="form.get('surname').invalid || form.get('surname').touched">
                <div *ngIf="form.get('surname').errors?.required" class="profile_information--error-message" i18n="@@ProfileInformation-Surname-required">Surname required</div>
            </div>
        </div>
    </div>
    <div class="profile_information--input-container">
        <div class="profile_information--input-label">
            <span i18n="@@ProfileInformation-DOB">DOB</span>:
        </div>
        <div class="profile_information--input-wrapper">
            <ngbd-datepicker-i18n>
                <input class="profile_information--input"
                placeholder="DD/MM/YYYY"
                #d="ngbDatepicker"
                ngbDatepicker
                [navigation]="'arrows'"
                (click)="d.toggle()"
                formControlName="dob"/>
            <fa-icon [icon]="faCalendar"></fa-icon>
            </ngbd-datepicker-i18n>
        </div>
        <div *ngIf="form.get('dob').invalid || form.get('dob').touched">
            <div *ngIf="form.get('dob').errors" class="profile_information--error-message" i18n="@@ProfileInformation-DOB-invalid">DOB invalid</div>
        </div>
    </div>
    <div class="profile_information--input-container">
        <div class="profile_information--input-label">
            <div class="profile_information--asterisk">*</div>
            <span i18n="@@ProfileInformation-Email">Email</span>:
        </div>
        <div class="profile_information--input-wrapper">
            <input class="profile_information--input" formControlName="email"/>
            <div *ngIf="form.get('email').invalid || form.get('email').touched">
                <div *ngIf="form.get('email').errors?.required" class="profile_information--error-message" i18n="@@ProfileInformation-Email-required">Email required</div>
                <div *ngIf="form.get('email').errors?.email" class="profile_information--error-message" i18n="@@ProfileInformation-Email-invalid">Email invalid</div>
            </div>
        </div>
    </div>
    <div class="profile_information--input-container">
        <div class="profile_information--input-label">
            <div class="profile_information--asterisk">*</div>
            <span i18n="@@ProfileInformation-Language">Language</span>:
        </div>
        <div class="profile_information--input-wrapper">
            <select class="profile_information--input" formControlName="selectedLanguage">
                <option *ngFor="let language of user.availableLanguages" [ngValue]="language.id">
                {{language.name}} - {{language.isoCode}}</option>
            </select>
            <fa-icon [icon]="faChevronDown"></fa-icon>
            <div *ngIf="form.get('selectedLanguage').invalid || form.get('selectedLanguage').touched">
                <div *ngIf="form.get('selectedLanguage').errors?.required" class="profile_information--error-message" i18n="@@ProfileInformation-Language-required">Language required</div>
            </div>
        </div>
    </div>
    <div formArrayName="phoneNumbers">
        <div *ngFor="let phoneForm of phoneNumbers.controls; let i = index" [formGroupName]="i" class="profile_information--input-container" >
            <div class="profile_information--input-label">
                <div class="profile_information--asterisk">*</div>
                <span>{{phoneForm.get('description').value}}</span>
                <span *ngIf="phoneForm.get('userPrimary').value === true" i18n="@@ProfileInformation-Primary">(Primary)</span>
            </div>
            <div [formGroup]="phoneForm" class="profile_information--input-wrapper">
                <div class="profile_information--phone-wrapper" >
                    <div class="profile_information--country-code">
                        <span>{{phoneForm.get('type').value}}</span>
                         <select class="profile_information--wrapped-input" formControlName="type">
                             <option *ngFor="let phoneType of phoneTypes; trackBy: trackbyId"
                                     [ngValue]="phoneType">
                                     <span> {{phoneType | translate}}</span>
                                  ({{phoneType}})
                             </option>
                         </select>
                         <fa-icon [icon]="faChevronDown"></fa-icon>
                     </div>
                    <div class="profile_information--country-code">
                       <span>({{phoneForm.get('countryCode').value}})</span>
                        <select class="profile_information--wrapped-input" formControlName="countryCode">
                            <option *ngFor="let country of countries; trackBy: trackbyId"
                                    [ngValue]="country.dCode">
                                    <span> {{country.cName | translate}}</span>
                                 ({{country.dCode}})
                            </option>
                        </select>
                        <fa-icon [icon]="faChevronDown"></fa-icon>
                    </div>
                    <input class="profile_information--mobile-number" formControlName="number">
                </div>
                <div *ngIf="phoneForm.get('number').invalid || phoneForm.get('number').touched">
                    <div *ngIf="phoneForm.get('number').errors?.required" class="profile_information--error-message" i18n="@@ProfileInformation-Number-required">Number required</div>
                    <div *ngIf="phoneForm.get('number').errors?.minlength" class="profile_information--error-message">
                        <span i18n="@@ProfileInformation-The-minimum-phone-number-length-is-10-digits">The minimum phone number length is 10 digits. Actual length </span> {{phoneForm.get('number').errors?.minlength.actualLength}}</div>
                    <div *ngIf="phoneForm.get('number').errors?.maxlength" class="profile_information--error-message">
                        <span i18n="@@ProfileInformation-The-maximum-phone-number-length-is-15-digits">The maximum phone number length is 15 digits. Actual length </span> {{phoneForm.get('number').errors?.maxlength.actualLength}}</div>
                    <div *ngIf="phoneForm.get('number').errors?.pattern" class="profile_information--error-message" i18n="@@ProfileInformation-OnlyNumbersAreAllowed">Only Numbers are allowed</div>
                </div>
            </div>
        </div>
    </div>
    <div formArrayName="additionalEmail">
        <div *ngFor="let emailForm of additionalEmail.controls; let i = index" [formGroupName]="i" >
            <div [formGroup]="emailForm" class="profile_information--input-wrapper">
                <div class="profile_information--input-container">
                    <div class="profile_information--input-label">
                        <span i18n="@@ProfileInformation-AdditionalEmail">Additional Email</span>:
                    </div>
                    <div class="profile_information--input-wrapper">
                        <input class="profile_information--input" formControlName="value"/>
                        <div *ngIf="emailForm.get('value').invalid || emailForm.get('value').touched">
                            <div *ngIf="emailForm.get('value').errors?.email" class="profile_information--error-message" i18n="@@ProfileInformation-Emailinvalid">Email invalid</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="profile_information--input-container">
        <div class="profile_information--input-label">
            <span i18n="@@ProfileInformation-EmergencyContactName">Emergency Contact Name</span>:
        </div>
        <div class="profile_information--input-wrapper">
            <input class="profile_information--input" formControlName="emergencyContactName"/>
        </div>
    </div>
    <div class="profile_information--input-container">
        <div class="profile_information--input-label">
            <span i18n="@@ProfileInformation-EmergencyContactNumber">Emergency Contact Number</span>:
        </div>
        <div class="profile_information--input-wrapper">
            <input class="profile_information--input" formControlName="emergencyContactPhone">
        </div>
    </div>
</div>
