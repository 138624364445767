import { Component, Input } from '@angular/core';
import { RailJourneyLeg, HelperRoutines } from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-rail-journey-segments',
  templateUrl: './rail-journey-segments.component.html',
  styleUrl: './rail-journey-segments.component.scss'
})
export class RailJourneySegmentsComponent {
  @Input() segments: (RailJourneyLeg & { toggleStop: boolean })[]= [];

  constructor(private helpers: HelperRoutines) {}

  removeTimeZone(date: string) {
    return this.helpers.sanitiseDateTime(date);
  }
}
