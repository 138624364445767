<div cdkTrapFocus
     aria-live="assertive">
  <div class="modal-header">
    <h3 class="modal-title"
        i18n="@@modalWarningTitle"
        id="modal-header">Warning</h3>
    <button type="button"
            class="close"
            aria-label="An Error Occured Close"
            ngbAutoFocus
            i18n-aria-label
            (click)="activeModal.close('Cross click')">
      <span aria-hidden="true"><i class='ico-cancel modal_exit_cross'></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p i18n="@@modalWarningTaxi">
        This address has already been added to your saved addresses, to make changes to the address please select “Remove Saved Addresses” and re-add accordingly.
    </p>
    <p  >
      <span i18n="@@modalWarningContact">
        If you need assistance please contact your travel administrator / support team on: {{phoneNo}} or email <a href="mailto:{{email}}">{{email}}</a> </span>
    </p>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="modal_button_okay"
            (click)="activeModal.close(true)"
            i18n="@@modalErrorOK">Ok</button>
  </div>
</div>
