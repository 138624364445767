<div class="tabs_container_irlDetails"
     aria-live="assertive">
  <div class='tabsHeader'>
    <div class='tab_base'
         [ngClass]="{'tab_select': selectedTab === TabNames.FareDetails}"
         (click)='selectTab(TabNames.FareDetails)'
         (keydown.enter)='selectTab(TabNames.FareDetails)'
         tabindex="0"
         role="button"
         i18n="@@itemDetailsFaredetails">
      Fare Details
    </div>
    <div class='tab_base'
         [ngClass]="{'tab_select': selectedTab === TabNames.RailLegTimes}"
         (keydown.enter)='selectTab(TabNames.RailLegTimes)'
         (click)='selectTab(TabNames.RailLegTimes)'
         tabindex="0"
         role="button"
         i18n="@@itemDetailsRailtLegTimes">
      Show Details
    </div>
  </div>
  <div class='pane'
       *ngIf='selectedTab === TabNames.FareDetails'
       id='0'
       Title=TabNames.FareDetails>
    <div *ngIf="second.length"
         class="type">2nd class</div>
    <div class="tickets">
      <div class="ticket"
           *ngFor="let option of second">
        <span class='leg'>
          <div>
            <b>{{getClassName(option.class)}}:</b>
          </div>
          <label class="ticket-option-type">{{option.type}}</label>
          <a class='fare_rules_link'
             (click)='openFareRulesModal(option)'
             (keydown.enter)='openFareRulesModal(option)'
             tabindex="0"
             role="link">
            <i class='currency_info_icon'></i> <span i18n="@@irlTypeBrandedFareRules">Fare Rules</span></a><br>
        </span>
        <span class="floatright">
          <div class="irl-price-details">
            <div class="star-container">
              <i class="ico-star"
                  *ngIf="option.fareInfo.code"
                  ngbTooltip="Company Negotiated Rate"
                  i18n-ngbTooltip
                  trigger="manual"
                  tabindex="0">
                </i>
            </div>
            <div class="cost"
                 tabindex="0">{{option.price |currency:currency:'symbol'}}
                 <span class="percentage-icon" tooltipClass="custom-tooltip" i18n-ngbTooltip  *ngIf="discountCard" [ngbTooltip]="discountCard">%</span>
              <!-- <section (mouseleave)="tt.close()"
                       *ngIf="!option.CanBook"
                       class="container">
                <img src="{{resultItemType.minus}}"
                     class='error_icon'
                     ngbTooltip="{{option.CannotBookReason}}"
                     attr.aria-label="{{option.CannotBookReason}}"
                     container="body"
                     placement="top"
                     triggers="manual"
                     #tt="ngbTooltip"
                     (mouseenter)="tt.open()"
                     (focus)="tt.open()"
                     (blur)="tt.close()"
                     tabindex="0"><span class="visuallyhidden">{{option.CannotBookReason}}</span>
              </section>
              <section (mouseleave)="tt2.close()"
                       *ngIf="option.CanBook && option.RequiresReason"
                       class="container">
                <img src="{{resultItemType.exclamation}}"
                     class='error_icon'
                     ngbTooltip="{{option.ReasonMessage | translate}}"
                     attr.aria-label="{{option.ReasonMessage | translate}}"
                     container="body"
                     placement="top"
                     triggers="manual"
                     #tt2="ngbTooltip"
                     (mouseenter)="tt2.open()"
                     (focus)="tt2.open()"
                     (blur)="tt2.close()"
                     tabindex="0"
                     [class.can-book-overridden]="option.CanBookOverridden">
                <span class="visuallyhidden">{{option.ReasonMessage | translate}}</span>
              </section>
              <section *ngIf="option.CanBook && !option.RequiresReason"
                       class="container">
                <img src="{{resultItemType.check}}"
                     class='error_icon'>
              </section> -->
            </div>
          </div>

          <button (click)="addToBasket(option)"
                  [disabled]="disabledCheck()"
                  class="link">{{(option.selected ? "Deselect" : "Select") | translate}}</button>
          <br>
        </span>
        <button *ngIf="showDebug"
                (click)="alwaysLog(option)"
                >Dump</button>
      </div>
    </div>
    <div *ngIf="first.length"
         class="type">1st class</div>
    <div class="tickets">
      <div class="ticket"
           *ngFor="let option of first">
        <span class='leg'>
          <div>
            <b>{{getClassName(option.class)}}:</b>
          </div>
          <div>
            <label class="ticket-option-type">{{option.type}}</label>
          </div>
          
          <a class='fare_rules_link'
             (click)='openFareRulesModal(option)'
             (keydown.enter)='openFareRulesModal(option)'
             tabindex="0"
             role="link">
            <i class='currency_info_icon'></i> <span i18n="@@irlTypeBrandedFareRules">Fare Rules</span></a><br>

        </span>
        <span class="floatright">
          <div class="irl-price-details">
            <div class="star-container">
              <i class="ico-star"
                  *ngIf="option.fareInfo.code"
                  ngbTooltip="Company Negotiated Rate"
                  i18n-ngbTooltip
                  trigger="manual"
                  tabindex="0">
                </i>
            </div>
            <div class="cost"
                 tabindex="0">{{option.price |currency:currency:'symbol'}}
                 <span class="percentage-icon" tooltipClass="custom-tooltip" i18n-ngbTooltip  *ngIf="discountCard" [ngbTooltip]="discountCard">%</span>
              <!-- <section (mouseleave)="tt.close()"
                       *ngIf="!option.CanBook"
                       class="container">
                <img src="{{resultItemType.minus}}"
                     class='error_icon'
                     ngbTooltip="{{option.CannotBookReason}}"
                     attr.aria-label="{{option.CannotBookReason}}"
                     container="body"
                     placement="top"
                     triggers="manual"
                     #tt="ngbTooltip"
                     (mouseenter)="tt.open()"
                     (focus)="tt.open()"
                     (blur)="tt.close()"
                     tabindex="0"><span class="visuallyhidden">{{option.CannotBookReason}}</span>
              </section>
              <section (mouseleave)="tt2.close()"
                       *ngIf="option.CanBook && option.RequiresReason"
                       class="container">
                <img src="{{resultItemType.exclamation}}"
                     class='error_icon'
                     ngbTooltip="{{option.ReasonMessage | translate}}"
                     attr.aria-label="{{option.ReasonMessage | translate}}"
                     container="body"
                     placement="top"
                     triggers="manual"
                     #tt2="ngbTooltip"
                     (mouseenter)="tt2.open()"
                     (focus)="tt2.open()"
                     (blur)="tt2.close()"
                     tabindex="0"
                     [class.can-book-overridden]="option.CanBookOverridden"><span
                      class="visuallyhidden">{{option.ReasonMessage | translate}}</span>
              </section>
              <section *ngIf="option.CanBook && !option.RequiresReason"
                       class="container">
                <img src="{{resultItemType.check}}"
                     class='error_icon'>
              </section> -->
            </div>
          </div>

          <button (click)="addToBasket(option)"
                  [disabled]="disabledCheck()"
                  class="link">{{(option.selected ? "Deselect" : "Select") | translate}}</button>
          <br>
        </span>
      </div>
    </div>
  </div>
  <div class='pane'
       *ngIf='selectedTab === TabNames.RailLegTimes'
       id='1'>

    <div class="leg_wrapper">
      <div class='leg_container'>
        <app-irl-legs [legs]='legs'
                      [trainNumber]="trainNumber">
        </app-irl-legs>
      </div>
    </div>
  </div>
</div>