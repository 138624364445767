<div cdkTrapFocus
     aria-live="assertive">
  <div class="modal-header">
    <h3 class="modal-title"
        i18n="@@modalTodErrorTitle"
        id="modal-header">Booking Error!</h3>
    <button type="button"
            class="close"
            aria-label="An Error Occured Close"
            ngbAutoFocus
            i18n-aria-label
            (click)="activeModal.close()">
      <span aria-hidden="true"><i class='ico-cancel modal_exit_cross'></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p> <span i18n="@@modalTODErrorLocation">The specified TOD collection location</span>
      [{{stationCode}}] {{stationName}}
      <span i18n="@@modalTODErrorNoDate">does not have TOD facilities available on the date of travel.</span>
    </p>
    <p i18n="@@modalTodErrorTicketQueue">Please try again specifying an alternative Ticket Queue</p>
    <p i18n="@@modalTodErrorContactAdmin">If this problem persists please contact an administrator.</p>

  </div>
  <div class="modal-footer">
    <button type="button"
            class="modal_button_okay"
            (click)="activeModal.close()"
            i18n="@@modalErrorOK">Ok</button>
  </div>
</div>
