import { Pipe, PipeTransform } from '@angular/core';
import { ParkingQuote, ServiceType, EnterpriseSearchService } from '@sabstravtech/obtservices/angular';

@Pipe({
  name: 'parkingPriceSort',
  pure: true
})
export class ParkingPriceSortPipe implements PipeTransform {
  constructor(private searchService: EnterpriseSearchService) {}

  transform(value: ParkingQuote[], ...args: any[]): ParkingQuote[] {
    value.sort(
      (a: ParkingQuote, b: ParkingQuote) => a.price - b.price
    );
    return value;
  }
}
