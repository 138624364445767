import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';


@Component({
    selector: 'app-new-version',
    templateUrl: './new-version.component.html',
    styleUrls: ['./new-version.component.scss']
})
export class NewVersionComponent {
    constructor(
        public activeModal: NgbActiveModal) {
    }
}

LightningModalTypes.NewVersion.component = NewVersionComponent;

