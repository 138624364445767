<div cdkTrapFocus
     aria-live="assertive">
  <div class="modal-header">
    <h3 class="modal-title"
        i18n="@@modalTravellerErrorTitle"
        id="modal-header">Error!</h3>
    <button type="button"
            class="close"
            aria-label="Error Close"
            ngbAutoFocus
            (click)="activeModal.close(true)">
      <span aria-hidden="true"><i class='ico-cancel modal_exit_cross'></i></span>
    </button>
  </div>
  <div class="modal-body">
    {{error}}
  </div>
  <div class="modal-footer">
    <button type="button"
            class="modal_button_okay"
            (click)="activeModal.close(true)"
            i18n="@@modalTravellerErrorOK">Ok</button>
  </div>
</div>