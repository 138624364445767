import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { passwordMismatchValidator } from '../../shared/directives/password-mismatch.directive';
import { Observable } from 'rxjs';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HotelAvalibilityQuoteResult, HotelQuoteResult } from '@sabstravtech/obtservices/base';

@Component({
  selector: 'app-hotel-result-modal',
  templateUrl: './hotel-result-modal.component.html',
  styleUrls: ['./hotel-result-modal.component.scss']
})
export class HotelResultModalComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal
  ) { }

  hotel: HotelQuoteResult = null;
  rooms: HotelAvalibilityQuoteResult = null;
  noOfRooms: number = null;
  changeFilterChain: EventEmitter<string> = null;
  hotelId: string = null;
  isConferma: boolean = null;
  hotelImages: string[] = null;
  thumbUrl: string = null;
  address: string = null;
  hotelRatingsInfo = null;
  index: number = 0;
  distanceUnit: string = null;
  gmt: boolean = false;
  gmtSelectedRoomIndex: number = null;
  ngOnInit(): void {

  }

  close() {
    if (this.gmt) {
      this.activeModal.close(this.gmtSelectedRoomIndex);
    } else {
      this.activeModal.close();
    }
  }

  changeFilterChainAction(chain:any) {
    if (this.gmt) {
      this.activeModal.close(chain)
    } else {
      this.changeFilterChain.emit(chain);
    }
  }
}

LightningModalTypes.HotelResultModalComponent.component = HotelResultModalComponent;

