import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LightningModalTypes } from '../../../../vendor/classes/modal-types.enum';

@Component({
    selector: 'app-apexx-new-card',
    templateUrl: './apexx-new-card.component.html',
    styleUrls: ['./apexx-new-card.component.scss']
})
export class ApexxNewCardComponent {
    constructor(
        public activeModal: NgbActiveModal
    ) {
    }

    label: string = '';

    onCancel(): void {
        this.activeModal.close(false);
    }

    submit() {
        this.activeModal.close(this.label);
    }

}
LightningModalTypes.ApexxNewCardComponent.component = ApexxNewCardComponent;

