import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { LightningModalTypes } from '../../../../vendor/classes/modal-types.enum';
import { ServiceType, RailTicketExtra, EnterpriseSearchService, HelperRoutines } from '@sabstravtech/obtservices/angular';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
  selector: 'app-rail-tickets-dialog',
  templateUrl: './rail-tickets-dialog.component.html',
  styleUrls: ['./rail-tickets-dialog.component.scss']
})
export class RailTicketsDialogComponent implements OnInit {
  public outboundRailJourneyOption: any = {};
  public inboundRailJourneyOption: any = null;
  public outboundChosenTickets: RailTicketExtra[];

  public isChild: boolean = false;
  public isAdult: boolean = false;
  public serviceType: typeof ServiceType = ServiceType;
  public noOfCards = 3;
  public startDate: {
    [key: string]: number;
  };
  public bookingDate: any[];
  isSkip = true;

  constructor(
    public activeModal: NgbActiveModal,
    public searchService: EnterpriseSearchService,
    private helpers: HelperRoutines
  ) { }

  submit() {

    const chosenTickets = [];

    this.outboundChosenTickets.forEach((ticket, index) => {
      if (ticket) {
        const ticketClone = this.helpers.clone(ticket);
        const date = this.helpers.clone(this.bookingDate[index]);
        ticketClone.date = [date.year, date.month, date.day].join('-');
        ticketClone.identifiers.date = ticketClone.date;
        chosenTickets.push(ticketClone);
      }
    });

    this.activeModal.close(chosenTickets);
  }

  ngOnInit() {
    // set up default value for select
    const outDate = this.outboundRailJourneyOption ? new Date(this.outboundRailJourneyOption.departDateTime.split('T')[0]) : null;
    const inDate = this.inboundRailJourneyOption ? new Date(this.inboundRailJourneyOption.departDateTime.split('T')[0]) : null;
    if (outDate && inDate) {
      const diffTime = Math.abs(+inDate - +outDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      switch (diffDays) {
        case 0:
          this.noOfCards = 1;
          break;
        case 1:
          this.noOfCards = 2;
          break;
        default:
          this.noOfCards = 3;
      }
    } else {
      // check if open return
      if (this.searchService.searches[ServiceType.Rail].openReturn) {
        this.noOfCards = 3;
      } else {
        this.noOfCards = 1;
      }
    }

    if (this.outboundRailJourneyOption) {
      this.outboundChosenTickets = Array(this.noOfCards).fill(null);
    }
    const splitDate = this.outboundRailJourneyOption.arriveDateTime.split('T')[0].split('-');
    this.startDate = { year: +splitDate[0], month: +splitDate[1], day: +splitDate[2] };
    this.bookingDate = [];
    for (let i = 0; i < this.noOfCards; i++) {
      if (i === 0) {
        this.bookingDate.push(this.helpers.clone(this.startDate));
      } else {
        this.bookingDate.push(null);
      }
    }
    this.isChild = this.searchService.searches[ServiceType.Rail].noOfChildren > 0;
    this.isAdult = this.searchService.searches[ServiceType.Rail].noOfAdults > 0;
  }

  updateTravelCard(index: number, extra: RailTicketExtra): void {
    this.updateState();
    this.outboundChosenTickets[index] = (this.outboundChosenTickets && this.outboundChosenTickets[index]?.adultAmount === extra.adultAmount && this.outboundChosenTickets[index]?.location === extra.location) ? null : extra;
    console.log(this.outboundChosenTickets);
  }

  updateDate(event, index) {
    const { year, month, day } = event;
    this.outboundChosenTickets[index].date = [year, month, day].join('-');
  }

  toggleChange(event: MatButtonToggleChange, index: number) {
    let toggle = event.source;
    if (toggle) {
      let group = toggle.buttonToggleGroup;
      if (event.value.some(item => item == toggle.value)) {
        // actualy set bookingdate to null
        console.log(group.selected[0].checked);
        group.value = [toggle.value];
      }
    }
  }

  isDisabled = (date: NgbDate) =>  {
    const vals = Object.values(this.bookingDate);

    for (const val of vals) {
      // @ts-ignore
      if (val && val.day === date.day && val.month === date.month && val.year === date.year) {
        return true;
      }
    }

    return false;
  };


  updateState(){
    if(this.isSkip) this.isSkip = false;
  }
}

LightningModalTypes.RailTicketsDialogComponent.component = RailTicketsDialogComponent;
