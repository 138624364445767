import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LightningModalTypes } from '../../../../../vendor/classes/modal-types.enum';
import { HotelQuoteResult, HotelDetailTypes } from '@sabstravtech/obtservices/base';
import { HotelTag } from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-hotel-info-dialog',
  templateUrl: './hotel-info-dialog.component.html',
  styleUrls: ['./hotel-info-dialog.component.scss']
})
export class HotelInfoDialogComponent implements OnInit {
  // Input
  @Input() public hotelDetail: HotelQuoteResult = null;
  @Input() public thumbUrl: string = null;
  @Input() public address: string = null;
  @Input() distanceUnit: string = null;

  public hotelFacilities: HotelTag[] = [];
  public roomFacilities: HotelTag[] = [];
  userDistanceUnit: string;
  hotelDetailTypes = HotelDetailTypes;
  constructor(
    public activeModal: NgbActiveModal
  ) {
  }

  ngOnInit() {
    if (this.hotelDetail) {
      this.hotelFacilities = this.hotelDetail.tags.filter((tag: HotelTag) => tag.type === 'Hotel Facilities');
      this.roomFacilities = this.hotelDetail.tags.filter((tag: HotelTag) => tag.type === 'Room Facilities');
    }
  }


}

LightningModalTypes.HotelInfoDialogComponent.component = HotelInfoDialogComponent;
