<nav ngbNav #navSeatMap="ngbNav" [destroyOnHide]="false" (navChange)="onNavChange($event)">
  <ng-container ngbNavItem *ngFor="let outboundFlight of flight.outboundFlights; let i = index">
    <button ngbNavLink>
      <span i18n="@@seatmapOutbound">Outbound</span> {{ outboundFlight.originAirport }} -
      {{ outboundFlight.destinationAirport }}
    </button>
    <ng-template ngbNavContent>
      <div class="flight-seat-map-container">
        <div class="seat-map" *ngIf="showSeatmap">
          <div
            *ngIf="user && seatingPreference"
            class="flex-row-container flex-row-container--center">
            <b>{{ user.title }} {{ user.forename }} {{ user.surname }}</b
            >&nbsp; <span i18n="@@seatmapSeatPreference">Seating preference:</span>&nbsp;
            {{ seatingPreference }}
          </div>
          <app-flight-seat-map
            [flight]="outboundFlight"
            [supplier]="flight.source"
            [travellerId]="travellerId"
            [flightSeats]="outboundSeats[i]"
            [requestActive]="activeRequests[i]"
            (seat)="getSeat($event)"
            (loyaltyRetrieved)="setLoyaltyCode($event, i)"
            (setSeatsReceived)="setSeatsReceived($event)"></app-flight-seat-map>
        </div>
        <div
          class="flight-extras"
          [ngClass]="{ 'has-only-extras': showAllExtras && !seatsReceived && !showSeatmap }"
          i18n="@@flight-extras-block"
          *ngIf="showAllExtras && (seatsReceived && showSeatmap || !seatsReceived && !showSeatmap)">
          <app-flight-extras
            [parentTabId]="activeTab"
            [showTFXLuggage]="
              i === 0 &&
              supplier === ServiceProvider.Travelfusion &&
              (luggagePerPax || (travellerIndex === 0 && !luggagePerPax))
            "
            [hideAncillary]="
              i !== 0 && supplier === ServiceProvider.Travelfusion && ancillaryPerBooking
                ? true
                : false
            "
            [supplier]="supplier"
            (flightExtras)="getExtras($event)"
            [selectedFlightExtrasChange]="selectedFlightExtrasChange"
            [flights]="flight.outboundFlights"
            [travellerId]="travellerId"
            [extraOptions]="outboundFlightsExtras?.[0]"
            [flightId]="outboundFlight?.id"></app-flight-extras>
          <pre>{{ additionalDetails | json }}</pre>
        </div>
        <div class="flight-extras" *ngIf="showLoyalty && (seatsReceived && showSeatmap || !seatsReceived && !showSeatmap)">
          <app-flight-extras-recalculate
            [operatingCarrierName]="outboundFlight.operatingCarrierName"
            [loyaltyCode]="loyaltyCodes[i]"
            (loyaltyCodeChanged)="setLoyaltyCode($event, i)"
            (recalculate)="recalculateFlightSeat(i)"></app-flight-extras-recalculate>
        </div>
      </div>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem *ngFor="let inboundFlight of flight.inboundFlights; let i = index">
    <button ngbNavLink>
      <span i18n="@@seatmapInbound">Inbound</span> {{ inboundFlight.originAirport }} -
      {{ inboundFlight.destinationAirport }}
    </button>
    <ng-template ngbNavContent>
      <div class="flight-seat-map-container">
        <div class="seat-map" *ngIf="showSeatmap">
          <app-flight-seat-map
            [flight]="inboundFlight"
            [supplier]="flight.source"
            [flightSeats]="inboundSeats[i]"
            [travellerId]="travellerId"
            [requestActive]="activeRequests[flight.outboundFlights.length + i]"
            (seat)="getSeat($event)"
            (loyaltyRetrieved)="setLoyaltyCode($event, i, true)"
            (setSeatsReceived)="setSeatsReceived($event)"></app-flight-seat-map>
        </div>
        <div
          [ngClass]="{ 'has-only-extras': showAllExtras && !seatsReceived && !showSeatmap }"
          class="flight-extras"
          i18n="@@flight-extras-block"
          *ngIf="showAllExtras && (seatsReceived && showSeatmap || !seatsReceived && !showSeatmap)">
          <app-flight-extras
            [parentTabId]="activeTab"
            [showTFXLuggage]="
              i === 0 &&
              supplier === ServiceProvider.Travelfusion &&
              !luggagePerBooking &&
              (luggagePerPax || (travellerIndex === 0 && !luggagePerPax))
            "
            [hideAncillary]="
              supplier === ServiceProvider.Travelfusion && ancillaryPerBooking ? true : false
            "
            [hideExtras]="supplier === ServiceProvider.Travelfusion"
            [supplier]="supplier"
            (flightExtras)="getExtras($event)"
            [selectedFlightExtrasChange]="selectedFlightExtrasChange"
            [flights]="flight.inboundFlights"
            [travellerId]="travellerId"
            [flightId]="inboundFlight?.id"
            [extraOptions]="inboundFlightsExtras?.[0]"></app-flight-extras>
          <pre>{{ additionalDetails | json }}</pre>
        </div>
        <div class="flight-extras" *ngIf="showLoyalty && (seatsReceived && showSeatmap || !seatsReceived && !showSeatmap)">
          <app-flight-extras-recalculate
            [operatingCarrierName]="inboundFlight.operatingCarrierName"
            [loyaltyCode]="loyaltyCodes[flight.outboundFlights.length + i]"
            (loyaltyCodeChanged)="setLoyaltyCode($event, i, true)"
            (recalculate)="recalculateFlightSeat(i, true)"></app-flight-extras-recalculate>
        </div>
      </div>
    </ng-template>
  </ng-container>
</nav>
<div [ngbNavOutlet]="navSeatMap" class="seatmap"></div>
<ng-container *ngIf="showSeatmap">
  <div class="key-container">
    <h4 i18n="@@key">Key:</h4>
    <div class="container">
      <div id="available-seat" class="seat available"></div>
      <label for="available-seat" i18n="@@available-seat">Available Seat</label>
    </div>
    <div class="container">
      <div id="not-available-seat" class="seat not-available"></div>
      <label for="not-available-seat" i18n="@@not-available-seat">Seat not available</label>
    </div>
    <div class="container">
      <div id="selected-seat" class="seat selected"></div>
      <label for="selected-seat" i18n="@@selected-seat">Seat Selected</label>
    </div>
  </div>
  <div class="key-container">
    <div class="empty"></div>
    <div class="container">
      <div id="no-seat" class="seat no-seat"></div>
      <label for="no-seat" i18n="@@no-seat">No Seat</label>
    </div>
  </div>
</ng-container>