<div [formGroup]="docsForm" *ngIf="user && docsForm" class="profile_information--content" >
    <div formArrayName="documents">
        <div  *ngFor="let docsForm of documents.controls; let i = index" [formGroupName]="i">
            <div [formGroup]="docsForm" *ngIf="i===showDocBlock">
                <div class="profile_information--input-container">
                    <div class="profile_information--input-label">
                        <div class="profile_information--asterisk">*</div>
                        <span i18n="@@ProfileInformation-DocumentType">Document Type</span>:
                    </div>
                    <div class="profile_information--input-wrapper">
                        <select class="profile_information--input" (change)="docTypeSelected(i)" formControlName="docType">
                            <option value="" i18n="@@ProfileInformation-Select">Select...</option>
                            <option *ngFor="let docType of docTypes" [value]="docType.type">
                            {{docType.label}}</option>
                        </select>
                        <fa-icon [icon]="faChevronDown"></fa-icon>
                        <div *ngIf="docsForm.get('docType').invalid || docsForm.get('docType').touched">
                            <div *ngIf="docsForm.get('docType').errors?.required" class="profile_information--error-message" i18n="@@ProfileInformation-DocumentType-required">Document Type required</div>
                        </div>
                    </div>
                </div>
                <div class="profile_information--input-container" *ngIf="docsForm.get('docType').value === docType.Passport">
                    <div class="profile_information--input-label">
                        <div class="profile_information--asterisk">*</div>
                        <span i18n="@@ProfileInformation-Nationality">Nationality</span>:
                    </div>
                    <div class="profile_information--input-wrapper">
                        <input class="profile_information--input" formControlName="nationality"/>
                        <div *ngIf="docsForm.get('nationality').invalid || docsForm.get('nationality').touched">
                            <div *ngIf="docsForm.get('nationality').errors?.required" class="profile_information--error-message" i18n="@@ProfileInformation-Nationality-required">Nationality required</div>
                        </div>
                    </div>
                </div>
                <div class="profile_information--input-container" *ngIf="docsForm.get('docType').value === docType.Passport">
                    <div class="profile_information--input-label">
                        <div class="profile_information--asterisk">*</div>
                        <span i18n="@@ProfileInformation-Gender">Gender</span>:
                    </div>
                    <div class="profile_information--input-wrapper">
                        <select class="profile_information--input" formControlName="gender">
                            <option value="" i18n="@@ProfileInformation-Select">Select...</option>
                            <option *ngFor="let gender of genders" [value]="gender.type">
                            {{gender.label}}</option>
                        </select>
                        <fa-icon [icon]="faChevronDown"></fa-icon>
                        <div *ngIf="docsForm.get('gender').invalid || docsForm.get('gender').touched">
                            <div *ngIf="docsForm.get('gender').errors?.required" class="profile_information--error-message" i18n="@@ProfileInformation-Gender-required">Gender required</div>
                        </div>
                    </div>
                </div>
                <div class="profile_information--input-container" *ngIf="docsForm.get('docType').value === docType.Visa || docsForm.get('docType').value === docType.IdCard">
                    <div class="profile_information--input-label">
                        <div class="profile_information--asterisk">*</div>
                        <span i18n="@@ProfileInformation-Country">Country</span>:
                    </div>
                    <div class="profile_information--input-wrapper">
                        <input class="profile_information--input" formControlName="country"/>
                        <div *ngIf="docsForm.get('country').invalid || docsForm.get('country').touched">
                            <div *ngIf="docsForm.get('country').errors?.required" class="profile_information--error-message" i18n="@@ProfileInformation-Country-required">Country required</div>
                        </div>
                    </div>
                </div>
                <div class="profile_information--input-container">
                    <div class="profile_information--input-label">
                        <div class="profile_information--asterisk">*</div>
                        <span i18n="@@ProfileInformation-Number">Number</span>:
                    </div>
                    <div class="profile_information--input-wrapper">
                        <input class="profile_information--input" formControlName="number"/>
                        <div *ngIf="docsForm.get('number').invalid || docsForm.get('number').touched">
                            <div *ngIf="docsForm.get('number').errors?.required" class="profile_information--error-message" i18n="@@ProfileInformation-Number-required">Number required</div>
                        </div>
                    </div>
                </div>
                <div class="profile_information--input-container" *ngIf="docsForm.get('docType').value === docType.Passport">
                    <div class="profile_information--input-label">
                        <div class="profile_information--asterisk">*</div>
                        <span i18n="@@ProfileInformation-Code-Of-Issuing-State">Code Of Issuing State</span>:
                    </div>
                    <div class="profile_information--input-wrapper">
                        <input class="profile_information--input" formControlName="codeOfIssuingState"/>
                        <div *ngIf="docsForm.get('codeOfIssuingState').invalid || docsForm.get('codeOfIssuingState').touched">
                            <div *ngIf="docsForm.get('codeOfIssuingState').errors?.required" class="profile_information--error-message" i18n="@@ProfileInformation-Code-Of-Issuing-State-required">Code Of Issuing State required</div>
                        </div>
                    </div>
                </div>
                <div class="profile_information--input-container">
                    <div class="profile_information--input-label">
                        <div class="profile_information--asterisk">*</div>
                        <span i18n="@@ProfileInformation-Date-Of-Issue">Date Of Issue</span>:
                    </div>
                    <div class="profile_information--input-wrapper">
                        <ngbd-datepicker-i18n>
                            <input class="profile_information--input"
                            placeholder="DD/MM/YYYY"
                            #d="ngbDatepicker"
                            ngbDatepicker
                            [navigation]="'arrows'"
                            (click)="d.toggle()"
                            formControlName="dateOfIssue"/>
                            <fa-icon [icon]="faCalendar"></fa-icon>
                            <div *ngIf="docsForm.get('dateOfIssue').invalid || docsForm.get('dateOfIssue').touched">
                                <div *ngIf="docsForm.get('dateOfIssue').errors?.required" class="profile_information--error-message" i18n="@@ProfileInformation-Date-Of-Issue-required">Date Of Issue required</div>
                            </div>
                        </ngbd-datepicker-i18n> 
                    </div>
                </div>
                <div class="profile_information--input-container">
                    <div class="profile_information--input-label">
                        <div class="profile_information--asterisk">*</div>
                        <span i18n="@@ProfileInformation-Date-Of-Expiry">Date of Expiry</span>:
                    </div>
                    <div class="profile_information--input-wrapper">
                        <ngbd-datepicker-i18n>
                            <input class="profile_information--input"
                            placeholder="DD/MM/YYYY"
                            #dateOfExpiry="ngbDatepicker"
                            ngbDatepicker
                            [navigation]="'arrows'"
                            (click)="dateOfExpiry.toggle()"
                            formControlName="dateOfExpiry"/>
                            <fa-icon [icon]="faCalendar"></fa-icon>
                            <div *ngIf="docsForm.get('dateOfExpiry').invalid || docsForm.get('dateOfExpiry').touched">
                                <div *ngIf="docsForm.get('dateOfExpiry').errors?.required" class="profile_information--error-message" i18n="@@ProfileInformation-Date-Of-Expiry-required">Date of Expiry required</div>
                            </div>
                        </ngbd-datepicker-i18n>
                    </div>
                </div>
                <div class="profile_information--input-container" *ngIf="docsForm.get('docType').value === docType.Passport || docsForm.get('docType').value === docType.IdCard">
                    <div class="profile_information--input-label">
                        <span i18n="@@ProfileInformation-primary">Primary</span>:
                    </div>
                    <div class="profile_information--input-checkbox">
                        <input type="checkbox" formControlName="primary"/>
                    </div>
                </div>
            </div>
        </div>
       <div class="profile_information--content">
            <div class="profile_information--sub-header" i18n="@@ProfileInformation-Documents">
                Documents
            </div>
            <div class="profile_information--add-new-container">
                <button type="button"  (click)="addDocument(newDoc)" [disabled]="docsForm.invalid || !isEditable"
                        class="small-button profile_information--add-button" i18n="@@ProfileInformation-Add-New">Add New</button>
            </div>
            <table>
                <thead class="profile_information--table-thead">
                    <tr>
                        <th i18n="@@ProfileInformation-DocumentType">Document Type</th>
                        <th i18n="@@ProfileInformation-Number">Number</th>
                        <th i18n="@@ProfileInformation-DateOfIssue">Date Of Issue</th>
                        <th i18n="@@ProfileInformation-DateOfExpiry">Date Of Expiry</th>
                        <th i18n="@@ProfileInformation-Nationality">Nationality</th>
                        <th i18n="@@ProfileInformation-Gender">Gender</th>
                        <th i18n="@@ProfileInformation-Country">Country</th>
                        <th i18n="@@ProfileInformation-IssuingState">Issuing State</th>
                        <th i18n="@@ProfileInformation-primary">Primary</th>
                        <th i18n="@@ProfileInformation-Edit">Edit</th>
                        <th i18n="@@ProfileInformation-Delete">Delete</th>
                    </tr>
                </thead>
                <tbody class="profile_information--table-tbody">
                    <tr *ngFor="let document of documents.controls; let i = index" [formGroupName]="i">
                        <td>{{document.get('docType').value}} </td>
                        <td>{{document.get('number').value}}</td>
                        <td>{{document.get('dateOfIssue').value?.day}}/{{document.get('dateOfIssue').value?.month}}/{{document.get('dateOfIssue').value?.year}}</td>
                        <td>{{document.get('dateOfExpiry').value?.day}}/{{document.get('dateOfExpiry').value?.month}}/{{document.get('dateOfExpiry').value?.year}}</td>
                        <td>{{document.get('nationality').value}}</td>
                        <td>{{document.get('gender').value}}</td>
                        <td>{{document.get('country').value}}</td>
                        <td>{{document.get('codeOfIssuingState').value}}</td>
                        <td><span *ngIf="document.get('primary').value" i18n="@@ProfileInformation-Yes">Yes</span>
                            <span *ngIf="!document.get('primary').value " i18n="@@ProfileInformation-No">No</span>
                        </td>
                        <td><button [disabled]="!isEditable" [class.disable-button]="!isEditable" class="mat-stroked-button" (click)="editDocument(i)" i18n="@@ProfileInformation-Edit">Edit</button></td>
                        <td><button [disabled]="!isEditable" [class.disable-button]="!isEditable" class="mat-stroked-button" (click)="deleteDocument(i)" i18n="@@ProfileInformation-Delete">Delete</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
