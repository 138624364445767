import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WithSubscriptionComponent } from '@sabstravtech/obtservices/angular';
import { LightningModalTypes } from '../../../../vendor/classes/modal-types.enum';

@Component({
    selector: 'app-modal-branded-fare',
    templateUrl: './modal-branded-fare.component.html'
})
export class ModalBrandedFareComponent extends WithSubscriptionComponent {
    public flights = null;
    constructor(public activeModal: NgbActiveModal) {
        super();
    }
}

LightningModalTypes.ModalBrandedFareComponent.component = ModalBrandedFareComponent;
