<ng-template #rt let-r="result">
  <span [innerHTML]="r.email"></span>
</ng-template>
<div class="become_user_modal_body">
  <div class="become_user_container flex-column-mobile">
    <!-- Display List of Companies and once retrieved, list of users for each office -->
    <!-- Company and office list -->
    <div class="company_list">
      <div class="">
        <i class="ico-search combo-box-icon" aria-hidden="true"></i>
        <input
          type="text"
          class="input-box"
          [(ngModel)]="companySearchString"
          (ngModelChange)="filterCompanies($event)"
          placeholder="Search by company name..." />
      </div>
      <div class="companies">
        <div class="company" *ngFor="let company of filteredCompanyList">
          <div
            class="company_title"
            (click)="openOffices(company)"
            tabindex="0"
            role="button"
            (keydown.enter)="openOffices(company)">
            {{ company.name }}
            <i
              *ngIf="openOfficeCompany && openOfficeCompany.id === company.id; else showAddIco"
              class="ico ico-minus"></i>
            <ng-template #showAddIco>
              <i class="ico ico-add"></i>
            </ng-template>
          </div>
          <div class="offices">
            <!-- <i *ngIf='company.loadingOffices'
               class='button_spinner'></i> -->
            <div
              *ngIf="
                company?.offices?.length > 0 &&
                openOfficeCompany &&
                openOfficeCompany.id === company.id
              "
              class="office_list">
              <div
                class="office"
                *ngFor="let office of company.offices"
                tabindex="0"
                (click)="loadUsers(office)"
                role="button"
                (keydown.enter)="loadUsers(office)">
                {{ office.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- List of Users -->
    <div class="user_list">
      <div *ngIf="isLoading">
        <i class="button_spinner"></i>
      </div>

      <div *ngIf="!isLoading && errorMessage">
        <span>{{ errorMessage }}</span>
      </div>

      <div *ngIf="!isLoading && (!userList || userList?.length === 0)" class="flex">
        <input
          placeholder="Search user by email across all companies..."
          class="combo-box input-box book-as__input"
          id="typeahead-basic"
          type="text"
          [(ngModel)]="findUserModel"
          (selectItem)="selectUser($event.item)"
          [resultFormatter]="formatter"
          [inputFormatter]="formatter"
          [ngbTypeahead]="findUser"
          [resultTemplate]="rt"
          [disabled]="userList?.length > 0" />
        <div *ngIf="isSearchUserCanBookForLoading | async" class="my-bookings-search__loader">
          <i class="button_spinner"></i>
        </div>
      </div>
      <div *ngIf="!isLoading && userList?.length > 0">
        <button
          type="button"
          class="small-button"
          i18n="@@ResetSelectedCompany"
          (click)="resetCompany()">
          Reset selected company
        </button>
        <!-- !doesnt exist yet -->
        <div *ngIf="limited" class="limited">
          <i class="ico-info"></i
          ><span i18n="@@becomeUserModalThisOffice">This office has more than</span> {{ total }}
          <span i18n="@@approversModalThisOffice2"
            >users. Please use the column filters to refine your search to be more specific</span
          >.
        </div>
        <div class="user-top">
          <div class="user_email">
            <label
              class="header-label become-user-label"
              i18n="@@becomeUserEmail"
              for="become_email"
              >Email</label
            >
            <div class="search_container_no_width">
              <i class="ico-search combo-box-icon" aria-hidden="true"></i>
              <input
                type="text"
                class="input-box"
                id="become_email"
                [(ngModel)]="userEmailSearchString"
                (ngModelChange)="filterUsers()"
                i18n-placeholder
                placeholder="Search by email..." />
            </div>
          </div>
          <div class="user_forename">
            <label
              class="header-label become-user-label"
              i18n="@@becomeUserForename"
              for="become_forename"
              >Forename</label
            >
            <div class="search_container_no_width">
              <i class="ico-search combo-box-icon" aria-hidden="true"></i>
              <input
                type="text"
                class="input-box"
                id="become_forename"
                [(ngModel)]="userForenameSearchString"
                (ngModelChange)="filterUsers()"
                i18n-placeholder
                placeholder="Search by forename..." />
            </div>
          </div>
          <div class="user_surname">
            <label
              class="header-label become-user-label"
              i18n="@@becomeUserSurname"
              for="become_surname"
              >Surname</label
            >
            <div class="search_container_no_width">
              <i class="ico-search combo-box-icon" aria-hidden="true"></i>
              <input
                type="text"
                class="input-box"
                id="become_surname"
                [(ngModel)]="userSurnameSearchString"
                (ngModelChange)="filterUsers()"
                i18n-placeholder
                placeholder="Search by surname..." />
            </div>
          </div>
          <!-- <button class='small-button reset-filter-button'
                  (click)='resetUserFilters()'
                  role="button"
                  i18n="@@becomeUserModalReset"> Reset </button> -->
        </div>

        <div class="users" id="users" tabindex="0">
          <div
            class="user"
            *ngFor="let user of filteredUserList"
            (click)="selectUser(user)"
            role="button"
            (keydown.enter)="selectUser(user)"
            tabindex="0">
            <div class="user_email">{{ user.email }}</div>
            <div class="user_forename">{{ user.forename }}</div>
            <div class="user_surname">{{ user.surname }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="small-button small-button--no" i18n="@@becomeUserCancel">
    Cancel
  </button>
  <button type="button" id="button_ok" class="small-button" i18n="@@becomeUserOk">Ok</button>
</div>

