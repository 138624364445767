<div [ngClass]="{'wellbeing_padding' : wellBeing && riskAlerts?.length}">
     <div class="risk-alerts-header"
          *ngIf="riskAlerts?.length; else showRiskMinimumLevel">
          <h4><span id="current-risk-title" i18n="@@CurrentRiskLevel">Current Risk Level</span><span id="country-and-risk">: {{fullCountryName | translate}} - {{highestRiskLevel 
          | translate}}</span></h4>
          <span class="expanded_toggle_container"
                (click)="expanded = !expanded"
                (keydown.enter)="expanded = !expanded"
                role="button"
                tabindex="0"
                attr.aria-expanded="{{expanded}}"
                attr.aria-label="{{expanded ? 'Hide ' + (text | translate) : 'Show' + (text | translate)}}">
               <h4 class="expanded_toggle">
                    <span i18n="@@searchToggleState_hide" id="hide-risk" *ngIf="expanded">Hide</span>
                    <span i18n="@@searchToggleState_show" id="show-risk" *ngIf="!expanded">Show</span>
                    <span>&nbsp;{{ text| translate }}</span>
               </h4><i [ngClass]="expanded ? 'ico-tri-up' : 'ico-tri-down'" aria-hidden="true"></i>
          </span>
     </div>

     <ng-template #showRiskMinimumLevel>
          <h4 *ngIf="highestRiskLevel"><span id="current-risk-title" i18n="@@CurrentRiskLevel">Current Risk Level</span><span id="country-and-risk">: {{fullCountryName}} - {{highestRiskLevel}}</span></h4>
     </ng-template>
     <ng-container *ngIf="riskAlerts?.length && expanded">
          <div class="flex-row-container risk-filter-container">
               <label for="risk-alert-search"><span class="sr-only">Risk Alerts&nbsp;</span>Search:</label>
               <input (keydown)="filterAlerts($event)" placeholder="Enter search terms..." id="risk-alert-search"/>
          </div>
          <div class="risk-alerts-container"
               [ngClass]="{'full_container' : fullyExpanded}">
               <div *ngFor="let alert of filteredRiskAlerts; let i = index"
                    class="flex-row-container risk-alert"
                    (click)="showDetails(alert)">
                    <div class="warning-block"
                         [ngClass]="{'warning-block__moderate' : alert.risk === 'Medium', 'warning-block__extreme' : alert.risk === 'Extreme', 'warning-block__low' : alert.risk === 'Low', 'warning-block__high' : alert.risk === 'High'}">
                    </div>
                    <b>{{alert.risk}}</b>
                    <span id="alert-title">&nbsp;-&nbsp;{{alert.title}}</span>
                    <button class="close-alert-button"
                            (click)="$event.stopPropagation(); removeAlert(i)"
                            [attr.aria-label]="'Close ' + alert.title + ' risk alert'">
                         X
                    </button>

               </div>
          </div>
          <span class="expanded_toggle_container full_expanded_toggle"
                (click)="fullyExpanded = !fullyExpanded"
                (keydown.enter)="fullyExpanded = !fullyExpanded"
                role="button"
                tabindex="0"
                attr.aria-expanded="{{fullyExpanded}}"
                attr.aria-label="{{fullyExpanded ? 'Contract' : 'Expand'}}">
               <h4 class="expanded_toggle">
                    <span i18n="@@fullyExpandedContract" *ngIf="fullyExpanded" id="contract">Contract</span>
                    <span i18n="@@fullyExpandedExpand" *ngIf="!fullyExpanded" id="expand">Expand</span>
               </h4>
               <i [ngClass]="fullyExpanded ? 'ico-tri-up' : 'ico-tri-down'" aria-hidden="true"></i>
          </span>
     </ng-container>
     <!-- *ngIf="isLoading | async" -->
     <div class="loading-container"
          *ngIf="isLoading | async">
          <i class="button_spinner"></i> <span class="loading-message" id="loading">Loading...</span>
     </div>
</div>