import { Component, OnInit } from '@angular/core';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RouteHappyResults } from '@sabstravtech/obtservices/base';

@Component({
  selector: 'app-routehappy-flight-modal',
  templateUrl: './routehappy-flight-modal.component.html',
  styleUrls: ['./routehappy-flight-modal.component.scss']
})
export class RoutehappyFlightModalComponent implements OnInit {
  results: RouteHappyResults = null;
  constructor(
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
  }
}

LightningModalTypes.RoutehappyFlightModalComponent.component = RoutehappyFlightModalComponent;

