<div cdkTrapFocus aria-live="assertive">
    <div class="modal-header">
        <h3 class="modal-title" i18n="@@AddNewPaymentCard" id="modal-header">
            Add New Payment Card
        </h3>
        <button
            type="button"
            class="close"
            aria-label="Close"
            ngbAutoFocus
            (click)="onCancel()">
            <span aria-hidden="true"><i class="ico-cancel modal_exit_cross"></i></span>
        </button>
    </div>
    <div class="modal-body modal_body_ticket" >
        <div class="card-label-container">
            <div class="label-wrapper">
                <span i18n="@@CardLabel">Card Label</span>:
                <div class="asterisk">*</div>
            </div>
            <div class="input-wrapper">
                <input [(ngModel)]="label"/>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button"
                id="button_ok"
                class="small-button"
                aria-label="Ok"
                i18n-aria-label
                aria-live="false"
                [disabled]="!label"
                (click)="submit()"
                (keydown.enter)="submit()"
                (keydown.space)="submit()"
                i18n="@@Ok">
            Ok
        </button>
    </div>
</div>
