import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'switchDistanceFormat',
})
export class switchDistanceFormatPipe implements PipeTransform {

  transform(values, displayType, isMilesOnly: false): string {
    const distance = !isMilesOnly ? values.distance: values;
    const type = !isMilesOnly ? values.distanceType : 'M';
    let newDistance = distance;
    if(type === 'M' && displayType === 'Kilometers'){
      newDistance = distance * 1.609;
    } else if(type === 'K' && displayType === 'Miles'){
      newDistance = distance / 1.609;
    } else if(displayType === 'Meters'){
      if(type === 'M'){
        newDistance = distance * 1609;
      }else if(type === 'K'){
        newDistance = distance * 1000;
      }
    }
    return newDistance;
  }
}
