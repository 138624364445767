import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import {
  RailJourneyOption,
  RailJourneyFare,
  RailClass,
  RailSearchJourneyType,
  EnterpriseSearchService,
  ServiceType,
  WithSubscriptionComponent,
  HelperRoutines,
  RailProvider
} from '@sabstravtech/obtservices/angular';
import { flatten } from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-trainline-eu-item',
  templateUrl: './trainline-eu-item.component.html',
  styleUrls: ['./trainline-eu-item.component.scss']
})
export class TrainlineEuItemComponent extends WithSubscriptionComponent implements OnInit, OnChanges {
  @Input() item: RailJourneyOption;
  @Input() type: RailSearchJourneyType = RailSearchJourneyType.SingleJourney;
  @Input() sourceId: number;
  @Input() hideNonSelected: boolean = false;
  @Input() isInbound: boolean = false;
  @Input() isExchangeSearch: boolean = false;
  @Input() isDualSingleReturn: boolean = false;
  @Input() clearOutbound: boolean = false;
  @Output() fetchInboundTickets: EventEmitter<{
    journeyHash: string;
    chosenFare: RailJourneyFare[];
  }> = new EventEmitter();
  @Output() selectedExchangeFare: EventEmitter<{
    fare: RailJourneyFare;
    detail: RailJourneyOption;
    isInbound: boolean;
  }> = new EventEmitter();
  cheapestSecondClassTicket: RailJourneyFare[];
  cheapestFirstClassTicket: RailJourneyFare[];
  showDetail: boolean = false;
  operator: string = '';
  operatorCode: string = '';
  direct = false;
  RailJourneyType = RailSearchJourneyType;
  searchParams = this.searchService.searches[ServiceType.Rail];
  display: boolean = true;
  transport: string;
  timetable: string;
  isCancelled: boolean;
  selectedRail: RailJourneyFare[] = [];
  
  constructor(private searchService: EnterpriseSearchService, private helpers: HelperRoutines) {
    super();
  }

  ngOnInit(): void {

    this.isCancelled = !!this.item.journeyLegs.find(leg => leg.cancelled);

    this.subscribe(this.searchParams.selectedTicketSelected, ticket => {
      if (ticket && !this.isInbound) {
        // try to find the mathcing fare
        let found = false;
        for (let ticket of Object.values(this.item.dualSingleJourneyFares)) {
          // console.log(`+++ ${ticket} | ${this.searchParams.selectedTicket.fare.fareHash} +++`)
          let journeyFares: RailJourneyFare[] = Object.values(ticket) as RailJourneyFare[];
          for (let ticket of journeyFares) {
            if (ticket?.fareHash === this.searchParams.selectedTicket.fare.fareHash) {
              found = true;
              break;
            }
          }
        }
        this.display = found;
      } else {
        this.display = true;
      }
    });
    const providers = this.item.providers;
    switch (this.type) {
      case RailSearchJourneyType.SingleJourney:
        this.cheapestSecondClassTicket = this.getCheapestClassTicket(
          this.item.singleJourneyFares,
          RailClass.Standard,
          providers
        );
        this.cheapestFirstClassTicket = this.getCheapestClassTicket(
          this.item.singleJourneyFares,
          RailClass.First,
          providers
        );
        break;
      case RailSearchJourneyType.ReturnJourney:
        this.cheapestSecondClassTicket = this.getCheapestClassTicket(
          this.item.dualSingleJourneyFares ?? this.item.returnJourneyFares,
          RailClass.Standard,
          providers
        );
        this.cheapestFirstClassTicket = this.getCheapestClassTicket(
          this.item.dualSingleJourneyFares ?? this.item.returnJourneyFares,
          RailClass.First,
          providers
        );
        break;
    }

    this.operator = this.getOperator();
    this.operatorCode = this.getOperatorCode();
    this.timetable = this.getTimetable();
    this.transport = this.getTransport();
    this.direct = this.isDirect(this.item);
  }

  ngOnChanges(){
    if(this.clearOutbound){
      this.selectedRail = [];
    }
  }

  getTimetable(): string {
    return this.item.journeyLegs[0]?.timetableId;
  }

  getTransport(): string {
    return this.item.journeyLegs[0]?.transportDesignation;
  }

  getOperator(): string {
    return this.item.journeyLegs[0]?.operator;
  }

  getOperatorCode(): string {
    const fullCode = this.item.journeyLegs[0]?.operatorCode;

    if (fullCode) {
      const split = fullCode.split(':');
      if (split[2]) {
        return split[2].toLowerCase();
      }
    }

    return 'Unknown';
  }

  calculateDuration(item: RailJourneyOption): string {
    let duration = 0;
    const depMoment = moment(item.departDateTime, 'YYYYMMDDHHmm');
    const arrMoment = moment(item.arriveDateTime, 'YYYYMMDDHHmm');

    const diff = depMoment.diff(arrMoment, 'minutes');
    duration += diff;

    return Math.abs(duration).toString();
  }

  isDirect(item: RailJourneyOption) {
    return item.changes === '0' ? true : false;
  }

  getCheapestClassTicket(fares: any, type: RailClass, providers: RailProvider[]): RailJourneyFare[] {
    console.log('Fares: ', fares);
    let cheapests: RailJourneyFare[] = [];
    const convertedFares: any[] = flatten(Object.values(fares));
    console.log(convertedFares);
    providers.forEach(provider => {
      let providerCheapest: RailJourneyFare = null;
      for (let fare of convertedFares) {
        if (fare?.class === type && provider.providerId === fare?.providerId && (!providerCheapest || fare?.price < providerCheapest?.price)) {
          providerCheapest = fare;
        }
      }
      cheapests.push(providerCheapest);
    });
    return cheapests;
  }

  async toggleFare(chosenFare: RailJourneyFare[]): Promise<void> {
    console.log(chosenFare);
    this.selectedRail = chosenFare;
    const selectedFare = chosenFare[0];
    const multiFares = chosenFare.map(fare => fare.identifiers.fareIdentifier);
    // push second leg inhere inastead  fareIdentifier
    if (this.isExchangeSearch) {
      this.selectedExchangeFare.emit({
        fare: chosenFare[0],
        detail: this.item,
        isInbound: this.isInbound
      });
    } else if (this.type === RailSearchJourneyType.SingleJourney) {
      // add straight to the basket
      this.searchParams.selectedMultiFares.outbound = multiFares;
      this.selectedRail = [];
      await this.searchParams.selectTicket(
        'outbound',
        <any>this.item,
        <any>selectedFare,
        this.sourceId.toString(),
        'singleJourneyFares',
        false,
        false,
        true
      );
    } else if (!this.isInbound) {
      this.searchParams.selectedMultiFares.outbound = multiFares;
      await this.searchParams.selectTicket(
        'outbound',
        <any>this.item,
        <any>selectedFare,
        this.sourceId.toString(),
        'dualSingleJourneyFares',
        false,
        false,
        true
      );
      console.log(this.searchParams.selectedTicket);

      if (this.searchParams.selectedTicket) {
        this.fetchInboundTickets.emit({ journeyHash: this.item.journeyHash, chosenFare });
      } else {
        this.fetchInboundTickets.emit(null);
      }
    } else {
      console.log('here');
      selectedFare['selectable'] = true;
      this.searchParams.selectedMultiFares.inbound = multiFares;
      await this.searchParams.selectTicket(
        'inbound',
        <any>this.item,
        <any>selectedFare,
        this.sourceId.toString(),
        'dualSingleJourneyFares',
        false,
        false,
        true
      );
      this.fetchInboundTickets.emit(null); // ? clear the inbound as they are dependant on the outbound selection, I think
    }
  }

  removeTimeZone(date: string) {
    return this.helpers.sanitiseDateTime(date);
  }

  getTotalPrice(rail: RailJourneyFare[]){
    return rail?.reduce((price, ra) =>{
      price += ra.price
      return price
    }, 0)
  }
}

