<div class="tabs_container_irlDetails" aria-live="assertive">
  <div class="tabsHeader" role="tablist">
    <div
      class="tab_base"
      [ngClass]="{ tab_select: selectedTab === TabNames.FareDetails }"
      (click)="selectTab(TabNames.FareDetails)"
      (keydown.enter)="selectTab(TabNames.FareDetails)"
      tabindex="0"
      role="tab"
      i18n="@@itemDetailsFaredetails">
      Fare Details
    </div>
    <div
      class="tab_base"
      [ngClass]="{ tab_select: selectedTab === TabNames.RailLegTimes }"
      (keydown.enter)="selectTab(TabNames.RailLegTimes)"
      (click)="selectTab(TabNames.RailLegTimes)"
      tabindex="0"
      role="tab"
      i18n="@@itemDetailsRailtLegTimes">
      Show Details
    </div>
  </div>
  <div
    class="pane"
    *ngIf="selectedTab === TabNames.FareDetails"
    id="0"
    Title="TabNames.FareDetails">
    @for ( railJourney of providerRailJourney; track railJourney; let leg = $index) {
      @if(providerRailJourney.length > 1){
        <div class="type">legs {{leg+1}}</div>
      }
    <div *ngIf="railJourney?.second?.length && (!selectedFare[leg] || selectedFare[leg]?.class === ClassType.Standard)" class="type">2nd class</div>
    <div class="tickets">
      @for ( option of railJourney.second; track option;) {
        @if(!selectedFare[leg] || selectedFare[leg]?.fareHash === option.fareHash){
      <div class="ticket">

        <span class="leg">
          <div>
            <b>{{ option.class | translate }}:</b>
          </div>
          <label class="ticket-option-type">{{ option.type }}</label>
          <label class="ticket-option-type" *ngIf="option.identifiers?.fares[0]?.comfortClass">{{ option.identifiers?.fares[0]?.comfortClass}}</label>
          <a
            class="fare_rules_link"
            (click)="openFareRulesModal(option)"
            (keydown.enter)="openFareRulesModal(option)"
            tabindex="0"
            role="link">
            <i class="currency_info_icon"></i>
            <span i18n="@@irlTypeBrandedFareRules">Fare Rules</span></a
          ><br />
        </span>
        <span class="floatright">
          <div class="irl-price-details">
            <div class="star-container"></div>
            <div class="cost">
              {{ option.price | currency : option.currency : 'symbol' }}
              <span
                class="percentage-icon"
                tooltipClass="custom-tooltip"
                i18n-ngbTooltip
                *ngIf="discountCard"
                [ngbTooltip]="discountCard"
                >%</span
              >
            </div>
            <div class="star-container">
              <app-warning-tags [ticket]="option"></app-warning-tags>
            </div>
          </div>

          <button (click)="addToBasket(option, leg)" [disabled]="isCancelled" class="link">
            {{
              ((selectedFare[leg]?.fareHash === option.fareHash)
                ? 'Deselect'
                : 'Select'
              ) | translate
            }}
          </button>
          <br />
        </span>
        <button *ngIf="showDebug" (click)="alwaysLog(option)">
          Dump
        </button>

      </div>
      }
    }
    </div>
    <div *ngIf="railJourney?.first?.length && (!selectedFare[leg] || selectedFare[leg]?.class === ClassType.First)" class="type">1st class</div>
    <div class="tickets">
      @for ( option of railJourney.first; track option;) {
        @if(!selectedFare[leg] || selectedFare[leg]?.fareHash === option.fareHash){
      <div class="ticket" >

        <span class="leg">
          <div>
            <b>{{ option.class }}:</b>
          </div>
          <div>        
            <label class="ticket-option-type">{{ option.type }}</label>
            <label class="ticket-option-type" *ngIf="option.identifiers?.fares[0]?.comfortClass">{{ option.identifiers?.fares[0]?.comfortClass}}</label>
          </div>

          <a
            class="fare_rules_link"
            (click)="openFareRulesModal(option)"
            (keydown.enter)="openFareRulesModal(option)"
            tabindex="0"
            role="link">
            <i class="currency_info_icon"></i>
            <span i18n="@@irlTypeBrandedFareRules">Fare Rules</span></a
          ><br />
        </span>
        <span class="floatright">
          <div class="irl-price-details">
            <div class="cost">
              {{ option.price | currency : option.currency : 'symbol' }}
              <span
                class="percentage-icon"
                tooltipClass="custom-tooltip"
                i18n-ngbTooltip
                *ngIf="discountCard"
                [ngbTooltip]="discountCard"
                >%</span
              >
            </div>
            <div class="star-container">
              <app-warning-tags [ticket]="option"></app-warning-tags>
            </div>
          </div>

          <button (click)="addToBasket(option, leg)" [disabled]="option?.unavailable || isCancelled" class="link">
            {{
              ((selectedFare[leg]?.fareHash === option.fareHash)
                ? 'Deselect'
                : 'Select'
              ) | translate
            }}
          </button>
          <br />
        </span>
      </div>
    }
    }
    </div>
    }
  </div>
  <div class="pane" *ngIf="selectedTab === TabNames.RailLegTimes" id="1">
    <div class="leg_wrapper">
      <div class="leg_container">
        <app-irl-legs [legs]="legs" [trainNumber]="trainNumber"> </app-irl-legs>
      </div>
    </div>
  </div>
</div>
