import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetector } from '../../services/device-detector.service';
import { FormControl, FormGroup } from "@angular/forms";
import { Observable, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { isEqual } from 'lodash';
import {
  EnterpriseSearchService
} from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit {

  @Input() minDate: NgbDate;
  @Input() startDate: NgbDate;
  @Input() model: NgbDate;
  @Input() componentId: string;
  @Input() formControlName: string = 'name';
  @Input() class: string;
  @Input() disabled: boolean;
  @Input() required: boolean = false;
  @Input() maxDate: NgbDate;
  @Output() newDate: EventEmitter<NgbDate> = new EventEmitter();
  previousValue: { day: number; month: number; year: number; };
  mform: FormGroup = new FormGroup({
    name: new FormControl()
  });

  obs: Subscription;


  constructor(
    public deviceDetector: DeviceDetector,
    private searchService: EnterpriseSearchService
  ) {
  }

  ngOnInit() {
    this.obs = this.mform.valueChanges
      .pipe(distinctUntilChanged(isEqual), debounceTime(900))
      .subscribe(data => {
        if (data?.name) {
          this.emit(data[this.formControlName]);
        }
      });
  }

  emit(newDate: any): void {

    if (typeof newDate === 'string') return;  // ! - Prevents incomplete dates from being emitted - in conjunction with custom-ngb-date-parser-formatter

    let date: NgbDate;

    if (newDate.year) {
      date = <NgbDate>{
        day: newDate.day,
        month: newDate.month,
        year: newDate.year
      };
    } else {
      try {
        // check the length of the string 31/12/22 - 8 chars, 31/12/2022 - 10 chars
        const split = newDate.split('/');
        if (newDate.length === 8) {
          date = <NgbDate>{
            day: Number(split[0]),
            month: Number(split[1]),
            year: Number(split[2]) + 2000
          };
        } else if (newDate.length === 10) {
          date = <NgbDate>{
            day: Number(split[0]),
            month: Number(split[1]),
            year: Number(split[2])
          };
        }

      } catch (error) {
        console.error(error);
      }
    }
    this.newDate.emit(date);
  }

  dateSelect(event: NgbDate) {
    if (event) {
      this.searchService.loadingDate.next(true);
    
      setTimeout(() => {
        this.searchService.loadingDate.next(false);
      }, 900);
    }
  }
}