<div cdkTrapFocus
     aria-live="assertive">
  <div class="modal-header">
    <h3 class="modal-title"
        i18n="@@modalErrorTitle"
        id="modal-header">An Error Occurred!</h3>
    <button type="button"
            class="close"
            aria-label="An Error Occured Close"
            ngbAutoFocus
            i18n-aria-label
            (click)="activeModal.close('Cross click')">
      <span aria-hidden="true"><i class='ico-cancel modal_exit_cross'></i></span>
    </button>
  </div>
  <div class="modal-body">
    <!-- <p i18n="@@modalErrorErrorOccurred">An error has occurred:</p> -->

    <p><span i18n="@@modalErrorErrorMessage">Error Message</span>: <span class='red-alert'>{{error}}</span></p>
    <p *ngIf="!miError" i18n="@@modalErrorContactAdmin">Please try again, if this problem persists please contact an administrator.</p>
    <p *ngIf="miError" >
      <span i18n="@@modalErrorContactMiAdmin">Please contact your Travel Manager or your CTM Online Support team </span>
      <span id="support_email"> {{email}} </span><span id="support_number"> {{number}}.</span>
    </p>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="modal_button_okay"
            (click)="activeModal.close(true)"
            i18n="@@modalErrorOK">Ok</button>
  </div>
</div>