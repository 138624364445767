import { Pipe, PipeTransform } from '@angular/core';
import { TitleCasePipe } from '@angular/common';

@Pipe({
  name: 'flightrule',
})
export class FlightrulePipe implements PipeTransform {
  constructor(public titlecase: TitleCasePipe) {}

  transform(value: string): string {
    return this.titlecase.transform(value).replace(/ /g, '&nbsp;');
  }
}
