import { Pipe, PipeTransform } from '@angular/core';

const COUNTRIES = {
  AD: ['ALV'],
  AE: ['AAN', 'AUH', 'AZI', 'DHF', 'DXB', 'FJR', 'NHD', 'RKT', 'SHJ'],
  AF: [
    'BIN',
    'BST',
    'CCN',
    'DAZ',
    'FAH',
    'FBD',
    'GRG',
    'GZI',
    'HEA',
    'IMZ',
    'JAA',
    'KBL',
    'KDH',
    'KHT',
    'KUR',
    'KWH',
    'LQN',
    'MMZ',
    'MZR',
    'SBF',
    'SGA',
    'TII',
    'TQN',
    'UND',
    'URN',
    'URZ',
    'ZAJ',
  ],
  AG: ['ANU', 'BBQ'],
  AI: ['AXA'],
  AL: ['TIA'],
  AM: ['EVN', 'LWN'],
  AN: ['BON', 'CUR', 'EUX', 'SAB', 'SXM'],
  AO: [
    'AAZ',
    'ANL',
    'AZZ',
    'BUG',
    'CAB',
    'CAV',
    'CBT',
    'CEO',
    'CFF',
    'CNZ',
    'CTI',
    'DRC',
    'DUE',
    'GGC',
    'GXG',
    'JMB',
    'KNP',
    'LAD',
    'LBZ',
    'LUO',
    'LZM',
    'MEG',
    'MSZ',
    'NDD',
    'NDF',
    'NGV',
    'NOV',
    'NZA',
    'PBN',
    'PGI',
    'SDD',
    'SPP',
    'SVP',
    'SZA',
    'UAL',
    'UGO',
    'VHC',
    'VPE',
    'XGN',
  ],
  AQ: ['TNM'],
  AR: [
    'AEP',
    'AFA',
    'AOL',
    'APZ',
    'ARR',
    'BHI',
    'BRC',
    'CCT',
    'CLX',
    'CNQ',
    'CNT',
    'COC',
    'COR',
    'CPC',
    'CPG',
    'CRD',
    'CRR',
    'CSZ',
    'CTC',
    'CUT',
    'CVH',
    'CVI',
    'EHL',
    'ELO',
    'EMX',
    'EPA',
    'EQS',
    'EZE',
    'FMA',
    'FTE',
    'GGS',
    'GHU',
    'GNR',
    'GPO',
    'HOS',
    'IGB',
    'IGR',
    'ING',
    'IRJ',
    'JNI',
    'JSM',
    'JUJ',
    'LCM',
    'LCP',
    'LGS',
    'LHS',
    'LLS',
    'LMD',
    'LPG',
    'LUQ',
    'MCS',
    'MDQ',
    'MDX',
    'MDZ',
    'MJR',
    'MQD',
    'NEC',
    'NQN',
    'OES',
    'OLN',
    'ORA',
    'OVR',
    'OYA',
    'OYO',
    'PEH',
    'PMQ',
    'PMY',
    'PRA',
    'PRQ',
    'PSS',
    'PUD',
    'RCQ',
    'RCU',
    'RDS',
    'REL',
    'RES',
    'RGA',
    'RGL',
    'RHD',
    'RLO',
    'ROS',
    'ROY',
    'RSA',
    'RYO',
    'RZA',
    'SDE',
    'SFN',
    'SGV',
    'SLA',
    'SST',
    'SZQ',
    'TDL',
    'TTG',
    'TUC',
    'UAQ',
    'ULA',
    'USH',
    'UZU',
    'VCF',
    'VDM',
    'VDR',
    'VGS',
    'VLG',
    'VME',
  ],
  AS: ['FTI', 'OFU', 'PPG', 'TAV'],
  AT: [
    'ANT',
    'GRZ',
    'HOH',
    'INN',
    'KLU',
    'LNZ',
    'QLX',
    'SZG',
    'VDD',
    'VIE',
    'XGZ',
    'XJS',
    'XWC',
    'XWW',
    'ZRS',
  ],
  AU: [
    'AAB',
    'ABG',
    'ABH',
    'ABM',
    'ABX',
    'ADL',
    'ADO',
    'AGW',
    'AGY',
    'ALH',
    'AMT',
    'AMX',
    'ANZ',
    'ARM',
    'ARY',
    'ASP',
    'AUD',
    'AUU',
    'AVG',
    'AVV',
    'AWN',
    'AWP',
    'AXC',
    'AXL',
    'AYD',
    'AYL',
    'AYQ',
    'AYR',
    'BBE',
    'BCI',
    'BCK',
    'BCZ',
    'BDB',
    'BDD',
    'BDW',
    'BEE',
    'BEO',
    'BEU',
    'BFC',
    'BHQ',
    'BHS',
    'BHT',
    'BIP',
    'BIW',
    'BKP',
    'BKQ',
    'BLN',
    'BLS',
    'BLT',
    'BME',
    'BMP',
    'BNE',
    'BNK',
    'BOX',
    'BQB',
    'BQL',
    'BQW',
    'BRJ',
    'BRK',
    'BRT',
    'BSJ',
    'BTD',
    'BTX',
    'BUC',
    'BUY',
    'BVI',
    'BVW',
    'BVZ',
    'BWB',
    'BWQ',
    'BWT',
    'BWU',
    'BXF',
    'BXG',
    'BYX',
    'BZD',
    'BZP',
    'CAZ',
    'CBC',
    'CBI',
    'CBR',
    'CBX',
    'CBY',
    'CCL',
    'CCW',
    'CDA',
    'CDQ',
    'CDU',
    'CED',
    'CES',
    'CFH',
    'CFI',
    'CFP',
    'CFS',
    'CGV',
    'CIE',
    'CKI',
    'CLH',
    'CMA',
    'CMD',
    'CML',
    'CMQ',
    'CNB',
    'CNC',
    'CNJ',
    'CNS',
    'COB',
    'COJ',
    'COY',
    'CPD',
    'CQP',
    'CRB',
    'CRH',
    'CRJ',
    'CRY',
    'CSD',
    'CTL',
    'CTN',
    'CTR',
    'CUD',
    'CUG',
    'CUQ',
    'CUY',
    'CVC',
    'CVQ',
    'CWR',
    'CWT',
    'CWW',
    'CXQ',
    'CXT',
    'CYG',
    'CZY',
    'DAJ',
    'DBO',
    'DBY',
    'DDI',
    'DDN',
    'DFP',
    'DGD',
    'DGE',
    'DHD',
    'DKI',
    'DKV',
    'DLK',
    'DLV',
    'DMD',
    'DNB',
    'DNG',
    'DNM',
    'DNQ',
    'DOX',
    'DPO',
    'DRB',
    'DRD',
    'DRN',
    'DRR',
    'DRW',
    'DRY',
    'DVP',
    'DVR',
    'DXD',
    'DYA',
    'DYM',
    'DYW',
    'ECH',
    'EDD',
    'EDR',
    'EIH',
    'EKD',
    'ELC',
    'EMD',
    'ENB',
    'EPR',
    'ERB',
    'ETD',
    'EUC',
    'EVD',
    'EVH',
    'EXM',
    'FIK',
    'FIZ',
    'FLC',
    'FLS',
    'FLY',
    'FOS',
    'FOT',
    'FRB',
    'FSL',
    'FVL',
    'FVR',
    'GAH',
    'GBL',
    'GBP',
    'GBV',
    'GDD',
    'GEE',
    'GET',
    'GEX',
    'GFE',
    'GFF',
    'GFN',
    'GGD',
    'GIC',
    'GKL',
    'GLG',
    'GLI',
    'GLM',
    'GLT',
    'GLY',
    'GOO',
    'GOS',
    'GOV',
    'GPN',
    'GSC',
    'GSN',
    'GTE',
    'GTS',
    'GTT',
    'GUH',
    'GUL',
    'GVP',
    'GYL',
    'GYP',
    'HAP',
    'HAT',
    'HBA',
    'HCQ',
    'HGD',
    'HID',
    'HIG',
    'HIH',
    'HIP',
    'HIS',
    'HLL',
    'HLS',
    'HLT',
    'HLV',
    'HMG',
    'HNK',
    'HOK',
    'HPE',
    'HRN',
    'HRY',
    'HSM',
    'HTI',
    'HTU',
    'HUB',
    'HVB',
    'HWK',
    'HXX',
    'IDK',
    'IFF',
    'IFL',
    'IGH',
    'IKP',
    'INJ',
    'INM',
    'IRG',
    'ISA',
    'ISI',
    'IVR',
    'IVW',
    'JAB',
    'JAD',
    'JCK',
    'JFM',
    'JHQ',
    'JUN',
    'KAH',
    'KAX',
    'KBB',
    'KBD',
    'KBJ',
    'KBY',
    'KCE',
    'KCS',
    'KDB',
    'KDS',
    'KFG',
    'KGC',
    'KGI',
    'KGR',
    'KGY',
    'KKP',
    'KML',
    'KNI',
    'KNS',
    'KNX',
    'KOH',
    'KPP',
    'KPS',
    'KQB',
    'KRA',
    'KRB',
    'KRD',
    'KSV',
    'KTA',
    'KTR',
    'KUG',
    'KWM',
    'KYB',
    'KYF',
    'KYI',
    'LBH',
    'LCN',
    'LDC',
    'LDH',
    'LDW',
    'LEA',
    'LEL',
    'LER',
    'LFP',
    'LGE',
    'LGH',
    'LHG',
    'LIB',
    'LKD',
    'LLG',
    'LLL',
    'LLP',
    'LLT',
    'LNH',
    'LNO',
    'LOA',
    'LOC',
    'LRE',
    'LST',
    'LSY',
    'LTB',
    'LTP',
    'LTV',
    'LUT',
    'LUU',
    'LVO',
    'LWH',
    'LYT',
    'LZR',
    'MBB',
    'MBF',
    'MBH',
    'MBN',
    'MBW',
    'MCV',
    'MCY',
    'MEB',
    'MEL',
    'MET',
    'MFL',
    'MFP',
    'MGB',
    'MGT',
    'MGV',
    'MHC',
    'MHO',
    'MHU',
    'MIH',
    'MIM',
    'MIN',
    'MIY',
    'MIZ',
    'MJK',
    'MJP',
    'MKR',
    'MKV',
    'MKY',
    'MLR',
    'MLV',
    'MMG',
    'MMM',
    'MNE',
    'MNG',
    'MNQ',
    'MNV',
    'MNW',
    'MOO',
    'MOV',
    'MQA',
    'MQE',
    'MQL',
    'MQZ',
    'MRG',
    'MRL',
    'MRP',
    'MRT',
    'MRZ',
    'MSF',
    'MTD',
    'MTL',
    'MTQ',
    'MUP',
    'MUQ',
    'MVH',
    'MVK',
    'MVU',
    'MWB',
    'MWT',
    'MWY',
    'MXD',
    'MXQ',
    'MXU',
    'MYA',
    'MYI',
    'MYO',
    'NAA',
    'NAC',
    'NBH',
    'NBR',
    'NDS',
    'NGA',
    'NIF',
    'NKB',
    'NLF',
    'NLL',
    'NLS',
    'NMP',
    'NMR',
    'NOA',
    'NPP',
    'NRA',
    'NRG',
    'NRY',
    'NSA',
    'NSM',
    'NSO',
    'NSV',
    'NTL',
    'NTN',
    'NUB',
    'NUR',
    'NYN',
    'OAG',
    'OBA',
    'ODD',
    'ODL',
    'ODR',
    'OKB',
    'OKR',
    'OKY',
    'OLP',
    'ONG',
    'ONR',
    'ONS',
    'OOL',
    'OOM',
    'OOR',
    'OPI',
    'ORR',
    'ORS',
    'OXO',
    'OXY',
    'OYN',
    'PBO',
    'PBY',
    'PDE',
    'PDN',
    'PEA',
    'PEP',
    'PER',
    'PEY',
    'PHE',
    'PHJ',
    'PKE',
    'PKT',
    'PLO',
    'PMK',
    'PPI',
    'PPP',
    'PQQ',
    'PRD',
    'PTE',
    'PTI',
    'PTJ',
    'PUG',
    'RAM',
    'RBC',
    'RBS',
    'RBU',
    'RCM',
    'RCN',
    'RDA',
    'RHL',
    'RKY',
    'RLP',
    'RMA',
    'RMK',
    'ROH',
    'ROK',
    'RPB',
    'RPM',
    'RPV',
    'RRE',
    'RRV',
    'RSB',
    'RSE',
    'RTP',
    'RTS',
    'RTY',
    'RVT',
    'SBR',
    'SCG',
    'SFP',
    'SGO',
    'SGP',
    'SHQ',
    'SHT',
    'SHU',
    'SIO',
    'SIX',
    'SNB',
    'SNH',
    'SOI',
    'SQC',
    'SQP',
    'SRM',
    'SRN',
    'SRR',
    'SSK',
    'SSP',
    'STF',
    'STH',
    'SWB',
    'SWC',
    'SWH',
    'SWZ',
    'SXE',
    'SYD',
    'SYU',
    'TAN',
    'TAQ',
    'TBK',
    'TBL',
    'TCA',
    'TCW',
    'TDN',
    'TDR',
    'TEF',
    'TEM',
    'TGN',
    'THG',
    'TIS',
    'TKY',
    'TMW',
    'TPR',
    'TRO',
    'TSV',
    'TUM',
    'TWB',
    'TWN',
    'TWP',
    'TXR',
    'TYB',
    'TYG',
    'TYP',
    'UBB',
    'UBU',
    'UDA',
    'UEE',
    'UIR',
    'ULP',
    'UMR',
    'USL',
    'UTB',
    'UTD',
    'VCD',
    'VNR',
    'WAN',
    'WAU',
    'WAV',
    'WAZ',
    'WBA',
    'WDI',
    'WEI',
    'WEW',
    'WGA',
    'WGE',
    'WGT',
    'WGZ',
    'WHL',
    'WIN',
    'WIO',
    'WIT',
    'WKB',
    'WLA',
    'WLC',
    'WLL',
    'WLO',
    'WMB',
    'WME',
    'WND',
    'WNR',
    'WOG',
    'WOL',
    'WON',
    'WPK',
    'WRW',
    'WSY',
    'WUD',
    'WUN',
    'WWI',
    'WWY',
    'WYA',
    'WYN',
    'XCO',
    'XHW',
    'XHX',
    'XJG',
    'XMC',
    'XML',
    'XMY',
    'XRH',
    'XTG',
    'XTO',
    'XTR',
    'XZX',
    'YLG',
    'YNN',
    'YUE',
    'ZBL',
    'ZBO',
    'ZGL',
    'ZNE',
    'ZVG',
  ],
  AUS: ['JUR'],
  AW: ['AUA'],
  AZ: ['BAK', 'GYD', 'KVD', 'NAJ', 'ZXT'],
  BA: ['BNX', 'OMO', 'SJJ', 'TZL'],
  BB: ['BGI'],
  BD: [
    'BZL',
    'CGP',
    'CLA',
    'DAC',
    'IRD',
    'JSR',
    'KHL',
    'RAU',
    'RJH',
    'SAJ',
    'SDW',
    'SPD',
    'TKR',
    'ZHM',
    'ZYL',
  ],
  BE: [
    'ANR',
    'BRU',
    'CRL',
    'GNE',
    'KJK',
    'KNO',
    'LGG',
    'MWW',
    'OBL',
    'OST',
    'XHN',
    'ZAY',
    'ZYB',
    'ZYR',
    'ZYZ',
  ],
  BF: [
    'ARL',
    'BNR',
    'BOY',
    'DGU',
    'DIP',
    'DOR',
    'FNG',
    'OUA',
    'OUG',
    'PUP',
    'TEG',
    'TMQ',
    'TUQ',
    'XAR',
    'XBG',
    'XBO',
    'XDE',
    'XDJ',
    'XGA',
    'XGG',
    'XKA',
    'XKY',
    'XLU',
    'XNU',
    'XPA',
    'XSE',
    'XZA',
  ],
  BG: [
    'BOJ',
    'GOZ',
    'HKV',
    'JAM',
    'KDG',
    'PDV',
    'PVN',
    'ROU',
    'SLS',
    'SOF',
    'SZR',
    'TGV',
    'VAR',
    'VID',
  ],
  BH: ['BAH', 'GBQ', 'WGF'],
  BI: ['BJM', 'GID', 'KRE'],
  BJ: ['COO', 'DJA', 'KDC', 'NAE', 'PKO', 'SVF'],
  BM: ['BDA', 'NWU'],
  BN: ['BWN'],
  BO: [
    'APB',
    'ASC',
    'BJO',
    'BVK',
    'BVL',
    'BYC',
    'CAM',
    'CBB',
    'CEP',
    'CIJ',
    'GYA',
    'LPB',
    'MGD',
    'MHW',
    'MQK',
    'ORU',
    'POI',
    'PSZ',
    'PUR',
    'RBO',
    'RBQ',
    'REY',
    'RIB',
    'SBL',
    'SJB',
    'SJS',
    'SJV',
    'SNG',
    'SNM',
    'SRB',
    'SRD',
    'SRE',
    'SRJ',
    'SRZ',
    'TDD',
    'TJA',
    'VAH',
    'VLM',
    'VVI',
  ],
  BR: [
    'AAG',
    'AAI',
    'AAX',
    'AFL',
    'AIF',
    'AIR',
    'AJU',
    'ALQ',
    'ALT',
    'AMJ',
    'APQ',
    'APS',
    'APU',
    'APX',
    'APY',
    'AQA',
    'AQM',
    'ARS',
    'ARU',
    'ATM',
    'AUB',
    'AUX',
    'AXE',
    'BAT',
    'BAU',
    'BAZ',
    'BCR',
    'BDC',
    'BEL',
    'BFH',
    'BGV',
    'BGX',
    'BJP',
    'BMS',
    'BNU',
    'BPG',
    'BPS',
    'BQQ',
    'BRA',
    'BRB',
    'BSB',
    'BSS',
    'BVB',
    'BVH',
    'BVM',
    'BVS',
    'BYO',
    'BZC',
    'CAC',
    'CAF',
    'CAU',
    'CAW',
    'CBW',
    'CCI',
    'CCM',
    'CCQ',
    'CCX',
    'CDI',
    'CDJ',
    'CFB',
    'CFC',
    'CFO',
    'CGB',
    'CGH',
    'CGR',
    'CIZ',
    'CKO',
    'CKS',
    'CLN',
    'CLV',
    'CMC',
    'CMG',
    'CMP',
    'CMT',
    'CNF',
    'CNV',
    'CPQ',
    'CPU',
    'CPV',
    'CQA',
    'CQS',
    'CRQ',
    'CSS',
    'CSU',
    'CSW',
    'CTP',
    'CTQ',
    'CWB',
    'CXJ',
    'CZB',
    'CZS',
    'DIQ',
    'DMT',
    'DNO',
    'DOU',
    'ERM',
    'ERN',
    'ESI',
    'FBE',
    'FEC',
    'FEJ',
    'FEN',
    'FLB',
    'FLN',
    'FOR',
    'FRC',
    'GCV',
    'GDP',
    'GEL',
    'GIG',
    'GJM',
    'GMS',
    'GNM',
    'GPB',
    'GRP',
    'GRU',
    'GUJ',
    'GUZ',
    'GVR',
    'GYN',
    'HRZ',
    'IDO',
    'IGU',
    'IJU',
    'ILB',
    'IMP',
    'IOS',
    'IPG',
    'IPN',
    'IPU',
    'IRE',
    'ITA',
    'ITB',
    'ITE',
    'ITI',
    'ITN',
    'ITP',
    'ITQ',
    'ITR',
    'JCB',
    'JCM',
    'JCR',
    'JDF',
    'JDO',
    'JEQ',
    'JIA',
    'JLS',
    'JNA',
    'JOI',
    'JPA',
    'JPR',
    'JRN',
    'JTI',
    'JUA',
    'LAJ',
    'LAZ',
    'LBR',
    'LCB',
    'LDB',
    'LEC',
    'LEP',
    'LIP',
    'LOI',
    'LVB',
    'MAB',
    'MAO',
    'MBK',
    'MBZ',
    'MCP',
    'MCZ',
    'MEA',
    'MEU',
    'MGF',
    'MII',
    'MNX',
    'MOC',
    'MQH',
    'MTE',
    'MTG',
    'MVF',
    'MVS',
    'NAT',
    'NBV',
    'NNU',
    'NOK',
    'NQL',
    'NTM',
    'NVP',
    'NVT',
    'OAL',
    'OBI',
    'OIA',
    'OPS',
    'ORX',
    'OUS',
    'OYK',
    'PAV',
    'PBB',
    'PBQ',
    'PBV',
    'PBX',
    'PCS',
    'PDF',
    'PDR',
    'PET',
    'PFB',
    'PGG',
    'PHB',
    'PHI',
    'PIG',
    'PIN',
    'PIV',
    'PLL',
    'PLU',
    'PMG',
    'PMW',
    'PNB',
    'PNG',
    'PNZ',
    'POA',
    'POJ',
    'POO',
    'PPB',
    'PPY',
    'PSW',
    'PTO',
    'PTQ',
    'PVH',
    'PVI',
    'RAO',
    'RBB',
    'RBR',
    'RDC',
    'REC',
    'REZ',
    'RIA',
    'RIG',
    'ROO',
    'RRN',
    'RSG',
    'RVD',
    'RWS',
    'SBJ',
    'SDU',
    'SEI',
    'SFK',
    'SFV',
    'SJK',
    'SJL',
    'SJP',
    'SLZ',
    'SNZ',
    'SOD',
    'SQM',
    'SQX',
    'SQY',
    'SRA',
    'SSA',
    'SSO',
    'SSZ',
    'STM',
    'STZ',
    'SWM',
    'SXO',
    'SXX',
    'TBT',
    'TEC',
    'TFF',
    'TFL',
    'TGQ',
    'THE',
    'TLZ',
    'TMT',
    'TOW',
    'TRQ',
    'TSQ',
    'TUR',
    'TUZ',
    'TXF',
    'UBA',
    'UBT',
    'UDI',
    'UMU',
    'UNA',
    'URB',
    'URG',
    'VAG',
    'VAL',
    'VCP',
    'VDC',
    'VIA',
    'VIX',
    'VLP',
    'VOT',
    'XAP',
    'XIG',
    'ZFU',
    'ZMD',
  ],
  BS: [
    'ASD',
    'AXP',
    'BIM',
    'CAT',
    'CCZ',
    'CEL',
    'COX',
    'CRI',
    'CXY',
    'DCT',
    'ELH',
    'FPO',
    'GBI',
    'GGT',
    'GHB',
    'GHC',
    'GTC',
    'HBI',
    'IGA',
    'LGI',
    'MAY',
    'MHH',
    'MSK',
    'MYG',
    'NAS',
    'NET',
    'NSB',
    'PID',
    'PPO',
    'PWN',
    'RCY',
    'RSD',
    'SAQ',
    'SML',
    'SWL',
    'TBI',
    'TCB',
    'TYM',
    'TZN',
    'WTD',
    'WZY',
    'ZSA',
  ],
  BT: ['PBH'],
  BW: [
    'BBK',
    'FRW',
    'GBE',
    'GNZ',
    'HUK',
    'JWA',
    'KHW',
    'LOQ',
    'MUB',
    'ORP',
    'PKW',
    'SVT',
    'SWX',
    'SXN',
    'TBY',
    'TLD',
  ],
  BY: ['BQT', 'GME', 'GNA', 'MHP', 'MSQ', 'MVQ', 'VTB'],
  BZ: [
    'BCV',
    'BGK',
    'BZE',
    'CUK',
    'CYC',
    'CZH',
    'DGA',
    'INB',
    'MDB',
    'MZE',
    'ORZ',
    'PLJ',
    'PND',
    'SJX',
    'SPR',
    'SQS',
    'STU',
    'SVK',
    'TZA',
  ],
  CA: [
    'AKV',
    'CFQ',
    'CJH',
    'CXH',
    'DAS',
    'DUQ',
    'DVK',
    'GSL',
    'HNX',
    'ILF',
    'IUM',
    'KES',
    'KEW',
    'KIF',
    'KNV',
    'KNY',
    'LAK',
    'LRQ',
    'MSA',
    'NWP',
    'PIW',
    'QBC',
    'SSQ',
    'SUR',
    'SYF',
    'TIL',
    'TNS',
    'TUX',
    'WNN',
    'WPC',
    'WPL',
    'XBB',
    'XBE',
    'XBR',
    'XBW',
    'XCL',
    'XCM',
    'XDV',
    'XDW',
    'XDY',
    'XEA',
    'XFY',
    'XFZ',
    'XGL',
    'XGR',
    'XKO',
    'XKS',
    'XLB',
    'XLF',
    'XLJ',
    'XLM',
    'XMP',
    'XPK',
    'XPP',
    'XQU',
    'XRR',
    'XSI',
    'XTL',
    'YAA',
    'YAB',
    'YAC',
    'YAD',
    'YAE',
    'YAF',
    'YAG',
    'YAH',
    'YAJ',
    'YAL',
    'YAM',
    'YAQ',
    'YAR',
    'YAT',
    'YAU',
    'YAV',
    'YAW',
    'YAX',
    'YAY',
    'YAZ',
    'YBA',
    'YBB',
    'YBC',
    'YBD',
    'YBE',
    'YBF',
    'YBG',
    'YBH',
    'YBI',
    'YBJ',
    'YBK',
    'YBL',
    'YBM',
    'YBN',
    'YBO',
    'YBQ',
    'YBR',
    'YBS',
    'YBT',
    'YBV',
    'YBW',
    'YBX',
    'YBY',
    'YCA',
    'YCB',
    'YCC',
    'YCD',
    'YCE',
    'YCF',
    'YCG',
    'YCH',
    'YCI',
    'YCJ',
    'YCK',
    'YCL',
    'YCM',
    'YCN',
    'YCO',
    'YCP',
    'YCQ',
    'YCR',
    'YCS',
    'YCT',
    'YCU',
    'YCV',
    'YCW',
    'YCX',
    'YCY',
    'YCZ',
    'YDA',
    'YDB',
    'YDC',
    'YDE',
    'YDF',
    'YDG',
    'YDH',
    'YDI',
    'YDJ',
    'YDK',
    'YDL',
    'YDN',
    'YDO',
    'YDP',
    'YDQ',
    'YDR',
    'YDS',
    'YDT',
    'YDU',
    'YDV',
    'YDW',
    'YDX',
    'YED',
    'YEG',
    'YEK',
    'YEL',
    'YEM',
    'YEN',
    'YEP',
    'YER',
    'YET',
    'YEU',
    'YEV',
    'YEY',
    'YFA',
    'YFB',
    'YFC',
    'YFE',
    'YFG',
    'YFH',
    'YFJ',
    'YFL',
    'YFO',
    'YFR',
    'YFS',
    'YFX',
    'YGA',
    'YGB',
    'YGC',
    'YGE',
    'YGG',
    'YGH',
    'YGK',
    'YGL',
    'YGM',
    'YGN',
    'YGO',
    'YGP',
    'YGQ',
    'YGR',
    'YGS',
    'YGT',
    'YGV',
    'YGW',
    'YGX',
    'YGY',
    'YGZ',
    'YHA',
    'YHB',
    'YHC',
    'YHD',
    'YHE',
    'YHF',
    'YHG',
    'YHH',
    'YHI',
    'YHK',
    'YHM',
    'YHN',
    'YHO',
    'YHP',
    'YHR',
    'YHS',
    'YHT',
    'YHU',
    'YHY',
    'YHZ',
    'YIB',
    'YIF',
    'YIG',
    'YIK',
    'YIO',
    'YIV',
    'YJF',
    'YJN',
    'YJO',
    'YJP',
    'YJT',
    'YKA',
    'YKC',
    'YKD',
    'YKE',
    'YKF',
    'YKG',
    'YKI',
    'YKJ',
    'YKK',
    'YKL',
    'YKQ',
    'YKT',
    'YKU',
    'YKX',
    'YKY',
    'YKZ',
    'YLA',
    'YLB',
    'YLC',
    'YLD',
    'YLE',
    'YLF',
    'YLH',
    'YLJ',
    'YLL',
    'YLM',
    'YLO',
    'YLP',
    'YLQ',
    'YLR',
    'YLS',
    'YLT',
    'YLW',
    'YLX',
    'YLY',
    'YMA',
    'YMB',
    'YMC',
    'YMD',
    'YME',
    'YMF',
    'YMG',
    'YMI',
    'YMJ',
    'YML',
    'YMM',
    'YMN',
    'YMO',
    'YMP',
    'YMR',
    'YMT',
    'YMW',
    'YMX',
    'YNA',
    'YNC',
    'YND',
    'YNE',
    'YNF',
    'YNI',
    'YNK',
    'YNL',
    'YNM',
    'YNO',
    'YNP',
    'YNR',
    'YNS',
    'YOA',
    'YOC',
    'YOD',
    'YOE',
    'YOG',
    'YOH',
    'YOJ',
    'YOO',
    'YOP',
    'YOS',
    'YOW',
    'YOY',
    'YPA',
    'YPB',
    'YPC',
    'YPD',
    'YPE',
    'YPF',
    'YPG',
    'YPH',
    'YPI',
    'YPJ',
    'YPL',
    'YPM',
    'YPN',
    'YPO',
    'YPP',
    'YPQ',
    'YPR',
    'YPS',
    'YPT',
    'YPW',
    'YPX',
    'YPY',
    'YPZ',
    'YQA',
    'YQB',
    'YQC',
    'YQD',
    'YQE',
    'YQF',
    'YQG',
    'YQH',
    'YQI',
    'YQK',
    'YQL',
    'YQM',
    'YQN',
    'YQQ',
    'YQR',
    'YQS',
    'YQT',
    'YQU',
    'YQV',
    'YQW',
    'YQX',
    'YQY',
    'YQZ',
    'YRA',
    'YRB',
    'YRD',
    'YRE',
    'YRF',
    'YRG',
    'YRI',
    'YRJ',
    'YRL',
    'YRM',
    'YRN',
    'YRO',
    'YRQ',
    'YRR',
    'YRS',
    'YRT',
    'YRV',
    'YSA',
    'YSB',
    'YSC',
    'YSD',
    'YSE',
    'YSF',
    'YSG',
    'YSH',
    'YSI',
    'YSJ',
    'YSK',
    'YSL',
    'YSM',
    'YSN',
    'YSO',
    'YSP',
    'YSQ',
    'YSR',
    'YSS',
    'YST',
    'YSU',
    'YSV',
    'YSX',
    'YSY',
    'YSZ',
    'YTA',
    'YTB',
    'YTC',
    'YTD',
    'YTE',
    'YTF',
    'YTG',
    'YTH',
    'YTI',
    'YTJ',
    'YTK',
    'YTL',
    'YTM',
    'YTN',
    'YTO',
    'YTP',
    'YTQ',
    'YTR',
    'YTS',
    'YTT',
    'YTU',
    'YTX',
    'YTZ',
    'YUB',
    'YUD',
    'YUL',
    'YUT',
    'YUX',
    'YUY',
    'YVB',
    'YVC',
    'YVE',
    'YVG',
    'YVM',
    'YVO',
    'YVP',
    'YVQ',
    'YVR',
    'YVT',
    'YVV',
    'YVZ',
    'YWA',
    'YWB',
    'YWF',
    'YWG',
    'YWH',
    'YWJ',
    'YWK',
    'YWL',
    'YWM',
    'YWN',
    'YWO',
    'YWP',
    'YWQ',
    'YWR',
    'YWS',
    'YWY',
    'YXC',
    'YXD',
    'YXE',
    'YXF',
    'YXH',
    'YXI',
    'YXJ',
    'YXK',
    'YXL',
    'YXN',
    'YXP',
    'YXQ',
    'YXR',
    'YXS',
    'YXT',
    'YXU',
    'YXX',
    'YXY',
    'YXZ',
    'YYA',
    'YYB',
    'YYC',
    'YYD',
    'YYE',
    'YYF',
    'YYG',
    'YYH',
    'YYI',
    'YYJ',
    'YYL',
    'YYM',
    'YYN',
    'YYQ',
    'YYR',
    'YYT',
    'YYU',
    'YYW',
    'YYY',
    'YYZ',
    'YZA',
    'YZC',
    'YZD',
    'YZE',
    'YZF',
    'YZG',
    'YZH',
    'YZM',
    'YZP',
    'YZR',
    'YZS',
    'YZT',
    'YZU',
    'YZV',
    'YZW',
    'YZX',
    'ZAA',
    'ZAC',
    'ZBF',
    'ZBM',
    'ZEL',
    'ZEM',
    'ZFA',
    'ZFB',
    'ZFD',
    'ZFL',
    'ZFM',
    'ZFN',
    'ZFW',
    'ZGF',
    'ZGI',
    'ZGR',
    'ZGS',
    'ZHO',
    'ZHP',
    'ZIF',
    'ZJG',
    'ZJN',
    'ZKE',
    'ZKG',
    'ZLT',
    'ZMH',
    'ZMT',
    'ZNA',
    'ZNG',
    'ZNU',
    'ZOF',
    'ZPB',
    'ZPO',
    'ZQS',
    'ZRJ',
    'ZRR',
    'ZSJ',
    'ZSP',
    'ZST',
    'ZSW',
    'ZTB',
    'ZTM',
    'ZTS',
    'ZUC',
    'ZUM',
    'ZWL',
  ],
  CC: ['CCK'],
  CD: ['FBM', 'FIH', 'FKI', 'GOM', 'IKL', 'INO', 'KWZ', 'MNO', 'TSH'],
  CF: [
    'AIG',
    'BBT',
    'BBY',
    'BCF',
    'BEM',
    'BGF',
    'BGU',
    'BIV',
    'BMF',
    'BOP',
    'BOZ',
    'BSN',
    'BTG',
    'CRF',
    'GDA',
    'GDI',
    'IMO',
    'IRO',
    'KOL',
    'KWD',
    'NDL',
    'ODA',
    'ODJ',
    'RFA',
  ],
  CG: [
    'ANJ',
    'BOE',
    'BTB',
    'BZV',
    'DIS',
    'DJM',
    'EPN',
    'EWO',
    'FTX',
    'GMM',
    'ION',
    'KEE',
    'KMK',
    'KNJ',
    'LCO',
    'LKC',
    'MKJ',
    'MSX',
    'MUY',
    'NKY',
    'OKG',
    'OUE',
    'PNR',
    'SIB',
    'SOE',
  ],
  CH: [
    'ACH',
    'ACO',
    'BRN',
    'BSL',
    'EML',
    'GVA',
    'LUG',
    'SIR',
    'SMV',
    'ZBA',
    'ZRH',
  ],
  CI: [
    'ABJ',
    'ABO',
    'ASK',
    'BBV',
    'BDK',
    'BGG',
    'BQO',
    'BRZ',
    'BUU',
    'BXI',
    'BYK',
    'DIM',
    'DIV',
    'DJO',
    'DNC',
    'FEK',
    'GGN',
    'GGO',
    'HGO',
    'KEO',
    'KTC',
    'LKT',
    'MJC',
    'MOK',
    'OFI',
    'OGO',
    'SEO',
    'SPY',
    'TBX',
    'TGX',
    'TOZ',
    'TXU',
    'ZSS',
    'ZUE',
  ],
  CK: ['AIT', 'AIU', 'MGS', 'MHX', 'MOI', 'MUK', 'PYE', 'PZK', 'RAR'],
  CL: [
    'ANF',
    'ARI',
    'BBA',
    'CCH',
    'CCP',
    'CJC',
    'CNR',
    'COW',
    'CPO',
    'ESR',
    'FFU',
    'FRT',
    'GXQ',
    'IPC',
    'IQQ',
    'KNA',
    'LGR',
    'LOB',
    'LSC',
    'LSQ',
    'OVL',
    'PMC',
    'PNT',
    'PUQ',
    'PUX',
    'SCL',
    'SMB',
    'TLX',
    'TOQ',
    'TTC',
    'ULC',
    'VAP',
    'VLR',
    'WAP',
    'WCA',
    'WCH',
    'WPA',
    'WPR',
    'WPU',
    'YAI',
    'ZAL',
    'ZCO',
    'ZIC',
    'ZOS',
    'ZPC',
    'ZUD',
  ],
  CM: [
    'BFX',
    'BLC',
    'BPC',
    'BTA',
    'DLA',
    'DSC',
    'EBW',
    'FOM',
    'GOU',
    'GXX',
    'KBI',
    'KLE',
    'KOB',
    'MMF',
    'MVR',
    'NGE',
    'NKS',
    'NSI',
    'OUR',
    'TKC',
    'VCC',
    'YAO',
  ],
  CN: [
    'AAT',
    'ACX',
    'AKA',
    'AKU',
    'AOG',
    'AQG',
    'AVA',
    'AYN',
    'BAV',
    'BFU',
    'BHY',
    'BPX',
    'BSD',
    'CAN',
    'CGD',
    'CGO',
    'CGQ',
    'CHG',
    'CHW',
    'CIF',
    'CIH',
    'CKG',
    'CNI',
    'CSX',
    'CTU',
    'CZX',
    'DAT',
    'DAX',
    'DDG',
    'DGM',
    'DIG',
    'DLC',
    'DLU',
    'DNH',
    'DOY',
    'DSN',
    'DYG',
    'DZU',
    'ENH',
    'FOC',
    'FUD',
    'FUG',
    'FUO',
    'FYN',
    'GHN',
    'GOQ',
    'GYS',
    'HAK',
    'HEK',
    'HET',
    'HFE',
    'HGH',
    'HHA',
    'HJJ',
    'HLD',
    'HLH',
    'HMI',
    'HNY',
    'HRB',
    'HSC',
    'HSN',
    'HTN',
    'HUZ',
    'HYN',
    'HZG',
    'HZH',
    'INC',
    'IQM',
    'IQN',
    'JDZ',
    'JGN',
    'JGS',
    'JHG',
    'JIL',
    'JIU',
    'JJN',
    'JMU',
    'JNG',
    'JNZ',
    'JUZ',
    'JZH',
    'KCA',
    'KHG',
    'KHN',
    'KMG',
    'KOW',
    'KRL',
    'KRY',
    'KWE',
    'KWL',
    'LCX',
    'LHK',
    'LHW',
    'LIA',
    'LJG',
    'LLF',
    'LNJ',
    'LUM',
    'LUZ',
    'LXA',
    'LXI',
    'LYA',
    'LYG',
    'LYI',
    'LZD',
    'LZH',
    'LZO',
    'MDG',
    'MIG',
    'MXZ',
    'NAO',
    'NAY',
    'NDG',
    'NGB',
    'NKG',
    'NNG',
    'NNY',
    'NTG',
    'NZH',
    'PEK',
    'PNJ',
    'PVG',
    'PZI',
    'RUG',
    'SHA',
    'SHE',
    'SHF',
    'SHP',
    'SHS',
    'SIA',
    'SJW',
    'SWA',
    'SXJ',
    'SYM',
    'SYX',
    'SZO',
    'SZV',
    'SZX',
    'TAO',
    'TCG',
    'TEN',
    'TGO',
    'TNA',
    'TNH',
    'TSN',
    'TXN',
    'TYN',
    'URC',
    'UYN',
    'WEF',
    'WEH',
    'WHU',
    'WNZ',
    'WUA',
    'WUH',
    'WUS',
    'WUX',
    'WUZ',
    'WXN',
    'XEN',
    'XFN',
    'XIC',
    'XIL',
    'XIN',
    'XIY',
    'XMN',
    'XNN',
    'XNT',
    'XTA',
    'XTF',
    'XUZ',
    'YBP',
    'YIH',
    'YIN',
    'YIW',
    'YLN',
    'YNJ',
    'YNT',
    'YNZ',
    'YUA',
    'ZAS',
    'ZAT',
    'ZAX',
    'ZBD',
    'ZBZ',
    'ZCA',
    'ZCP',
    'ZCU',
    'ZEF',
    'ZGC',
    'ZGN',
    'ZHA',
    'ZIY',
    'ZMY',
    'ZTI',
    'ZUH',
    'ZYI',
    'ZYK',
  ],
  CO: [
    'ACD',
    'ACL',
    'ACM',
    'ACR',
    'ADN',
    'ADZ',
    'AFI',
    'API',
    'APO',
    'ARF',
    'ARO',
    'ARQ',
    'AUC',
    'AXM',
    'AYA',
    'AYC',
    'AYG',
    'AYI',
    'AZT',
    'BAC',
    'BAQ',
    'BGA',
    'BHF',
    'BOG',
    'BSC',
    'BUN',
    'CAQ',
    'CCO',
    'CIM',
    'CJD',
    'CLO',
    'COG',
    'CPB',
    'CPL',
    'CQT',
    'CRC',
    'CSR',
    'CTG',
    'CUC',
    'CUI',
    'CUO',
    'CVE',
    'CZU',
    'DZI',
    'EBG',
    'ECO',
    'ECR',
    'EJA',
    'ELB',
    'ELJ',
    'EOH',
    'EUO',
    'EYP',
    'FLA',
    'GAA',
    'GCA',
    'GGL',
    'GIR',
    'GMC',
    'GPI',
    'GRA',
    'HRR',
    'HTZ',
    'IBE',
    'IGO',
    'IPI',
    'ISD',
    'IVO',
    'JUO',
    'LAT',
    'LCR',
    'LET',
    'LGT',
    'LGZ',
    'LMC',
    'LMX',
    'LPD',
    'LPE',
    'LQM',
    'LRI',
    'MCJ',
    'MDE',
    'MFB',
    'MFS',
    'MGN',
    'MHF',
    'MIX',
    'MMP',
    'MND',
    'MOY',
    'MQR',
    'MQU',
    'MTB',
    'MTR',
    'MVP',
    'MZL',
    'NAD',
    'NAR',
    'NBB',
    'NCI',
    'NPU',
    'NQU',
    'NUH',
    'NVA',
    'OCV',
    'ORC',
    'OTU',
    'PAL',
    'PBE',
    'PCC',
    'PCR',
    'PDA',
    'PEI',
    'PLA',
    'PLC',
    'PLT',
    'PPN',
    'PRE',
    'PSO',
    'PTX',
    'PUU',
    'PVA',
    'PYA',
    'PYN',
    'PZA',
    'RAV',
    'RCH',
    'RON',
    'RVE',
    'SCA',
    'SJE',
    'SJG',
    'SJH',
    'SJR',
    'SMC',
    'SMR',
    'SNT',
    'SOH',
    'SOX',
    'SQB',
    'SQE',
    'SQF',
    'SRO',
    'SRS',
    'SSD',
    'SSL',
    'SVI',
    'TAU',
    'TBD',
    'TCD',
    'TCO',
    'TDA',
    'TIB',
    'TLU',
    'TME',
    'TQS',
    'TRB',
    'TTM',
    'UIB',
    'ULQ',
    'ULS',
    'UNC',
    'URI',
    'URR',
    'VAB',
    'VGZ',
    'VUP',
    'VVC',
  ],
  COL: ['SFR'],
  CR: [
    'BAI',
    'BCL',
    'CSC',
    'FMG',
    'FON',
    'GLF',
    'GPL',
    'JAP',
    'LCS',
    'LIO',
    'LIR',
    'LSL',
    'NCT',
    'NOB',
    'OTR',
    'PBP',
    'PJM',
    'PLD',
    'PMZ',
    'RFR',
    'RIK',
    'SJO',
    'SYQ',
    'SZC',
    'TMU',
    'TNO',
    'TOO',
    'TTQ',
    'UPL',
    'XQP',
  ],
  CS: ['HNO', 'UZC'],
  CU: [
    'AVI',
    'BCA',
    'BYM',
    'CCC',
    'CFG',
    'CMW',
    'CYO',
    'GAO',
    'GER',
    'HAV',
    'HOG',
    'ICR',
    'LCL',
    'MJG',
    'MOA',
    'MZO',
    'NBW',
    'PST',
    'SCU',
    'SNJ',
    'SNU',
    'SZJ',
    'TND',
    'UMA',
    'UPA',
    'UPB',
    'USS',
    'VRA',
    'VRO',
    'VTU',
  ],
  CV: ['BVC', 'BVR', 'MMO', 'MTI', 'NTO', 'RAI', 'SFL', 'SID', 'SNE', 'VXE'],
  CX: ['XCH'],
  CY: ['AKT', 'ECN', 'EPK', 'GEC', 'LCA', 'NIC', 'PFO'],
  CZ: [
    'BRQ',
    'GTW',
    'KLV',
    'MKA',
    'OLO',
    'OSR',
    'PED',
    'PRG',
    'PRV',
    'UHE',
    'ZBE',
  ],
  DE: [
    'AAH',
    'AGB',
    'AGE',
    'AOC',
    'BBH',
    'BBJ',
    'BER',
    'BFE',
    'BGN',
    'BMK',
    'BMR',
    'BRE',
    'BRV',
    'BWE',
    'BYU',
    'CBU',
    'CGN',
    'CSO',
    'DRS',
    'DTM',
    'DUS',
    'EIB',
    'EME',
    'ERF',
    'ESS',
    'EUM',
    'FCN',
    'FDH',
    'FEL',
    'FKB',
    'FLF',
    'FMM',
    'FMO',
    'FNB',
    'FRA',
    'FRF',
    'FRZ',
    'GHF',
    'GKE',
    'GMY',
    'GTI',
    'GUT',
    'GWT',
    'HAJ',
    'HAM',
    'HDB',
    'HDF',
    'HEI',
    'HGL',
    'HHN',
    'HOQ',
    'IES',
    'IGS',
    'ILH',
    'JUI',
    'KEL',
    'KLT',
    'KOQ',
    'KSF',
    'KZG',
    'LBC',
    'LEJ',
    'LGO',
    'LHA',
    'LRC',
    'MGL',
    'MHG',
    'MUC',
    'NDZ',
    'NOD',
    'NOE',
    'NRD',
    'NRN',
    'NUE',
    'OBF',
    'OHR',
    'PAD',
    'PEF',
    'PHM',
    'PSH',
    'QDU',
    'QGV',
    'QHU',
    'RBM',
    'REB',
    'RLG',
    'RMS',
    'SCN',
    'SEX',
    'SGE',
    'SPM',
    'STR',
    'SXF',
    'SZW',
    'TXL',
    'UPF',
    'URD',
    'VAC',
    'WBG',
    'WID',
    'WIE',
    'WVN',
    'XFW',
    'XHJ',
    'XLW',
    'XZE',
    'ZAU',
    'ZCD',
    'ZFR',
    'ZGA',
    'ZGE',
    'ZGO',
    'ZGW',
    'ZGZ',
    'ZHZ',
    'ZIU',
    'ZJS',
    'ZSU',
    'ZTT',
    'ZTZ',
    'ZVM',
    'ZVR',
  ],
  DJ: ['AII', 'JIB', 'MHI', 'OBC', 'TDJ'],
  DK: [
    'AAL',
    'AAR',
    'BLL',
    'BYR',
    'CNL',
    'CPH',
    'EBJ',
    'KRP',
    'MRW',
    'ODE',
    'PQT',
    'QJV',
    'QSG',
    'RKE',
    'RNN',
    'SGD',
    'SKS',
    'SQW',
    'STA',
    'TED',
    'VEJ',
    'XJE',
    'ZBU',
  ],
  DM: ['DCF', 'DOM'],
  DO: [
    'AZS',
    'BRX',
    'CBJ',
    'COZ',
    'EPS',
    'HEX',
    'LRM',
    'POP',
    'PUJ',
    'SDQ',
    'SJM',
    'SNX',
    'STI',
  ],
  DRC: ['LJA'],
  DZ: [
    'AAE',
    'ALG',
    'AZR',
    'BFW',
    'BJA',
    'BLJ',
    'BMW',
    'BSK',
    'BUJ',
    'CBH',
    'CZL',
    'DJG',
    'ELG',
    'ELU',
    'GBB',
    'GHA',
    'GJL',
    'HME',
    'IAM',
    'INF',
    'INZ',
    'KDF',
    'MQV',
    'MUW',
    'MZW',
    'OGX',
    'ORN',
    'QSF',
    'SKI',
    'TAF',
    'TEE',
    'TGR',
    'TID',
    'TIN',
    'TLM',
    'TMR',
    'TMX',
    'VVZ',
  ],
  EC: [
    'ATF',
    'BHA',
    'CUE',
    'ESM',
    'GPS',
    'GYE',
    'JIP',
    'LGQ',
    'LOH',
    'LTX',
    'MCH',
    'MEC',
    'MRR',
    'MZD',
    'OCC',
    'PTZ',
    'PVO',
    'PYO',
    'SCY',
    'SNC',
    'SUQ',
    'TPC',
    'TPN',
    'TSC',
    'TUA',
    'UIO',
    'XMS',
  ],
  EE: ['EPU', 'KDL', 'QUF', 'TAY', 'TLL', 'URE'],
  EG: [
    'AAC',
    'ABS',
    'ALY',
    'ASW',
    'ATZ',
    'AUE',
    'CAI',
    'DAK',
    'DBB',
    'ELT',
    'EMY',
    'GSQ',
    'HBE',
    'HRG',
    'LXR',
    'MFO',
    'MUH',
    'PSD',
    'RMF',
    'SEW',
    'SKV',
    'SQK',
    'SSH',
    'TCP',
    'TFR',
    'UVL',
  ],
  ER: ['ASA', 'ASM', 'MSW', 'TES'],
  ES: [
    'ABC',
    'ACE',
    'AEI',
    'AGP',
    'ALC',
    'BCN',
    'BIO',
    'BJZ',
    'CDZ',
    'CJI',
    'CSL',
    'EAS',
    'FES',
    'FGR',
    'FUE',
    'GMZ',
    'GRO',
    'GRX',
    'HEV',
    'HSK',
    'IBZ',
    'JCU',
    'LCG',
    'LEI',
    'LEN',
    'LEU',
    'LPA',
    'MAD',
    'MAH',
    'MJV',
    'MLN',
    'NDO',
    'ODB',
    'OVD',
    'OZI',
    'OZP',
    'OZU',
    'PMI',
    'PNA',
    'PXS',
    'REU',
    'RJL',
    'RRA',
    'SCQ',
    'SDR',
    'SLM',
    'SPC',
    'SPO',
    'SVQ',
    'TFN',
    'TFS',
    'TOJ',
    'UER',
    'UTL',
    'VDE',
    'VGO',
    'VIT',
    'VLC',
    'VLL',
    'XRY',
    'ZAZ',
    'ZRC',
  ],
  ET: [
    'ABK',
    'ADD',
    'ALK',
    'AMH',
    'ASO',
    'AWA',
    'AWH',
    'AXU',
    'BCO',
    'BCY',
    'BEI',
    'BJR',
    'DBM',
    'DBT',
    'DEM',
    'DGC',
    'DIR',
    'DSE',
    'EGL',
    'ETE',
    'FNH',
    'GDE',
    'GDQ',
    'GHD',
    'GLC',
    'GMB',
    'GNN',
    'GOB',
    'GOR',
    'HIL',
    'HUE',
    'JIJ',
    'JIM',
    'LFO',
    'LLI',
    'MHJ',
    'MKD',
    'MKS',
    'MQX',
    'MTF',
    'MUJ',
    'MYS',
    'MZX',
    'NDM',
    'NEJ',
    'NEK',
    'OTA',
    'PWI',
    'SHC',
    'SKR',
    'SQJ',
    'SXU',
    'SZE',
    'TIE',
    'TUJ',
    'WAC',
    'WRA',
    'XBL',
  ],
  FI: [
    'ENF',
    'HEL',
    'HEM',
    'HYV',
    'IVL',
    'JOE',
    'JYV',
    'KAJ',
    'KAO',
    'KAU',
    'KEM',
    'KEV',
    'KHJ',
    'KOK',
    'KTQ',
    'KTT',
    'KUO',
    'LPP',
    'MHQ',
    'MIK',
    'OUL',
    'POR',
    'QVY',
    'RVN',
    'SJY',
    'SOT',
    'SVL',
    'TKU',
    'TMP',
    'UTI',
    'VAA',
    'VRK',
    'YLI',
  ],
  FJ: [
    'AQS',
    'BFJ',
    'BVF',
    'BXL',
    'CST',
    'ICI',
    'KAY',
    'KDV',
    'KVU',
    'KXF',
    'LBS',
    'LEV',
    'LKB',
    'LUC',
    'MFJ',
    'MNF',
    'NAN',
    'NGI',
    'NTA',
    'ONU',
    'PHR',
    'PTF',
    'RBI',
    'RTA',
    'SUV',
    'SVU',
    'TTL',
    'TVU',
    'VAU',
    'VBV',
    'VTF',
    'YAS',
  ],
  FK: ['MPN', 'PSY'],
  FM: ['KSA', 'PNI', 'TKK', 'ULI', 'YAP'],
  FO: ['FAE'],
  FR: [
    'AGF',
    'AHZ',
    'AJA',
    'ANE',
    'ANG',
    'AUF',
    'AUR',
    'AVF',
    'AVN',
    'BAE',
    'BES',
    'BIA',
    'BIQ',
    'BOD',
    'BOR',
    'BOU',
    'BVA',
    'BVE',
    'BZR',
    'CCF',
    'CDG',
    'CEQ',
    'CER',
    'CET',
    'CFE',
    'CFR',
    'CHR',
    'CLY',
    'CMF',
    'CMR',
    'CNG',
    'CQF',
    'CSF',
    'CTT',
    'CVF',
    'DCM',
    'DIJ',
    'DLE',
    'DLP',
    'DNR',
    'DOL',
    'DPE',
    'DXA',
    'EBU',
    'EDM',
    'EGC',
    'ENC',
    'EPL',
    'ETZ',
    'EVX',
    'FNI',
    'FRJ',
    'FSC',
    'GAT',
    'GFR',
    'GNB',
    'HZB',
    'JAH',
    'JCA',
    'JDP',
    'JEV',
    'JLP',
    'JPU',
    'JSZ',
    'LAI',
    'LBG',
    'LBI',
    'LBY',
    'LDE',
    'LDV',
    'LEH',
    'LIG',
    'LIL',
    'LME',
    'LPY',
    'LRH',
    'LRT',
    'LSO',
    'LTQ',
    'LTT',
    'LVA',
    'LYN',
    'LYS',
    'MCU',
    'MEN',
    'MFX',
    'MLH',
    'MPL',
    'MRS',
    'MVV',
    'MXN',
    'MZM',
    'NCE',
    'NCY',
    'NIT',
    'NTE',
    'NVS',
    'OBS',
    'ORE',
    'ORY',
    'PAR',
    'PGF',
    'PGX',
    'PIS',
    'PLG',
    'POX',
    'PPT',
    'PRP',
    'PUF',
    'QAM',
    'QYW',
    'RCO',
    'RDZ',
    'RHE',
    'RNE',
    'RNS',
    'RYN',
    'SBK',
    'SCP',
    'SEC',
    'SNR',
    'SOZ',
    'SXB',
    'SXD',
    'SYT',
    'TGF',
    'TLN',
    'TLS',
    'TNF',
    'TUF',
    'UIP',
    'URO',
    'VAF',
    'VHY',
    'VIY',
    'VNE',
    'VTL',
    'XCR',
    'XDB',
    'XDT',
    'XED',
    'XEX',
    'XFA',
    'XGB',
    'XPG',
    'XPJ',
    'XPZ',
    'XRF',
    'XTT',
    'XVF',
    'XYD',
    'XYL',
    'XYT',
    'XYV',
    'XZN',
    'ZAO',
    'ZFJ',
    'ZFQ',
    'ZYN',
  ],
  GA: [
    'AKE',
    'AWE',
    'BAW',
    'BGB',
    'BGP',
    'BMM',
    'FOU',
    'GAX',
    'GIM',
    'GKO',
    'IGE',
    'KDN',
    'KMD',
    'KOU',
    'LBQ',
    'LBV',
    'LEO',
    'LTL',
    'MBC',
    'MDV',
    'MFF',
    'MGO',
    'MGX',
    'MJL',
    'MKB',
    'MKU',
    'MVB',
    'MVG',
    'MVX',
    'MYB',
    'MZC',
    'NKA',
    'OKN',
    'OMB',
    'OUU',
    'OWE',
    'OYE',
    'POG',
    'TCH',
    'WGY',
    'WNE',
    'ZKM',
  ],
  GB: [
    'ABB',
    'ABZ',
    'ACI',
    'ADV',
    'ADX',
    'AYH',
    'BBP',
    'BBS',
    'BEB',
    'BEQ',
    'BEX',
    'BFS',
    'BHD',
    'BHX',
    'BLK',
    'BOH',
    'BOL',
    'BQH',
    'BRF',
    'BRR',
    'BRS',
    'BSH',
    'BUT',
    'BWF',
    'BWY',
    'BZZ',
    'CAL',
    'CAX',
    'CBG',
    'CEG',
    'COL',
    'CRN',
    'CSA',
    'CVT',
    'CWL',
    'DND',
    'DOC',
    'DSA',
    'EDI',
    'EMA',
    'ENK',
    'EOI',
    'ESH',
    'EWY',
    'EXT',
    'FAB',
    'FEA',
    'FFD',
    'FIE',
    'FKH',
    'FLH',
    'FOA',
    'FSS',
    'FWM',
    'FZO',
    'GCI',
    'GLA',
    'GLO',
    'GQJ',
    'GSY',
    'GXH',
    'HAW',
    'HEN',
    'HLY',
    'HOY',
    'HRT',
    'HTF',
    'HUY',
    'HYC',
    'ILY',
    'INV',
    'IOM',
    'ISC',
    'JER',
    'KNF',
    'KOI',
    'KYN',
    'LBA',
    'LCY',
    'LDY',
    'LEQ',
    'LGW',
    'LHR',
    'LKZ',
    'LMO',
    'LON',
    'LPH',
    'LPL',
    'LSI',
    'LTN',
    'LWK',
    'LYE',
    'LYX',
    'MAN',
    'MHZ',
    'MME',
    'MSE',
    'NCL',
    'NDY',
    'NHT',
    'NQT',
    'NQY',
    'NRL',
    'NWI',
    'OBN',
    'ODH',
    'OHP',
    'OKH',
    'ORM',
    'OUK',
    'OXF',
    'PIK',
    'PLH',
    'PME',
    'PPW',
    'PSL',
    'PSV',
    'PZE',
    'QDH',
    'QQS',
    'QQW',
    'RAY',
    'RCS',
    'SCS',
    'SDZ',
    'SEN',
    'SKL',
    'SOU',
    'SOY',
    'SQZ',
    'STN',
    'SWI',
    'SWS',
    'SYY',
    'SZD',
    'TRE',
    'TSO',
    'UHF',
    'ULL',
    'UNT',
    'UPV',
    'VLY',
    'WEM',
    'WFD',
    'WHS',
    'WIC',
    'WOB',
    'WRY',
    'WTN',
    'WUK',
    'WXF',
    'XQE',
    'XQH',
    'XQI',
    'XWS',
    'YEO',
    'ZBC',
    'ZDU',
    'ZEP',
    'ZEQ',
    'ZFC',
    'ZGG',
    'ZIV',
    'ZLX',
    'ZXP',
  ],
  GD: ['CRU', 'GND'],
  GE: ['BUS', 'KUT', 'SUI', 'TBS'],
  GF: ['CAY', 'LDX', 'MPY', 'OYP', 'REI', 'XAU'],
  GH: ['ACC', 'KMS', 'NYI', 'TKD', 'TML'],
  GI: ['GIB'],
  GL: [
    'AGM',
    'CNP',
    'DUN',
    'GOH',
    'JAV',
    'JCH',
    'JEG',
    'JFR',
    'JGO',
    'JGR',
    'JHS',
    'JJU',
    'JNN',
    'JNS',
    'JQA',
    'JRK',
    'JSU',
    'JUV',
    'KUS',
    'LLU',
    'NAQ',
    'OBY',
    'QCU',
    'QFI',
    'QGQ',
    'QJE',
    'QJI',
    'QMK',
    'QPW',
    'QRY',
    'QUP',
    'SFJ',
    'THU',
    'UAK',
    'UMD',
    'XEO',
    'XEQ',
    'XIQ',
  ],
  GM: ['BJL'],
  GN: [
    'BKJ',
    'CKY',
    'FAA',
    'FIG',
    'GII',
    'KNN',
    'KSI',
    'LEK',
    'MCA',
    'NZE',
    'SBI',
  ],
  GP: [
    'BBR',
    'CCE',
    'DSD',
    'GBJ',
    'HTB',
    'LSS',
    'MSB',
    'PTP',
    'SBH',
    'SFC',
    'SFG',
  ],
  GQ: ['BSG', 'SSG'],
  GR: [
    'AGQ',
    'AOK',
    'ATH',
    'AXD',
    'CFU',
    'CHQ',
    'DRM',
    'EFL',
    'GPA',
    'HER',
    'HEW',
    'IOA',
    'JIK',
    'JKH',
    'JMK',
    'JNX',
    'JSH',
    'JSI',
    'JSS',
    'JSY',
    'JTR',
    'JTY',
    'KGS',
    'KIT',
    'KLX',
    'KSJ',
    'KSO',
    'KVA',
    'KZI',
    'KZS',
    'LRA',
    'LRS',
    'LXS',
    'MJT',
    'MLO',
    'PAS',
    'PKH',
    'PVK',
    'PYR',
    'RHO',
    'SKG',
    'SKU',
    'SMI',
    'SPJ',
    'VOL',
    'ZTH',
  ],
  GT: [
    'AQB',
    'CBV',
    'CIQ',
    'CMM',
    'CTF',
    'DON',
    'ENJ',
    'FRS',
    'GUA',
    'HUG',
    'LCF',
    'LOX',
    'MCR',
    'PBR',
    'PCG',
    'PKJ',
    'PON',
    'RER',
    'RUV',
    'TKM',
    'UAX',
  ],
  GU: ['GUM', 'NGM', 'NRV', 'UAM'],
  GW: ['BQE', 'BXO', 'OXB'],
  GY: [
    'AHL',
    'BCG',
    'BMJ',
    'EKE',
    'GEO',
    'GFO',
    'IMB',
    'KAI',
    'KAR',
    'KKG',
    'KPG',
    'KRG',
    'KRM',
    'KTO',
    'LTM',
    'LUB',
    'MHA',
    'MWJ',
    'MYM',
    'NAI',
    'OGL',
    'ORJ',
    'PIQ',
    'PKM',
    'PMT',
    'PRR',
    'SDC',
    'SKM',
    'USI',
    'VEG',
  ],
  HK: ['HHP', 'HKG', 'KTZ', 'QDM', 'ZJK'],
  HN: [
    'AHS',
    'BHG',
    'CAA',
    'CDD',
    'CYL',
    'EDQ',
    'ENQ',
    'GAC',
    'GJA',
    'GUO',
    'IRN',
    'JUT',
    'LCE',
    'LEZ',
    'LLH',
    'LMH',
    'LUI',
    'MRJ',
    'OAN',
    'ORO',
    'PCH',
    'PEU',
    'RTB',
    'RUY',
    'SAP',
    'SCD',
    'SDH',
    'SET',
    'TCF',
    'TEA',
    'TGU',
    'TJI',
    'UII',
    'VTA',
    'XPL',
  ],
  HR: ['BWK', 'DBV', 'LSZ', 'OSI', 'PUY', 'RJK', 'SPU', 'ZAD', 'ZAG'],
  HT: ['CAP', 'CYA', 'JAK', 'JEE', 'PAP', 'PAX'],
  HU: ['BUD', 'DEB', 'MCQ', 'QGY', 'QPJ', 'SOB', 'XXJ'],
  ID: [
    'AAS',
    'ABU',
    'AEG',
    'AGD',
    'AHI',
    'AKQ',
    'AMI',
    'AMQ',
    'ARD',
    'ARJ',
    'AUT',
    'AYW',
    'BDJ',
    'BDO',
    'BEJ',
    'BIK',
    'BJG',
    'BJK',
    'BJW',
    'BKS',
    'BMU',
    'BPN',
    'BTH',
    'BTJ',
    'BTW',
    'BUI',
    'BUW',
    'BXB',
    'BXD',
    'BXM',
    'BXT',
    'BYQ',
    'CBN',
    'CGK',
    'CPF',
    'CXP',
    'DJB',
    'DJJ',
    'DOB',
    'DPS',
    'DRH',
    'DTD',
    'DUM',
    'ELR',
    'ENE',
    'EWE',
    'EWI',
    'FKQ',
    'FOO',
    'GAV',
    'GEB',
    'GLX',
    'GNS',
    'GTO',
    'HLP',
    'ILA',
    'INX',
    'IUL',
    'JKT',
    'JOG',
    'KAZ',
    'KBF',
    'KBU',
    'KBX',
    'KCD',
    'KCI',
    'KDI',
    'KEA',
    'KEI',
    'KEQ',
    'KLQ',
    'KMM',
    'KNG',
    'KOD',
    'KOE',
    'KOX',
    'KRC',
    'KTG',
    'KWB',
    'LAH',
    'LBJ',
    'LBW',
    'LHI',
    'LII',
    'LKA',
    'LLN',
    'LPU',
    'LSW',
    'LSX',
    'LUV',
    'LUW',
    'LWE',
    'LYK',
    'MAL',
    'MDC',
    'MDP',
    'MEQ',
    'MES',
    'MJU',
    'MJY',
    'MKQ',
    'MKW',
    'MLG',
    'MNA',
    'MOF',
    'MPC',
    'MPT',
    'MSI',
    'MUF',
    'MWK',
    'MXB',
    'NAF',
    'NAH',
    'NAM',
    'NBX',
    'NDA',
    'NKD',
    'NNX',
    'NPO',
    'NRE',
    'NTI',
    'NTX',
    'OBD',
    'OEC',
    'OKL',
    'OKQ',
    'ONI',
    'OTI',
    'PBW',
    'PCB',
    'PDG',
    'PDO',
    'PGK',
    'PKN',
    'PKU',
    'PKY',
    'PLM',
    'PLW',
    'PNK',
    'PPJ',
    'PPR',
    'PSJ',
    'PSU',
    'PUM',
    'PWL',
    'RAQ',
    'RDE',
    'RGT',
    'RKI',
    'RKO',
    'RSK',
    'RTG',
    'RTI',
    'RUF',
    'SAE',
    'SAU',
    'SBG',
    'SEH',
    'SEQ',
    'SGQ',
    'SIQ',
    'SIW',
    'SMQ',
    'SOC',
    'SOQ',
    'SQG',
    'SQN',
    'SQR',
    'SRG',
    'SRI',
    'SUB',
    'SUP',
    'SWQ',
    'SXK',
    'SZH',
    'TAX',
    'TBM',
    'TIM',
    'TJB',
    'TJG',
    'TJQ',
    'TJS',
    'TKG',
    'TLI',
    'TMC',
    'TMH',
    'TMY',
    'TNB',
    'TNJ',
    'TPK',
    'TRK',
    'TSX',
    'TSY',
    'TTE',
    'TTR',
    'TXM',
    'UAI',
    'UBR',
    'UGU',
    'UOL',
    'UPG',
    'VIQ',
    'WAR',
    'WET',
    'WGP',
    'WMX',
    'WSR',
    'ZEG',
    'ZKL',
    'ZRI',
    'ZRM',
  ],
  IE: [
    'BLY',
    'BYT',
    'CFN',
    'CHE',
    'CLB',
    'DUB',
    'GWY',
    'IIA',
    'INQ',
    'IOR',
    'KIR',
    'KKY',
    'LTR',
    'NNR',
    'NOC',
    'ORK',
    'SNN',
    'SXL',
    'WAT',
    'WEX',
    'ZSL',
  ],
  IL: [
    'BEV',
    'EIY',
    'ETH',
    'GHK',
    'HFA',
    'JRS',
    'KSW',
    'MIP',
    'MTZ',
    'RPN',
    'SDV',
    'TLV',
    'VDA',
    'YOT',
  ],
  IN: [
    'AGR',
    'AJL',
    'AMD',
    'ATQ',
    'BBI',
    'BDQ',
    'BHJ',
    'BHO',
    'BHU',
    'BLR',
    'BOM',
    'CCJ',
    'CCU',
    'CJB',
    'COK',
    'DED',
    'DEL',
    'DHM',
    'DIB',
    'DIU',
    'DMU',
    'GAU',
    'GAY',
    'GOI',
    'GOP',
    'GWL',
    'HBX',
    'HJR',
    'HYD',
    'IDR',
    'IMF',
    'ISK',
    'IXA',
    'IXB',
    'IXC',
    'IXD',
    'IXE',
    'IXG',
    'IXJ',
    'IXL',
    'IXM',
    'IXR',
    'IXS',
    'IXU',
    'IXW',
    'IXY',
    'IXZ',
    'JAI',
    'JDH',
    'JGA',
    'JLR',
    'JRH',
    'JSA',
    'KLH',
    'KNU',
    'KUU',
    'LKO',
    'LTU',
    'MAA',
    'NAG',
    'NDC',
    'PAT',
    'PBD',
    'PNQ',
    'PUT',
    'RAJ',
    'RJA',
    'RPR',
    'SLV',
    'SXR',
    'TCR',
    'TEZ',
    'TIR',
    'TRV',
    'TRZ',
    'UDR',
    'VDY',
    'VGA',
    'VNS',
    'VTZ',
  ],
  IQ: [
    'BGW',
    'BMN',
    'BSR',
    'EBL',
    'KIK',
    'OSM',
    'OSO',
    'SDA',
    'XIH',
    'XNH',
    'XQC',
    'XQV',
    'XTM',
    'XTV',
  ],
  IR: [
    'ABD',
    'ACP',
    'ACZ',
    'ADU',
    'AEU',
    'AFZ',
    'AJK',
    'AKW',
    'AWZ',
    'AZD',
    'BBL',
    'BDH',
    'BJB',
    'BND',
    'BSM',
    'BUZ',
    'BXR',
    'CKT',
    'CQD',
    'DEF',
    'FAZ',
    'GBT',
    'GCH',
    'GSM',
    'GZW',
    'HDM',
    'HDR',
    'IAQ',
    'IFH',
    'IFN',
    'IHR',
    'IIL',
    'IKA',
    'JWN',
    'JYR',
    'KER',
    'KHA',
    'KHD',
    'KHK',
    'KHY',
    'KIH',
    'KLM',
    'KNR',
    'KSH',
    'LFM',
    'LRR',
    'LVP',
    'MHD',
    'MRX',
    'NSH',
    'NUJ',
    'OMH',
    'OMI',
    'PFQ',
    'RAS',
    'RJN',
    'RZR',
    'SDG',
    'SRY',
    'SXI',
    'SYJ',
    'SYZ',
    'TBZ',
    'TCX',
    'TEW',
    'THR',
    'XBJ',
    'YEH',
    'YES',
    'ZAH',
    'ZBR',
  ],
  IS: [
    'AEY',
    'BGJ',
    'BIU',
    'BJD',
    'BLO',
    'BXV',
    'DJU',
    'EGS',
    'FAG',
    'FAS',
    'FLI',
    'GJR',
    'GRY',
    'GUU',
    'HFN',
    'HVK',
    'HVM',
    'HZK',
    'IFJ',
    'KEF',
    'MVA',
    'NOR',
    'OFJ',
    'OLI',
    'OPA',
    'PFJ',
    'REK',
    'RFN',
    'RHA',
    'RKV',
    'SAK',
    'SEJ',
    'SIJ',
    'SUY',
    'SYK',
    'TEY',
    'THO',
    'TLK',
    'VEY',
    'VPN',
  ],
  IT: [
    'AHO',
    'ALL',
    'AOI',
    'AOT',
    'AVB',
    'BDS',
    'BGY',
    'BLQ',
    'BLX',
    'BRI',
    'BZO',
    'CAG',
    'CIA',
    'CIY',
    'CRV',
    'CTA',
    'CUF',
    'DCI',
    'EBA',
    'FCO',
    'FLR',
    'FOG',
    'FRL',
    'GBN',
    'GOA',
    'GRS',
    'ISH',
    'JLO',
    'LCC',
    'LCV',
    'LIN',
    'LIU',
    'LMP',
    'MIL',
    'MXP',
    'NAP',
    'NSY',
    'OLB',
    'PEG',
    'PEJ',
    'PMF',
    'PMO',
    'PNL',
    'PRJ',
    'PSA',
    'PSR',
    'QMM',
    'RAN',
    'REG',
    'RMI',
    'ROM',
    'RRO',
    'SAY',
    'SUF',
    'SWK',
    'TAR',
    'TFC',
    'TPS',
    'TQR',
    'TRN',
    'TRS',
    'TSF',
    'TTB',
    'UDN',
    'VBS',
    'VCE',
    'VIC',
    'VIF',
    'VRN',
    'XIK',
    'XIX',
    'XRJ',
    'XVQ',
    'XVY',
    'ZAK',
    'ZBN',
    'ZIE',
    'ZIP',
    'ZIQ',
    'ZJE',
    'ZJJ',
    'ZJX',
    'ZJY',
    'ZMF',
    'ZMI',
    'ZMJ',
    'ZMO',
    'ZMR',
    'ZMS',
  ],
  JM: ['KIN', 'KTP', 'MBJ', 'MVJ', 'NEG', 'OCJ', 'POT'],
  JO: ['ADJ', 'AMM', 'AQJ', 'MPQ', 'OMF'],
  JP: [
    'AGJ',
    'AHA',
    'AKJ',
    'AOJ',
    'ASJ',
    'AXT',
    'BPU',
    'CTS',
    'DNA',
    'FKJ',
    'FKS',
    'FUJ',
    'FUK',
    'GAJ',
    'HAC',
    'HHE',
    'HIJ',
    'HIW',
    'HKD',
    'HNA',
    'HND',
    'HSG',
    'HTR',
    'IEJ',
    'IHA',
    'IIN',
    'IKI',
    'ISG',
    'ITM',
    'IWJ',
    'IWO',
    'IZO',
    'KCZ',
    'KIJ',
    'KIX',
    'KJP',
    'KKJ',
    'KKX',
    'KMI',
    'KMJ',
    'KMQ',
    'KOJ',
    'KTD',
    'KUH',
    'KUJ',
    'KUM',
    'MBE',
    'MMB',
    'MMD',
    'MMJ',
    'MMY',
    'MSJ',
    'MUS',
    'MYE',
    'MYJ',
    'NGO',
    'NGS',
    'NJA',
    'NKM',
    'NRT',
    'NTQ',
    'OBO',
    'OGN',
    'OIM',
    'OIR',
    'OIT',
    'OKA',
    'OKD',
    'OKE',
    'OKI',
    'OKJ',
    'OKO',
    'OMJ',
    'ONJ',
    'RBJ',
    'RIS',
    'RNJ',
    'SDJ',
    'SDO',
    'SDS',
    'SHB',
    'SHI',
    'SHM',
    'SYO',
    'TAK',
    'TJH',
    'TKN',
    'TKS',
    'TNE',
    'TOY',
    'TRA',
    'TSJ',
    'TTJ',
    'TYO',
    'UBJ',
    'UEO',
    'UKB',
    'UKY',
    'WKJ',
    'XZJ',
    'YGJ',
    'YOK',
  ],
  KE: [
    'ASV',
    'BMQ',
    'EDL',
    'EYS',
    'FER',
    'GAS',
    'HOA',
    'ILU',
    'KEY',
    'KIS',
    'KIU',
    'KLK',
    'KRV',
    'KTL',
    'KWY',
    'LAU',
    'LBK',
    'LBN',
    'LKG',
    'LKU',
    'LOK',
    'LOY',
    'MBA',
    'MRE',
    'MUM',
    'MYD',
    'NBO',
    'NDE',
    'NUU',
    'NYE',
    'NYK',
    'NZO',
    'OYL',
    'RBT',
    'UAS',
    'UKA',
    'WIL',
    'WJR',
  ],
  KG: ['FRU', 'OSS'],
  KH: [
    'BBM',
    'HPP',
    'KKZ',
    'KMT',
    'KOS',
    'KTI',
    'KZC',
    'KZD',
    'KZK',
    'MWV',
    'OMY',
    'PAI',
    'PNH',
    'RBE',
    'REP',
    'SVR',
    'TNX',
  ],
  KI: [
    'AAK',
    'ABF',
    'AEA',
    'AIS',
    'BBG',
    'BEZ',
    'CIS',
    'CXI',
    'KUC',
    'MNK',
    'MTK',
    'MZK',
    'NIG',
    'NON',
    'OOT',
    'TBF',
    'TMN',
    'TNQ',
    'TNV',
    'TRW',
    'TSU',
  ],
  KM: ['AJN', 'DZA', 'HAH', 'NWA', 'YVA'],
  KN: ['NEV', 'SKB'],
  KP: ['FNJ'],
  KR: [
    'CHF',
    'CHN',
    'CJJ',
    'CJU',
    'GMP',
    'HIN',
    'ICN',
    'JCJ',
    'JCN',
    'JGE',
    'JSP',
    'KAG',
    'KPO',
    'KUV',
    'KUZ',
    'KWJ',
    'MPK',
    'OSN',
    'PUS',
    'RSU',
    'SHO',
    'SUK',
    'SWU',
    'SYS',
    'TAE',
    'USN',
    'WJU',
    'YEC',
    'YNY',
  ],
  KW: ['KWI', 'XIJ'],
  KY: ['CYB', 'GCM', 'LYB'],
  KZ: [
    'AKX',
    'ALA',
    'ATX',
    'AYK',
    'BXH',
    'BXJ',
    'CIT',
    'DMB',
    'DZN',
    'EKB',
    'GUW',
    'HRC',
    'KGF',
    'KOV',
    'KSN',
    'KZO',
    'PLX',
    'PPK',
    'PWQ',
    'SCO',
    'SZI',
    'TDK',
    'TSE',
    'UKK',
    'URA',
  ],
  LA: [
    'AOU',
    'HOE',
    'KOG',
    'LPQ',
    'LXG',
    'NEU',
    'ODY',
    'OUI',
    'PCQ',
    'PKS',
    'PKZ',
    'SND',
    'THK',
    'UDO',
    'UON',
    'VGG',
    'VNA',
    'VNG',
    'VTE',
    'XAY',
    'XIE',
    'XKH',
    'ZBY',
    'ZVK',
  ],
  LB: ['BEY', 'KYE'],
  LC: ['SLU', 'UVF'],
  LK: [
    'ACJ',
    'ADP',
    'AFK',
    'BJT',
    'BTC',
    'CMB',
    'DBU',
    'DIW',
    'GIU',
    'GOY',
    'HIM',
    'JAF',
    'KCT',
    'KDW',
    'KDY',
    'KDZ',
    'KEZ',
    'KTY',
    'MNH',
    'NUF',
    'TRR',
    'WRZ',
  ],
  LR: [
    'BYL',
    'CPA',
    'FOY',
    'GRC',
    'MLW',
    'NIA',
    'ROB',
    'RVC',
    'SAZ',
    'SNI',
    'THC',
    'TPT',
    'UCN',
    'VOI',
    'WES',
    'WOI',
    'XSA',
  ],
  LS: [
    'LEF',
    'LES',
    'LRB',
    'MFC',
    'MKH',
    'MSG',
    'MSU',
    'NKU',
    'PEL',
    'SHK',
    'SHZ',
    'SKQ',
    'SOK',
    'THB',
    'TKO',
    'UNE',
    'UTG',
  ],
  LT: ['KLJ', 'KUN', 'PLQ', 'PNV', 'SQQ', 'VNO'],
  LU: ['LUX'],
  LV: ['DGP', 'LPX', 'RIX'],
  LY: [
    'AKF',
    'BCQ',
    'BEN',
    'DNF',
    'GHT',
    'HUQ',
    'LAQ',
    'LMQ',
    'LTD',
    'MJI',
    'MRA',
    'NFR',
    'QMQ',
    'QUB',
    'SEB',
    'SRX',
    'TIP',
    'TOB',
  ],
  MA: [
    'AGA',
    'AHU',
    'CAS',
    'CMN',
    'ERH',
    'ESU',
    'EUN',
    'FEZ',
    'GLN',
    'MEK',
    'NDR',
    'NNA',
    'OUD',
    'OZZ',
    'RAK',
    'RBA',
    'SEK',
    'SFI',
    'SII',
    'SMW',
    'TFY',
    'TNG',
    'TTA',
    'TTU',
    'VIL',
  ],
  MC: ['MCM'],
  MD: ['BZY', 'KIV'],
  ME: ['IVG', 'TGD', 'TIV'],
  MG: [
    'AHY',
    'AMB',
    'AMP',
    'AMY',
    'ANM',
    'ATJ',
    'BKU',
    'BMD',
    'BPY',
    'DIE',
    'DOA',
    'DWB',
    'FTU',
    'HVA',
    'IHO',
    'ILK',
    'IVA',
    'JVA',
    'MJA',
    'MJN',
    'MNJ',
    'MOQ',
    'MXM',
    'MXT',
    'NKO',
    'NOS',
    'OVA',
    'RVA',
    'SMS',
    'SVB',
    'TDV',
    'TLE',
    'TMM',
    'TNR',
    'TTS',
    'TVA',
    'VAT',
    'VND',
    'VOH',
    'VVB',
    'WAD',
    'WAI',
    'WAK',
    'WAM',
    'WAQ',
    'WBD',
    'WBE',
    'WBO',
    'WFI',
    'WMA',
    'WMD',
    'WML',
    'WMN',
    'WMP',
    'WMR',
    'WMV',
    'WOR',
    'WPB',
    'WTA',
    'WTS',
    'WVK',
    'ZVA',
    'ZWA',
  ],
  MH: [
    'AIC',
    'AIM',
    'AIP',
    'AMR',
    'AUL',
    'BII',
    'EAL',
    'EBN',
    'EBO',
    'EJT',
    'ENT',
    'IMI',
    'JAT',
    'JEJ',
    'KBT',
    'KIO',
    'KWA',
    'LIK',
    'LML',
    'LOF',
    'MAJ',
    'MAV',
    'MIJ',
    'MJB',
    'MJE',
    'NDK',
    'NMU',
    'RNP',
    'TBV',
    'TIC',
    'UIT',
    'UJE',
    'UTK',
    'WJA',
    'WTE',
    'WTO',
  ],
  MK: ['OHD', 'SKP'],
  ML: [
    'BKO',
    'EYL',
    'GAQ',
    'GUD',
    'KNZ',
    'KSS',
    'KTX',
    'KYS',
    'MZI',
    'NIX',
    'NRM',
    'SZU',
    'TOM',
  ],
  MM: [
    'AKY',
    'BMO',
    'BPE',
    'BSX',
    'GAW',
    'GWA',
    'HEB',
    'HEH',
    'HOX',
    'KAW',
    'KET',
    'KHM',
    'KMV',
    'KYP',
    'KYT',
    'LIW',
    'LSH',
    'MDL',
    'MGK',
    'MGU',
    'MGZ',
    'MNU',
    'MOE',
    'MOG',
    'MWQ',
    'MYT',
    'NMS',
    'NMT',
    'NYU',
    'PAA',
    'PAU',
    'PBU',
    'PKK',
    'PPU',
    'PRU',
    'RGN',
    'SNW',
    'THL',
    'TIO',
    'TVY',
    'XYE',
  ],
  MN: [
    'AVK',
    'BYN',
    'COQ',
    'DLZ',
    'ERT',
    'HJT',
    'HTM',
    'HVD',
    'KHR',
    'LTI',
    'MXV',
    'MXW',
    'TNZ',
    'TSZ',
    'UGA',
    'UGT',
    'ULG',
    'ULN',
    'ULO',
    'ULZ',
    'UNR',
    'UUN',
  ],
  MO: ['MFM'],
  MP: ['ROP', 'SPN', 'TIQ'],
  MQ: ['FDF'],
  MR: [
    'AEO',
    'AJJ',
    'ATR',
    'BGH',
    'CGT',
    'EMN',
    'FGD',
    'KED',
    'KFA',
    'LEG',
    'MBR',
    'MOM',
    'NDB',
    'NKC',
    'OTL',
    'OUZ',
    'SEY',
    'THI',
    'THT',
    'TIY',
    'TMD',
    'ZLG',
  ],
  MS: ['MNI'],
  MT: ['GZM', 'JCO', 'MLA'],
  MU: ['MRU', 'RRG'],
  MV: ['GAN', 'HAQ', 'KDM', 'KDO', 'MLE'],
  MW: [
    'BLZ',
    'CEH',
    'CMK',
    'DWA',
    'KBQ',
    'KGJ',
    'LIX',
    'LLW',
    'LMB',
    'MAI',
    'MYZ',
    'VUU',
    'ZZU',
  ],
  MX: [
    'ACA',
    'ACN',
    'AGU',
    'AJS',
    'AZG',
    'AZP',
    'BHL',
    'BJX',
    'CEN',
    'CJS',
    'CJT',
    'CLQ',
    'CME',
    'CNA',
    'CPE',
    'CTM',
    'CUA',
    'CUL',
    'CUN',
    'CUU',
    'CVJ',
    'CVM',
    'CZA',
    'CZM',
    'DGO',
    'ESE',
    'GDL',
    'GUB',
    'GYM',
    'HMO',
    'HUX',
    'ISJ',
    'IZT',
    'JAL',
    'LAP',
    'LMM',
    'LOM',
    'LOV',
    'LTO',
    'LZC',
    'MAM',
    'MEX',
    'MID',
    'MLM',
    'MMC',
    'MTT',
    'MTY',
    'MUG',
    'MXL',
    'MZT',
    'NCG',
    'NLD',
    'NLU',
    'NOG',
    'NTR',
    'OAX',
    'PAZ',
    'PBC',
    'PCM',
    'PCO',
    'PCV',
    'PDS',
    'PJZ',
    'PNO',
    'PPE',
    'PQM',
    'PUH',
    'PVR',
    'PXM',
    'QRO',
    'REX',
    'SCX',
    'SFH',
    'SGM',
    'SJD',
    'SLP',
    'SLW',
    'SNQ',
    'SRL',
    'SZT',
    'TAM',
    'TAP',
    'TCN',
    'TGZ',
    'TIJ',
    'TLC',
    'TPQ',
    'TRC',
    'TSL',
    'TUY',
    'TZM',
    'UAC',
    'UPN',
    'VER',
    'VIB',
    'VSA',
    'XAL',
    'ZCL',
    'ZIH',
    'ZLO',
    'ZMM',
  ],
  MY: [
    'AOR',
    'BBN',
    'BKI',
    'BKM',
    'BLG',
    'BSE',
    'BTU',
    'BWH',
    'GSA',
    'GTB',
    'GTK',
    'IPH',
    'JHB',
    'KBR',
    'KCH',
    'KGU',
    'KPI',
    'KTE',
    'KUA',
    'KUD',
    'KUL',
    'LAC',
    'LBP',
    'LBU',
    'LDU',
    'LGK',
    'LGL',
    'LKH',
    'LLM',
    'LMN',
    'LSM',
    'LSU',
    'LWY',
    'MEP',
    'MKM',
    'MKZ',
    'MUR',
    'MYY',
    'MZS',
    'MZV',
    'ODN',
    'PAY',
    'PEN',
    'PKG',
    'RDN',
    'RNU',
    'SBW',
    'SDK',
    'SGG',
    'SMM',
    'SPE',
    'SPT',
    'SWY',
    'SXS',
    'SXT',
    'SZB',
    'TEL',
    'TGG',
    'TMG',
    'TOD',
    'TPG',
    'TWU',
    'XPQ',
    'ZGY',
    'ZJB',
    'ZJR',
    'ZJT',
    'ZLW',
    'ZMK',
    'ZWR',
  ],
  MZ: [
    'AME',
    'ANO',
    'APL',
    'BCW',
    'BEW',
    'BJN',
    'BZB',
    'CMZ',
    'DGK',
    'FXO',
    'IBL',
    'IBO',
    'IDC',
    'IHC',
    'IMG',
    'INE',
    'INH',
    'LBM',
    'LFB',
    'LMZ',
    'MFW',
    'MJS',
    'MMW',
    'MNC',
    'MPM',
    'MTU',
    'MUD',
    'MZB',
    'NND',
    'NTC',
    'PDD',
    'PEB',
    'POL',
    'RRM',
    'TCV',
    'TET',
    'TGS',
    'UEL',
    'VJB',
    'VJQ',
    'VNX',
    'VPY',
    'VXC',
  ],
  NA: [
    'ADI',
    'AIW',
    'BQI',
    'ERS',
    'GFY',
    'GOG',
    'HAL',
    'KAS',
    'KMP',
    'LHU',
    'LUD',
    'MJO',
    'MPA',
    'MQG',
    'NDU',
    'NNI',
    'OGV',
    'OHI',
    'OKF',
    'OKU',
    'OMD',
    'OMG',
    'OND',
    'OPW',
    'OTJ',
    'RHN',
    'SWP',
    'SZM',
    'TCY',
    'TSB',
    'WDH',
    'WVB',
    'ZSZ',
    'ZWH',
  ],
  NC: [
    'BMY',
    'GEA',
    'HLU',
    'HNG',
    'ILP',
    'IOU',
    'KNQ',
    'KOC',
    'LIF',
    'MEE',
    'NOU',
    'PDC',
    'PUV',
    'TGJ',
    'TOU',
    'UVE',
  ],
  NE: ['AJY', 'BKN', 'MFQ', 'NIM', 'RLT', 'THZ', 'ZND'],
  NF: ['NLK'],
  NG: [
    'ABV',
    'AKR',
    'BCU',
    'BNI',
    'CBQ',
    'ENU',
    'IBA',
    'ILR',
    'JOS',
    'KAD',
    'KAN',
    'LOS',
    'MDI',
    'MIU',
    'MXJ',
    'PHC',
    'PHG',
    'QOW',
    'QRW',
    'QVL',
    'SKO',
    'YOL',
    'ZAR',
  ],
  NI: ['BEF', 'BZA', 'MGA', 'NCR', 'NVG', 'PUZ', 'RFS', 'RNI', 'SIU', 'WSP'],
  NL: [
    'AMS',
    'BZM',
    'DHR',
    'EIN',
    'ENS',
    'GLZ',
    'GRQ',
    'LEY',
    'LID',
    'LWR',
    'MST',
    'RTM',
    'UDE',
    'UTC',
    'WOE',
    'ZYH',
    'ZYA',
    'QRH',
  ],
  NO: [
    'AES',
    'ALF',
    'ANX',
    'BDU',
    'BGO',
    'BJF',
    'BNN',
    'BOO',
    'BVG',
    'DLD',
    'EVE',
    'FAN',
    'FDE',
    'FRO',
    'GEN',
    'GLL',
    'HAA',
    'HAU',
    'HFT',
    'HMR',
    'HOV',
    'HRD',
    'HVG',
    'KKN',
    'KRS',
    'KSU',
    'LKL',
    'LKN',
    'LYR',
    'MEH',
    'MJF',
    'MOL',
    'MQN',
    'NTB',
    'NVK',
    'OLA',
    'OSL',
    'OSY',
    'QFK',
    'QFQ',
    'RET',
    'RRS',
    'RVK',
    'RYG',
    'SDN',
    'SKE',
    'SKN',
    'SOG',
    'SOJ',
    'SRP',
    'SSJ',
    'SVG',
    'SVJ',
    'SYG',
    'TOS',
    'TRD',
    'TRF',
    'VAW',
    'VDB',
    'VDS',
    'VRY',
    'XKC',
    'XUV',
    'XUW',
  ],
  NP: [
    'BDP',
    'BGL',
    'BHP',
    'BHR',
    'BIR',
    'BIT',
    'BJH',
    'BJU',
    'BWA',
    'DAP',
    'DHI',
    'DNP',
    'DOP',
    'FEB',
    'GKH',
    'HRJ',
    'IMK',
    'JIR',
    'JKR',
    'JMO',
    'JUM',
    'KEP',
    'KTM',
    'LDN',
    'LTG',
    'LUA',
    'MEY',
    'MWP',
    'NGX',
    'PKR',
    'PPL',
    'RHP',
    'RJB',
    'RPA',
    'RUK',
    'RUM',
    'SIF',
    'SIH',
    'SKH',
    'SYH',
    'TMI',
    'TPJ',
    'TPU',
    'XMG',
  ],
  NR: ['INU'],
  NU: ['IUE'],
  NZ: [
    'AKL',
    'ALR',
    'AMZ',
    'ASG',
    'BHE',
    'CHC',
    'CHT',
    'CMV',
    'DGR',
    'DUD',
    'FGL',
    'GBS',
    'GBZ',
    'GIS',
    'GMN',
    'GTN',
    'HKK',
    'HLZ',
    'IVC',
    'KAT',
    'KBZ',
    'KKE',
    'KKO',
    'KTF',
    'KUI',
    'KWU',
    'MFN',
    'MHB',
    'MON',
    'MRO',
    'MTA',
    'MZP',
    'NPE',
    'NPL',
    'NSN',
    'OAM',
    'OHA',
    'PCN',
    'PKL',
    'PMR',
    'PPQ',
    'RAG',
    'ROT',
    'SZS',
    'TEU',
    'THH',
    'TIU',
    'TKZ',
    'TMZ',
    'TRG',
    'TUO',
    'TWZ',
    'WAG',
    'WGN',
    'WHK',
    'WHO',
    'WIK',
    'WIR',
    'WKA',
    'WLG',
    'WRE',
    'WSZ',
    'WTZ',
    'ZQN',
  ],
  OM: ['BYB', 'KHS', 'MCT', 'MSH', 'OMM', 'RMB', 'SLL', 'SUH', 'TTH', 'WWG'],
  PA: [
    'ACU',
    'AIL',
    'AML',
    'BFQ',
    'BLB',
    'BOC',
    'CDE',
    'CHX',
    'CTD',
    'CTE',
    'CZJ',
    'DAV',
    'ELE',
    'GHE',
    'HOW',
    'JQE',
    'MPI',
    'MPP',
    'NBL',
    'NGN',
    'NMG',
    'ONX',
    'OTD',
    'PAC',
    'PLP',
    'PTY',
    'PUE',
    'PVE',
    'PYC',
    'PYV',
    'RIT',
    'RIZ',
    'RSI',
    'SAX',
    'SFW',
    'SYP',
    'TJC',
    'TUE',
    'TUW',
    'UTU',
  ],
  PE: [
    'ALD',
    'ANS',
    'APE',
    'AQP',
    'ATA',
    'AYP',
    'BLP',
    'CHH',
    'CHM',
    'CIX',
    'CJA',
    'CUZ',
    'HUU',
    'IBP',
    'ILQ',
    'IQT',
    'JAU',
    'JJI',
    'JUL',
    'LIM',
    'MBP',
    'MFT',
    'PCL',
    'PEM',
    'PIO',
    'PIU',
    'RIJ',
    'RIM',
    'SJA',
    'SMG',
    'SQU',
    'SYC',
    'TBP',
    'TCQ',
    'TGI',
    'TPP',
    'TRU',
    'TYL',
    'UMI',
    'YMS',
  ],
  PF: [
    'AAA',
    'AHE',
    'APK',
    'AUQ',
    'AXR',
    'BOB',
    'FAC',
    'FAV',
    'FGU',
    'FHZ',
    'GMR',
    'HHZ',
    'HIX',
    'HOI',
    'HUH',
    'KHZ',
    'KKR',
    'KXU',
    'MAU',
    'MKP',
    'MOZ',
    'MVT',
    'NAU',
    'NHV',
    'NUK',
    'PKP',
    'PUK',
    'REA',
    'RFP',
    'RGI',
    'RUR',
    'RVV',
    'TIH',
    'TJN',
    'TKP',
    'TKV',
    'TKX',
    'TPX',
    'TTI',
    'TUB',
    'UAH',
    'UAP',
    'VHZ',
    'XMH',
    'ZTA',
  ],
  PG: [
    'ABP',
    'ABW',
    'ADC',
    'AEK',
    'AFR',
    'AGG',
    'AGK',
    'AGL',
    'AIE',
    'AIH',
    'AKG',
    'AMF',
    'AMG',
    'AMU',
    'AOA',
    'AOB',
    'AON',
    'APP',
    'APR',
    'ARP',
    'ASZ',
    'ATN',
    'ATP',
    'AUI',
    'AUJ',
    'AUP',
    'AUV',
    'AWB',
    'AWR',
    'AYU',
    'AZB',
    'BAA',
    'BAJ',
    'BAP',
    'BCP',
    'BDZ',
    'BEA',
    'BIJ',
    'BIZ',
    'BMH',
    'BMZ',
    'BNM',
    'BNT',
    'BNV',
    'BNZ',
    'BOQ',
    'BOT',
    'BOV',
    'BPB',
    'BPD',
    'BPK',
    'BRH',
    'BRP',
    'BSP',
    'BSV',
    'BUA',
    'BUL',
    'BVP',
    'BWJ',
    'BWP',
    'BXZ',
    'CGC',
    'CMU',
    'CPI',
    'CPN',
    'CVB',
    'CVL',
    'DAF',
    'DAO',
    'DAU',
    'DBP',
    'DDM',
    'DER',
    'DGG',
    'DLB',
    'DNU',
    'DOI',
    'DOO',
    'DOS',
    'DPU',
    'EFG',
    'EGA',
    'EIA',
    'EMI',
    'EMO',
    'EMS',
    'EPT',
    'ERE',
    'ERU',
    'FAQ',
    'FIN',
    'FNE',
    'FRQ',
    'FUB',
    'FUM',
    'GAP',
    'GAR',
    'GAZ',
    'GBC',
    'GBF',
    'GEI',
    'GEW',
    'GKA',
    'GLP',
    'GMI',
    'GOC',
    'GOE',
    'GRH',
    'GRL',
    'GUE',
    'GUG',
    'GUR',
    'GUV',
    'GVI',
    'GWN',
    'HAZ',
    'HBD',
    'HEO',
    'HGU',
    'HIT',
    'HKN',
    'HNI',
    'HNN',
    'HOC',
    'HWA',
    'HYF',
    'IAU',
    'IBI',
    'IDN',
    'IHU',
    'IIS',
    'ILX',
    'IMA',
    'IMD',
    'IMN',
    'IOK',
    'IOP',
    'ITK',
    'IUS',
    'JAQ',
    'JOP',
    'KAF',
    'KAK',
    'KAQ',
    'KBM',
    'KCJ',
    'KDE',
    'KDP',
    'KDQ',
    'KDR',
    'KEG',
    'KEX',
    'KGB',
    'KGH',
    'KGM',
    'KGW',
    'KIA',
    'KIE',
    'KII',
    'KIQ',
    'KIZ',
    'KJU',
    'KKD',
    'KMA',
    'KMB',
    'KMF',
    'KMR',
    'KNE',
    'KNL',
    'KOM',
    'KOR',
    'KPA',
    'KPE',
    'KPF',
    'KPL',
    'KPM',
    'KQL',
    'KRI',
    'KRJ',
    'KRU',
    'KRX',
    'KSB',
    'KSG',
    'KSP',
    'KSX',
    'KTK',
    'KUP',
    'KUQ',
    'KUW',
    'KUX',
    'KVE',
    'KVG',
    'KWO',
    'KWV',
    'KWX',
    'KXR',
    'KYX',
    'KZF',
    'LAB',
    'LAE',
    'LGM',
    'LGN',
    'LHP',
    'LMG',
    'LMI',
    'LMY',
    'LNC',
    'LNF',
    'LNG',
    'LNM',
    'LNQ',
    'LNV',
    'LPN',
    'LSA',
    'LSJ',
    'LTF',
    'LWI',
    'MAG',
    'MAP',
    'MAS',
    'MBV',
    'MDM',
    'MDU',
    'MFZ',
    'MGG',
    'MGP',
    'MHY',
    'MIS',
    'MJJ',
    'MKN',
    'MLQ',
    'MMV',
    'MNP',
    'MPF',
    'MPG',
    'MPU',
    'MPX',
    'MQO',
    'MRH',
    'MRM',
    'MVI',
    'MWG',
    'MWI',
    'MWU',
    'MXH',
    'MXK',
    'MYX',
    'MZN',
    'NBA',
    'NDI',
    'NDN',
    'NGR',
    'NIS',
    'NKN',
    'NMN',
    'NOM',
    'NOO',
    'NPG',
    'NUG',
    'NUT',
    'NWT',
    'OBM',
    'OBX',
    'OGE',
    'OKP',
    'OKV',
    'OLQ',
    'OML',
    'ONB',
    'OPB',
    'OPU',
    'OSE',
    'OSG',
    'OTY',
    'PAW',
    'PDI',
    'PGB',
    'PGE',
    'PGN',
    'PLE',
    'PMN',
    'PMP',
    'PNP',
    'POM',
    'PPX',
    'PUA',
    'PUI',
    'RAA',
    'RAB',
    'RAW',
    'RAX',
    'RBP',
    'RGE',
    'RKU',
    'RMN',
    'RNR',
    'RUU',
    'SAM',
    'SBC',
    'SBE',
    'SBV',
    'SDI',
    'SFU',
    'SGB',
    'SGJ',
    'SGK',
    'SIL',
    'SIM',
    'SIZ',
    'SKC',
    'SMH',
    'SMJ',
    'SMP',
    'SPH',
    'SPV',
    'SQT',
    'SSS',
    'SUZ',
    'SWE',
    'SWG',
    'SWR',
    'SXA',
    'SXH',
    'SXW',
    'TAJ',
    'TBA',
    'TBE',
    'TBG',
    'TBQ',
    'TCJ',
    'TCK',
    'TDB',
    'TDS',
    'TEO',
    'TEP',
    'TFB',
    'TFI',
    'TFM',
    'TGL',
    'TIG',
    'TIZ',
    'TKB',
    'TKW',
    'TLO',
    'TLP',
    'TLW',
    'TOK',
    'TON',
    'TPI',
    'TRJ',
    'TSI',
    'TSK',
    'TSW',
    'TUT',
    'TWY',
    'UAE',
    'UBI',
    'UKU',
    'ULE',
    'UMC',
    'UNG',
    'UPR',
    'URU',
    'USO',
    'UUU',
    'UVO',
    'VAI',
    'VIV',
    'VMU',
    'WAJ',
    'WAO',
    'WBC',
    'WBM',
    'WED',
    'WEP',
    'WGU',
    'WIU',
    'WKN',
    'WNU',
    'WOA',
    'WPM',
    'WSA',
    'WSU',
    'WTP',
    'WTT',
    'WUG',
    'WUM',
    'WUV',
    'WWK',
    'XBN',
    'XYR',
    'YEQ',
    'YVD',
    'ZEN',
  ],
  PH: [
    'AAV',
    'BAG',
    'BCD',
    'BNQ',
    'BPH',
    'BQA',
    'BSO',
    'BXU',
    'CBO',
    'CDY',
    'CEB',
    'CGG',
    'CGM',
    'CGY',
    'CRK',
    'CRM',
    'CUJ',
    'CYP',
    'CYU',
    'CYZ',
    'DGT',
    'DPL',
    'DSG',
    'DTE',
    'DVO',
    'ENI',
    'EUQ',
    'GES',
    'ICO',
    'IGN',
    'ILO',
    'IPE',
    'JOL',
    'KLO',
    'LAO',
    'LBX',
    'LGP',
    'LWA',
    'MBO',
    'MBT',
    'MLP',
    'MNL',
    'MPH',
    'MRQ',
    'MXI',
    'NCP',
    'NSP',
    'OMC',
    'OZC',
    'PAG',
    'PPS',
    'RXS',
    'RZP',
    'SFE',
    'SGL',
    'SGS',
    'SJI',
    'SSV',
    'SUG',
    'TAC',
    'TAG',
    'TBH',
    'TDG',
    'TGB',
    'TUG',
    'TWT',
    'USU',
    'VRC',
    'WNP',
    'XCN',
    'XMA',
    'XSO',
    'ZAM',
  ],
  PK: [
    'AAW',
    'ATG',
    'BDN',
    'BHV',
    'BHW',
    'BNP',
    'CHB',
    'CJL',
    'CWP',
    'DBA',
    'DDU',
    'DEA',
    'DSK',
    'GIL',
    'GRT',
    'GWD',
    'HDD',
    'HRA',
    'ISB',
    'JAG',
    'JIW',
    'KBH',
    'KCF',
    'KDD',
    'KDU',
    'KHI',
    'LHE',
    'LRG',
    'LYP',
    'MFG',
    'MJD',
    'MPD',
    'MUX',
    'MWD',
    'NHS',
    'OHT',
    'ORW',
    'PAJ',
    'PEW',
    'PJG',
    'PSI',
    'PZH',
    'RAZ',
    'RYK',
    'RZS',
    'SBQ',
    'SDT',
    'SGI',
    'SKT',
    'SKZ',
    'SUL',
    'SWN',
    'SWV',
    'SYW',
    'TFT',
    'TLB',
    'TUK',
    'UET',
    'WAF',
    'WGB',
    'WNS',
    'XJM',
  ],
  PL: [
    'BZG',
    'CZW',
    'GDN',
    'IEG',
    'KRK',
    'KTW',
    'LCJ',
    'OSP',
    'OSZ',
    'POZ',
    'RZE',
    'SZY',
    'SZZ',
    'WAW',
    'WMI',
    'WRO',
  ],
  PM: ['FSP', 'MQC'],
  PNG: ['WAB'],
  PR: [
    'ARE',
    'BQN',
    'CPX',
    'DDP',
    'FAJ',
    'HUC',
    'MAZ',
    'NRR',
    'PPD',
    'PSE',
    'SIG',
    'SJU',
    'VQS',
  ],
  PS: ['GZA', 'ZDM'],
  PT: [
    'BGC',
    'BGZ',
    'CBP',
    'CHV',
    'COV',
    'CVU',
    'FAO',
    'FLW',
    'FNC',
    'GRW',
    'HOR',
    'LIS',
    'OPO',
    'PDL',
    'PIX',
    'PRM',
    'PXO',
    'SIE',
    'SJZ',
    'SMA',
    'TER',
    'VRL',
    'VSE',
    'ZYD',
    'ZYF',
  ],
  PW: ['ROR'],
  PY: [
    'AGT',
    'ASU',
    'AYO',
    'BFA',
    'CIO',
    'ENO',
    'ESG',
    'FLM',
    'OLK',
    'PBT',
    'PCJ',
    'PIL',
    'PJC',
    'VMI',
  ],
  QA: ['DOH', 'XJD', 'XOZ'],
  RE: ['RUN', 'ZSE'],
  RO: [
    'ARW',
    'BAY',
    'BBU',
    'BCM',
    'CLJ',
    'CND',
    'CRA',
    'CSB',
    'DVA',
    'IAS',
    'OMR',
    'OTP',
    'SBZ',
    'SCV',
    'SUJ',
    'TCE',
    'TGM',
    'TSR',
  ],
  RS: ['BEG', 'INI'],
  RU: [
    'AAQ',
    'ABA',
    'ACS',
    'ADH',
    'AER',
    'AMV',
    'ARH',
    'ASF',
    'BAX',
    'BCX',
    'BKA',
    'BQS',
    'BTK',
    'BWO',
    'BZK',
    'CEE',
    'CEK',
    'CKH',
    'CKL',
    'CNN',
    'CSY',
    'CYX',
    'DKS',
    'DME',
    'DYR',
    'EGO',
    'EIE',
    'ESL',
    'GDG',
    'GDX',
    'GDZ',
    'GOJ',
    'GRV',
    'HMA',
    'HTA',
    'HTG',
    'IAA',
    'IAR',
    'IJK',
    'IKS',
    'IKT',
    'INA',
    'IWA',
    'JOK',
    'KEJ',
    'KGD',
    'KGP',
    'KHV',
    'KJA',
    'KLD',
    'KLF',
    'KMW',
    'KRO',
    'KRR',
    'KSZ',
    'KUF',
    'KVK',
    'KVX',
    'KXK',
    'KYZ',
    'KZN',
    'LDG',
    'LED',
    'LNX',
    'LPK',
    'MCX',
    'MJZ',
    'MMK',
    'MQF',
    'MRV',
    'NAL',
    'NBC',
    'NEF',
    'NER',
    'NFG',
    'NJC',
    'NNM',
    'NOI',
    'NOJ',
    'NOZ',
    'NUX',
    'NVR',
    'NYM',
    'OEL',
    'OGZ',
    'OHO',
    'OKT',
    'OMS',
    'OSW',
    'OVB',
    'PEE',
    'PES',
    'PEX',
    'PEZ',
    'PKC',
    'PKV',
    'PVS',
    'PVX',
    'PWE',
    'PYJ',
    'RAT',
    'REN',
    'ROV',
    'RTW',
    'RVH',
    'RYB',
    'RZN',
    'SCW',
    'SGC',
    'SKX',
    'SLY',
    'STW',
    'SVO',
    'SVX',
    'SWT',
    'TBW',
    'TJM',
    'TOF',
    'TOX',
    'TYA',
    'TYD',
    'UCT',
    'UFA',
    'UIK',
    'UKX',
    'ULY',
    'URJ',
    'URS',
    'USK',
    'UUA',
    'UUD',
    'UUS',
    'VGD',
    'VKO',
    'VKT',
    'VLK',
    'VLU',
    'VOG',
    'VOZ',
    'VUS',
    'VVO',
    'XRK',
    'YKS',
    'ZKD',
    'ZLK',
  ],
  RUS: ['CSH'],
  RW: ['BTQ', 'GYI', 'KGL', 'KME', 'RHG'],
  SA: [
    'ABT',
    'AHB',
    'AJF',
    'AKH',
    'AQI',
    'BHH',
    'DHA',
    'DMM',
    'DWD',
    'EAM',
    'EJH',
    'ELQ',
    'GIZ',
    'HAS',
    'HBT',
    'HOF',
    'JED',
    'KMC',
    'KMX',
    'MED',
    'MJH',
    'RAE',
    'RAH',
    'RUH',
    'SHW',
    'SLF',
    'TIF',
    'TUI',
    'TUU',
    'URY',
    'UZH',
    'WAE',
    'XZF',
    'YNB',
    'ZUL',
  ],
  SB: [
    'AFT',
    'ANH',
    'ATD',
    'AVU',
    'BAS',
    'BNY',
    'BPF',
    'CHY',
    'EGM',
    'FRE',
    'GEF',
    'GSI',
    'GTA',
    'GZO',
    'HIR',
    'IRA',
    'KGE',
    'KUE',
    'KWR',
    'KWS',
    'MBU',
    'MNY',
    'MUA',
    'NAZ',
    'NNB',
    'ONE',
    'OTV',
    'PRS',
    'RBV',
    'RIN',
    'RNA',
    'RNL',
    'RRI',
    'RUS',
    'SCZ',
    'SVY',
    'TAA',
    'TLG',
    'VAO',
    'VEV',
    'VIU',
    'XYA',
  ],
  SC: ['BDI', 'DEI', 'DES', 'FRK', 'PRI', 'SEZ'],
  SD: [
    'AAD',
    'ATB',
    'DNI',
    'DNX',
    'DOG',
    'EBD',
    'EDB',
    'EGN',
    'ELF',
    'GBU',
    'GSU',
    'HGI',
    'JUB',
    'KRT',
    'KSL',
    'KST',
    'MAK',
    'MWE',
    'NHF',
    'NUD',
    'PZU',
    'RSS',
    'UYL',
    'WHF',
    'WUU',
  ],
  SE: [
    'AGH',
    'AJR',
    'ARN',
    'BLE',
    'BMA',
    'EKT',
    'EVG',
    'GEV',
    'GOT',
    'GSE',
    'GVX',
    'HAD',
    'HFS',
    'HLF',
    'HMV',
    'HUV',
    'IDB',
    'JHE',
    'JKG',
    'JLD',
    'JMM',
    'JSO',
    'KID',
    'KLR',
    'KRF',
    'KRN',
    'KSD',
    'KSK',
    'KVB',
    'LDK',
    'LLA',
    'LPI',
    'LYC',
    'MMX',
    'MXX',
    'NRK',
    'NYO',
    'OER',
    'ORB',
    'OSD',
    'OSK',
    'PJA',
    'RNB',
    'SDL',
    'SFT',
    'SOO',
    'SQO',
    'THN',
    'TYF',
    'UME',
    'VBY',
    'VHM',
    'VST',
    'VVK',
    'VXO',
    'XEZ',
    'XFR',
    'XWK',
    'XWL',
    'XWP',
    'XWQ',
    'XWZ',
    'XXA',
    'XXK',
    'XXU',
    'XYB',
    'XYC',
    'XYH',
    'XYI',
    'XYM',
    'XYN',
    'XYO',
    'XYP',
    'XYQ',
    'XYU',
    'XYX',
    'XZQ',
  ],
  SG: ['QPG', 'SIN', 'XSP'],
  SH: ['ASI'],
  SI: ['LJU', 'MBX', 'POW'],
  SK: ['BTS', 'ILZ', 'KSC', 'LUE', 'POV', 'PZY', 'SLD', 'TAT'],
  SL: [
    'BTE',
    'DSL',
    'FNA',
    'GBK',
    'HGS',
    'JMY',
    'KBA',
    'KBS',
    'KEN',
    'SRK',
    'WYE',
  ],
  SN: [
    'BXE',
    'CSK',
    'DKR',
    'KDA',
    'KGG',
    'KLC',
    'MAX',
    'NIK',
    'POD',
    'RDT',
    'SMY',
    'TUD',
    'XLS',
    'ZIG',
  ],
  SO: [
    'ALU',
    'BBO',
    'BIB',
    'BSA',
    'BSY',
    'BUO',
    'BXX',
    'CMO',
    'CMS',
    'CXN',
    'ERA',
    'GBM',
    'GGR',
    'GLK',
    'GSR',
    'HCM',
    'HGA',
    'KMU',
    'LGX',
    'LKR',
    'MGQ',
  ],
  SR: [
    'AAJ',
    'ABN',
    'AGI',
    'BTO',
    'DOE',
    'DRJ',
    'ICK',
    'KCB',
    'LDO',
    'MOJ',
    'OEM',
    'ORG',
    'PBM',
    'SMZ',
    'TOT',
    'WSO',
  ],
  ST: ['PCP', 'PGP', 'TMS'],
  SV: ['SAL'],
  SVG: ['BQU'],
  SY: ['ALP', 'DAM', 'DEZ', 'KAC', 'LTK', 'PMS', 'SOR'],
  SZ: ['MTS'],
  TC: ['GDT', 'MDS', 'NCA', 'PIC', 'PLS', 'SLX', 'XSC'],
  TD: [
    'AEH',
    'AKM',
    'AMC',
    'AMO',
    'AOD',
    'ATV',
    'BKR',
    'FYT',
    'LTC',
    'MEF',
    'MQQ',
    'MVO',
    'NDJ',
    'OGR',
    'OTC',
    'OUM',
    'OUT',
    'PLF',
    'SRH',
  ],
  TG: ['LFW', 'LRL'],
  TH: [
    'BAO',
    'BFV',
    'BKK',
    'CEI',
    'CJM',
    'CNX',
    'DMK',
    'HDY',
    'HGN',
    'HHQ',
    'HKT',
    'KBV',
    'KDT',
    'KKC',
    'KKM',
    'KOP',
    'LOE',
    'LPT',
    'MAQ',
    'NAK',
    'NAW',
    'NNT',
    'NST',
    'PAN',
    'PBS',
    'PHS',
    'PHY',
    'PHZ',
    'PMM',
    'PRH',
    'PXR',
    'PYX',
    'ROI',
    'SGZ',
    'SNO',
    'TDX',
    'THS',
    'TKH',
    'TKT',
    'TST',
    'UBO',
    'UBP',
    'UNN',
    'URT',
    'USM',
    'UTH',
    'UTP',
    'UTR',
  ],
  TJ: ['DYU', 'LBD'],
  TM: ['ASB', 'CRZ', 'KRW', 'MYP', 'TAZ'],
  TN: ['DJE', 'EBM', 'GAE', 'GAF', 'MIR', 'NBE', 'SFA', 'TBJ', 'TOE', 'TUN'],
  TO: ['EUA', 'NTT', 'TBU'],
  TP: ['BCH', 'DIC', 'DIL'],
  TR: [
    'ADA',
    'ADB',
    'ADF',
    'AFY',
    'AJI',
    'ANK',
    'AOE',
    'ASR',
    'AYT',
    'BAL',
    'BDM',
    'BJV',
    'BTZ',
    'BXN',
    'BZI',
    'CKZ',
    'DIY',
    'DLM',
    'DNZ',
    'EDO',
    'ERC',
    'ERZ',
    'ESB',
    'ESK',
    'EZS',
    'GZT',
    'IGL',
    'ISE',
    'IST',
    'IZM',
    'KCM',
    'KCO',
    'KFS',
    'KSY',
    'KYA',
    'MLX',
    'MQJ',
    'MQM',
    'MSR',
    'MZH',
    'NAV',
    'ONQ',
    'SAW',
    'SFQ',
    'SIC',
    'SQD',
    'SSX',
    'SXZ',
    'SZF',
    'TEQ',
    'TJK',
    'TZX',
    'UAB',
    'USQ',
    'VAN',
    'VAS',
    'YEI',
  ],
  TT: ['POS', 'TAB'],
  TV: ['FUN'],
  TW: [
    'CMJ',
    'CYI',
    'GNI',
    'HCN',
    'HSZ',
    'HUN',
    'KHH',
    'KNH',
    'KYD',
    'LHN',
    'LZN',
    'MFK',
    'MZG',
    'PIF',
    'RMQ',
    'SMT',
    'TNN',
    'TPE',
    'TSA',
    'TTT',
    'TXG',
    'WOT',
  ],
  TZ: [
    'ARK',
    'BKZ',
    'DAR',
    'DOD',
    'GIT',
    'IRI',
    'JOM',
    'JRO',
    'KIY',
    'LDI',
    'LKY',
    'LUY',
    'MBI',
    'MFA',
    'MUZ',
    'MWN',
    'MWZ',
    'MYW',
    'NCH',
    'PMA',
    'SEU',
    'SGX',
    'SHY',
    'SUT',
    'TBO',
    'TGT',
    'TKQ',
    'XMI',
    'ZNZ',
  ],
  UA: [
    'CEJ',
    'CKC',
    'CWC',
    'DNK',
    'DOK',
    'ERD',
    'HMJ',
    'HRK',
    'IEV',
    'IFO',
    'KBP',
    'KCP',
    'KGO',
    'KHC',
    'KHE',
    'KHU',
    'KRQ',
    'KWG',
    'LWO',
    'MPW',
    'MXR',
    'NLV',
    'ODS',
    'OZH',
    'PLV',
    'QOF',
    'QOH',
    'RWN',
    'SEV',
    'SIP',
    'TNL',
    'UCK',
    'UDJ',
    'UKS',
    'UMY',
    'VIN',
    'VSG',
    'ZTR',
  ],
  UG: [
    'EBB',
    'JIN',
    'KBG',
    'KCU',
    'KSE',
    'MBQ',
    'OYG',
    'PAF',
    'RUA',
    'SRT',
    'TRY',
    'ULU',
  ],
  UM: ['AWK', 'JON', 'MDY'],
  UNI: ['CSI'],
  US: [
    'AAF',
    'AAP',
    'ABE',
    'ABI',
    'ABL',
    'ABQ',
    'ABR',
    'ABY',
    'ACB',
    'ACK',
    'ACT',
    'ACV',
    'ACY',
    'ADG',
    'ADK',
    'ADM',
    'ADQ',
    'ADR',
    'ADS',
    'ADT',
    'ADW',
    'AED',
    'AEL',
    'AET',
    'AEX',
    'AFF',
    'AFN',
    'AFO',
    'AFW',
    'AGC',
    'AGN',
    'AGO',
    'AGS',
    'AHC',
    'AHD',
    'AHF',
    'AHH',
    'AHN',
    'AHT',
    'AIA',
    'AIB',
    'AID',
    'AIK',
    'AIN',
    'AIO',
    'AIV',
    'AIY',
    'AIZ',
    'AKB',
    'AKC',
    'AKI',
    'AKK',
    'AKN',
    'AKO',
    'AKP',
    'ALB',
    'ALE',
    'ALI',
    'ALM',
    'ALN',
    'ALO',
    'ALS',
    'ALW',
    'ALX',
    'ALZ',
    'AMA',
    'AMK',
    'AMN',
    'AMW',
    'ANA',
    'ANB',
    'ANC',
    'AND',
    'ANI',
    'ANN',
    'ANP',
    'ANQ',
    'ANV',
    'ANW',
    'ANY',
    'AOH',
    'AOO',
    'AOS',
    'APA',
    'APC',
    'APF',
    'APG',
    'APH',
    'APN',
    'APT',
    'APV',
    'AQY',
    'ARA',
    'ARB',
    'ARC',
    'ARG',
    'ART',
    'ARV',
    'ARX',
    'ASE',
    'ASH',
    'ASL',
    'ASN',
    'ASQ',
    'AST',
    'ASX',
    'ASY',
    'ATE',
    'ATK',
    'ATL',
    'ATO',
    'ATS',
    'ATT',
    'ATU',
    'ATW',
    'ATY',
    'AUG',
    'AUK',
    'AUM',
    'AUN',
    'AUO',
    'AUS',
    'AUW',
    'AUZ',
    'AVL',
    'AVO',
    'AVP',
    'AVW',
    'AVX',
    'AWM',
    'AXB',
    'AXG',
    'AXN',
    'AXS',
    'AXV',
    'AXX',
    'AYE',
    'AYS',
    'AYZ',
    'AZA',
    'AZO',
    'BAB',
    'BAD',
    'BAF',
    'BAM',
    'BAR',
    'BBB',
    'BBC',
    'BBD',
    'BBF',
    'BBW',
    'BBX',
    'BCB',
    'BCC',
    'BCE',
    'BCJ',
    'BCS',
    'BCT',
    'BDE',
    'BDF',
    'BDG',
    'BDL',
    'BDR',
    'BDX',
    'BDY',
    'BEC',
    'BED',
    'BEH',
    'BET',
    'BFB',
    'BFD',
    'BFF',
    'BFG',
    'BFI',
    'BFK',
    'BFL',
    'BFM',
    'BFP',
    'BFR',
    'BFT',
    'BGD',
    'BGE',
    'BGM',
    'BGQ',
    'BGR',
    'BGS',
    'BGT',
    'BHB',
    'BHC',
    'BHM',
    'BIC',
    'BID',
    'BIE',
    'BIF',
    'BIG',
    'BIH',
    'BIL',
    'BIS',
    'BIX',
    'BJC',
    'BJI',
    'BJJ',
    'BKC',
    'BKD',
    'BKE',
    'BKF',
    'BKG',
    'BKH',
    'BKL',
    'BKT',
    'BKW',
    'BKX',
    'BLD',
    'BLF',
    'BLH',
    'BLI',
    'BLM',
    'BLU',
    'BLV',
    'BLW',
    'BMC',
    'BMG',
    'BMI',
    'BML',
    'BMT',
    'BMX',
    'BNA',
    'BNF',
    'BNG',
    'BNH',
    'BNL',
    'BNO',
    'BNW',
    'BOF',
    'BOI',
    'BOK',
    'BOS',
    'BOW',
    'BPA',
    'BPI',
    'BPT',
    'BQK',
    'BQV',
    'BRD',
    'BRG',
    'BRL',
    'BRO',
    'BRW',
    'BRY',
    'BSF',
    'BSI',
    'BSQ',
    'BSW',
    'BSZ',
    'BTF',
    'BTI',
    'BTL',
    'BTM',
    'BTN',
    'BTP',
    'BTR',
    'BTT',
    'BTV',
    'BTY',
    'BUB',
    'BUF',
    'BUM',
    'BUR',
    'BVD',
    'BVO',
    'BVU',
    'BVX',
    'BVY',
    'BWC',
    'BWD',
    'BWG',
    'BWI',
    'BWL',
    'BWM',
    'BWS',
    'BXA',
    'BXC',
    'BXK',
    'BXS',
    'BYA',
    'BYG',
    'BYH',
    'BYI',
    'BYS',
    'BYW',
    'BZN',
    'BZS',
    'BZT',
    'CAD',
    'CAE',
    'CAK',
    'CAO',
    'CAR',
    'CBA',
    'CBE',
    'CBF',
    'CBK',
    'CBM',
    'CBZ',
    'CCA',
    'CCB',
    'CCD',
    'CCG',
    'CCR',
    'CCY',
    'CDB',
    'CDC',
    'CDH',
    'CDK',
    'CDL',
    'CDN',
    'CDR',
    'CDS',
    'CDV',
    'CDW',
    'CEA',
    'CEC',
    'CEF',
    'CEM',
    'CEU',
    'CEV',
    'CEW',
    'CEX',
    'CEY',
    'CEZ',
    'CFA',
    'CFD',
    'CFK',
    'CFT',
    'CFV',
    'CGA',
    'CGE',
    'CGF',
    'CGI',
    'CGS',
    'CGX',
    'CGZ',
    'CHA',
    'CHD',
    'CHI',
    'CHK',
    'CHL',
    'CHO',
    'CHP',
    'CHS',
    'CHU',
    'CHZ',
    'CIB',
    'CIC',
    'CID',
    'CIG',
    'CIK',
    'CIL',
    'CIN',
    'CIR',
    'CIU',
    'CIV',
    'CJN',
    'CKA',
    'CKB',
    'CKD',
    'CKE',
    'CKK',
    'CKM',
    'CKN',
    'CKR',
    'CKU',
    'CKV',
    'CKX',
    'CLC',
    'CLD',
    'CLE',
    'CLG',
    'CLI',
    'CLK',
    'CLL',
    'CLM',
    'CLP',
    'CLR',
    'CLS',
    'CLT',
    'CLU',
    'CLW',
    'CMH',
    'CMI',
    'CMX',
    'CMY',
    'CNE',
    'CNH',
    'CNK',
    'CNM',
    'CNO',
    'CNU',
    'CNW',
    'CNY',
    'COA',
    'COD',
    'COF',
    'COI',
    'COM',
    'CON',
    'COP',
    'COS',
    'COT',
    'COU',
    'CPM',
    'CPR',
    'CPS',
    'CRE',
    'CRG',
    'CRO',
    'CRP',
    'CRS',
    'CRT',
    'CRW',
    'CRX',
    'CSE',
    'CSG',
    'CSM',
    'CSN',
    'CSP',
    'CSQ',
    'CSV',
    'CTB',
    'CTH',
    'CTK',
    'CTO',
    'CTW',
    'CTX',
    'CTY',
    'CTZ',
    'CUB',
    'CUH',
    'CUS',
    'CUW',
    'CUX',
    'CVA',
    'CVG',
    'CVN',
    'CVO',
    'CVR',
    'CVS',
    'CWA',
    'CWG',
    'CWI',
    'CWO',
    'CWS',
    'CXC',
    'CXF',
    'CXL',
    'CXO',
    'CYE',
    'CYF',
    'CYM',
    'CYS',
    'CYT',
    'CZC',
    'CZF',
    'CZK',
    'CZN',
    'CZO',
    'CZP',
    'CZT',
    'CZZ',
    'DAA',
    'DAB',
    'DAG',
    'DAL',
    'DAN',
    'DAY',
    'DBN',
    'DBQ',
    'DBS',
    'DCA',
    'DCK',
    'DCR',
    'DCU',
    'DDC',
    'DEC',
    'DEH',
    'DEN',
    'DEO',
    'DET',
    'DFI',
    'DFW',
    'DGB',
    'DGF',
    'DGL',
    'DGN',
    'DGW',
    'DHN',
    'DHT',
    'DIK',
    'DIO',
    'DJN',
    'DKK',
    'DLF',
    'DLG',
    'DLH',
    'DLL',
    'DLN',
    'DLO',
    'DLS',
    'DMA',
    'DMN',
    'DMO',
    'DNE',
    'DNL',
    'DNN',
    'DNS',
    'DNT',
    'DNV',
    'DOF',
    'DOV',
    'DPA',
    'DPG',
    'DPK',
    'DRA',
    'DRE',
    'DRF',
    'DRG',
    'DRI',
    'DRO',
    'DRT',
    'DRU',
    'DSI',
    'DSM',
    'DSV',
    'DTA',
    'DTH',
    'DTL',
    'DTN',
    'DTR',
    'DTW',
    'DUA',
    'DUC',
    'DUF',
    'DUG',
    'DUJ',
    'DUT',
    'DVL',
    'DVN',
    'DVT',
    'DWF',
    'DWH',
    'DWN',
    'DWS',
    'DXR',
    'DYL',
    'DYS',
    'EAA',
    'EAN',
    'EAR',
    'EAT',
    'EAU',
    'EBR',
    'EBS',
    'ECA',
    'ECG',
    'ECS',
    'EDA',
    'EDE',
    'EDF',
    'EDG',
    'EDK',
    'EDW',
    'EED',
    'EEK',
    'EEN',
    'EFB',
    'EFD',
    'EFK',
    'EFO',
    'EFW',
    'EGE',
    'EGI',
    'EGP',
    'EGV',
    'EGX',
    'EHM',
    'EHT',
    'EIL',
    'EKA',
    'EKI',
    'EKN',
    'EKO',
    'EKX',
    'ELA',
    'ELD',
    'ELI',
    'ELK',
    'ELM',
    'ELN',
    'ELP',
    'ELV',
    'ELW',
    'ELY',
    'ELZ',
    'EMB',
    'EMK',
    'EMM',
    'EMP',
    'EMT',
    'ENA',
    'END',
    'ENL',
    'ENN',
    'ENV',
    'ENW',
    'EOK',
    'EOS',
    'EPG',
    'EPH',
    'ERI',
    'ERO',
    'ERR',
    'ERV',
    'ESC',
    'ESD',
    'ESF',
    'ESN',
    'ESO',
    'ESP',
    'EST',
    'ESW',
    'ETB',
    'ETN',
    'ETS',
    'EUE',
    'EUF',
    'EUG',
    'EVA',
    'EVM',
    'EVV',
    'EVW',
    'EWB',
    'EWD',
    'EWK',
    'EWN',
    'EWR',
    'EXI',
    'EYR',
    'EYW',
    'FAF',
    'FAI',
    'FAK',
    'FAL',
    'FAM',
    'FAR',
    'FAT',
    'FAY',
    'FBG',
    'FBK',
    'FBL',
    'FBR',
    'FBS',
    'FBY',
    'FCA',
    'FCH',
    'FCM',
    'FCS',
    'FCT',
    'FCY',
    'FDK',
    'FDR',
    'FDY',
    'FEP',
    'FET',
    'FEW',
    'FFA',
    'FFL',
    'FFM',
    'FFO',
    'FFT',
    'FHU',
    'FIC',
    'FID',
    'FIL',
    'FIV',
    'FKL',
    'FKN',
    'FLD',
    'FLE',
    'FLG',
    'FLJ',
    'FLL',
    'FLO',
    'FLP',
    'FLT',
    'FLU',
    'FLV',
    'FLX',
    'FMC',
    'FME',
    'FMH',
    'FMN',
    'FMS',
    'FMY',
    'FNK',
    'FNL',
    'FNR',
    'FNT',
    'FOB',
    'FOD',
    'FOE',
    'FOK',
    'FOP',
    'FOX',
    'FPR',
    'FPY',
    'FRD',
    'FRG',
    'FRH',
    'FRI',
    'FRM',
    'FRN',
    'FRP',
    'FRR',
    'FRY',
    'FSD',
    'FSI',
    'FSK',
    'FSM',
    'FSN',
    'FST',
    'FSU',
    'FTK',
    'FTL',
    'FTW',
    'FTY',
    'FUL',
    'FWA',
    'FWH',
    'FWL',
    'FXE',
    'FXM',
    'FXY',
    'FYM',
    'FYU',
    'FYV',
    'GAB',
    'GAD',
    'GAG',
    'GAI',
    'GAK',
    'GAL',
    'GAM',
    'GBD',
    'GBG',
    'GBH',
    'GBO',
    'GBR',
    'GCC',
    'GCK',
    'GCN',
    'GCY',
    'GDC',
    'GDH',
    'GDM',
    'GDV',
    'GDW',
    'GED',
    'GEG',
    'GEK',
    'GEY',
    'GFA',
    'GFB',
    'GFD',
    'GFK',
    'GFL',
    'GGE',
    'GGG',
    'GGW',
    'GHM',
    'GIF',
    'GJT',
    'GKN',
    'GKT',
    'GLD',
    'GLE',
    'GLH',
    'GLQ',
    'GLR',
    'GLS',
    'GLV',
    'GLW',
    'GMT',
    'GMU',
    'GMV',
    'GNG',
    'GNT',
    'GNU',
    'GNV',
    'GOF',
    'GOK',
    'GOL',
    'GON',
    'GPT',
    'GPZ',
    'GQQ',
    'GRB',
    'GRD',
    'GRE',
    'GRF',
    'GRI',
    'GRK',
    'GRM',
    'GRN',
    'GRR',
    'GSB',
    'GSH',
    'GSO',
    'GSP',
    'GST',
    'GTF',
    'GTG',
    'GTR',
    'GTY',
    'GUC',
    'GUF',
    'GUN',
    'GUP',
    'GUS',
    'GUY',
    'GVE',
    'GVL',
    'GVT',
    'GVW',
    'GWO',
    'GWS',
    'GWV',
    'GXY',
    'GYR',
    'GYY',
    'HAB',
    'HAE',
    'HAF',
    'HAI',
    'HAO',
    'HAR',
    'HAX',
    'HAY',
    'HBB',
    'HBC',
    'HBG',
    'HBH',
    'HBO',
    'HBR',
    'HCA',
    'HCB',
    'HCC',
    'HCR',
    'HCW',
    'HDA',
    'HDE',
    'HDH',
    'HDN',
    'HED',
    'HEE',
    'HES',
    'HEY',
    'HEZ',
    'HFD',
    'HFF',
    'HGR',
    'HGT',
    'HGZ',
    'HHH',
    'HHI',
    'HHR',
    'HIB',
    'HIE',
    'HIF',
    'HII',
    'HIK',
    'HIO',
    'HKA',
    'HKB',
    'HKS',
    'HKY',
    'HLB',
    'HLC',
    'HLG',
    'HLI',
    'HLM',
    'HLN',
    'HLR',
    'HMN',
    'HMS',
    'HMT',
    'HNB',
    'HNC',
    'HNE',
    'HNH',
    'HNL',
    'HNM',
    'HNS',
    'HOB',
    'HOL',
    'HOM',
    'HON',
    'HOP',
    'HOT',
    'HOU',
    'HPB',
    'HPN',
    'HPT',
    'HPV',
    'HPY',
    'HQM',
    'HRL',
    'HRO',
    'HSB',
    'HSH',
    'HSI',
    'HSL',
    'HSP',
    'HST',
    'HSV',
    'HTH',
    'HTL',
    'HTO',
    'HTS',
    'HTW',
    'HUA',
    'HUD',
    'HUF',
    'HUJ',
    'HUL',
    'HUM',
    'HUS',
    'HUT',
    'HVE',
    'HVN',
    'HVR',
    'HVS',
    'HWD',
    'HWI',
    'HWO',
    'HYA',
    'HYG',
    'HYL',
    'HYR',
    'HYS',
    'HZL',
    'IAB',
    'IAD',
    'IAG',
    'IAH',
    'IAN',
    'ICL',
    'ICT',
    'ICY',
    'IDA',
    'IDG',
    'IDI',
    'IDP',
    'IFA',
    'IFP',
    'IGG',
    'IGM',
    'IJX',
    'IKB',
    'IKK',
    'IKO',
    'ILE',
    'ILG',
    'ILI',
    'ILL',
    'ILM',
    'ILN',
    'IML',
    'IMM',
    'IMT',
    'IND',
    'INK',
    'INL',
    'INR',
    'INS',
    'INT',
    'INW',
    'IOW',
    'IPL',
    'IPT',
    'IRB',
    'IRC',
    'IRK',
    'IRS',
    'ISL',
    'ISM',
    'ISN',
    'ISO',
    'ISP',
    'ISQ',
    'ISS',
    'ISW',
    'ISZ',
    'ITH',
    'ITO',
    'IVH',
    'IWD',
    'IWS',
    'IYK',
    'JAC',
    'JAE',
    'JAJ',
    'JAN',
    'JAO',
    'JAR',
    'JAS',
    'JAX',
    'JBC',
    'JBK',
    'JBP',
    'JBR',
    'JBS',
    'JBT',
    'JCC',
    'JCE',
    'JCI',
    'JCT',
    'JCX',
    'JCY',
    'JDA',
    'JDB',
    'JDM',
    'JDN',
    'JDT',
    'JDX',
    'JDY',
    'JEF',
    'JEM',
    'JFK',
    'JFN',
    'JGC',
    'JGL',
    'JGP',
    'JGQ',
    'JGX',
    'JHC',
    'JHM',
    'JHW',
    'JHY',
    'JID',
    'JIO',
    'JKV',
    'JLA',
    'JLB',
    'JLH',
    'JLN',
    'JLX',
    'JMA',
    'JMC',
    'JMD',
    'JMH',
    'JMN',
    'JMS',
    'JNH',
    'JNP',
    'JNU',
    'JOC',
    'JOR',
    'JOT',
    'JPD',
    'JPN',
    'JPT',
    'JRA',
    'JRB',
    'JRC',
    'JRD',
    'JRE',
    'JSD',
    'JSG',
    'JSK',
    'JSL',
    'JSN',
    'JST',
    'JTO',
    'JUC',
    'JUP',
    'JVI',
    'JVL',
    'JWC',
    'JWH',
    'JWL',
    'JXN',
    'KAE',
    'KAL',
    'KBC',
    'KBE',
    'KBK',
    'KBW',
    'KCC',
    'KCG',
    'KCK',
    'KCL',
    'KCN',
    'KCQ',
    'KCR',
    'KDK',
    'KEB',
    'KEH',
    'KEK',
    'KEU',
    'KFP',
    'KGK',
    'KGX',
    'KGZ',
    'KIB',
    'KIC',
    'KIP',
    'KKA',
    'KKB',
    'KKF',
    'KKH',
    'KKI',
    'KKK',
    'KKL',
    'KKT',
    'KKU',
    'KLG',
    'KLL',
    'KLN',
    'KLP',
    'KLS',
    'KLW',
    'KMO',
    'KMY',
    'KNB',
    'KNK',
    'KNT',
    'KNW',
    'KOA',
    'KOT',
    'KOY',
    'KOZ',
    'KPB',
    'KPC',
    'KPD',
    'KPH',
    'KPK',
    'KPN',
    'KPR',
    'KPT',
    'KPV',
    'KPY',
    'KQA',
    'KSM',
    'KSR',
    'KTB',
    'KTH',
    'KTN',
    'KTS',
    'KUK',
    'KUY',
    'KVC',
    'KVL',
    'KWF',
    'KWK',
    'KWN',
    'KWP',
    'KWT',
    'KXA',
    'KYK',
    'KYL',
    'KYO',
    'KYU',
    'KZB',
    'KZH',
    'LAA',
    'LAF',
    'LAL',
    'LAM',
    'LAN',
    'LAR',
    'LAS',
    'LAW',
    'LAX',
    'LBB',
    'LBE',
    'LBF',
    'LBL',
    'LBT',
    'LCH',
    'LCI',
    'LCK',
    'LDJ',
    'LDM',
    'LEB',
    'LEE',
    'LEM',
    'LEW',
    'LEX',
    'LFI',
    'LFK',
    'LFN',
    'LFT',
    'LGA',
    'LGB',
    'LGC',
    'LGD',
    'LGF',
    'LGU',
    'LHB',
    'LHM',
    'LHV',
    'LIC',
    'LIH',
    'LIJ',
    'LIT',
    'LIV',
    'LIY',
    'LIZ',
    'LJC',
    'LJN',
    'LKE',
    'LKI',
    'LKK',
    'LKP',
    'LKS',
    'LKV',
    'LLX',
    'LLY',
    'LMA',
    'LMS',
    'LMT',
    'LNA',
    'LND',
    'LNI',
    'LNK',
    'LNN',
    'LNP',
    'LNR',
    'LNS',
    'LNY',
    'LOG',
    'LOL',
    'LOR',
    'LOT',
    'LOU',
    'LOW',
    'LOZ',
    'LPC',
    'LPO',
    'LPS',
    'LPW',
    'LQK',
    'LRD',
    'LRF',
    'LRJ',
    'LRK',
    'LRN',
    'LRO',
    'LRU',
    'LSB',
    'LSD',
    'LSE',
    'LSF',
    'LSK',
    'LSN',
    'LSR',
    'LSV',
    'LTH',
    'LTS',
    'LTW',
    'LUF',
    'LUK',
    'LUL',
    'LUP',
    'LUR',
    'LVD',
    'LVK',
    'LVL',
    'LVM',
    'LVS',
    'LWB',
    'LWC',
    'LWL',
    'LWM',
    'LWS',
    'LWT',
    'LWV',
    'LXN',
    'LXV',
    'LYH',
    'LYO',
    'LYU',
    'LZU',
    'MAC',
    'MAE',
    'MAF',
    'MAW',
    'MBG',
    'MBL',
    'MBS',
    'MBY',
    'MCB',
    'MCC',
    'MCD',
    'MCE',
    'MCF',
    'MCG',
    'MCI',
    'MCK',
    'MCL',
    'MCN',
    'MCO',
    'MCW',
    'MDA',
    'MDD',
    'MDF',
    'MDH',
    'MDJ',
    'MDN',
    'MDO',
    'MDR',
    'MDT',
    'MDW',
    'MEI',
    'MEJ',
    'MEM',
    'MEO',
    'MER',
    'MEV',
    'MFD',
    'MFE',
    'MFH',
    'MFI',
    'MFR',
    'MFV',
    'MGC',
    'MGE',
    'MGI',
    'MGJ',
    'MGM',
    'MGR',
    'MGW',
    'MGY',
    'MHE',
    'MHK',
    'MHL',
    'MHM',
    'MHN',
    'MHR',
    'MHS',
    'MHT',
    'MHV',
    'MIA',
    'MIB',
    'MIC',
    'MIE',
    'MIF',
    'MIO',
    'MIT',
    'MIV',
    'MIW',
    'MJQ',
    'MJX',
    'MKC',
    'MKE',
    'MKF',
    'MKG',
    'MKK',
    'MKL',
    'MKO',
    'MKT',
    'MLB',
    'MLC',
    'MLD',
    'MLF',
    'MLI',
    'MLJ',
    'MLK',
    'MLL',
    'MLS',
    'MLT',
    'MLU',
    'MLY',
    'MMH',
    'MMI',
    'MML',
    'MMN',
    'MMR',
    'MMS',
    'MMT',
    'MMU',
    'MNM',
    'MNN',
    'MNT',
    'MNZ',
    'MOB',
    'MOD',
    'MOP',
    'MOR',
    'MOS',
    'MOT',
    'MOU',
    'MOX',
    'MPB',
    'MPE',
    'MPJ',
    'MPO',
    'MPR',
    'MPS',
    'MPV',
    'MPZ',
    'MQB',
    'MQI',
    'MQT',
    'MQW',
    'MQY',
    'MRB',
    'MRC',
    'MRF',
    'MRI',
    'MRK',
    'MRN',
    'MRY',
    'MSC',
    'MSD',
    'MSL',
    'MSN',
    'MSO',
    'MSP',
    'MSS',
    'MSV',
    'MSY',
    'MTC',
    'MTH',
    'MTJ',
    'MTM',
    'MTN',
    'MTO',
    'MTP',
    'MTW',
    'MTX',
    'MUE',
    'MUI',
    'MUL',
    'MUO',
    'MUT',
    'MUU',
    'MUV',
    'MVC',
    'MVE',
    'MVL',
    'MVM',
    'MVN',
    'MVW',
    'MWA',
    'MWC',
    'MWH',
    'MWL',
    'MWM',
    'MWO',
    'MWS',
    'MXA',
    'MXC',
    'MXE',
    'MXF',
    'MXG',
    'MXO',
    'MXY',
    'MYF',
    'MYH',
    'MYK',
    'MYL',
    'MYR',
    'MYU',
    'MYV',
    'MZJ',
    'MZZ',
    'NAB',
    'NAX',
    'NBG',
    'NBP',
    'NBU',
    'NCN',
    'NCO',
    'NCQ',
    'NDP',
    'NDV',
    'NEA',
    'NEL',
    'NEN',
    'NES',
    'NEW',
    'NFB',
    'NFL',
    'NGC',
    'NGP',
    'NGU',
    'NGW',
    'NGZ',
    'NHK',
    'NHX',
    'NHZ',
    'NIB',
    'NIE',
    'NIN',
    'NIP',
    'NIR',
    'NJK',
    'NKI',
    'NKV',
    'NKX',
    'NLC',
    'NLE',
    'NLG',
    'NME',
    'NNK',
    'NNL',
    'NOH',
    'NOL',
    'NOT',
    'NPA',
    'NPH',
    'NPT',
    'NQA',
    'NQI',
    'NQX',
    'NRB',
    'NRC',
    'NRI',
    'NRS',
    'NSE',
    'NSF',
    'NTD',
    'NTJ',
    'NTU',
    'NUI',
    'NUL',
    'NUN',
    'NUP',
    'NUQ',
    'NUW',
    'NVD',
    'NWH',
    'NWS',
    'NXX',
    'NYC',
    'NYG',
    'NZW',
    'NZY',
    'OAJ',
    'OAK',
    'OAR',
    'OBE',
    'OBT',
    'OBU',
    'OCA',
    'OCE',
    'OCF',
    'OCH',
    'OCI',
    'OCN',
    'OCW',
    'ODW',
    'OEA',
    'OEO',
    'OFK',
    'OGA',
    'OGB',
    'OGD',
    'OGG',
    'OGS',
    'OHC',
    'OIC',
    'OIL',
    'OJC',
    'OKC',
    'OKK',
    'OKM',
    'OKS',
    'OLD',
    'OLE',
    'OLF',
    'OLH',
    'OLM',
    'OLS',
    'OLU',
    'OLV',
    'OLY',
    'OMA',
    'OME',
    'OMK',
    'ONA',
    'ONH',
    'ONL',
    'ONM',
    'ONN',
    'ONO',
    'ONP',
    'ONT',
    'ONY',
    'OOA',
    'OOK',
    'OPF',
    'OPL',
    'ORD',
    'ORF',
    'ORH',
    'ORI',
    'ORL',
    'ORQ',
    'ORT',
    'ORV',
    'OSB',
    'OSC',
    'OSH',
    'OSU',
    'OSX',
    'OTG',
    'OTH',
    'OTM',
    'OTN',
    'OTO',
    'OTS',
    'OTZ',
    'OUN',
    'OVE',
    'OWA',
    'OWB',
    'OWD',
    'OWK',
    'OXC',
    'OXD',
    'OXR',
    'OYS',
    'OZA',
    'OZR',
    'PAE',
    'PAH',
    'PAK',
    'PAM',
    'PAO',
    'PAQ',
    'PBA',
    'PBF',
    'PBG',
    'PBI',
    'PBK',
    'PCA',
    'PCD',
    'PCE',
    'PCK',
    'PCT',
    'PCU',
    'PDB',
    'PDK',
    'PDT',
    'PDX',
    'PEC',
    'PEQ',
    'PFA',
    'PFC',
    'PFD',
    'PFN',
    'PGA',
    'PGC',
    'PGD',
    'PGL',
    'PGM',
    'PGO',
    'PGR',
    'PGS',
    'PGV',
    'PHD',
    'PHF',
    'PHK',
    'PHL',
    'PHN',
    'PHO',
    'PHP',
    'PHT',
    'PHX',
    'PIA',
    'PIB',
    'PIE',
    'PIH',
    'PII',
    'PIM',
    'PIP',
    'PIR',
    'PIT',
    'PIZ',
    'PJB',
    'PJS',
    'PKA',
    'PKB',
    'PKD',
    'PKF',
    'PLB',
    'PLK',
    'PLN',
    'PLR',
    'PLY',
    'PMB',
    'PMD',
    'PMH',
    'PML',
    'PMU',
    'PMX',
    'PNC',
    'PNE',
    'PNN',
    'PNS',
    'PNU',
    'PNX',
    'POB',
    'POC',
    'POE',
    'POF',
    'POH',
    'POQ',
    'POU',
    'POY',
    'PPA',
    'PPC',
    'PPF',
    'PPM',
    'PPV',
    'PQI',
    'PQS',
    'PRB',
    'PRC',
    'PRF',
    'PRL',
    'PRO',
    'PRT',
    'PRW',
    'PRX',
    'PRZ',
    'PSB',
    'PSC',
    'PSF',
    'PSG',
    'PSK',
    'PSM',
    'PSN',
    'PSP',
    'PSQ',
    'PSX',
    'PTA',
    'PTB',
    'PTC',
    'PTD',
    'PTH',
    'PTK',
    'PTL',
    'PTN',
    'PTR',
    'PTS',
    'PTT',
    'PTU',
    'PTV',
    'PTW',
    'PUB',
    'PUC',
    'PUL',
    'PUO',
    'PUW',
    'PVC',
    'PVD',
    'PVF',
    'PVU',
    'PVW',
    'PVY',
    'PVZ',
    'PWA',
    'PWD',
    'PWK',
    'PWM',
    'PWR',
    'PWT',
    'PXL',
    'PYL',
    'PYM',
    'QAW',
    'QBF',
    'QCE',
    'QKB',
    'QKS',
    'QMV',
    'QNY',
    'QWF',
    'QWG',
    'QWP',
    'RAC',
    'RAL',
    'RAP',
    'RBD',
    'RBF',
    'RBG',
    'RBH',
    'RBK',
    'RBL',
    'RBN',
    'RBW',
    'RBY',
    'RCA',
    'RCE',
    'RCK',
    'RCP',
    'RCR',
    'RCT',
    'RDB',
    'RDD',
    'RDG',
    'RDM',
    'RDR',
    'RDU',
    'RDV',
    'RED',
    'REE',
    'REH',
    'REO',
    'RFD',
    'RFG',
    'RFK',
    'RGR',
    'RHI',
    'RHV',
    'RIC',
    'RID',
    'RIE',
    'RIF',
    'RIL',
    'RIR',
    'RIV',
    'RIW',
    'RKC',
    'RKD',
    'RKH',
    'RKP',
    'RKR',
    'RKS',
    'RKW',
    'RLA',
    'RLD',
    'RLI',
    'RLU',
    'RMC',
    'RME',
    'RMG',
    'RMP',
    'RNC',
    'RND',
    'RNG',
    'RNH',
    'RNO',
    'RNT',
    'RNZ',
    'ROA',
    'ROC',
    'ROG',
    'ROL',
    'ROW',
    'ROX',
    'RPX',
    'RRL',
    'RRT',
    'RSH',
    'RSJ',
    'RSL',
    'RSN',
    'RSP',
    'RST',
    'RSW',
    'RSX',
    'RTD',
    'RTE',
    'RTL',
    'RTN',
    'RUI',
    'RUT',
    'RVR',
    'RVS',
    'RWB',
    'RWF',
    'RWI',
    'RWL',
    'RZH',
    'RZZ',
    'SAA',
    'SAC',
    'SAD',
    'SAF',
    'SAG',
    'SAN',
    'SAR',
    'SAS',
    'SAT',
    'SAV',
    'SBA',
    'SBD',
    'SBM',
    'SBN',
    'SBO',
    'SBP',
    'SBS',
    'SBT',
    'SBX',
    'SBY',
    'SCB',
    'SCC',
    'SCE',
    'SCF',
    'SCH',
    'SCJ',
    'SCK',
    'SCM',
    'SCR',
    'SDF',
    'SDM',
    'SDP',
    'SDX',
    'SDY',
    'SEA',
    'SEE',
    'SEF',
    'SEG',
    'SEM',
    'SEP',
    'SER',
    'SES',
    'SFB',
    'SFF',
    'SFM',
    'SFO',
    'SFZ',
    'SGF',
    'SGH',
    'SGR',
    'SGT',
    'SGU',
    'SGW',
    'SGY',
    'SHD',
    'SHG',
    'SHH',
    'SHN',
    'SHR',
    'SHV',
    'SHX',
    'SIK',
    'SIT',
    'SIV',
    'SIY',
    'SJC',
    'SJN',
    'SJT',
    'SKA',
    'SKF',
    'SKJ',
    'SKK',
    'SKW',
    'SKY',
    'SLB',
    'SLC',
    'SLE',
    'SLG',
    'SLJ',
    'SLK',
    'SLN',
    'SLO',
    'SLQ',
    'SLR',
    'SLT',
    'SMD',
    'SME',
    'SMF',
    'SMK',
    'SMN',
    'SMO',
    'SMU',
    'SMX',
    'SNA',
    'SNK',
    'SNL',
    'SNP',
    'SNS',
    'SNY',
    'SOL',
    'SOP',
    'SOV',
    'SOW',
    'SPA',
    'SPF',
    'SPG',
    'SPI',
    'SPQ',
    'SPS',
    'SPW',
    'SPX',
    'SPZ',
    'SQA',
    'SQI',
    'SQL',
    'SQV',
    'SRC',
    'SRF',
    'SRQ',
    'SRU',
    'SRV',
    'SRW',
    'SSC',
    'SSF',
    'SSI',
    'SSU',
    'SSW',
    'STC',
    'STE',
    'STG',
    'STJ',
    'STK',
    'STL',
    'STP',
    'STQ',
    'STS',
    'SUA',
    'SUC',
    'SUD',
    'SUE',
    'SUM',
    'SUN',
    'SUO',
    'SUS',
    'SUU',
    'SUW',
    'SUX',
    'SVA',
    'SVC',
    'SVE',
    'SVH',
    'SVN',
    'SVS',
    'SVW',
    'SWD',
    'SWF',
    'SWO',
    'SWW',
    'SXC',
    'SXP',
    'SXQ',
    'SXY',
    'SYA',
    'SYB',
    'SYI',
    'SYL',
    'SYN',
    'SYR',
    'SYV',
    'SZL',
    'SZN',
    'SZP',
    'TAD',
    'TAL',
    'TBC',
    'TBN',
    'TBR',
    'TCC',
    'TCL',
    'TCM',
    'TCS',
    'TCT',
    'TDO',
    'TDW',
    'TDZ',
    'TEB',
    'TEH',
    'TEK',
    'TEX',
    'TGE',
    'THA',
    'THM',
    'THP',
    'THV',
    'TIK',
    'TIW',
    'TIX',
    'TKA',
    'TKE',
    'TKF',
    'TKI',
    'TKJ',
    'TKL',
    'TLA',
    'TLF',
    'TLH',
    'TLJ',
    'TLR',
    'TLT',
    'TMA',
    'TMB',
    'TNC',
    'TNK',
    'TNP',
    'TNT',
    'TNU',
    'TOA',
    'TOC',
    'TOG',
    'TOI',
    'TOL',
    'TOP',
    'TOR',
    'TPA',
    'TPH',
    'TPL',
    'TPO',
    'TRH',
    'TRI',
    'TRL',
    'TRM',
    'TRP',
    'TRT',
    'TRX',
    'TSG',
    'TSM',
    'TSP',
    'TSS',
    'TTD',
    'TTN',
    'TTO',
    'TUH',
    'TUL',
    'TUP',
    'TUS',
    'TVC',
    'TVF',
    'TVI',
    'TVL',
    'TWA',
    'TWD',
    'TWE',
    'TWF',
    'TWH',
    'TXK',
    'TYE',
    'TYR',
    'TYS',
    'TYZ',
    'UBS',
    'UCA',
    'UCC',
    'UCE',
    'UCY',
    'UDD',
    'UES',
    'UGB',
    'UGI',
    'UGN',
    'UGS',
    'UIL',
    'UIN',
    'UIZ',
    'UKI',
    'UKN',
    'UKT',
    'ULM',
    'UMB',
    'UMM',
    'UMT',
    'UNK',
    'UNS',
    'UNU',
    'UOS',
    'UOX',
    'UPP',
    'UQE',
    'UST',
    'UTO',
    'UUK',
    'UVA',
    'UWA',
    'VAD',
    'VAK',
    'VBG',
    'VCB',
    'VCT',
    'VCV',
    'VDI',
    'VDZ',
    'VEE',
    'VEL',
    'VEX',
    'VGT',
    'VHN',
    'VIH',
    'VIK',
    'VIS',
    'VJI',
    'VKS',
    'VKW',
    'VLA',
    'VLD',
    'VLE',
    'VLO',
    'VNC',
    'VNY',
    'VOK',
    'VPS',
    'VPZ',
    'VQQ',
    'VRB',
    'VRS',
    'VSF',
    'VTN',
    'VYS',
    'WAA',
    'WAH',
    'WAL',
    'WAX',
    'WAY',
    'WBB',
    'WBI',
    'WBN',
    'WBQ',
    'WBR',
    'WBU',
    'WBW',
    'WCR',
    'WDB',
    'WDG',
    'WDN',
    'WDR',
    'WDY',
    'WEA',
    'WFB',
    'WFK',
    'WGO',
    'WHD',
    'WHH',
    'WHP',
    'WHT',
    'WJF',
    'WKK',
    'WKL',
    'WLB',
    'WLD',
    'WLK',
    'WLM',
    'WLN',
    'WLR',
    'WLW',
    'WMC',
    'WMH',
    'WMK',
    'WMO',
    'WNA',
    'WNC',
    'WOD',
    'WOO',
    'WOW',
    'WPO',
    'WRB',
    'WRG',
    'WRH',
    'WRI',
    'WRL',
    'WSB',
    'WSD',
    'WSF',
    'WSG',
    'WSH',
    'WSJ',
    'WSM',
    'WSN',
    'WST',
    'WSX',
    'WTC',
    'WTK',
    'WTL',
    'WTR',
    'WVI',
    'WVL',
    'WWA',
    'WWD',
    'WWP',
    'WWR',
    'WWS',
    'WWT',
    'WYB',
    'WYS',
    'XES',
    'XMD',
    'XNA',
    'XPR',
    'XPU',
    'XSD',
    'XSM',
    'XZU',
    'YAK',
    'YIP',
    'YKM',
    'YKN',
    'YNG',
    'YUM',
    'ZBP',
    'ZBV',
    'ZFZ',
    'ZGD',
    'ZHC',
    'ZME',
    'ZML',
    'ZNC',
    'ZPH',
    'ZRD',
    'ZRF',
    'ZRK',
    'ZRL',
    'ZRP',
    'ZRT',
    'ZRU',
    'ZRV',
    'ZRZ',
    'ZSF',
    'ZSH',
    'ZTD',
    'ZTE',
    'ZTF',
    'ZTJ',
    'ZTL',
    'ZTN',
    'ZTO',
    'ZTY',
    'ZUA',
    'ZUG',
    'ZVE',
    'ZWB',
    'ZWI',
    'ZWU',
    'ZWW',
    'ZXS',
    'ZYQ',
    'ZZV',
  ],
  UY: [
    'ATI',
    'BUV',
    'CYR',
    'DZO',
    'MLZ',
    'MVD',
    'PDP',
    'PDU',
    'RVY',
    'STY',
    'TAW',
    'TYT',
    'VCH',
  ],
  UZ: [
    'AFS',
    'AZN',
    'BHK',
    'FEG',
    'KSQ',
    'NCU',
    'NMA',
    'NVI',
    'SKD',
    'TAS',
    'TMJ',
    'UGC',
  ],
  VC: ['CIW', 'MQS', 'PLI', 'SVD', 'UNI'],
  VE: [
    'AAO',
    'AGV',
    'BLA',
    'BNS',
    'BRM',
    'CAJ',
    'CBL',
    'CBS',
    'CCS',
    'CGU',
    'CLZ',
    'CUM',
    'CUP',
    'CUV',
    'CXA',
    'CZE',
    'ELX',
    'EOR',
    'EOZ',
    'GDO',
    'GUI',
    'GUQ',
    'ICA',
    'KAV',
    'KTV',
    'LAG',
    'LFR',
    'LGY',
    'LPJ',
    'LRV',
    'LSP',
    'MAR',
    'MIQ',
    'MRD',
    'MUN',
    'MYC',
    'PBL',
    'PDZ',
    'PMV',
    'PPH',
    'PPZ',
    'PTM',
    'PYH',
    'PZO',
    'SBB',
    'SCI',
    'SFD',
    'SFX',
    'SNF',
    'SNV',
    'SOM',
    'STB',
    'STD',
    'SVV',
    'SVZ',
    'TMO',
    'TUV',
    'URM',
    'VCR',
    'VDP',
    'VIG',
    'VLN',
    'VLV',
    'WOK',
  ],
  VG: ['EIS', 'NGD', 'NSX', 'RAD', 'TOV', 'VIJ'],
  VI: ['JCD', 'SJF', 'SPB', 'SSB', 'STT', 'STX'],
  VN: [
    'BMV',
    'CAH',
    'CSJ',
    'CXR',
    'DAD',
    'DIN',
    'DLI',
    'HAN',
    'HBN',
    'HOO',
    'HPH',
    'HUI',
    'KON',
    'NHA',
    'PHA',
    'PHH',
    'PHU',
    'PQC',
    'PXU',
    'SGN',
    'SOA',
    'SQH',
    'TBB',
    'TMK',
    'UIH',
    'VCA',
    'VCL',
    'VCS',
    'VII',
    'VKG',
    'VSO',
    'VTG',
    'XLO',
    'XNG',
    'XVL',
  ],
  VU: [
    'AUY',
    'AWD',
    'CCV',
    'DLY',
    'EAE',
    'EPI',
    'FTA',
    'GBA',
    'IPA',
    'LNB',
    'LNE',
    'LOD',
    'LPM',
    'MTV',
    'MWF',
    'NUS',
    'OLJ',
    'PBJ',
    'RCL',
    'SLH',
    'SON',
    'SSR',
    'SWJ',
    'TAH',
    'TGH',
    'TOH',
    'UIQ',
    'ULB',
    'VLI',
    'VLS',
    'WLH',
    'ZGU',
  ],
  VZ: ['UPC'],
  WF: ['FUT', 'WLS'],
  WS: ['AAU', 'APW', 'FGI', 'LAV', 'MXS'],
  XK: ['PRN'],
  YE: [
    'AAY',
    'ADE',
    'AJO',
    'AXK',
    'BHN',
    'BUK',
    'BYD',
    'DAH',
    'DHL',
    'DMR',
    'EAB',
    'GXF',
    'HOD',
    'IHN',
    'KAM',
    'LDR',
    'MKX',
    'MYN',
    'RIY',
    'RXA',
    'SAH',
    'SCT',
    'SYE',
    'TAI',
    'UKR',
    'WDA',
  ],
  YU: ['BJY', 'ZBK'],
  ZA: [
    'AAM',
    'ADY',
    'AFD',
    'AGZ',
    'ALJ',
    'BFN',
    'BIY',
    'CDO',
    'CPT',
    'DUK',
    'DUR',
    'ELL',
    'ELS',
    'EMG',
    'FCB',
    'GCJ',
    'GIY',
    'GRJ',
    'GSS',
    'HBL',
    'HCS',
    'HDS',
    'HIC',
    'HLA',
    'HLW',
    'HPR',
    'HRS',
    'HZV',
    'INY',
    'JNB',
    'JOH',
    'KHO',
    'KIG',
    'KIM',
    'KLZ',
    'KMH',
    'KOF',
    'KXE',
    'LAY',
    'LCD',
    'LDZ',
    'LLE',
    'LMR',
    'LTA',
    'LUJ',
    'MBD',
    'MBM',
    'MEZ',
    'MGH',
    'MQP',
    'MWR',
    'MZF',
    'MZQ',
    'MZY',
    'NCS',
    'NGL',
    'NLP',
    'NTY',
    'OUH',
    'OVG',
    'PBZ',
    'PHW',
    'PLZ',
    'PRK',
    'PRY',
    'PTG',
    'PZB',
    'PZL',
    'QRA',
    'RCB',
    'ROD',
    'RVO',
    'SBU',
    'SDB',
    'SIS',
    'SZK',
    'TCU',
    'TDT',
    'THY',
    'TSD',
    'ULD',
    'ULX',
    'UTE',
    'UTN',
    'UTT',
    'UTW',
    'VIR',
    'VRE',
    'VRU',
    'VYD',
    'WEL',
    'WKF',
    'ZEC',
  ],
  ZM: [
    'BBZ',
    'CGJ',
    'CIP',
    'KAA',
    'KIW',
    'KLB',
    'KMZ',
    'LUN',
    'LVI',
    'LXU',
    'MFU',
    'MMQ',
    'MNR',
    'MNS',
    'NLA',
    'SJQ',
    'SLI',
    'SXG',
    'ZGM',
    'ZKB',
    'ZKP',
  ],
  ZR: [
    'BAN',
    'BDT',
    'BDV',
    'BKY',
    'BMB',
    'BNB',
    'BNC',
    'BOA',
    'BSU',
    'BUX',
    'BZU',
    'FDU',
    'FMI',
    'GDJ',
    'GMA',
    'IDF',
    'IRP',
    'KAP',
    'KBN',
    'KBO',
    'KEC',
    'KGA',
    'KGN',
    'KIL',
    'KKW',
    'KLI',
    'KLY',
    'KMN',
    'KND',
    'KNM',
    'KOO',
    'KRZ',
    'LBO',
    'LIE',
    'LIQ',
    'LUS',
    'LZA',
    'LZI',
    'MAT',
    'MDK',
    'MEW',
    'MJM',
    'MNB',
    'MSM',
    'NIO',
    'NKL',
    'PFR',
    'PUN',
    'PWO',
    'YAN',
  ],
  ZW: [
    'BFO',
    'BUQ',
    'BZH',
    'CHJ',
    'GWE',
    'HRE',
    'HWN',
    'KAB',
    'MJW',
    'MVZ',
    'UTA',
    'VFA',
    'WKI',
  ],
};

@Pipe({
  name: 'airportCountry',
})
export class AirportCountryPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return null;
  }

  /**
   * @description - determine if an airport is in a specified country
   * @param airportCode - the code of the airport e.g. MAN
   * @param countryCode - the code the country e.g. GB
   */
  airportIsInCountry(airportCode: string, countryCode: string): boolean {
    if (
      COUNTRIES[countryCode.toUpperCase()] &&
      COUNTRIES[countryCode.toUpperCase()].includes(airportCode.toUpperCase())
    ) {
      return true;
    }

    return false;
  }

  /**
   * @description - get a country code based on an airport code - if not found returns null
   * @param airportCode - the code of the airport to look for
   */
  getCountryFromAirport(airportCode: string): any {
    const keys = Object.keys(COUNTRIES);

    for (let i = 0; i < keys.length; i++) {
      if (COUNTRIES[keys[i]].includes(airportCode.toUpperCase())) {
        return keys[i];
      }
    }

    return null;
  }

  /**
   * @description - check if a code belongs to an airport
   * @param airportCode - string 3 digits e.g. LHR for London Heathrow
   */
  isAirport(airportCode: string): boolean {
    const keys = Object.keys(COUNTRIES);

    for (let i = 0; i < keys.length; i++) {
      if (COUNTRIES[keys[i]].includes(airportCode.toUpperCase())) {
        return true;
      }
    }

    return false;
  }
}
