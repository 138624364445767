<div cdkTrapFocus
     aria-live="assertive">
  <div class="modal-header">
    <h3 class="modal-title"
        i18n="@@modalTitle"
        id="modal-header">Already Logged In!</h3>
    <button type="button"
            class="close"
            aria-label="Already Logged In Close"
            ngbAutoFocus
            (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true"><i class='ico-cancel modal_exit_cross'></i></span>
    </button>
  </div>
  <div class="modal-body"
       i18n="@@modalBody">
    You are already logged in on another session or you have closed your browser without logging out.
    If you continue, that session will be automatically ended.
  </div>
  <div class="modal-footer">
    <button type="button"
            class="small-button small-button--no"
            (click)="activeModal.close(false)"
            i18n="@@modalCancel">Cancel</button>
    <button type="button"
            id='button_ok'
            class="small-button"
            (click)="activeModal.close(true)"
            i18n="@@modalOK">Ok</button>
  </div>
</div>