import { Pipe, PipeTransform } from '@angular/core';

/**
 * @class SabsDistancePipe
 * @implements {PipeTransform}
 * @description converts meters into miles e.g. 1609 (meters) = 1 mile / 3218 meters = 2 miles
 */
@Pipe({ name: 'sabsDistance' })
export class SabsDistancePipe implements PipeTransform {
  transform(value: number, args?: string): any {
    if (!value) {
      return value;
    }

    const METRES_IN_MILE = 1609;
    const METRES_IN_KILOMETRE = 1000;
    switch (args) {
      case 'metres_to_miles':
        const miles = value / METRES_IN_MILE;
        return `${miles.toFixed(2)} mile${miles > 1 ? 's' : '' }`;
      case 'metres_to_kilometres':
        const kilometres = value / METRES_IN_KILOMETRE;
        return `${kilometres.toFixed(2)} kilometre${kilometres > 1 ? 's' : '' }`;
      default:
        return value;
    }
  }
}
