<div>
  
  <div class='leg'
       *ngFor='let leg of legs; let i = index; let first = first'>
       <!-- <ng-container *ngIf="first">
        <h3 *ngIf="leg.LegDirection === 'O'"class="leg_header" i18m="@@irllegs_outboundjourney">Outbound Journey</h3>
        <h3 *ngIf="leg.LegDirection === 'R'"class="leg_header" i18m="@@irllegs_inboundjourney">Return Journey</h3>
        <mat-divider></mat-divider>
      </ng-container> -->
       <!-- <ng-container  *ngIf="legs[i-1] && leg.LegDirection === 'I' && legs[i-1].LegDirection === 'O'">
        <h3 class="leg_header" i18m="@@irllegs_inboundjourney">Inbound Journey</h3>
        <mat-divider></mat-divider>
      </ng-container> -->
    <div class="leg_header_container">
      <section (mouseleave)="tt.close()"
               class="container">
        <div i18n-ngbTooltip
             ngbTooltip="{{ busToolTip }}"
             attr.aria-label="{{ busToolTip }}"
             class='leg_header'
             triggers="manual"
             #tt="ngbTooltip"
             (mouseenter)="busToolTip ? tt.open() : null"
             (focus)="busToolTip ? tt.open() : null"
             (blur)="tt.close()">
          <strong class="tourOpId">{{leg.touropIDFull}}</strong>
          <span>{{leg.transNo}}</span>

        </div>
      </section>
      <div class="leg_header"
           >

        <span *ngIf="trainNumber" class="codeshare">
          <span i18n="@@irlLegsEquipmentBy"> Train number:</span>
          {{trainNumber}}
        </span>
        <br />
        <!-- <span class="codeshare"
              *ngIf="legs[0].TKTs[i].RouteTransport"><span i18n="@@irlLegsOperatedBy"> Operated By</span>
          {{legs[0].TKTs[i].RouteTransport}}</span> -->
      </div>


    </div>
    <div class="flex-row-container amenities-leg-container">
      <div class='leg_trip'>
        <span class='leg_start_location'
            >
          <strong>{{leg.depart}})</strong>
          <br />
          <em>
            {{leg.departDateTime | sabsDate:2}}
          </em>

        </span>
        <span class='leg_start_time'>{{leg.departDateTime | sabsTimePipe: "timeString"}}</span>
        <span class="bus-route" *ngIf="leg.operator === 'Coach'">
          <i class="ico ico-bus"></i><span i18n="@@railBus"> - This leg is operated by a bus.</span>
        </span>
        <span class='leg_end_location'>
          <strong>{{leg.arrive}}</strong>
          <br />
          <em>
            {{leg.arriveDateTime | sabsDate:2}}
          </em>
          <br />
          <span class='leg_time'
         ><time>Duration: {{getTimeDifference(leg.departDateTime, leg.arriveDateTime) | sabsTimePipe:
        "min_duration"}}</time></span>
        </span>
        <span *ngIf="co2Aray"
              class="leg_c02">CO2 {{co2Aray[i]}} Kg pp</span>
        <span class='leg_end_time'>{{leg.arriveDateTime | sabsTimePipe: "timeString"}}</span>
        <div class='leg_line'>
          <div class='leg_line_line'></div>
        </div>
      </div>
    </div>
   
    <!-- <div class='legs_layover'
         *ngIf='legs[i+1] && !(leg.LegLegDirection === "O" && legs[i+1].LegDirection === "I")'>
      <i class='ico-transfer'></i>
      <em i18n="@@irlLegsChangeAt">Change at</em> {{leg.arrive}})
      <span>{{getTimeDifference(leg.arriveDateTime, legs[i + 1].departDateTime) | sabsTimePipe:
        "duration"}}</span>
    </div>
    <ng-container *ngIf="legs[i+1] && leg.LegDirection === 'O' && legs[i+1].LegDirection === 'I'">
      <h3 i18m="@@irllegs_inboundjourney">Inbound Journey</h3>
      <mat-divider></mat-divider>
    </ng-container> -->
  </div>
</div>