import { Component, HostListener, Input, QueryList, ViewChild, ViewChildren, OnInit, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { NgbInputDatepicker, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, BehaviorSubject, of, merge } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { SaveUserAddressMutationVariables, ServiceType, EnterpriseSearchService, HelperRoutines, UserService, ModalOpenerService, CabHireVehicleType } from '@sabstravtech/obtservices/angular';
import { AddressForm, RailAirport, CabHireEnterpriseSearchInterface, TaxiFormMode, OBTAirlinesDetails, LocationDetails, LocationTypes } from '@sabstravtech/obtservices/base';
import { UiHelpers } from '../../../vendor/classes/ui-helpers';
import { LightningModalTypes } from '../../../vendor/classes/modal-types.enum';
import { Helpers } from '../../../vendor/classes/helpers';
import moment from 'moment';


@Component({
  selector: 'app-taxi-address-form',
  templateUrl: './taxi-address-form.component.html',
  styleUrls: ['./taxi-address-form.component.scss']
})
export class TaxiAddressFormComponent implements OnInit, OnChanges {
  @ViewChildren(NgbInputDatepicker) datepickerList: QueryList<NgbInputDatepicker>;
  @ViewChild('inputAddress') inputAddress: NgbTypeahead;
  @ViewChild('inputStation') inputStation: NgbTypeahead;

  @ViewChild('inputHouse') inputHouse: NgbTypeahead;
  @ViewChild('inputStreet') inputStreet: NgbTypeahead;
  @ViewChild('inputTown') inputTown: NgbTypeahead;
  @ViewChild('inputPostCode') inputPostCode: NgbTypeahead;

  @Input() title: string;
  @Input() required: boolean;
  @Input() departure: boolean;
  @Input() address: AddressForm;
  @Input() limo: boolean = false;
  @Output() addressChanged: EventEmitter<AddressForm> = new EventEmitter<AddressForm>();
  @Input() station: RailAirport;
  @Output() stationChange: EventEmitter<RailAirport> = new EventEmitter<RailAirport>();
  CabHireVehicleType = CabHireVehicleType;
  formatter_airlines = (x: OBTAirlinesDetails) => x.name;
  public addressForm: AddressForm = {
    House: '',
    Street: '',
    Town: '',
    PostCode: '',
    Country: '',
    placeDescription: '',
    FlightNumber: '',
    Carrier: '',
    FlightTrainDateTime: ''
  };
  timesArray: string[];

  carrier: string | OBTAirlinesDetails;

  TaxiFormMode: typeof TaxiFormMode = TaxiFormMode;

  mode: TaxiFormMode = TaxiFormMode.ADDRESS; // Defaults should be address

  addressClick$: Subject<string> = new Subject();
  addressList: Observable<AddressForm[]>;

  stationFocus$: Subject<string> = new Subject();
  airlineFocus$: Subject<string> = new Subject();

  typeaHeadLimit = Number.MAX_SAFE_INTEGER;

  reloadAddresses: boolean;
  savingAddress: boolean = false;
  isGRS: boolean = false;
  loadingAddresses: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loadingStations: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  arriveLocationIsLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  LocationTypes: typeof LocationTypes = LocationTypes;
  invalidAirlineSelected = false;
  formatAddresses = (address: AddressForm): string => {
    if (address.House) {
      return `${address.House} ${address.Street || ''} ${address.Country}, ${address.PostCode}`;
    }
    return;
  };

  formatStations = (railAirport: any) => railAirport.name;

  searchParams: CabHireEnterpriseSearchInterface;
  type: LocationTypes;
  terminals: { key: string, value: string; }[];
  airportInfo: any;
  limosCannotBeBooked: boolean = false;

  constructor(public searchService: EnterpriseSearchService, private helpers: HelperRoutines, private userService: UserService, private modalOpenerService: ModalOpenerService) {
    this.searchParams = searchService.searches[ServiceType.Cab];
  }

  ngOnInit() {
    this.timesArray = Helpers.generateTimesList(0, 24);
    // ENT-9207 set to GB
    this.setDefaultCountryCode();

    // this.isGRS = this.userService.getServices('CTX')[0].provider === 'GRS';

  }

  getTimeArray(inputTime: string, afterInput: boolean): string[] {
    const inputParts = inputTime.split(':');
    const hours = parseInt(inputParts[0], 10);
    const minutes = parseInt(inputParts[1], 10);
    const timeArray: string[] = [];
    for (let h = 0; h < 24; h++) {
      for (let m = 0; m < 60; m += 15) {
        if ((afterInput && (h > hours || (h === hours && m > minutes))) ||
          (!afterInput && (h < hours || (h === hours && m < minutes)))) {
          timeArray.push(moment({ h, m }).format('HH:mm'));
        }
      }
    }
    return timeArray;
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    UiHelpers.closeOpenCalendars(this.datepickerList, event);
  }

  getAddresses = (): Observable<unknown> => {
    return this.addressClick$.pipe(
      switchMap(() => (this.addressList = this.searchService.getUserAddresses(this.loadingAddresses)))
    );
  };

  updateStation(event: MouseEvent | FocusEvent) {
    this.stationFocus$.next((<HTMLInputElement>event.target).value);
  }

  updateAirline(event: MouseEvent | FocusEvent) {
    this.airlineFocus$.next((<HTMLInputElement>event.target).value);
  }

  updateAddress(event: MouseEvent | FocusEvent) {
    this.addressClick$.next((<HTMLInputElement>event.target).value);
  }

  getStations = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(
        (term: string): Observable<LocationDetails[]> =>
          term.length <= 2
            ? of([])
            : this.searchService.getStationsAndAirportsList(term, null, this.loadingStations)
              .pipe(
                map((res: any[]) => {
                  // show railstation and airports with postcode only
                  return res.filter(re => re.railstation?.locationDetails?.postcode || re.airport?.postcode).slice(0, this.typeaHeadLimit);
                }),
                catchError(() => of([]))
              )
      )
    );
  };

  getAirline = (text$: Observable<string>): Observable<OBTAirlinesDetails[]> =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(
        (term: string): Observable<OBTAirlinesDetails[]> =>
          term.length <= 2
            ? of([])
            : this.searchService.getAirlinesList(term, this.arriveLocationIsLoading).pipe(
              map((res: any[]) => {
                console.log(res);
                return res.slice(0, this.typeaHeadLimit);
              }),
              catchError(() => of([]))
            )
      )
    );

  getList(list: any) {
    if (list.type === LocationTypes.Airport) {
      return `${list.name} (${list.airport.gateway})`;
    }
    return list.name;
  }

  saveAddress(): void {
    this.savingAddress = true;
    this.searchService.saveUserAddress(this.address).then(data => {
      console.log(data);

      if (data.alreadySaved) {
        this.modalOpenerService.open(LightningModalTypes.TaxiWarningModalComponent);
      }

    }).finally(() => this.savingAddress = false);
  }

  clearAddress(): void {
    const station = this.address.Terminal || this.address.Terminal === null;
    station ? this.stationChange.emit(this.airportInfo) : this.addressChanged.emit();
    this.address = {
      House: '',
      Street: '',
      Town: '',
      PostCode: '',
      Country: !this.limo ? 'GB' : '',
      placeDescription: '',
      FlightNumber: '',
      Carrier: '',
      FlightTrainDateTime: ''
    };
    this.onChange();
  }

  deleteAddress(address: AddressForm, event: any): void {
    let currentEvent = event.currentTarget?.parentNode?.parentNode;
    event.stopPropagation();
    this.searchService.deleteUserAddressByID(address.ID.toString())
      .subscribe(
        (response) => {
          if (response) {
            currentEvent.remove();
          }
        }
      );
  }

  ensureElementIsScrolledTo(event) {
    try {
      const typeAheadList = event.target.nextElementSibling;
      const activeButton = typeAheadList.getElementsByClassName('active')[0];
      if (activeButton.offsetTop + activeButton.clientHeight > typeAheadList.clientHeight + typeAheadList.scrollTop) {
        typeAheadList.scrollTop = activeButton.offsetTop + activeButton.clientHeight - typeAheadList.clientHeight;
      } else if (activeButton.offsetTop < typeAheadList.scrollTop) {
        typeAheadList.scrollTop = activeButton.offsetTop;
      }
    } catch (e) {
      console.log("Couldn't find elements to scroll");
    }
  }

  selectedAddress(value: any) {
    this.addressChanged.emit(value.item);
  }

  selectedStation(value: RailAirport) {
    this.stationChange.emit(value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.address) {
      this.address = Object.assign({}, this.addressForm);
    }

    if (changes.limo) {
      this.setDefaultCountryCode();
    }
  }

  // set country if no postcode or country code, otherwise retain previous value from the search
  setDefaultCountryCode() {
    if (this.address.PostCode === '' || this.address.Country === '') {
      this.address.Country = !this.limo ? 'GB' : '';
    }
  }

  resultFormatBandListValue(address: any) {
    return `${address.House} ${address.Street || ''}, ${address.PostCode}`;
  }

  /**
   * @description - returns boolean for whether the address is valid or not, true if house and postcode provided
   */
  validAddress(): boolean {
    return (
      this.address.House && this.address.PostCode && this.address.PostCode.length >= 3
    );
  }

  onChange() {
    this.invalidAirlineSelected = false;
    if (this.type === LocationTypes.Airport) {
      this.validateAirlineSelection();
      this.address.Carrier = (this.carrier as OBTAirlinesDetails)?.code || '';
    } else if (this.type === LocationTypes.TrainStation) {
      this.address.Carrier = this.carrier as string;
    }
    this.address.House?.replace(/\s/g, "");
    this.address.PostCode?.replace(/\s/g, "");
    this.address.Street?.replace(/\s/g, "");
    this.address.Town?.replace(/\s/g, "");
    this.address.Country?.replace(/\s/g, "");
    this.address.FlightTrainDateTime?.replace(/\s/g, "");
    this.addressChanged.emit(this.address);
    if (this.limo) {
      this.canLimosBeBooked();
    }
  }

  canLimosBeBooked() {
    this.limosCannotBeBooked = this.searchService.countriesWithoutPostalCodes.some(item => item.cCode === this.address.Country);
  }

  validateAirlineSelection() {
    this.invalidAirlineSelected = (this.carrier as OBTAirlinesDetails)?.code ? false : true;
  }

  setAirportRail(station) {
    this.type = station.type;
    this.carrier = '';
    if (station.type === 'Airport') {
      this.airportInfo = station;
      this.terminals = station.airport.terminals;
      this.addTerminal(this.terminals[0].key);
    }
  }

  addTerminal(value: string) {
    this.airportInfo.terminal = value;
    this.selectedStation(this.airportInfo);
  }

  openRemoveAddressesModal() {
    this.modalOpenerService.open(LightningModalTypes.ModalDeleteSavedAddresses, { centered: true }, {
      addresses: this.searchService.getUserAddresses(this.loadingAddresses),
      removeAddress: this.deleteAddress,
      searchService: this.searchService
    });
  }
}
