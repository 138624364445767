import { TitleCasePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  NgbInputDatepicker,
  NgbTooltip,
  NgbTypeahead,
  NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, of, BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, map, catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import moment from 'moment';
import { LightningUserFavorurite } from '../../../vendor/classes/user-favourite.enum';
import { BaseComponent } from '../../../vendor/components/base_components/base-componet';
import {
  EnterpriseSearchService,
  RailSearchJourneyType,
  ReferenceRailCardQualifier,
  ServiceType,
  UserService,
  HelperRoutines
} from '@sabstravtech/obtservices/angular';
import {
  RailEnterpriseSearchInterface,
  Traveller,
  LocationDetails,
  AllowedRailClass,
  ServiceProvider,
  TrainlineTraveller,
  TrainlineSearchConfigTypes,
  LocationTypes
} from '@sabstravtech/obtservices/base';
import { LightToggleBtnComponent } from '../../../startup/light-toggle-btn/light-toggle-btn.component';
import { DeviceDetector } from '../../../vendor/services/device-detector.service';
import { Helpers } from '../../../vendor/classes/helpers';
import { Router } from '@angular/router';

@Component({
  selector: 'app-rail-search',
  templateUrl: './rail-search.component.html',
  styleUrls: ['./rail-search.component.scss']
})
export class RailSearchComponent extends BaseComponent implements OnInit {
  @ViewChildren(NgbInputDatepicker) datepickerList: QueryList<NgbInputDatepicker>;
  // public moment: Date = new Date()
  @ViewChild('onewayBtn') oneWayBtn: LightToggleBtnComponent;
  @ViewChild('returnBtn') returnBtn: LightToggleBtnComponent;
  @ViewChild('instance') instance: NgbTypeahead;
  @ViewChild('ttip') ttip: NgbTooltip;
  focus$ = new Subject<string>();
  focus2$ = new Subject<string>();
  focus3$ = new Subject<string>();
  public input1Moment: any;

  showNotShow: string;
  bringToFront: string;
  isReturnSelected: false;
  openReturnTrue: false;
  results: string[];
  allowOpenReturn = true;
  hideLiveRail = false;
  hideOpenReturn = false;
  hideClasses = false;
  ShowCarnet = false;
  // serviceClasses: string[] = ['Any', 'Standard'];

  travellingFrom: Observable<any[]> = of([]);
  travellingTo: Observable<any[]> = of([]);
  viaLocation: Observable<any[]> = of([]);
  got_railcards = false;

  ServiceType: typeof ServiceType = ServiceType;
  RailJourneyType: typeof RailSearchJourneyType = RailSearchJourneyType;
  searchParams: RailEnterpriseSearchInterface;

  typeaHeadLimit = Number.MAX_SAFE_INTEGER;
  chosenRailCards: ReferenceRailCardQualifier[] = [];
  railCards: ReferenceRailCardQualifier[] = [];

  travellers: Traveller[];
  // journeyType: RailJourneyType = RailJourneyType.ReturnJourney;
  isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public selectedTravelCards: (ReferenceRailCardQualifier & { userId?: string; })[] = [];
  trainlineEnabled: boolean = false;
  euSearchEnabled: boolean = false;
  showLoyaltyNumber: boolean = false;
  cardPattern = '';

  hotelsearchRequested: boolean = false;
  displayHotelCheck: boolean = false;
  isEvolvi: boolean = false;
  @Output() toggleHotelSearchRequested: EventEmitter<boolean> = new EventEmitter();

  // Inject HttpClient into rail search component or service
  constructor(
    public deviceDetector: DeviceDetector,
    public searchService: EnterpriseSearchService,
    private titleCasePipe: TitleCasePipe,
    private userService: UserService,
    title: Title,
    public translateService: TranslateService,
    private router: Router,
    private helpers: HelperRoutines
  ) {
    super(title, translateService);
  }

  ngOnInit(): void {
    this.searchParams = this.searchService.searches[ServiceType.Rail];
    this.trainlineEnabled = this.userService.userHasServiceProvider(
      ServiceType.Rail,
      ServiceProvider.Trainline
    );
    console.log(`+++ User has trainline enabled: ${this.trainlineEnabled} +++`);
    this.isEvolvi = this.userService.userHasServiceProvider(
      ServiceType.Rail,
      ServiceProvider.Evolvi
    );

    this.subscribe(this.searchParams.locationChange, change => {
      console.log(`+++ Rail location changed: ${change} +++`);
      this.loadRailcards();
    });

    if (this.trainlineEnabled) {
      this.subscribe(
        this.searchService.getTrainlineSearchConfig(),
        data => {
          // console.warn(`+++ User trainline search config: ${data} ${this.searchParams.isLoading.value} +++`);
          if (data === TrainlineSearchConfigTypes.ukAndEurope) {
            this.euSearchEnabled = true;
            // console.log('+++ Setting Default Location +++');
            this.searchParams.setDefaultLocation();
            this.loadRailcards();
          } else if (data === TrainlineSearchConfigTypes.allOfEurope) {
            if (this.isEvolvi) {
              this.euSearchEnabled = true;
              if (this.searchParams.location === 'UK') {
                this.searchParams.changeLocation('UK');
              }
            } else {
              this.euSearchEnabled = false;
              this.searchParams.changeLocation('EU');
            }
            this.loadRailcards();
          } else if (data === TrainlineSearchConfigTypes.ukOnly) {
            this.euSearchEnabled = false;
            this.searchParams.changeLocation('UK');
            this.loadRailcards();
          }
        },
        error => {
          console.error(`+++ Error fetching trainline search config ${error} +++`);
        }
      );
    }

    this.travellers = this.searchService.getSelectedtravellers();
    if (this.searchParams.railCards.length) {
      this.selectedTravelCards = this.searchParams.railCards.map(railCard => {
        if (!railCard?.code) {
          return undefined;
        }
        return railCard;
      });
    }
    this.bringToFront = '-2';
    this.ShowCarnet = this.userService.isUserFavoriteSet(LightningUserFavorurite.carnet_tickets);
    this.allowOpenReturn = !this.userService.isUserFavoriteSet(
      LightningUserFavorurite.no_open_returns
    );
    this.hideLiveRail =
      this.userService.getUserFavoriteObject<{ hideLiveRail: { value: boolean; }; }>(
        LightningUserFavorurite.RailSearchFormConfiguration
      )?.hideLiveRail.value || false;

    this.hideOpenReturn =
      this.userService.getUserFavoriteObject<{ hideOpenReturn: { value: boolean; }; }>(
        LightningUserFavorurite.RailSearchFormConfiguration
      )?.hideOpenReturn?.value || false;

    let hideClasses = this.userService.getUserFavoriteObject<{
      hideRailClasses: {
        any: boolean;
        standard: boolean;
        first: boolean;
      };
    }>(LightningUserFavorurite.RailSearchFormConfiguration)?.hideRailClasses || {
      any: false,
      standard: false,
      first: false
    };

    if (hideClasses.any && hideClasses.standard && hideClasses.first) {
      this.hideClasses = true;
    }

    this.checkOutboundReturnDateTimes();
    console.log(this.searchParams);
    this.loadRailcards();
    this.subscribe(this.searchParams.classTypes, (classTypes) => { //If the chosen search type is not part of the allowed class types, default it to the first available option
      if (classTypes && classTypes.length) {
        const classTypesWithChosenType = classTypes.filter((classType) => classType.value === this.searchParams.chosenSearchClass);
        if (!classTypesWithChosenType.length) {
          this.searchParams.chosenSearchClass = classTypes[0].value;
        }
      }
    });
  }

  // @desc - load in the railcards
  loadRailcards(): void {
    this.subscribe(
      this.searchService.getRailCards(this.searchParams.ukRail ? 'GB' : 'EU'),
      data => {
        this.railCards = data || [];
        console.log('railcards: ', data);
      }
    );
  }

  /**
  @desc - returns NgbDateStruct object representing todays date
  **/
  getTodaysDate(): NgbDateStruct {
    const year = parseInt(moment().format('YYYY'), 10);
    const month = parseInt(moment().format('M'), 10);
    const day = parseInt(moment().format('D'), 10);
    return { year, month, day };
  }

  /**
  @desc - sets default date and times for outbound and return journeys, if not set previously by the user
  **/
  checkOutboundReturnDateTimes(): void {
    const today = this.getTodaysDate();
    // check outbound
    if (!this.searchParams.outbound_date) {
      this.searchParams.outbound_date = today;
    }
    if (!this.searchParams.outbound_time) {
      this.searchParams.outbound_time = '0700';
    }
    // check return
    if (!this.searchParams.return_date) {
      this.searchParams.return_date = today;
    }
    if (!this.searchParams.return_time) {
      this.searchParams.return_time = '1600';
    }
  }

  /**
  @desc - when the user changes the outbound date, update the return date to match the outbound date
  **/
  onOutboundDateChange(): void {
    this.searchParams.return_date = this.searchParams.outbound_date;
  }

  showApplyRailModal() {
    if (this.searchParams.applyRailcards) {
      this.ttip.open();
    } else {
      this.selectedTravelCards = [];
      this.ttip.close();
    }
  }

  @HostListener('document:click', ['$event'])
  public onClick(event) {
    Helpers.closeOpenCalendars(this.datepickerList, event);
  }

  ticketTypeOneWay(): void {
    this.showNotShow = 'rgba(0,0,0,0.9)';
    this.bringToFront = '2';
  }

  returnTimeProximity(prox): void { }

  // changeTicketType(event: RailJourneyType, secondPaam): void {
  //   console.log(event);
  //   this.searchParams.chosenSearchType = event;
  // }

  searchJourneyType(type: RailSearchJourneyType) {
    if (
      (type === RailSearchJourneyType.ReturnJourney && !this.returnBtn.disabled) ||
      type === RailSearchJourneyType.SingleJourney
    ) {
      this.searchParams.chosenSearchType = type;
    }
  }

  onCarnetTicketChange(value: boolean): void {
    if (value) {
      this.oneWayBtn.click();
      this.searchParams.chosenSearchType = RailSearchJourneyType.SingleJourney;
      this.returnBtn.disabled = true;
    } else {
      this.returnBtn.disabled = false;
    }
  }

  ticketTypeReturn(): void {
    this.showNotShow = null;
    this.bringToFront = '-2';
  }

  formatter = (x: { destination: string; }): string => this.titleCasePipe.transform(x.destination);
  formatter2 = (x: { name: string; }) => x.name;

  railLocations = (text$: Observable<string>): Observable<LocationDetails[]> =>
    this.locationTypeahead(text$);
  railLocations2 = (text$: Observable<string>) => this.locationTypeahead(text$);
  railLocations3 = (text$: Observable<string>) => this.locationTypeahead(text$);

  private locationTypeahead(text$: Observable<string>): Observable<LocationDetails[]> {
    return text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(
        (term: string): Observable<LocationDetails[]> =>
          term.length <= 2
            ? of([])
            : this.searchService
              .getStationLocations(
                term,
                this.isLoading,
                undefined,
                this.searchParams.ukRail,
                !this.searchParams.ukRail
              )
              .pipe(
                map((res: any[]) => {
                  console.log(res);
                  return res.slice(0, this.typeaHeadLimit);
                }),
                catchError(() => of([]))
              )
      )
    );
  }

  changeRailcardOption(index: number, travellerId: string): void {
    const selectedRailCard = this.helpers.clone(this.selectedTravelCards);
    if (!selectedRailCard[index]) {
      selectedRailCard[index] = {
        code: '',
        name: ''
      };
    }
    selectedRailCard[index].userId = travellerId;
    this.selectedTravelCards = selectedRailCard;
    this.searchParams.railCards = selectedRailCard;
  }

  ensureElementIsScrolledTo(event) {
    try {
      const typeAheadList = event.target.nextElementSibling;
      const activeButton = typeAheadList.getElementsByClassName('active')[0];
      if (
        activeButton.offsetTop + activeButton.clientHeight >
        typeAheadList.clientHeight + typeAheadList.scrollTop
      ) {
        typeAheadList.scrollTop =
          activeButton.offsetTop + activeButton.clientHeight - typeAheadList.clientHeight;
      } else if (activeButton.offsetTop < typeAheadList.scrollTop) {
        typeAheadList.scrollTop = activeButton.offsetTop;
      }
    } catch (e) {
      console.log("Couldn't find elements to scroll");
    }
  }

  isValidTime(favouriteTime: string): boolean {
    return !!favouriteTime.match(/^(\d{2}):(\d{2})$/);
  }

  roundToNearest30(time: string) {
    const m = moment(`2018-12-08 ${time}`);
    return m.minute() >= 30 ? m.minutes(30).format('HH:mm') : m.minutes(0).format('HH:mm');
  }

  goToLiveSearch() {
    // this.searchService[ServiceType.Rail].isLiveRail = true;
    this.router.navigate(['live-rail']);
  }

  cardComparator(a: ReferenceRailCardQualifier, b: ReferenceRailCardQualifier): boolean {
    return a?.name === b?.name;
  }

  trackByClass(index: number, allowedClass: AllowedRailClass): string {
    return allowedClass.value.toString();
  }

  changeLocation(newLocation: string): void {
    console.log(`+++ Location changed to: ${newLocation} +++`);
    this.searchParams.results.next([]);
    if (newLocation === 'EU') {
      this.searchParams.setupTrainlineEUTravellers();
    }
  }

  checkHotelCheckbox() {
    this.displayHotelCheck =
      this.checkDiffBetweenDates(
        this.searchParams.outBoundDateTime,
        this.searchParams.inBoundDateTime
      ) &&
      this.searchParams.chosenSearchType === this.RailJourneyType.ReturnJourney &&
      !!this.searchParams.travellingFrom &&
      !!this.searchParams.travellingTo;
    if (!this.displayHotelCheck && this.toggleHotelSearchRequested.observed) {
      this.hotelsearchRequested = false;
      this.toggleHotelSearchRequested.emit(this.hotelsearchRequested);
    }
  }

  checkDiffBetweenDates(outBoundDate: moment.Moment, inboundDate: moment.Moment): boolean {
    return !outBoundDate.isSame(inboundDate, 'date') && inboundDate > outBoundDate; //If they're not on the same date, and inbound is later than outbound, then the stay must be atleast over 1 night
  }

  toggleHotelCheckbox() {
    this.hotelsearchRequested = !this.hotelsearchRequested;
    this.requireHotelCheckbox(this.hotelsearchRequested);
    this.toggleHotelSearchRequested.emit(this.hotelsearchRequested);
  }

  requireHotelCheckbox(isHotelRequired = false): void {
    if (!isHotelRequired) return;
    this.searchService.searches[ServiceType.Hotel].checkin_date =
      this.searchParams.outBoundDateTime;
    this.searchService.searches[ServiceType.Hotel].checkout_date =
      this.searchParams.inBoundDateTime;

    this.searchService.searches[ServiceType.Hotel].location = this.searchParams.travellingTo;
    this.searchService.searches[ServiceType.Hotel].trainsStation =
      this.searchParams.travellingTo?.railstation;

    let hotelCounty = {
      cCode: this.searchParams.travellingFrom?.railstation?.locationDetails?.countryCode,
      cName: this.searchParams.travellingFrom?.railstation?.locationDetails?.country
    };

    this.searchService.searches[ServiceType.Hotel].country = hotelCounty;
    this.searchService.searches[ServiceType.Hotel].location_type_select =
      LocationTypes.TrainStation;
  }
}

