
<ng-template #approvalEmailTemp let-r="result">
    {{ r.email }}
</ng-template>

<div class="profile_information--content" [formGroup]="approvalReassignmentForm">
    <div class="profile_information--sub-header" i18n="@@outOfOfficeApprover">
        Out of Office Approver Email
    </div>
    <p class="profile_information--sub-header-hint"  i18n="@@approverAndBothDatesAreCompleted">
        Please ensure the Approver and both dates are completed.
    </p>

    <div class="profile_information--input-container">
        <div class="profile_information--input-label">
            <span i18n="@@outOfOfficeApproverEmail">Out of Office Approver Email:</span>
        </div>
        <div class="profile_information--input-wrapper">
            <input
            i18n-placeholder
            placeholder="Select..."
            formControlName="approver"
            type="text"
            class="profile_information--input"
            [resultTemplate]="approvalEmailTemp"
            [inputFormatter]="formatterApprovalEmail"
            [required]="isApproverRequired()"
            [ngbTypeahead]="searchTravellerEmail"/>
            <div *ngIf="approvalReassignmentForm.get('approver').invalid">
                <div *ngIf="approvalReassignmentForm.get('approver').errors?.required" class="profile_information--error-message" i18n="@@approverEmailRequired">Approver email required</div>
            </div>
        </div>
    </div>
    <div class="profile_information--input-container">
        <div class="profile_information--input-label">
            <span i18n="@@outOfOfficeStartDate">Out of Office Start Date:</span>
        </div>
        <div class="profile_information--input-wrapper">
            <ngbd-datepicker-i18n>
                <input class="profile_information--input"
                placeholder="DD/MM/YYYY"
                #dos="ngbDatepicker"
                ngbDatepicker
                [navigation]="'arrows'"
                (click)="dos.toggle()"
                [required]="isValidToRequired()"
                formControlName="validFrom"/>
                <fa-icon [icon]="faCalendar"></fa-icon>
                <div *ngIf="approvalReassignmentForm.get('validFrom').invalid || approvalReassignmentForm.get('validFrom').touched">
                    <div *ngIf="approvalReassignmentForm.get('validFrom').errors?.required" class="profile_information--error-message" i18n="@@outOfHoursStartRequired">Out of Office Start Date required</div>
                </div>
            </ngbd-datepicker-i18n>
        </div>
    </div>
    <div class="profile_information--input-container">
        <div class="profile_information--input-label">
            <span i18n="@@outOfOfficeEndDate">Out of Office End Date:</span>
        </div>
        <div class="profile_information--input-wrapper">
        <ngbd-datepicker-i18n>
            <input class="profile_information--input"
            placeholder="DD/MM/YYYY"
            #doe="ngbDatepicker"
            ngbDatepicker
            [required]="isValidToRequired()"
            [navigation]="'arrows'"
            (click)="doe.toggle()"
            formControlName="validTo"/>
            <fa-icon [icon]="faCalendar"></fa-icon>
            <div *ngIf="approvalReassignmentForm.get('validTo').invalid">
                <div *ngIf="approvalReassignmentForm.get('validTo').errors?.required" class="profile_information--error-message" i18n="@@outOfHoursEndRequired">Out of Office End Date required</div>
            </div>
        </ngbd-datepicker-i18n>
        </div>
    </div>
</div>
