import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { WithSubscriptionComponent, FlightJourney, FlightCabinClass } from '@sabstravtech/obtservices/angular';
import {
    FlightItineraryWithExtensions,
    FlightGroupedQuoteResult,
    FlightDirectionEnum,
    FlightTempParams,
    FlightEnterpriseSearchInterface
} from '@sabstravtech/obtservices/base';
import { FLIGHT_CLASSES } from '../../../../vendor/classes/helpers';
import { ShowSubClass } from '../../../../vendor/interfaces/flight-interfaces';

enum TabType {
    FareDetails,
    FlightLegTimes
}
@Component({
    selector: 'app-flight-journey-detail-old',
    templateUrl: './flight-journey-detail.html',
    styleUrls: ['./flight-journey-detail.scss']
})
export class FlightJourneyDetailOld extends WithSubscriptionComponent implements OnInit, OnChanges {
    @Input() flightDetail: FlightGroupedQuoteResult = null;
    @Input() direction: FlightDirectionEnum = null;
    @Input() selectedClasses: ShowSubClass;
    @Input() search: FlightTempParams;
    @Input() searchParams: FlightEnterpriseSearchInterface;

    FlightCabinClass: typeof FlightCabinClass = FlightCabinClass;
    public selectBrand;
    debug = false;
    showLegs = false;
    availableBrands = [];
    selectedTab = TabType.FareDetails;
    TabType: typeof TabType = TabType;
    legs: FlightJourney[] = null;
    tickets: {
        flights: FlightItineraryWithExtensions[];
        type: FlightCabinClass;
        show: boolean;
        title: string;
    }[];
    constructor() {
        super();
    }
    ngOnChanges(changes: SimpleChanges): void {
        const flightNumbersOutlegs: string[] = this.flightDetail.outlegs.map((leg: FlightJourney) => leg.operatingFlightNumber);

        this.tickets = FLIGHT_CLASSES.reduce(
            (
                accum: {
                    flights: FlightItineraryWithExtensions[];
                    type: FlightCabinClass;
                    show: boolean;
                    title: string;
                }[],
                next: any
            ) => {
                if (this.selectedClasses[next.showClass]) {
                    if (this.flightDetail.classes[next.fareBasis]?.flights ) {
                        // push flights if legs have the same operatingFlightNumber
                        if (this.direction !== FlightDirectionEnum.Inbound) {
                            this.flightDetail.classes[next.fareBasis].flights = this.flightDetail.classes[next.fareBasis]?.flights.filter((ticket: FlightItineraryWithExtensions) =>
                                ticket.outboundFlights.every((out: FlightJourney) => 
                                flightNumbersOutlegs.includes(out.operatingFlightNumber)
                            ))
                        }
                        accum.push({
                            flights: this.flightDetail.classes[next.fareBasis]?.flights,
                            type: next.fareBasis,
                            show: next.showClass,
                            title: next.title
                        });
                    }
                    if (this.flightDetail.classes[next.branded]?.flights) {
                        // push flights if legs have the same operatingFlightNumber
                        if (this.direction !== FlightDirectionEnum.Inbound)  {
                            this.flightDetail.classes[next.branded].flights = this.flightDetail.classes[next.branded]?.flights.filter((ticket: FlightItineraryWithExtensions) =>
                            ticket.outboundFlights.every((out: FlightJourney) => flightNumbersOutlegs.includes(out.operatingFlightNumber)))
                        }
                        accum.push({
                            flights: this.flightDetail.classes[next.branded]?.flights,
                            type: next.branded,
                            show: next.showClass,
                            title: next.title
                        });
                    }
                }
                return accum;
            },
            []
        );
    }

    ngOnInit() {
        // we have to constantly use outlegs here, because when we create a RETURN flightDetail ticket we change the parameters for the method createGroupedItem - outboundFlights to inboundFlights
        this.legs = this.flightDetail.outlegs;

        this.debug = (window as any).showDebug;
    }
}
