import {
  trigger,
  style,
  transition,
  state,
  animate,
  query,
  animateChild,
  group,
} from '@angular/animations';

export const COLLAPSE_IN_OUT = trigger('collapseInOut', [
  state('void', style({ opacity: 0, height: 0 })),
  transition(
    ':enter',
    animate('300ms ease-in', style({ opacity: 1, height: '*' }))
  ),
  transition(
    ':leave',
    animate('200ms ease-out', style({ opacity: 0, height: 0 }))
  ),
]);

export const FADE_IN_OUT = trigger('fadeInOut', [
  state('void', style({ opacity: 0, height: 0 })),
  transition(':enter', animate('300ms ease-in', style({ opacity: 1 }))),
  transition(':leave', animate('200ms ease-out', style({ opacity: 0 }))),
]);

export const SLIDE_UP_AND_DOWN = trigger('slideUpAndDown', [
  state('in', style({ transform: 'translateY(0)' })),
  transition('void => *', [
    style({ transform: 'translateY(100%)' }),
    animate(200),
  ]),
  transition('* => void', [
    animate(200, style({ transform: 'translateY(50%)' })),
  ]),
]);

export const FADE_ANIMATION = trigger('fadeAnimation', [
  transition('* => *', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        width: '100%',
      }),
    ]),
    query(':enter', [style({ opacity: 1 })]),
    query(':leave', animateChild()),
    group([
      query(':leave', [animate('200ms ease-out', style({ opacity: 0 }))]),
      query(':enter', [animate('200ms ease-out', style({ opacity: 1 }))]),
    ]),
    query(':enter', animateChild()),
  ]),
]);
