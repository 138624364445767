import { Directive, ElementRef, EventEmitter, Input, Output, HostListener } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment'
import { Helpers } from '../../vendor/classes/helpers';

@Directive({
  selector: '[appValidateDate]'
})
export class ValidateDateDirective {
  @Input() appValidateDate: NgbDateStruct;
  @Input() maxDate: NgbDateStruct;
  @Input() minDate: NgbDateStruct;
  @Output() appValidateDateChange: EventEmitter<NgbDateStruct> = new EventEmitter();

  constructor(public el: ElementRef) {}

  @HostListener('blur')
  onBlur() {
    this.validateDates();
  }

  @HostListener('keyup.enter')
  onKeyupEnter() {
    this.validateDates();
  }

  private validateDates() {
    const max: moment.Moment = this.maxDate ? Helpers.ngDateToMoment(this.maxDate) : null;
    const min: moment.Moment = this.minDate ? Helpers.ngDateToMoment(this.minDate) : null;
    let model: moment.Moment;

    if (!(this.appValidateDate instanceof Object)) {
      model = moment(); // if user not enter valid date object set current model to today than validate;
    } else {
      model = Helpers.ngDateToMoment(this.appValidateDate);
    }
    if (min && max) {
      this.setModel(model > max ? max : model < min ? min : model);
      return;
    }
    if (max && model > max) {
      this.setModel(max);
      return;
    }
    if (min && model < min) {
      this.setModel(min);
      return;
    }
    this.setModel(model);
  }

  private setModel(date: moment.Moment) {
    this.appValidateDate = Helpers.dateToNgDate(date.toDate());
    this.appValidateDateChange.emit(this.appValidateDate);
  }
}
