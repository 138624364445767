<div cdkTrapFocus>
  <div class="modal-header">
    <h3 class="modal-title">
      <span>
        <span i18n="@@boltCarDetails">Car Details</span>
        {{ result?.identifiers.makeModel}}
      </span>
    </h3>
    <button type="button" class="close" aria-label="Close" ngbAutoFocus (click)="modal.close()">
      <span aria-hidden="true"><i class="ico-cancel modal_exit_cross"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="pickup">
      <h4 i18n="@@carsPickUpAddress">Pick up address</h4>
      <span>{{ result.displayRecord.pickupLocation.name }}</span>
      <br />
      <span>{{ result.displayRecord.pickupLocation.topLine }}</span>
      <br />
      <span>{{ result.displayRecord.pickupLocation.city }}</span>
      <br />
      <span>{{ result.displayRecord.pickupLocation.postCode }}</span>
      <br />
    </div>
    <div>
      <h4 i18n="@@carsDetailsTitle">Car Details</h4>
      <ul class="car_icons">
        <section
          (mouseleave)="tt.close()"
          *ngIf="result.displayRecord.vehicle.aircon"
          class="container">
          <li
            class="air_con_icon"
            triggers="manual"
            #tt="ngbTooltip"
            (mouseenter)="tt.open()"
            (focus)="tt.open()"
            (blur)="tt.close()"
            tabindex="0"
            ngbTooltip="Air Conditioning"
            aria-label="Air Conditioning"></li>
        </section>
        <section (mouseleave)="tt2.close()" class="container">
          <li
            class="door_icon"
            ngbTooltip="{{ result.displayRecord.vehicle.type }}"
            attr.aria-label="{{ result.displayRecord.vehicle.type }}"
            triggers="manual"
            #tt2="ngbTooltip"
            (focus)="tt2.open()"
            (blur)="tt2.close()"
            tabindex="0"
            (mouseenter)="tt2.open()">
            <span>{{ getTypeForDisplayFromResult(result) }}</span>
          </li>
        </section>
      </ul>
      <p>
        <span i18n="@@estimatedCo2Usage">Estimated Co2 Usage:</span> {{ getCo2Usage(result) }} Kg
      </p>
      <i *ngIf="carDetailLoadingStates | async" class="button_spinner"></i>
      <!-- car details -->
      <div
        *ngIf="carHireAvailabilityDetailsObject[ result.identifiers.vendor + '_' + result.identifiers.vehType ] as chad">
        <div *ngFor="let additionalInfo of chad?.additionalInfo">
          <h4 *ngIf="additionalInfo?.name && !additionalInfo?.json">
            <u>{{ additionalInfo.name }}</u>
          </h4>
          <p *ngIf="additionalInfo?.text && !additionalInfo?.json ">
            <ng-container *ngFor="let t of additionalInfo?.text">{{ t }}<br /> </ng-container>
          </p>
          <!-- handle case where there is neither text or json property data available -->
          <ng-container *ngIf="!additionalInfo?.text && !additionalInfo?.json">
            <p>n/a</p>
          </ng-container>
        </div>
      </div>
      <!-- end car details -->
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="small-button small-button--no"
      i18n="@@fareruleOK"
      (click)="modal.close()">
      Ok
    </button>
  </div>
</div>

