<!-- Price breakdowns -->
<ng-container *ngIf="{
    basket: (basketService.getBasketStats(index) | async) || [],
    basketItems: ((basketService.baskets | async)[index].subject | async).basketItems
  } as data">

    <div class="cart_panel_total">
        <div class="cart_panel_total_co2_heading" *ngIf="data.basket.totalCo2">
            <h2 class="basket_panel_co2_total"
                aria-label="CO2 Total">
                <strong i18n="@@basketCo2">CO<sub>2</sub></strong>
                <span class="breakdown_co2_total">{{ data.basket.totalCo2 | number : '1.2-2' }} Kg</span>
            </h2>
        </div>
        <div class="cart_panel_total_heading"
             *ngIf="!hasMultipleCurrencies(data.basketItems)">
            <h2 class="basket_panel_total"
                aria-label="Total">
                <strong i18n="@@basketPanelTotal">Total&nbsp;</strong>
                <span class="breakdown_total"> {{ data.basket.currency }} {{ data.basket.total | number : '1.2-2'
                    }}</span>
            </h2>
        </div>

        <div class="total_breakdown_list_wrapper">
            <table class="total_breakdown_list">
                <caption class="visuallyhidden">Emissions and prices for selected items</caption>
                <thead>
                    <tr class="total_breakdown_list-header">
                        <th class="visuallyhidden" i18n="@@basketPanelType">Type</th>
                        <th class="total_breakdown_item_total" i18n="@@basketCo2">
                            CO<sub>2</sub>
                        </th>
                        <th class="total_breakdown_item_total" i18n="@@basketPanelPrice">
                            Price
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let list of data.basket.list; let i = index">
                        <tr *ngIf="list.price > 0" class="total_breakdown_item">
                            <td class="total_breakdown_item_name"> 
                                <strong>{{list.text | translate}}</strong>
                            </td>
                            <td>
                                <span class="total_breakdown_item_total">
                                <div *ngIf="list.co2 > 0; else notAvaliable">{{list.co2 | number: '1.2-2' }}</div>
                                <ng-template #notAvaliable>
                                N/A
                                </ng-template>
                            </span>
                            </td>
                            <td>
                                <span class="total_breakdown_item_total">{{list.price | number : '1.2-2'}}</span>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>

        <!--  <ul class="total_breakdown_list">
        <li>
            <span class="total_breakdown_header_spacer"></span>
            <span *ngIf="showCO2()"
                  class="total_breakdown_header_co2">CO<sub>2</sub></span>
            <span class="total_breakdown_header_price"
                  i18n="@@BasketPanelBreakdownHeaderPrice">Price</span>
        </li>
        <li *ngIf="data.userServices && data.userServices[travelEnums.FLIGHT]"
            class="total_breakdown_item">
            <strong i18n="@@basketPanelFlightsBreakdown">Flights</strong>
            <span *ngIf="showCO2()"
                  class="basket_co2">{{
                basketService.totalCO2("flights")
                }}</span>
            <span class="total_breakdown_item_total">{{
                basketService.flightTotal
                }}</span>
        </li>
        <li *ngIf="
      data.userServices && data.userServices[travelEnums.RAIL] ||
      data.userServices && data.userServices[travelEnums.AIR_EXP]
      "
            class="total_breakdown_item">
            <strong i18n="@@basketPanelRailBreakdown">Rail</strong>
            <span *ngIf="showCO2()"
                  class="basket_co2">{{
                basketService.totalCO2("rail")
                }}</span>
            <span class="total_breakdown_item_total">{{
                basketService.railTotal
                }}</span>
        </li>
        <li *ngIf="data.userServices && data.userServices[travelEnums.HOTEL]"
            class="total_breakdown_item">
            <strong i18n="@@basketPanelHotelsBreakdown">Hotels</strong>
            <span *ngIf="showCO2()"
                  class="basket_co2">{{
                basketService.totalCO2("hotels")
                }}</span>
            <span class="total_breakdown_item_total">{{
                basketService.hotelTotal
                }}</span>
        </li>
        <li *ngIf="data.userServices && data.userServices[travelEnums.TAXI]"
            class="total_breakdown_item">
            <strong i18n="@@basketPanelTaxisBreakdown">Taxis</strong>
            <span *ngIf="showCO2()"
                  class="basket_co2">{{
                basketService.totalCO2("taxis")
                }}</span>
            <span class="total_breakdown_item_total">{{
                basketService.taxiTotal
                }}</span> &nbsp;
            <span>(Excl VAT)</span>
        </li>
        <li *ngIf="data.userServices && data.userServices[travelEnums.CAR]"
            class="total_breakdown_item">
            <strong i18n="@@basketPanelCarsBreakdown">Cars</strong>
            <span *ngIf="showCO2()"
                  class="basket_co2">{{
                basketService.totalCO2("cars")
                }}</span>
            <span class="total_breakdown_item_total">{{
                basketService.carTotal
                }}</span>
        </li>
        <li *ngIf="data.userServices && data.userServices[travelEnums.EUROSTAR]"
            class="total_breakdown_item">
            <strong i18n="@@basketPanelEurostarBreakdown">Eurostar</strong>
            <span *ngIf="showCO2()"
                  class="basket_co2">{{
                basketService.totalCO2("eurostar")
                }}</span>
            <span class="total_breakdown_item_total">{{
                basketService.eurostarTotal
                }}</span>
        </li>
        <li *ngIf="data.userServices && data.userServices[travelEnums.AIR_PARKING]"
            class="total_breakdown_item">
            <strong i18n="@@basketPanelParkingBreakdown">Parking</strong>
            <span *ngIf="showCO2()"
                  class="basket_co2">{{
                basketService.totalCO2("parkings")
                }}</span>
            <span class="total_breakdown_item_total">{{
                basketService.parkingTotal
                }}</span>
        </li>
        <li *ngIf="data.userServices && data.userServices[travelEnums.AIR_LOUNGE]"
            class="total_breakdown_item">
            <strong i18n="@@basketPanelLoungesBreakdown">Lounges</strong>
            <span *ngIf="showCO2()"
                  class="basket_co2">{{
                basketService.totalCO2("lounges")
                }}</span>
            <span class="total_breakdown_item_total">{{
                basketService.loungeTotal
                }}</span>
        </li>
        <li *ngIf="data.userServices && data.userServices[travelEnums.COACH]"
            class="total_breakdown_item">
            <strong i18n="@@basketPanelCoachBreakdown">Coach</strong>
            <span *ngIf="showCO2()"
                  class="basket_co2">{{
                basketService.totalCO2("coach")
                }}</span>
            <span class="total_breakdown_item_total">{{
                basketService.coachTotal
                }}</span>
        </li>
    </ul>-->
        <div class="currency_message"
             *ngIf="data.basket.currency === 'GBP' && !hasMultipleCurrencies(data.basketItems)">
            <span i18n="@@basketPanelAllPrices">All prices shown in GBP</span>
            <div class="tooltip_wrap">
                <section (mouseleave)="tt.close()"
                         class="container">
                    <i class="currency_info_icon"
                       [placement]="tooltipPlacement"
                       triggers="manual"
                       #tt="ngbTooltip"
                       (mouseenter)="tt.open()"
                       (focus)="tt.open()"
                       (blur)="tt.close()"
                       i18n-ngbTooltip
                       tabindex="0"
                       ngbTooltip="All prices shown in GBP based on exchange rates we have on record."
                       aria-label="All prices shown in GBP based on exchange rates we have on record.">
                        <span class="visuallyhidden"
                              i18n="@@basketPanelAllPricesExchangeRecord">All prices shown in GBP based on exchange
                            rates we have on
                            record.</span>
                    </i>
                </section>
            </div>
        </div>
    </div>

</ng-container>