import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '@sabstravtech/obtservices/angular';
import { FlightItineraryWithExtensions } from '@sabstravtech/obtservices/base';
@Pipe({
  name: 'showSelectButton'
})
export class ShowSelectButtonPipe implements PipeTransform {
  constructor(private userService: UserService) { }
  transform(values: FlightItineraryWithExtensions[]): any {
    if ((<any>window).showAllFlights) {//|| this.userService.isAgentBookerBookingOnBehalfOf()) {
      return values;
    }
    // ! this was causing a dupe error so removed for ENT-5159
    // else if (values[0].source === 'Travelfusion') {
    //   return values.reduce((acc, value) => {
    //     if (!acc.length) {
    //       acc.push(value);
    //     } else {
    //       const price = value.total.price;
    //       const brandCode = value.outboundFlights[0].additional?.fareBrand?.brandCode;
    //       const matchingFare = acc.find(fare => fare.total.price === price && fare.outboundFlights[0].additional?.fareBrand?.brandCode === brandCode);
    //       if (!matchingFare) {
    //         acc.push(value);
    //       }
    //     }
    //     return acc;
    //   }, []);
    // } 
    else {
      return values.reduce((acc, value) => {
        if (!acc.length) {
          acc.push(value);
        } else {
          const brandCode = value.outboundFlights[0].additional?.fareBrand?.brandCode;
          const matchingFare = acc.find(fare => fare.outboundFlights[0].additional?.fareBrand?.brandCode === brandCode);
          if (!matchingFare) {
            acc.push(value);
          }
        }
        return acc;
      }, []);
    }
    // const items = values.filter((flight: FlightItineraryWithSelected) => {
    //   return flight.ShowSelectButton;
    // });
    // const baItems = values.filter(( flight: FlightItineraryWithSelected, i: number) => {
    //   if (flight.ShowSelectButton && i === 0) {
    //     acc.push(flight);
    //   } else if (flight.ShowSelectButton && flight.Operator === 'BA') {
    //     if (flight.BrandedOption && flight.BrandedOption.Name.includes('SELECT')) {
    //       const exists = !!acc.find(chosenflights =>
    //         chosenflights.BrandedOption.Name === flight.BrandedOption.Name
    //       )
    //       if (!exists) {
    //         acc.push(flight)
    //       }
    //     } else if (flight.TicketClass.includes('SELECT')) {
    //       const exists = !!acc.find(chosenflights =>
    //         chosenflights.TicketClass === flight.TicketClass
    //       )
    //       if (!exists) {
    //         acc.push(flight)
    //       }
    //     }
    //   }

    // })

    // if (baItems.length > 1) {
    //   return baItems
    // }
    // return items.length ? [items[0]] : [values[0]];
  }
}
