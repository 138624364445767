import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  ServiceProvider
} from '@sabstravtech/obtservices/base';
import {
  CarHireType,
  CarHireDetailRequestResponse,
  CarHireAdditionalInfoItem,
  EnterpriseBasketService,
  ModalOpenerService,
  EnterpriseSearchService,
  CarHireVehicleAvailibility,
  ServiceType,
  VehicleType,
  CurrencyRates,
  CarHireIdentifiers
} from '@sabstravtech/obtservices/angular';
import { LightningModalTypes } from '../../../../vendor/classes/modal-types.enum';

@Component({
  selector: 'app-car-item',
  templateUrl: './car-item.component.html',
  styleUrl: './car-item.component.scss'
})
export class CarItemComponent {
  @Input() result: CarHireVehicleAvailibility;
  @Input() index: number;
  @Input() results: CarHireVehicleAvailibility[] = [];
  @Input() carDetailLoadingStates: BehaviorSubject<boolean>[] = [];
  @Input() isExchange = false;
  @Input() isPricingExchange = false;
  @Output() selectExchangeCar: EventEmitter<CarHireVehicleAvailibility> = new EventEmitter();
  ServiceType: typeof ServiceType = ServiceType;
  public preferredCurency: string;
  public conversionRates: CurrencyRates;
  public carTypeCodes: [string, string, CarHireType][];
  public carHireAvailabilityDetailsObject: { [key: string]: CarHireDetailRequestResponse } = {};
  public co2PerItem: string = '';
  public convertedPrice: string = '';
  public typeForDisplay: string = '';
  public logoUrl: string = '';
  public errorUrl: string = '';
  public carImage: string = '';

  constructor(
    public searchService: EnterpriseSearchService,
    protected modalService: ModalOpenerService,
    protected basketService: EnterpriseBasketService
  ) {;
    this.carTypeCodes = this.searchService.searches[ServiceType.Car].carTypeCodes;
  }

  ngOnInit() {
    const baseUrl = "https://images.sabscorp.com/images/carhire";
    this.logoUrl = `${baseUrl}/${this.result.displayRecord.vehicle.class.toLowerCase()}.jpg`;
    this.errorUrl = `${baseUrl}/image-unavailable.png`;
    this.carImage = `${baseUrl}/${this.result.identifiers.vendor}.gif`;

    this.co2PerItem = this.getCo2Usage(this.result);
    this.convertedPrice = this.getConvertedPrice(this.result.displayRecord.charges[0].amount);
    this.typeForDisplay = this.getTypeForDisplayFromResult(this.result);
  }

  /**
    @desc - for a given result return the type string for vanity purposes for a vehicle (utilising carTypeCodes in obtservices)
    @param result of type CarHireVehicleAvailibility
    @returns the car type string e.g. '2-3 Door' instead of 'TwoToThreeDoor' raw type
  **/
  getTypeForDisplayFromResult(result: CarHireVehicleAvailibility): string {
    const typeForDisplay = this.carTypeCodes.find(
      carTypeCode => carTypeCode[2] === result?.displayRecord?.vehicle?.type
    );
    if (typeForDisplay) {
      return typeForDisplay[1];
    }
  }
  
  getCo2Usage(result: CarHireVehicleAvailibility): string {
    return result?.co2PerItem ? result.co2PerItem.toFixed(2) : '0.00'
  }

  getConvertedPrice(price: string): string {
    return this.preferredCurency && this.conversionRates
      ? (+price * this.conversionRates[this.preferredCurency]).toFixed(2)
      : price;
  }

  showSpecialFares(car: CarHireVehicleAvailibility) {
    return car.fareInfo.type === VehicleType.Private;
  }

  /**
    @desc - add a car to the basket
  **/
  addToCart(item: CarHireVehicleAvailibility): void {
    this.searchService.searches[ServiceType.Car].addCarHireToBasket(
      item,
      this.searchService.searches[ServiceType.Car].getSearchQuery()
    );
    this.basketService.toggleMenu();
  }

  exchangeCar(item: CarHireVehicleAvailibility): void {
    if (this.isExchange) {
      if (!this.isPricingExchange) {
        this.selectExchangeCar.emit(item);
      }
      return;
    }
  }

  getCarDetails(index: number): void {
    this.getCarHireAvailabilityDetail(this.results[index], index);
  }

  /**
    @desc - for a given result retrieve extra details (e.g. availability detail)
    @param result of type CarHireVehicleAvailibility
  **/
  getCarHireAvailabilityDetail(result: CarHireVehicleAvailibility, resultIndex: number): void {
    // remove the '__typename' property from identifiers object as this will stop the BE call working
    const { __typename, ...identifiers } = result.identifiers;

    if (result.source === ServiceProvider.TravelportCarHire) {
      const detailResult: CarHireDetailRequestResponse = {
        additionalInfo: result.displayRecord.additionalInfo,
        address: result.displayRecord.pickupLocation,
        identifiers: result.identifiers,
        vendor: result.displayRecord.vendor
      };
      detailResult.additionalInfo?.sort((a, b) =>
        a.name > b.name ? 1 : a.name === b.name ? 0 : -1
      );
      // move the number to the beginning of the array
      let phone = detailResult.additionalInfo?.find(
        (item: CarHireAdditionalInfoItem) => item.name === 'Contact Numbers'
      );
      detailResult.additionalInfo?.sort((a, b) => (a === phone ? -1 : b == phone ? 1 : 0));

      // store result in an object so we don't have to load the same data again if it's been previously loaded
      this.updateCarHireAvailabilityDetailsObject(result, {
        key: this.createKeyFromIdentifiers(identifiers),
        value: detailResult
      });
    } else {
      const vendor = identifiers.vendor === 'Enterprise' ? 'ET' : identifiers.vendor;
      this.searchService.searches[ServiceType.Car]
        .getCarHireAvailabilityDetail(
          {
            dropOffLocation: identifiers.dropOffLocation,
            dropOffDateTime: identifiers.dropOffDateTime,
            pickUpDateTime: identifiers.pickUpDateTime,
            pickUpExtendedLocationCode: identifiers.pickUpExtendedLocationCode,
            pickUpLocationCode: identifiers.pickUpLocationCode,
            pickUpLocationName: identifiers.pickUpLocationName,
            locator: '',
            rph: '',
            vehType: identifiers.vehType,
            vendor: vendor,
            service: result.source
          },
          this.carDetailLoadingStates[resultIndex]
        )
        .subscribe(fetchedResult => {
          fetchedResult.additionalInfo.sort((a, b) =>
            a.name > b.name ? 1 : a.name === b.name ? 0 : -1
          );
          // move the number to the beginning of the array
          let phone = fetchedResult.additionalInfo.find(
            (item: CarHireAdditionalInfoItem) => item.name === 'Contact Numbers'
          );
          fetchedResult.additionalInfo.sort((a, b) => (a === phone ? -1 : b == phone ? 1 : 0));

          // store result in an object so we don't have to load the same data again if it's been previously loaded
          this.updateCarHireAvailabilityDetailsObject(result, {
            key: this.createKeyFromIdentifiers(identifiers),
            value: fetchedResult
          });
        });
    }
  }

  /**
    @desc - update the carHireAvailabilityDetailsObject with the provided key and value
    @param data object containing key value pair
  **/
  updateCarHireAvailabilityDetailsObject(
    result: CarHireVehicleAvailibility,
    data: {
      key: string;
      value: CarHireDetailRequestResponse;
    }
  ): void {
    this.carHireAvailabilityDetailsObject[data.key] = data.value;
    this.modalService.open(
      LightningModalTypes.ModalCarDetailsComponent,
      { centered: true },
      {
        result: result,
        carHireAvailabilityDetailsObject: this.carHireAvailabilityDetailsObject,
        carTypeCodes: this.carTypeCodes
      }
    );
  }

  /**
    @desc - for a provided identifiers param return a key string by combining the 'vendor' and 'vehType' properties
    @return string containing key e.g. 'vendor_vehType'. This will be used in carHireAvailabilityDetailsObject
  **/
  createKeyFromIdentifiers(identifiers: CarHireIdentifiers): string {
    return `${identifiers.vendor}_${identifiers.vehType}`;
  }
}