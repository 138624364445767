import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SortType } from '../../../interfaces/sort-type.interface';

/**
 * This is sorting component, which can be used for display sorting box
 *
 * @export
 * @class SortingToggleComponent
 */
@Component({
  selector: 'app-sorting-toggle',
  templateUrl: './sorting-toggle.component.html',
  styleUrls: ['./sorting-toggle.component.scss'],
})
export class SortingToggleComponent {
  /**
   * Array of SortType object that should be visible on dropdown
   *
   * @type {SortType[]}
   * @memberof SortingToggleComponent
   */
  @Input() sortingTypes: SortType[] = [];

  /**
   * Fired when sorting was selected
   *
   * @type {EventEmitter<SortType>}
   * @memberof SortingToggleComponent
   */
  @Output() sortingSelected: EventEmitter<SortType> = new EventEmitter();

  /** Current selected sort type */
  selectedSortType: SortType = {
    title: 'Sort',
    method: null,
    icon: null,
    dir: null,
  };

  /** Determinate if sort type is opened  */
  open = false;

  /**
   * Select sort type, change sort direction
   *
   * @param {SortType} sortType
   * @memberof SortingToggleComponent
   */
  select(sortType: SortType) {
    sortType.dir = !sortType.dir;
    this.selectedSortType = sortType;
    this.sortingSelected.emit(sortType);
    this.toggle();
  }

  /**
   * Toggles dropdown
   */
  toggle() {
    this.open = !this.open;
  }

  moveArrowDown(e, index) {
    if (index < this.sortingTypes.length - 1) {
      e.preventDefault(); //prevents scrolling
      (<HTMLElement>document.activeElement.nextElementSibling).focus();
    }
  }

  moveArrowUp(e, index) {
    if (index !== 0) {
      e.preventDefault(); // prevents scrolling
      (<HTMLElement>document.activeElement.previousElementSibling).focus();
    }
  }

  closeOnTabOut(index) {
    if (index === this.sortingTypes.length - 1) {
      this.open = false;
    }
  }
}
