import { enableProdMode, MissingTranslationStrategy } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule
    //   , {
    //   missingTranslation: MissingTranslationStrategy.Warning,
    // }
  ).then(() => {
    console.log(navigator);
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('ngsw-worker.js').then(() => {
        console.log('Service worker registered!');
      }).catch((error) => {
        console.warn('Error registering service worker:');
        console.warn(error);
      });
    }
  }).catch((err) => console.log(err));
