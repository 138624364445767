import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EnterpriseBasketService, EnterpriseSearchService, UserService, ParkingQuote, ServiceType } from '@sabstravtech/obtservices/angular';
import { FlightEnterpriseSearchInterface } from '@sabstravtech/obtservices/base';
import { BaseSearchComponent } from '../../../vendor/components/base-search/base-search.component';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-parking',
  templateUrl: './parking.component.html',
  styleUrls: ['./parking.component.scss']
})
export class ParkingComponent extends BaseSearchComponent implements OnInit {
  ServiceType: typeof ServiceType = ServiceType;
  showItemDetails: boolean[] = [];
  public visibleMapView: boolean = false;
  canOverride: boolean = false;
  public flightSearchParams: FlightEnterpriseSearchInterface;


  // public terminalSource = new BehaviorSubject<boolean>(false);

  constructor(
    public searchService: EnterpriseSearchService,
    public userService: UserService,
    public route: ActivatedRoute,
    public basketService: EnterpriseBasketService,
    public translateService: TranslateService,
    public title: Title
  ) {
    super(searchService);
    this.flightSearchParams = searchService.searches[ServiceType.Flight];
    this.title.setTitle(this.translateService.instant('Parking Results - LightUk'));
  }

  ngOnInit(): void {
    this.canOverride = this.userService.canOverride();
    this.showItemDetails = [];
    this.subscribe(this.route.data, ({ type }) => {
      console.log(`Detected search type: ${type}`, 'PNL');
    });
  }

  toggleItemDetails(index: number): void {
    this.showItemDetails[index] = !this.showItemDetails[index];
  }

  addToCart(item: ParkingQuote) {
    this.searchService.searches[ServiceType.Parking].addParkingToBasket(
      item,
      this.searchService.searches[ServiceType.Parking].getSearchQuery()
    );
    this.basketService.toggleMenu();
  }

  skipToResultContent() {
    const focusable = document
      .getElementById('parking')
      .querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
    // @ts-ignore
    focusable[0].focus();
    return false;
  }
}
