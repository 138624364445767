import { LanguageEnum, Language } from '../vendor/interfaces/language-types';
import localePl from '@angular/common/locales/pl';
import localePlExtra from '@angular/common/locales/extra/pl';

export default class PolishLanguage extends Language {
  protected locale = localePl;
  protected localeExtra = localePlExtra;
  protected details = {
    image: 'images/flags/pl.png',
    title: 'Polski',
    enum: LanguageEnum.Polish,
    aira: 'Wybrany język polski',
    select: 'Wybierz język polski',
    abre: 'pl',
  };
}
