<ng-template #rt let-r="result" let-t="term">
    {{ r.destination }} ({{ r.gateway.toUpperCase() }})
</ng-template>
<ng-container
    *ngIf="{
        isArrivalLoading: isArrivalLoading | async
    } as data">
    <div class="parking-search-container">
        <div class="two-thirds-container">
            <div class="two-third">
               <div class="half-container">
                    <div class="half-block">
                        <label
                            class="header-label"
                            id="airportlabel"
                            for="airport"
                            i18n="@@pnlSearchAirport"
                            >Airport</label
                        >
                        <i class="ico-flight combo-box-icon" aria-hidden="true"></i>
                        <div class="flex-row-container">
                            <input
                                #inputAirport="ngbTypeahead"
                                id="airport"
                                autocomplete="off"
                                name="travellingFrom"
                                placeholder="Flying from"
                                i18n-placeholder
                                type="text"
                                class="combo-box"
                                [(ngModel)]="searchParameters.locationCode"
                                [ngbTypeahead]="getAirports"
                                (click)="airportsClick$.next($event.target.value)"
                                [resultTemplate]="rt"
                                [inputFormatter]="formatAirports"
                                aria-labelledby="airportlabel"
                                (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
                                (keyup.arrowup)="ensureElementIsScrolledTo($event)"
                                [ngClass]='{"error-box": searchParameters.validationMessages?.includes("Please add in a airport location")}' />
                            <i
                                *ngIf="loadingAirports"
                                class="button_spinner input-loading-spinner-left"></i>
                        </div>
                    </div>
                    <div class="half-block">
                <div class="flex-row-container"
                [ngClass]='{"error-box": searchParameters.validationMessages?.includes("Departure date can not be in the past")}'>
                    <ngbd-datepicker-i18n>
                    <div class="flex-column-container combo-box-left">
                        <label
                            class="header-label date-label"
                            for="fast-track-date"
                            i18n="@@pnlSearchOutwardDate"
                            >Outward Date (DD/MM/YYYY)</label
                        >
                        <i
                            class="ico-calendar combo-box-icon" aria-hidden="true"
                            style="cursor: pointer"
                            ></i>
                        <app-datepicker
                            [minDate]='searchParameters.minDate'
                            [startDate]='searchParameters.date'
                            [model]="searchParameters.date"
                            [class]="'combo-box'"
                            [componentId]="'fast-track-date'"
                            (newDate)="searchParameters.date = $event"
                        >
                        </app-datepicker>
                    </div>
                    <div class="flex-column-container select-box-right">
                        <label
                        class="header-label"
                        for="pnl_outward_time"
                        i18n="@@pnlSearchOutwardTime"
                        >Outward Time</label
                        >
                        <i class="ico-clock select-box-icon" aria-hidden="true"></i>
                        <select
                            class="select-box"
                            [(ngModel)]="searchParameters.time"
                            aria-labelledby="pnl-search-outward-time"
                            id="pnl_outward_time">
                            <option *ngFor="let time of timesArray" [ngValue]="time">{{ time }}</option>
                        </select>
                    </div>
                </ngbd-datepicker-i18n>
                </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<app-fast-track-loading-screen></app-fast-track-loading-screen>
