import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  BasketItem,
  RailJourneyLeg,
  DiscountType,
  ModalOpenerService,
  UserService
} from '@sabstravtech/obtservices/angular';
import { Outbound, Return, ServiceProvider } from '@sabstravtech/obtservices/base';
import _, { uniq } from 'lodash';
import { LightningModalTypes } from '../../../../vendor/classes/modal-types.enum';

@Component({
  selector: 'app-basket-rail',
  templateUrl: './basket-rail.component.html',
  styleUrls: ['./basket-rail.component.scss']
})
export class BasketRailComponent implements OnInit {
  DiscountType: typeof DiscountType = DiscountType;
  @Input() rail: BasketItem;
  @Input() duplicateBooking = false;
  @Output() remove: EventEmitter<void> = new EventEmitter<void>();
  @Output() openChangeModal: EventEmitter<void> = new EventEmitter<void>();
  @Input() displayItinerary = false;

  inwardPermittedOrigins: string[];
  inwardPermittedDestinations: string[];
  outwardPermittedOrigins: string[];
  outwardPermittedDestinations: string[];
  appliedDiscounts: {
    name: string;
    count: number;
  }[] = [];
  openReturnValidity: string;
  numberTravellersText: string;
  serviceName: string;

  constructor(private modalService: ModalOpenerService, private userService: UserService) {}

  ngOnInit(): void {
    this.serviceName = this.userService.findService(this.rail.service.id)?.name;
    console.log(this.rail);

    this.inwardPermittedOrigins = _.get(
      this.rail,
      'detail.inwardDetail.supplierTickets[0].permittedOrigins',
      []
    )?.map(x => x.description);

    if (
      this.inwardPermittedOrigins?.length === 1 &&
      this.inwardPermittedOrigins[0] === this.rail.detail?.inwardDetail?.departureStationName
    ) {
      this.inwardPermittedOrigins = [];
    }

    this.inwardPermittedDestinations = _.get(
      this.rail,
      'detail.inwardDetail.supplierTickets[0].permittedDestinations',
      []
    )?.map(x => x.description);

    if (
      this.inwardPermittedDestinations?.length === 1 &&
      this.inwardPermittedDestinations[0] === this.rail.detail?.inwardDetail?.arrivalStationName
    ) {
      this.inwardPermittedDestinations = [];
    }

    this.outwardPermittedOrigins = _.get(
      this.rail,
      'detail.outwardDetail.supplierTickets[0].permittedOrigins',
      []
    )?.map(x => x.description);

    if (
      this.outwardPermittedOrigins?.length === 1 &&
      this.outwardPermittedOrigins[0] === this.rail.detail?.outwardDetail?.departureStationName
    ) {
      this.outwardPermittedOrigins = [];
    }

    this.outwardPermittedDestinations = _.get(
      this.rail,
      'detail.outwardDetail.supplierTickets[0].permittedDestinations',
      []
    )?.map(x => x.description);

    if (
      this.outwardPermittedDestinations?.length === 1 &&
      this.outwardPermittedDestinations[0] === this.rail.detail?.outwardDetail?.arrivalStationName
    ) {
      this.outwardPermittedDestinations = [];
    }

    this.appliedDiscounts = _.map(_.groupBy(this.rail.detail.discountInfo, 'code'), items => ({
      ...items[0],
      count: items.length
    }));

    this.openReturnValidity =
      this.serviceName === ServiceProvider.Trainline
        ? this.rail?.detail?.termsAndCond?.outboundTerms?.[0]?.terms?.find(
            x => x?.name === 'Valid Return Rule'
          )?.value
        : null;

    /**
     * Not showing if only 1 adult
     * If 2 adults 2 children: (For 2 Adult(s) and 2 children)
     * If 1 adult 1 child: (For 1 Adult(s) and 1 child)
     */
    this.numberTravellersText =
      this.rail?.adults > 1 || (this.rail?.adults && this.rail?.children)
        ? `(For ${this.rail.adults} Adult(s)
    ${
      this.rail?.children
        ? `and ${this.rail.children} ${this.rail.children > 1 ? 'children' : 'child'}`
        : ''
    })`
        : null;
  }

  getRailOperators(legs: RailJourneyLeg[]): string {
    const filteredLegs = legs.filter(leg => !leg.operator.includes('Alternative service'));
    return uniq(filteredLegs.map(leg => leg.operator)).join(' - ');
  }

  async openTicketCondition(journey: Outbound | Return, terms: any[], from?: string, to?: string) {
    if (journey) {
      if (terms?.length > 1 && journey?.isSplit) {
        await this.modalService.open(
          LightningModalTypes.RailFareListComponent,
          { centered: true },
          {
            outboundFareTermsList: terms,
            outboundFares: journey.fares,
            splitType: journey.composition?.[0],
            origin: from,
            destination: to,
            serviceName: this.serviceName
          }
        );
      } else {
        await this.modalService.open(
          LightningModalTypes.RailFareTermsComponent,
          { centered: true },
          {
            ticketDetails: terms?.[0]?.terms
          }
        );
      }
    }
  }
}

