<div>
    <label class="header-label"
           id="countryssearch2"
           for="hotel-pref-prop">{{title | translate}}</label>
    <i class='ico-marker select-box-icon'></i>
    <select [(ngModel)]='country.cCode'
            class="select-box"
            name="location"
            id="hotel-pref-prop"
            aria-labelledby="countryssearch2">
        <option *ngFor='let ctry of countries'
                [value]="ctry.cCode">{{ctry.cName}}
        </option>
    </select>
</div>