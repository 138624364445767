<ng-template #railairtemp
             let-r="result"
             let-t="term">
   {{ r.name|flightFormat}} {{r.railstation?.stationCode ? '('+r.railstation.stationCode+')' : ''}}
</ng-template>
<label
   class="header-label"
   id="locationssearch"
   for="hotel-input-trainstation"
   i18n="@@hotelTrainSearchLocation"
   >Train Station
   Location</label>
<div>
   <i class='ico-flight combo-box-icon'
      *ngIf='!isTrain'></i>
   <i class="ico-rail combo-box-icon"
      *ngIf='isTrain'></i>
   <input autocomplete="off"
          i18n-placeholder
          id="hotel-input-trainstation"
          placeholder='Train Station'
          onClick='this.select()'
          type="text"
          class="input-box"
          [(ngModel)]="model"
          (selectItem)="onStationChange($event)"
          [ngbTypeahead]="searchLocations"
          [resultTemplate]="railairtemp"
          [inputFormatter]="formatter_rail_air"
          (focus)="focus$.next(model);"
          (keyup.arrowdown)="updateElement.emit($event)"
          (keyup.arrowup)="updateElement.emit($event)" />
   <i *ngIf='searchLocationLoading | async'
      class='button_spinner input-loading-spinner-left'></i>
</div>