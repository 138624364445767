<ng-template #toggleOn>
  <div class="booking_option_toggle booking_option_toggle_active"
       aria-live="assertive"
       aria-hidden="true">
    <i class="ico-check-1 booking_option_toggle_check_active"></i>
  </div>
</ng-template>
<ng-template #toggleOff>
  <div class="booking_option_toggle booking_option_toggle"
       aria-live="assertive"
       aria-hidden="true">
    <i class="ico-check-1 booking_option_toggle_check"></i>
  </div>
</ng-template>
<ng-container *ngIf="{
  services: userService.fullUserDetails.userServices | async  
} as data">
  <div (click)="selected.emit(service)"
       (keydown.enter)="selected.emit(service)"
       (keydown.space)="selected.emit(service)"
       role="checkbox"
       [attr.aria-checked]="searchService.search_objects[service].chosen"
       [attr.aria-labelledby]="'search booking-options-' + service"
       tabindex="0"
       class="item-main">
    <ng-container *ngIf="searchService.search_objects[service].chosen;then toggleOn;else toggleOff">
    </ng-container>
    <ng-content></ng-content>
  </div>
</ng-container>