import { Component, Input } from '@angular/core';
import { WithSubscriptionComponent } from '@sabstravtech/obtservices/angular';
import { BasketDetails } from '@sabstravtech/obtservices/base';

@Component({
    selector: 'app-basket-invoked-user[basket]',
    templateUrl: './basket-invoked-user.component.html',
    styleUrls: ['./basket-invoked-user.component.scss']
})
export class BasketInvokedUserComponent extends WithSubscriptionComponent {
    @Input() basket: BasketDetails;
    constructor() {
        super();
    }
}

