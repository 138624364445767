<div cdkTrapFocus
     aria-live="assertive">
  <div class="modal-header">
    <h3 class="modal-title"
        id="modal-header">{{ title }}</h3>
    <button type="button"
            class="close"
            attr.aria-label="{{title}} Close"
            ngbAutoFocus
            (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true"><i class='ico-cancel modal_exit_cross'></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="!isLoading"
         class="safe-html"
         [innerHTML]="body | safeHtml:'html'"></div>
    <div *ngIf="isLoading">
      <i class="button_spinner"></i>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button"
            *ngIf="options.buttonCancel"
            class="small-button small-button--no"
            (click)="activeModal.close(false)">{{options.buttonCancelText}}</button>
    <button type="button"
            *ngIf="options.buttonOk"
            id='button_ok'
            class="small-button"
            (click)="buttonOkAction()">{{options.buttonOkText}}</button>
  </div>
</div>