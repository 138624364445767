<div class="modal-header">
  <h3 class="modal-title" i18n="@@RailFareListTickets"><strong>Tickets</strong></h3>
  <button
    type="button"
    class="light-icon-button close"
    aria-label="Close"
    (click)="activeModal.close(false)"
    ngbAutoFocus>
    <i class="ico-cancel AA-standard-grey" aria-hidden="true"></i>
  </button>
</div>
<div class="modal-body">
  <div *ngIf="isOutboundSplit; else defaultOutboundTicket">
    <div class="journey-section" *ngIf="outbound?.length; else noData">
      <span *ngIf="inbound?.length && showReturn"
        ><strong i18n="@RaiLFareListOutbound">Outbound</strong></span
      >
      <div *ngIf="splitType === RailSearchComposition.DirectSplit">
        <div class="split-ticket-info">
          <span i18n="@@RailFareListsShowDifferentTicket"
            >Show different tickets along your journey.</span
          >
          <a i18n="@@RailFareListHowToUse" target="_blank" href="{{ splitsURL }}">
            How does SplitTicket work?</a
          >
        </div>
        <ul>
          <div class="flex-container-origin-text">
            <div class="flex-item">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32">
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M21.23 3c1.537 0 2.926.92 3.53 2.338l.296.692A24.318 24.318 0 0 1 26.074 22.2a3.845 3.845 0 0 1-3.46 2.792l1.28 2.56a1 1 0 0 1-1.788.894L20.382 25h-8.765l-1.723 3.447a1 1 0 0 1-1.788-.894l1.28-2.56a3.843 3.843 0 0 1-3.46-2.792A24.306 24.306 0 0 1 6.945 6.028l.294-.69A3.84 3.84 0 0 1 10.77 3h10.46zm2.311 4.62l-.04.196c-.98 4.723-3.904 8.184-7.5 8.184-3.648 0-6.602-3.557-7.542-8.38a22.305 22.305 0 0 0-.609 14.035A1.842 1.842 0 0 0 9.62 23H22.38c.82 0 1.542-.548 1.77-1.346a22.319 22.319 0 0 0-.61-14.034zM11.5 18.375a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25zm9 0a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25zM21.23 5H10.77c-.21 0-.413.036-.605.103l-.007-.144C10.368 10.097 13.023 14 16 14c2.948 0 5.583-3.83 5.835-8.895A1.808 1.808 0 0 0 21.23 5z"></path>
              </svg>
            </div>
            <span class="flex-item"> {{ origin }}</span>
          </div>
          <li>
            <div class="location">
              <div *ngFor="let fare of outbound; let first = first">
                <div class="ticket-container">
                  <div class="flex-container">
                    <span class="flex-item">
                      <div>
                        <span class="split-save-icon" *ngIf="outbound?.length > 1">
                          <img
                            class="split-ticket-logo"
                            src="https://images.sabscorp.com/images/enterprise/lightUK/assets/png/split-ticket-logo.png"
                            alt="Split Tickets"
                        /></span>
                        <strong>{{ fare.name }}</strong>
                      </div>
                      <div>
                        <span>{{ fare.origin }} to {{ fare.destination }}</span>
                      </div>
                    </span>
                    <div class="ticket-condition-link">
                      <a
                        i18n="@RailFareListViewConditions"
                        class="flex-item"
                        (click)="openTicketCondition(fare.id)"
                        >View Conditions</a
                      >
                    </div>
                  </div>
                </div>
                <div class="switch-info" *ngIf="first">
                  <strong i18n="@RailFareListStayOnTrain"
                    >Stay on the train, just switch tickets</strong
                  >
                </div>
              </div>
            </div>
          </li>
          <div class="destination">
            <span>{{ destination }}</span>
          </div>
        </ul>
      </div>

      <div *ngIf="splitType === RailSearchComposition.InterchangeSplit">
        <div class="split-ticket-info">
          <span i18n="@@RailFareListsShowDifferentTicket"
            >Show different tickets along your journey.</span
          >
          <a i18n="@@RailFareListHowToUse" target="_blank" href="{{ splitsURL }}">
            How does SplitTicket work?</a
          >
        </div>
        <ul>
          <li
            class="interchange-split"
            *ngFor="let fare of outbound; let first = first; let last = last">
            <div class="flex-container-origin-text">
              <div class="flex-item">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32">
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M21.23 3c1.537 0 2.926.92 3.53 2.338l.296.692A24.318 24.318 0 0 1 26.074 22.2a3.845 3.845 0 0 1-3.46 2.792l1.28 2.56a1 1 0 0 1-1.788.894L20.382 25h-8.765l-1.723 3.447a1 1 0 0 1-1.788-.894l1.28-2.56a3.843 3.843 0 0 1-3.46-2.792A24.306 24.306 0 0 1 6.945 6.028l.294-.69A3.84 3.84 0 0 1 10.77 3h10.46zm2.311 4.62l-.04.196c-.98 4.723-3.904 8.184-7.5 8.184-3.648 0-6.602-3.557-7.542-8.38a22.305 22.305 0 0 0-.609 14.035A1.842 1.842 0 0 0 9.62 23H22.38c.82 0 1.542-.548 1.77-1.346a22.319 22.319 0 0 0-.61-14.034zM11.5 18.375a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25zm9 0a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25zM21.23 5H10.77c-.21 0-.413.036-.605.103l-.007-.144C10.368 10.097 13.023 14 16 14c2.948 0 5.583-3.83 5.835-8.895A1.808 1.808 0 0 0 21.23 5z"></path>
                </svg>
              </div>
              <span class="flex-item"> {{ first ? origin : fare.origin }}</span>
            </div>
            <div class="location">
              <div>
                <div class="ticket-container">
                  <div class="flex-container">
                    <span class="flex-item">
                      <div>
                        <span class="split-save-icon" *ngIf="outbound?.length > 1">
                          <img
                            class="split-ticket-logo"
                            src="https://images.sabscorp.com/images/enterprise/lightUK/assets/png/split-ticket-logo.png"
                            alt="Split Tickets"
                        /></span>
                        <strong>{{ fare.name }}</strong>
                      </div>
                      <div>
                        <span
                          >{{ first ? origin : fare.origin }} to
                          {{ last ? destination : fare.destination }}</span
                        >
                      </div>
                    </span>
                    <div class="ticket-condition-link">
                      <a
                        i18n="@RailFareListViewConditions"
                        class="flex-item"
                        (click)="openTicketCondition(fare.id)"
                        >View Conditions</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="destination">
              <span>{{ last ? destination : fare.destination }}</span>
            </div>
            <div *ngIf="first" class="change-train-text" i18n="@RailFareListChangeTrains">
              Change trains and switch to your next ticket
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div *ngIf="isInboundSplit; else defaultInboundTicket">
    <div class="journey-section" *ngIf="inbound?.length && showReturn">
      <span><strong i18n="@RaiLFareListInbound">Inbound</strong></span>
      <div *ngIf="inboundSplitType === RailSearchComposition.DirectSplit">
        <div class="split-ticket-info">
          <span i18n="@@RailFareListsShowDifferentTicket"
            >Show different tickets along your journey.</span
          >
          <a i18n="@@RailFareListHowToUse" target="_blank" href="{{ splitsURL }}">
            How does SplitTicket work?</a
          >
        </div>
        <ul>
          <div class="flex-container-origin-text">
            <div class="flex-item">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32">
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M21.23 3c1.537 0 2.926.92 3.53 2.338l.296.692A24.318 24.318 0 0 1 26.074 22.2a3.845 3.845 0 0 1-3.46 2.792l1.28 2.56a1 1 0 0 1-1.788.894L20.382 25h-8.765l-1.723 3.447a1 1 0 0 1-1.788-.894l1.28-2.56a3.843 3.843 0 0 1-3.46-2.792A24.306 24.306 0 0 1 6.945 6.028l.294-.69A3.84 3.84 0 0 1 10.77 3h10.46zm2.311 4.62l-.04.196c-.98 4.723-3.904 8.184-7.5 8.184-3.648 0-6.602-3.557-7.542-8.38a22.305 22.305 0 0 0-.609 14.035A1.842 1.842 0 0 0 9.62 23H22.38c.82 0 1.542-.548 1.77-1.346a22.319 22.319 0 0 0-.61-14.034zM11.5 18.375a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25zm9 0a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25zM21.23 5H10.77c-.21 0-.413.036-.605.103l-.007-.144C10.368 10.097 13.023 14 16 14c2.948 0 5.583-3.83 5.835-8.895A1.808 1.808 0 0 0 21.23 5z"></path>
              </svg>
            </div>
            <span class="flex-item"> {{ inboundOrigin }}</span>
          </div>
          <li>
            <div class="location">
              <div *ngFor="let fare of inbound; let first = first">
                <div class="ticket-container">
                  <div class="flex-container">
                    <span class="flex-item">
                      <div>
                        <span class="split-save-icon" *ngIf="inbound?.length > 1">
                          <img
                            class="split-ticket-logo"
                            src="https://images.sabscorp.com/images/enterprise/lightUK/assets/png/split-ticket-logo.png"
                            alt="Split Tickets"
                        /></span>
                        <strong>{{ fare.name }}</strong>
                      </div>
                      <div>
                        <span>{{ fare.origin }} to {{ fare.destination }}</span>
                      </div>
                    </span>
                    <div class="ticket-condition-link">
                      <a
                        i18n="@RailFareListViewConditions"
                        class="flex-item"
                        (click)="openTicketCondition(fare.id)"
                        >View Conditions</a
                      >
                    </div>
                  </div>
                </div>
                <div class="switch-info" *ngIf="first">
                  <strong i18n="@RailFareListStayOnTrain"
                    >Stay on the train, just switch tickets</strong
                  >
                </div>
              </div>
            </div>
          </li>
          <div class="destination">
            <span>{{ inboundDestination }}</span>
          </div>
        </ul>
      </div>

      <div *ngIf="inboundSplitType === RailSearchComposition.InterchangeSplit">
        <div class="split-ticket-info">
          <span i18n="@@RailFareListsShowDifferentTicket"
            >Show different tickets along your journey.</span
          >
          <a i18n="@@RailFareListHowToUse" target="_blank" href="{{ splitsURL }}">
            How does SplitTicket work?</a
          >
        </div>
        <ul>
          <li class="interchange-split" *ngFor="let fare of inbound; let first = first">
            <div class="flex-container-origin-text">
              <div class="flex-item">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32">
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M21.23 3c1.537 0 2.926.92 3.53 2.338l.296.692A24.318 24.318 0 0 1 26.074 22.2a3.845 3.845 0 0 1-3.46 2.792l1.28 2.56a1 1 0 0 1-1.788.894L20.382 25h-8.765l-1.723 3.447a1 1 0 0 1-1.788-.894l1.28-2.56a3.843 3.843 0 0 1-3.46-2.792A24.306 24.306 0 0 1 6.945 6.028l.294-.69A3.84 3.84 0 0 1 10.77 3h10.46zm2.311 4.62l-.04.196c-.98 4.723-3.904 8.184-7.5 8.184-3.648 0-6.602-3.557-7.542-8.38a22.305 22.305 0 0 0-.609 14.035A1.842 1.842 0 0 0 9.62 23H22.38c.82 0 1.542-.548 1.77-1.346a22.319 22.319 0 0 0-.61-14.034zM11.5 18.375a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25zm9 0a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25zM21.23 5H10.77c-.21 0-.413.036-.605.103l-.007-.144C10.368 10.097 13.023 14 16 14c2.948 0 5.583-3.83 5.835-8.895A1.808 1.808 0 0 0 21.23 5z"></path>
                </svg>
              </div>
              <span class="flex-item"> {{ fare.origin }}</span>
            </div>
            <div class="location">
              <div>
                <div class="ticket-container">
                  <div class="flex-container">
                    <span class="flex-item">
                      <div>
                        <span class="split-save-icon" *ngIf="inbound?.length > 1">
                          <img
                            class="split-ticket-logo"
                            src="https://images.sabscorp.com/images/enterprise/lightUK/assets/png/split-ticket-logo.png"
                            alt="Split Tickets"
                        /></span>
                        <strong>{{ fare.name }}</strong>
                      </div>
                      <div>
                        <span>{{ fare.origin }} to {{ fare.destination }}</span>
                      </div>
                    </span>
                    <div class="ticket-condition-link">
                      <a
                        i18n="@RailFareListViewConditions"
                        class="flex-item"
                        (click)="openTicketCondition(fare.id)"
                        >View Conditions</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="destination">
              <span>{{ fare.destination }}</span>
            </div>
            <div *ngIf="first" class="change-train-text" i18n="@RailFareListChangeTrains">
              Change trains and switch to your next ticket
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <ng-template #defaultOutboundTicket>
    <span><strong i18n="@RaiLFareListOutbound">Outbound</strong></span>
    <div *ngIf="!isEvolvi" class="journey-section">
      <div class="flex-container" *ngFor="let fare of outboundFares">
        <span class="flex-item">
          <strong>{{ fare.name }}</strong></span
        >
        <a
          i18n="@RailFareListViewConditions"
          class="flex-item ticket-condition-link"
          (click)="openTicketCondition(fare.id)"
          >View Conditions</a
        >
      </div>
    </div>
    <div *ngIf="isEvolvi" class="journey-section">
      <section class="notSplitSection">
        <ul class="modal-list" *ngIf="outboundFareTermsList[0].terms">
          <section *ngFor="let detail of outboundFareTermsList[0].terms">
            <li class="modal-list-item" *ngIf="detail.value">
              <span class="rule-title">{{ detail.name | translate | titlecase }}</span>
              <span class="rule-value" [innerHTML]="detail.value"></span>
            </li>
          </section>
        </ul>
      </section>
    </div>
  </ng-template>
  <ng-template #defaultInboundTicket>
    <span><strong i18n="@RaiLFareListInbound">Inbound</strong></span>
    <div *ngIf="!isEvolvi" class="journey-section">
      <div class="flex-container" *ngFor="let fare of inboundFares">
        <span class="flex-item">
          <strong>{{ fare.name }}</strong></span
        >
        <a
          i18n="@RailFareListViewConditions"
          class="flex-item ticket-condition-link"
          (click)="openTicketCondition(fare.id)"
          >View Conditions</a
        >
      </div>
    </div>

    <div *ngIf="isEvolvi" class="journey-section">
      <section class="notSplitSection">
        <ul class="modal-list" *ngIf="inboundFareTermsList[0].terms">
          <section *ngFor="let detail of inboundFareTermsList[0].terms">
            <li class="modal-list-item" *ngIf="detail.value">
              <span class="rule-title">{{ detail.name | translate | titlecase }}</span>
              <span class="rule-value" [innerHTML]="detail.value"></span>
            </li>
          </section>
        </ul>
      </section>
    </div>
  </ng-template>
  <ng-template #noData> No data to display </ng-template>
</div>
<div class="modal-footer">
  <button type="button" title="Ok" class="small-button" (click)="activeModal.close(false)">
    Ok
  </button>
</div>

