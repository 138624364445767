import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';
import { ParkingQuoteResult } from '@sabstravtech/obtservices/base';

@Component({
  selector: 'app-gmt-parking-fare-rules-modal',
  templateUrl: './gmt-parking-fare-rules-modal.html',
  styleUrls: ['./gmt-parking-fare-rules-modal.scss']
})
export class GmtParkingModalFareRulesComponent implements OnInit {
  parking: ParkingQuoteResult = null;
  canOverride: boolean = false;
  constructor(
    public modal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

}

LightningModalTypes.GmtParkingModalFareRulesComponent.component = GmtParkingModalFareRulesComponent;