
<div class="wrapper" [class.dark]="darkMode" *ngIf="hasInwardStops || hasOutwardStops">
  <mat-accordion>
    <mat-expansion-panel id="rail-journey">
      <mat-expansion-panel-header>
        <mat-panel-title i18n="@@basketPanelTotalShowDetails">
          Show Details
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
        <ng-container *ngIf="hasOutwardStops">
          <div class="summary">
            <div>
              {{ railOutwardSummary.departureStationName }} To {{ railOutwardSummary.arrivalStationName }}
            </div>
            <div>
              {{ totalTimeJourney(railOutwardSummary.departureDateTime, railOutwardSummary.arrivalDateTime) }}
            </div>
          </div>
          <ul>
            <li *ngFor="let leg of railOutwardStops">
              <div class="time">{{ leg.departureDateTime | sabsTimePipe: 'timeString' }}</div>
              <div class="location">{{ leg.departureStationName }}&nbsp;<span>to</span>&nbsp;{{ leg.arrivalStationName }} 
              </div>
            </li>
          </ul>
        </ng-container>
        <ng-container *ngIf="hasInwardStops">
          <div class="summary">
            <div>
              {{ railInwardSummary.departureStationName }} To {{ railInwardSummary.arrivalStationName }}
            </div>
            <div>
              {{ totalTimeJourney(railInwardSummary.departureDateTime, railInwardSummary.arrivalDateTime) }}
            </div>
          </div>
          <ul>
            <li *ngFor="let leg of railInwardStops">
              <div class="time">{{ leg.departureDateTime | sabsTimePipe: 'timeString' }}</div>
              <div class="location">{{ leg.departureStationName }}&nbsp;<span>to</span>&nbsp;{{ leg.arrivalStationName }} 
              </div>
            </li>
          </ul>
        </ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</div>

