import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component } from '@angular/core';
import { BaseModalComponent } from '../base-modal.component';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';

@Component({
  templateUrl: '../modal-base-template.component.html',
  styleUrls: ['../modal.component.scss']
})
export class BasicModalComponent extends BaseModalComponent {
  constructor(public activeModal: NgbActiveModal) {
    super(activeModal);
  }

  cancel() {
    this.activeModal.close(false);
  }

  buttonOkAction() {
    this.activeModal.close(true);
  }
}
LightningModalTypes.BasicModalComponent.component = BasicModalComponent;
