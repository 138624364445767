<div cdkTrapFocus
     aria-live="assertive">
  <div class="modal-header">
    <h3 class="modal-title"
    [hidden]="!data"
        i18n="@@modalEmailTitle"
        id="modal-header">Email Sent</h3>
        <h3 class="modal-title"
        [hidden]="data"
            i18n="@@modalEmailTitleWarning"
            id="modal-header">Warning!</h3>
    <button type="button"
            class="close"
            aria-label="An Error Occured Close"
            ngbAutoFocus
            i18n-aria-label
            (click)="activeModal.close('Cross click')">
      <span aria-hidden="true"><i class='ico-cancel modal_exit_cross'></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p [hidden]="!data"><span i18n="@@modalSuccessMessageHeader">Message</span>: <span i18n="@@modalSentEmailMessage">Your approval email was resent.</span></p>
    <p [hidden]="data"> <span i18n="@@modalSentEmailFailedMessage">You have reached the maximum number of attempts for re-sending the approval request. The maximum limit is 1 request every 12 hours.</span></p>
    <p [hidden]="data"><span i18n="@@modalSentEmailFailedMessage2"> Please try again using the specified criteria, if the warning continues to appear, please contact your Travel Manager or Online Support Department.</span></p>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="modal_button_okay"
            (click)="activeModal.close(true)"
            i18n="@@modalErrorOK">Ok</button>
  </div>
</div>