<app-loading-screen *ngIf="searchService.search_loading" icon="ico-lounge ico-material"     [animated]="animated">
    <span title i18n="@@loungesresultsloading_title">Just loading your search results</span>
    <div msgOne>
        <span i18n="@@loungesresultsloading_msgOne">Lounges For</span>
        {{ searchParams.locationCode.destination }} ({{
            searchParams.locationCode.gateway.toUpperCase()
        }}) ({{ searchParams.selectedTerminal }})
    </div>
    <span msgTwo i18n="loungesresultsloading_msgTwo">Checking for availability</span>
</app-loading-screen>
