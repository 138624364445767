import { Pipe, PipeTransform } from '@angular/core';

const OPERATORS = {
  CC: 'C2C',
  XC: 'CrossCountry',
  QL: 'Lul',
  QV: 'Arriva Bus',
  HT: 'Hull Trains',
  QA: 'Cie',
  QO: 'Condor Ferries',
  QF: 'Ffestiniog Railway',
  SE: 'SouthEastern',
  ZZ: 'Other Operator',
  HX: 'Heathrow Express',
  QH: 'Hovertravel',
  GX: 'Gatwick Express',
  CH: 'Chiltern Railways',
  ME: 'Merseyrail',
  WS: 'Wrexham And Shropshire Railway',
  SW: 'South West Trains',
  '*T': 'Unknown TRAIN',
  QW: 'Swansea Cork Ferries',
  QR: 'Red Funnel',
  EM: 'East Midlands Trains',
  FC: 'First Capital Connect',
  QT: 'Stagecoach Bus',
  TW: 'Tyne And Wear Metro',
  QU: 'First Group Bus',
  QC: 'Caledonian Macbrayne',
  TP: 'Transpennine Express',
  EP: 'Eurostar (Uk)',
  GC: 'Grand Central Railway',
  GW: 'Great Western Railway',
  GR: 'LNER',
  GN: 'Thameslink And Gt Northern Gn',
  NT: 'Northern Rail',
  QI: 'Irish Ferries',
  QZ: 'Northlink',
  LO: 'London Overground',
  SR: 'First ScotRail',
  QY: 'Strathclyde Pte',
  LT: 'London Underground',
  VT: 'Avanti West Coast',
  PM: 'Pre Metro Operations Ltd',
  SN: 'Southern',
  QN: 'Ns',
  HC: 'Heathrow Connect',
  '*A': 'First Airport Coach',
  AW: 'Arriva Trains Wales',
  '*C': 'Croydon Tramlink',
  LM: 'West Midlands Trains',
  TT: 'First Great Western Link',
  '*B': 'Unknown BUS',
  LE: 'Greater Anglia',
  QB: 'Nir',
  IL: 'Island Line',
  '*F': 'Unknown FERRY',
  CS: 'Serco Caledonian Sleeper',
  TL: 'Thameslink Rail Limited',
};

@Pipe({
  name: 'railOperators',
})
export class RailOperatorsPipe implements PipeTransform {
  transform(value: string): string | undefined {
    return OPERATORS[value.toUpperCase()];
  }
}
