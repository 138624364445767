import { Component, EventEmitter, Injectable, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  BreatfastTypes,
  HotelPolicyEnum,
  HotelAvalibilityQuoteResult,
  HotelEnterpriseSearchInterface,
  HotelQuoteResultWithExtension,
  HotelRoomWithLocalPrice,
  ServiceProvider,
  filtersKeys
} from '@sabstravtech/obtservices/base';
import {
  EnterpriseSearchService,
  HotelRoom,
  HotelTag,
  ServiceType,
  ModalOpenerService,
  UserService,
  EnterpriseBasketService,
  WithSubscriptionComponent,
  Room
} from '@sabstravtech/obtservices/angular';

import { Memorise } from '@sabstravtech/obtservices/base';
import { resultIcons } from '../../../../vendor/enum/result-icons.enum';
import { LightningModalTypes } from '../../../../vendor/classes/modal-types.enum';

const included = [
  'BREAKFAST INCLUDED',
  'breakfast',
  'BB',
  'B&B',
  'Bead and breakfast',
  'Bed and breakfast',
  'Bed&Break',
  'INCLUDES BREAK',
  'INCL BED AND BRE',
  'BRKFST',
  'BED AND BRKFST',
  'BRKFS'
];

// Excluded breakfast keywords
const excluded = [
  'breakfast not included',
  'breakfast is not included',
  // tslint:disable-next-line: quotemark
  "Breakfast isn't included",
  'Does not include breakfast',
  'EXCL',
  'exel Breakfast',
  'Breakfast Costs'
];

// Output regex patterns
const includedPattern = '(' + included.join(')|(') + ')';
const excludedPattern = '(' + excluded.join(')|(') + ')';

// Create regular expressions
const includedExpression = new RegExp(includedPattern, 'i');
const excludedExpression = new RegExp(excludedPattern, 'i');

@Component({
  selector: 'app-rates-hotel',
  templateUrl: './rates-hotel.component.html',
  styleUrls: ['./rates-hotel.component.scss']
})
@Injectable()
export class RatesHotelComponent extends WithSubscriptionComponent implements OnInit {
  rateConditions: string[];
  selectedRates = [];
  // showDebug: boolean = (window as any).showDebug;
  resultItemType = resultIcons;

  public hotelName: string;
  showCtmAccreditation: boolean;
  showSwapSellHotelGroupLink = false;

  @Input() hotel: HotelQuoteResultWithExtension = null;
  @Input() rooms: HotelAvalibilityQuoteResult = null;
  @Input() noOfRooms: number = null;
  @Input() gmt: boolean = false;
  @Input() gmtSelectedRoomIndex: number = null;
  clsRooms: Room[] = [];
  @Output() changeFilterChain: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() gmtNewRoomId: EventEmitter<number> = new EventEmitter<number>();
  searchParams: HotelEnterpriseSearchInterface;
  HotelPolicyEnum: typeof HotelPolicyEnum = HotelPolicyEnum;
  listOfHotelPolicies: HotelPolicyEnum[] = Object.values(HotelPolicyEnum);
  public rateDescriptionMaxLength = 100;
  ServiceProvider = ServiceProvider;
  loading = false;
  canOverride: boolean = false;
  swapSellHotelGroup: string[] = null;
  swapSellMessage: string = null;
  displayPriceBreakdown: any = {};
  roomPrices: any = {};
  displayItinerary = false;

  constructor(
    public userService: UserService,
    private searchService: EnterpriseSearchService,
    protected modalService: ModalOpenerService,
    private activeModal: NgbActiveModal,
    private enterpriseBasketService: EnterpriseBasketService
  ) {
    super();
    this.searchParams = searchService.searches[ServiceType.Hotel];
  }

  getDetail(): void { }

  ngOnInit(): void {
    this.canOverride = this.userService.canOverride();
    const swapHotelGroups = this.rooms.rooms.filter(room => room.swapSellHotelGroup);
    if (swapHotelGroups.length) {
      this.showSwapSellHotelGroupLink = true;
      this.swapSellHotelGroup = swapHotelGroups[0].swapSellHotelGroup;
    }
    const swapSellMessages = this.rooms.rooms.filter(room => room.swapSellMessage);
    if (swapSellMessages.length) {
      this.swapSellMessage = swapSellMessages[0].swapSellMessage;
    }
    this.setupRoomPriceObject();
    this.displayItinerary = this.userService.isBasketReplacementUserFavouriteSet();
  }

  onNoClick(event): void { }

  /**
  @desc - close without selecting an rates (cross at top-right)
  **/
  close(): void {
    this.activeModal.close(); //.dismiss();
  }

  warningSources: string[] = ['Booking.com'];
  warnAboutSource(source: string): boolean {
    return this.warningSources.includes(source);
  }

  /**
    @desc - adds hotel room to basket
  **/
  async addToCart(hotelRoom: HotelRoom): Promise<void> {
    this.close(); // closes rates model
    const ogSearch = this.searchService.searches[ServiceType.Hotel].originalUserSearch;
    // now overwrite the search
    if (ogSearch?.no_of_rooms > 1) {
      await this.modalService.open(LightningModalTypes.WarningModalComponent);
      await this.searchService.searches[ServiceType.Hotel].addHotelRoomToBasket(
        this.hotel,
        hotelRoom,
        this.searchService.searches[ServiceType.Hotel].getSearchQuery(true)
      );
    } else {
      await this.searchService.searches[ServiceType.Hotel].addHotelRoomToBasket(
        this.hotel,
        hotelRoom,
        this.searchService.searches[ServiceType.Hotel].getSearchQuery(true)
      );
    }
    this.enterpriseBasketService.toggleMenu();
  }

  emitChange() {
    this.searchParams.filterLighteningResults();
  }

  public applyFilter({ checked }, filterString: string, filterType: string): void {
    if (checked) {
      this.searchParams.addFilter(filterString, filtersKeys[filterType]);
    } else {
      this.searchParams.removeFilter(filterString, filtersKeys[filterType]);
    }

    this.searchParams.filterChange.next(true);
    this.emitChange();
  }

  /**
    @desc - add Hotel Chain filter when click "Click Here!" on rate modal
  **/
  changeHotelChainFilter() {
    // this.changeFilterChain.emit(null);
    this.changeFilterChain.emit(this.swapSellHotelGroup);
    if (this.swapSellHotelGroup.length) {
      this.swapSellHotelGroup.forEach(group => {
        this.applyFilter({ checked: true }, group, 'chain');
      });
    }

    this.close();
  }

  /**
  @desc - select or deselect a rate (adds or removes it from the selected rates array)
  **/
  selectRate(rate: HotelRoom): void {
    this.activeModal.close([rate]);
  }

  /**
  @desc - open/close the info div
  **/
  showInfo(room: HotelRoom): void {
    // room.showDetail = !room.showDetail;
  }

  /**
  @desc - helper function - determine if a rate is selected by searching for it's index
  **/
  rateAlreadySelected(index: number): boolean {
    return false;
  }

  /**
  @desc - get the price back for the total no of rooms selected
  **/
  getPrice(room: HotelRoom): string {
    const roomPrice = Number(room.total);
    return (roomPrice * this.noOfRooms).toFixed(2);
  }

  /**
  @desc - get the price per room per night
  **/

  getPricePerRoomPerNight(room: HotelRoom): number {
    return room.total / room.nights.length;
  }

  getConvertedPricePerRoomPerNight(room: HotelRoom): string {
    const convertedRoomPrice = Number(room.currencyConversion.convertedTotal);
    const nights = Number(room.nights.length) || 1;
    return (convertedRoomPrice / nights).toFixed(2);
  }

  showPricePerRoomPerNight(): boolean {
    let cal = this.searchParams.calcDayDiff();
    return Number(cal) > 1 || this.noOfRooms > 1;
  }

  /**
  @desc - get the price back for the total no of rooms selected (in GBP)
  **/

  getLocalPrice(room: HotelRoom): string {
    return 'To DO';
  }

  /**
  @desc - get the price back for the room per room per night (in GBP)
  **/
  @Memorise()
  getLocalPricePerRoomPerNight(room: HotelRoom): string {
    const roomPrice = Number(room.total);
    const nights = Number(room.nights.length) || 1;
    return (roomPrice / nights).toFixed(2);
  }

  hasBreakfast(description: string, roomDescription: string): boolean {
    // console.log(description);
    return (
      (description.match(includedExpression) && !description.match(excludedExpression)) ||
      (roomDescription?.match(includedExpression) && !roomDescription?.match(excludedExpression))
    );
  }

  contains(hayStack: string, needle: string): boolean {
    return hayStack.toLowerCase().indexOf(needle) !== -1;
  }

  // changeFilter() {
  //   this.close();
  //   this.changeFilterChain.emit(true);
  // }

  /*openPriceBreakdownDialog(room: HotelRoom) {
    let hotel = {
      unavailable: this.hotel.unavailable,
      unavailableMessage: this.hotel.unavailableMessage
    };
    this.modalService.open(
      LightningModalTypes.PriceBreakdownDialogComponent,
      { centered: true },
      { room: room, hotel: hotel }
    );
  }*/

  togglePriceBreakdown(room: HotelRoom): void {
    if (!this.displayPriceBreakdown[room.roomId]) {
      this.displayPriceBreakdown[room.roomId] = true;
    } else {
      this.displayPriceBreakdown[room.roomId] = false;
    }
  }

  getShortRoomType(room: HotelRoom): number {
    if (room.roomType.indexOf(',') !== -1) {
      return room.roomType.indexOf(',');
    }
  }

  setupRoomPriceObject() {
    this.rooms.rooms.forEach((room: HotelRoom) => {
      this.roomPrices[room.roomId] = this.getPrice(room);
    });
  }

  selectNewRoomIdGMT(index: number, selected: boolean) {
    if (selected) {
      this.gmtNewRoomId.emit(index);
    } else {
      this.gmtNewRoomId.emit(null);
    }  
  }
}

LightningModalTypes.RatesHotelComponent.component = RatesHotelComponent;

