<div cdkTrapFocus
     aria-live="assertive">
  <div class="modal-header">
    <h3 class="modal-title"
        i18n="@@modalSearchErrorTitle"
        id="modal-header">Search Error!</h3>
    <button type="button"
            class="close"
            aria-label="Search Error Close"
            ngbAutoFocus
            (click)="activeModal.close(true)">
      <span aria-hidden="true"><i class='ico-cancel modal_exit_cross'></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class='red-alert'>{{error}}</p>
    <p i18n="@@modalSearchErrorContactAdministrator">Please try again, if this error persists contact an administrator.
    </p>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="modal_button_okay"
            (click)="activeModal.close(true)"
            i18n="@@modalSearchErrorOK">Ok</button>
  </div>
</div>