import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrl: './gallery.component.scss'
})
export class GalleryComponent implements OnInit {
  @Input() photos: string[] = null;
  @Input() title: string = '';
  public selected: string;

  ngOnInit(): void {
    if (this.photos.length) {
      this.selected = this.photos[0];
    }
  }
}
