<div cdkTrapFocus
     aria-live="assertive">
  <div class="modal-header">
    <h3 class="modal-title"
        id="modal-header">
      <em></em>{{message_header | translate}}</h3>
    <button type="button"
            class="close"
            title='Close Modal'
            attr.aria-label="{{message_header}} Close"
            ngbAutoFocus
            i18n-aria-label
            (click)="activeModal.close(false)">
      <span aria-hidden="true">
        <i class='ico-cancel modal_exit_cross'></i>
      </span>
    </button>
  </div>
  <div class="modal-body">
    {{message | translate}}
  </div>
  <div class="modal-footer">
    <button type="button"
            class="small-button small-button--no"
            title='Cancel Choice'
            i18n-title
            (click)="activeModal.close(false)">{{btnCancelText | translate}}</button>
    <button type="button"
            class="small-button"
            title='Confirm Choice'
            i18n-title
            (click)="activeModal.close(true)">{{btnOkText | translate}}</button>
  </div>
</div>
