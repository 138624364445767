import { Component, Input } from '@angular/core';
import { ParkingQuote } from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-parking-details',
  templateUrl: './parking-details.component.html',
  styleUrls: ['./parking-details.component.scss']
})
export class ParkingDetailsComponent {
  @Input() parking: ParkingQuote = null;
  constructor() {}
}
