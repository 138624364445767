import { ProductLoadingScreen } from './product-loading-screen';
import { EnterpriseSearchService,ServiceType } from '@sabstravtech/obtservices/angular'
import { RailEnterpriseSearchInterface} from '@sabstravtech/obtservices/base'
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-rail-loading-screen',
    templateUrl: './rail-loading-screen.component.html',
    styleUrls: [],
  })
export class RailLoadingScreenComponent extends ProductLoadingScreen<RailEnterpriseSearchInterface> {

    @Input() animated: boolean = true;

    constructor(service: EnterpriseSearchService) {
        super(service, service.searches[ServiceType.Rail]);
    }
}