import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';
import { BasketItem } from '@sabstravtech/obtservices/angular';
@Component({
  selector: 'app-booking-price-changes-modal',
  templateUrl: './booking-price-changes-modal.component.html'
})
export class BookingPriceChangesModalComponent implements OnInit {
  @Input() item: BasketItem = null;
  type: string = 'item';
  constructor(public activeModal: NgbActiveModal, private router: Router,) { }

  ngOnInit(): void {
    // check to see what item is as flight is hardcoded
    this.type = this.item?.service?.type.toLowerCase();
  }



  close() {
    this.activeModal.close();
    this.router.navigate(['/search']);
  }
}

LightningModalTypes.BookingPriceChangesModalComponent.component = BookingPriceChangesModalComponent;
