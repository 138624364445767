import {
  Component,
  OnInit,
  ViewChildren,
  Input,
  Output,
  EventEmitter,
  Inject,
} from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LightningUserFavorurite } from '../../../vendor/classes/user-favourite.enum';
import { WithSubscriptionComponent } from '@sabstravtech/obtservices/angular';
import { faUser as faUserSolid } from '@fortawesome/free-solid-svg-icons';
import { faUser as faUserRegular } from '@fortawesome/free-regular-svg-icons';
import {
  TravellerSearchTypes,
  Traveller,
  TravellerObject,
  UserData,
  ErmTravellerType,
  FlightEnterpriseSearchInterface,
  TimeWindow
} from '@sabstravtech/obtservices/base';
import {
  EnterpriseSearchService,
  BookerType,
  UserService,
  ServiceType,
  User,
  UserOrGuest,
  CompanyType
} from '@sabstravtech/obtservices/angular';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-add-travellers',
  templateUrl: './add-travellers.component.html',
  styleUrls: ['./add-travellers.component.scss'],
})
export class AddTravellersComponent
  extends WithSubscriptionComponent
  implements OnInit
{
  faUserSolid = faUserSolid
  faUserRegular = faUserRegular;
  @ViewChildren('input') trvNpt;
  userCanBookOthers: boolean;
  CompanyType: typeof CompanyType = CompanyType;
  ermCompanyType: CompanyType;
  @Input() isGetUsThere: boolean = false;
  @Input()
  get showGuestTravellerForm() {
    return this._showGuestTravellerForm;
  }
  set showGuestTravellerForm(value: boolean) {
    if (value !== this._showGuestTravellerForm) {
      this._showGuestTravellerForm = value;
      this.showGuestTravellerFormChange.emit(value);
    }
  }
  searchParams: FlightEnterpriseSearchInterface;
  @Output() showGuestTravellerFormChange = new EventEmitter<boolean>();
  @Output() loadingTravellerProfile = new EventEmitter<boolean>();
  @Output() showRightPanelMenu = new EventEmitter<boolean>();

  public allowAddGuest: boolean = true;
  public loadingCurrUser: boolean = false;
  public loadingTravellers = false;
  public travellerResults: Traveller[] = [];
  public travellerSearchInput: string = '';
  public canAddSelf: boolean = false;
  public searchTravellersBy: TravellerSearchTypes = TravellerSearchTypes.email;
  public currentNoOfTravellersGreaterOrEqualToMax: boolean = false;
  public canUseTravellerProfile: boolean = false; // TODO workout when this is true

  public showInvokeUser = false;
  private _showGuestTravellerForm: boolean;
  public showTravelerDropdown: boolean;
  public showTravelerInput: boolean;
  public travellerControl = new FormControl();
  public showRemoveUserButton = true;
  isSelfBooker = false;
  ermTravellerType = ErmTravellerType;
  serviceType = ServiceType;
  isLoadingFrequent: boolean;
  constructor(
    public searchService: EnterpriseSearchService,
    public userService: UserService,
    public modalService: NgbModal,
    @Inject(DOCUMENT) private document: Document
  ) {
    super();
  }

  ngOnInit(): void {
    this.getFavouriteTravellers();
    console.log(this.userService.fullUserDetails);

    this.travellerControl.setValue('');

    if (!environment.bypass_logon) {
      this.subscribe(
        this.travellerControl.valueChanges.pipe(debounceTime(300)),
        (newValue: string) => {
          if (newValue) {
            this.searchTravellers(newValue);
          }
        }
      );
    }

    this.ermCompanyType = this.userService.ermCompanyType();

    this.subscribe(
      this.searchService.travellerInformation,
      (travellers: Traveller[]) => {
        console.log('here');
        this.currentNoOfTravellersGreaterOrEqualToMax =
          !this.isGetUsThere ? this.searchService.currentNoOfTravellersGreaterOrEqualToMax() : travellers.length >= 8;
        this._canAddSelf();
      }
    );

    this.subscribe(
      this.userService.fullUserDetails.allDetails,
      (user: User) => {
        this.showInvokeUser = user?.canInvokeUser
      });

    this.subscribe(
      this.userService.fullUserDetails.userData,
      (user: UserData) => {
        this._canAddSelf();
        this.allowAddGuest = !this.userService.isUserFavoriteSet(
          LightningUserFavorurite.no_add_guest
        );

        this.userCanBookOthers = false;

        switch (user.booker_type) {
          case BookerType.SelfBook:
            this.loadingCurrUser = true;
            const selfTraveller = this.userService.createSelfTraveller();
            this.addToTravellerSelection(selfTraveller);
            this.loadingCurrUser = false;
            this.showRemoveUserButton = false;
            this.isSelfBooker = true;
            break;
          case BookerType.BookerWithSelf:
            this.loadingCurrUser = true;
            const BookerWithSelfTraveller = this.userService.createSelfTraveller();
            this.addToTravellerSelection(BookerWithSelfTraveller);
            this.loadingCurrUser = false;
            this.userCanBookOthers = true;
            this.showRemoveUserButton = false;
            break;
          default:
            this.showRemoveUserButton = true;
            this.userCanBookOthers = true;
        }
      }
    );
  }

  get canAddGuest(): boolean {
    return this.userService.canBookGuest();
  }

  get isAgentBooker(): boolean {
    return this.userService.userIsAgent();
  }

  /**
  @desc - control for displaying the traveler input field
  **/
  showTravellerInput(): void {

    if (this.isAgentBooker && !this.userService.bobo) {
      console.warn('+++ User is agent and no bobo user assigned, backing out... +++');
      return;
    }


    if (
      this.userCanBookOthers && 
      !this.isGetUsThere ? !this.searchService.currentNoOfTravellersGreaterOrEqualToMax() : this.searchService.getCurrentNoOfTravellers() <= 8) {
      this.showTravelerInput = !this.showTravelerInput;
      this.showTravelerDropdown = !this.showTravelerDropdown;
    }
    this.showApplyRailModal();
  }

  /**
  @desc - remove a specified traveller from the selected array
  **/
  removeTraveller(traveller: Traveller): void {
    this.searchService.removeTraveller(traveller);
    // this.userService.unsetTravellerProfile();
    this._canAddSelf();
  }

  /**
  @desc - add a traveller to the list of selected travellers
  **/
  addToTravellerSelection(traveller: Traveller): void {
    // console.log('CurrentTravellers', this.searchService.selectedTravellers);
    // traveller not already selected - can add to list
    // traveller.railcard = '';

    if ( !this.searchService.travellerInformation.value.find(item => item.id === traveller.id)) {
      this.searchService.addTraveller(traveller, this.isGetUsThere);
    }
    
    // console.log(this.searchService.searches[ServiceType.Rail]);
    this.travellerResults = []; // set results and string to empty
    this.travellerControl.setValue('');
    /* TODO if (this.searchService.selectedTravellers.length === 1 && this.userService.useTravellerProfile) {
      this.updateUseTravellerProfile();
    }*/

    this.showTravelerDropdown = false;
    this.showTravelerInput = false;

    this._canAddSelf();
  }

  addCurrentUser(): void {
    if (this._canAddSelf()) {
      this.loadingCurrUser = true;
      // console.log(this.userService.fullUserDetails.allDetails);
      const userData = this.userService.fullUserDetails.userData.value;
      userData.dob = this.userService.fullUserDetails.allDetails.value.dob; // since we don't seem to be storing the logged in users dob in userData for some reason
      const selfTraveller = TravellerObject.makeTravellerObjectFromUserData(
        userData
      );
      this.addToTravellerSelection(selfTraveller);
      this.loadingCurrUser = false;
    } else {
      console.error('+++ Max Travellers +++');
    }
    this._canAddSelf();
  }

  private _canAddSelf(): boolean {
    const user: UserData = this.userService.fullUserDetails.userData.value;
    let currentNoOfTravellersLessOrEqualToMax =
    !this.isGetUsThere ? !this.searchService.currentNoOfTravellersGreaterOrEqualToMax() : this.searchService.getCurrentNoOfTravellers() <= 8;
    this.canAddSelf =
      user.booker_type !== BookerType.Traveller &&
      user.booker_type !== BookerType.SelfBook &&
      currentNoOfTravellersLessOrEqualToMax &&
      !this.searchService.travellerAlreadyAdded(
        this.userService.createSelfTraveller()
      );
    return this.canAddSelf;
  }

  /**
   * @desc - display the guest traveller form
   */
  showGuestTravellerFormToggle(): void {
    console.log('Guest Traveller Form Toggled!');
    this.showGuestTravellerForm = !this.showGuestTravellerForm;
      // !this.searchService.currentNoOfTravellersGreaterOrEqualToMax();
  }

  /**
   * @description - function for handling guest returned from guest traveller form component
   * @param guest
   */
  addGuest(guest: Traveller): void {
    this.showGuestTravellerForm = false; // close the guest traveller form
    // Used for testing LU-2079. This causes an error message about the title being wrong during booking.
    if (
      this.userService.isUserFavoriteSet(
        LightningUserFavorurite.LU2079GenerateErrors
      )
    ) {
      guest.title = 'XXXX';
    }
    console.log('Emitted Guest: ', guest);
    // this.userService.createGuestUserNumber(guest.phoneCC, guest.phoneNo);

    // now add the guest to the traveller list - check for max length of passengers
    let currentNoOfTravellersLessOrEqualToMax =
    !this.isGetUsThere ? !this.searchService.currentNoOfTravellersGreaterOrEqualToMax() : this.searchService.getCurrentNoOfTravellers() <= 8;
    
    if (currentNoOfTravellersLessOrEqualToMax) {
      this.addToTravellerSelection(guest);
    } else {
      console.error('+++ Max Travellers +++');
    }
    this._canAddSelf();
  }

  get isMe(): boolean {
    return false; // TODO this.searchService.userAccountType.toLowerCase() !== 'me';
  }

  /**
  @desc - callback function to search for travellers - input the string from the <input>
  **/
  searchTravellers(input: string): void {
    this.loadingTravellers = true;
    this.travellerResults = [];

    if (input && input.length > 1) {
      this.searchService
        .searchTravellers(input, this.searchTravellersBy)
        .subscribe(
          (data: Traveller[]) => {
            this.loadingTravellers = false;

            this.travellerResults = data;

            this.showTravelerDropdown = true;
          },
          (error) => {
            console.log(error);
            this.loadingTravellers = false;
            this.showTravelerDropdown = false;
          }
        );
    }
  }

  /**
   * @description - get the current number of travllers in the selected travllers array
   */
  getCurrentNoOfTravellers(): number {
    return this.searchService.getCurrentNoOfTravellers();
  }

  updateUseTravellerProfile(): void {
    /* TODO
    if (this.userService.useTravellerProfile && this.canUseTravellerProfile()) {
      this.loadingTravellerProfile.emit(true);
      this.subscribe(this.userService.setTravellerPolicy(this.searchService.selectedTravellers[0].email), result => {
        this.loadingTravellerProfile.emit(false);
      });
    } else {
      this.userService.unsetTravellerProfile();
    }*/
  }

  showApplyRailModal() {
    /* TODO
    if (this.searchService.selectedTravellers.length > 1 && this.searchService.rail.applyRailcard) {
      const ref = this.modalService.open(ModalApplyRailComponent, {
        size: 'md',
        windowClass: 'dark-modal',
        centered: true
      })
      console.log('selected travellers count', this.searchService.selectedTravellers.length)
      ref.componentInstance.preventAddSecondTraveller = true;
    }*/
  }

  changedTravellerType(targetType: ErmTravellerType) {
    this.searchParams = this.searchService.searches[ServiceType.Flight];
    // If the checkbox was already checked, clear the currentlyChecked variable
    if (this.searchService.ermTravellerType === targetType) {
      this.searchService.ermTravellerType = this.ermTravellerType.None;
      this.searchParams.outTimeCriteria = TimeWindow.Anytime;
      return;
    } 

    this.searchService.ermTravellerType = targetType;
    if (this.searchService.ermTravellerType === this.ermTravellerType.Joiner) {
      this.searchParams.outTimeCriteria = TimeWindow.Arrive;
    } else if (this.searchService.ermTravellerType === this.ermTravellerType.Leaver) {
      this.searchParams.outTimeCriteria = TimeWindow.Depart;
    } 
  }

  getFavouriteTravellers(){
    this.isLoadingFrequent = true;
    this.subscribe(this.userService.getFavouriteTravellers(), result => {
      if (result) {
        this.isLoadingFrequent = false;
        this.userService.favouriteTravellers.next(result);
      }
    }, error => {
      this.isLoadingFrequent = false;
    })
  }

  addFrequentTravellers(traveller: Traveller) {
    this.isLoadingFrequent = true;
    this.subscribe(this.userService.saveFavouriteTravellers(traveller), result => {
      if (result) {
        this.getFavouriteTravellers();
        this.showRightPanelMenu.emit(true);
      } else {
        this.showRightPanelMenu.emit(false);
        setTimeout(() => {
          this.document.getElementById(`add_frequent_traveller_${traveller.id}`).focus();
        }, 250);
      }
    })
  }

  removeFrequentTraveller(traveller: UserOrGuest){
    this.isLoadingFrequent = true;
    this.subscribe(this.userService.removeFavouriteTravellers(traveller), result => {
      if (result) {
        this.userService.favouriteTravellers.next(this.userService.favouriteTravellers.value.filter(frequentTraveller => frequentTraveller.id !== traveller.id));
        this.isLoadingFrequent = false;
        setTimeout(() => {
          this.document.getElementById(`add_frequent_traveller_${traveller.id}`).focus();
        }, 250);
      }
    })
  }

  moveArrowDownToListbox() {
    if (this.travellerResults.length) {
      document.getElementById('name__option--0').focus();
    }
  }

  moveArrowDown(e: Event, index: number): void {
    if (index < this.travellerResults.length - 1) {
      e.preventDefault();
      (<HTMLElement>document.activeElement.nextElementSibling).focus();
    }
  }

  moveArrowUp(e: Event, index: number): void {
    if (index !== 0) {
      e.preventDefault();
      (<HTMLElement>document.activeElement.previousElementSibling).focus();
    } else {
      document.getElementById('name').focus();
    }
  }

  resetValue(): void {
    this.showTravelerDropdown = !this.showTravelerDropdown;
    this.travellerControl.setValue('');
    document.getElementById('name').focus();
  }
}
