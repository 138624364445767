import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'flightFormat' })
export class FlightPipe implements PipeTransform {
  transform(value: string): any {
    if (!value) {
      return '';
    }

    return value.replace(/\(...\)/, '');
  }
}
