import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BasketItem, WithSubscriptionComponent, EnterpriseSearchService } from '@sabstravtech/obtservices/angular';

import { UiHelpers } from '../../../../vendor/classes/ui-helpers';
import {
  FlightBasketDetails,
  Journey,
} from '../../../../vendor/interfaces/flight-basket-details';

@Component({
  selector: 'app-basket-eurostar',
  templateUrl: './basket-eurostar.component.html',
  styleUrls: ['./basket-eurostar.component.scss'],
})
export class BasketEurostarComponent extends WithSubscriptionComponent implements OnInit {
  @Input() eurostar: BasketItem;
  @Input() duplicateBooking = false;
  @Output() remove: EventEmitter<void> = new EventEmitter<void>();
  @Output() openChangeModal: EventEmitter<void> = new EventEmitter<void>();
  @Input() displayItinerary = false;

  eurostarToDisplay: BasketItem;
  euroStations: { gateway: string; destination: string; }[];
  noOfOutbounds: number;

  constructor(private searchService: EnterpriseSearchService) {
    super();
  }

  ngOnInit(): void {
    this.eurostarToDisplay = { ...this.eurostar };
    this.eurostarToDisplay.detail.flights = [...this.eurostar.detail.outboundFlights, ...this.eurostar.detail.inboundFlights];
    this.noOfOutbounds = this.eurostar.detail.outboundFlights.length;
    console.log(this.eurostarToDisplay.detail.flights);
    this.searchService.lookupEurostarStations('')
      .subscribe(data => {
        this.euroStations = data;
      });
  }

  makeJourneys(flights: FlightBasketDetails[]): Journey[] {
    return UiHelpers.makeJourneys(flights);
  }
  getFullStationName(station: string): string {
    return this.euroStations.find(eStation => eStation.gateway === station).destination;
  }
}
