import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LightningModalTypes } from '../../../../vendor/classes/modal-types.enum';

@Component({
  templateUrl: './modal-lounge-images.component.html',
  styleUrls: ['./modal-lounge-images.component.css']
})
export class ModalLoungeImagesComponent implements OnInit {

  photos: string[] = null;
  selected = {};
  constructor(
    public activeModal: NgbActiveModal
  ) {
  }

  ngOnInit(): void {
    this.photos.forEach((_, index) => {
      this.selected[index] = false
    })
  }

  removeSpaces(path: string) {
    return path.replace(/\s/g, "");
  }

}

LightningModalTypes.ModalLoungeImagesComponent.component =
  ModalLoungeImagesComponent;
