import { Pipe, PipeTransform } from '@angular/core';

const translation = {
  'HP1-3': 'Train between London Heathrow T1-3 and London Paddington station',
  'PH1-3': 'Train between London Paddington and London Heathrow T1-3 station',
  HP4: 'Train between London Heathrow T4 and London Paddington station',
  PH4: 'Train between London Paddington and London Heathrow T4 station',
  HP5: 'Train between London Heathrow T5 and London Paddington station',
  PH5: 'Train between London Paddington and London Heathrow T5 station',
  GV: 'Train between London Gatwick and London Victoria station',
  VG: 'Train between London Victoria and London Gatwick station',
  SL: 'Train between London Stansted and London Liverpool Street station',
  LS: 'Train between London Liverpool Street and London Stansted station',
};

@Pipe({
  name: 'airportExpress',
})
export class AirportExpressPipe implements PipeTransform {
  transform(value: string): string {
    return translation[value];
  }
}
