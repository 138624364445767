import { Component, OnInit, Input } from '@angular/core';
import { ModalOpenerService, IrlSegment } from '@sabstravtech/obtservices/angular';
import moment from 'moment';
// import { Leg } from '../../../obtservices/vendor/models/irl.model';

@Component({
  selector: 'app-irl-legs',
  templateUrl: './irl-legs.component.html',
  styleUrls: ['./irl-legs.component.scss']
})
export class IrlLegsComponent implements OnInit {
  @Input() legs: IrlSegment[];
  @Input() trainNumber: string;
  busToolTip: string = '';
  co2Aray: string[];
  amenitiesSubscription: any;
  duration: string;
  constructor(private modalOpenerService: ModalOpenerService) { }

  ngOnInit(): void {
    // console.log(this.legs, 'ITENERY');
    // maybe pass down both co2 and duration...
    // ! scratch that we dont have it
    // if (this.legs[0].legJson) {
    //   this.co2Aray = this.legs.map(leg => leg.LegCo2);
    // }
    this.duration = this.calculateDuration(this.legs);
  }

  getTimeDifference(date1, date2): string {
    let duration = 0;

    // legs.forEach(leg => {

        const depMoment = moment(date1, 'YYYYMMDDHHmm');
        const arrMoment = moment(date2, 'YYYYMMDDHHmm');

        const diff = depMoment.diff(arrMoment, 'minutes');

        duration += diff;
    // });

    return Math.abs(duration).toString();
  }


  calculateDuration(legs: IrlSegment[], direction: string = 'O'): string {
    // calculateDuration(legs: Leg[], direction: string = 'O'): string {

    let duration = 0;

    legs.forEach(leg => {

        const depMoment = moment(leg.departDateTime, 'YYYYMMDDHHmm');
        const arrMoment = moment(leg.arriveDateTime, 'YYYYMMDDHHmm');

        const diff = depMoment.diff(arrMoment, 'minutes');

        duration += diff;
    });

    return Math.abs(duration).toString();
  }
}
