import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import {RailEnterpriseSearchInterface, TrainlineTraveller } from '@sabstravtech/obtservices/base';
import {EnterpriseSearchService, ServiceType, WithSubscriptionComponent, UserService, ReferenceRailCardQualifier } from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-trainline-traveller',
  templateUrl: './trainline-traveller.component.html',
  styleUrls: ['./trainline-traveller.component.scss']
})
export class TrainlineTravellerComponent extends WithSubscriptionComponent implements OnInit, AfterViewInit {

  @Input() traveller: TrainlineTraveller;
  @Input() railCards: ReferenceRailCardQualifier[] = [];

  searchParams: RailEnterpriseSearchInterface;
  ageInvalid: boolean = false;

  constructor(
    private searchService: EnterpriseSearchService,
    private userService: UserService
  ) {
    super();
   }

  ngOnInit(): void {

    this.searchParams = this.searchService.searches[ServiceType.Rail];
  }

  ngAfterViewInit(): void {
    this.updateAge(this.traveller.age.toString());

  }

  railCardSelected(newCard: any, traveller: TrainlineTraveller, index: number = 0): void {
    console.log('New card Selected: ', newCard);

    if (!newCard) {
      traveller.loyalties[index].loyaltyNumber = '';
      traveller.loyalties[index].loyaltyNumberRequired = false
      traveller.loyalties[index].loyaltyPattern = '';
      traveller.loyalties[index].loyaltyPrefix = '';
    } else {
      // find the card
      const selectedCard = this.railCards.find(card => card.code === newCard);

      console.log(selectedCard);

      traveller.loyalties[index].loyaltyNumber = '';
      traveller.loyalties[index].loyaltyNumberRequired = selectedCard.isNumberRequired;
      traveller.loyalties[index].loyaltyPattern = selectedCard.cardNumberValidationRegex;
      traveller.loyalties[index].loyaltyPrefix = selectedCard.numberPrefix;
      this.searchService.searches[ServiceType.Rail].travellersUpdate();
      this.subscribe(this.userService.getUserDocumentLoyalty(), loyaties => {
        console.log(loyaties);

        // find the correctly loyalty code
        const foundCode = loyaties.loyaltyCodes?.find(loyalty => loyalty.carrier.includes(selectedCard.name));
        console.log(foundCode);
        if (foundCode) {
          traveller.loyalties[index].loyaltyNumber = foundCode.code;
        }

      })
      } 

    
  }

  updateAge(newAge: string): void {
    this.ageInvalid = false;

    if (Number(newAge) < 0 || Number(newAge) > 25 || !newAge) {
      console.log(`+++ Inputted age is invalid: ${newAge} +++`);
      this.ageInvalid = true;
    }
    this.searchService.searches[ServiceType.Rail].travellersUpdate();
  }

  addNewCard(): void {
    this.traveller.loyalties.push({
      loyaltyCode: '',
      loyaltyNumber: '',
      loyaltyNumberRequired: false,
      loyaltyPattern: '',
      loyaltyPrefix: ''
    })
    this.searchService.searches[ServiceType.Rail].travellersUpdate();
  }

  removeCard(index: number): void {
    this.traveller.loyalties.splice(index, 1);

    if (!this.traveller.loyalties.length) {
      this.addNewCard();      // clear the first card rather than remove it - looks weird otherwise
    }
    this.searchService.searches[ServiceType.Rail].travellersUpdate();
  }
}
