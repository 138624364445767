<div cdkTrapFocus
     aria-live="assertive">
    <div class="modal-header">
        <h3 class="modal-title"
            id="modal-header" i18n="@@railDeparture">Departure</h3>
        <button type="button"
                class="close"
                attr.aria-label="Close Live Rail Legs"
                ngbAutoFocus
                (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true"><i class='ico-cancel modal_exit_cross'></i></span>
        </button>
    </div>
    <div class="modal-body">
        <table class="train_journey_table sticky-table white_background"
               aria-label="train times"
               aria-colcount="4">
            <tr>
                <th scope="row"
                    i18n="@@railDue">
                    Due
                </th>
                <th scope="row"
                    i18n="@@railLiveDestination">
                    Destination
                </th>
                <th scope="row"
                    i18n="@@railJourneyStatus">
                    Status
                </th>
                <th scope="row"
                    i18n="@@railPlatform">
                    Platform
                </th>
            </tr>

            <tr *ngFor="let result of legs">
                <td>{{(result.eta ? result.eta : result.sta) | sabsTimePipe: 'timeString'}}</td>
                <td>{{result.locationName }}</td>
                <td>{{getTrainStatus(result, true)}}</td>
                <td *ngIf="result.platform">{{result.platform}}</td>
            </tr>
        </table>
    </div>
</div>