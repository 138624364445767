import { Component, HostListener, QueryList, ViewChild, ViewChildren, OnInit, Output, EventEmitter } from '@angular/core';

import { NgbInputDatepicker, NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { UsStatesPipe } from '../../../startup/pipes/us-states.pipe';
import {
  HttpCallService,
  ModalOpenerService,
  EnterpriseSearchService,
  EnterpriseBasketService,
  UserService,
  ServiceType,
  CarHireDepot
} from '@sabstravtech/obtservices/angular';
import {
  CarhireEnterpriseSearchInterface,
  LocationDetails,
  LocationTypes,
  OBTAirportDetails
} from '@sabstravtech/obtservices/base';

import { TranslateService } from '@ngx-translate/core';
import { LightningUserFavorurite } from '../../../vendor/classes/user-favourite.enum';
import { CarLocationsBase } from '../../../vendor/components/base_components/car-locations-base';
import { DeviceDetector } from '../../../vendor/services/device-detector.service';
import { Helpers } from '../../../vendor/classes/helpers';

@Component({
  selector: 'app-car-search',
  templateUrl: './car-search.component.html',
  styleUrls: ['./car-search.component.scss']
})
export class CarSearchComponent extends CarLocationsBase implements OnInit {
  public carHireCodes: any[] = [];
  public postCode: string = '';
  public carTypeCodes: string[][];
  ServiceType: typeof ServiceType = ServiceType;
  LocationTypes: typeof LocationTypes = LocationTypes;
  searchParams: CarhireEnterpriseSearchInterface;

  carTransmissionCodes: string[][];

  carAirConditionTypes: string[][];

  @ViewChildren(NgbInputDatepicker) datepickerList: QueryList<NgbInputDatepicker>;

  @ViewChild('instance') instance: NgbTypeahead;

  formatter_rail_air = (x: { destination: string; }) => x.destination; // used for formatting the output on the dropdown

  formatter = (x: { Name: string; Display?: string; }) => (x.Display ? x.Display : x.Name);
  postcodeFormatter = (x: { name: string; }) => x.name;

  cityFormatter = (x: { name: string; countryCode: string; admin1_code: string; }) => x.name + '(' + x.countryCode + ')';

  isEnt: boolean = false;

  hotelsearchRequested: boolean = false;
  displayHotelCheck: boolean = false;
  @Output() toggleHotelSearchRequested: EventEmitter<boolean> = new EventEmitter();

  @HostListener('document:click', ['$event'])
  public onClick(event) {
    Helpers.closeOpenCalendars(this.datepickerList, event);
  }
  constructor(
    public deviceDetector: DeviceDetector,
    public searchService: EnterpriseSearchService,
    protected userService: UserService,
    protected modalService: ModalOpenerService,
    protected httpCallService: HttpCallService,
    protected basketService: EnterpriseBasketService,
    protected sanitized: DomSanitizer,
    protected usStatesPipe: UsStatesPipe,
    title: Title,
    public translateService: TranslateService
  ) {
    super(
      deviceDetector,
      searchService,
      userService,
      modalService,
      httpCallService,
      basketService,
      sanitized,
      usStatesPipe,
      title,
      translateService
    );
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.searchParams = this.searchService.searches[ServiceType.Car];
    this.searchParams.return_to_pickup = false;
  }

  filterTimeArray(array): any[] {
    //Asked to only show results every half an hour on Lightning so using this to filter unwanted time intervals out without affecting other FEs
    let filteredArray = array.filter(function (time) {
      return (time.value && time.value.split(":")[1] !== '15' && time.value.split(":")[1] !== '45');
    });
    return filteredArray;
  }

  // ! is this needed
  // showTescoEmail(): void {
  //   this.modalService.open(LightningModalTypes.ModalTescoCarHireEmailComponent, {}, {});
  // }
  // canShowSendEmail(): boolean {
  //   return true;
  //   // return this.searchService.selectedTravellers.length === 0;
  // }

  hideCarHireOptions(): boolean {
    return this.userService.isUserFavoriteSet(LightningUserFavorurite.HideCarHireOptions);
  }

  ensureElementIsScrolledTo(event) {
    try {
      const typeAheadList = event.target.nextElementSibling;
      const activeButton = typeAheadList.getElementsByClassName('active')[0];
      if (activeButton.offsetTop + activeButton.clientHeight > typeAheadList.clientHeight + typeAheadList.scrollTop) {
        typeAheadList.scrollTop = activeButton.offsetTop + activeButton.clientHeight - typeAheadList.clientHeight;
      } else if (activeButton.offsetTop < typeAheadList.scrollTop) {
        typeAheadList.scrollTop = activeButton.offsetTop;
      }
    } catch (e) {
      console.log("Couldn't find elements to scroll");
    }
  }

  postcodeSelected(postcode: any) {
    console.log('+++ New postcode area selected: ', postcode, ' +++');
  }

  /**
  @desc - when a location is changed (city, airport or postcode) update the relevant dropoff if it hasn't already been selected by the user
  **/

  selectedPickup(
    locationType: LocationTypes.Airport | LocationTypes.City | LocationTypes.Postcode,
    selectedItem: NgbTypeaheadSelectItemEvent
  ): void {
    switch (locationType) {
      case LocationTypes.Airport:
        if (!this.searchParams.dropoff_location) {
          const airportPickup: OBTAirportDetails = selectedItem.item;
          this.searchParams.dropoff_location = airportPickup;
          if (airportPickup.countryCode !== this.searchParams.dropoff_country) {
            this.searchParams.dropoff_country = airportPickup.countryCode;
          }
        }
        break;
      case LocationTypes.City:
        if (!this.searchParams.cityDropoff) {
          const cityPickup: LocationDetails = selectedItem.item;
          this.searchParams.cityDropoff = cityPickup;
          this.updateCityDropoffDepot(cityPickup);
        }
        break;
      case LocationTypes.Postcode:
        if (!this.searchParams.postcodeDropoffRaw) {
          const postcodePickup: LocationDetails = selectedItem.item;
          this.searchParams.postcodeDropoffRaw = postcodePickup;
        }
        break;
      default:
        console.log(`unhandled type in selectedPickup() method ${locationType}`);
    }
  }

  /**
    @desc - when the pickup depot is changed (city or postcode) update the relevant dropoff depot, but only if it's currently empty
  **/
  selectedPickupDepot(
    locationType: LocationTypes.City | LocationTypes.Postcode,
    pickupDepot: CarHireDepot
  ): void {
    console.log('selectedPickupDepot() called with:', locationType);
    switch (locationType) {
      case LocationTypes.City:
        if (!this.searchParams.cityDropoffDepot) {
          this.searchParams.cityDropoffDepot = pickupDepot;
        }
        break;
      case LocationTypes.Postcode:
        if (!this.searchParams.postcodeDropoff) {
          this.searchParams.postcodeDropoff = pickupDepot;
        }
        break;
      default:
        console.log(`unhandled type in selectedPickupDepot() method ${locationType}`);
    }
  }

  onOptionsSelected(item: any) {
    this.searchParams.pickup_location = null;
  }

  removeExtra(value: string) {
    return value.replace(/-/, '');
  }

  updateAircon(value: string) {
    if (value && typeof this.searchParams.airCon !== 'boolean') {
      this.searchParams.airCon = true;
    }
  }

  checkHotelCheckbox(locType?: LocationTypes) {

    if (locType) this.searchParams.locationType = locType;

    this.displayHotelCheck = (
      (this.checkDiffBetweenDates(this.searchParams.pickup_datetime, this.searchParams.dropoff_datetime) &&
        ((this.searchParams.locationType === LocationTypes.Airport && !!this.searchParams.pickup_location && !!this.searchParams.dropoff_location) ||
          (this.searchParams.locationType === LocationTypes.Postcode && !!this.searchParams.postcodePickupRaw && !!this.searchParams.postcodeDropoffRaw) ||
          (this.searchParams.locationType === LocationTypes.City && !!this.searchParams.cityPickup && !!this.searchParams.cityDropoff)
        ))
    );
    if (!this.displayHotelCheck && this.toggleHotelSearchRequested.observed) {
      this.hotelsearchRequested = false;
      this.toggleHotelSearchRequested.emit(this.hotelsearchRequested);
    }
  }

  checkDiffBetweenDates(
    outBoundDate: moment.Moment,
    inboundDate: moment.Moment
  ): boolean {
    return (!outBoundDate.isSame(inboundDate, 'date') && inboundDate > outBoundDate); //If they're not on the same date, and inbound is later than outbound, then the stay must be atleast over 1 night
  }

  toggleHotelCheckbox() {
    this.hotelsearchRequested = !this.hotelsearchRequested;
    this.requireHotelCheckbox(this.hotelsearchRequested);
    this.toggleHotelSearchRequested.emit(this.hotelsearchRequested);
  }

  requireHotelCheckbox(isHotelRequired = false): void {
    if (!isHotelRequired) return;
    this.searchService.searches[ServiceType.Hotel].checkin_date = this.searchParams.pickup_datetime;
    this.searchService.searches[ServiceType.Hotel].checkout_date =
      this.searchParams.dropoff_datetime;

    const location = this.searchParams.locationType === LocationTypes.Airport ? this.searchParams.pickup_location : null;
    const cityLocation = this.searchParams.locationType === LocationTypes.City ? this.searchParams.cityPickup : null;
    const postcodeLocation = this.searchParams.locationType === LocationTypes.Postcode ? this.searchParams.postcodePickupRaw : null;

    if (location) {
      this.searchService.searches[ServiceType.Hotel].location = {
        airport: location,
        name: location.destination,
        type: LocationTypes.Airport
      };

      let hotelCounty = {
        cCode: location.countryCode,
        cName: location.country
      };

      this.searchService.searches[ServiceType.Hotel].country = hotelCounty;
      this.searchService.searches[ServiceType.Hotel].location_type_select = LocationTypes.Airport;
    } else if (cityLocation) {
      this.searchService.searches[ServiceType.Hotel].location = cityLocation;
      this.searchService.searches[ServiceType.Hotel].country = {
        "cName": "United Kingdom",
        "cCode": "GB",
      };
      this.searchService.searches[ServiceType.Hotel].location_type_select = LocationTypes.City;
    } else if (postcodeLocation) {
      this.searchService.searches[ServiceType.Hotel].postcode = postcodeLocation;
      this.searchService.searches[ServiceType.Hotel].location_type_select = LocationTypes.City;
      this.searchService.searches[ServiceType.Hotel].country = {
        "cName": "United Kingdom",
        "cCode": "GB",
      };

    } else {
      console.warn('+++ Hotel location not found +++');
    }



  }

  toggleDeliveryCheckbox() {
    if (this.searchParams.delivery) {
      this.resetVendor();
    }
    this.updateLocation();
  }

  toggleCollectionCheckbox() {
    if (this.searchParams.collection) {
      this.resetVendor();
    }
    this.updateLocation();
  }

  resetVendor() {
    this.searchParams.chosenVendor = "-";
  }
}
