import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';


@Component({
    templateUrl: './modal-hotel-price-breakdown.component.html',
    styleUrls: ['./modal-hotel-price-breakdown.component.scss']
})
export class ModalHotelPriceBreakdownComponent implements OnInit {
    public data = null;
    public nights: any[] = []
    public currency = '£';
    constructor(public activeModal: NgbActiveModal) { }

    ngOnInit() {
        this.nights = this.data;
    }
}

LightningModalTypes.ModalHotelPriceBreakdownComponent.component = ModalHotelPriceBreakdownComponent;
