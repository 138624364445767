import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AddTravellersComponent } from './components/add-travellers/add-travellers.component';
import { AirportExpressSearchComponent } from './components/airport-express-search/airport-express-search.component';
import { CarSearchComponent } from './components/car-search/car-search.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { FlightSearchComponent } from './components/flight.search/flight-search.component';
import { EurostarSearchComponent } from './components/eurostar-search/eurostar-search.component';
import { GuestTravellerFormComponent } from './components/guest-traveller-form/guest-traveller-form.component';
import { HotelSearchComponent, RoomNoValidateDirective } from './components/hotel-search/hotel-search.component';
import { LoungeSearchComponent } from './components/lounge.search/lounge-search.component';

import { ParkingSearchComponent } from './components/parking.search/parking-search.component';
import { ProductCheckboxComponent } from './components/product-checkbox/product-checkbox.component';
import { RailSearchComponent } from './components/rail-search/rail-search.component';
import { IrlSearchComponent } from './components/irl-search/irl-search.component';
import { SearchComponent } from './components/search/search.component';
import { StartupModule } from '../startup/startup.module';
import { SearchRoutingModule } from './search-routing.module';
import { TaxiAddressFormComponent } from './components/taxi-address-form/taxi-address-form.component';
import { TaxiSearchComponent } from './components/taxi-search/taxi-search.component';
import { OfflineRequestDialogComponent } from './components/irl-search/offline-request-dialog/offline-request-dialog.component';
import { InvokeUserComponent } from './components/invoke-user/invoke-user.component';
import { FastTrackSearchComponent } from './components/fast-track-search/fast-track-search.component';
import { FerrySearchComponent } from './components/ferry-search/ferry-search.component';
import { MultiCitySearchComponent } from './components/flight.search/multi-city-search/multi-city-search.component';
import { MultiCityDestinationInputComponent } from './components/flight.search/multi-city-search/multi-city-destination-input/multi-city-destination-input.component';
import { FrequentTravellersComponent } from './components/frequent-travellers/frequent-travellers.component';
import { TravellerDetailComponent } from './components/frequent-travellers/traveller-detail/traveller-detail.component';
import { PlusMinusIconComponent } from './components/plus-minus-icon/plus-minus-icon.component';
import { TrainlineTravellerComponent } from './components/rail-search/trainline-traveller/trainline-traveller.component';
import { ApartmentSearchComponent } from './components/apartment-search/apartment-search.component';
import { SeasonTicketsSearchComponent } from './components/season-tickets/season-tickets-search.component';
import { ApartmentRequestDialogComponent } from './components/apartment-search/apartment-request-dialog/apartment-request-dialog.component';
import { MeetingRoomSearchComponent } from './components/meeting-room-search/meeting-room-search.component';

const ENTRY_COMPONENTS = [
  AddTravellersComponent,
  AirportExpressSearchComponent,
  FlightSearchComponent,
  MultiCitySearchComponent,
  MultiCityDestinationInputComponent,
  EurostarSearchComponent,
  GuestTravellerFormComponent,
  CarSearchComponent,
  RailSearchComponent,
  IrlSearchComponent,
  HotelSearchComponent,
  LoungeSearchComponent,
  ParkingSearchComponent,
  FastTrackSearchComponent,
  SearchComponent,
  TaxiAddressFormComponent,
  TaxiSearchComponent,
  OfflineRequestDialogComponent,
  ApartmentRequestDialogComponent,
  FerrySearchComponent,
  FrequentTravellersComponent,
  TravellerDetailComponent,
  MeetingRoomSearchComponent
];

@NgModule({
  declarations: [...ENTRY_COMPONENTS, ProductCheckboxComponent, PlusMinusIconComponent, ClickOutsideDirective, RoomNoValidateDirective, InvokeUserComponent, TrainlineTravellerComponent, ApartmentSearchComponent, SeasonTicketsSearchComponent],
  imports: [CommonModule, StartupModule, SearchRoutingModule],
  exports: [...ENTRY_COMPONENTS, ClickOutsideDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SearchModule { }
