<div cdkTrapFocus aria-live="assertive">
  <div class="modal-header">
    <h3 class="modal-title" i18n="@@requiresOverrideReasonTitle" id="modal-header">
      Requires Override Reason
    </h3>
  </div>
  <div class="modal-body">
    <form [formGroup]="form">
      <ng-container *ngFor="let item of form.controls | keyvalue; let index = index">
        <ng-container
          *ngIf="{
            basketItems: getBasketItem | memoize : item.key,
            mi: getMI | memoize : item.key
          } as data">
          <div *ngIf="data.mi" [ngClass]="{ hide: splitCheck(index) }">
            <label>{{ data.mi.managementInfo?.name }}</label>
            <i class="ico-info" *ngIf="data" [ngbTooltip]="data.mi.managementInfo?.description"></i>
            <select
              [formControlName]="data.mi.managementInfo.id"
              (change)="validate()"
              class="select-box select-box--border select-box--no-icon">
              <option *ngFor="let opt of data.mi.managementInfo.availableValues" [value]="opt.id">
                {{ opt.label }}
              </option>
            </select>
          </div>
        </ng-container>
      </ng-container>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="submit"
      aria-labelledby="cancel and remove basket item"
      class="small-button"
      (click)="cancel()"
      (keydown.enter)="cancel()"
      (keydown.space)="cancel()"
      i18n-aria-label
      i18n="@@requiresOverrideReasonCancel">
      Cancel
    </button>
    <button
      type="submit"
      [disabled]="!valid()"
      aria-labelledby="selected reason and continue"
      class="small-button"
      (click)="ok()"
      (keydown.enter)="ok()"
      (keydown.space)="ok()"
      i18n-aria-label
      i18n="@@requiresOverrideReasonOk">
      ok
    </button>
  </div>
</div>
