import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LightningModalTypes } from '../../../../vendor/classes/modal-types.enum';

@Component({
  selector: 'app-offline-request-dialog',
  templateUrl: './offline-request-dialog.component.html',
  styleUrls: ['./offline-request-dialog.component.scss']
})
export class OfflineRequestDialogComponent {
  // Input
  public jrfReference: string = null;
  public text: string = 'The offline booking request has been sent.';

  constructor(
    public activeModal: NgbActiveModal
  ) {
  }
}

LightningModalTypes.OfflineRequestDialogComponent.component = OfflineRequestDialogComponent;