<div [ngSwitch]="joinerOrLeaver">
  <div *ngSwitchCase="ermTravellerType.Joiner" class="ermTraveller-wrapper">
    <div rel="icon" type="image/x-icon" 
    [ngClass]="{
      'erm-traveller-type-img-ship': showShip,
      'erm-traveller-type-img-rig': showRig,
      'erm-traveller-type-img-windmill': showWindmill,
      'erm-traveller-type-img-ship-dark': showShip && style ==='dark',
      'erm-traveller-type-img-rig-dark': showRig && style ==='dark',
      'erm-traveller-type-img-windmill-dark': showWindmill && style ==='dark'
   }"
   ></div>
    <span class="ermTraveller-title">Joiner</span>
  </div>
  <div *ngSwitchCase="ermTravellerType.Leaver" class="ermTraveller-wrapper">
    <div rel="icon" type="image/x-icon" 
    [ngClass]="{
      'erm-traveller-type-img-ship': showShip,
      'erm-traveller-type-img-rig': showRig,
      'erm-traveller-type-img-windmill': showWindmill,
      'erm-traveller-type-img-ship-dark':  showShip && style ==='dark',
      'erm-traveller-type-img-rig-dark': showRig && style ==='dark',
      'erm-traveller-type-img-windmill-dark': showWindmill && style ==='dark'
   }"></div>
    <span class="ermTraveller-title">Leaver</span>
  </div>
</div>