import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {
  state,
  style,
  transition,
  trigger,
  animate,
} from '@angular/animations';
import * as _ from 'lodash';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import { Title } from '@angular/platform-browser';
import { LightningUserFavorurite } from '../../../vendor/classes/user-favourite.enum';
import {
  TaxiAttribute,
  CabHireEnterpriseSearchInterface,
  AddressForm,
  ServiceProvider,
  TransType
} from '@sabstravtech/obtservices/base';
import {
  ServiceType,
  EnterpriseSearchService,
  UserService,
  CabHireVehicleType,
  TransportType
} from '@sabstravtech/obtservices/angular';
import { Helpers } from '../../../vendor/classes/helpers';
import { DeviceDetector } from '../../../vendor/services/device-detector.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-taxi-search',
  templateUrl: './taxi-search.component.html',
  styleUrls: ['./taxi-search.component.scss'],
  animations: [
    trigger('easeInOut', [
      state('void', style({ opacity: 0, height: 0 })),
      transition(
        ':enter',
        animate('300ms ease-in', style({ opacity: 1, height: 395 }))
      ),
      transition(
        ':leave',
        animate('200ms ease-out', style({ opacity: 0, height: 0 }))
      ),
    ]),
  ],
})
export class TaxiSearchComponent implements OnInit {
  @Input() resultsMode = false;
  today: NgbDateStruct = Helpers.dateToNgDate(new Date());
  timesArray: string[];
  transTypes: TransType[] = [];
  readonly searchParams: CabHireEnterpriseSearchInterface;
  CabHireVehicleType = CabHireVehicleType;
  TransportType = TransportType;
  limoDurations: number[];
  hasGroundspan = false;
  hasCmac= false;
  constructor(
    public deviceDetector: DeviceDetector,
    public searchService: EnterpriseSearchService,
    public userService: UserService,
    protected title: Title,
    public translateService: TranslateService
  ) {
    this.searchParams = searchService.searches[ServiceType.Cab];
  }

  ngOnInit() {
    this.timesArray = Helpers.generateTimesList(0, 24);
    this.limoDurations = Array.from({ length: 23 }, (_, i) => i + 2);

    if (
      this.userService.isUserFavoriteSet(LightningUserFavorurite.taxiAlwaysCall)
    ) {
      this.searchParams.attributes.forEach((attr: TaxiAttribute) => {
        if (attr.key === 'call_on_arrival') {
          attr.enabled = true;
        }
      });
    }

    this.hasGroundspan = this.userService.userHasServiceProvider(ServiceType.Cab, ServiceProvider.Groundspan);
    this.hasCmac = this.userService.userHasServiceProvider(ServiceType.Cab, ServiceProvider.CMAC);

    if (this.hasGroundspan && this.userService.fullUserDetails.userServices.value[ServiceType.Cab].length === 1) {
      this.transTypes = [this.searchParams.groundspanTransTypes.find(type => type.key === CabHireVehicleType.Limo)];
      this.searchParams.vehicleType = CabHireVehicleType.Limo;
    } else {
      this.transTypes = this.hasGroundspan ? this.searchParams.groundspanTransTypes : this.hasCmac ? this.searchParams.cmacTransTypes : this.searchParams.transTypes;
    }

  }

  toggleAttr(attr: TaxiAttribute): void {
    attr.enabled = !attr.enabled;
  }

  search(): void {
    if (this.searchService.validateForms()) {
      this.searchService.search_objects[ServiceType.Cab].chosen = true;
      this.searchService.determineHighestSearchPriority();
      this.searchService.startSearches();
    }
  }

  fromAddressChange(event) {
    this.searchParams.fromAddress = event;
  }

  toAddressChange(event) {
    this.searchParams.toAddress = event;
  }

  stationChange(event, isTo = false) {
    const newAddress = this.countyCheck(event, (isTo ? this.searchParams.toAddress : this.searchParams.fromAddress));
    if (isTo) {
      this.searchParams.toAddress = newAddress;
    } else {
      this.searchParams.fromAddress = newAddress;
    }
  }

  countyCheck(event, currentAddress: AddressForm): AddressForm {
    const address = this.searchParams.getAddress(event);
    if (!address?.County && currentAddress?.County) {
      address.County = currentAddress.County;
    }
    return address;
  }

  viaPickupAddress(event) {
    this.searchParams.viasFrom = null;
    this.searchParams.viasFrom = event;
  }

  viaStationChange(event) {
    this.searchParams.viasFrom = this.searchParams.getAddress(event);
  }

  viaDropoffAddress(event) {
    this.searchParams.viasTo = null;
    this.searchParams.viasTo = event;
  }

  viaDropoffChange(event) {
    this.searchParams.viasTo = this.searchParams.getAddress(event);
  }



}
