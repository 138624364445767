<div cdkTrapFocus
     aria-live="assertive">
    <div class="modal-header">
        <h3 class="modal-title"
            i18n="@@modalTitleWellbeing"
            id="modal-header">Wellbeing</h3>
        <button type="button"
                class="close"
                ngbAutoFocus
                aria-label="Close wellbeing modal"
                (click)="close()"
                (keydown.enter)="close()"
                (keydown.space)="close()"
                >
            <span aria-hidden="true"><i class='ico-cancel modal_exit_cross'></i></span>
        </button>
    </div>
    <div class="modal-body">
        <p i18n="@@modalBodyWellbeing1">You have selected a trip that exceeds the recommended travel time within a day.</p>
        <p i18n="@@modalBodyWellbeing2">Would you like to see alternative options including an overnight stay?</p>
    </div>
    <div class="modal-footer">
      <button class="small-button" (click)="close()" i18n="@@modalNoThanks">No Thanks</button>
      <button class="small-button" (click)="ok()" i18n="@@modalOK">Ok</button>
    </div>
</div>
