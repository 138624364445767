import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {
  HelperRoutines,
  RouteHappyService,
  ModalOpenerService,
  FlightJourney,
  WithSubscriptionComponent
} from '@sabstravtech/obtservices/angular';
import {
  FlightItineraryWithExtensions,
  RouteHappyAmenities,
  FlightGroupedQuoteResult,
  Memorise,
} from '@sabstravtech/obtservices/base';
import { LightningModalTypes } from '../../../../../vendor/classes/modal-types.enum';

@Component({
  selector: 'app-flight-legs-old',
  templateUrl: './flight-legs.component.html',
  styleUrls: ['./flight-legs.component.scss'],
})
export class FlightLegsOldComponent
  extends WithSubscriptionComponent
  implements OnInit, OnDestroy {
  @Input() legs!: FlightJourney[];
  @Input() altColour!: boolean;
  @Input() flight!: FlightGroupedQuoteResult;
  @Input() FlightItineraryWithSelected!: FlightItineraryWithExtensions;
  amenitiesSubscription: any;
  amenitites: RouteHappyAmenities[] = [];
  constructor(
    private routeHappyService: RouteHappyService,
    private modalOpenerService: ModalOpenerService,
    private helpers: HelperRoutines
  ) {
    super();
  }
  ngOnDestroy(): void {
    if (this.amenitiesSubscription) {
      this.amenitiesSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    console.log(this.legs, 'ITENERY');
    this.amenitites = [];
    this.amenitiesSubscription = this.routeHappyService
      .getAmenitiesForFlight(this.flight)
      .subscribe((amenitites: RouteHappyAmenities[]) => {
        this.amenitites = amenitites;
      });
  }

  getTimeDifference(depDateTime: string, arrDateTime: string): number {
    return this.helpers.getTimeDifference(arrDateTime, depDateTime);
  }

  @Memorise()
  setIco(leg: FlightJourney): string {
    if (leg.equipmentCode === 'BUS') {
      return 'ico-bus';
    } else {
      return 'ico-flight';
    }
  }

  showFlightCosts(flight: FlightItineraryWithExtensions) {
    this.modalOpenerService
      .open(
        LightningModalTypes.FlightCostsComponent,
        {},
        { costs: flight.total }
      )
      .then();
  }
  
}