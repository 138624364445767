<div cdkTrapFocus aria-live="assertive">
    <div class="modal-header">
        <h3 class="modal-title" i18n="@@dt-header" id="modal-header">
            Warning - Different traveller
        </h3>
        <button
            type="button"
            class="close"
            aria-label="Cancel"
            ngbAutoFocus
            (click)="activeModal.close(DifferentTraveller.cancel)">
            <span aria-hidden="true"><i class="ico-cancel modal_exit_cross"></i></span>
        </button>
    </div>
    <div class="modal-body">
        <div>
            <span i18n="@@dt-body1">The current traveller</span>
            <span *ngIf="travellers"> (
                <span *ngFor="let user of travellers; last as isLast">
                    {{ user.email }}<span *ngIf="!isLast">, </span>
                </span> )
            </span>

            <span i18n="@@dt-body2"> and the assigned to the basket </span>
            <span *ngIf="userInBasket"> (
                <span *ngFor="let user of userInBasket; last as isLast">
                    {{ user.email }}<span *ngIf="!isLast">, </span>
                </span>
            </span> )
            <span i18n="@@dt-body3"> are different.</span>
        </div>
        <div>
            <span i18n="@@dt-body4">
                These need to be the same. Please choose one of the options below
            </span>
            <ul>
                <li i18n="@@dt-body-newbasket">Create a new basket with current traveller.</li>
                <li i18n="@@dt-body-cancel">Cancel adding the item to the basket.</li>
            </ul>
        </div>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="modal_button_okay"
            (click)="activeModal.close(DifferentTraveller.newBasket)"
            i18n="@@dt-newBasket">
            Create new basket
        </button>
        <button
            type="button"
            class="modal_button_okay"
            (click)="activeModal.close(DifferentTraveller.cancel)"
            i18n="@@dt-cancel">
            Cancel
        </button>
    </div>
</div>

