import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { WithSubscriptionComponent } from '@sabstravtech/obtservices/angular';

@Component({ template: '' })
export class BaseComponent extends WithSubscriptionComponent {
  translationSubscription: Subscription = null;

  constructor(protected title: Title, private translate: TranslateService) {
    super();
  }

  setTitle(title: string) {
    if (this.translationSubscription) {
      this.translationSubscription.unsubscribe();
    }
    this.subscribe(this.translate.get(title), (trnaslation: string) => {
      this.title.setTitle(trnaslation);
    });
  }
}
