import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../vendor/components/base_components/base-componet';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { User, PreferenceKey, UserPreference, ServiceType } from '@sabstravtech/obtservices/angular';
import { PREFERENCE_VALUES, PreferenceValue } from '@sabstravtech/obtservices/base';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-profile-information-travel-preferences',
  templateUrl: './profile-information-travel-preferences.component.html',
  styleUrls: ['./profile-information-travel-preferences.component.scss']
})
export class ProfileInformationTravelPreferencesComponent extends BaseComponent implements OnInit {
  faChevronDown = faChevronDown;
  @Input() user: User;
  @Input() travelPreferencesForm: FormGroup;
  @Input() isEditable: boolean;

  mealRequest: PreferenceValue[];
  seatingPreference: PreferenceValue[];
  specialNeed: PreferenceValue[];
  seatLocation: PreferenceValue[];
  seatPosition: PreferenceValue[];
  seatDirection: PreferenceValue[];

  preferenceKeyEnum = PreferenceKey;

  constructor(
    title: Title,
    translateService: TranslateService,
    private fb: FormBuilder
  ) {
    super(title, translateService);
  }

  get preferences() {
    return this.travelPreferencesForm.controls["preferences"] as FormArray;
  }

  ngOnInit(): void {
    this.mealRequest = PREFERENCE_VALUES.filter((pref: PreferenceValue) => pref.type === PreferenceKey.MealRequest);
    this.specialNeed = PREFERENCE_VALUES.filter((pref: PreferenceValue) => pref.type === PreferenceKey.SpecialNeed);
    this.seatingPreference = PREFERENCE_VALUES.filter((pref: PreferenceValue) => pref.type === PreferenceKey.SeatingPreference);
    this.seatLocation = PREFERENCE_VALUES.filter((pref: PreferenceValue) => pref.type === PreferenceKey.SeatLocation);
    this.seatPosition = PREFERENCE_VALUES.filter((pref: PreferenceValue) => pref.type === PreferenceKey.SeatPosition);
    this.seatDirection = PREFERENCE_VALUES.filter((pref: PreferenceValue) => pref.type === PreferenceKey.SeatDirection);
    if (this.user && !this.preferences.value.length) {
      this.setValue();
    }
    if(!this.isEditable) this.travelPreferencesForm.disable();
  }

  setValue() {
    this.travelPreferencesForm.setValue({
      preferences: []
    });

    if (this.user.preferences?.length) {
      this.user.preferences.forEach((preference: UserPreference) => {
        this.addPreference(preference);
      });
    } else {
      // add preferences
      let newPreference = [
        {
          serviceType: ServiceType.Flight,
          preferenceKey: PreferenceKey.SpecialNeed,
          preferenceValue: '',
          code: ''
        },
        {
          serviceType: ServiceType.Flight,
          preferenceKey: PreferenceKey.MealRequest,
          preferenceValue: '',
          code: ''
        },
        {
          serviceType: ServiceType.Flight,
          preferenceKey: PreferenceKey.SeatingPreference,
          preferenceValue: '',
          code: ''
        },
        {
          serviceType: ServiceType.Rail,
          preferenceKey: PreferenceKey.SeatDirection,
          preferenceValue: '',
          code: ''
        },
        {
          serviceType: ServiceType.Rail,
          preferenceKey: PreferenceKey.SeatPosition,
          preferenceValue: '',
          code: ''
        },
        {
          serviceType: ServiceType.Rail,
          preferenceKey: PreferenceKey.SeatLocation,
          preferenceValue: '',
          code: ''
        }
      ] as UserPreference [];

      newPreference.forEach((pref: UserPreference) => this.addPreference(pref));
    }
  }

  addPreference(preference: UserPreference){
    const preferenceForm = this.fb.group({
      serviceType: [preference.serviceType],
      preferenceKey: [preference.preferenceKey],
      preferenceValue: [preference.preferenceValue],
      code: preference.code
    });
    this.preferences.push(preferenceForm);
  }
}
