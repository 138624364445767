import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
// import { LegTKT } from '../../../obtservices/vendor/models/irl.model';
import { LightningModalTypes } from '../../../../vendor/classes/modal-types.enum';
// import { resultIcons } from '../../../obtservices/vendor/enums/result-icons.enum'
import {
  EnterpriseSearchService,
  EnterpriseBasketService,
  ServiceType,
  UserService,
  IrlTicket,
  ModalOpenerService,
  IrlTicketOption,
  IrlJourneyOption
} from '@sabstravtech/obtservices/angular';

import {
  ClassType,
  IrlTicketOptionWithSelected
} from '@sabstravtech/obtservices/base';

export enum TabNames {
  RailLegTimes = 'Rail Leg Times',
  FareDetails = 'Fare Details'
}

@Component({
  selector: 'app-irl-item-detail',
  templateUrl: './irl-list-item-detail.component.html',
  styleUrls: ['./irl-list-item-detail.component.scss']
})
export class IrlItemDetailComponent implements OnInit {
  @Input() first: any;
  @Input() second: any
  @Input() isReturnTrip: boolean;
  @Input() currency: string;
  @Input() legs//: LegTKT;
  @Input() trainNumber: string
  @Input() item: IrlJourneyOption;
  @Input() discountCard: string;
  @Output() selectIrl: EventEmitter<any> = new EventEmitter<any>();
  TabNames: typeof TabNames = TabNames
  selectedTab: TabNames = TabNames.FareDetails;
  ClassType: typeof ClassType = ClassType;


  // resultItemType: typeof resultIcons = resultIcons;

  constructor(
    private basketService: EnterpriseBasketService,
    public searchService: EnterpriseSearchService,
    private modalService: ModalOpenerService
  ) { }

  ngOnInit(): void {
  }

  getTimeDifference(date1: string, time1: string, date2: string, time2: string): string {
    const tempDate1 = date1.match(/.{1,2}/g); // splits every 2 characters
    const tempTime1 = time1.match(/.{1,2}/g);

    const tempDate2 = date2.match(/.{1,2}/g); // splits every 2 characters
    const tempTime2 = time2.match(/.{1,2}/g);

    const jsDate1 = new Date(
      Number(tempDate1[0]) + 2000,
      Number(tempDate1[1]) - 1,
      Number(tempDate1[2]),
      Number(tempTime1[0]),
      Number(tempTime1[1])
    );
    const jsDate2 = new Date(
      Number(tempDate2[0]) + 2000,
      Number(tempDate2[1]) - 1,
      Number(tempDate2[2]),
      Number(tempTime2[0]),
      Number(tempTime2[1])
    );

    const diff = new Date(jsDate2.getTime() - jsDate1.getTime());
    return diff.getHours().toString().padStart(2, '0') + diff.getMinutes().toString().padStart(2, '0');
  }

  /**
   * @description - change the selected tab - called from view
   * @param tabString  - the tab to select
   */
  selectTab(tabString: TabNames): void {
    this.selectedTab = tabString;
  }

  /**
 * @description - Open the fare rules modal with the data retrieved from jsonCAllHBR
 * @param ticket -  IrlTicketOption
 */
  openFareRulesModal(ticket: IrlTicketOption): void {
    this.modalService.open(
      LightningModalTypes.ModalIrlFareComponent,
      {
        centered: true
      },
      { data: ticket.fareRules }
    );
  }

  addToBasket(item: IrlTicketOptionWithSelected): void {
    this.selectIrl.emit(item);
  }

  disabledCheck(): boolean {
    return !!(this.isReturnTrip && !this.searchService.searches[ServiceType.InternationalRail].outboundTicket);
  }

  getClassName(classType: ClassType): ClassType {
    switch (classType) {
      case ClassType.Class_1:
      case ClassType.First_Class:
        return ClassType.First_Class;
      case ClassType.Class_2:
      case ClassType.Second_Class:
        return ClassType.Second_Class;
      default:
        console.log('something has gone wrong');
        return classType;
    }
  }
}
