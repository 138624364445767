<button class="small-button carbon_back_button"
        *ngIf="displayBackToWellbeing"
        (click)="back(true)"><i class='ico ico-arrow-left'></i><span i18n="@@backToWellbeingResults">Back to Wellbeing
                Results</span></button>
<button class="small-button carbon_back_button"
        *ngIf="displayBackToPrevious"
        (click)="back()"><i class='ico ico-arrow-left'></i><span i18n="@@backToPreviousResults">Back to Previous
                Results</span></button>
<div class="background-60 carbon-container"
     *ngIf="policies.length">
        <div class="flex-row-container spaced">
                <span class="co2_margin" i18n="@@CO2AllowanceRemaining">CO<sub>2</sub> Allowance Remaining</span> <span
                              *ngIf="policies[0].carbonPolicySource === CarbonPolicySource.User"
                              i18n="@@CO2ForSelectedTravellers"> For Selected Travellers</span>
                <span>
                <a href="javascript:void(0)"
                   (click)="hidePolicy()"
                   tabindex="0"
                   (keydown.enter)="hidePolicy()"
                   (keydown.space)="hidePolicy()"
                   class="carbon-toggle">
                   <span>
                    <span i18n="@@searchToggleState_hide" *ngIf="!hideAllowance">Hide</span>
                    <span i18n="@@searchToggleState_show" *ngIf="hideAllowance">Show</span>
                    <span i18n="@@carbonAllowanceDisplayButton"> Carbon Allowance</span>
                   </span>
                   <i [ngClass]="!hideAllowance ? 'ico-tri-up' : 'ico-tri-down'" aria-hidden="true"></i>
                </a></span>
        </div>
        <ng-container *ngIf="!hideAllowance">
                <app-carbon-allowance *ngFor="let policy of policies; let first = first"
                                      [policy]="policy"
                                      [first]="first"
                                      [co2PerItem]="co2PerItem"
                                      [currentId]="currentId"
                                      [basket]="basket"></app-carbon-allowance>
        </ng-container>
</div>
