import { Component, OnInit, Input } from '@angular/core';
import { EnterpriseSearchService, ServiceType } from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-rail-results',
  templateUrl: './rail-results.component.html',
  styleUrls: ['./rail-results.component.css']
})
export class RailResultsComponent implements OnInit {
  @Input() isAirportExpress = false;
  gettingFareRules = false;
  ServiceType: typeof ServiceType = ServiceType;
  searchParams = this.searchService.searches[ServiceType.Rail];

  constructor(public searchService: EnterpriseSearchService) {}

  ngOnInit(): void {}
}
