import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FastTrackQuote } from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-fast-track-details',
  templateUrl: './fast-track-details.component.html',
  styleUrls: ['./fast-track-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FastTrackDetailsComponent implements OnInit {
  @Input() fastTrack: FastTrackQuote;
  constructor() {}

  ngOnInit(): void {}
}
