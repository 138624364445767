import { Component, OnInit, Input } from '@angular/core';
import {
  HelperRoutines,
  FlightJourney,
  WithSubscriptionComponent,
  FlightItinerary,
  EnterpriseSearchService,
  ServiceType,
  
} from '@sabstravtech/obtservices/angular';
import { Memorise, FlightEquipment } from '@sabstravtech/obtservices/base';
import moment from 'moment';

@Component({
  selector: 'app-flight-legs',
  templateUrl: './flight-legs.component.html',
  styleUrls: ['./flight-legs.component.scss']
})
export class FlightLegsComponent extends WithSubscriptionComponent implements OnInit {
  @Input() legs!: FlightJourney[];
  @Input() altColour!: boolean;
  @Input() flight!: FlightItinerary;
  @Input() isReturn = false;
  @Input() isMultiCity = false;
  @Input() flightIndex: number;
  timeDifference: number = 0;

  // enums
  FlightEquipment: typeof FlightEquipment = FlightEquipment;

  constructor(private helpers: HelperRoutines, public searchService: EnterpriseSearchService) {
    super();
  }

  ngOnInit(): void {}

  showTimeDifference(depDateTime: string, arrDateTime: string): boolean {
    this.timeDifference = this.helpers.getTimeDifference(arrDateTime, depDateTime);
    return this.isMultiCity ? !!(this.timeDifference < 1440) : true;
  }

  @Memorise()
  setIco(leg: FlightJourney): string {
    if (leg.equipmentCode === 'BUS') {
      return 'ico-bus';
    } else {
      return 'ico-flight';
    }
  }

  dayDifference(dept: string, arr: string): string {
    const diff = this.helpers.dayDifference(
      this.helpers.sanitiseDateTime(dept),
      this.helpers.sanitiseDateTime(arr)
    );
    return diff ? '+' + diff : '';
  }

  parseDateToMoment(dateString: string): moment.Moment {
    return moment(this.helpers.sanitiseDateTime(dateString));
  }
}
