import { LogonService } from '@sabstravtech/obtservices/angular';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService, User } from '@auth0/auth0-angular';
import { firstValueFrom } from 'rxjs';
import { Location } from '@angular/common';


const GUARD_EXCEPTIONS = [
  // list of exceptions to the guard - anyone can get to these pages, even if not logged in
  '/sso',
  '/login',
  '/auth/auth0'
];

const sso_re = /^\/sso\/(?:flight|hotel|rail)/;
const sso_code = /access_token=/;

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  private tokenPromise: Promise<[string, User]> | null = null; // To store the pending promise
  constructor(
    private authService: LogonService,
    private router: Router
    // private auth0: AuthService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const url: string = state.url;
    return this.checkLogin(url);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return this.canActivate(route, state);
  }

  async checkLogin(url: string): Promise<boolean> {
    // const queryParams = new URLSearchParams(window.location.search);
    // const code = queryParams.get('code');
    // console.log('queryParams', queryParams, code);

    // if (code) {

    //   if (!this.tokenPromise) {
    //     this.tokenPromise = this.fetchAccessTokenAndUser(); // Fetch token and user

    //     try {
    //       const [access_token, user] = await this.tokenPromise;

    //       if (access_token && user) {
    //         // Redirect to /auth/auth0 with the necessary tokens
    //         console.log(access_token, user);
    //         const cleanUrl = window.location.origin + `/auth/auth0?access_token=${access_token}&username=${encodeURIComponent(user.email)}`;
    //         window.history.replaceState({}, document.title, cleanUrl);
    //         window.location.href = cleanUrl; // Perform redirect
    //         return false; // Prevent navigating to the protected route
    //       }
    //     } catch (error) {
    //       // Handle errors and ensure promise is reset on failure
    //       this.tokenPromise = null;
    //       console.error('Error during token fetch:', error);
    //       await this.router.navigate(['/login']);
    //       return false;
    //     } finally {
    //       this.tokenPromise = null; // Reset promise after resolution
    //     }
    //   } else {
    //     // If a token fetch is already in progress, wait for it to resolve
    //     await this.tokenPromise;
    //   }
    // }
    // Handle exceptions and authentication check for non-SSO routes
    if (
      GUARD_EXCEPTIONS.includes(url) ||
      sso_re.test(url) ||
      (await this.authService.checkLogin())
    ) {
      return true;
    }
    this.authService.redirectUrl = url;
    this.router.navigate(['/logon']);
    return false;
  }

  // Helper method to fetch access token and user
  // private fetchAccessTokenAndUser(): Promise<[string, User]> {
  //   return Promise.all([
  //     firstValueFrom(this.auth0.getAccessTokenSilently()),
  //     firstValueFrom(this.auth0.user$)
  //   ]);
  // }
}

