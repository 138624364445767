<ng-template #rt let-r="result" let-t="term">
  <div class="item-search-select">
    <span *ngIf="r.season-ticketsstation?.stationCode" class="item-dest">
      {{ r.name | flightFormat }} ({{ r.season-ticketsstation?.stationCode }})
    </span>
    <span *ngIf="!r.season-ticketsstation?.stationCode" class="item-dest">{{ r.name }}</span>
  </div>
</ng-template>

<div id="SeasonTicketsSearchForm">
  <div class="inputs-container">
    <div class="half-container">
      <div class="half-block">
        <label
          class="header-label"
          for="season-tickets-travel-from-input"
          i18n="@@season-ticketsSearchTravellingFrom">
          Travelling from:
        </label>
        <i class="ico-rail combo-box-icon" aria-hidden="true"></i>
        <div class="flex-row-container">
          <input
            autocomplete="off"
            type="text"
            id="season-tickets-travel-from-input"
            i18n-placeholder
            placeholder="Enter a location"
            onClick="this.select()"
            class="input-box"
            name="fromLocation"
            [ngbTypeahead]="railLocations"
            [(ngModel)]="searchParams.fromLocation"
            [resultTemplate]="rt"
            [inputFormatter]="formatter"
            tabindex="0"
            (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
            (keyup.arrowup)="ensureElementIsScrolledTo($event)"
            [ngClass]="{
              'error-box': searchParams.validationMessages?.includes(
                'Please Enter a From Location'
              )
            }" />
        </div>
      </div>
      <div class="half-block">
        <label
          class="header-label"
          for="season-tickets-travel-to-input"
          i18n="@@railSearchTravellngTo">
          Travelling to:
        </label>
        <i class="ico-rail combo-box-icon" aria-hidden="true"></i>
        <div class="flex-row-container">
          <input
            autocomplete="off"
            type="text"
            id="season-tickets-travel-to-input"
            i18n-placeholder
            placeholder="Enter a location"
            onClick="this.select()"
            class="input-box"
            name="toLocation"
            [ngbTypeahead]="railLocations2"
            [(ngModel)]="searchParams.toLocation"
            [resultTemplate]="rt"
            [inputFormatter]="formatter"
            tabindex="0"
            (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
            (keyup.arrowup)="ensureElementIsScrolledTo($event)"
            [ngClass]="{
              'error-box': searchParams.validationMessages?.includes('Please Enter a To Location')
            }" />
        </div>
      </div>
    </div>

    <!-- <div class="half-container">
      <div class="half-block">
        <label
          class="header-label"
          for="season-tickets-route"
          i18n="@@season-ticketsRoute">
          Route
          </label>
          <i class="ico-rail combo-box-icon"></i>
        <div class="flex-row-container">
          <input
            autocomplete="off"
            type="text"
            id="season-tickets-route"
            i18n-placeholder
            placeholder="Example: High Speed"
            onClick="this.select()"
            class="input-box"
            name="route"
            [(ngModel)]="searchParams.route"
            tabindex="0"
            (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
            (keyup.arrowup)="ensureElementIsScrolledTo($event)" />
        </div>
      </div>
    </div> -->

    <div class="half-container flex-start">
      <div class="half-block">
        <label class="header-label date-label" for="season-tickets-StartDate" i18n="@@season-tickets-StartDate">Start Date (DD/MM/YYYY)</label>
        <ngbd-datepicker-i18n>
          <i class="ico-calendar combo-box-icon cursor_pointer" aria-hidden="true"></i>
          <app-datepicker
            [class]="'combo-box'"
            [componentId]="'season-tickets-StartDate'"
            [minDate]="searchParams.minStartDate"
            [startDate]="searchParams.startDate"
            [model]="searchParams.startDate_ngb"
            (newDate)="searchParams.startDate_ngb = $event"
            >
          </app-datepicker>
        </ngbd-datepicker-i18n>
      </div>
    </div> 

    <div class="half-container pt-4">
      <div class="flex-column-container">
        <label id="seasonTicketType" class="header-label" i18n="@@seasonTicketType">Select Season Ticket Type</label>
        <div class="flex-row-container">
          <mat-radio-group [(ngModel)]="searchParams.ticketType"
          aria-label="return flight time preferences">
          <mat-radio-button class="light-primary time-option"
              aria-labelledby="seasonTicketType"
              color="primary"
              [value]="seasonTicketType.value"
              *ngFor="let seasonTicketType of searchParams.seasonTicketTypes">
              {{ seasonTicketType.display }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
    
    <!-- options -->
    <div class="flex-column-container">
      <h2 class="header-label rem-1-5" i18n="@@seasonTicketsSearchOptions">Options</h2>
      <div class="half-container">
        <div class="half-block">
          <div class="half-container">
            <div class="half-block">
              <label i18n="@@RailSearchMaxClass" for="season-tickets-select-class">
                Class of Service
              </label>
              <select
                class="combo-box"
                [(ngModel)]="searchParams.classOfService"
                id="season-tickets-select-class">
                <option
                  *ngFor="let serviceClass of searchParams.seasonTicketClassTypes"
                  [value]="serviceClass.value">
                  {{ serviceClass.display}}
                </option>
              </select>
            </div>
            <div class="half-block">
              <label i18n="@@season-ticketsRailOperator" for="season-tickets-rail-operator">
                Rail Operator
              </label>
              <select
                class="combo-box"
                [(ngModel)]="searchParams.railOperator"
                id="season-tickets-rail-operator">
                <option value=''
                i18n="@@SeasonTicketAny">Any</option>
                <option
                  *ngFor="let operator of searchParams.railOperators"
                  [value]="operator">
                  {{ operator }}
                </option>
              </select>
            </div>
          </div>
          <div class="half-container pt-4">
            <div class="half-block">
              <label i18n="@@seasonTicketsUndergroundRequired" for="season-tickets-underground-required">
                Is London Underground Required 
              </label>
              <select
                class="combo-box"
                [(ngModel)]="searchParams.isLondonUndergroundRequired"
                id="season-tickets-underground-required">
                <option value='null'
                i18n="@@SeasonTicketSelectfromdropdown"
                selected="selected">Select from dropdown</option>
                <option
                  *ngFor="let undergroundZone of searchParams.seasonTicketLondonUndergroundZones"
                  [value]="undergroundZone.value">
                  {{ undergroundZone.display }}
                </option>
              </select>
            </div>
            <div class="half-block">
              <label i18n="@@seasonTicketsDeliveryType" for="season-tickets-delivery-type">
                Delivery Type
              </label>
              <select
                class="combo-box"
                [(ngModel)]="searchParams.deliveryType"
                id="season-tickets-delivery-type">
                <option
                  *ngFor="let deliveryType of searchParams.seasonTicketDeliveryTypes"
                  [value]="deliveryType.value">
                  {{ deliveryType.display }}
                </option>
              </select>
              <p i18n="@@seasonTicketsAllDeliveryAreSubject">*All delivery are subject to the order been submitted before 12:30pm.</p>
            </div>
          </div>
          <div class="half-container">
            <div class="half-block">
              <label i18n="@@seasonTicketsDeliveryAddress" for="season-tickets-delivery-address">
                Delivery Address and Postcode*
              </label>
              <textarea 
              class="textarea-container"
              rows="5" 
              [(ngModel)]="searchParams.deliveryAddressAndPostcode" 
              id="season-tickets-delivery-address"
              [ngClass]='{"error-box": searchParams.validationMessages?.includes("Please add Delivery Address and Postcode")}' >
              </textarea>
            </div>
            <div class="half-block">
              <label i18n="@@seasonTicketsPhotocardNumber" for="season-tickets-photocard-number">
                Photocard Number
              </label>
              <input
              #tt
              autocomplete="off"
              type="text"
              id="season-tickets-photocard-number"
              onClick="this.select()"
              class="input-box"
              name="postcode"
              [(ngModel)]="searchParams.photoCardNumber"
              tabindex="0"
              (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
              (keyup.arrowup)="ensureElementIsScrolledTo($event)" />
            </div>
          </div>
        </div>


        <div class="half-block">
          <label i18n="@@season-tickets-additionalInformation" for="additionalInformation">
            Additional Information
          </label>
          <textarea 
            rows="15"
            class="textarea-container"
            [(ngModel)]="searchParams.additionalInformation" 
            id="additionalInformation">
            Notes
          </textarea>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <app-season-ticket-loading-screen></app-season-ticket-loading-screen> -->