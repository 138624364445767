<div class="modal-header">
    <h3 class="modal-title"
        i18n="@@scratchpadViewer"
        id="supplement-modal-header">
        Scratchpad Viewer
    </h3>
    <button type="button"
            class="close"
            i18m-aria-label
            aria-label="Close Booking Information"
            ngbAutoFocus
            (click)="close()"
            (keydown.enter)="close()"
            (keydown.space)="close()"
            aria-describedby="">
        <span aria-hidden="true"><i class="ico-cancel modal_exit_cross"></i></span>
    </button>
</div>
<div class="modal-body"
     id="mi-details">
     <p>
        <strong>Basket Ref: {{basketRef}}</strong>
     </p>
     <div *ngIf="loading" class="relative-container loading-container">
        <span ><i
            class='button_spinner input-loading-spinner-left'></i> Loading notes...</span> 
     </div>
    <div class="saved-note-container" *ngIf="!loading">
        <!-- list of availbale notes -->
        <div class="saved-note" *ngFor="let note of savedNotes">
            <p>
                <strong>{{note.createdAt | sabsDate: 9}}</strong>&nbsp;
                {{note.agent.email}}
            </p>
            <p>
                {{note.note}}
            </p>
        </div>
    </div>

    <!-- new entries -->
    <div>
        <p>Add a new Scratchpad entry</p>
        <textarea
            style="width:100%"
            rows="5"
            maxlength="5000"
            [(ngModel)]="newNote"
        ></textarea>
    </div>
</div>
<div class="modal-footer">
    <div class="relative-container">
        <button type="button"
            id="button_ok"
            class="small-button"
            aria-label="Save and Continue"
            i18n-aria-label
            aria-live="false"
            [disabled]="!valid() || saving"
            (click)="submit()"
            (keydown.enter)="submit()"
            (keydown.space)="submit()"
            i18n="@@suppsave">
        <span *ngIf="!saving">Save</span>
        <span *ngIf="saving"><i
            class='button_spinner input-loading-spinner-left'></i></span>
        
    </button>
    </div>
    
</div>