import { Pipe, PipeTransform } from '@angular/core';

const OPERATORS = {
  NX: 'National Express',
};

@Pipe({
  name: 'coachOperators',
})
export class CoachOperatorsPipe implements PipeTransform {
  transform(value: any): any {
    return OPERATORS[value.toUpperCase()];
  }
}
