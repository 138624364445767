import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-preferred-icons',
  templateUrl: './preferred-icons.component.html',
  styleUrls: ['./preferred-icons.component.scss']
})
export class PreferredIconsComponent {
  @Input() tmcPreferred = false;
  @Input() tmcPreferredLogoUrl: string = '';
  @Input() tmcPreferredMessage: string = '';
  @Input() clientPreferred = false;
  @Input() companyPreferredMessage: string = '';
  @Input() officePreferredMessage: string = '';
  preferredMessage: string = '';
  constructor() { }

  ngOnInit() {
    this.getPreferredMessage();
  }

  getPreferredMessage(): string {
    if (this.tmcPreferredLogoUrl) {
      this.preferredMessage = (this.officePreferredMessage ? this.officePreferredMessage: '') + ' '+
      (this.companyPreferredMessage ? this.companyPreferredMessage : '')
    } else {
      this.preferredMessage = 
      (this.tmcPreferredMessage ? this.tmcPreferredMessage: '') + ' '+
      (this.officePreferredMessage ? this.officePreferredMessage: '') + ' '+
      (this.companyPreferredMessage ? this.companyPreferredMessage : '')
    }

    return this.preferredMessage;
  }
}
