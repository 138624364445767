<div class="details-wrapper">
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <span class="mat-content">
          <mat-panel-title>
            <span i18n="@@basketPanelTotalShowDetails">
              Show Details
            </span>
            <span class="sr-only"> for {{ detail?.vendorName }} car</span>
          </mat-panel-title>
        </span>
      </mat-expansion-panel-header>
        <ng-container>
          <div class="summary">
            <div *ngIf="detail?.identifiers?.vehType as vehType">
              <strong>{{vehType}}</strong>
            </div>
            <div *ngIf="detail?.car as car">
              <strong>{{car?.class}} </strong>
              <span *ngIf="car?.type"><strong>{{searchParams.getCarTypeForDisplay(car.type)}}</strong></span>
            </div>
          </div>
        </ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</div>