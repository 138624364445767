import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FlightEnterpriseSearchInterface, ServiceProvider } from '@sabstravtech/obtservices/base';
import {
  EnterpriseSearchService,
  UserService,
  StorageService,
  InputFlightJourney,
  ServiceType,
  WithSubscriptionComponent,
  FlightOptions,
  FlightExtrasOptions
} from '@sabstravtech/obtservices/angular';
import { FormControl, FormGroup } from '@angular/forms';
import { LightningUserFavorurite } from '../../../../../vendor/classes/user-favourite.enum';

@Component({
  selector: 'app-flight-extras',
  templateUrl: './flight-extras.component.html',
  styleUrls: ['./flight-extras.component.scss']
})
export class FlightExtrasComponent extends WithSubscriptionComponent implements OnInit {
  permittedService: string[] = [
    ServiceProvider.Travelfusion,
    ServiceProvider.TravelportFlight,
    ServiceProvider.AmadeusFlight
  ];
  // Flights on the same journey (Outbound or Inbound)
  @Input() flights: InputFlightJourney[] = [];
  @Input() extraOptions: FlightOptions;
  // Current flight ID
  @Input() flightId: string = '';
  @Input() travellerId: string = '';
  @Output() flightExtras: EventEmitter<any> = new EventEmitter<any>();
  @Input() selectedFlightExtrasChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() supplier: string = '';
  @Input() showTFXLuggage = false;
  @Input() hideAncillary = false;
  @Input() hideExtras = false;
  @Input() parentTabId: string = '';
  ServiceProvider = ServiceProvider;
  public form: FormGroup;
  searchParams: FlightEnterpriseSearchInterface;
  // This flag is used to hide Luggage Options, Flight Extras and Advanced Ancillary Options
  // Reduced Mobillity Assistance should not be hidden
  showExtras = false;
  emptyOption: any = {};
  selectedFlightExtras: {
    flightExtras: FlightExtrasOptions;
    baggageAllowance: FlightExtrasOptions;
    advancedAncillaryOptions: FlightExtrasOptions;
    reducedMobillityAssistance: FlightExtrasOptions;
  } = {
    flightExtras: this.emptyOption,
    baggageAllowance: this.emptyOption,
    advancedAncillaryOptions: this.emptyOption,
    reducedMobillityAssistance: this.emptyOption
  };

  constructor(
    public activeModal: NgbActiveModal,
    public searchService: EnterpriseSearchService,
    public userService: UserService,
    public storageService: StorageService
  ) {
    super();
  }

  ngOnInit() {
    this.form = new FormGroup({
      flightExtras: new FormControl(''),
      baggageAllowance: new FormControl(''),
      advancedAncillaryOptions: new FormControl(''),
      reducedMobillityAssistance: new FormControl('')
    });

    this.searchParams = this.searchService.searches[ServiceType.Flight];

    const seatMapConfigs = this.userService.getUserFavoriteObject<{
      GDS: { showSeatMaps: boolean };
      LCC: { showSeatMaps: boolean; showExtras: boolean };
      NDC: { showSeatMaps: boolean };
    }>(LightningUserFavorurite.FlightResultsConfiguration);

    this.showExtras =
      this.supplier === (ServiceProvider.Travelfusion as string)
        ? seatMapConfigs?.LCC?.showExtras
        : true;

    if (this.permittedService.includes(this.supplier)) {
      // Subscribe for changes to update the extras on the same journey
      this.selectedFlightExtrasChange.subscribe(({ data, key, updatingFlightId }) => {
        if (
          !this.flights.some(flight => flight.id === updatingFlightId) ||
          updatingFlightId === this.flightId
        ) {
          return;
        }
        this.selectedFlightExtras[key] = data[key];
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.parentTabId?.currentValue === this.parentTabId) {
      if (!this.showTFXLuggage) {
        this.form?.get('baggageAllowance')?.disable();
      }

      if (this.hideAncillary) {
        this.form?.get('advancedAncillaryOptions')?.disable();
      }

      if (this.hideExtras) {
        this.form?.get('flightExtras')?.disable();
      }
    }
  }

  updateFlightExtras(key: string) {
    this.flightExtras.emit({
      data: this.form.value,
      flightIds: this.flights.map(flight => flight.id), // List of flightIds on the same journey
      updatingFlightId: this.flightId,
      travellerId: this.travellerId,
      key // Updating object key
    });
  }
}

