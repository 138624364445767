<div class="tabs_container_flightDetails" aria-live="assertive">
  <div class="tabsHeader">
    <div
      class="tab_base"
      [ngClass]="{'tab_select': selectedTab === TabType.FareDetails}"
      (click)="selectedTab = TabType.FareDetails"
      (keydown.enter)="selectedTab = TabType.FareDetails"
      tabindex="0"
      role="button"
      i18n="@@itemDetailsFaredetails"
    >
      Fare Details
    </div>
    <div
      class="tab_base"
      [ngClass]="{'tab_select': selectedTab === TabType.FlightLegTimes}"
      (keydown.enter)="selectedTab = TabType.FlightLegTimes"
      (click)="selectedTab = TabType.FlightLegTimes"
      tabindex="0"
      role="button"
      i18n="@@itemDetailsFlightLegTimes"
    >
      Show Details
    </div>
  </div>
  <div
    class="pane"
    *ngIf="selectedTab === TabType.FareDetails"
    id="0"
    i18n-Title
    title="Fare Details"
  >
    <div class="ticketScroll">
      <button (click)="alwaysLog(flightDetail)" *ngIf="debug">Dump</button>
      <ng-container *ngFor="let ticket of tickets">
        <span hidden>{{ticket.title | translate}}</span>
        <app-flight-type-tickets-old
          class="economy"
          [tickets]="ticket.flights"
          [isSelected]="isSelected"
          [flightDetails]="flightDetail"
          [type]="ticket.type"
          [title]="ticket.title"
          [direction]="direction"
          [search]="search"
          [searchParams]="searchParams"
        >
        </app-flight-type-tickets-old>
      </ng-container>
    </div>
  </div>
  <div class="pane" *ngIf="selectedTab === TabType.FlightLegTimes" id="1">
    <div class="leg_wrapper">
      <div class="leg_container">
        <app-routehappy [flight]="flightDetails"></app-routehappy>
        <app-flight-legs-old [legs]="legs" [flight]="flightDetail">
        </app-flight-legs-old>
      </div>
    </div>
  </div>
</div>
