import { Component, Input, OnInit } from '@angular/core';
import {
  ServiceType,
  EnterpriseSearchService
} from '@sabstravtech/obtservices/angular';
import { FlightBasketDetails } from '../../vendor/interfaces/flight-basket-details';

import { FlightEnterpriseSearchInterface } from '@sabstravtech/obtservices/base';
@Component({
  selector: 'app-show-details-flight',
  templateUrl: './show-details-flight.component.html',
  styleUrls: ['./show-details-flight.component.scss']
})
export class ShowDetailsFlightComponent implements OnInit {
  @Input() flightDetails: FlightBasketDetails;
  @Input() detail: any;
  
  public searchParams: FlightEnterpriseSearchInterface;
  constructor( private searchService: EnterpriseSearchService) { }

  ngOnInit(): void {
    this.searchParams = this.searchService.searches[ServiceType.Flight];
  }

}
