<div *ngIf="!editMode && control.value" (click)="editMode = !editMode" class="input-box input-box--border input-box--no-icon encrypted">
    {{control.value | encrypt : 'X' : 3}}
</div>
<div *ngIf="!editMode && !control.value" (click)="editMode = !editMode" class="input-box input-box--border input-box--no-icon encrypted ng-invalid">
</div>
<input *ngIf="editMode" class="input-box input-box--border input-box--no-icon"                    
        id="free-text-input"
        [readonly]="readOnly"
        [attr.aria-label]="name | splitCamelCase"
        [formControl]="control"
        (change)="change($event)"
        name="value" />

