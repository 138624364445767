import { Component, OnInit } from '@angular/core';
import {
  UserService
} from '@sabstravtech/obtservices/angular';
import { WithSubscriptionComponent } from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-frequent-travellers',
  templateUrl: './frequent-travellers.component.html',
  styleUrls: ['./frequent-travellers.component.scss']
})

export class FrequentTravellersComponent  extends WithSubscriptionComponent implements OnInit {
  isLoadingFrequent:boolean;
  
  constructor(
    public userService: UserService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getFavouriteTravellers();
  }

  getFavouriteTravellers(){
    this.isLoadingFrequent = true;
    this.subscribe(this.userService.getFavouriteTravellers(), result => {
      if(result){
        this.userService.favouriteTravellers.next(result);
        this.isLoadingFrequent = false;
      }
    }, error => {
      this.isLoadingFrequent = false;
    })
  }

}
