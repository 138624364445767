<ng-container *ngIf="{
  favouriteTravellers: userService.favouriteTravellers | async
} as data">
  <div class="frequent-travellers-tab background-40">
    <ng-template *ngIf="isLoadingFrequent">
      <p i18n="@@LoadingUserData" tabindex="0">Loading User Data... <i class="button_spinner"></i></p>
    </ng-template>

    <ng-container *ngIf="data.favouriteTravellers?.length && !isLoadingFrequent">
      <app-traveller-detail
      *ngFor="let favTraveller of data.favouriteTravellers"
      [traveller]="favTraveller"
      class="flex-row-container flex-row-container--between traveller"></app-traveller-detail>
    </ng-container>

    <ng-container *ngIf="!data.favouriteTravellers?.length && !isLoadingFrequent">
      <p i18n="@@YouHaveNoSavedFavourites" tabindex="0">You have no saved favourites.</p>
    </ng-container>
  </div>
</ng-container>