import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { LightningModalTypes } from '../../../../../vendor/classes/modal-types.enum';
import { HotelRoom } from '@sabstravtech/obtservices/angular';
import { HotelQuoteResultWithExtension } from '@sabstravtech/obtservices/base';
import { Helpers } from '../../../../../vendor/classes/helpers';
import { resultIcons } from '../../../../../vendor/enum/result-icons.enum';

@Component({
  selector: 'app-price-breakdown-dialog',
  templateUrl: './price-breakdown-dialog.component.html',
  styleUrls: ['./price-breakdown-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PriceBreakdownDialogComponent implements OnInit {
  // Input
  @Input() public room: HotelRoom = null;
  @Input() public hotel: HotelQuoteResultWithExtension = {} as HotelQuoteResultWithExtension;
  public hotelRoom: HotelRoom = null;

  firstDay: string;
  lastDay: string;
  numberOfNights: number;
  resultItemType = resultIcons;

  constructor(
    public activeModal: NgbActiveModal
  ) {
  }

  ngOnInit() {
    if (this.room) {
      this.numberOfNights = this.room.nights.length;
      this.firstDay = this.room.nights[0].date;
      this.lastDay = this.room.nights[this.numberOfNights - 1].date;

      this.hotelRoom = Helpers.clone(this.room);
      this.hotelRoom.nights.forEach(night => {
        night.date = Helpers.yyyymmddToNgDate(
          Helpers.dateStringToyyyymmdd(night.date)
        );
      });
    }
  }

  isDisabled = (date: NgbDate) =>
    this.hotelRoom.nights.every(night => night.date.day !== date.day ||
      night.date.month !== date.month || night.date.year !== date.year);
}

LightningModalTypes.PriceBreakdownDialogComponent.component = PriceBreakdownDialogComponent;