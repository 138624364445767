<div class="background-60 carbon-comparison-container" 
    *ngIf="lowestCarbonFlight && lowestCarbonEuroStar">
    <div class="flex-column-container">
        <div class="flex-column-container">
            <h2 class="carbon_header" i18n="@@carbonComparisonHeader">Carbon Comparison</h2>
            <div *ngIf="lowestCarbonEuroStar < lowestCarbonFlight">
                <span i18n="@@carbonComparisonEurostarSave">Save</span>&nbsp;{{(lowestCarbonFlight - lowestCarbonEuroStar).toFixed()}}kg&nbsp;<span i18n="@@carbonComparisonEurostarText">of CO₂ by travelling via Eurostar.</span>
            </div>
        </div>
        <div *ngIf="lowestCarbonEuroStar">
            <div class="flex-column-container">
                <div class="transaction-carbon-container">
                    <i class="ico-flight" aria-hidden="true">
                    </i>
                    <div class="transaction-carbon-bar"
                         [ngClass]="{ 'high_carbon': lowestCarbonFlight > lowestCarbonEuroStar }"
                         [ngStyle]="{'width': lowestCarbonFlight < lowestCarbonEuroStar ? getCarbonStyle() : null}">
                        Flight - {{lowestCarbonFlight.toFixed()}}kg
                    </div>
                </div>
                <div class="transaction-carbon-container">
                    <i class="custom_icon-eurostar" aria-hidden="true">
                    </i>
                    <div class="transaction-carbon-bar"
                         [ngClass]="{ 'high_carbon': lowestCarbonEuroStar > lowestCarbonFlight }"
                         [ngStyle]="{'width': lowestCarbonEuroStar < lowestCarbonFlight ? getCarbonStyle() : null}">
                        Eurostar - {{lowestCarbonEuroStar.toFixed()}}kg
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
