import { Pipe, PipeTransform } from '@angular/core';
import { FlightSeat } from '../../results-module/components/flights/flight-extras-dialog/flight-seat-map/flight-set.interface';
import { SeatMapType } from '../../vendor/enum/seat-map-type.enum';

/**
 * Return seat classes separated by comma in human format
 *
 * @export
 * @class SeatClassPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'seatMapSpacing'
})
export class SeatMapSpacingPipe implements PipeTransform {
  transform(value: FlightSeat, isEndSeat: boolean, aisleSeatLocation: string[]): string {
    const seatAttributes =  value.attributes || [];
    const seatCode = seatAttributes.map(r => r.code);
    const isAisleSeat = seatCode.includes(SeatMapType.AisleSeat) && !seatCode.includes(SeatMapType.CenterSeat);
    if((!isEndSeat && isAisleSeat) || (aisleSeatLocation.includes(value.column))){
      return value.isFake ? 'seat-margin fake-aisle-seat' : 'seat-margin';
    }
    if(value.isFake){
      return 'fake-seat'
    }
  }
}
