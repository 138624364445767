
<div class="wrapper" [class.dark]="darkMode" *ngIf="hasInwardStops || hasOutwardStops">
  <mat-accordion>
    <mat-expansion-panel id="rail-journey">
      <mat-expansion-panel-header>
        <span class="mat-content">
          <mat-panel-title i18n="@@basketPanelTotalShowDetails">
            Show Details
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </span>
      </mat-expansion-panel-header>
        <ng-container *ngIf="hasOutwardStops">
          <div class="summary">
            <div>
              {{ railOutwardSummary.departureStationName }} <span i18n="@@railBasketPanelDetailsTo">To</span> {{ railOutwardSummary.arrivalStationName }}
            </div>
            <div>
              {{ totalTimeJourney(railOutwardSummary.departureDateTime, railOutwardSummary.arrivalDateTime) }}
            </div>
          </div>
          <div *ngFor="let stop of railOutwardStops; let index = index"  class="stops">
            <div *ngIf="getSeats(index, 'outward') as seats"><span i18n="@@Seat(s)">Seat(s)</span>: {{seats}}</div>
            <ul *ngIf="!stop.callingPoints.length">
              <li>
                <div class="time" *ngIf="!hideCallingPointTime">{{ stop.departDateTime | sabsTimePipe: 'timeString' }}</div>
                <div class="location">{{ stop.depart }}</div>
              </li>
              <li>
                <div class="time" *ngIf="!hideCallingPointTime">{{ stop.arriveDateTime | sabsTimePipe: 'timeString' }}</div>
                <div class="location">{{ stop.arrive }}</div>
              </li>
            </ul>
            <ul *ngIf="stop.callingPoints.length">
              <li *ngFor="let leg of stop.callingPoints; let first=first">
                <div class="time" *ngIf="!hideCallingPointTime">{{ leg.arrivalDateTime && !first ?  (leg.arrivalDateTime | sabsTimePipe: 'timeString') : (leg.departureDateTime | sabsTimePipe: 'timeString')}}</div>
                <div class="location">{{ leg.stationName }}</div>
              </li>
            </ul>
          </div>
        </ng-container>
        <ng-container *ngIf="hasInwardStops">
          <div class="summary">
            <div>
              {{ railInwardSummary.departureStationName }} <span i18n="@@railBasketPanelDetailsTo">To</span> {{ railInwardSummary.arrivalStationName }}
            </div>
            <div>
              {{ totalTimeJourney(railInwardSummary.departureDateTime, railInwardSummary.arrivalDateTime) }}
            </div>
          </div>
          <div *ngFor="let stop of railInwardStops; let index = index">
            <div *ngIf="getSeats(index, 'inward') as seats"><span i18n="@@Seat(s)">Seat(s)</span>: {{seats}}</div>

            <ul *ngIf="!stop.callingPoints.length">
              <li>
                <div class="time" *ngIf="!hideCallingPointTime">{{ stop.departDateTime | sabsTimePipe: 'timeString' }}</div>
                <div class="location">{{ stop.depart }}</div>
              </li>
              <li>
                <div class="time" *ngIf="!hideCallingPointTime">{{ stop.arriveDateTime | sabsTimePipe: 'timeString' }}</div>
                <div class="location">{{ stop.arrive }}</div>
              </li>
            </ul>
            <div></div>
            <ul *ngIf="stop.callingPoints.length">
              <li *ngFor="let leg of stop.callingPoints; let first = first">
                <div class="time" *ngIf="!hideCallingPointTime">{{ leg.arrivalDateTime && !first ?  (leg.arrivalDateTime | sabsTimePipe: 'timeString') : (leg.departureDateTime | sabsTimePipe: 'timeString') }}</div>
                <div class="location">{{ leg.stationName }}</div>
              </li>
            </ul>
          </div>
        </ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</div>

