<div *ngIf="user" class="profile_information--content">
    <div class="profile_information--content profile_information--payment">
        <div class="profile_information--add-new-container">
            <button type="button" (click)="addCard(newCard)" [disabled]="!isEditable"
                    class="small-button profile_information--add-button" i18n="@@ProfileInformation-Add-New-Card" tabindex="0">Add New Card</button>
        </div>
        <table>
            <thead class="profile_information--table-thead">
                <tr>
                    <th i18n="@@ProfileInformation-id">Id</th>
                    <th i18n="@@ProfileInformation-label">Label</th>
                    <th i18n="@@ProfileInformation-status">Status</th>
                    <th i18n="@@ProfileInformation-expires">Expires</th>
                    <th i18n="@@ProfileInformation-Edit">Edit</th>
                    <th i18n="@@ProfileInformation-Delete">Delete</th>
                </tr>
            </thead>
            <tbody class="profile_information--table-tbody">
                <ng-container *ngIf="cards.length > 0; else noData">
                    <tr *ngFor="let card of cards; let i = index">
                        <td>{{card.id}} </td>
                        <td>{{card.label}}</td>
                        <td>{{card.status}}</td>
                        <td >{{card.expires | date:'dd/MM/yyyy'}}</td>
                        <td><button tabindex="0" [disabled]="!isEditable" [class.disable-button]="!isEditable" class="mat-stroked-button" (click)="editCard(card)" i18n="@@ProfileInformation-Edit">Edit</button></td>
                        <td><button tabindex="0" [disabled]="!isEditable" [class.disable-button]="!isEditable" class="mat-stroked-button" (click)="deleteCard(card.id)" i18n="@@ProfileInformation-Delete">Delete</button></td>
                    </tr>
                </ng-container>
                <ng-template #noData>
                <tr>
                    <td colspan="6" i18n="@@ProfileInformation-NoSavedCards">The user has no saved cards for this payment method.</td>
                </tr>
                </ng-template>
            </tbody>
        </table>
        <div [formGroup]="paymentForm" *ngIf="editCardItem">
            <div class="profile_information--input-container">
                <div class="profile_information--input-label">
                    <div class="profile_information--asterisk">*</div>
                    <span i18n="@@EnterLabelforCard">Enter Label for Card</span>:
                </div>
                <div class="profile_information--input-wrapper">
                    <input class="profile_information--input" tabindex="0" formControlName="label" aria-label="Label for Card"/>
                    <div *ngIf="paymentForm.get('label').invalid || paymentForm.get('label').touched">
                        <div *ngIf="paymentForm.get('label').errors?.required" class="profile_information--error-message" i18n="@@ProfileInformation-label-required">Label required</div>
                    </div>
                </div>
            </div>
            <div class="profile_information--input-container">
                <div class="profile_information--input-label">
                    <div class="profile_information--asterisk">*</div>
                    <span i18n="@@ProfileInformation-status">Status</span>:
                </div>
                <div class="profile_information--input-wrapper">
                    <select class="profile_information--input" tabindex="0" aria-label="Status" formControlName="status" disabled>
                        <option *ngFor="let status of ApexxCardStatus| keyvalue" [ngValue]="status.value">{{ status.value }}</option>
                    </select>
                    <fa-icon [icon]="faChevronDown"></fa-icon>
                    <div *ngIf="paymentForm.get('status').invalid || paymentForm.get('status').touched">
                        <div *ngIf="paymentForm.get('status').errors?.required" class="profile_information--error-message" i18n="@@ProfileInformation-status-required">Status required</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="profile_information--add-new-container" *ngIf="editCardItem">
            <button type="button" tabindex="0" (click)="saveCard()" [disabled]="!isEditable"
                    class="small-button" i18n="@@Save">Save</button>
        </div>
    </div>
</div>

