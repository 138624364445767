import { ServiceType } from '@sabstravtech/obtservices/angular';
export interface TravelTypeInfoType {
  ariaLabelId: string;
  i18n: string;
  icon: string;
  searchName: string;
  type: ServiceType;
  typeName: string;
}

export const TravelTypeInfo: TravelTypeInfoType[] = [
  {
    ariaLabelId: 'aria-search-flight',
    i18n: '@@searchFlight',
    icon: 'ico-flight',
    searchName: 'flight',
    type: ServiceType.Flight,
    typeName: 'Flight'
  },
  {
    ariaLabelId: 'aria-search-rail',
    i18n: '@@searchRail',
    icon: 'ico-rail',
    searchName: 'rail',
    type: ServiceType.Rail,
    typeName: 'Rail'
  },
  {
    ariaLabelId: 'aria-search-hotel',
    i18n: '@@searchHotel',
    icon: 'ico-hotel',
    searchName: 'hotel',
    type: ServiceType.Hotel,
    typeName: 'Hotel'
  },
  {
    ariaLabelId: 'aria-search-car',
    i18n: '@@searchCar',
    icon: 'ico-car',
    searchName: 'car',
    type: ServiceType.Car,
    typeName: 'Car'
  },
  // {
  //   ariaLabelId: 'aria-search-airport-express',
  //   i18n: '@@searchAirportExpress',
  //   icon: 'ico-rail',
  //   searchName: 'airportexpress',
  //   type: ServiceType.AIR_EXP,
  //   typeName: 'Airport Express'
  // },
  {
    ariaLabelId: 'aria-search-airport-lounge',
    i18n: '@@searchAirportLounge',
    icon: 'ico-lounge ico-material',
    searchName: 'lounge',
    type: ServiceType.Lounge,
    typeName: 'Airport Lounge'
  },
  {
    ariaLabelId: 'aria-search-airport-parking',
    i18n: '@@searchAirportParking',
    icon: 'ico-car',
    searchName: 'parking',
    type: ServiceType.Parking,
    typeName: 'Airport Parking'
  },
  {
    ariaLabelId: 'aria-search-eurostar',
    i18n: '@@searchEurostar',
    icon: 'custom_icon-eurostar',
    searchName: 'eurostar',
    type: ServiceType.Eurostar,
    typeName: 'Eurostar'
  },
  {
    ariaLabelId: 'aria-search-taxi',
    i18n: '@@searchTaxi',
    icon: 'ico-taxi',
    searchName: 'taxi',
    type: ServiceType.Cab,
    typeName: 'Taxi'
  },
  {
    ariaLabelId: 'aria-search-airport-fast-track',
    i18n: '@@searchAirportFastTrack',
    icon: 'ico-fast-track ico-material',
    searchName: 'fastTrack',
    type: ServiceType.FastTrack,
    typeName: 'Airport Fast Track'
  },
  // {
  //   ariaLabelId: 'aria-search-coach',
  //   i18n: '@@SearchCoach',
  //   icon: 'ico-m-coach',
  //   searchName: 'coach',
  //   type: ServiceType.COACH,
  //   typeName: 'Coach'
  // }
];

export interface Passenager {
  email: string;
  employeeNumber: string;
  name: string;
  pax_id: string;
  railcard: string;
  username: string;
}
