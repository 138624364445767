<div cdkTrapFocus aria-live="assertive">
    <div class="modal-header">
        <h3 class="modal-title" i18n="@@UpdateToFlightSelection" id="modal-header">
            Update to Flight selection
        </h3>
        <button
            type="button"
            class="close"
            aria-label="Close"
            ngbAutoFocus
            (click)="activeModal.close(false)">
            <span aria-hidden="true"><i class="ico-cancel modal_exit_cross"></i></span>
        </button>
    </div>
    <div class="modal-body modal_body_ticket" >
       <strong i18n="@@pleaseNote">Please Note: </strong><span i18n="@@UpdateToFlightSelectionDescription">The same fare type must be selected on both outbound and inbound legs. The outbound fare and total price has now been updated to reflect and inbound selection.</span>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="small-button"
            (click)="activeModal.close(false)"
            i18n="@@buttonCancel">Cancel</button>
        <button type="button" 
            (click)="activeModal.close(true)"
            class="small-button small-button--no"
            i18n="@@buttonOk">Ok</button>
    </div>
</div> 
