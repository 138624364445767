<app-loading-screen
    *ngIf="searchService.search_loading"
    [animated]="animated"
    icon="ico-taxi"
    [loadingMsgOne]="loadingMsg">
    <span title i18n="@@taxiloading_title">Just loading your search results</span>
    <div msgOne >
        <span title i18n="@@taxiloading_from">Taxis from</span>
        {{searchParams.fromLocation.address}} {{searchParams.fromLocation.postcode}}
        <span title i18n="@@taxiloading_to">to</span>
        {{searchParams.toLocation.address}} {{searchParams.toLocation.postcode}}
    </div>
    <span msgTwo i18n="@@taxiloading_msgTwo">Checking for availability</span>
</app-loading-screen>
