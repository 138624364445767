<div *ngIf="!isLoadingFrequent; else loadingBlock" class="traveller-detail-container">
  <div>  
    <fa-icon 
      tabindex="0"
      role="button"
      [attr.aria-label]="'Add to travellers' + traveller.name"
      (click)="addToTraveller(traveller)" 
      [icon]="faUser" 
      class="add-frequent-button">
    </fa-icon>
    {{ traveller.forename }} {{ traveller.surname }} ({{ ageType | titlecase }})
  </div>
  <fa-icon 
    tabindex="0"
    role="button"
    [attr.aria-label]="'Remove frequent traveller' + traveller.name"
    class="fa-trash-icon" 
    [icon]="faTrash" 
    (click)="removeFrequentTravellers(traveller)">
  </fa-icon>
</div>
<ng-template #loadingBlock><i class="button_spinner"></i></ng-template>
