<ul class="fastTrack-details-container">
    <li *ngIf="fastTrack.name">
        <span class="light-fat"
              i18n="@@fastTrackdetails_name">Name:</span>
        <span [innerHTML]="fastTrack.name"></span>
    </li>
    <li *ngIf="fastTrack.description">
        <span class="light-fat"
              i18n="@@fastTrackdetails_description">Description:</span>
        <span [innerHTML]="fastTrack.description"></span>
    </li>
    <li *ngIf="fastTrack.importantInfo">
        <span class="light-fat"
              i18n="@@fastTrackdetails_info">Important Info:</span>
        <span [innerHTML]="fastTrack.importantInfo"></span>
    </li>
    <li *ngIf="fastTrack.extraInfo">
      <span class="light-fat "
            i18n="@@fastTrackdetails_info-extra">Extra Info:</span>
      <span class="fastTrack-info" [innerHTML]="fastTrack.extraInfo"></span>
  </li>
</ul>
