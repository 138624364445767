<div cdkTrapFocus
     aria-live="assertive">
    <div class="modal-header">
        <h3 class="modal-title"
            i18n="
            @@modalSendPdfByEmailTitle"
            id="modal-header">Send Email to:</h3>
        <button
            type="button"
            class="close"
            aria-label="Close"
            ngbAutoFocus
            (click)="activeModal.close()">
            <span aria-hidden="true"><i class="ico-cancel modal_exit_cross"></i></span>
        </button>
    </div>

    <div class="modal-body">
        <form [formGroup]="emailsForm">
            <div formArrayName="emails">
                <div *ngFor="let email of emails.controls; let i = index" >
                    <div [formGroup]="email" class="email-list">
                        <input type="checkbox" (change)="createEmailList()" [checked]="email.get('selected')?.value" formControlName="selected"> {{email.get('email')?.value}}
                    </div>
                </div>
            </div>
        </form>

        <form [formGroup]="addEmailForm">
            <p class="additional-email" i18n="@@AdditionalEmail">Additional Email</p>
            <input class="input-box input-box--border input-box--no-iconed" type="text" formControlName="email" i18n-placeholder placeholder="Enter an additional email to send to">
        </form>
        <button type="button"
            id="button_ok"
            class="small-button add-email-button"
            aria-label="add an email"
            i18n-aria-label
            aria-live="false"
            (click)="addEmail()"
            [disabled]="addEmailForm.invalid"
            (keydown.enter)="addEmail()"
            (keydown.space)="addEmail()"
            i18n="@@modalAdd">
            Add
        </button>
  </div>
  <div class="modal-footer">
    <button type="button"
        class="small-button small-button--close"
        (click)="activeModal.close()"
        (keydown.enter)="activeModal.close()"
        (keydown.space)="activeModal.close()"
        i18n="@@modalCancel">Cancel</button>
    <button type="button"
        id="button_ok"
        class="small-button"
        aria-label="Save and Continue"
        i18n-aria-label
        aria-live="false"
        (click)="submit()"
        [disabled]="!emailsList.length"
        (keydown.enter)="submit()"
        (keydown.space)="submit()"
        i18n="@@modalSend">
        Send
    </button>
  </div>
</div>