import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import {
  Observable,
  Subject,
  Subscription,
  BehaviorSubject,
  merge,
  of,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  filter,
} from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { BaseComponent } from './base-componet';
import { TranslateService } from '@ngx-translate/core';
import {
  EnterpriseBasketService,
  EnterpriseSearchService,
  HttpCallService,
  ModalOpenerService,
  ServiceType,
  UserService,
  LocationCodes
} from '@sabstravtech/obtservices/angular';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { UsStatesPipe } from '../../../startup/pipes/us-states.pipe';
import { LightningUserFavorurite } from '../../classes/user-favourite.enum';
import { DeviceDetector } from '../../services/device-detector.service';
import { CarHireDepot, CarhireEnterpriseSearchInterface, LocationDetails, LocationTypes, ServiceProvider } from '@sabstravtech/obtservices/base';
import { DepotLocations } from '../../interfaces/depot-locations';

@Component({ template: '' })
export class CarLocationsBase extends BaseComponent implements OnInit {
  focus$ = new Subject<string>();
  focus2$ = new Subject<string>();
  pickupFocus$ = new Subject<string>();
  dropoffFocus$ = new Subject<string>();
  click$ = new Subject<string>();
  click2$ = new Subject<string>();
  @ViewChild('instance', { read: NgbTypeahead, static: true })
  instance: NgbTypeahead;
  @ViewChild('instance2', { read: NgbTypeahead })
  instance2: NgbTypeahead;
  isLocationLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  chosenVendorCity: string = '-';

  private carHireParams: CarhireEnterpriseSearchInterface = null;

  origCityPickup = null;
  origPostcodePickup = null;

  showTescoSendEmail: boolean = false;
  copiedPostCode: string;

  vendors = null;
  countries: {}[] = [];
  tempPickupArray: any = [];
  tempDropoffArray: any = [];

  pickupPostcodeControl = new FormControl();
  dropOffPostcodeControl = new FormControl();
  pickupCityControl = new FormControl();
  dropOffCityControl = new FormControl();
  formCtrlSub: Subscription;
  formCtrlSubDrop: Subscription;
  postCodePickupDepotSubscription: Subscription;
  postCodeDropoffDepotSubscription: Subscription;
  pickupLocationIsLoading: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  dropoffLocationIsLoading: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  cityLocations: DepotLocations = new DepotLocations();
  postcodeLocations: DepotLocations = new DepotLocations();
  searchParams: CarhireEnterpriseSearchInterface;
  isEnterpriseCarHire: boolean = false;

  constructor(
    public deviceDetector: DeviceDetector,
    public searchService: EnterpriseSearchService,
    protected userService: UserService,
    protected modalService: ModalOpenerService,
    protected httpCallService: HttpCallService,
    protected basketService: EnterpriseBasketService,
    protected sanitized: DomSanitizer,
    protected usStatesPipe: UsStatesPipe,
    title: Title,
    public translateService: TranslateService
  ) {
    super(title, translateService);
  }

  ngOnInit() {
    this.isEnterpriseCarHire = this.userService.userHasServiceProvider(ServiceType.Car, ServiceProvider.EnterpriseCarHire);

    const tescoCarHire =
      this.userService.getUserFavoriteValue(
        LightningUserFavorurite.sendTescoCarHire
      ) || '';
    this.showTescoSendEmail = tescoCarHire.length > 0;

    this.carHireParams = this.searchService.searches[ServiceType.Car];

    this.vendors = this.searchService.searches[ServiceType.Car].vendors;
    this.searchParams = this.searchService.searches[ServiceType.Car];
  }


  /**
   * @description - get locations for carhire pickup, can only see airports for now
   * @param text$
   * @returns
   */
  pickupLocations = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance.isPopupOpen())
    );

    return merge(debouncedText$, this.focus$, clicksWithClosedPopup$).pipe(
      switchMap(term => this.searchService.getAirportsList(term, this.carHireParams.pickup_country, this.isLocationLoading))
      // ! swap when countries working
      // this.searchService.getAirportsList(term, this.pickupLocationIsLoading,
      // this.searchService.searches[ServiceType.Car].pickup_country))
    );
  };

  /**
   * @description - get locations for carhire pickup, can only see airports for now
   * @param text$
   * @returns
   */
  dropoffLocations = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click2$.pipe(
      filter(() => !this.instance2.isPopupOpen())
    );

    return merge(debouncedText$, this.focus2$, clicksWithClosedPopup$).pipe(
      switchMap(term => this.searchService.getAirportsList(term, this.carHireParams.dropoff_country, this.isLocationLoading))
      // ! swap when countries working
      // this.searchService.getAirportsList(term, this.dropoffLocationIsLoading,
      // this.searchService.searches[ServiceType.Car].dropoff_country))
    );
  };

  updateLocation() {
    if (this.searchParams.locationType === LocationTypes.City) {
      if (this.searchParams.cityDropoff?.latitude && this.searchParams.cityDropoff?.longitude) {
        this.updateCityDropoffDepot(this.searchParams.cityDropoff);
      }
      if (this.searchParams.cityPickup?.latitude && this.searchParams.cityPickup?.longitude) {
        this.updateCityPickupDepot(this.searchParams.cityPickup);
      }
    }
    if (this.searchParams.locationType === LocationTypes.Postcode) {
      if (this.searchParams.postcodeDropoffRaw?.latitude && this.searchParams.postcodeDropoffRaw?.longitude) {
        this.changeDropoffPostcode(this.searchParams.postcodeDropoffRaw);
      }
      if (this.searchParams.postcodePickupRaw?.latitude && this.searchParams.postcodePickupRaw?.longitude) {
        this.changePickupPostcode(this.searchParams.postcodePickupRaw);
      }
    }
  }

  updatePostcodes() {
    this.searchParams.postcodePickupRaw = null;
    this.searchParams.postcodePickup = null;
    this.postcodeLocations.filteredPickupDepots = [];
    this.postcodeLocations.hasPickupResults = false;

    this.searchParams.postcodeDropoffRaw = null;
    this.searchParams.postcodeDropoff = null;
    this.postcodeLocations.filteredDropoffDepots = [];
    this.postcodeLocations.hasDropoffResults = false;
  }

  updateCityDropoffDepot(newCity: LocationDetails): void {
    this.updateDepot(newCity, 'Dropoff', 'city', false);
  }

  updateCityPickupDepot(newCity: LocationDetails): void {
    this.updateDepot(newCity, 'Pickup', 'city', false);
  }

  private updateDepot(locationDetails: LocationDetails, type: 'Dropoff' | 'Pickup', searchType: 'city' | 'postcode', isPostCode: boolean): void {
    // Construct dynamic property names based on the type
    const loadingKey = `${type.toLowerCase()}Loading`;
    const hasResultsKey = `has${type}Results`;
    const filteredDepotsKey = `filtered${type}Depots`;
    const locationType = `${searchType}Locations`;
    const typeKey = `${searchType}${type}`;

    // If either delivery or collection search parameters are set
    if (this.searchParams.delivery || this.searchParams.collection) {
      if (searchType === 'city' && (locationDetails?.name && locationDetails?.countryCode)) {
        this.subscribe(
          this.searchParams.getEntCarhireLocationsCity(locationDetails.countryCode, locationDetails.name, this.searchParams.chosenVendor),
          (data: LocationCodes[]) => {
            const transformedData: CarHireDepot[] = data.map((location: LocationCodes) => ({
              addressLines: location.address || null,
              cityName: location.city || null,
              countryCode: location.country || null,
              extendedLocationCode: location.code || null,
              latitude: null,
              locationCode: location.postcode || null,
              locationType: null,
              longitude: null,
              vendor: location.vendor,
              vendorCode: location.vendor
            }));

            this.getFilteredCityDepotData(transformedData, locationDetails.name, typeKey, filteredDepotsKey, hasResultsKey, loadingKey, locationType, false);
          },
          (error) => {
            console.error('+++ Error retrieving depots: ', error, ' +++');
            this[locationType][loadingKey] = false;
          }
        );
      } else if (searchType === 'postcode' && (locationDetails?.name && locationDetails?.countryCode)) { //test + refactoring 
        this.subscribe(
          this.searchParams.getEntCarhireLocationsPostcode(locationDetails.countryCode, locationDetails.name, this.searchParams.chosenVendor),
          (data: LocationCodes[]) => {
            const transformedData: CarHireDepot[] = data.map((location: LocationCodes) => ({
              addressLines: location.address || null,
              cityName: location.city || null,
              countryCode: location.country || null,
              extendedLocationCode: location.code || null,
              latitude: null,
              locationCode: location.postcode || null,
              locationType: null,
              longitude: null,
              vendor: location.vendor,
              vendorCode: location.vendor
            }));

            this.getFilteredCityDepotData(transformedData, locationDetails.name, typeKey, filteredDepotsKey, hasResultsKey, loadingKey, locationType, true);
          },
          (error) => {
            console.error('+++ Error retrieving depots: ', error, ' +++');
            this[locationType][loadingKey] = false;
          }
        );
      }
    } else if (locationDetails?.latitude && locationDetails?.longitude) {
      // If latitude and longitude of the new locationType are available
      this[locationType][loadingKey] = true;
      this.subscribe(
        this.searchService.getCarHireDepots(locationDetails.latitude, locationDetails.longitude, this.searchParams.chosenDistance),
        (data) => {
          this.getFilteredCityDepotData(data, locationDetails.name, typeKey, filteredDepotsKey, hasResultsKey, loadingKey, locationType, isPostCode);
        },
        (error) => {
          console.error('+++ Error retrieving depots: ', error, ' +++');
          this[locationType][loadingKey] = false;
        }
      );
    } else if (this.searchParams[typeKey]?.name) {
      // If city name is available in search parameters
      this[locationType][hasResultsKey] = true;
      this[locationType][filteredDepotsKey] = [this.searchParams[typeKey]];
    }
  }

  getFilteredCityDepotData(data: CarHireDepot[], name: string, typeKey: string, filteredDepotsKey: string, hasResultsKey: string, loadingKey: string, locationType: string, isPostCode: boolean) {
    if (!this.searchParams[typeKey]) {
      this.searchService.searches[ServiceType.Car][typeKey] = null;
    }
    const filteredData = this.searchParams.filterCarHireDepots(data, name, isPostCode);
    this[locationType][filteredDepotsKey] = this.searchParams.sortCarHireDepots(filteredData);
    this[locationType][hasResultsKey] = filteredData.length > 0;
    this[locationType][loadingKey] = false;
  }

  pickupCities = (text$: Observable<string>): Observable<LocationDetails[]> => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    return debouncedText$
      .pipe(
        switchMap(
          (term: string): Observable<any[]> =>
            term.length <= 2
              ? of([]) : (this.searchService.getCityLocations(term, '', this.isLocationLoading))
        )
      );
  };

  pickupPostcodes = (
    text$: Observable<string>
  ): Observable<unknown> => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      // merge(this.pickupFocus$),
      switchMap((term) => {
        return this.searchService.getPostcodeList(term, this.searchParams.pickup_country);
      })
    );
  };

  dropOffCities = (text$: Observable<string>): Observable<LocationDetails[]> => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    return debouncedText$
      .pipe(
        switchMap(
          (term: string): Observable<any[]> =>
            term.length <= 2
              ? of([]) : (this.searchService.getCityLocations(term, '', this.isLocationLoading))
        )
      );
  };

  changePickupPostcode($event: LocationDetails): void {
    this.updateDepot($event, 'Pickup', 'postcode', true);
  }

  compareDepot(optionOne, optionTwo): boolean {
    if (optionOne && optionTwo) {
      return optionOne.addressLines[0] === optionTwo.addressLines[0] && optionOne.vendorCode === optionTwo.vendorCode && optionOne.cityName === optionTwo.cityName;
    }
  }

  changeDropoffPostcode($event: LocationDetails): void {
    this.updateDepot($event, 'Dropoff', 'postcode', true);
  }

}