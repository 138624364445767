import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pnrImportFormControl'
})
export class PnrImportFormControlPipe implements PipeTransform {

  transform(value: string): string {

    try {

      const split = value.split('_');

      return `${split[2]} ${split[3]}`

    } catch (error) {
      // nada
    }

    return value;
  }

}
