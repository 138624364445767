import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, } from '@ng-bootstrap/ng-bootstrap';
import { EnterpriseBasketService, WithSubscriptionComponent, BasketItemCustomRemark } from '@sabstravtech/obtservices/angular';
import { LightningModalTypes } from '../../../vendor/classes/modal-types.enum';

@Component({
  selector: 'app-custom-remarks-dialog',
  templateUrl: './custom-remarks-dialog.component.html',
  styleUrls: ['./custom-remarks-dialog.component.scss']
})
export class CustomRemarksDialogComponent extends WithSubscriptionComponent implements OnInit {
  

  basketItemId = '';
  text = '';
  chosenRemark: any = null;
  service = 0;
  remarks: any[] = [];
  customRemarks: BasketItemCustomRemark[] = [];
  loadingSavedRemarks: boolean = false;
  loadingRemarks: boolean = false;
  addingRemark: boolean = false;

  constructor(
    public dialogRef: NgbActiveModal,
    private basketService: EnterpriseBasketService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadingRemarks = true;
    this.subscribe(this.basketService.getRemarks(this.service.toString()), remarks => {
      console.log(remarks);
      this.remarks = remarks;
      this.loadingRemarks = false;
    })

    this.loadSavedRemarks();
  }

  addRemark() {
    this.addingRemark = true;
    this.subscribe(this.basketService.addBasketItemRemark(this.basketItemId.toString(), this.chosenRemark, this.text), result => {
      console.log(result);
      this.addingRemark = false;
      this.loadSavedRemarks();
    });
  }

  loadSavedRemarks(): void {
    this.loadingSavedRemarks = true;
    this.subscribe(this.basketService.getBasketItemRemarks(this.basketItemId), remarks => {
      this.customRemarks = remarks;
      console.log(remarks);
      this.loadingSavedRemarks = false;
    });
  }

  close(): void {
    this.dialogRef.close(null);
  }

}
LightningModalTypes.CustomRemarksDialog.component = CustomRemarksDialogComponent;
