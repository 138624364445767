import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, map, catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../../vendor/components/base_components/base-componet';
import {
  EnterpriseSearchService,
  ReferenceRailCardQualifier,
  ServiceType,
} from '@sabstravtech/obtservices/angular';
import {
  LocationDetails,
  SeasonTicketsEnterpriseSearchInterface
} from '@sabstravtech/obtservices/base';
import { DeviceDetector } from '../../../vendor/services/device-detector.service';

@Component({
  selector: 'app-season-tickets-search',
  templateUrl: './season-tickets-search.component.html',
  styleUrls: ['./season-tickets-search.component.scss']
})
export class SeasonTicketsSearchComponent extends BaseComponent implements OnInit {
  ServiceType: typeof ServiceType = ServiceType;
  searchParams: SeasonTicketsEnterpriseSearchInterface;
  typeaHeadLimit = Number.MAX_SAFE_INTEGER;
  isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public selectedTravelCards: (ReferenceRailCardQualifier & { userId?: string })[] = [];
  isLocationLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    public deviceDetector: DeviceDetector,
    public searchService: EnterpriseSearchService,
    title: Title,
    public translateService: TranslateService
  ) {
    super(title, translateService);
  }

  ngOnInit(): void {
    this.searchParams = this.searchService.searches[ServiceType.SeasonTicket];
  }

  formatter = (x: { name: string }) => x.name;
  railLocations = (text$: Observable<string>): Observable<LocationDetails[]> => this.locationTypeahead(text$);
  railLocations2 = (text$: Observable<string>) => this.locationTypeahead(text$);

  private locationTypeahead(text$: Observable<string>): Observable<LocationDetails[]> {
    return text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(
        (term: string): Observable<LocationDetails[]> =>
          term.length <= 2
            ? of([])
            : this.searchService
              .getStationLocations(
                term,
                this.isLoading
              )
              .pipe(
                map((res: LocationDetails[]) => {
                  return res.slice(0, this.typeaHeadLimit);
                }),
                catchError(() => of([]))
              )
      )
    );
  }

  ensureElementIsScrolledTo(event) {
    try {
      const typeAheadList = event.target.nextElementSibling;
      const activeButton = typeAheadList.getElementsByClassName('active')[0];
      if (
        activeButton.offsetTop + activeButton.clientHeight >
        typeAheadList.clientHeight + typeAheadList.scrollTop
      ) {
        typeAheadList.scrollTop =
          activeButton.offsetTop + activeButton.clientHeight - typeAheadList.clientHeight;
      } else if (activeButton.offsetTop < typeAheadList.scrollTop) {
        typeAheadList.scrollTop = activeButton.offsetTop;
      }
    } catch (e) {
      console.log("Couldn't find elements to scroll");
    }
  }

}