import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'flightNumber',
})
export class FlightNumberPipe implements PipeTransform {
  transform(flightnum: string): string {
    const n = flightnum.indexOf('/');
    return flightnum.substring(0, n >= 0 ? n : flightnum.length);
  }
}
