<div class="modal-header">
  <h3 class="modal-title" i18n="@@TicketConditions">Ticket Conditions</h3>
  <button
    type="button"
    class="light-icon-button close"
    aria-label="Close"
    (click)="activeModal.close(false)"
    ngbAutoFocus>
    <i class="ico-cancel AA-standard-grey" aria-hidden="true"></i>
  </button>
</div>
<div class="modal-body" tabindex="0">
  <ul class="modal-list" *ngIf="ticketDetails; else noData">
    <section  *ngFor="let detail of ticketConditionsOrdered | keyValue">
      <li class="modal-list-item" *ngIf="detail.value">
        <span class="rule-title">{{ detail.key | translate | titlecase }}</span>
        <span class="rule-value" [innerHTML]="detail.value"></span>
      </li>
    </section>
    @if(includedExtras?.length){
      <section >
        <li class="modal-list-item">
          <span class="rule-title" i18n="@@trainlineFreeExtras">Free extras</span>
          <span class="rule-value">
          @for(extras of includedExtras; track extras){
            <div>{{extras}}</div>
          }
        </span>
        </li>
      </section>
    }
  </ul>
  <ng-template #noData> No data to display </ng-template>
</div>
<div class="modal-footer">
  <button type="button" title="Ok" class="small-button" (click)="activeModal.close(false)">
    Ok
  </button>
</div>
