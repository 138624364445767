<ul class="pnl-details-container">
    <li *ngIf="lounge.lounge.name">
        <span class="light-fat"
              i18n="@@loungedetails_name">Name:</span>
        <span [innerHTML]="lounge.lounge.name"></span>
    </li>

    <li *ngIf="lounge.lounge.telephone">
        <span class="light-fat"
              i18n="@@loungedetails_telephone">Telephone:</span>
        <span [innerHTML]="lounge.lounge.telephone"></span>
    </li>

    <li *ngIf="lounge.lounge.introduction">
        <span class="light-fat"
              i18n="@@loungedetails_Introduction">Introduction:</span>
        <span [innerHTML]="lounge.lounge.introduction"></span>
    </li>
    <li *ngIf="lounge.lounge.misc.covidCopy">
        <span class="light-fat"
              i18n="@@loungedetails_Infomation">Covid Information:</span>
        <span [innerHTML]="lounge.lounge.misc.covidCopy"></span>
    </li>
    <li *ngIf="lounge.lounge.misc.importantInformation">
        <span class="light-fat"
              i18n="@@loungedetails_ImportantInfo">Important Informaiton:</span>
        <span [innerHTML]="lounge.lounge.misc.importantInformation"></span>
    </li>

    <li *ngIf="lounge.lounge.cancellationInfo">
        <span class="light-fat"
              i18n="@@loungedetails_cancellation">Cancellation Information:</span>
        <span [innerHTML]="lounge.lounge.cancellationInfo"></span>
    </li>
    <li *ngIf="lounge.lounge.amendmentsInfo">
        <span class="light-fat"
              i18n="@@loungedetails_admendments">Amendments Information:</span>
        <span [innerHTML]="lounge.lounge.amendmentsInfo"></span>
    </li>
    <li *ngIf="lounge.lounge.checkInInfo">
        <span class="light-fat"
              i18n="@@loungedetails_checkin">Check-in Information:</span>
        <span [innerHTML]="lounge.lounge.checkInInfo"></span>
    </li>
    <li *ngIf="lounge.lounge.misc.businessfacilities">
        <span class="light-fat"
              i18n="@@loungedetails_bissFac">Business Facilities:</span>
        <span [innerHTML]="lounge.lounge.misc.businessfacilities"></span>
    </li>
    <li *ngIf="lounge.lounge.misc.facilitiesdisabled">
        <span class="light-fat"
              i18n="@@loungedetails_diabledFac">Disabled Facilities:</span>
        <span [innerHTML]="lounge.lounge.misc.facilitiesdisabled"></span>
    </li>
    <li *ngIf="lounge.lounge.openTime">
        <span class="light-fat"
              i18n="@@loungedetails_times">Times:</span>
        <span>
            Opens: <span [innerHTML]="lounge.lounge.openTime"></span> Closes:<span
                  [innerHTML]="lounge.lounge.closeTime"></span>
        </span>
    </li>
    <li *ngIf="lounge.lounge.groupInfo">
        <span class="light-fat"
              i18n="@@loungedetails_groups">Groups:</span>
        <span [innerHTML]="lounge.lounge.groupInfo"></span>
    </li>
    <li *ngIf="lounge.lounge.dressCode">
        <span class="light-fat"
              i18n="@@loungedetails_dresscode">Dress Code:</span>
        <span [innerHTML]="lounge.lounge.dressCode"></span>
    </li>
    <li *ngIf="lounge.lounge.foodAndBeverage">
        <span class="light-fat"
              i18n="@@loungedetails_foodAndBeverage">Food And Beverage:</span>
        <span [innerHTML]="lounge.lounge.foodAndBeverage"></span>
    </li>
    <li *ngIf="lounge.lounge.whatsIncludedFood">
        <span class="light-fat"
              i18n="@@loungedetails_includeFood">Food Included:</span>
        <span [innerHTML]="lounge.lounge.whatsIncludedFood"></span>
    </li>
    <li *ngIf="lounge.lounge.whatsIncludedDrinks">
        <span class="light-fat"
              i18n="@@loungedetails_includedDrink">Drinks Included:</span>
        <span [innerHTML]="lounge.lounge.whatsIncludedDrinks"></span>
    </li>
    <li *ngIf="lounge.lounge.whatsIncludedExtras">
        <span class="light-fat"
              i18n="@@loungedetails_includedExtras">Extras Included:</span>
        <span [innerHTML]="lounge.lounge.whatsIncludedExtras"></span>
    </li>
    <li *ngIf="lounge.lounge.groupInfo">
        <span class="light-fat"
              i18n="@@loungedetails_groupInfo">Group Info:</span>
        <span [innerHTML]="lounge.lounge.groupInfo"></span>
    </li>

</ul>