<div class="modal-header">
    <h3 class="modal-title light-color-primary">Fare Rules</h3>
    <button type="button"
            class="close"
            aria-label="European rail Fare Details Close"
            ngbAutoFocus
            i18n-aria-label
            (click)="activeModal.close(true)">
      <span aria-hidden="true">
        <i class='ico-cancel modal_exit_cross'></i>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <p [innerHtml]="parsedRules">
    </p>
  </div>
  <div class="modal-footer">
    <button type="button"
    class="small-button"
    (click)="activeModal.close(true)"
    i18n="@@modalFlightFareOK">Close</button>
  </div>