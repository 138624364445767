<div>
  <h2 class='airportExpressHeader'>
    {{searchParams.airportExpressStation|airportExpress}}
  </h2>
  <div class="half-container">
    <div class="half-block">
      <label class="header-label"
             id="aedirection"
             i18n="@@aiportExpressDirection"
             for="ae-select-direction">Direction</label>
      <i class='ico ico-rail select-box-icon'></i>
      <select class='select-box'
              [(ngModel)]='searchParams.airportExpressStation'
              (ngModelChange)="onStationChange($event)"
              aria-labelledby="aedirection"
              id="ae-select-direction">
        <option disabled
                [value]="null"
                i18n="@@selectRoute"
                selected>Select a route</option>
        <option *ngFor='let station of getAirportExpressStations()'
                [ngValue]='station.code'>{{station.name}}</option>
      </select>
    </div>
    
  </div>
  <div class="half-container">
    <div class="half-block">
      <div class="flex-row-container clearfix">
        <ngbd-datepicker-i18n>
          <div class="flex-column-container combo-box-left">
            <i class='ico-calendar combo-box-icon cursor_pointer'
            ></i>
            <label class="header-label date-label"
              for="ae-outbound-date"
              id="outbounddat"
              i18n="@@expressSearchOutboundDate">Outbound Date (DD/MM/YYYY)</label>
            <app-datepicker
                [minDate]='searchParams.minOutboundDate'
                [startDate]='searchParams.outbound_date'
                [model]="searchParams.outbound_date"
                [class]="'combo-box'"
                [componentId]="'ae-outbound-date'"
                (newDate)="searchParams.outbound_date = $event"
                >
                </app-datepicker>
          </div>
          <div class="flex-column-container combo-box-right">
            <i class='ico-clock combo-box-icon'></i>
            <label class="header-label"
              for="ae-select-outbound-time"
              id="outboundtime"
              i18n="@@expressSearchOutboundTime">Outbound Time</label>
            <select class='combo-box'
                    [(ngModel)]='searchParams.outbound_time'
                    id="ae-select-outbound-time">
              <option *ngFor="let time of searchParams.timeArray"
                      [ngValue]="time.value">{{time.display}}</option>
            </select>
          </div>
        </ngbd-datepicker-i18n>
      </div>
    </div>
    <div class="half-block rail_return_container" [ngClass]="{
      rail_return_disabled:searchParams.chosenSearchType === RailJourneyType.SingleJourney
      }">
      <div class="flex-row-container clearfix">
        <ngbd-datepicker-i18n>
          <div class="flex-column-container combo-box-left">
            <label class="header-label date-label"
             for="ae-return-date"
             id="returndate"
             i18n="@@expressSearchReturnDate">Return Date (DD/MM/YYYY)</label>
            <i class='ico-calendar combo-box-icon cursor_pointer'
            ></i>
            <app-datepicker
            [minDate]='searchParams.minInboundDate'
            [startDate]='searchParams.return_date'
            [model]="searchParams.return_date"
            [class]="'combo-box'"
            [componentId]="'ae-return-date'"
            [disabled]='searchParams.chosenSearchType === RailJourneyType.SingleJourney'
            (newDate)="searchParams.return_date = $event"
            >
            </app-datepicker>
          </div>
          <div class="flex-column-container combo-box-right">
            <label class="header-label"
             for="ae-select-return-time"
             id="returndate"
             i18n="@@expressSearchReturnTime">Return Time</label>
            <i class='ico-clock combo-box-icon'></i>
            <select class='combo-box'
              [disabled]='searchParams.chosenSearchType === RailJourneyType.SingleJourney'
              [(ngModel)]='searchParams.return_time'
              id="ae-select-return-time"
            >
              <option *ngFor="let time of searchParams.timeArray"
                      [ngValue]="time.value">{{time.display}}</option>
            </select>
          </div>
        </ngbd-datepicker-i18n>
      </div>
    </div>
  </div>
</div>