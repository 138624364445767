<div cdkTrapFocus aria-live="assertive">
    <div class="modal-header">
        <h3 class="modal-title" i18n="@@modalBrandedFareTitle" id="modal-header">
            Flight Fare Details
        </h3>
        <button
            type="button"
            class="close"
            aria-label="Flight Fare Details Close"
            ngbAutoFocus
            (click)="activeModal.close(true)">
            <span aria-hidden="true"><i class="ico-cancel modal_exit_cross"></i></span>
        </button>
    </div>
    <div class="modal-body modal_body_ticket">
        <div class="branded_info_container">
            <div *ngFor="let flight of flights">
                <strong>{{flight.marketingCarrier}}{{flight.marketingFlightNumber}}</strong>
                <ul class="fare_list">
                    <li *ngFor="let item of flight.additional.disclosures">{{ item | titlecase }}</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="modal_button_okay"
            (click)="activeModal.close(true)"
            i18n="@@modalBrandedFareOK">
            Ok
        </button>
    </div>
</div>
