import { Component, OnInit, HostListener, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { DeviceDetector } from '../../../vendor/services/device-detector.service';
// import { EurostarService } from '../../obtservices/vendor/services/eurostar.service';
// import { EurostarShowClasses } from '../../obtservices/vendor/interfaces/eurostar';
import { ModalOpenerService } from '@sabstravtech/obtservices/angular';
import { BaseComponent } from '../../../vendor/components/base_components/base-componet';
import { TranslateService } from '@ngx-translate/core';
import { EnterpriseSearchService, ServiceType } from '@sabstravtech/obtservices/angular';
import {
    FerryEnterpriseSearchInterface,
    FerryTempParams,
    EurostarResults,
    
} from '@sabstravtech/obtservices/base';
import { SortType } from '../../interfaces/sort-type.interface';
import { LightningModalTypes } from '../../../vendor/classes/modal-types.enum';
import { Router } from '@angular/router';

@Component({
    selector: 'app-ferry',
    templateUrl: './ferry.component.html',
    styleUrls: ['./ferry.component.scss']
})
export class FerryComponent extends BaseComponent implements OnInit {
    searchType: ServiceType = ServiceType.Ferry;
    searchResults: any = {
        outbound: [],
        inbound: []
    };
    $margin: Observable<number> = new Observable();
    showClasses: any;
    public searchParams: FerryEnterpriseSearchInterface;
    public search: FerryTempParams;

    constructor(
        public searchService: EnterpriseSearchService,
        // public eurostarService: EurostarService,
        public deviceDetector: DeviceDetector,
        public modalService: ModalOpenerService,
        titleService: Title,
        public translateService: TranslateService,
        public router: Router
    ) {
        super(titleService, translateService);
        this.searchParams = searchService.searches[ServiceType.Ferry];
    }

    ngOnInit(): void {
        this.setTitle(this.translateService.instant('Ferry results - LightUk'));
        this.search = this.searchService.searches[ServiceType.Ferry].originalUserSearch;

        // this might need sorting
        // this.$margin = this.deviceDetector.deviceChange.pipe(map(this.setMargin));
    }

    // setMargin = (device: DeviceType) => (device === DeviceType.MOBILE ? 30 : 100);

    openFareRules(): void {
        this.modalService.open(
            LightningModalTypes.ModalFareRulesComponent,
            {
                windowClass: 'fare-rules-modal',
                centered: true
            },
            {}
        );
    }

    openFareRulesAndFocus(): void {
        this.openFareRules();
        document.getElementById('standardHeader').focus();
    }


   

    skipToResultContent() {
        const focusable = document
            .getElementById('eurostart')
            .querySelectorAll(
                'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
            );
        // @ts-ignore
        focusable[0].focus();
        return false;
    }
    /**
@desc - do another search - based off of the input at the top of the screen and the option entered in the initial search
**/
    reSearch(): void {
        this.searchService.search_objects[ServiceType.Ferry].chosen = true;
        this.searchService.determineHighestSearchPriority();

        this.searchService.startSearches();
        this.search = this.searchService.searches[ServiceType.Ferry].originalUserSearch;
    }
}
