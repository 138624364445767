import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../vendor/components/base_components/base-componet';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { UserService, UserApprovalRedirection, EnterpriseMyBookingsService } from '@sabstravtech/obtservices/angular';
import { FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-profile-information-approval-reassignment',
  templateUrl: './profile-information-approval-reassignment.component.html',
  styleUrls: ['./profile-information-approval-reassignment.component.scss']
})
export class ProfileInformationApprovalReassignmentComponent extends BaseComponent implements OnInit {
  faChevronDown = faChevronDown;
  faCalendar = faCalendar;
  @Input() approver: UserApprovalRedirection;
  @Input() approvalReassignmentForm: FormGroup;
  @Input() isEditable: boolean;
  constructor(
    title: Title,
    translateService: TranslateService,
    public userService: UserService,
    public myBookingsService: EnterpriseMyBookingsService
  ) {
    super(title, translateService);
  }

  ngOnInit(): void {
    if(!this.isEditable) { 
      this.approvalReassignmentForm.disable();
    }
  }

  formatterApprovalEmail = (obj: { email: string }) => obj.email; // used for formatting travellerEmail typeahead output

  searchTravellerEmail = (text$: Observable<string>): Observable<any[]> => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    return debouncedText$.pipe(switchMap((search: string): Observable<string[]> =>
      search.length <= 2 ? of([]) : this.userService.getSearchCompanyApprovers(search)
    ));
  }

  isApproverRequired(){
    return this.approvalReassignmentForm.get('validTo').value;
  }

  isValidToRequired(){
    return this.approvalReassignmentForm.get('approver').value?.id;
  }
}