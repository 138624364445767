import { CarHireDepot } from '@sabstravtech/obtservices/base';

export class DepotLocations {
  chosenDropoffDepot: number = -1;
  chosenPickupDepot: number = -1;
  dropoffLoading = false;
  filteredDropoffDepots: CarHireDepot[] = [];
  filteredPickupDepots: CarHireDepot[] = [];
  hasDropoffResults = false;
  hasPickupResults = false;
  pickupLoading = false;
}
