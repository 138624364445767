import localeIt from '@angular/common/locales/it';
import localeItExtra from '@angular/common/locales/extra/it';
import { Language, LanguageEnum } from '../vendor/interfaces/language-types';

export default class ItialianLanguage extends Language {
  protected locale = localeIt;
  protected localeExtra = localeItExtra;
  protected details = {
    image: 'images/flags/it.png',
    title: 'Itilian',
    enum: LanguageEnum.Itilian,
    aira: 'Itilian Chosen',
    select: 'Select Itilian Language',
    abre: 'it',
  };
}
