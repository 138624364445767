<div style="position:relative">
    <i class="ico-calendar calender-icon" aria-hidden="true"></i>
    <div *ngIf="!editMode && control.value" (click)="editMode = !editMode" class="input-box input-box--border input-box--no-icon encrypted">
        {{control.value?.day | encrypt : 'X' : 0}}/{{control.value?.month | encrypt : 'X' : 0 : 2}}/{{control.value?.year | encrypt : 'X' : 0}}
    </div>
    <div *ngIf="!editMode && !control.value" (click)="editMode = !editMode" class="input-box input-box--border input-box--no-icon encrypted ng-invalid">
        XX/XX/XXXX
    </div>
    <input
    *ngIf="editMode" 
    autocomplete="off"
    class="input-box input-box--border"
    [navigation]="'select'"
    (click)="d.toggle()"
    ngbDatepicker
    #d="ngbDatepicker"
    name='{{name}}'
    id="{{name}}"
    [readonly]="disabled"
    [attr.aria-label]="name | splitCamelCase"
    [formControl]="control"
    [container]="'body'"
    [maxDate]="maxDate"
    [minDate]="minDate"
    >
</div>
