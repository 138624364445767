<div cdkTrapFocus
     aria-live="assertive">
    <div class="modal-header">
        <h3 class="modal-title"
            i18n="@@customRemarkTitle"
            id="modal-header">Custom Remarks</h3>
        <button type="button"
                class="close"
                aria-label="Custom Remark Modal Close"
                ngbAutoFocus
                [disabled]="addingRemark"
                (click)="close()"
                aria-describedby="signout-message">
            <span aria-hidden="true"><i class='ico-cancel modal_exit_cross'></i></span>
        </button>
    </div>
    <div class="modal-body"
        id="remark-message">

        <!-- Loaded Remarks for this basket -->
        <div class="saved-remarks">
            <div *ngIf="loadingSavedRemarks">
                Loading Saved Remarks...
            </div>
            <div class="flex-column-container" *ngIf="!loadingSavedRemarks">
                <div class="flex-column-container" *ngFor="let remark of customRemarks">
                    <div class="remark-text">
                        {{remark.remarkType | remarkFormat | titlecase}}&nbsp;{{remark.text}}
                    </div>
                    <div class="remark-foot">
                        Added on {{remark.createdAt | sabsDate}} by {{remark.agent.email}}
                        <hr/>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div *ngIf="!remarks.length && !loadingRemarks" class="error">
                No remarks found.
            </div>
            <select [disabled]="addingRemark" *ngIf="!loadingRemarks && remarks.length" class="select-box select-box--border select-box--no-icon" [(ngModel)]="chosenRemark">
                <option [value]="remark.value" *ngFor="let remark of remarks">{{remark.label}}</option>
            </select>
            <div class="text-input">
                <textarea [disabled]="addingRemark" class="remark-text-box" style="width:100%" [(ngModel)]="text" maxlength="5000">

                </textarea>
            </div>
        </div>

    </div>
    <div class="modal-footer">
        <button type="button"
                id='button_ok'
                class="small-button"
                aria-label="Add Remark"
                (click)="addRemark()"
                [disabled]="!chosenRemark || !text || addingRemark"
                i18n="@@addremark">
                <span *ngIf="!addingRemark">Add Remark</span>
                <span *ngIf="addingRemark">
                    <fa-icon 
                        class=""
                        [icon]="['fas', 'spinner-third']"
                        [spin]="true">
                    </fa-icon>
                </span>
            </button>
        <button 
            type="button"
            class="small-button small-button--no"
            [disabled]="addingRemark"
            (click)="close()"
            i18n="@@closeDialog">
                Close
        </button>
        
    </div>
</div>
