import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { OBTAirportDetails, TimeWindow } from '@sabstravtech/obtservices/base';
import { DestinationEntry } from '@sabstravtech/obtservices/angular';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-multi-city-search',
  templateUrl: './multi-city-search.component.html',
  styleUrls: ['./multi-city-search.component.scss']
})
export class MultiCitySearchComponent implements OnInit {
  @Input() flightLocations: (
    text$: Observable<string>
  ) => Observable<OBTAirportDetails[]>;
  @Input() flightLocations2: (
    text$: Observable<string>
  ) => Observable<OBTAirportDetails[]>;
  @Input() formatter_rail_air: (x: { destination: string }) => any;
  @Input() flightDestinationArray: DestinationEntry[];
  @Input() minDate: NgbDateStruct;
  @Input() minNextDate: NgbDateStruct;
  @Input() outTimeCriteria: TimeWindow;
  @Input() criteria = [];
  @Output() selectedInput = new EventEmitter<number>();
  maxSectors: number = 6;

  constructor() {}

  ngOnInit() {}

  addFlight(): void {
    if (this.flightDestinationArray.length < this.maxSectors) {
      const firstDate = this.flightDestinationArray[0].date;
      let lastItem = this.flightDestinationArray.length - 1;
      let emptyFlight = {
        orig: null,
        dest: null,
        originAirportFull: null,
        destinationAirportFull: null,
        date: firstDate,
        outTimeCriteria: TimeWindow.Depart,
        outTimeFlexibility: 2
      };
      if (this.flightDestinationArray[lastItem].dest)
        emptyFlight.orig = this.flightDestinationArray[lastItem].dest;
      this.flightDestinationArray.push(emptyFlight);
    }
  }

  removeFlight(index: number) {
    this.flightDestinationArray.splice(index, 1);
    this.selectedInput.emit(0);
  }

  updateTemp(index) {
    this.selectedInput.emit(index);
  }
}
