import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WithSubscriptionComponent, User } from '@sabstravtech/obtservices/angular';
import { DifferentTraveller } from '@sabstravtech/obtservices/base';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';
@Component({
    selector: 'app-different-traveller-per-basket',
    templateUrl: './different-traveller-per-basket.component.html'
})
export class DifferentTravellerPerBasketComponent extends WithSubscriptionComponent {
    //Inputs provided by caller.
    userInBasket: User[] = null;
    travellers: User[] = null;

    // Other fields
    DifferentTraveller = DifferentTraveller;

    constructor(public activeModal: NgbActiveModal) {
        super();
    }
}

LightningModalTypes.DifferentTravellerPerBasketComponent.component = DifferentTravellerPerBasketComponent;
