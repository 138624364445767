import { Component, OnInit } from '@angular/core';
import { WithSubscriptionComponent, EnterpriseSearchService } from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-covidmap',
  templateUrl: './covidmap.component.html',
  styleUrls: ['./covidmap.component.scss']
})
export class CovidmapComponent extends WithSubscriptionComponent implements OnInit {
  url: string = '';

  constructor( private readonly searchService: EnterpriseSearchService ) {
    super();
  }

  ngOnInit(): void {
    this.subscribe(this.searchService.getCovidMicrositeToken(), (token: string) => {
      if (token) {
        this.url = `https://us.ctmsmart.com/ctmglobalriskmap?token=${token}&country=GB`;
      }
    });
  }
}
