import { Component, Input, OnInit } from '@angular/core';
import { resultIcons } from '../../../vendor/enum/result-icons.enum';
import { RuleActionExtension } from '@sabstravtech/obtservices/base';
import { WithSubscriptionComponent } from '@sabstravtech/obtservices/angular';
@Component({
  selector: 'app-warning-tags[ticket]',
  templateUrl: './warning-tags.component.html',
  styleUrls: ['./warning-tags.component.scss']
})
export class WarningTagsComponent extends WithSubscriptionComponent implements OnInit {
  @Input() ticket: RuleActionExtension;
  @Input() displayCheck = false;
  @Input() isUnavailableOnly = false;
  resultItemType = resultIcons;
  messages: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.messages = this.ticket.requiresReasonMessages?.join(', ') || '';
  }
}
