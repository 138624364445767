import { Component, Input } from '@angular/core';
import { ErmFareTypes, FlightItineraryWithExtensions } from '@sabstravtech/obtservices/base';
import { CompanyType } from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-special-fare-icon',
  templateUrl: './special-fare-icon.component.html',
  styleUrls: ['./special-fare-icon.component.scss']
})

export class SpecialFareIconComponent {
  ermCompanyType = CompanyType;
  ermFareTypes = ErmFareTypes;
  fareTitle: string = '';
  @Input() flight: FlightItineraryWithExtensions;

  ngOnInit() {
    const fareInfo = this.flight.outboundFlights[0]?.fareInfo || this.flight.inboundFlights[0]?.fareInfo;
    this.fareTitle = fareInfo?.title || '';
  }

  getSpecialFareCode(): string {
    return this.flight.outboundFlights[0]?.fareInfo.code || this.flight.inboundFlights[0]?.fareInfo.code || '';
  }
}
