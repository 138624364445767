import { Component, OnInit } from '@angular/core';
import { LightningModalTypes } from '../../../vendor/classes/modal-types.enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WithSubscriptionComponent, EnterpriseBasketService, BasketQuote } from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-quote-modal',
  templateUrl: './quote-modal.component.html',
  styleUrls: ['./quote-modal.component.scss']
})
export class QuoteModalComponent extends WithSubscriptionComponent implements OnInit {

  newNote = "";
  savedNotes = [];
  basketId = '';
  basketRef = '';
  saving = false;
  loading = false;
  quote: BasketQuote;
  invokedUserId: string = '';
  ownerId = '';
  owner = null;
  note: string = '';

  constructor(
    private activeModal: NgbActiveModal,
    private basketService: EnterpriseBasketService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loading = true;
    this.subscribe(this.basketService.getBasketQuote(this.basketId, false), quote => {
      console.log(quote);
      this.quote = quote;
      this.loading = false;
    });
  }

  close(): void {
    this.activeModal.close(this.owner);
  }

  submit(): void {
    this.close();
  }

  changeOwnership(): void {
    this.saving = true;
    console.log('+++ Call to change ownership to the invoked user +++');
    this.subscribe(this.basketService.changeBasketOwnership(this.basketId, this.invokedUserId, this.note), result => {
      console.log(result);
      this.ownerId = result.basket.owner.id;
      this.quote.generatedHtml = result.generatedHtml;
      this.owner = result.basket.owner;
      this.saving = false;
    });
  }

  resendEmail(): void {
    this.loading = true;
    this.subscribe(this.basketService.getBasketQuote(this.basketId, true, this.note), quote => {
      this.quote = quote;
      this.loading = false;
    });
  }

}

LightningModalTypes.QuoteModalComponent.component = QuoteModalComponent;
