<div class="flex-row-container">
  <div class="flex-column-container">
    <span class="car_code">
      {{ result.displayRecord.vehicle.class }}
      {{ typeForDisplay }}
    </span>
    <span class="car_code">
      {{ result.identifiers.vehType }}
    </span>
    <span *ngIf="result.identifiers.makeModel" class="car_code">
      {{ result.identifiers.makeModel }}
    </span>
  </div>

  <img
    [src]="carImage"
    class="car_vendor_image"
    alt="car vendor image" />
</div>
<app-preferred-icons
  [tmcPreferred]="result.tmcPreferred"
  [tmcPreferredLogoUrl]="result.tmcPreferredLogoUrl"
  [tmcPreferredMessage]="result.tmcPreferredMessage"
  [clientPreferred]="result.officePreferred || result.companyPreferred"
  [companyPreferredMessage]="result.companyPreferredMessage"
  [officePreferredMessage]="result.officePreferredMessage">
</app-preferred-icons>
<section (mouseleave)="tt3.close()" class="container" *ngIf="showSpecialFares(result)">
  <i
    class="ico-star"
    ngbTooltip="Company Negotiated Rate"
    i18n-ngbTooltip
    triggers="manual"
    #tt3="ngbTooltip"
    (mouseenter)="tt3.open()"
    (focus)="tt3.open()"
    (blur)="tt3.close()"
    tabindex="0"></i>
</section>
<app-image-with-error
  class="car_image"
  [logoUrl]="logoUrl"
  [errorUrl]="errorUrl"
  [alt]="result.displayRecord.vehicle.class"
  [title]="result.displayRecord.vehicle.class">
</app-image-with-error>
<span class="car_message" i18n="@@carsCarMessage">* Image is not representative of the actual vehicle.</span>
<p class="co2-usage">
  <span i18n="@@estimatedCo2Usage">Estimated Co2 Usage:</span>
  {{ co2PerItem }} Kg
</p>
<div class="flex-row-container">
  <div class="car_results_rate">
    <span class="daily_rate">
      {{ result.displayRecord.charges[0].amount }}
      <em>{{ result.displayRecord.charges[0].currencyCode }}</em>
    </span>
    <div *ngIf="preferredCurency" class="car_results_rate">
      <span class="daily_converted_rate">
        {{ convertedPrice }}
        <em>{{ preferredCurency }}</em>
      </span>
    </div>
    <span class="rate_plan" i18n="@@carsTotalPrice"> Total Price </span>
    <app-warning-tags [ticket]="result"></app-warning-tags>
  </div>
  <div class="car_results_actions">
    <button
      *ngIf="!isExchange"
      class="add_car_button"
      (click)="addToCart(result)"
      id="add_car_button_{{index}}"
      i18n="@@carsAddCar"
      [disabled]="result.unavailable && !canOverride">
      Add Car
    </button>
    <button
      *ngIf="isExchange && !isPricingExchange"
      class="add_car_button"
      (click)="exchangeCar(result)"
      id="add_car_button_{{index}}"
      i18n="@@carsSelect"
      [disabled]="result.unavailable && !canOverride">
      Select
    </button>
    <button class="car_details_button" (click)="getCarDetails(index)" i18n="@@carsDetails">Car Details</button>
  </div>
</div>