import { Component, EventEmitter, OnInit } from '@angular/core';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HotelQuoteResult, URLS, HotelDetails } from '@sabstravtech/obtservices/base';
import {
  UserService,
  WithSubscriptionComponent,
  EnterpriseSearchService,
  ServiceType,
  HotelAvalibilityService,
} from '@sabstravtech/obtservices/angular';
import { DistanceUnit, UnitType } from '../../vendor/interfaces/hotel-search-form-configuration';
import { LightningUserFavorurite } from '../../vendor/classes/user-favourite.enum';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-hotel-suggestion-modal',
  templateUrl: './hotel-suggestion-modal.component.html',
  styleUrls: ['./hotel-suggestion-modal.component.scss']
})
export class HotelSuggestionModalComponent extends WithSubscriptionComponent implements OnInit {
  suggestedHotels: HotelQuoteResult[] = [];
  userDistanceUnit: string;
  faLocationDot = faLocationDot;
  imageUrls = {};
  thumbs = {};

  constructor(
    public activeModal: NgbActiveModal,
    private userService: UserService,
    private searchService: EnterpriseSearchService,
    private hotelAvailabilityService: HotelAvalibilityService
  ) {
    super();
  }

  ngOnInit(): void {
    this.userDistanceUnit =
      this.userService.getUserFavoriteObject<{
        distanceUnit: DistanceUnit;
      }>(LightningUserFavorurite.HotelSearchFormConfiguration)?.distanceUnit?.unit ||
      UnitType.Miles;
    const hotelParams = this.searchService.searches[ServiceType.Hotel];
    this.subscribe(hotelParams.results, results => {
      this.suggestedHotels = results.slice(0, 3).map(hotelResult => {
        return this.hotelAvailabilityService.updateHotel(hotelResult);
      }).filter(hotelResult => {
        //Get either rooms still loading or ones rooms available, none unavailable
        return !hotelResult.availableRates || hotelResult.availableRates?.rooms?.length;
      });
      this.loadMoreHotelRatesIfNeeded(3, results);
      
      this.setup();
    });
  }

  close(goToHotels: boolean) {
    this.activeModal.close(goToHotels);
  }

  getPrice(rooms: any) {
    //copied from item-hotels
    if (rooms && rooms?.rooms?.length >= 1) {
      const roomsWithMinPrice = rooms.rooms.filter(room => room.total === rooms.minprice);
      const roomPrice = roomsWithMinPrice.length ? rooms.minprice : rooms.rooms[0].total;
      return roomPrice * this.searchService.searches[ServiceType.Hotel].no_of_rooms;
    } else {
      return null;
    }
  }

  getAddress(HotelDetail: HotelQuoteResult) {
    //copied from item-hotels
    if (HotelDetail?.address?.addressLine2 && HotelDetail?.address?.addressLine3) {
      return `${HotelDetail?.address?.addressLine2}, ${HotelDetail?.address?.addressLine3}, ${HotelDetail?.address?.postOrZipCode}`;
    } else {
      return `${HotelDetail?.address?.addressLine1}, ${HotelDetail?.address?.postOrZipCode}`;
    }
  }

  loadMoreHotelRatesIfNeeded(index, hotelResults: HotelDetails[])  {
    if (this.suggestedHotels.length < 3 && hotelResults?.length && hotelResults[index]) { //Get the next available rate
        const newHotelRate = this.hotelAvailabilityService.updateHotel(hotelResults[index]);
        if (!newHotelRate.availableRates || newHotelRate.availableRates?.rooms?.length) {
          this.suggestedHotels.push(newHotelRate);
        }
        index++;
        this.loadMoreHotelRatesIfNeeded(index, hotelResults);
    }
  }

  setup() {
    this.suggestedHotels.forEach((hotelDetail, index) => {
      if (!this.imageUrls[hotelDetail.id]) {
        const code = hotelDetail.id.toString();
        const thumbUrl =
          URLS.CONFERMA_THUMBNAIL_URL + code.charAt(0) + '/' + code.charAt(1) + '/' + code + '.jpg';
        this.imageUrls[hotelDetail.id] = hotelDetail.availableRates?.imageUrl
          ? hotelDetail.availableRates.imageUrl
          : hotelDetail.availableRates?.additional.hotelImages[0]
          ? hotelDetail.availableRates?.additional.hotelImages[0]
          : thumbUrl;
        this.thumbs[hotelDetail.id] = thumbUrl;
        if (this.imageUrls[hotelDetail.id] === thumbUrl) {
          //Load a better quality picture
          this.subscribe(this.searchService.getConfermaRoomImages(code), images => {
            if (images && images.length) {
              this.imageUrls[hotelDetail.id] = images[0];
            }
          });
        }
      }
    });
  }
}

LightningModalTypes.HotelSuggestionModalComponent.component = HotelSuggestionModalComponent;
