import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'greenerPercentage',
  pure: true, //can be omitted as default value
})
export class GreenerPercentagePipe implements PipeTransform {
  transform(fn: number, ...args: any[]): any {
    return (fn * 100).toFixed(2);
  }
}
