import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  EnterpriseSearchService,
  UserService,
  StorageService,
  WithSubscriptionComponent,
} from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-flight-extras-recalculate',
  templateUrl: './flight-extras-recalculate.component.html',
  styleUrls: ['./flight-extras-recalculate.component.scss']
})
export class FlightExtrasRecalculateComponent extends WithSubscriptionComponent implements OnInit {
  @Input() operatingCarrierName: string = null;
  @Input() loyaltyCode: string = "";
  @Output() loyaltyCodeChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() recalculate: EventEmitter<void> = new EventEmitter<void>();

  recalcActive: boolean = false;
  loyaltyClone: string = "";

  constructor(
    public activeModal: NgbActiveModal,
    public searchService: EnterpriseSearchService,
    public userService: UserService,
    public storageService: StorageService
  ) {
    super();
  }

  ngOnInit() {
    this.loyaltyClone = this.loyaltyCode;
  }

  updateLoyaltyCode(event: any) {
    this.loyaltyClone = event.target.value;
    this.recalcActive = this.loyaltyCheck()
  }

  loyaltyCheck() {
    if(this.loyaltyClone !== this.loyaltyCode) {
      return true;
    }
    return false;
  }



  recalcuateSeats() {
    this.loyaltyCodeChanged.emit(this.loyaltyClone);
    this.recalculate.emit();
    this.recalcActive = false;
  }
}


