<ng-container  *ngFor='let flight of flightDestinationArray; let i = index; let last = last'>
  <app-multi-city-destination-input
  [flight]="flight"
  [flightIndex]="i"
  [flightLocations]="flightLocations"
  [flightLocations2]="flightLocations2"
  [formatter_rail_air]="formatter_rail_air"
  [minDate]="i===0 ? minDate : minNextDate"
  class="two-thirds-container flight-search__destination-container search-form-row-holder"
  (selectedInput)="updateTemp($event);"
  (flightRemove)="removeFlight($event)"
  [outTimeCriteria]="outTimeCriteria"
  [criteria]="criteria"
  [isLast]="i === flightDestinationArray.length - 1" 
  ></app-multi-city-destination-input>
</ng-container>
<div class="flex-row-container add-fligth-wrapper">
  <button class="small-button"
          [disabled]='flightDestinationArray.length >= maxSectors'
          (click)='addFlight()'>Add another flight</button>
</div>