<app-results-header [blank]="true" [page]="'search'"></app-results-header>

<div class="profile_information--container" id="main" *ngIf="user">
  <div class="profile_information--tabs">
      <div
          *ngIf="userProfileConfig.profile !== profileConfigOption.Hidden"
          class="profile_information--tab"
          [ngClass]="{ 'profile_information--tab--selected': tabSelected === 0 }"
          (click)="tabSelected = 0">
          <fa-icon [icon]="faUser"></fa-icon>
          <span i18n="@@profile-tab">Profile</span>
      </div>
      <div
          *ngIf="userProfileConfig.address !== profileConfigOption.Hidden"
          class="profile_information--tab"
          [ngClass]="{ 'profile_information--tab--selected': tabSelected === 1 }"
          (click)="tabSelected = 1">
          <fa-icon [icon]="faMapMarkerAlt"></fa-icon>
          <span i18n="@@address-tab">Address</span>
      </div>
      <div
          *ngIf="userProfileConfig.miDefault !== profileConfigOption.Hidden"
          class="profile_information--tab"
          [ngClass]="{ 'profile_information--tab--selected': tabSelected === 2 }"
          (click)="tabSelected = 2">
          <fa-icon [icon]="faListAlt"></fa-icon>
          <span i18n="@@mi-default-tab">MI Default</span>
      </div>
      <div
          *ngIf="userProfileConfig.documents !== profileConfigOption.Hidden"
          class="profile_information--tab"
          [ngClass]="{ 'profile_information--tab--selected': tabSelected === 3 }"
          (click)="tabSelected = 3">
          <span i18n="@@docs-tab">Docs</span>
      </div>
      <div
          *ngIf="userProfileConfig.loyalty !== profileConfigOption.Hidden"
          class="profile_information--tab"
          [ngClass]="{ 'profile_information--tab--selected': tabSelected === 4 }"
          (click)="tabSelected = 4">
          <span i18n="@@loyalty-tab">Loyalty</span>
      </div>
      <div
          *ngIf="userProfileConfig.travelPreferences !== profileConfigOption.Hidden"
          class="profile_information--tab"
          [ngClass]="{ 'profile_information--tab--selected': tabSelected === 5 }"
          (click)="tabSelected = 5">
          <span i18n="@@travel-preferences-tab">Travel Preferences</span>
      </div>
      <div *ngIf="user.approver && userProfileConfig.approvers !== profileConfigOption.Hidden"
        class="profile_information--tab"
        [ngClass]="{ 'profile_information--tab--selected': tabSelected === 7 }"
        (click)="tabSelected = 7">
        <span i18n="@@approval-tab">Approval</span>
      </div>
      <div *ngIf="user.approver && userProfileConfig.approvalReassignment !== profileConfigOption.Hidden"
          class="profile_information--tab"
          [ngClass]="{ 'profile_information--tab--selected': tabSelected === 6 }"
          (click)="tabSelected = 6">
          <span i18n="@@approval-reassignment-tab">Approval Reassignment</span>
      </div>
      <div *ngIf="userProfileConfig.payment !== profileConfigOption.Hidden"
        class="profile_information--tab"
        [ngClass]="{ 'profile_information--tab--selected': tabSelected === 8 }"
        (click)="tabSelected = 8">
        <span i18n="@@payment-tab">Payment</span>
    </div>
  </div>

  <app-profile-information-profile
      *ngIf="tabSelected === 0"
      [user]="user"
      [form]="profileForm"
      [isEditable]="userProfileConfig.profile === profileConfigOption.Editable">
  </app-profile-information-profile>
  <app-profile-information-address
      class="profile_information--tab-content"
      *ngIf="tabSelected === 1"
      [user]="user"
      [addressForm]="addressForm"
      [isEditable]="userProfileConfig.address === profileConfigOption.Editable">
  </app-profile-information-address>
  <app-profile-information-mi-default
      *ngIf="tabSelected === 2"
      [miDefaultForm]="miDefaultForm"
      [userMIs]="userMIs"
      [isEditable]="userProfileConfig.miDefault === profileConfigOption.Editable">
  </app-profile-information-mi-default>
  <app-profile-information-docs
      class="profile_information--tab-content"
      *ngIf="tabSelected === 3"
      [user]="user"
      [docsForm]="docsForm"
      [isEditable]="userProfileConfig.documents === profileConfigOption.Editable">
  </app-profile-information-docs>
  <app-profile-information-loyalty
      class="profile_information--tab-content"
      *ngIf="tabSelected === 4"
      [user]="user"
      [loyaltiesForm]="loyaltiesForm"
      [isEditable]="userProfileConfig.loyalty === profileConfigOption.Editable">
  </app-profile-information-loyalty>
  <app-profile-information-travel-preferences
      *ngIf="tabSelected === 5"
      [user]="user"
      [travelPreferencesForm]="travelPreferencesForm"
      [isEditable]="userProfileConfig.travelPreferences === profileConfigOption.Editable">
  </app-profile-information-travel-preferences>
  <app-profile-information-approval-reassignment
      *ngIf="tabSelected === 6"
      [approver]="approver"
      [approvalReassignmentForm]="approvalReassignmentForm"
      [isEditable]="userProfileConfig.approvalReassignment === profileConfigOption.Editable">
  </app-profile-information-approval-reassignment>
  <app-profile-information-approval
    *ngIf="tabSelected === 7"
    [user]="user"
    [approvalForm]="approvalForm"
    [isEditable]="userProfileConfig.approvers === profileConfigOption.Editable">
  </app-profile-information-approval>
  <app-profile-information-payment
    *ngIf="tabSelected === 8"
    [user]="user"
    [isEditable]="userProfileConfig.payment === profileConfigOption.Editable">
  </app-profile-information-payment>

  <div class="footer">
      <button
        [disabled] = "!approvalReassignmentForm.get('approver').value?.id && !approvalReassignmentForm.get('validTo').value"
        *ngIf="tabSelected === 6"
        class="small-button"
        (click)="clearApprovalReassignment()"
        i18n="@@Clear">Clear</button>
      <button type="button" *ngIf="tabSelected !== 8 && tabSelected !== 2"
              class="small-button"
              [disabled]="checkFormValidation()"
              (click)="editUser()"
              i18n="@@Save">Save</button>
  </div>
</div>
