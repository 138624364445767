import { LanguageEnum, Language } from '../vendor/interfaces/language-types';
import localeNo from '@angular/common/locales/nn';
import localeNoExtra from '@angular/common/locales/extra/nn';

export default class NorwegianLanguage extends Language {
  protected locale = localeNo;
  protected localeExtra = localeNoExtra;
  protected details = {
    image: 'images/flags/nn.png',
    title: 'Norwegian',
    enum: LanguageEnum.Norwegian,
    aira: 'Norwegian language chosen',
    select: 'Select Norweigan Language',
    abre: 'nn',
  };
}
