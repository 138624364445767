<mat-expansion-panel class="rail_item" [ngClass]="{ basketpanel: basketPanel }">
  <mat-expansion-panel-header [ngClass]="{ basketpanel: basketPanel }">
    <mat-panel-title>
      <i class="ico-ticket" aria-hidden="true"></i> &nbsp; <span i18n="@@railcardsApplied">Applied Discount/Card</span>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div *ngFor="let card of railcards">
    <div *ngIf="card.type === DiscountType.RailCard">
      <strong>{{ card.count }} x {{ card.description }} </strong>
    </div>
    <div *ngIf="card.type === DiscountType.DiscountCard">
      <strong>{{ card.description }} </strong>
    </div>
    <div *ngIf="card.type === DiscountType.Groupsave">
      <strong>GroupSave Discount</strong>
    </div>
  </div>
</mat-expansion-panel>
