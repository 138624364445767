import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Helpers } from '../../../../vendor/classes/helpers';
import { LightningUserFavorurite } from '../../../../vendor/classes/user-favourite.enum';

import {
  EnterpriseSearchService,
  ServiceType,
  UserService,
  HelperRoutines,
  EnterpriseBasketService
} from '@sabstravtech/obtservices/angular';
import {
  EurostarEnterpriseSearchInterface,
  EurostarItem,
  EurostarFare,
  EurostarSearchType,
  EurostarItineraryWithExtensions,
  ResultType,
  EuroClass
} from '@sabstravtech/obtservices/base';
import moment from 'moment';

@Component({
  selector: 'app-eurostar-list',
  templateUrl: './eurostar-list.component.html',
  styleUrls: ['./eurostar-list.component.scss']
})
export class EurostarListComponent implements OnInit {
  @Input() results: any[];
  @Input() from: { gateway: string; destination: string; };
  @Input() to: { gateway: string; destination: string; };
  @Input() date: any;
  /* Type of results passed to component */
  @Input() resType: ResultType;
  /* logo used to display */
  readonly eurostarLogo = 'https://images.sabscorp.com/images/TourOp/svg/9F.svg';
  @Input() showClasses: boolean;
  EuroClass: typeof EuroClass = EuroClass;
  preferredLogoUrl: string;
  public searchParams: EurostarEnterpriseSearchInterface;
  fromLocationString: string;
  toLocationString: string;
  // resultItemType = resultIcons;
  canOverride: boolean = false;
  updatedFares: EurostarFare[] = [];
  loadingFare: EurostarFare = null;
  ResultType: typeof ResultType = ResultType;
  constructor(
    public searchService: EnterpriseSearchService,
    // public basketService: BasketService,
    // public eurostarService: EurostarService,
    public modalService: NgbModal,
    public userService: UserService,
    private helpers: HelperRoutines,
    private basketService: EnterpriseBasketService
  ) { }
  ngOnInit(): void {
    this.canOverride = this.userService.canOverride();
    const preferredLogo = this.userService.getUserFavoriteValue(
      LightningUserFavorurite.preferredLogoImagePath
    );
    this.searchParams = this.searchService.searches[ServiceType.Eurostar];

    if (preferredLogo) {
      this.preferredLogoUrl =
        'https://images.sabscorp.com/images/Corporate/CompanyLogos/' + preferredLogo;
    } else {
      this.preferredLogoUrl = this.eurostarLogo;
    }

    if (this.resType === ResultType.Inbound) {
      this.searchParams.results.subscribe(() => {
        if (!this.searchParams.selectedFareClass && !!this.updatedFares.length) {
          //Outbound fare has been deselected so reset the prices for any updated fares, and clear the updated fares array
          this.updatedFares.forEach(updatedFare => {
            this.results.forEach(result => {
              if (result.standardFare.journeyHash === updatedFare.journeyHash) {
                result.standardFare.price = updatedFare.price;
              } else if (result.standardPremierFare.journeyHash === updatedFare.journeyHash) {
                result.standardPremierFare.price = updatedFare.price;
              }
            });
          });
          this.updatedFares = [];
        }
      });
    }
  }

  /**
   * Return date in human readable format to display
   *
   * @returns
   * @memberof EurostarListComponent
   */
  getDate(): string {
    // this.date = moment(this.date);
    // return this.date.format('dd, D MMM YYYY');

    // return Helpers.ngDateToMoment(this.date) ? Helpers.ngDateToMoment(this.date).format(Helpers.READ_DATE_FORMAT) : this.date.format(Helpers.READ_DATE_FORMAT);
    return moment(this.date).format(Helpers.READ_DATE_FORMAT);
  }

  /**
   * Select fare if all selected adds fares to basket
   * also filter fares to display only available for match
   * @param item
   * @param fareClass
   */
  async toggleFare(item: EurostarItem, fare: EurostarFare): Promise<void> {
    const { adults, children, selectedEurostarFare, chosenSearchType } = this.searchParams;
    if (
      this.resType === ResultType.Inbound &&
      this.searchParams.selectedFareClass !== fare.class &&
      !this.newFareHasLoaded(fare) &&
      selectedEurostarFare
    ) {
      //inbound class not the same as outbound class so we need to reload
      const inboundFlights = this.searchParams.rawResults.journeys.filter(
        (journey: EurostarItineraryWithExtensions) => journey.journeyHash === fare.journeyHash
      )[0]?.inboundFlights;
      const outboundFlights = this.searchParams.rawResults.journeys.filter(
        (journey: EurostarItineraryWithExtensions) =>
          journey.journeyHash === selectedEurostarFare.journeyHash
      )[0]?.outboundFlights;
      this.loadingFare = fare;
      this.searchService
        .getFlightAtNewClass(
          this.searchParams.getSearchQuery(),
          { 
            outboundFlights, 
            inboundFlights, 
            source: this.searchParams.rawResults.journeys[0].source 
          },
          adults,
          children,
          selectedEurostarFare.fare[0].bookingCode,
          fare.fare[0].bookingCode
        )
        .subscribe(newResult => {
          this.updatedFares.push(this.helpers.clone(fare));
          if (newResult[0]) {
            fare.price = newResult[0].total.price;
          }
          this.loadingFare = null;
        });
    } else if (this.resType === ResultType.Outbound) {
      const direction = this.resType === ResultType.Outbound ? 1 : 0;
      console.log(item);
      this.searchParams.selectGroupedEurostar(item, fare, direction);
      if (this.searchParams.chosenSearchType === EurostarSearchType.oneWay) {
        //Only needed for singles as it toggles fine on returns
        this.basketService.toggleMenu();
      }
    } else {
      const direction = 0;
      if (this.searchParams.chosenSearchType === EurostarSearchType.oneWay || direction === 0) {
        if (this.searchService.searches[ServiceType.Flight]?.eurostarFromFlightSearchPerformed) {
          this.searchService.search_objects[ServiceType.Flight].chosen = false;
        }
      }
      console.log(item);
      this.searchParams.selectGroupedEurostar(item, fare, direction);
      if (chosenSearchType === EurostarSearchType.oneWay) {
        //Only needed for singles as it toggles fine on returns
        this.basketService.toggleMenu();
      }

      if (this.searchService.searches[ServiceType.Flight]?.eurostarFromFlightSearchPerformed) {
        this.searchService.search_objects[ServiceType.Flight].chosen = false;
      }
    }
  }

  fareIsSelected(fare: EurostarFare): boolean {
    const selectedFare = this.searchParams.selectedEurostarFare;
    if (selectedFare && selectedFare.fare) {
      return !!selectedFare.fare.find(item => fare?.fare?.map(item2 => item2.id).includes(item.id));
    }
    return false;
  }

  newFareHasLoaded(fare: EurostarFare): boolean {
    return (
      this.updatedFares.filter(updatedFare => updatedFare.journeyHash === fare.journeyHash).length >
      0
    );
  }

  getPreferredTitle(euroDetail): string {
    //TODO OLD CODE
    return (
      (euroDetail.tmcPreferredMessage ? euroDetail.tmcPreferredMessage : '') +
      ' ' +
      (euroDetail.companyPreferredMessage ? euroDetail.companyPreferredMessage : '') +
      ' ' +
      (euroDetail.officePreferredMessage ? euroDetail.officePreferredMessage : '')
    );
  }
}

