<div cdkTrapFocus class="flight-tabs">
  <div class="modal-header">
    <h3 class="modal-title">
      <span i18n="@@gmt-ShowDetails">Show details</span>
    </h3>
    <button
      type="button"
      class="close"
      aria-label="Close"
      ngbAutoFocus
      (click)="close()">
      <span aria-hidden="true"><i class="ico-cancel modal_exit_cross"></i></span>
  </button>
  </div>
  <div class="modal-body">
    <mat-tab-group dynamicHeight [(selectedIndex)]="index">
      <mat-tab label="Fare details" i18n-label>
        <app-fares-table
          (addToBasketEvent)="addToBasket($event)"
          [fares]="flight.flights"
          tabindex="0"
          [searchParams]="searchParams"
          [isTravelfusion]="isTravelfusion"
          [showFaresBlock]="true">
        </app-fares-table>
      </mat-tab>
      <mat-tab label="Show details" i18n-label>
        <app-flight-legs
        [legs]="flight.flights[0].outboundFlights"
        [flight]="flight.flights[0]">
        </app-flight-legs>
        <div *ngIf="flight.flights[0].inboundFlights.length">
          <app-flight-legs
            [legs]="flight.flights[0].inboundFlights"
            [flight]="flight.flights[0]"
            [isReturn]="true">
          </app-flight-legs>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="modal-footer">
    <button type="button"
      id="button_ok"
      class="small-button small-button--no flex-row-container--center"
      (click)="close()"
      i18n="@@closeDialog">
      Close
    </button>
  </div>
</div>