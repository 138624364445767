import { Injectable } from '@angular/core';
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment'

@Injectable()
export class CustomNgbDateParserFormatter extends NgbDateParserFormatter {
  constructor() {
    super();
  }

  parse(value: string): NgbDateStruct {

    // ! - first two in format ddmmyy, ddmmyyyy - later dd/mm/yy dd/mm/yyyy
    if (((value.length === 8 || value.length === 6 )&& !value.includes('/') ) || ((value.length === 10 || value.length === 8 ) && value.includes('/'))) {
      let mDate = moment(value, 'DD/MM/YYYY');
      if (!mDate.isValid()) {
        mDate = moment(value, 'DD/MM/YY');
        if (!mDate.isValid()) {
          mDate = moment(value);
        }
      }
      if (!mDate || !mDate.isValid()) {
        return null;
      }

      return {
        year: mDate.year(),
        month: mDate.month() + 1,
        day: mDate.date(),
      };
    }
  }

  format(date: NgbDateStruct): string {
    if (
      !date ||
      !Number.isInteger(date.day) ||
      !Number.isInteger(date.day) ||
      !Number.isInteger(date.day)
    ) {
      return null;
    }
    return moment(
      `${date.year}/${date.month}/${date.day}`,
      'YYYY/MM/DD'
    ).format('DD/MM/YYYY');
  }
}
