<ng-template #rt let-r="result" let-t="term">
  <span>
    {{ r.name }} <span *ngIf="r.countryCode">({{ r.countryCode }})</span></span
  >
</ng-template>
<ng-template #rtp let-r="result" let-t="term">
  <span> {{ r.name }}</span>
</ng-template>


  <div id="apartmentSearchForm1" class="apartment-search-form">
    <div class="inputs-container">
      <div i18n="@@apartmentSearchTravellingOfflineMessage">
        Please fill out this form as the serviced apartment you wish to book is not available online. When you submit the request a member of our travel team will be in touch with a quote. Please call our travel team if your request is for travel within 48 hours.
      </div>
      <div class="half-container">
        <div class="half-block">
          <i class="ico-pin combo-box-icon"></i>
          <div class="flex-row-container">
            <app-search-country
              [(country)]="searchParams.country"
              [countries]="searchService.countries"
              title="Country"
              i18n-title>
            </app-search-country>
          </div>
        </div>
        <div class="half-block">
          <div class="flex-row-container">
            <div class="town-block">
              <label
                id="apartment-town"
                class="header-label"
                for="travelFrom2"
                i18n="@@apartmentTown"
                >Location:</label
              >
              <i class="ico-marker combo-box-icon" aria-hidden="true"></i>
              <input
                #tt
                autocomplete="off"
                type="text"
                id="travelFrom2"
                i18n-placeholder
                placeholder="Town/City"
                onClick="this.select()"
                class="input-box"
                name="town"
                [(ngModel)]="searchParams.town"
                aria-labelledby="apartment-town"
                tabindex="0"
                [ngbTypeahead]="searchLocations"
                [resultTemplate]="rt"
                [inputFormatter]="formatter"
                (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
                (keyup.arrowup)="ensureElementIsScrolledTo($event)" />
            </div>
            <div class="postcode-block">
              <label
                id="apartment-postcode"
                class="header-label"
                for="travelFrom3"
                i18n="@@apartmentPostcode"
              >
                Postcode:
              </label>
              <i class="ico-marker combo-box-icon" aria-hidden="true"></i>
              <input
                #tt
                autocomplete="off"
                type="text"
                id="travelFrom3"
                i18n-placeholder
                placeholder="Postcode/Zipcode"
                onClick="this.select()"
                class="input-box"
                name="postcode"
                [(ngModel)]="searchParams.postcode"
                aria-labelledby="apartment-postcode"
                tabindex="0"
                (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
                (keyup.arrowup)="ensureElementIsScrolledTo($event)" />
            </div>
          </div>
          
        </div>
      </div>
      <div class="half-container flex-start">
        <div class="half-block">
          <div class="half-container">
            <div class="half-block">
                <label
                  class="header-label"
                  for="dp1"
                  id="outbounddateandtime"
                  i18n="@@apartmentSearchOutboundDateAndTime"
                  >
                  Check In
                </label>
              <div class="flex-row-container">
                <ngbd-datepicker-i18n>
                  <i class="ico-calendar combo-box-icon cursor_pointer" aria-hidden="true"></i>
                  <app-datepicker
                    [minDate]="searchParams.minOutboundDate"
                    [startDate]="searchParams.departDateTime"
                    [(model)]="searchParams.outboundDate"
                    [class]="'combo-box'"
                    [id]="'apartment-outbound-date'"
                    (newDate)="searchParams.outboundDate = $event">
                  </app-datepicker>
                </ngbd-datepicker-i18n>
              </div>
            </div>
            <div class="half-block">
              <div class="flex-row-container flex-row-container--between flex-row-container--end">
                <label
                  class="header-label"
                  for="dp2"
                  id="returndateandtime"
                  i18n="@@apratmentSearchReturnDateAndTime"
                  >Check Out</label
                >
              </div>
              <div class="flex-row-container">
                <ngbd-datepicker-i18n>
                  <i class="ico-calendar combo-box-icon cursor_pointer" aria-hidden="true"></i>
                  <app-datepicker
                    [minDate]="searchParams.minInboundDate"
                    [startDate]="searchParams.returnDateTime"
                    [(model)]="searchParams.inboundDate"
                    [class]="'combo-box'"
                    [id]="'apartment-return-date'"
                    (newDate)="searchParams.inboundDate = $event">
                  </app-datepicker>
                </ngbd-datepicker-i18n>
              </div>
            </div>
          </div>
            
        </div>

        <div class="half-block apartment_return_container">
          <div class="thirds-container">
            <div class="third-block">
              <label id="within" class="header-label">Within Distance</label>
              <select
                  class="distance-select"
                  aria-labelledby="within"
                  id="hotel-within-selec"
                  [(ngModel)]="searchParams.distance"
                  class="select-box select-box--no-icon">
                  <option [ngValue]="value" *ngFor="let value of searchParams.distance_list">
                    {{ value | number: '1.0-0' }}
                    <ng-container>
                      <span *ngIf="value === 1" i18n="@@hotelSearchMile">mile</span>
                      <span *ngIf="value > 1" i18n="@@hotelSearchMiles">miles</span>
                    </ng-container>
                  </option>
                </select>
            </div>
            <div class="third-block">
              <label id="rooms" class="header-label">No of Rooms</label>
              <i class="ico ico-new-user input-box-icon"></i>
              <input
                [(ngModel)]="searchParams.numberOfRooms"
                class="input-box"
                type="number"
                min="1"
                max="8"
                step="1"
                aria-labelledby="rooms"
              />
            </div>
            <div class="third-block">
              <label id="beds" class="header-label">No of Beds</label>
              <i class="ico ico-new-user input-box-icon"></i>
              <input
              aria-labelledby="beds"
                [(ngModel)]="searchParams.numberOfBedsPerApartment"
                class="input-box"
                type="number"
                min="1"
                max="20"
                step="1"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- !OPTIONS -->
      <br/>
      <div class="flex-column-container">
        <div class="additional_info_container flex-column-mobile">
          <div class="half-container offline_info_boxes">
            <div class="half-block">
              <label class="container_label">
                <label class="header-label" i18n="@@apartmentSearchAdditionalInformation">
                  Options
                </label>
              </label>
              <div class="traveller_info quarter-block">
                <div class="quarter-block offline_info_boxes">
                  <mat-checkbox
                    aria-label="Accessibility"
                    [(ngModel)]="searchParams.accesibility"
                    class="light-primary"
                    color="primary"
                    id="apartment_accesability"
                    i18n="@@apartmentAccesability">
                    Accessibility* (If required enter requirements within additional information)
                  </mat-checkbox>
                </div>
              </div>
              <div class="traveller_info quarter-block">
                <div class="quarter-block">
                  <mat-checkbox
                    aria-label="Pets"
                    [(ngModel)]="searchParams.pets"
                    class="light-primary"
                    color="primary"
                    id="apartment_pets"
                    i18n="@@apartmentPets">
                    Pets* (If required enter requirements within additional information)
                  </mat-checkbox>
                </div>
              </div>
              <div class="traveller_info quarter-block">
                <div class="quarter-block">
                  <mat-checkbox
                    aria-label="Car Parking"
                    [(ngModel)]="searchParams.parking"
                    class="light-primary"
                    color="primary"
                    id="apartment_parking"
                    i18n="@@apartmentParking">
                    Car Parking
                  </mat-checkbox>
                </div>
              </div>
            </div>
            <div class="half-block addition_instructions_container flex-column-container">
              <label class="header-label" id="apartment-service-class" i18n="@@apartmentSearchInstructions" for="apartment_class"
                >Additional Information</label
              >
              <textarea aria-labelledby="apartment-service-class" maxlength="2000" rows="10" [(ngModel)]="searchParams.additionalInformation" class="addition_instructions">
                  Notes
              </textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <app-apartment-loading-screen></app-apartment-loading-screen>
  </div>
  
  