<div cdkTrapFocus aria-live="assertive">
  <div class="modal-header">
    <h3 class="modal-title" i18n="@@modalRailJourneyDetailsTitle" id="modal-header">
      Train Journey Details
    </h3>
    <button
      type="button"
      class="close"
      aria-label="Train Journey Details Close"
      ngbAutoFocus
      (click)="activeModal.close(true)">
      <span aria-hidden="true"><i class="ico-cancel modal_exit_cross"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <app-rail-journey-segments [segments]="segments"></app-rail-journey-segments>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="modal_button_okay"
      (click)="activeModal.close(true)"
      i18n="@@modalRailJourneyDetailsOK">
      Ok
    </button>
  </div>
</div>
