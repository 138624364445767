<div class="modal-header">
    <h3 class="modal-title"
        i18n="@@quoteHeader"
        id="quote-modal-header">
        Quote Viewer
    </h3>
    <button type="button"
            class="close"
            i18m-aria-label
            aria-label="Close Quote"
            ngbAutoFocus
            [disabled]="saving"
            (click)="close()"
            (keydown.enter)="close()"
            (keydown.space)="close()"
            aria-describedby="">
        <span aria-hidden="true"><i class="ico-cancel modal_exit_cross"></i></span>
    </button>
</div>
<div class="modal-body"
     id="quote-details">
     <div *ngIf="loading" class="relative-container loading-container">
        <span ><i class='button_spinner input-loading-spinner-left'></i> Loading quote...</span> 
     </div>
     <div *ngIf="saving" class="relative-container loading-container">
        <span ><i class='button_spinner input-loading-spinner-left'></i> Changing Owership...</span> 
     </div>
    <div *ngIf="!loading && !saving" [innerHtml]="quote?.generatedHtml | safeHtml:'html'">
    </div>
    <div class="message-container" *ngIf="!loading && !saving">
        <label i18n="@@quoteModalMessage"><strong>Message:</strong></label><br/>
        <textarea cols="74" rows="5" maxlength="1000" [(ngModel)]="note"></textarea>
    </div>
</div>
<div class="modal-footer">
    <div class="relative-container flex-row-container" style="justify-content: space-between; width: 100%">
        <button type="button"
            *ngIf="ownerId !== invokedUserId"
            id="button_ochange"
            class="small-button"
            aria-label="Close"
            i18n-aria-label
            aria-live="false"
            [disabled]="loading || saving"
            (click)="changeOwnership()"
            (keydown.enter)="changeOwnership()"
            (keydown.space)="changeOwnership()"
            i18n="@@suppchangeownership">
            <span >Change ownership and send quote</span>
        </button>
        <button type="button"
            *ngIf="ownerId === invokedUserId"
            id="button_resend"
            class="small-button"
            aria-label="Close"
            i18n-aria-label
            aria-live="false"
            [disabled]="loading || saving"
            (click)="resendEmail()"
            (keydown.enter)="resendEmail()"
            (keydown.space)="resendEmail()"
            i18n="@@suppresend">
            <span >Resend Email</span>
        </button>
        <button type="button"
            id="button_ok"
            class="small-button"
            aria-label="Close"
            i18n-aria-label
            aria-live="false"
            [disabled]="saving"
            (click)="close()"
            i18n="@@suppclose">
            <span >Close</span>
        </button>
    </div>
    
</div>