import { Component } from '@angular/core';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CombinedFlight, FlightEnterpriseSearchInterface, ServiceProvider } from '@sabstravtech/obtservices/base';
import { FlightItinerary } from '@sabstravtech/obtservices/angular';
@Component({
  selector: 'app-gmt-flight-details-modal',
  templateUrl: './gmt-flight-details-modal.component.html',
  styleUrls: ['./gmt-flight-details-modal.component.scss']
})
export class GmtFlightDetailsModalComponent {
  flight: CombinedFlight = null;
  gmtSelectedFlightIndex: number = null;
  searchParams: FlightEnterpriseSearchInterface = null;
  isTravelfusion: boolean = false;
  index: number = 0;
  constructor(
    public activeModal: NgbActiveModal
  ) { }
  
  ngOnInit() {
    if (this.flight.flights[this.gmtSelectedFlightIndex].source === ServiceProvider.Travelfusion) {
      this.isTravelfusion = true;
    }
  }
  addToBasket(flight: FlightItinerary){
    let selectedFlightIndex = this.flight.flights.findIndex(item => item.journeyHash === flight.journeyHash);
    if (this.searchParams.selectedFlight.value?.journeyHash !== flight.journeyHash) {
      this.searchParams.selectedFlight.next(flight);
      this.gmtSelectedFlightIndex = selectedFlightIndex;
    } else {
      this.searchParams.selectedFlight.next(null);
      this.gmtSelectedFlightIndex = null;
    }
  }

  close() {
    this.activeModal.close(this.gmtSelectedFlightIndex);
  }
}

LightningModalTypes.GmtFlightDetailsModalComponent.component = GmtFlightDetailsModalComponent;