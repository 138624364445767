import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { BannerDisplayTarget, EnterpriseSearchService, WithSubscriptionComponent } from '@sabstravtech/obtservices/angular'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrl: './banner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerComponent extends WithSubscriptionComponent implements OnInit  { 

  @Input() target: BannerDisplayTarget;
  @Input() agentOnly: boolean = false

  showBanner: boolean = true;
  bannerImage: any;

  constructor(
    private searchService: EnterpriseSearchService,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef 
  ){
    super();
  }


  async ngOnInit(): Promise<void> {

    if (this.target) {  // don't call if we don't have a target               // ? - Maybe display an error?
      const banner = await this.searchService.getBanner(this.target, this.agentOnly).toPromise();
      if (banner) {
        this.bannerImage = this.sanitizer.bypassSecurityTrustResourceUrl(banner.image);
        this.cd.detectChanges();
      }
    }
    
  }

  close(): void {
    this.showBanner = false;
  }

}
