import { Component, Input, OnInit } from '@angular/core';
import { LoungeQuote } from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-lounge-details',
  templateUrl: './lounge-details.component.html',
  styleUrls: ['./lounge-details.component.scss']
})
export class LoungeDetailsComponent implements OnInit {
  @Input() lounge: LoungeQuote;
  constructor() {}

  ngOnInit(): void {}
}
