import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'retex',
})
export class RetexPipe implements PipeTransform {
  transform(value: string): string {
    let newValue = value.replace('Online Rate', '');

    newValue = newValue.replace('(travelodge.co.uk) ', '');

    newValue = newValue.replace('(PTI) Crown Rate (CHAD) ', '');

    newValue = newValue.replace('(PTI) Crown Rate (CHAD) ', '');

    newValue = newValue.replace('(PTI) CCS Negotiated ', '');

    return `${newValue}`;
  }
}
