<div cdkTrapFocus aria-live="assertive">
  <div class="modal-header">
    <img
      *ngIf="themeService.isLightningEnv"
      src="https://images.sabscorp.com/images/ctmportal/CTM_Logo.png"
      alt="CTM Logo" />
    <h3 class="modal-title" i18n="@@modalPasswordResetTitle" id="modal-header">Change Password</h3>
  </div>
  <div class="modal-body">
    <form [formGroup]="resetPasswordForm" autocomplete="off">
      <div class="formRow">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label i18n="@@newLogonPassword">New Password</mat-label>
          <input
            matInput
            id="new-password"
            name="new-password"
            type="password"
            formControlName="newPassword"
            autocomplete="off"
            (keyup)="checkValidation($event)" />
        </mat-form-field>
      </div>
      <div class="formRow">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label i18n="@@confirmNewPassword">Confirm New Password</mat-label>
          <input
            matInput
            id="password-check"
            name="password-check"
            type="password"
            formControlName="newPasswordCheck"
            autocomplete="off" />
        </mat-form-field>
      </div>
      <div *ngIf="!isPasswordMatching" class="alert alert-danger">
        <div i18n="@@passwordNotMatching">Your new password does not match to confirmed one.</div>
      </div>
      <div *ngIf="hasMinLengthError" class="alert alert-danger">
        <div i18n="@@passwordLength">Your password length needs to be at least 10 characters.</div>
      </div>
      <div *ngIf="hasPatternError" class="alert alert-danger">
        <div i18n="@@passwordRequirement">Please check password minimum requirement.</div>
      </div>
      <div *ngIf="error_message" class="alert alert-danger">
        <div i18n="@@passwordResetErrorMsg">
          {{ error_message }}
        </div>
      </div>
      <div class="alert alert-info">
        <div>
          <span i18n="@@passwordRequirementMessageNew"
            >Please type in and confirm your new password in accordance with the password
            policy:</span
          >
          <ul>
            <li i18n="@@passwordRequirementMessageNew2">
              Passwords should not be a common password or easily guessable. (Avoid using real words
              such as first name/surname or DOB, this also includes sequential numbers or
              alphabetical characters such as Password123)
            </li>
            <li>
              <span i18n="@@passwordRequirementMessageNew3"
                >Passwords must contain the following:</span
              >
              <ul>
                <li
                  i18n="@@passwordRequirementChars"
                  [ngClass]="{ 'valid-requirement': hasTenchars }">
                  10 characters
                </li>
                <li
                  i18n="@@passwordRequirementLower"
                  [ngClass]="{ 'valid-requirement': hasLowercase }">
                  Lower case letters (a-z)
                </li>
                <li
                  i18n="@@passwordRequirementUpper"
                  [ngClass]="{ 'valid-requirement': hasUppercase }">
                  Upper case letters (A-Z)
                </li>
                <li
                  i18n="@@passwordRequirementNumber"
                  [ngClass]="{ 'valid-requirement': hasNumber }">
                  Numbers (0-9)
                </li>
                <li
                  i18n="@@passwordRequirementSpecial"
                  [ngClass]="{ 'valid-requirement': hasSpecial }">
                  Special characters (e.g. !&#64;#$%^&*)
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </form>
    <button
      type="submit"
      [disabled]="!resetPasswordForm.valid"
      aria-labelledby="reset-password"
      class="modal_button_okay"
      (click)="resetPassword()"
      i18n="@@resetPassowrdButton">
      Reset Password
      <mat-spinner [diameter]="20" [color]="'white'" *ngIf="loading"></mat-spinner>
    </button>
  </div>
</div>
