<div cdkTrapFocus aria-live="assertive">
    <div class="modal-header">
        <h3 class="modal-title" i18n="@@resendConfirmationTitle" id="modal-header">Resend Confirmation</h3>
    </div>
    <div class="modal-body">
        <form #sendForm="ngForm">
            <label i18n="@@resendConfirmationLabel">Resend Confirmation To</label>
            <select [(ngModel)]="sendTo"  class="select-box select-box--border" name="resendChoice" aria-label="Resend Confirmation To" required>
                <option *ngFor="let send of resendOptions" [value]="send"> {{send}} </option>
            </select>
        </form>
    </div>
    <div class="modal-footer">
        <button
            type="submit"
            aria-labelledby="cancel"
            class="small-button"
            (click)="cancel()"
            (keydown.enter)="cancel()"
            (keydown.space)="cancel()"
            i18n-aria-label
            i18n="@@resendConfirmationCancel">
            Cancel
        </button>
        <button
            type="submit"
            aria-labelledby="resend confirmation"
            [disabled]="sendForm.invalid"
            class="small-button"
            (click)="ok()"
            (keydown.enter)="ok()"
            (keydown.space)="ok()"
            i18n-aria-label
            i18n="@@resendConfirmationOk">
            Ok
        </button>
    </div>
</div>
