import { Component, OnInit, Input } from '@angular/core';
import { ServiceType } from '@sabstravtech/obtservices/angular';
@Component({
  selector: 'app-no-results',
  templateUrl: './no-results.component.html',
  styleUrls: ['./no-results.component.scss']
})
export class NoResultsComponent implements OnInit {
  @Input() type: ServiceType;
  @Input() icon = 'ico-info';
  @Input() extraUpdateText = null;

  constructor() { }

  ngOnInit(): void { }
}
