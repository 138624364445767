import { Pipe, PipeTransform } from '@angular/core';

const REGIONSCOUNTRYLIST = {
  ASIA: [
    'AF',
    'BH',
    'BD',
    'BT',
    'BN',
    'KH',
    'CN',
    'CC',
    'GE',
    'HK',
    'IN',
    'ID',
    'IR',
    'IQ',
    'JP',
    'JO',
    'KZ',
    'KP',
    'KR',
    'KW',
    'KG',
    'LA',
    'LB',
    'MY',
    'MV',
    'MN',
    'MM',
    'NP',
    'OM',
    'PK',
    'PS',
    'PH',
    'QA',
    'SA',
    'SG',
    'LK',
    'SY',
    'TW',
    'TJ',
    'TH',
    'TL',
    'TM',
    'AE',
    'UZ',
    'VN',
    'YE',
    'MO',
  ],
  EUROPE: [
    'AX',
    'AL',
    'AD',
    'AM',
    'AT',
    'AZ',
    'BY',
    'BE',
    'BA',
    'BG',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FO',
    'FI',
    'FR',
    'GI',
    'GR',
    'GG',
    'HU',
    'IS',
    'IE',
    'IM',
    'IT',
    'JE',
    'LV',
    'LI',
    'LT',
    'LU',
    'MK',
    'MT',
    'MD',
    'MC',
    'ME',
    'NL',
    'NO',
    'PL',
    'PT',
    'RO',
    'RU',
    'SM',
    'RS',
    'SK',
    'SI',
    'ES',
    'SE',
    'CH',
    'TR',
    'UA',
    'IL',
    'DE',
  ],
  AFFRICA: [
    'DZ',
    'AO',
    'BJ',
    'BW',
    'IO',
    'BF',
    'BI',
    'CM',
    'CV',
    'CF',
    'TD',
    'KM',
    'CG',
    'CD',
    'CI',
    'DJ',
    'EG',
    'GN1',
    'ER',
    'ET',
    'TF',
    'GA',
    'GM',
    'GH',
    'GN',
    'GW',
    'KE',
    'LS',
    'LR',
    'LY',
    'MG',
    'MW',
    'ML',
    'MR',
    'MU',
    'YT',
    'MA',
    'MZ',
    'NA',
    'NE',
    'NG',
    'RE',
    'RW',
    'SH',
    'ST',
    'SN',
    'SC',
    'SL',
    'SO',
    'ZA',
    'SD',
    'SZ',
    'TZ',
    'TG',
    'TN',
    'UG',
    'EH',
    'ZM',
    'ZW',
  ],
  Oceania: [
    'AS',
    'CK',
    'FJ',
    'PF',
    'GU',
    'HM',
    'KI',
    'MH',
    'FS',
    'NR',
    'NC',
    'NZ',
    'NU',
    'NF',
    'MP',
    'LC',
    'PW',
    'PG',
    'PN',
    'WS',
    'SB',
    'TK',
    'TO',
    'TV',
    'VU',
    'WF',
  ],
  'North America': [
    'AI',
    'BS',
    'BB',
    'BL',
    'BM',
    'KY',
    'CR',
    'CU',
    'DM',
    'DO',
    'SV',
    'GL',
    'GD',
    'GP',
    'GT',
    'HT',
    'HN',
    'JM',
    'MQ',
    'MX',
    'MS',
    'AN',
    'NI',
    'PA',
    'PR',
  ],
  Antartica: ['AQ'],
  'South America': [
    'AR',
    'AW',
    'BO',
    'BV',
    'BR',
    'CL',
    'CO',
    'EC',
    'FK',
    'GF',
    'GY',
    'PY',
    'PE',
    'KN',
    'PM',
    'VC',
    'GS',
    'SR',
    'TT',
    'TC',
    'UY',
    'VE',
    'VG',
    'VI',
  ],
  Australia: ['AU', 'CX'],
  'USA and Canada': ['CA', 'US', 'UM'],
  'The World exc UK': [
    'AF',
    'BH',
    'BD',
    'BT',
    'BN',
    'KH',
    'CN',
    'CC',
    'GE',
    'HK',
    'IN',
    'ID',
    'IR',
    'IQ',
    'JP',
    'JO',
    'KZ',
    'KP',
    'KR',
    'KW',
    'KG',
    'LA',
    'LB',
    'MY',
    'MV',
    'MN',
    'MM',
    'NP',
    'OM',
    'PK',
    'PS',
    'PH',
    'QA',
    'SA',
    'SG',
    'LK',
    'SY',
    'TW',
    'TJ',
    'TH',
    'TL',
    'TM',
    'AE',
    'UZ',
    'VN',
    'YE',
    'MO',
    'AX',
    'AL',
    'AD',
    'AM',
    'AT',
    'AZ',
    'BY',
    'BE',
    'BA',
    'BG',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FO',
    'FI',
    'FR',
    'GI',
    'GR',
    'GG',
    'HU',
    'IS',
    'IE',
    'IM',
    'IT',
    'JE',
    'LV',
    'LI',
    'LT',
    'LU',
    'MK',
    'MT',
    'MD',
    'MC',
    'ME',
    'NL',
    'NO',
    'PL',
    'PT',
    'RO',
    'RU',
    'SM',
    'RS',
    'SK',
    'SI',
    'ES',
    'SE',
    'CH',
    'TR',
    'UA',
    'IL',
    'DE',
    'DZ',
    'AO',
    'BJ',
    'BW',
    'IO',
    'BF',
    'BI',
    'CM',
    'CV',
    'CF',
    'TD',
    'KM',
    'CG',
    'CD',
    'CI',
    'DJ',
    'EG',
    'GN1',
    'ER',
    'ET',
    'TF',
    'GA',
    'GM',
    'GH',
    'GN',
    'GW',
    'KE',
    'LS',
    'LR',
    'LY',
    'MG',
    'MW',
    'ML',
    'MR',
    'MU',
    'YT',
    'MA',
    'MZ',
    'NA',
    'NE',
    'NG',
    'RE',
    'RW',
    'SH',
    'ST',
    'SN',
    'SC',
    'SL',
    'SO',
    'ZA',
    'SD',
    'SZ',
    'TZ',
    'TG',
    'TN',
    'UG',
    'EH',
    'ZM',
    'ZW',
    'AS',
    'CK',
    'FJ',
    'PF',
    'GU',
    'HM',
    'KI',
    'MH',
    'FS',
    'NR',
    'NC',
    'NZ',
    'NU',
    'NF',
    'MP',
    'LC',
    'PW',
    'PG',
    'PN',
    'WS',
    'SB',
    'TK',
    'TO',
    'TV',
    'VU',
    'WF',
    'AI',
    'BS',
    'BB',
    'BL',
    'BM',
    'KY',
    'CR',
    'CU',
    'DM',
    'DO',
    'SV',
    'GL',
    'GD',
    'GP',
    'GT',
    'HT',
    'HN',
    'JM',
    'MQ',
    'MX',
    'MS',
    'AN',
    'NI',
    'PA',
    'PR',
    'AQ',
    'AR',
    'AW',
    'BO',
    'BV',
    'BR',
    'CL',
    'CO',
    'EC',
    'FK',
    'GF',
    'GY',
    'PY',
    'PE',
    'KN',
    'PM',
    'VC',
    'GS',
    'SR',
    'TT',
    'TC',
    'UY',
    'VE',
    'VG',
    'VI',
    'AU',
    'CX',
    'CA',
    'US',
    'UM',
  ],
  'UK - GB': ['UK', 'GB'],
};

@Pipe({
  name: 'countryRegion',
})
export class CountryRegionPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return null;
  }

  /**
   * @description - get the region(s) based off of the country code - returns empty array if not found
   * The region list was taken from config.js in the ext so any spelling errors/weird region listings are nothing
   * to do with me - Ed
   * @param country - the country code to look up
   */
  getRegionFromCountry(country: string): any {
    const regionKeys = Object.keys(REGIONSCOUNTRYLIST);
    const foundRegions = [];

    for (let i = 0; i < regionKeys.length; i++) {
      if (REGIONSCOUNTRYLIST[regionKeys[i]].includes(country.toUpperCase())) {
        foundRegions.push(regionKeys[i]);
      }
    }

    return foundRegions;
  }
}
