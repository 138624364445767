import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

/**
 * Make time readable
 * TODO: Extend docs
 *
 * @export
 * @class TimePipe
 * @implements {PipeTransform}
 */
@Pipe({ name: 'sabsTimePipe' })
export class TimePipe implements PipeTransform {
  transform(value: string | moment.Moment, args?: string, offset?: string): any {
    if (!value) {
      return value;
    }
    if (typeof value === 'string') {

      // if (!offset && value.includes('+')) {
      //   try {

      //     const split = value.split('+');
      //     offset = split[1];
      //     value = split[0]; // do this to remove any timezone nonsence - messes with the moment add hour calc
      //   } catch (error) {

      //   }
      // }
    }

    if (offset) {
      console.log(offset);
      const numoff = Number(offset.split(':')[0]);
      // console.log(numoff)

      if (numoff) {
        let momvalue = moment(value);
        momvalue.hour(momvalue.hour() + numoff);
        value = momvalue.toString();
      }
    }

    const test = value.toString().match(/.{1,2}/g);
    let new_time = '';
    switch (args) {
      case 'duration':
        new_time = Number(test[0].trim()) + 'h,' + Number(test[1].trim()) + 'm';
        break;
      case 'min_duration':
        const temp = Math.floor(Number(value) / 60);
        const temp2 = Number(value) % 60;
        new_time = temp + 'h ' + temp2 + 'm';
        break;
      case 'json_time':
        // Assume that all dates are in local time, so remove any time zone information.
        return moment(value).utc().format('HH:mm');
      case 'trainline_eu_time': // for some godforsaken reason - when you remove the timezone info it deducts an hour anyway for no good reason - so here
        let offset = value.toString().split('+');

        if (offset[1]) {
          const innerOffset = Number(offset[1].split(':')[0]);
          return moment(value).add(innerOffset, 'hour').utc().format('HH:mm');
        }

        return moment(value).format('HH:mm');

      case 'hour_ahead':
        return moment(value).add(1, 'hour').format('HH:mm');
      case 'timeString':
      default:
        return moment(value).format('HH:mm');
    }

    return new_time;
  }
}
