import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component } from '@angular/core';
import { WithSubscriptionComponent } from '@sabstravtech/obtservices/angular';
import { LightningModalTypes } from '../vendor/classes/modal-types.enum';

@Component({
  templateUrl: './modal-base-template.component.html',
  styleUrls: ['./modal.component.scss']
})
export class BaseModalComponent extends WithSubscriptionComponent {
  title: string = null;
  body: any = null;
  options: {
    buttonCancelText: string;
    buttonOkText: string;
    buttonCancel: boolean;
    buttonOk: boolean;
    buttonOkAction: () => boolean;
  };
  public isLoading = true;

  constructor(public activeModal: NgbActiveModal) {
    super();
    this.options = {
      buttonCancelText: 'Cancel',
      buttonOkText: 'Ok',
      buttonCancel: true,
      buttonOk: false,
      buttonOkAction() {
        return this.activeModal.close();
      }
    };
  }

  buttonOkAction(): void {
    if (this.options.buttonOkAction && this.options.buttonOkAction()) {
      this.activeModal.close();
    }
  }
}

LightningModalTypes.BaseModalComponent.component = BaseModalComponent;