<div class="map-actions light-right">
  <a class="light-btn-link colour-font-secondary"
     *ngIf="backBtn"
     (click)="closeMapView.emit()"
     (keydown.enter)="closeMapView.emit()"
     tabindex="0"
     role="link"
     i18n="@@mapViewsBackToList">Back to list view</a>
</div>
<div class="map-container"
     tabindex="-1">
  <google-map [style.height.px]="height"
           [usePanning]="false"
           [options]="mapOptions" 
           [zoom]="zoom">
    <map-marker [position]="center"
                [icon]="icon"
                *ngIf="!displayRoute"
                [title]="title">
    </map-marker>
    <ng-container *ngIf="!displayRoute">
      <map-marker *ngFor="let pin of pins"
                [position]="pin"
                [title]="pin.message">
      </map-marker>
    </ng-container>
    <core-map-content (onMapLoad)="loadAPIWrapper($event)"></core-map-content>
    @if (directionsResults$ | async; as directionsResults) {
      <map-directions-renderer [directions]="directionsResults"/>
    }
  </google-map>
</div>