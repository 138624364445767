import { Pipe, PipeTransform } from '@angular/core';

/**
 * @class SabsSecondsPipe
 * @implements {PipeTransform}
 * @description converts seconds into something more meaningful, e.g. hours/hours-mins/hours-mins-secs
 */
@Pipe({ name: 'sabsSecondsPipe' })
export class SabsSecondsPipe implements PipeTransform {
  transform(seconds: number, args?: string): string {
    if (seconds) {
      return seconds.toString();
    }

    // hours
    const h = Math.floor(seconds / (60 * 60));

    // minutes
    const divisor_for_minutes = seconds % (60 * 60);
    const m = Math.floor(divisor_for_minutes / 60);

    // seconds
    const divisor_for_seconds = divisor_for_minutes % 60;
    const s = Math.ceil(divisor_for_seconds);

    switch (args) {
      case 'h':
        return `${h}h`;
      case 'hm':
        return `${h}h ${m}m`;
      case 'hms':
        return `${h}h ${m}m ${s}s`;
      default:
        return seconds.toString();
    }
  }
}
