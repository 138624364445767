import { TitleCasePipe } from '@angular/common';
import { Component, HostListener, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbInputDatepicker, NgbModal, NgbTooltip, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, merge } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { EnterpriseSearchService, ServiceType, UserService } from '@sabstravtech/obtservices/angular';

import { DeviceDetector } from '../../../vendor/services/device-detector.service';
import moment from 'moment';
// import { SetDefaultService } from '../../obtservices/vendor/services/set-default.service';
// import { UserFavorurite } from '../../obtservices/vendor/enums/user-favourite.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-irl-results',
    templateUrl: './irl-results.component.html',
    styleUrls: ['./irl-results.component.scss']
})
export class IrlResultsComponent implements OnInit {
    @ViewChild('instance') instance: NgbTypeahead;
    @ViewChild('ttip') ttip: NgbTooltip;
    ServiceType: typeof ServiceType = ServiceType;
    constructor(
        private userService: UserService,
        private readonly router: Router,
        public deviceDetector: DeviceDetector,
        public searchService: EnterpriseSearchService,
        private titleCasePipe: TitleCasePipe,
        private title: Title,
        private route: ActivatedRoute,
        public translateService: TranslateService
    ) { }

    ngOnInit(): void {
        this.title.setTitle(this.translateService.instant('European Rail Results - LightUk'));
    }
}