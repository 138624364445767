import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EnterpriseSearchService } from '@sabstravtech/obtservices/angular';
@Component({
  selector: 'app-validate-errors',
  templateUrl: './validate-errors.component.html',
  styleUrls: ['./validate-errors.component.scss']
})
export class ValidateErrorsComponent implements OnChanges {
  @Input() messages: string[];
  @Input() variable: 'infoMessages' | 'validationMessages' = 'validationMessages';
  show: boolean = false;

  constructor(private searchService: EnterpriseSearchService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.messages) {
      this.show = this.messages.length > 0;
    }
  }

  close(): void {
    this.messages = [];
    this.show = false;
  }
}
