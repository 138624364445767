import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';
import { TermsAndConditions } from '@sabstravtech/obtservices/base'
@Component({
  selector: 'app-gmt-taxi-fare-rules-modal',
  templateUrl: './gmt-taxi-fare-rules-modal.html',
  styleUrls: ['./gmt-taxi-fare-rules-modal.scss']
})
export class GmtTaxiModalFareRulesComponent implements OnInit {
  termsAndCond: TermsAndConditions[] = [];
  constructor(public modal: NgbActiveModal) {}

  ngOnInit(): void {}

}

LightningModalTypes.GmtTaxiModalFareRulesComponent.component = GmtTaxiModalFareRulesComponent;