import { Component, Input, OnInit } from '@angular/core';
import { DateService } from '../../../../../shared/services/date.service';
import { BasketItem, RailJourneyLeg } from '@sabstravtech/obtservices/angular';
// import { RailJourneyLeg } from '@sabstravtech/obtservices/base/lib/vendor/interfaces/rail-journey-leg.interface';
import { } from '@sabstravtech/obtservices/base';

@Component({
  selector: 'app-irl-journey-details',
  templateUrl: './irl-journey-details.component.html',
  styleUrls: ['./irl-journey-details.component.scss']
})
export class IrlJourneyDetailsComponent implements OnInit {
  @Input() basketItem: BasketItem;
  @Input() darkMode = false;

  get railOutwardStops(): RailJourneyLeg[] {
    return this.basketItem.detail.outwardDetail?.supplierTickets[0].legs;
  }

  get railInwardStops(): [] {
    return this.basketItem.detail.inwardDetail?.supplierTickets[0].legs;
  }

  get railInwardSummary(): RailJourneyLeg {
    const basketInwardDetail = this.basketItem.detail.inwardDetail;

    return null;
    // return ({
    //   arrivalDateTime: basketInwardDetail?.arrivalDateTime,
    //   arrivalStationName: basketInwardDetail?.arrivalStationName,
    //   departureDateTime: basketInwardDetail?.departureDateTime,
    //   departureStationName: basketInwardDetail?.departureStationName
    // });
  }

  get railOutwardSummary(): RailJourneyLeg {
    const basketOutwardDetail = this.basketItem.detail.outwardDetail;
    return null;
    // return ({
    //   arrivalDateTime: basketOutwardDetail?.arrivalDateTime,
    //   arrivalStationName: basketOutwardDetail?.arrivalStationName,
    //   departureDateTime: basketOutwardDetail?.departureDateTime,
    //   departureStationName: basketOutwardDetail?.departureStationName
    // });
  }

  get hasOutwardStops(): boolean {
    return !!(this.railOutwardStops && this.railOutwardSummary);
  }

  get hasInwardStops(): boolean {
    return !!(this.railInwardStops && this.railInwardSummary);
  }

  constructor(private date: DateService) { }

  totalTimeJourney(departureDateTime: string, arrivalDateTime: string): string {
    return this.date.calculateTimeDifference(
      departureDateTime,
      arrivalDateTime
    );
  }

  ngOnInit(): void { }

}
