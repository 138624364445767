import { isPlatformBrowser } from '@angular/common';
import {
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Output,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';

@Directive({ selector: '[inview]' })
export class InViewDirective {
  @Output() inViewChanged: EventEmitter<boolean> = new EventEmitter<boolean>(
    false
  );
  options: any = { threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8] };
  observer: IntersectionObserver;

  constructor(
    public element: ElementRef,
    public renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.observer = new IntersectionObserver(
        this.handleIntersect.bind(this),
        this.options
      );
      this.observer.observe(this.element.nativeElement);
    }
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }

  handleIntersect(entries, observer): void {
    entries.forEach((entry: IntersectionObserverEntry) => {
      this.inViewChanged.emit(entry.isIntersecting);
    });
  }
}
