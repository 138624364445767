import { Component, ElementRef, EventEmitter, Input, OnInit, Output, HostListener, SimpleChanges } from '@angular/core';
import { LightToggleBtn } from '../../vendor/interfaces/light-toggle-btn.interface';

@Component({
  selector: 'app-light-toggle-btn',
  templateUrl: './light-toggle-btn.component.html',
  styleUrls: ['./light-toggle-btn.component.scss']
})
export class LightToggleBtnComponent implements OnInit {
  @Output() public clicked: EventEmitter<LightToggleBtn> = new EventEmitter();
  @Input() iconClass: string;
  @Input() disabled = false;
  @Input() tooltipText = null;
  @Input() isLink = false;
  private active = false;
  private _selected = false;
  @Input() set selected(value: boolean) {
    this._selected = value;
    if (value) {
      this.handleSelectionChange();
    }
  }

  get selected(): boolean {
    return this._selected;
  }

  constructor(public elementRef: ElementRef) {}

  ngOnInit(): void {
    if (this.selected) {
      this.active = true;
    }
  }

  handleSelectionChange(): void {
    this.clicked.emit(this.getRef());
  }

  click(e?: Event): void {
    if (this.disabled || this.active) {
      return;
    }
    this.clicked.emit(this.getRef(e));
  }

  keydown(e?: KeyboardEvent): void {
    if (e.keyCode == 32) {
      e.preventDefault(); // Prevent scrolldown on spacebar press
    }
    if (e.keyCode === 32 || e.keyCode === 13) {
      // User has pressed enter or space
      this.click(e);
    }
  }

  toggle(): void {
    this.active = !this.active;
  }

  setActive(value: boolean): void {
    this.active = value;
  }

  isActive(): boolean {
    return this.active;
  }

  getRef(event?: Event): LightToggleBtn {
    return {
      active: this.active,
      elementRef: this.elementRef,
      event
    };
  }
}
