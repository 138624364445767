<div cdkTrapFocus>
  <div class="modal-header">
    <h3 class="modal-title">
      <span i18n="faretypeHeader">Flight Fare Details</span>: {{ from }}
      <i class="ico ico-breadcrumb-arrow"></i> {{ to }}
    </h3>
    <button
        type="button"
        class="close"
        aria-label="Flight Fare Details Close"
        ngbAutoFocus
        (click)="activeModal.close(true)">
        <span aria-hidden="true"><i class="ico-cancel modal_exit_cross"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="fare-rules-warning" i18n="faretypeWarning" i18n="@@fareDetailsPleaseNote">
      Please Note: When booking a return journey the most restrictive fare rules will apply for the whole itinerary.
    </div>
    <div class="fare-rules-container" *ngIf="!inboundRules">
      <div class="segment-block" *ngIf="flight?.outboundFlights">
        <div class="segment-block-segment"
          [ngClass]="{'selected-segment': segment.id === selectedId}"
          *ngFor="let segment of flight.outboundFlights"
          (click)="selectSegment(segment)"
          (keydown.enter)="selectSegment(segment)"
          (keydown.space)="selectSegment(segment)"
          tabindex="0"
          role="button">
          <div class="left-title">
            <span class="direction-span" i18n="fareDetailsDepart">Depart</span>
            <img *ngIf='segment.marketingCarrier !== "9F"'
                  class="logo"
                  src="https://images.sabscorp.com/images/TourOp/svg/{{segment.marketingCarrier}}.svg"
                  alt="{{segment.marketingCarrier}}">
            <img *ngIf='segment.marketingCarrier === "9F"'
                  class="logo"
                  src="https://images.sabscorp.com/images/enterprise/scion/assets/svg/eurostar.svg"
                  alt="Eurostar">
          </div>
          <div class="segment-block-info">
            <span class="date-span">{{segment.dateTimeDeparting | date :'EEE dd MMM' }}</span>
            <div class="thirds-container">
              <div class="thirds-block">
                <h5><b>{{segment.dateTimeDeparting | date :'HH:mm'}}</b><br /> {{segment.originAirport }}</h5>
              </div>
              <div class="thirds-block">
                <img class="segment-arrow" alt=""
                      src="https://images.sabscorp.com/images/enterprise/scion/assets/png/tab_arrow.png">
              </div>
              <div class="thirds-block">
                <h5><b>{{segment.dateTimeArriving | date :'HH:mm' }}</b><br /> {{segment.destinationAirport}}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content-block" *ngIf="!loading && rules">
        <div *ngIf="rules.cancellation && rules.cancellation.length > 0">
          <h3 i18n="@@fareruleCancellation">Cancellation</h3>
          <app-farerule-items [items]="rules.cancellation"></app-farerule-items>
        </div>

        <div *ngIf="rules.change && rules.change.length > 0">
          <h3 i18n="@@fareruleChanges">Changes</h3>
          <app-farerule-items [items]="rules.change"></app-farerule-items>
        </div>

        <div *ngIf="rules.noShow && rules.noShow.length > 0">
          <h3 i18n="@@fareruleNoShow">No Show</h3>
          <app-farerule-items [items]="rules.noShow"></app-farerule-items>
        </div>

        <div *ngIf="rules.upgrade && rules.upgrade.length > 0">
          <h3 i18n="@@fareruleUpgrading">Upgrading</h3>
          <app-farerule-items [items]="rules.upgrade"></app-farerule-items>
        </div>

        <div *ngIf="rules.other && rules.other.length > 0">
          <h3 i18n="@@fareruleOther">Other</h3>
          <app-farerule-items [items]="rules.other"></app-farerule-items>
        </div>
      </div>
      <div class="content-block" *ngIf="!loading && !rules">
        <span class="error" i18n="@@noRulesError">No Rules Found.</span>
      </div>
      <div *ngIf="loading" i18n="@@LoadingFareRules">
        Loading Fare Rules...
      </div>
    </div>
    <div *ngIf="inboundRules" class="fare-rules-container return-container" >
      <div class="flex-row-container">
        <div class="flex-column-container flight-container outbound-flight" *ngIf="flight?.outboundFlights">
          <div class="segment-block-segment">
            <div class="left-title">
              <img *ngIf='flight?.outboundFlights[0].marketingCarrier !== "9F"'
                    class="logo"
                    src="https://images.sabscorp.com/images/TourOp/svg/{{flight?.outboundFlights[0].marketingCarrier}}.svg"
                    alt="{{flight?.outboundFlights[0].marketingCarrier}}">
              <img *ngIf='flight?.outboundFlights[0].marketingCarrier === "9F"'
                    class="logo"
                    src="https://images.sabscorp.com/images/enterprise/scion/assets/svg/eurostar.svg"
                    alt="Eurostar">
            </div>
            <div class="segment-block-info">
              <div class="thirds-container">
                <div class="thirds-block">
                  <span class="date-span">{{flight?.outboundFlights[0].dateTimeDeparting | date :'EEE dd MMM' }}</span>
                  <span class="airport_name">{{flight?.outboundFlights[0].originAirportName }}</span>
                  <div class="flex-row-container">
                    <span class="direction-span" i18n="fareDetailsDepart">Depart</span>&nbsp;
                    <b>{{flight?.outboundFlights[0].dateTimeDeparting | date :'HH:mm'}}</b>
                  </div>
                </div>
                <div class="thirds-block">
                  <img class="segment-arrow" alt=""
                        src="https://images.sabscorp.com/images/enterprise/scion/assets/png/tab_arrow.png">
                </div>
                <div class="thirds-block">
                  <span class="airport_name">{{flight?.outboundFlights[flight.outboundFlights.length - 1].destinationAirportName}}</span>
                  <b>{{flight?.outboundFlights[flight.outboundFlights.length - 1].dateTimeArriving | date :'HH:mm' }}</b>
                </div>
              </div>
            </div>
          </div>
          <div class="content-block" *ngIf="!loading && rules">
            <div *ngIf="rules.cancellation && rules.cancellation.length > 0">
              <h3 i18n="@@fareruleCancellation">Cancellation</h3>
              <app-farerule-items [items]="rules.cancellation"></app-farerule-items>
            </div>

            <div *ngIf="rules.change && rules.change.length > 0">
              <h3 i18n="@@fareruleChanges">Changes</h3>
              <app-farerule-items [items]="rules.change"></app-farerule-items>
            </div>

            <div *ngIf="rules.noShow && rules.noShow.length > 0">
              <h3 i18n="@@fareruleNoShow">No Show</h3>
              <app-farerule-items [items]="rules.noShow"></app-farerule-items>
            </div>

            <div *ngIf="rules.upgrade && rules.upgrade.length > 0">
              <h3 i18n="@@fareruleUpgrading">Upgrading</h3>
              <app-farerule-items [items]="rules.upgrade"></app-farerule-items>
            </div>

            <div *ngIf="rules.other && rules.other.length > 0">
              <h3 i18n="@@fareruleOther">Other</h3>
              <app-farerule-items [items]="rules.other"></app-farerule-items>
            </div>
          </div>
          <div class="content-block" *ngIf="!loading && !rules">
            <span class="error" i18n="@@noRulesError">No Rules Found.</span>
          </div>
          <div *ngIf="loading" i18n="@@LoadingFareRules">
            Loading Fare Rules...
          </div>
        </div>
        <div class="flex-column-container flight-container" *ngIf="flight?.inboundFlights">
          <div class="segment-block-segment">
            <div class="left-title">
              <img *ngIf='flight?.inboundFlights[0].marketingCarrier !== "9F"'
                    class="logo"
                    src="https://images.sabscorp.com/images/TourOp/svg/{{flight?.inboundFlights[0].marketingCarrier}}.svg"
                    alt="{{flight?.inboundFlights[0].marketingCarrier}}">
              <img *ngIf='flight?.inboundFlights[0].marketingCarrier === "9F"'
                    class="logo"
                    src="https://images.sabscorp.com/images/enterprise/scion/assets/svg/eurostar.svg"
                    alt="Eurostar">
            </div>
            <div class="segment-block-info">
              <div class="thirds-container">
                <div class="thirds-block">
                  <span class="date-span">{{flight?.inboundFlights[0].dateTimeDeparting | date :'EEE dd MMM' }}</span>
                  <span class="airport_name">{{flight?.inboundFlights[0].originAirportName }}</span>
                  <div class="flex-row-container">
                    <span class="direction-span" i18n="fareDetailsReturn">Return</span>&nbsp;
                    <b>{{flight?.inboundFlights[0].dateTimeDeparting | date :'HH:mm'}}</b>
                  </div>
                </div>
                <div class="thirds-block">
                  <img class="segment-arrow" alt=""
                        src="https://images.sabscorp.com/images/enterprise/scion/assets/png/tab_arrow.png">
                </div>
                <div class="thirds-block">
                  <span class="airport_name">{{flight?.inboundFlights[flight.inboundFlights.length - 1].destinationAirportName}}</span>
                  <b>{{flight?.inboundFlights[flight.inboundFlights.length - 1].dateTimeArriving | date :'HH:mm' }}</b>
                </div>
              </div>
            </div>
          </div>
          <div class="content-block" *ngIf="!loading && inboundRules">
            <div *ngIf="inboundRules.cancellation && inboundRules.cancellation.length > 0">
              <h3 i18n="@@fareruleCancellation">Cancellation</h3>
              <app-farerule-items [items]="inboundRules.cancellation"></app-farerule-items>
            </div>

            <div *ngIf="inboundRules.change && inboundRules.change.length > 0">
              <h3 i18n="@@fareruleChanges">Changes</h3>
              <app-farerule-items [items]="inboundRules.change"></app-farerule-items>
            </div>

            <div *ngIf="inboundRules.noShow && inboundRules.noShow.length > 0">
              <h3 i18n="@@fareruleNoShow">No Show</h3>
              <app-farerule-items [items]="inboundRules.noShow"></app-farerule-items>
            </div>

            <div *ngIf="inboundRules.upgrade && inboundRules.upgrade.length > 0">
              <h3 i18n="@@fareruleUpgrading">Upgrading</h3>
              <app-farerule-items [items]="inboundRules.upgrade"></app-farerule-items>
            </div>

            <div *ngIf="inboundRules.other && inboundRules.other.length > 0">
              <h3 i18n="@@fareruleOther">Other</h3>
              <app-farerule-items [items]="inboundRules.other"></app-farerule-items>
            </div>
          </div>
          <div class="content-block" *ngIf="!loading && !inboundRules">
            <span class="error" i18n="@@noRulesError">No Rules Found.</span>
          </div>
          <div *ngIf="loading" i18n="@@LoadingFareRules">
            Loading Fare Rules...
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="fare_rule-key" *ngIf="fareRulesKey">
     <span *ngIf="fareRulesKey.isCHA" i18n="@@fareruleCHA"> CHA = Chargeable</span>
     <span *ngIf="fareRulesKey.isINC" i18n="@@fareruleINC"> INC = Included</span>
     <span *ngIf="fareRulesKey.isNOF" i18n="@@fareruleNOF"> NOF = Not Offered</span>
    </div>
    <button
      type="button"
      class="small-button small-button--no"
      i18n="@@fareruleOK"
      (click)="activeModal.close(false)"
    >
      Ok
    </button>
  </div>
</div>
