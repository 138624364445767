<!-- Car Hire -->
<div class="cart_item" [class.duplicate-item]="duplicateBooking">
  <div class="cart_item_wrapper">
    <ng-container *ngIf="car.detail as detail">
      <div>
        <div [class.duplicate-item-wrapper]="duplicateBooking">
          <div *ngIf="duplicateBooking" class="duplicate-item-date">
            <i class="ico-car" aria-hidden="true"></i>&nbsp;
              <span i18n="@@basketPanelPickUpCarHire">Pick up:</span>
              <ng-container *ngIf="detail.pickUpDatetime as pickUpDatetime">
                <strong >{{ pickUpDatetime | sabsDate: 5 }}</strong>
                <!-- <strong >{{ pickUpDatetime | date: 'HH:mm' }}</strong> -->
              </ng-container>
          </div>
          <div class="cart_item_heading">
            <ng-container *ngIf="!duplicateBooking">
              <i class="ico-car" aria-hidden="true"></i>&nbsp;
            </ng-container>
            <span *ngIf="detail.vendorName && detail.identifiers.locationName">
              {{ detail.vendorName | titlecase}}
              {{ detail.identifiers.locationName | titlecase }}
            </span>
            <button *ngIf="!duplicateBooking"
              class="cart_item_remove ico-trash"
              ngbTooltip="Remove {{ detail.vendorName }} from your {{displayItinerary ? 'itinerary' : 'basket'}}"
              i18n-ngbTooltip
              attr.aria-label="Remove {{ detail.vendorName }} car hire from your {{displayItinerary ? 'itinerary' : 'basket'}}"
              (click)="remove.emit()"
              id="remove-basketitem-{{car.id}}"></button>
            <img *ngIf="!duplicateBooking"
                 src="https://images.sabscorp.com/images/enterprise/lightUK/assets/svg/{{ displayItinerary ?  'luggage_icon' : 'basket_move'}}.svg"
                 class="move_basket_icon"
                 role="button"
                 ngbTooltip="Move to different {{displayItinerary ? 'itinerary' : 'basket'}}"
                 i18n-ngbTooltip
                 attr.aria-label="Move {{ detail.vendorName }} car hire to different {{displayItinerary ? 'itinerary' : 'basket'}}"
                 (click)="openChangeModal.emit()"
                 (keydown.enter)="openChangeModal.emit()"
                 (keydown.space)="openChangeModal.emit()"
                 tabindex="0"
                 id="share-basketitem-{{car.id}}">
          </div>
          <div class="car_cart_item_from_point" id="car_from_point_{{car.id}}">
            <strong *ngIf="!duplicateBooking && detail.pickUpDatetime as pickUpDatetime">{{ pickUpDatetime | sabsDate : 5 }}</strong>
            <div class="car_cart_item_point_location"
                 *ngIf="detail.pickUpLocation as pickUpLocation">
              {{ pickUpLocation.address }}
              ({{ pickUpLocation.name | titlecase }})
            </div>
          </div>
        </div>

        <div [class.duplicate-item-wrapper]="duplicateBooking">
          <div *ngIf="duplicateBooking" class="duplicate-item-date">
            <i class="ico-car" aria-hidden="true"></i>&nbsp;
              <span i18n="@@basketPanelDropOffCarHire">Drop off:</span>
              <ng-container *ngIf="detail.dropOffDatetime as dropOffDatetime">
                <strong >{{ dropOffDatetime | sabsDate: 5 }}</strong>
                <!-- <strong >{{ dropOffDatetime | date: 'HH:mm' }}</strong> -->
              </ng-container>
          </div>
          <div class="car_cart_item_to_point" id="car_to_point_{{car.id}}">
            <strong *ngIf="!duplicateBooking && detail.dropOffDatetime as dropOffDatetime">{{ dropOffDatetime | sabsDate : 5 }}</strong>
            <div class="car_cart_item_point_location"
                *ngIf="detail.dropOffLocation as dropOffLocation">
              {{ dropOffLocation.address }}
              ({{ dropOffLocation.name | titlecase }})
            </div>
          </div>
        </div>

      </div>

    </ng-container>
    <div class="cart_item_price"
         *ngIf="car.price >= 0">
      <strong *ngIf="duplicateBooking" i18n="@@basketPanelCostCarHire">Cost</strong>
      <strong>{{ car.price | currency: car.currency }}</strong>
      <span class="converted_currency" *ngIf="car.convertedCurrencyCode">&nbsp;(Approx: {{ car.convertedCurrencyAmount | currency: car.convertedCurrencyCode }})</span>

      <em *ngIf="!duplicateBooking" i18n="@@basketPanelTotalPriceCarHire">Total Price</em>
    </div>
  </div>
</div>
