import { ProductLoadingScreen } from './product-loading-screen';
import { EnterpriseSearchService,ServiceType } from '@sabstravtech/obtservices/angular'
import { ParkingEnterpriseSearchInterface} from '@sabstravtech/obtservices/base'
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-parking-loading-screen',
    templateUrl: './parking-loading-screen.component.html',
    styleUrls: [],
  })
export class ParkingLoadingScreenComponent extends ProductLoadingScreen<ParkingEnterpriseSearchInterface> {
    @Input() animated: boolean = true;
    constructor(service: EnterpriseSearchService) {
        super(service, service.searches[ServiceType.Parking]);
    }
}