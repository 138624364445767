<div class="modal-header">
    <h3 class="modal-title light-color-primary" i18n="@@modalPriceBreakdown">Price Breakdown</h3>
    <button type="button"
            class="close"
            aria-label="European rail Fare Details Close"
            ngbAutoFocus
            i18n-aria-label
            (click)="activeModal.close(true)">
      <span aria-hidden="true">
        <i class='ico-cancel modal_exit_cross'></i>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngFor="let night of nights">
      <div class="breakdown-spacer">
        <strong>{{ night.date | sabsDate: 2 }} - {{night.rate | currency: currency}}</strong>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button"
    class="small-button"
    (click)="activeModal.close(true)"
    i18n="@@modalFlightFareOK">Close</button>
  </div>
