import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toArrayNumbers',
})
export class ToArrayNumbersPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return Array(value)
      .fill(0)
      .map((v, i) => i + 1);
  }
}
