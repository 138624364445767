import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { OBTAirportDetails, TimeWindow, FlightEnterpriseSearchInterface, IMultiDestination } from '@sabstravtech/obtservices/base';
import { EnterpriseSearchService, HelperRoutines, ServiceType } from '@sabstravtech/obtservices/angular';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-multi-city-destination-input',
  templateUrl: './multi-city-destination-input.component.html',
  styleUrls: ['./multi-city-destination-input.component.scss']
})
export class MultiCityDestinationInputComponent implements OnInit {
  faTrash = faTrash;
  searchParams: FlightEnterpriseSearchInterface;
  @Input() flightLocations: (
    text$: Observable<string>
  ) => Observable<OBTAirportDetails[]>;
  @Input() flightLocations2: (
    text$: Observable<string>
  ) => Observable<OBTAirportDetails[]>;
  @Input() formatter_rail_air: (x: { destination: string; }) => any;
  @Input() minDate: NgbDateStruct;
  @Input() flight: IMultiDestination;
  @Input() flightIndex: number;
  @Input() isLast: boolean;
  @Input() criteria = [];
  flexibilityHours: number[] = [];
  TimeWindow: typeof TimeWindow = TimeWindow;
  @Output() selectedInput = new EventEmitter<number>();
  @Output() flightRemove = new EventEmitter<number>();
  focus$ = new Subject<string>();
  focus2$ = new Subject<string>();
  startDate: NgbDateStruct;
  date: NgbDateStruct;
  time: string;
  constructor(
    public searchService: EnterpriseSearchService,
    private helpers: HelperRoutines,
  ) {
    for (let i = 1; i <= 7; i++) {
      this.flexibilityHours.push(i);
    }
  }

  ngOnInit() {
    this.searchParams = this.searchService.searches[ServiceType.Flight];
    this.date = this.helpers.momentToNgDate(this.flight.date);
    this.time = this.helpers.formatTimeColon(this.flight.date);

    this.startDate = this.minDate || this.helpers.dateToNgDate(new Date);
  }

  removeFlight(index: number) {
    this.flightRemove.emit(index);
  }
  updateTemp(index: number, newDate?: NgbDateStruct) {
    if (newDate) {
      this.date = newDate;
    }
    // this.flight.date = moment(this.date).add({M:-1});
    this.flight.date = this.helpers.ngDateToMoment(this.date, this.time);
    this.selectedInput.emit(index);
  }
}
