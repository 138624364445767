<h2>Flight selected</h2>
<div class="flights-by-schedule-container">
  <div class="remove-and-search-again">
    <button
      mat-stroked-button
      class="basket_item_action_button"
      i18n-title
      title="Remove & Search again"
      (click)="removeAndSearchAgain.emit(true)"
      (keydown.enter)="removeAndSearchAgain.emit(true)">
      <span aria-hidden="true">
        <i class="ico-trash"></i>
      </span>
      <span i18n="@@RemoveSearchAgain">Remove & Search again</span>
    </button>
  </div>
  <div class="flights-by-schedule-list-container">
    <div class="flights-by-schedule-list">
      <div class="flights-by-schedule-item" *ngFor="let item of multiCityByScheduleFlightsList">
        <span class="flight-logo-container">
          <app-image-with-error
            title="{{ item.marketingCarrier + item.marketingFlightNumber }}"
            logoUrl="{{
              'https://images.sabscorp.com/images/TourOp/svg/' + item.marketingCarrier + '.svg'
            }}"
            alt="{{ item.marketingCarrier }} {{ item.marketingFlightNumber }}"
            errorIcon="ico-flight"
            class="ie-width-fix">
          </app-image-with-error>
        </span>
        <span
          >{{ item.marketingCarrier }}{{ item.marketingFlightNumber }}
          {{ item.originAirportName }} ({{ item.originAirport }})
          <time>{{ parseDateToMoment(item.dateTimeDeparting) | sabsTimePipe }} </time>
          {{ item.destinationAirportName }} ({{ item.destinationAirport }})
          <time>{{ parseDateToMoment(item.dateTimeArriving) | sabsTimePipe }}</time>
        </span>
      </div>
    </div>

    <ng-container *ngIf="flightItinerary; else flightItineraryDoesNotExist">
      <ng-container *ngTemplateOutlet="flightItineraryExists"></ng-container>
    </ng-container>

    <ng-template #flightItineraryExists>
      <div class="price-container">
        <span class="flight-price">
          {{ flightItinerary.total.price }} {{ flightItinerary.total.currency }}
        </span>
        <div class="flight-baggage">
          <b
            >{{ searchParams.getTotalBaggage(flightItinerary) }}
            <span *ngIf="searchParams.getTotalBaggage(flightItinerary) !== 1; else oneBag">
              <span i18n="@@bags">bags</span>
            </span>
            <ng-template #oneBag>
              <span i18n="@@bag">bag</span>
            </ng-template>
          </b>
        </div>
        <button class="small-button" (click)="addToBasket()" (keydown.enter)="addToBasket()">
          <span *ngIf="!displayItinerary" i18n="@@byScheduleAddtoBasket">Add to Basket</span>
          <span *ngIf="displayItinerary" i18n="@@byScheduleAddtoItinerary">Add to Itinerary</span>
        </button>
        <div class="flight-co2" *ngIf="flightItinerary.co2PerItem">
          {{ flightItinerary.co2PerItem }} <span i18n="@flight_kg">kg</span>&nbsp;
          <span i18n="@flight_leg_co2">CO</span><sub i18n="@flight_leg_2">2</sub>&nbsp;<span
            i18n="@flight_pp"
            >pp</span
          >
        </div>
      </div>
    </ng-template>

    <ng-template #flightItineraryDoesNotExist>
      <div class="price-container">
        <button
          class="small-button"
          (click)="getPrice()"
          (keydown.enter)="getPrice()"
          [disabled]="loadingPrice"
          i18n="@@byScheduleGetPrice">
          Get price
        </button>
      </div>
    </ng-template>
  </div>
</div>
