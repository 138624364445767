<!--
	Desc: Main Lightning Page - the first page after logon, can choose to logout, start a search or manage previous bookings
	Author: Ed Stansfield
	Date: 13/12/2017
-->
<app-results-header [blank]="true" [page]="'main'"></app-results-header>
<!-- this is just for sso -->
<app-flight-loading-screen></app-flight-loading-screen>
<div class="bg_login" *ngIf="!hasHomepage">
    <section class="home" id="main">
        <div class="home-panel">
            <div class="spacer-70">
                <h1 class="home-logo" i18n="@@mainTitle">Lightning</h1>
            </div>
            <div class="maincontent">
                <div class="spacer-30">&nbsp;</div>
                <div class="main-buttons spacer-70">
                    <!--logo-->
                    <!-- Two buttons, one to new booking, other to my bookings -->
                    <ul class="home-nav">
                        <li>
                            <span
                                class="nav-search"
                                routerLink="/search"
                                id="main-new-booking"
                                aria-labelledby="main-new-booking"
                                role="link"
                                (keydown.enter)="redirect('/search')">
                                <span i18n="@@mainNewBooking">New Booking</span>
                            </span>
                        </li>
                        <li>
                            <span
                                class="nav-bookings"
                                routerLink="/my-bookings"
                                id="main-my-booking"
                                aria-labelledby="main-my-booking"
                                i18n="@@mainMyBooking"
                                role="link"
                                (keydown.enter)="redirect('/my-bookings')"
                                >My Bookings</span
                            >
                        </li>
                        <li *ngIf="canBookGetMeThere">
                            <span
                                class="nav-bookings"
                                routerLink="/getmethere"
                                id="main-getMe-booking"
                                aria-labelledby="main-getMe-booking"
                                i18n="@@mainMyGetMeThere"
                                role="link"
                                (keydown.enter)="redirect('/getmethere')"
                                >Get Me There</span
                            >
                        </li>
                        <li>
                            <span
                                *ngIf="isAgency"
                                class="nav-bookings"
                                routerLink="/my-bookings/pnr"
                                id="main-pnr-import"
                                aria-labelledby="main-pnr-import"
                                i18n="@@mainPNRImport"
                                role="link"
                                (keydown.enter)="redirect('/my-bookings/pnr')"
                                >PNR Import Queue</span
                            >
                        </li>
                    </ul>
                </div>
                <div *ngIf="!showRobot" class="spacer-30">&nbsp;</div>
                <div class="trip-robot-logo spacer-30" *ngIf="showRobot">
                    <span
                        class="triprobot"
                        routerLink="/trip-robot"
                        title="trip robot"
                        tabindex="0"
                        role="link"
                        (keydown.enter)="redirect('/trip-robot')"
                        alt="Trip Robot">
                        <svg
                            viewBox="0 0 117 91"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            role="presentation"
                            focusable="false">
                            <g
                                id="Page-1"
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                                width="100px">
                                <g id="HD" transform="translate(-902.000000, -434.000000)">
                                    <g id="triprobot" transform="translate(902.000000, 437.000000)">
                                        <path
                                            d="M35.8604651,43 C39.6493954,43 42.7209302,45.9847683 42.7209302,49.6666667 L42.7209302,56.3333333 C42.7209302,60.0152317 39.6493954,63 35.8604651,63 C32.0715349,63 29,60.0152317 29,56.3333333 L29,49.6666667 C29,45.9847683 32.0715349,43 35.8604651,43 Z"
                                            id="lefteye"
                                            fill="#fff"></path>
                                        <path
                                            d="M81.1395349,43 C84.9284651,43 88,45.9847683 88,49.6666667 L88,56.3333333 C88,60.0152317 84.9284651,63 81.1395349,63 C77.3506046,63 74.2790698,60.0152317 74.2790698,56.3333333 L74.2790698,49.6666667 C74.2790698,45.9847683 77.3506046,43 81.1395349,43 Z"
                                            id="righteye"
                                            fill="#fff"></path>
                                        <path
                                            d="M35.372093,26.5576923 C21.0961744,26.5576923 9.52325581,38.1724591 9.52325581,52.5 C9.52325581,66.8275409 21.0961744,78.4423077 35.372093,78.4423077 L81.627907,78.4423077 C95.9038256,78.4423077 107.476744,66.8275409 107.476744,52.5 C107.476744,38.1724591 95.9038256,26.5576923 81.627907,26.5576923 L35.372093,26.5576923 Z M35.372093,17 L81.627907,17 C82.4511142,17 83.2677534,17.0282229 84.0769163,17.0837571 C102.469936,18.3461007 117,33.7200754 117,52.5 C117,72.1061086 101.163375,88 81.627907,88 L35.372093,88 C15.8366255,88 0,72.1061086 0,52.5 C0,32.8938914 15.8366255,17 35.372093,17 Z"
                                            id="head"
                                            fill="#fff"
                                            fill-rule="nonzero"></path>
                                        <path
                                            d="M76.5403643,13.4537245 L88.2419272,13.4537245 L85.5554831,16.0352871 L53.2374633,47.0890701 C52.7307618,47.5302367 52.0268425,47.5890129 51.4613078,47.3378581 C50.6064793,46.9582275 50.2212553,45.9575007 50.6050247,45.0934369 L57.2251525,30.4537245 L45.4572971,30.4537245 L63.1151149,-1.5 L88.5042993,-1.5 L76.5403643,13.4537245 Z"
                                            id="lightningedge"
                                            stroke="#FFFFFF"
                                            stroke-width="3"
                                            fill="#33766f"
                                            class="zap"></path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <span
                            id="aria-trip-robot"
                            aria-labelledby="aria-trip-robot"
                            hidden
                            i18n="@@AriaTripRobot"
                            tab-index="0"
                            >Trip Robot</span
                        >
                    </span>
                </div>
            </div>
        </div>
    </section>
</div>
<div class="homepage" *ngIf="hasHomepage" [innerHTML]="homepage | markdown"></div>
<!--
<div class="wcaglogo">
	<a alt="DAC DACCertification Two Tick, opens in new tab"
	   target="_blank"
	   href="http://digitalaccessibilitycentre.org/index.php/ctm-north-limited-online-booking-tool-lightning">
		<img src="https://images.sabscorp.com/images/logo/DAC_Certification_Two_Tick.jpg">
	</a>
</div>-->

<app-loading-screen *ngIf="becomingUser()">
    <span title i18n="@@mainBecomingUser">Becoming User ...</span>
</app-loading-screen>
<app-loading-screen *ngIf="loadingSSO">
    <span title i18n="@@mainLoadingSSO">Performing flight search ...</span>
</app-loading-screen>