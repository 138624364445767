<app-loading-screen *ngIf="searchService.search_loading" icon="custom_icon-eurostar" [animated]="animated">
    <span title i18n="@@eurostar_loading_title">Just loading your search results</span>
    <div msgOne>
        <span i18n="@@eurostar_loading_from">Trains From</span>
        {{ (searchParams.departLocation['destination'] || searchParams.departLocation) | titlecase }},
        {{ searchParams.departLocation['country'] }}
        <span i18n="@@eurostar_loading_to">to</span>
        {{ searchParams.arriveLocation['destination'] || searchParams.arriveLocation }},
        {{ searchParams.arriveLocation['country'] }}
    </div>
    <span msgTwo i18n="@@eurostar_loading_msg2"> Checking for availability </span>
</app-loading-screen>
