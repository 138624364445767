import { ProductLoadingScreen } from './product-loading-screen';
import { EnterpriseSearchService,ServiceType } from '@sabstravtech/obtservices/angular'
import { EurostarEnterpriseSearchInterface} from '@sabstravtech/obtservices/base'
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-eurostar-loading-screen',
    templateUrl: './eurostar-loading-screen.component.html',
    styleUrls: [],
  })
export class EurostarLoadingScreenComponent extends ProductLoadingScreen<EurostarEnterpriseSearchInterface> {
    @Input() animated: boolean = true;
    constructor(service: EnterpriseSearchService) {
        super(service, service.searches[ServiceType.Eurostar]);
    }
}