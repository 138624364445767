<button aria-controls="sorting-toggle_dropdown"
        [attr.aria-expanded]="open"
        class="sorting-toggle"
        (click)="toggle()"
        [class.sorting-toggle--open]="open"
        aria-label='Price sort by'>
  <i class="sorting-toggle_icon ico-sort-amount-2"
     [class.sorting-toggle_icon--desc]="!selectedSortType.dir"></i>{{selectedSortType.title| translate}}</button>
<ul class="sorting-toggle_dropdown background-80"
    *ngIf="open">
  <li *ngFor="let type of sortingTypes; let i = index"
      (click)="select(type)"
      (keydown.enter)="select(type)"
      (keydown.arrowdown)="moveArrowDown($event,i)"
      (keydown.arrowup)="moveArrowUp($event,i)"
      (keydown.esc)="toggle()"
      class="sorting-toggle_list-item"
      [class.sorting-toggle_list-item--active]="selectedSortType === type"
      (keydown.tab)="closeOnTabOut(i)"
      tabindex="0">
    {{type.title| translate}}
    <i class="{{type.icon}} sorting-toggle_list-icon"></i>
  </li>
</ul>