import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../vendor/components/base_components/base-componet';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import {
  UserDefaultManagementInfoValue,
  ServiceType,
  UserService,
  ManagementInfoUiPresentation,
  EmptyUserDefaultManagementValue,
  ManagementInfoDefaultValuePrepopulation
} from '@sabstravtech/obtservices/angular';
import { ServiceProvider } from '@sabstravtech/obtservices/base';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-profile-information-mi-default',
  templateUrl: './profile-information-mi-default.component.html',
  styleUrls: ['./profile-information-mi-default.component.scss']
})
export class ProfileInformationMiDefaultComponent extends BaseComponent implements OnInit {
  tabSelected = 0;
  @Input() miDefaultForm: FormGroup;
  @Input() isEditable: boolean;
  @Input() userMIs: UserDefaultManagementInfoValue[] = [];
  miCars: UserDefaultManagementInfoValue[];
  miFlights: UserDefaultManagementInfoValue[];
  miParkings: UserDefaultManagementInfoValue[];
  miHotels: UserDefaultManagementInfoValue[];
  miLounges: UserDefaultManagementInfoValue[];
  miRails: UserDefaultManagementInfoValue[];
  miEurostars: UserDefaultManagementInfoValue[];
  miInternationalRail: UserDefaultManagementInfoValue[];
  newMI = {} as UserDefaultManagementInfoValue;
  serviceType = ServiceType;
  faChevronDown = faChevronDown;
  defaultMiValue: UserDefaultManagementInfoValue[];
  ManagementInfoUiPresentation: typeof ManagementInfoUiPresentation = ManagementInfoUiPresentation;
  ServiceProvider: typeof ServiceProvider = ServiceProvider;
  serviceSelected: ServiceProvider = ServiceProvider.SabreCarHire;
  selectedMiValue: UserDefaultManagementInfoValue = null;
  emptyMiDefaults: EmptyUserDefaultManagementValue[] = [];
  isLoading = false;
  newMi: EmptyUserDefaultManagementValue = null;
  newMiValue = '';
  miPrepopulationTypes = ManagementInfoDefaultValuePrepopulation;
  null = null;

  constructor(
    title: Title,
    translateService: TranslateService,
    private fb: FormBuilder,
    private userService: UserService
  ) {
    super(title, translateService);
  }

  get userMiDefaultValues() {
    return this.miDefaultForm.controls['userMiDefaultValues'] as FormArray;
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(): void {
    this.isLoading = true;
    this.userService
      .getEmptyUserMiDefaultValues()
      .subscribe((data: EmptyUserDefaultManagementValue[]) => {
        if (data) {
          this.emptyMiDefaults = Object.values(
            data.reduce((acc, item) => {
              if (!acc[item.id]) {
                acc[item.id] = item;
              }
              return acc;
            }, {})
          );
        }
      });
    this.userService.getUseMiDefaultValues().subscribe(data => {
      this.isLoading = false;
      if (data) {
        this.defaultMiValue = data;
        this.setValue();
        if (!this.isEditable) {
          this.miDefaultForm.disable();
        }
      }
    });
  }

  setValue() {
    if (!this.userMiDefaultValues?.length) {
      this.miDefaultForm.setValue({
        userMiDefaultValues: []
      });
    } else {
      this.userMiDefaultValues.clear();
    }

    if (this.defaultMiValue.length) {
      this.defaultMiValue.forEach((mi: UserDefaultManagementInfoValue) => {
        this.addMiValue(mi);
      });
    }
  }

  addMiValue(mi: UserDefaultManagementInfoValue) {
    const miForm = this.fb.group({
      managementInfo: mi,
      freeTextValue: mi.value
    });
    this.userMiDefaultValues.push(miForm);
  }

  deleteMiValue(miValueId: number): void {
    this.userMiDefaultValues.removeAt(miValueId);
    this.userMiDefaultValues.markAsDirty();
  }

  addNewMiValue(miItem: UserDefaultManagementInfoValue): void {
    // before add item check if itemId exist in the list. if exist remove old record
    let userMiDefaultValues: UserDefaultManagementInfoValue[] = this.userMiDefaultValues.value;
    const filteredUserMiDefaultValues: UserDefaultManagementInfoValue[] =
      userMiDefaultValues.filter((mi: UserDefaultManagementInfoValue) => mi.id !== miItem.id);
    if (filteredUserMiDefaultValues.length !== userMiDefaultValues.length) {
      this.userMiDefaultValues.clear();
      filteredUserMiDefaultValues.forEach((mi: UserDefaultManagementInfoValue) => {
        this.addMiValue(mi);
      });
    }

    const miDefaultForm = this.fb.group({
      managementInfo: miItem,
      freeTextValue: miItem.value
    });

    this.userMiDefaultValues.push(miDefaultForm);
    this.miDefaultForm.markAsDirty();
  }

  saveMIValue(miItem: UserDefaultManagementInfoValue, newValue: string) {
    const newMI = {
      name: miItem.name,
      value: newValue,
      id: miItem.id
    };
    this.userService.setUserMIDefaultValue(newMI).subscribe(result => {
      this.loadData();
    });
  }

  saveEmptyMIValue() {
    const newMI = {
      id: this.newMi.id,
      name: this.newMi.name,
      value: this.newMiValue
    };
    this.userService.setEmptyUserMIDefaultValue(newMI).subscribe(result => {
      this.newMi = null;
      this.newMiValue = '';
      this.loadData();
    });
  }
}
