import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'flightRef',
})
export class FlightRefPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return value.split(':')[0];
  }
}
