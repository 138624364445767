import { Component, OnInit, ViewChild } from '@angular/core';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FlightItinerary, AirAvailabiltyResult, HelperRoutines } from '@sabstravtech/obtservices/angular';
import { ServiceProvider} from '@sabstravtech/obtservices/base';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-flight-reprice-modal',
  templateUrl: './flight-reprice-modal.component.html',
  styleUrls: ['./flight-reprice-modal.component.scss']
})
export class FlightRepriceModalComponent implements OnInit {

  flight: FlightItinerary = null;
  availability: AirAvailabiltyResult[] = null;
  legCopies: any[] = [];
  serviceProvider: typeof ServiceProvider = ServiceProvider;
  @ViewChild('repriceForm') repriceForm: NgForm = new NgForm([], []);

  constructor(
    public activeModal: NgbActiveModal,
    private helpers: HelperRoutines
  ) { }

  ngOnInit(): void {
    console.log('+++ Flight Reprice modal opened! +++');
    console.log(this.flight);
    console.log(this.availability);
    this.legCopies = this.helpers.clone(this.flight.outboundFlights.concat(this.flight.inboundFlights));
    console.log(this.repriceForm);
  }

  close(): void {
    this.activeModal.close(false);
  }
}

LightningModalTypes.FlightRepriceModalComponent.component = FlightRepriceModalComponent;