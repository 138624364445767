import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-encrypted-input',
  templateUrl: './encrypted-input.component.html',
  styleUrls: ['./encrypted-input.component.scss'],
  providers: [
    {
      provide: ControlContainer,
      multi: true,
      useExisting: FormGroupDirective 
    }
  ]
})
export class EncryptedInputComponent implements OnInit {

  control:FormControl
   @Input('control') set _control(value){
       this.control=value as FormControl
   }
   editMode: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
