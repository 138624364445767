import { LanguageEnum, Language } from '../vendor/interfaces/language-types';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';

export default class GermanLanguage extends Language {
  protected locale = localeDe;
  protected localeExtra = localeDeExtra;
  protected details = {
    image: 'images/flags/de.png',
    title: 'Deutsche',
    enum: LanguageEnum.German,
    aira: 'Deutsche Sprache gewählt',
    select: 'deutsche sprache wählen',
    abre: 'de',
  };
}
