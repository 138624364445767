<section  (mouseleave)="tt1.close()" *ngIf="!ticket.unavailable && !ticket.requiresReasonKeys?.length && displayCheck" class="container">
    <img
        src="{{ resultItemType.check }}"
        alt="Green check - Can book"
        class="error_icon"
        placement="top"
        container="body"
        triggers="manual"
        #tt1="ngbTooltip"
        ngbTooltip="Can Book"
        i18n-ngbTooltip
        (mouseenter)="tt1.open()"
        i18n-alt
        attr.aria-label="Can Book"
        (focus)="tt1.open()"
        (blur)="tt1.close()"
        tabindex="0" /><span class="visuallyhidden">Can book</span>
</section>

<section (mouseleave)="tt2.close()" *ngIf="ticket.unavailable" class="container">
    <img
        class="error_icon"
        src="{{ resultItemType.minus }}"
        placement="top"
        container="body"
        triggers="manual"
        #tt2="ngbTooltip"
        (mouseenter)="tt2.open()"
        alt="Red warning - Cannot book"
        i18n-alt
        ngbTooltip="{{ ticket.unavailableMessage }}"
        i18n-ngbTooltip
        attr.aria-label="{{ ticket.unavailableMessage }}"
        (focus)="tt2.open()"
        (blur)="tt2.close()"
        tabindex="0" /><span class="visuallyhidden">{{ ticket.unavailableMessage }}</span>
</section>
<section (mouseleave)="tt3.close()" *ngIf="ticket.requiresReasonKeys?.length && !isUnavailableOnly && !ticket.unavailable" class="container">
    <img
        class="error_icon"
        src="{{ resultItemType.exclamation }}"
        placement="top"
        container="body"
        triggers="manual"
        #tt3="ngbTooltip"
        (mouseenter)="tt3.open()"
        alt="Amber Warning - Requires Reason"
        i18n-alt
        ngbTooltip="{{ messages }}"
        i18n-ngbTooltip
        attr.aria-label="{{ messages }}"
        (focus)="tt3.open()"
        (blur)="tt3.close()"
        tabindex="0" /><span class="visuallyhidden">{{ messages }}</span>
</section>
