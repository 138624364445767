import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EnterpriseSearchService, UserService, ServiceType } from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-product-checkbox',
  templateUrl: './product-checkbox.component.html',
  styleUrls: ['./product-checkbox.component.scss']
})
export class ProductCheckboxComponent implements OnInit {
  @Input() service: ServiceType;
  @Output() selected: EventEmitter<ServiceType> = new EventEmitter<ServiceType>();

  constructor(public userService: UserService, public searchService: EnterpriseSearchService) {}

  ngOnInit() {}
}
