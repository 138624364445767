<div class="modal-header">
    <h4 class="modal-title"
        id="modal-basic-title"
        i18n="@@itemHotelsFacilities"
        id="modal-header">Facilities</h4>
    <button type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="flex-row-container flex-row-container--tb-margin flex-row-container--wrap">
        <section (mouseleave)="tt.close()"
                 *ngFor="let tag of hotel?.sortedTags"
                 class="container">
            <img class="preferred-container__icon list"
                 src="{{tag.icon}}"
                 height="24"
                 width="24"
                 triggers="manual"
                 container="body"
                 #tt="ngbTooltip"
                 (mouseenter)="tt.open()"
                 ngbTooltip="{{tag.display}}"
                 attr.aria-label="{{tag.display}}"
                 (focus)="tt.open()"
                 (blur)="tt.close()"
                 tabindex="0">
        </section>
    </div>
</div>
<div class="modal-footer">
    <button type="button"
            class="btn btn-outline-dark"
            (click)="activeModal.close()"
            i18n="@@itemHotelsClose">close</button>
</div>