<app-loading-screen *ngIf="searchService.search_loading" icon="ico-ferries"     [animated]="animated">
    <span title i18n="@@ferry_loading_title">Just loading your search results</span>
    <div msgOne>
        <span i18m="@@ferry_loading_from">Ferry From</span>
        {{ searchParams.fromLocation | titlecase }},
        <span i18m="@@ferry_loading_from">to</span>
        {{ searchParams.toLocation }},
    </div>
    <span msgTwo i18n="@@ferry_loading_msg2"> Checking for availability </span>
</app-loading-screen>
