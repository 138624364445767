import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WithSubscriptionComponent, EnterpriseSearchService, ServiceType } from '@sabstravtech/obtservices/angular';
import {
    FlightEnterpriseSearchInterface,
    RailEnterpriseSearchInterface,
    FlightDirectionEnum
} from '@sabstravtech/obtservices/base';
import { values } from 'lodash';

@Component({
    selector: 'app-carbon-comparison',
    templateUrl: './carbon-comparison.component.html',
    styleUrls: ['./carbon-comparison.component.scss']
})
export class CarbonComparisonComponent implements OnInit {
    flightSearchParams: FlightEnterpriseSearchInterface;
    railSearchParams: RailEnterpriseSearchInterface;
    lowestCarbonFlight: number;
    lowestCarbonRail: number;
    @Output() valueCalculated: EventEmitter<number> = new EventEmitter<number>();
    @Input() carbonPercentage;

    constructor(private readonly searchService: EnterpriseSearchService) {

    }

    ngOnInit(): void {
        this.flightSearchParams = this.searchService.searches[ServiceType.Flight];
        this.railSearchParams = this.searchService.searches[ServiceType.Rail];
        this.lowestCarbonFlight = this.getLowestCarbonFlight();
        this.lowestCarbonRail = this.getLowestCarbonRail();
        if (this.lowestCarbonFlight > this.lowestCarbonRail) {
            let percentageSaved = (1 - (this.lowestCarbonRail / this.lowestCarbonFlight)) * 100;
            this.valueCalculated.emit(percentageSaved);
        }
    }

    getLowestCarbonFlight(): number {
        try {
            const journeys = this.flightSearchParams.fullResults.map((flight) => {
                return flight?.journeys;
            });

            let lowestJourneys = journeys[0].length ? journeys[0].reduce(
                (prev, curr) => prev.co2PerItem < curr.co2PerItem ? prev : curr
            ).co2PerItem : 0;
            return lowestJourneys;
        } catch {
            return 0;
        }

    }

    getLowestCarbonRail(): number {
        try {
            if (this.railSearchParams.rawResults[0].return.length) {
                let lowestOutboundCarbon = this.railSearchParams.rawResults[0].outbound.reduce(
                    (prev, curr) => prev.co2PerItem < curr.co2PerItem ? prev : curr).co2PerItem;
                let lowestInboundCarbon = this.railSearchParams.rawResults[0].return.reduce(
                    (prev, curr) => prev.co2PerItem < curr.co2PerItem ? prev : curr).co2PerItem;
                return lowestOutboundCarbon + lowestInboundCarbon;
            } else {
                return this.railSearchParams.rawResults[0].outbound.reduce(
                    (prev, curr) => prev.co2PerItem < curr.co2PerItem ? prev : curr).co2PerItem;
            }
        } catch {
            return 0;
        }
    }

    getCarbonStyle(): string {
        let lowerValue = Math.min(this.lowestCarbonFlight, this.lowestCarbonRail);
        let higherValue = Math.max(this.lowestCarbonFlight, this.lowestCarbonRail);
        let percentage = ((lowerValue / higherValue) * 100).toFixed();
        return 'calc(' + percentage.toString() + '% - ' + (60 * (lowerValue / higherValue)).toFixed() + 'px)';
    }
}
