<div class="profile_information--content">
    <div class="flex-row-container profile_information--sub-tabs">
        <button type="button"
                class="small-button"
                [ngClass]="{ 'profile_information--sub-tab-selected': tabSelected === 0 }"
                (click)="showTabContent(0)" i18n="@@ProfileInformation-Air">Air</button>
        <button type="button"
                class="small-button"
                [ngClass]="{ 'profile_information--sub-tab-selected': tabSelected === 1 }"
                (click)="showTabContent(1)" i18n="@@ProfileInformation-Rail">Rail</button>
        <button type="button"
                class="small-button"
                [ngClass]="{ 'profile_information--sub-tab-selected': tabSelected === 2 }"
                (click)="showTabContent(2)" i18n="@@ProfileInformation-Hotel">Hotel</button>
        <button type="button"
                class="small-button"
                [ngClass]="{ 'profile_information--sub-tab-selected': tabSelected === 3 }"
                (click)="showTabContent(3)" i18n="@@ProfileInformation-Car">Car</button>
    </div>

    <div [formGroup]="addNewLoyaltyForm" >
        <div class="profile_information--input-container">
            <span class="profile_information--docs-loyalty-label">
                {{addNewLoyaltyForm.get('type').value | translate | titlecase }}
            </span>
            <div class="profile_information--docs-loyalty-input-1" >
                <select class="profile_information--wrapped-input" formControlName="carrier">
                    <option value="" i18n="@@ProfileInformation-Select">Select...</option>
                    <option  *ngFor="let data of tabData" [value]="data.value">
                        {{data.label}}
                    </option>
                </select>
                <fa-icon [icon]="faChevronDown"></fa-icon>
            </div>
            <input class="profile_information--docs-loyalty-input-2" i18n-placeholder placeholder="Enter code" formControlName="code">
        </div>
    </div>

    <div class="profile_information--add-new-container">
        <button type="button" [disabled]="!isEditable" [class.disable-button]="!isEditable" (click)="addNewLoyalty()" class="small-button" i18n="@@ProfileInformation-AddNew">Add New</button>
    </div>
</div>

<div [formGroup]="loyaltiesForm" *ngIf="user && loyaltiesForm" class="profile_information--content">
    <div formArrayName="loyaltyCodes">
        <div class="profile_information--content">
            <div class="profile_information--sub-header" i18n="@@ProfileInformation-Loyalties">
                Loyalties
            </div>
            <table *ngIf="loyaltyCodes.length; else noLoyalties">
                <thead class="profile_information--table-thead">
                    <tr>
                        <th></th>
                        <th i18n="@@ProfileInformation-LoyaltyProgram">Loyalty Program</th>
                        <th i18n="@@ProfileInformation-Carrier">Carrier</th>
                        <th i18n="@@ProfileInformation-LoyaltyCode">Loyalty Code</th>
                        <th i18n="@@ProfileInformation-Delete">Delete</th>
                    </tr>
                </thead>
                <tbody class="profile_information--table-tbody">
                    <tr *ngFor="let loyalty of loyaltyCodes.controls; let i = index" [formGroupName]="i">
                        <td>
                            <span *ngIf="loyalty.get('type').value === serviceType.Hotel"><i class="ico-bed"></i></span>
                            <span *ngIf="loyalty.get('type').value === serviceType.Flight"><i class="ico-flight"></i></span>
                            <span *ngIf="loyalty.get('type').value === serviceType.Rail"><i class="ico-rail"></i></span>
                            <span *ngIf="loyalty.get('type').value === serviceType.Car"><i class="ico-car"></i></span>
                        </td>
                        <td>{{loyalty.get('type').value}} <span i18n="@@ProfileInformation-LOYALTYPROGRAMS">LOYALTY PROGRAMS</span></td>
                        <td>{{loyalty.get('carrier').value}}</td>
                        <td>{{loyalty.get('code').value}}</td>
                        <td><button class="mat-stroked-button" [disabled]="!isEditable"  [class.disable-button]="!isEditable" (click)="deleteLoyalty(i)" i18n="@@ProfileInformation-Delete">Delete</button></td>
                    </tr>
                </tbody>
            </table>

            <ng-template #noLoyalties>
                <p class="no-data-container" i18n="@@ProfileInformation-No-loyalties-to-display">No loyalties to display</p>
            </ng-template>
        </div>
    </div>
</div>
