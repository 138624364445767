import { Component, OnInit } from '@angular/core';
import { DeviceDetector } from '../../../vendor/services/device-detector.service';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../../vendor/components/base_components/base-componet';

import {
  EnterpriseSearchService,
  ModalOpenerService,
  ServiceType,
  RailSearchJourneyType,
} from '@sabstravtech/obtservices/angular';
import { RailEnterpriseSearchInterface, CodeName } from '@sabstravtech/obtservices/base';
import { LightningModalTypes } from '../../../vendor/classes/modal-types.enum';
import { BehaviorSubject, combineLatest } from 'rxjs';

@Component({
  selector: 'app-airport-express-search',
  templateUrl: './airport-express-search.component.html',
  styleUrls: ['./airport-express-search.component.scss'],
})
export class AirportExpressSearchComponent
  extends BaseComponent
  implements OnInit {
  RailJourneyType: typeof RailSearchJourneyType = RailSearchJourneyType;
  searchParams: RailEnterpriseSearchInterface;
  ServiceType: typeof ServiceType = ServiceType;
  loadingRailStation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(
    public deviceDetector: DeviceDetector,
    private modalService: ModalOpenerService,
    public searchService: EnterpriseSearchService,
    title: Title,
    translate: TranslateService
  ) {
    super(title, translate);
  }

  ngOnInit(): void {
    this.searchParams = this.searchService.searches[ServiceType.Rail];
  }

  /**
   * @description - get the list of stations for airport express
   */
  getAirportExpressStations(): CodeName[] {
    return this.searchParams.getAirportExpressStations();
  }

  /**
   * @description - when the user changes the station dropdown get the departure and arrival station id from the provided stationCode
   * @param stationCode - the 3 character station code e.g. HXX, PAD
   */
  onStationChange(stationCode: string): void {
    const airportExpressStationsCodes =
      this.searchParams.airportExpressStationsCodes;
    if (airportExpressStationsCodes[stationCode]) {
      const { dep, arr } = airportExpressStationsCodes[stationCode];
      combineLatest(
        this.searchService.searches[ServiceType.Rail].getRailStation(
          dep,
          this.loadingRailStation
        ),
        this.searchService.searches[ServiceType.Rail].getRailStation(
          arr,
          this.loadingRailStation
        ),
      ).subscribe(([departureStation, arrivalStation]) => {
        this.searchService.searches[
          ServiceType.Rail
        ].setAirportExpressStationIds({
          departureLocationId: departureStation.id,
          arrivalLocationId: arrivalStation.id,
        });
      });
    } else {
      this.modalService.open(
        LightningModalTypes.ModalErrorComponent,
        { centered: true },
        {
          error: `Station code ${stationCode} appears to be invalid! Must be a 3 character code.`,
        }
      );
      // TODO: show loading spinner somewhere in UI or disable the form fields when request(s) are in progress
    }
  }
}
