import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';
import { EurostarClassEnum } from '@sabstravtech/obtservices/base';
@Component({
  selector: 'app-fare-rules-modal',
  templateUrl: './fare-rules-modal.component.html',
  styleUrls: ['./fare-rules-modal.component.scss']
})
export class ModalFareRulesComponent implements OnInit {
  @ViewChild('standardHeader') standardHeader!: ElementRef;
  readonly standard = {
    rules: [
      '2 pieces of luggage + 1 hand luggage',
      'Change journey with a fee',
      'Arrive at station 45 - 60 mins before departure',
      'Buy snacks, drinks and meals on board'
    ],
    // tslint:disable-next-line:max-line-length
    fareConditions: `You can change the date or time of your journey any time before departure by paying a fee and any difference in fare. These tickets are non-refundable.`
  };

  readonly standardPremier = {
    rules: [
      '2 pieces of luggage + 1 hand luggage',
      'Change journey with a fee',
      'Arrive at station 45 - 60 mins before departure',
      'Light meal and drinks served at your seat',
      'Spacious seats'
    ],
    // tslint:disable-next-line:max-line-length
    fareConditions: `You can change the date or time of your journey any time before departure by paying a fee and any difference in fare. These tickets are non-refundable.`
  };

  readonly businessPremier = {
    rules: [
      '3 pieces of luggage + 1 hand luggage',
      'No change fees and free cancellation',
      'Ticket gate closes 10 mins before departure',
      'Hot meals designed by Raymond Blanc, served with champagne',
      'Spacious seats',
      'Exclusive Lounge',
      'Taxi booking service'
    ],
    fareConditions: `You can change your tickets free of charge up to 60 days after your planned departure.`
  };

  selectedFare: EurostarClassEnum = null;
  EurostarClassEnum = EurostarClassEnum;
  
  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (this.standardHeader) {
      this.standardHeader.nativeElement.focus();
    }
  }

  close(): void {
    this.modal.close();
    // Then focus back to the fare bucket
    if (document.getElementById('fareRulesStand')) {
      document.getElementById('fareRulesStand').focus();
    } else if (document.getElementById('fareRulesStandPrem')) {
      document.getElementById('fareRulesStandPrem').focus();
    } else if (document.getElementById('fareRulesBus')) {
      document.getElementById('fareRulesBus').focus();
    } else if (document.getElementById('fare-rules-icon')) {
      document.getElementById('fare-rules-icon').focus();
    }
  }
}

LightningModalTypes.ModalFareRulesComponent.component = ModalFareRulesComponent;
