
<div class="thirds-container" >

    <div class="thirds-block flex-column-container">
        <label i18n="@@railTravellerInfoName" class="header-label">Name</label>
        <input [(ngModel)]="traveller.name" (ngModelChange)="searchParams.travellersUpdate()" class="input-box" type="text" name="travellerNameInput" aria-label="Enter Traveller Name" placeholder="Enter Traveller Name" required>
    </div>

    <div class="thirds-block flex-column-container">
        <label i18n="@@railTravellerInfoAge" class="header-label">Age Range</label>
        <select [(ngModel)]="traveller.ageRange" (ngModelChange)="searchParams.travellersUpdate()" class="select-box" aria-label="Enter Traveller Age" required name="travellerAgeInput">
            <option *ngFor="let ageRange of searchParams.trainlineAgeRanges" [value]="ageRange.value">{{ageRange.display}} </option>
        </select>
        <section [hidden]="traveller.ageRange != 'youth'">
            <label i18n="@@railExactAge">Exact Age</label>
            <input  class="input-box" [ngClass]="{'ng-invalid': ageInvalid}" type="string" (ngModelChange)="updateAge($event);searchParams.travellersUpdate()" placeholder="Enter exact age." aria-label="Exact age" [(ngModel)]="traveller.age"/>
        </section>
    </div>

    <div class="thirds-block flex-column-container">
    <label i18n="@@railTravellerInfoLoyalty" class="header-label">Loyalty and Discount</label>
    <ng-container *ngFor="let loyalty of traveller.loyalties; let i = index">
        <div class="thirds-container">
                <section class="loyalty-section-container">
                    <select [(ngModel)]="loyalty.loyaltyCode" (ngModelChange)="searchParams.travellersUpdate();railCardSelected($event, traveller, i)" class="select-box" aria-label="Enter Loyalty Scheme" name="travellerLoyaltyInput">
                        <option [value]="''">Select a loyalty scheme</option>
                        <option *ngFor="let card of railCards" [value]="card.code">{{card.name}}</option>
                    </select>
                    <section *ngIf="loyalty.loyaltyNumberRequired" class="loyalty-section">
                        <label i18n="@@loyaltyNumber">Loyalty or Discount Number</label>
                        <input [(ngModel)]="loyalty.loyaltyNumber" (ngModelChange)="searchParams.travellersUpdate();" aria-label="Loyalty Number" class="input-box" [ngClass]="{'input-prefix': loyalty.loyaltyPrefix}" placeholder="Enter Card Number" pattern="{{loyalty.loyaltyPattern}}" required/>
                        <span class="loyalty-prefix" *ngIf="loyalty.loyaltyPrefix">{{loyalty.loyaltyPrefix}}</span>
                    </section>
                </section>
                <button class="delete-button" (click)="removeCard(i)" aria-label="Remove Card" i18n="@@removeLoyaltyCard"><i class="ico-cancel" aria-hidden="true"></i></button>
        </div>
        
        
        
    </ng-container>
    <div class="flex-row-container flex-row-container--right">
        <button class="link-button add-card" (click)="addNewCard()" aria-label="Add another Loyalty/Discount" i18n="@@addCard">Add another Loyalty/Discount</button>
    </div>
    
    </div>

</div>

