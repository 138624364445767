import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Country } from '@sabstravtech/obtservices/base';

@Component({
  selector: 'app-search-country',
  templateUrl: './search-country.html',
  styleUrls: ['./search-country.scss']
})
export class SearchCountryComponent {
  @Input() countries: Country[];
  @Input() country: Country;
  @Output() countryChange = new EventEmitter<Country>();

  @Input() title: string;

  constructor() { }

  onChanged(newvalue): void {
    this.countryChange.emit(newvalue);
  }
}
