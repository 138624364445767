<div cdkTrapFocus
     aria-live="assertive">
  <div class="modal-header">
    <h3 class="modal-title"
        id="modal-header"
        i18n="@@modalConfirmCardTitle">Confirm New Payment Card</h3>
  </div>
  <div class="modal-body">
    <p i18n="@@modalConfirmCardClickOK">
      Click ok to select newly added card:
    </p>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="modal_button_okay"
            (click)="activeModal.close(true)"
            i18n="@@modalConfirmCardOK"
            aria-label="Confirm New Payment Card"
            ngbAutoFocus>Ok</button>
  </div>
</div>