import { Pipe, PipeTransform } from '@angular/core';

const CITIES = {
  Aachen: ['AAW', 'AAH', 'XHJ'],
  Aalborg: ['AAL', 'ZTG'],
  Aalesund: ['AES'],
  Aappilattoq: ['AOQ'],
  'Aappilattoq Nanortalik': ['QUV'],
  AARAU: ['ZDA'],
  Aarhus: ['ZID', 'ZJH', 'AJH', 'AAR'],
  Aasiaat: ['JEG'],
  Abadan: ['ABD'],
  Abakan: ['ABA'],
  Abbotsford: ['YXX'],
  Abbs: ['EAB'],
  Aberdeen: ['ZXA', 'ABR', 'ABZ', 'APG'],
  Abha: ['AHB'],
  Abidjan: ['ABJ'],
  Abilene: ['ABI', 'DYS'],
  Abingdon: ['VJI'],
  'Abu Dhabi': ['ZVJ', 'XBW', 'DHF', 'AZI', 'AUH'],
  'Abu Simbel': ['ABS'],
  Abuja: ['ABV'],
  Acandi: ['ACD'],
  Acapulco: ['ACA'],
  Accra: ['ACC'],
  'Ada OK': ['ADT'],
  ADADO: ['AAD'],
  'Adak Island': ['ADK'],
  Adana: ['ADA', 'UAB'],
  'Addis Ababa': ['ADD'],
  Adelaide: ['ADL', 'XHX'],
  Aden: ['ADE'],
  Adiyaman: ['ADF'],
  Adrian: ['ADG'],
  Aershan: ['YIE'],
  Afyon: ['AFY'],
  Agadir: ['AGA'],
  Agartala: ['IXA'],
  'Agatti Island': ['AGX'],
  Agen: ['AGF'],
  Agra: ['AGR'],
  Agri: ['AJI'],
  Agrinion: ['AGQ'],
  Aguadilla: ['BQN'],
  Aguascalientes: ['AGU'],
  'AHE Atoll': ['AHE'],
  Ahmedabad: ['AMD'],
  Ahwaz: ['AWZ'],
  Aigle: ['ZDC'],
  Aiken: ['AIK'],
  Ainsworth: ['ANW'],
  'Airlie Beach': ['WSY'],
  Aitape: ['ATP', 'TAJ'],
  Aitutaki: ['AIT'],
  'Aix En Provence': ['QXB'],
  Aizawl: ['AJL'],
  Ajaccio: ['AJA'],
  Akhiok: ['AKK'],
  Akiachak: ['KKI'],
  Akita: ['AXT'],
  Aklavik: ['LAK'],
  Akola: ['AKD'],
  Akron: ['AKC', 'CAK', 'AKO'],
  Aksu: ['AKU'],
  Aktau: ['SCO'],
  Akulivik: ['AKV'],
  Akureyri: ['AEY'],
  'Al Ain': ['AAN', 'ZVH'],
  'Al Baha': ['ABT'],
  'Al Fujairah': ['FJR'],
  'Al Ghaydah': ['AAY'],
  'Al Hoceima': ['AHU'],
  'Al Jouf': ['AJF'],
  'Al Najaf': ['NJF'],
  'Al No': ['XXR'],
  'Al Ula': ['ULH'],
  Alamogordo: ['ALM', 'HMN'],
  Alamos: ['XAL'],
  Alamosa: ['ALS'],
  Albacete: ['ABC', 'XJJ', 'EEM'],
  Albany: ['ALH', 'ALB', 'ZLY', 'ABY'],
  Albenga: ['ALL'],
  'Albert Lea': ['AEL'],
  Albi: ['LBI'],
  Albuquerque: ['ABQ'],
  Albury: ['ABX'],
  Aldan: ['ADH'],
  Alderney: ['ACI'],
  Aldershot: ['XLY'],
  Alegrete: ['ALQ'],
  Aleneva: ['AED'],
  Aleppo: ['ALP'],
  'Alexander Bay': ['ALJ'],
  'Alexander City': ['ALX'],
  Alexandra: ['ALR'],
  Alexandria: ['AXN', 'AEX', 'XFS', 'ALY', 'ESF'],
  Alexandroupolis: ['AXD'],
  Algeciras: ['AEI', 'AXI'],
  Alghero: ['AHO'],
  Algiers: ['ALG'],
  Alicante: ['ALC', 'YJE'],
  Alice: ['ALI'],
  'Alice Springs': ['ASP', 'XHW'],
  Aliceville: ['AIV'],
  Allahabad: ['IXD'],
  Allentown: ['ABE'],
  Alliance: ['AIA'],
  'Alluitsup Paa': ['QFJ', 'LLU'],
  Alma: ['YTF'],
  Almaty: ['ALA'],
  Almelo: ['QYL'],
  Almenara: ['AMJ'],
  Almeria: ['LEI', 'AQL', 'AMR'],
  Along: ['IXV'],
  'Alor Setar': ['AOR'],
  Alotau: ['GUR'],
  Alpena: ['APN'],
  Alpine: ['ALE'],
  Alta: ['ALF'],
  'Alta Floresta': ['AFL'],
  Altamira: ['ATM'],
  Altay: ['AAT'],
  Altenburg: ['AOC'],
  Altenrhein: ['ACH'],
  Altoona: ['AOO'],
  Altus: ['LTS', 'AXS'],
  Alvdal: ['XJA'],
  Alverca: ['AVR'],
  Alvesta: ['XXA'],
  'Alxa Right Banner': ['RHT'],
  Amahai: ['AHI'],
  Amakusa: ['AXJ'],
  'Amami O Shima': ['ASJ'],
  Amarillo: ['AMA', 'TDW'],
  Amasya: ['MZH'],
  Ambler: ['ABL'],
  Ambon: ['AMQ'],
  Amersfoort: ['QYM'],
  Ames: ['AMW'],
  Amherst: ['XZK'],
  Amman: ['ADJ', 'AMM'],
  Ammassivik: ['QUW'],
  'Amook Bay': ['AOS'],
  Amos: ['YEY'],
  Ampara: ['AFK'],
  Amritsar: ['ATQ'],
  Amsterdam: ['ZYA', 'AMS'],
  Anaa: ['AAA'],
  Anadyr: ['DYR'],
  'Anahim Lake': ['YAA'],
  Anapa: ['AAQ'],
  Anapolis: ['APS'],
  Anchorage: ['ANC', 'FRN', 'EDF', 'MRI'],
  Ancona: ['AOI'],
  Ancortes: ['OTS'],
  Andenes: ['ANX'],
  Anderson: ['AND', 'AID'],
  Andizhan: ['AZN'],
  'Andorra La Vella': ['ALV'],
  'Andros Town': ['ASD'],
  Aneityum: ['AUY'],
  Angama: ['ANA'],
  'Angel Fire': ['AXX'],
  Angelholm: ['AGH', 'XYQ'],
  Angers: ['QXG', 'ANE'],
  Angoon: ['AGN'],
  Angouleme: ['ANG'],
  Anguilla: ['AXA'],
  Aniak: ['ANI'],
  'Anita Bay': ['AIB'],
  Ankang: ['AKA'],
  Ankara: ['ESB', 'ANK'],
  'Ann Arbor': ['ARB'],
  'Ann MM': ['VBA'],
  Annaba: ['AAE'],
  Annapolis: ['ANP'],
  Annecy: ['NCY'],
  'Annette Island': ['ANN'],
  Anniston: ['ANB', 'QAW', 'RLI'],
  Annobon: ['NBN'],
  Ansbach: ['QOB'],
  Anshan: ['AOG', 'VBL'],
  Anshun: ['AVA'],
  Anta: ['ATA'],
  Antalya: ['AYT'],
  Antananarivo: ['TNR'],
  Antigua: ['ANU'],
  Antofagasta: ['ANF'],
  Antwerp: ['ZYZ', 'ANR', 'ZWE', 'ZAY'],
  Anyang: ['AYN'],
  Aomori: ['AOJ'],
  Aosta: ['AOT'],
  Apalachicola: ['AAF'],
  Apartado: ['APO'],
  Apatzingan: ['AZG'],
  Apeldoorn: ['QYP'],
  Apia: ['APW', 'FGI'],
  Appenzell: ['ZAP'],
  Appleton: ['ATW'],
  Applevalley: ['APV'],
  Aqaba: ['AQJ'],
  Aqtobe: ['AKX'],
  Aracaju: ['AJU'],
  Aracatuba: ['ARU'],
  Arad: ['ARW'],
  Araguaina: ['AUX'],
  Aranuka: ['AAK'],
  Arapoti: ['AAG'],
  Arar: ['RAE'],
  Araracuara: ['ACR'],
  Ararangua: ['XRB'],
  Araraquara: ['AQA'],
  Ararat: ['ARY'],
  'Arathusa Safari Lodge': ['ASS'],
  Arauca: ['AUC'],
  Araxa: ['AAX'],
  'Arba Minch': ['AMH'],
  Arboga: ['XXT'],
  Arbon: ['ZDD'],
  Arcata: ['ACV'],
  'Arctic Bay': ['YAB'],
  'Arctic Village': ['ARC'],
  Ardabil: ['ADU'],
  Ardmore: ['AMZ', 'ADM', 'AHD'],
  Arequipa: ['AQP'],
  Arica: ['ARI'],
  Ariquemes: ['AQM'],
  Arkhangelsk: ['ARH'],
  Arles: ['ZAF'],
  Armenia: ['AXM'],
  Armidale: ['ARM'],
  Armstromg: ['YYW'],
  Arna: ['XHT'],
  'Arnhem City': ['ZYM'],
  Arona: ['RNA'],
  Arosa: ['ZDE'],
  Arrabury: ['AAB'],
  Arras: ['QRV'],
  Arsuk: ['JRK'],
  'Arthurs Town': ['ATC'],
  Artigas: ['ATI'],
  Aruba: ['AUA'],
  Arusha: ['ARK'],
  Arviat: ['YEK'],
  Arvidsjaur: ['AJR'],
  Arvika: ['XYY'],
  Asaba: ['ABB'],
  Asahikawa: ['AKJ'],
  Asaloyeh: ['PGU'],
  Asau: ['AAU'],
  'Ascension Island St Helena': ['ASI'],
  Aschaffenburg: ['ZCB'],
  Ascona: ['ACO'],
  Ashcroft: ['YZA'],
  Asheville: ['AVL'],
  Ashford: ['QDH'],
  Ashgabat: ['ASB'],
  Asmara: ['ASM'],
  Asosa: ['ASO'],
  Aspen: ['ASE'],
  Assiut: ['ATZ'],
  Astana: ['TSE'],
  Astoria: ['AST'],
  Astrakhan: ['ASF'],
  Asturias: ['OVD', 'OVI'],
  'Astypalaia Island': ['JTY'],
  Asuncion: ['ASU'],
  Aswan: ['ASW'],
  Atammik: ['QJF'],
  Atar: ['ATR'],
  Athens: ['AHN', 'ATH', 'MMI', 'ATO'],
  Atibaia: ['ZBW'],
  Atikokan: ['YIB'],
  Atka: ['AKB'],
  Atlanta: ['PDK', 'FTY', 'ATL'],
  Atlantic: ['AIO'],
  'Atlantic City': ['ACY', 'ZRA', 'AIY', 'JSL'],
  Atoifi: ['ATD'],
  Atqasuk: ['ATK'],
  Atsugi: ['NJA'],
  Attawapiskat: ['YAT'],
  Attu: ['QGQ'],
  'Attu Island': ['ATU'],
  Atuona: ['AUQ'],
  Atyrau: ['GUW'],
  'Aua Island': ['AUI'],
  Aubenas: ['OBS'],
  AUBURN: ['AUN'],
  Auburn: ['AUO'],
  Auckland: ['AKL', 'MHB'],
  'Aue De': ['ZAU'],
  Augsburg: ['AUB', 'AGY', 'AGB'],
  Augusta: ['AGS', 'DNL', 'AUG'],
  Auki: ['AKS'],
  Aupaluk: ['YPJ'],
  Aurangabad: ['IXU'],
  Aurillac: ['AUR'],
  Aurora: ['AUZ'],
  'Aurukun Mission': ['AUU'],
  Austin: ['AUM', 'AUS', 'MMR'],
  Auxerre: ['AUF'],
  'Avesta Krylbo': ['XYP'],
  Aviano: ['AVB'],
  Avignon: ['AVN', 'XZN'],
  Avila: ['AVJ', 'AVS'],
  AWABA: ['AWB'],
  Axum: ['AXU'],
  Ayacucho: ['AYP'],
  'Ayers Rock': ['AYQ'],
  'Ayr Au': ['AYR'],
  Bacau: ['BCM'],
  Bacolod: ['BCD'],
  Badajoz: ['BQZ', 'BJZ'],
  Baden: ['ZDG'],
  'Badu Island': ['BDD'],
  Bagdad: ['BGT'],
  Bagdogra: ['IXB'],
  Baghdad: ['BGW'],
  Bagotville: ['YBG'],
  Bagram: ['OAI'],
  Baguio: ['BAG'],
  'Bahar Dar': ['BJR'],
  Bahawalpur: ['BHV'],
  'Bahia Blanca': ['BHI'],
  'Bahia De Los Angeles': ['BHL'],
  'Bahia Pina': ['BFQ'],
  'Bahia Solano': ['BSC'],
  Bahrain: ['BAH'],
  'Baia Mare': ['BAY'],
  Baicheng: ['DBC'],
  'Baie Comeau': ['YBC'],
  Bairnsdale: ['BSJ'],
  Baise: ['AEB'],
  Baishan: ['NBS'],
  Bakalalan: ['BKM'],
  Baker: ['BKE'],
  'Baker Lake': ['YBK'],
  Bakersfield: ['BFL'],
  Baku: ['GYD', 'ZXT'],
  Balalae: ['BAS'],
  Balikesir: ['BZI'],
  Balikpapan: ['BPN'],
  Balimo: ['OPU'],
  Balimuru: ['VMU'],
  Ballina: ['BNK'],
  Balmaceda: ['BBA'],
  Baltimore: ['BWI'],
  Balurghat: ['RGH'],
  Bamaga: ['ABM'],
  Bamako: ['BKO'],
  'Banda Aceh': ['BTJ'],
  'Bandar Abbas': ['BND'],
  'Bandar Lampung': ['TKG'],
  'Bandar Seri Begawan': ['BWN'],
  Bandung: ['BDO'],
  Banff: ['YBA'],
  Bangkok: ['DMK', 'BKK'],
  Bangor: ['BGR'],
  Bangui: ['BGF'],
  'Banja Luka': ['BNX'],
  Banjarmasin: ['BDJ'],
  Bankstown: ['BWU'],
  Banmethuot: ['BMV'],
  Banning: ['BNG'],
  Banyuwangi: ['BWX'],
  Baoding: ['BVQ'],
  Baoshan: ['BSD'],
  Baotou: ['BAV'],
  'Bar Harbor': ['BHB'],
  'Bar River': ['YEB'],
  Baracoa: ['BCA'],
  Barahona: ['BRX'],
  Baranof: ['BNF'],
  Barbacena: ['QAK'],
  Barbelos: ['BAZ'],
  Barcaldine: ['BCI'],
  Barcelona: ['BCN', 'XJB', 'YJD', 'YJB', 'BLA'],
  Bardufoss: ['BDU'],
  Bari: ['BRI', 'BAU'],
  Barinas: ['BNS'],
  Bario: ['BBN'],
  Barisal: ['BZL'],
  Barnaul: ['BAX'],
  Barquisimeto: ['BRM'],
  Barra: ['BRR'],
  Barrancabermeja: ['EJA'],
  Barranquilla: ['BAQ'],
  Barreiras: ['BRA'],
  Barretos: ['BAT'],
  Barrow: ['BRW', 'PBA'],
  'Barrow In Furness': ['BWF'],
  Barth: ['BBH'],
  Bartlesville: ['BVO'],
  Bartow: ['BOW'],
  Basco: ['BSO'],
  Basel: ['BSL', 'ZDH'],
  Basingstoke: ['XQB'],
  Basra: ['BSR'],
  'Basse Terre': ['BBR'],
  Bastia: ['BIA'],
  Bata: ['BSG'],
  Batagay: ['BQJ'],
  Batam: ['BTH'],
  Batesville: ['BVX', 'HLB'],
  Bath: ['QQX'],
  Bathurst: ['ZBF', 'BHS'],
  'Bathurst Isl': ['BRT'],
  Batman: ['BAL'],
  Batna: ['BLJ'],
  'Baton Rouge': ['BTR'],
  Batsfjord: ['BJF'],
  Battambang: ['BBM'],
  Batticaloa: ['BTC'],
  'Battle Creek': ['BTL'],
  Batumi: ['BUS'],
  Baubau: ['BUW'],
  Baucau: ['BCH'],
  Bauru: ['JTC'],
  Bawean: ['BXW'],
  Bayamo: ['BYM'],
  Bayannur: ['RLK'],
  Baykonur: ['BXY'],
  Bayreuth: ['BYU'],
  Baytown: ['HPY'],
  'Bear Creek': ['BCC'],
  'Bearskin Lake Indian Reserve': ['XBE'],
  Beaufort: ['BFT'],
  Beaumont: ['BPT', 'BMT'],
  'Beauvais Tille': ['BVA'],
  'Beaver Falls': ['BFP'],
  Bechar: ['CBH'],
  Beckley: ['BKW'],
  Bedford: ['XQD', 'BED', 'BFR'],
  Bedourie: ['BEU'],
  'Bedwell Harbor': ['YBW'],
  'Beef Island': ['TOV', 'EIS'],
  Beihai: ['BHY'],
  Beijing: ['PEK', 'NAY'],
  Beira: ['BEW'],
  Beirut: ['BEY'],
  Beja: ['BYJ'],
  Bejaia: ['BJA'],
  Belaga: ['BLG'],
  'Belaya Gora': ['BGN'],
  Belem: ['BEL'],
  Beletwene: ['BLW'],
  Belfast: ['BHD', 'BFS'],
  Belgaum: ['IXG'],
  Belgorod: ['EGO'],
  Belgrade: ['BJY', 'BEG'],
  'Belize City': ['TZA', 'BZE'],
  'Bell Island': ['KBE'],
  'Bella Coola': ['QBC'],
  Bellary: ['BEP'],
  Belleville: ['XVV', 'BLV'],
  Bellingham: ['BLI'],
  Bellinzona: ['ZDI'],
  Belluno: ['BLX'],
  Belmar: ['BLM'],
  Belmopan: ['BCV'],
  'Belo Horizonte': ['PLU', 'CNF'],
  Beloit: ['JVL'],
  'Belorussky Rail Station': ['JQO'],
  Beloyarsky: ['EYK'],
  Beluga: ['BVU'],
  Bemidji: ['BJI'],
  Benbecula: ['BEB'],
  Bengaluru: ['BLR'],
  Benghazi: ['BEN'],
  Bengkulu: ['BKS'],
  'Beni Mellal': ['BEM'],
  'Benin City': ['BNI'],
  'Bento Goncalves': ['BGV'],
  'Benton Harbor': ['BEH'],
  Bentota: ['BJT'],
  Beppu: ['BPU'],
  Berbera: ['BBO'],
  'Berens River Indian Reserve': ['YBV'],
  BEREZOVO: ['EZV'],
  Bergamo: ['BGY'],
  Bergen: ['QFV', 'XKN', 'BGO', 'XVK'],
  Bergerac: ['EGC'],
  Berkak: ['XUB'],
  Berlevag: ['BVG'],
  Berlin: ['BER', 'QPP', 'QWB', 'BML', 'QWC', 'SXF', 'TXL', 'THF'],
  Bermuda: ['BDA'],
  Berne: ['BRN', 'ZDJ'],
  Beru: ['BEZ'],
  'Berwick Upon Tweed': ['XQG'],
  Bethel: ['BET', 'JBT'],
  Beverly: ['BVY'],
  Beziers: ['BZR'],
  Bhairawa: ['BWA'],
  Bhamo: ['BMO'],
  Bharatpur: ['BHR'],
  Bhatinda: ['BUP'],
  Bhavnagar: ['BHU'],
  Bhopal: ['BHO'],
  Bhubaneswar: ['BBI'],
  Bhuj: ['BHJ'],
  Biak: ['BIK'],
  Biarritz: ['BIQ'],
  'Biel Bienne': ['ZDK'],
  Bielefeld: ['BFE'],
  'Big Bear City': ['RBF'],
  'Big Delta': ['BIG'],
  'Big Lake': ['BGQ'],
  'Big Mountain': ['BMX'],
  'BIG SPRING': ['HCA'],
  'Big Trout': ['YTL'],
  Bijie: ['BFJ'],
  'Bikini Atoll': ['BII'],
  Bilaspur: ['PAB'],
  Bilbao: ['YJI', 'BIO'],
  Bildudalur: ['BIU'],
  Billings: ['BIL'],
  Billund: ['BLL'],
  Biloela: ['ZBL'],
  Bima: ['BMU'],
  Bimini: ['NSB', 'BIM'],
  Binghamton: ['BGM'],
  Bingol: ['BGG'],
  Bintulu: ['BTU'],
  Birdsville: ['BVI'],
  Birmingham: ['QQN', 'BHX', 'ZBC', 'BHM'],
  Bisbee: ['BSQ'],
  Bisha: ['BHH'],
  Bishkek: ['FRU'],
  Bisho: ['BIY'],
  Bishop: ['BIH'],
  Biskra: ['BSK'],
  Bismarck: ['BIS'],
  Bissau: ['OXB'],
  Bitburg: ['BBJ'],
  Bjerka: ['ZMZ'],
  Blackall: ['BKQ'],
  Blackpool: ['BLK', 'XQL'],
  Blacksburg: ['BCB'],
  Blackwater: ['BLT'],
  Blagoveshchensk: ['BQS'],
  'Blakely Island': ['BYW'],
  'Blanc Sablon': ['YBX'],
  Blantyre: ['BLZ'],
  Blenheim: ['BHE'],
  'Block Island': ['BID'],
  Bloemfontein: ['BFN'],
  'Bloodvein River': ['YDV'],
  'Bloomfield River': ['BFC'],
  Bloomington: ['BMI', 'BMG'],
  'Blue Canyon': ['BLU'],
  'Blue Fox Bay': ['BFB'],
  'Blue Mountain': ['VBM'],
  Bluefield: ['BLF'],
  Blumenau: ['BNU'],
  Blythe: ['BLH'],
  Blytheville: ['BYH', 'HKA'],
  'Bo Norway': ['XXC'],
  'Boa Vista': ['BVB', 'BVC'],
  Boao: ['BPO'],
  Bobadilla: ['OZI'],
  'Bobo Dioulasso': ['BOY'],
  'Boca Do Acre': ['BCR'],
  'Boca Raton': ['BCT'],
  'Bocas Del Toro': ['BOC'],
  Bochum: ['QBO'],
  Bodaybo: ['ODO'],
  Bodo: ['BOO', 'ZXM'],
  Bodrum: ['BXN', 'BJV'],
  Bogota: ['BOG'],
  'Boigu Island': ['GIC'],
  Boise: ['BOI'],
  Bokpyin: ['VBP'],
  Bole: ['BPL'],
  Bologna: ['IBT', 'BLQ'],
  Bolzano: ['BZO'],
  Bonaire: ['BON'],
  Bonaventure: ['YVB'],
  Bonito: ['BYO'],
  Bonn: ['BNJ'],
  Bonnyville: ['YBY'],
  Boolgeeda: ['OCM'],
  'BOR RU': ['TGP'],
  'Bora Bora': ['BOB'],
  Borba: ['RBB'],
  Bordeaux: ['BOD', 'ZFQ'],
  'Borg El Arab': ['HBE'],
  Borlange: ['XYB', 'BLE'],
  Bornholm: ['RNN'],
  Bornite: ['RLU'],
  'Borrego Springs': ['BXS'],
  Borroloola: ['BOX'],
  Bosaso: ['BSA'],
  BOSSET: ['BOT'],
  Boston: ['ZTY', 'BOS', 'BNH', 'ZRU', 'ZTO'],
  'Boswell Bay': ['BSW'],
  Boulder: ['WBU'],
  Boulia: ['BQL'],
  Bountiful: ['BTF'],
  Bourgas: ['BOJ'],
  Bourges: ['BOU'],
  Bourke: ['BRK'],
  Bournemouth: ['BOH', 'POQ'],
  'Bowling Green': ['BWG'],
  Bozeman: ['BZN'],
  Brac: ['BWK'],
  Bradford: ['BRF', 'BFD'],
  Brainerd: ['BRD'],
  Braintree: ['WXF'],
  Brampton: ['XPN'],
  'Brampton Island': ['BMP'],
  Brandon: ['YBR'],
  Branson: ['BKG'],
  Brantford: ['XFV'],
  Brasilia: ['BSB'],
  Brasov: ['XHV'],
  Bratislava: ['ZRG', 'BTS'],
  Bratsk: ['BTK'],
  Braunschweig: ['BWE'],
  Brava: ['BVR'],
  Brawley: ['BWC'],
  Brazoria: ['BZT'],
  Brazzaville: ['BZV'],
  Breckenridge: ['QKB'],
  Bregenz: ['XGZ'],
  Bremen: ['BRE'],
  Bremerhaven: ['BRV'],
  Bremerton: ['PWT'],
  Brest: ['BQT', 'BES'],
  'Brevig Mission': ['KTS'],
  Brewarrina: ['BWQ'],
  Bridgeport: ['BDR'],
  Bridgetown: ['BGI'],
  Bridgwater: ['UBW'],
  Brig: ['ZDL'],
  Brighton: ['BSH'],
  Brindisi: ['BDS'],
  Brisbane: ['BNE'],
  Bristol: ['BRS', 'BPR', 'TPB', 'TRI'],
  'Brive La Gaill': ['BVE'],
  Brno: ['ZDN', 'BRQ'],
  Brochet: ['YBT'],
  Brockville: ['XBR'],
  'Broken Bow': ['BBW'],
  'Broken Hill': ['BHQ'],
  Bromont: ['ZBM'],
  Bronnoysund: ['BNN'],
  Brookhaven: ['WSH'],
  Brookings: ['BKX'],
  Broome: ['BME'],
  Broomfield: ['BJC'],
  Brownsville: ['BRO'],
  Brownwood: ['BWD'],
  Brugge: ['ZGJ'],
  Brumado: ['BMS'],
  Brumunddal: ['ZVB'],
  Brunswick: ['NHZ', 'BQK'],
  Brussels: ['ZWY', 'ZYR', 'BRU', 'CRL'],
  Bryan: ['CFD'],
  Bryansk: ['BZK'],
  Bryce: ['BCE'],
  Bryne: ['XOB'],
  Bucaramanga: ['BGA'],
  'Buch Sg': ['ZDO'],
  Bucharest: ['BBU', 'OTP'],
  Buckeye: ['BXK'],
  Budapest: ['XXJ', 'XXQ', 'BUD'],
  Buenaventura: ['BUN'],
  'Buenos Aires': ['BAI', 'BBE', 'AEP', 'EZE'],
  'Buerjin City': ['KJI'],
  Buffalo: ['ZFZ', 'ZXS', 'BUF'],
  Bugulma: ['UUA'],
  Bujumbura: ['BJM'],
  'Buka Island': ['BUA'],
  Bukhara: ['BHK'],
  Bukoba: ['BKZ'],
  Bulawayo: ['BUQ'],
  'Bullfrog Basin': ['BFG'],
  'Bullhead City': ['IFP'],
  Bulolo: ['BUL'],
  'Bumi Hills': ['BZH'],
  Bunbury: ['BUY'],
  Bundaberg: ['BDB'],
  Buochs: ['BXO'],
  Burbank: ['BUR', 'JPD'],
  Burgdorf: ['ZDP'],
  Burgos: ['RGS', 'XJU', 'UGR'],
  'Buri Ram': ['BFV'],
  Burketown: ['BUC'],
  Burley: ['BYI'],
  Burlington: ['BTV', 'BRL'],
  Burnie: ['BWT'],
  Burns: ['BNO'],
  'Burns Lake': ['YPZ'],
  Bursa: ['YEI'],
  'Bury St Edmunds': ['BEQ'],
  Busan: ['PUS'],
  Bushehr: ['BUZ'],
  Busselton: ['BQB'],
  Busuanga: ['USU'],
  Butler: ['BTP', 'BUM'],
  Butte: ['BTM'],
  Butuan: ['BXU'],
  Buzios: ['BZC'],
  Bydgoszcz: ['BZG'],
  'Ca Mau City': ['CAH'],
  Cabimas: ['CBS'],
  'Cabo Frio': ['CFB'],
  Cacador: ['CFC'],
  Caceres: ['EXC', 'QUQ'],
  Cachoeirinha: ['QKA'],
  'Cachoeiro De Itapemirim': ['CDI'],
  Cacoal: ['OAL'],
  Cadillac: ['CAD'],
  Cadiz: ['CDZ'],
  Caen: ['CFR'],
  Cagayan: ['CGY'],
  Cagliari: ['CAG'],
  Cahors: ['ZAO'],
  Cairns: ['CNS'],
  Cairo: ['CAI', 'CCE'],
  Cajamarca: ['CJA'],
  Calabar: ['CBQ'],
  Calais: ['CQF'],
  'Calais City': ['XFF'],
  Calama: ['CJC'],
  Calbayog: ['CYP'],
  'Caldas Novas': ['CLV'],
  Caldwell: ['CDW'],
  Calexico: ['CXL'],
  Calgary: ['YYC'],
  Cali: ['CLO'],
  Calipatria: ['CLR'],
  Calverton: ['CTO'],
  Calvi: ['CLY'],
  'Cam Ranh': ['CXR'],
  Camaguey: ['CMW'],
  Cambridge: ['CBG'],
  'Cambridge Bay': ['YCB'],
  Camden: ['CDH'],
  Camiguin: ['CGM'],
  Camiri: ['CAM'],
  'Camp Springs': ['ADW', 'NSF'],
  'Campbell Island': ['ZEL'],
  'Campbell River': ['YBL', 'YHH'],
  Campbellton: ['XAZ'],
  Campbelltown: ['CAL'],
  Campeche: ['CPE'],
  'Campina Grande': ['CPV'],
  Campinas: ['CPQ'],
  Campogrande: ['CGR'],
  'Campos Dos Goytacaze': ['CAW'],
  'Can Tho': ['VCA'],
  Canakkale: ['CKZ'],
  Canberra: ['CBR'],
  Cancun: ['CUN'],
  Candle: ['CDL'],
  Cangyuan: ['CWJ'],
  Cannes: ['JCA', 'QYW', 'CEQ'],
  'Canon City': ['CNE'],
  'Canouan Island': ['CIW'],
  'Cap Haitien': ['CAP'],
  'Cape Dorset': ['YTE'],
  'Cape Dyer': ['YVN'],
  'Cape Girardeau': ['CGI'],
  'Cape May': ['WWD'],
  'Cape Pole': ['CZP'],
  'Cape Sarichef': ['WSF'],
  'Cape Spencer': ['CSP'],
  'Cape Town': ['CPT'],
  'Cape Yakataga': ['CYT'],
  Capreol: ['XAW'],
  Capri: ['PRJ'],
  Capurgana: ['CPB'],
  'Car Nicobar': ['CBD'],
  Caracas: ['CCS'],
  Carajas: ['CKS'],
  Carauari: ['CAF'],
  Carbondale: ['MDH'],
  Carcassonne: ['CCF'],
  Cardiff: ['CFW', 'CWL'],
  Caribou: ['CAR'],
  Carleton: ['XON'],
  Carlisle: ['CAX', 'CXX', 'KXN'],
  Carlsbad: ['CNM'],
  Carnarvon: ['CVQ', 'WLP'],
  Carroll: ['CIN'],
  'Carson City': ['CSN'],
  Cartagena: ['XUF', 'CTG'],
  Cartago: ['CRC'],
  Cartwright: ['YRF'],
  Caruaru: ['CAU'],
  'Casa Grande': ['CGZ'],
  Casablanca: ['CAS', 'CMN'],
  Cascais: ['CAT'],
  Cascavel: ['CAC'],
  Casino: ['CSI'],
  Casper: ['CPR'],
  Casselman: ['XZB'],
  'Castellon de la Plana': ['CDT', 'CPJ'],
  Castlegar: ['YCG'],
  Castres: ['DCM'],
  Castro: ['WCA', 'MHC'],
  'Cat Lake Indian Reserve': ['YAC'],
  Catalao: ['TLZ'],
  Catamarca: ['CTC'],
  Catania: ['CTA', 'NSY'],
  Catarman: ['CRM'],
  Caticlan: ['MPH'],
  Catumbela: ['CBT'],
  'Cauayan City': ['CYZ'],
  Caucasia: ['CAQ'],
  Cauquira: ['CDD'],
  'Caxias Do Sul': ['CXJ'],
  'Caye Caulker': ['CUK'],
  Cayenne: ['CAY'],
  'Cayman Brac': ['CYB'],
  'Cayo Coco': ['CCC'],
  'Cayo Largo Del Sur': ['CYO'],
  Cebu: ['CEB'],
  'Cedar City': ['CDC'],
  'Cedar Rapids': ['CID'],
  Ceduna: ['CED'],
  Ceiba: ['NRR'],
  Celaya: ['CYW'],
  Centralia: ['ENL'],
  'Century City': ['CCD'],
  Cessnock: ['CES'],
  Chadron: ['CDR'],
  Chaiten: ['WCH'],
  Chambery: ['CMF'],
  Chambord: ['XCI'],
  Champaign: ['CMI'],
  Champery: ['ZDQ'],
  Chandalar: ['WCR'],
  Chandigarh: ['IXC'],
  Chandler: ['XDL', 'CHD'],
  Changchun: ['CGQ', 'VWB'],
  Changde: ['CGD'],
  Changsha: ['CSX', 'DDV'],
  Changuinola: ['CHX'],
  Changzhi: ['CIH'],
  Changzhou: ['CZX'],
  Chania: ['CHQ'],
  Chanute: ['CNU'],
  Chapeco: ['XAP'],
  Chapelco: ['CPC'],
  Chapleau: ['YLD'],
  'Charles City': ['CCY'],
  Charleston: ['CHS', 'CRW'],
  Charleville: ['CTL'],
  Charlo: ['YCL'],
  Charlotte: ['CLT', 'QWG'],
  Charlottesville: ['CHO'],
  Charlottetown: ['YYG', 'YHG'],
  'Chateau D Oex': ['ZDR'],
  Chateauroux: ['CHR'],
  Chatham: ['XCM', 'CYM'],
  Chattanooga: ['CHA', 'QDK'],
  Chaves: ['CHV'],
  Cheadle: ['TIL'],
  Cheboksary: ['CSY'],
  Chehalis: ['CLS'],
  Chelinda: ['CEH'],
  Cheltenham: ['CHW'],
  Chelyabinsk: ['CEK'],
  Chemainus: ['XHS'],
  Chemnitz: ['ZTZ'],
  'Chena Hot Springs': ['CEX'],
  Chengde: ['CDE'],
  Chengdu: ['CTU'],
  Chennai: ['MAA'],
  Chenzhou: ['LQP'],
  Cheongju: ['CJJ'],
  Cherbourg: ['CER'],
  Cherkassy: ['CKC'],
  Chernofski: ['KCN'],
  Chernovtsy: ['CWC'],
  Chersky: ['CYX'],
  Chester: ['CEG', 'CXE'],
  Chesterfield: ['ZFI'],
  'Chesterfield Inlet': ['YCS'],
  Chetumal: ['CTM'],
  Chevery: ['YHR'],
  Cheyenne: ['CYS', 'FEW'],
  'Chiang Mai': ['CNX'],
  'Chiang Rai': ['CEI'],
  Chiasso: ['ZDS'],
  Chiayi: ['CYI'],
  Chibougamau: ['YMT'],
  Chicago: ['DPA', 'PWK', 'MDW', 'RFD', 'ZUN', 'ZWV', 'ZUK', 'ORD'],
  'Chichen Itza': ['CZA'],
  Chiclayo: ['CIX'],
  Chico: ['CIC'],
  Chifeng: ['CIF'],
  'Chignik Lagoon': ['KCQ', 'KCG', 'KBW', 'KCL'],
  Chigoro: ['IGO'],
  Chihuahua: ['CUU'],
  Chilliwack: ['YCW'],
  Chimoio: ['VPY'],
  Chimore: ['CCA'],
  Chincoteague: ['WAL'],
  Chinju: ['HIN'],
  Chios: ['JKH'],
  Chipata: ['CIP'],
  Chiredzi: ['BFO'],
  Chisasibi: ['YKU'],
  Chisinau: ['KIV'],
  Chistochina: ['CZO'],
  Chita: ['HTA'],
  Chitina: ['CXC'],
  Chitre: ['CTD'],
  Chittagong: ['CGP'],
  Chizhou: ['JUH'],
  Chlef: ['CFK'],
  Chokurdakh: ['CKH'],
  Cholet: ['CET'],
  Chongqing: ['CKG'],
  Christchurch: ['CHC'],
  'Christmas Island': ['CXI', 'XCH'],
  Chuathbaluk: ['CHU'],
  Chumphon: ['CJM'],
  Chur: ['ZDT'],
  'Churchhill Falls': ['ZUM'],
  Churchill: ['YYQ', 'XAD'],
  'Ciego De Avila': ['AVI'],
  Cienfuegos: ['CFG'],
  Cijulang: ['CJN'],
  Cilacap: ['CXP'],
  Cincinnati: ['LUK', 'CVG'],
  Circle: ['IRC'],
  'Circle Hot Springs': ['CHP'],
  Cirebon: ['CBN'],
  'Ciudad Bolivar': ['CBL'],
  'Ciudad Constitucion': ['CUA'],
  'Ciudad Del Carmen': ['CME'],
  'Ciudad Del Este': ['AGT'],
  'Ciudad Juarez': ['CJS'],
  'Ciudad Mante': ['MMC'],
  'Ciudad Obregon': ['CEN'],
  'Ciudad Real': ['CQM', 'XJI'],
  'Ciudad Real City': ['CJI'],
  'Ciudad Victoria': ['CVM'],
  Clarksburg: ['CKB'],
  Clarksdale: ['CKM'],
  Clarksville: ['CKV'],
  'Clear Lake': ['CKE'],
  Clearwater: ['CLW'],
  Clemson: ['CEU'],
  Clermont: ['CMQ'],
  'Clermont  Ferrand': ['CFE'],
  Cleveland: ['BKL', 'CGF', 'CLE'],
  Clifton: ['CFT'],
  Clinton: ['CWI', 'CLK', 'CSM'],
  Cloncurry: ['CNJ'],
  'Clover Pass': ['RTE'],
  Clovis: ['CVS', 'CVN'],
  'Club Makokola': ['CMK'],
  Cluj: ['CLJ'],
  'Clyde River': ['YCY'],
  Coalinga: ['CLG'],
  Coatesville: ['CTH'],
  Cobar: ['CAZ'],
  Cobija: ['CIJ'],
  Cobourg: ['XGJ'],
  Coca: ['OCC'],
  Cochabamba: ['CBB'],
  'Cochise County': ['CWX'],
  Cochrane: ['YCN'],
  Cocoa: ['COI', 'COF'],
  'Coconut Island': ['CNC'],
  'Cocos Islands': ['CCK'],
  Cody: ['COD'],
  Coen: ['CUQ'],
  'Coeur D Alene': ['COE'],
  'Coffs Harbour': ['CFS'],
  Cognac: ['CNG'],
  Coimbatore: ['CJB'],
  Coimbra: ['CBP'],
  Colac: ['XCO'],
  Colby: ['CBK'],
  Colchester: ['CLB'],
  'Cold Lake': ['YOD'],
  Coldfoot: ['CXF'],
  Colima: ['CLQ'],
  'Coll Island': ['COL'],
  'College Station': ['CLL'],
  Collie: ['CIE'],
  Collinsville: ['KCE'],
  Colmar: ['CMR'],
  Cologne: ['QBB', 'QKL', 'QKU', 'CGN'],
  Colombo: ['CMB', 'RML'],
  Colon: ['ONX'],
  Colonia: ['CDM', 'CYR'],
  'Colorado Creek': ['KCR'],
  'Colorado Springs': ['FCS', 'COS', 'AFF'],
  Columbia: ['COA', 'CUB', 'MMT', 'CAE', 'COU', 'MRC'],
  Columbus: [
    'CBM',
    'OLU',
    'LSF',
    'CSG',
    'CUS',
    'CLU',
    'GTR',
    'UBS',
    'OSU',
    'CMH',
    'LCK',
  ],
  Colville: ['YCK'],
  Comayagua: ['XPL'],
  Comiso: ['CIY'],
  'Comodoro Rivadavia': ['CRD'],
  Comox: ['YQQ'],
  Compton: ['CPM'],
  'Con Dao': ['VCS'],
  Conakry: ['CKY'],
  Concepcion: ['CIO', 'CCP'],
  Concord: ['CCR', 'CON', 'USA'],
  Concordia: ['CNK', 'COC', 'CCI'],
  Condoto: ['COG'],
  Conroe: ['CXO'],
  Constanta: ['CRN', 'CND'],
  Constantine: ['CZL'],
  Contadora: ['OTD'],
  'Coober Pedy': ['CPD'],
  'Cooch Behar': ['COH'],
  Cooinda: ['CDA'],
  Cooktown: ['CTN'],
  Cooma: ['OOM'],
  Coonabarabrn: ['COJ'],
  Coonamble: ['CNB'],
  'Cooper Landing': ['JLA'],
  Copenhagen: ['CPH', 'ZGH'],
  Copiapo: ['CPO'],
  'Copper Center': ['CZC'],
  'Copper Mountain': ['QCE'],
  'Coral Harbour': ['YZS'],
  'Corazon De Jesus': ['CZJ'],
  Corcoran: ['CRO'],
  Cordoba: ['ODB', 'XOJ', 'COR'],
  Cordova: ['CDV', 'CKU'],
  Corinth: ['CRX'],
  Cork: ['ORK'],
  'Corner Bay': ['CBA'],
  Cornwall: ['YCC'],
  Coro: ['CZE'],
  Coromandel: ['CMV'],
  Coronation: ['YCT'],
  Corowa: ['CWW'],
  Corozal: ['CZH', 'CZU'],
  'Corpus Christi': ['NGW', 'CRP', 'NGP', 'CUX'],
  Corrientes: ['CNQ'],
  Corsicana: ['CRS'],
  'Cortes Bay': ['YCF'],
  Cortez: ['CEZ'],
  Corumba: ['CMG'],
  Corvallis: ['QWY', 'CVO'],
  'Corvo Island': ['CVU'],
  Cotabato: ['CBO'],
  Coteau: ['XGK'],
  Cotia: ['QOI'],
  'Coto 47': ['OTR'],
  Cotonou: ['COO'],
  Cottbus: ['ZTT'],
  Cottonwood: ['CTW'],
  Council: ['CIL'],
  'Council Bluffs': ['CBF'],
  Courchevel: ['CVF'],
  Courtenay: ['YCA'],
  Coventry: ['CVT'],
  Cowra: ['CWT'],
  'Coxs Bazar': ['CXB'],
  Coyhaique: ['GXQ'],
  Cozumel: ['CZM'],
  Craig: ['CIG', 'CGA'],
  'Craig Cove': ['CCV'],
  Craiova: ['CRA'],
  Cranbrook: ['YXC'],
  Creil: ['CSF'],
  'Crescent City': ['CEC'],
  Creston: ['CFQ'],
  Criciuma: ['CCM'],
  'Crooked Creek': ['CKD'],
  'Crooked Island': ['CRI'],
  'Cross lake': ['YCR'],
  Crossett: ['CRT'],
  Crossville: ['CSV'],
  Crotone: ['CRV'],
  'Crows Landing': ['NRC'],
  Cruz: ['JJD'],
  'Cruz Alta': ['CZB'],
  'Cruzeiro Do Sul': ['CZS'],
  'Cube Cove': ['CUW'],
  Cucuta: ['CUC'],
  Cudal: ['CUG'],
  Cuddapah: ['CDP'],
  Cuenca: ['CEJ', 'CUE'],
  Cuernavaca: ['CVJ'],
  Cuiaba: ['CGB'],
  Culebra: ['CPX'],
  Culiacan: ['CUL'],
  Cumana: ['CUM'],
  Cumberland: ['CBE'],
  Cuneo: ['CUF'],
  Cunnamulla: ['CMA'],
  Curacao: ['CUR'],
  Curitiba: ['BFH', 'CWB'],
  Cuxhaven: ['FCN'],
  Cuzco: ['CUZ'],
  Cyangugu: ['KME'],
  'Da Nang': ['DAD'],
  Daegu: ['TAE'],
  Daggett: ['DAG'],
  'Dahl Creek': ['DCK'],
  Dakar: ['DKR'],
  Dakhla: ['VIL'],
  'Dakhla Oasis': ['DAK'],
  Dalaman: ['DLM'],
  Dalat: ['DLI'],
  Dalby: ['DBY'],
  'Dali City': ['DLU'],
  Dalian: ['DLC', 'DBL'],
  Dallas: ['ADS', 'JDB', 'RBD', 'DFW', 'DAL', 'AFW', 'FWH'],
  Dalton: ['DNN'],
  Daman: ['NMB'],
  Damascus: ['DAM'],
  Dammam: ['XZF', 'DMM', 'DMS'],
  Danbury: ['DXR'],
  Dandong: ['DDG'],
  Dangriga: ['DGA'],
  Danville: ['DAN', 'DNV'],
  Daocheng: ['DCY'],
  Daparizo: ['DAE'],
  Daqing: ['DQA'],
  'Dar Es Salaam': ['DAR'],
  Darlington: ['XVG'],
  Daru: ['DAU'],
  Darwin: ['DRW', 'XJG'],
  Dashoguz: ['TAZ'],
  Datong: ['DAT'],
  Daugavpils: ['DGP'],
  Dauphin: ['YDN'],
  Davao: ['DVO'],
  Davenport: ['DVN'],
  David: ['DAV'],
  Davos: ['ZDV'],
  Dawadmi: ['DWD'],
  'Dawson City': ['YDA'],
  'Dawson Creek': ['YDQ'],
  'Daydream Island': ['DDI'],
  Dayong: ['DYG'],
  Dayton: ['DAY', 'MGY', 'FFO', 'DWF'],
  'Daytona Beach': ['DAB'],
  Dazhou: ['DAX'],
  'Deadmans Cay': ['LGI'],
  'Dease Lake': ['YDL'],
  'Death Valley': ['DTH'],
  Deauville: ['DOL'],
  Debrecen: ['DEB'],
  Decatur: ['DEC', 'DCU'],
  Decorah: ['DEH'],
  'Deer Harbour': ['DHB'],
  'Deer Lake': ['YVZ', 'YDF'],
  Defiance: ['DFI'],
  Degerfors: ['XXD'],
  'Dehra Dun': ['DED'],
  Deirezzor: ['DEZ'],
  'Del Carmen': ['IAO'],
  'Del Rio': ['DRT', 'DLF'],
  Delemont: ['ZDW'],
  Delhi: ['DEL'],
  Deline: ['YWJ'],
  'Delta Junction': ['DJN'],
  Denham: ['DNM'],
  Deniliquin: ['DNQ'],
  Denizli: ['DNZ'],
  'Denpasar Bali': ['DPS'],
  Denver: ['BFK', 'APA', 'DEN', 'QWM'],
  Deputatskij: ['DPT'],
  'Dera Ghazi Khan': ['DEA'],
  Derby: ['DCN', 'DRB', 'XQH'],
  'Des Moines': ['DSM'],
  Dessau: ['ZSU'],
  Destin: ['DSI'],
  Detroit: ['UIZ', 'DET', 'DTW', 'YIP'],
  Deventer: ['QYV'],
  'Devils Lake': ['DVL'],
  Devonport: ['DPO'],
  Dhahran: ['DHA'],
  Dhaka: ['DAC'],
  Diamantina: ['DTI'],
  Dibrugarh: ['DIB'],
  Dickinson: ['DIK'],
  Dickwella: ['DIW'],
  Didcot: ['XPW'],
  'Dien Bien Phu': ['DIN'],
  Dieppe: ['DPE'],
  Dietikon: ['ZDX'],
  Dijon: ['DIJ'],
  Dili: ['DIL'],
  Dillingham: ['DLG'],
  Dillon: ['DLL'],
  'Dillons Bay': ['DLY'],
  Dimapur: ['DMU'],
  Dinard: ['DNR'],
  Dipolog: ['DPL'],
  Diqing: ['DIG'],
  'Dire Dawa': ['DIR'],
  Dirranbandi: ['DRN'],
  'Disneyland Paris Rail': ['XED'],
  'Diu In': ['DIU'],
  Divinopolis: ['DIQ'],
  Diyarbakir: ['DIY'],
  Djerba: ['DJE'],
  Djibouti: ['JIB'],
  Dnepropetrovsk: ['DNK'],
  Dobo: ['DOB'],
  'Dodge City': ['DDC'],
  Doha: ['DOH'],
  Dole: ['DLE'],
  Dolomi: ['DLO'],
  Dombas: ['XGP'],
  Dominica: ['DCF', 'DOM'],
  Doncaster: ['DSA'],
  Donegal: ['CFN'],
  Donetsk: ['DOK'],
  'Dong Hoi': ['VDH'],
  Dongara: ['DOX'],
  Dongying: ['DOY'],
  Donnelly: ['YOE'],
  Doomadgee: ['DMD'],
  'Dora Bay': ['DOF'],
  Dornoch: ['DOC'],
  Dortmund: ['DTZ', 'DTM'],
  Dothan: ['DHN'],
  Douala: ['DLA'],
  Douglas: ['DUG', 'DGW', 'DGL'],
  Dourados: ['DOU'],
  Dover: ['DVX', 'DOV', 'QQD'],
  Doylestown: ['DYL'],
  'Drake Bay': ['DRK'],
  Drangedal: ['ZVD'],
  Dresden: ['DRS', 'XIR'],
  'Drift River': ['DRF'],
  Drummondville: ['XDM'],
  Dryden: ['YHD'],
  Dubai: ['DWC', 'XEU', 'DCG', 'DXB', 'XMB', 'XHB', 'NHD', 'XNB'],
  Dubbo: ['DBO'],
  Dublin: ['DUB', 'DBN', 'PSK'],
  Dubois: ['DUJ'],
  Dubrovnik: ['DBV'],
  Dubuque: ['DBQ'],
  Duisburg: ['DUI'],
  Duluth: ['DLH'],
  Dumaguete: ['DGT'],
  Duncan: ['DUQ', 'DUC'],
  Dundee: ['ZDU', 'DND'],
  Dunedin: ['DUD'],
  Dunhuang: ['DNH'],
  'Dunk Island': ['DKI'],
  Duqm: ['DQM', 'JNJ'],
  Durango: ['AMK', 'DRO', 'DGO'],
  Durban: ['DUR', 'VIR'],
  Durgapur: ['RDP'],
  Durham: ['XVU', 'MME'],
  Dushanbe: ['DYU'],
  Dusseldorf: ['QDU', 'DUS', 'MGL', 'NRN'],
  'Dutch Harbor': ['DUT'],
  Dzaoudzi: ['DZA'],
  Eagle: ['ZBV', 'EAA', 'EGE', 'QBF'],
  'Eagle Pass': ['EGP'],
  'Eagle River': ['EGV'],
  'East Hampton': ['HTO'],
  'East London': ['ELS'],
  'East Sound': ['ESD', 'RSJ', 'WSX'],
  'East Stroudsburg': ['ESP'],
  'Easter Island': ['IPC'],
  Eastland: ['ETN'],
  Eastmain: ['ZEM'],
  Easton: ['ESN'],
  'Eau Claire': ['EAU'],
  Ebon: ['EBO'],
  Echuca: ['ECH'],
  Eday: ['EOI'],
  Edenton: ['EDE'],
  Edinburgh: ['EDI', 'ZXE'],
  Edmonton: ['YED', 'YXD', 'YEG', 'XZL'],
  'Edna Bay': ['EDA'],
  Edremit: ['EDO'],
  Edson: ['YET'],
  'Edward River': ['EDR'],
  'Eek City': ['EEK'],
  Efogi: ['EFG'],
  Egelsbach: ['QEF'],
  Egilsstadir: ['EGS'],
  Eindhoven: ['ZYE', 'EIN'],
  Einsiedeln: ['ZDZ'],
  Eirunepe: ['ERN'],
  Eisenach: ['EIB', 'EIZ'],
  Ekaterinburg: ['SVX'],
  'El Aaiun': ['EUN'],
  'El Arish': ['AAC'],
  'El Bagre': ['EBG'],
  'El Bayadh': ['EBH'],
  'El Bolson': ['EHL'],
  'El Calafate': ['FTE'],
  'El Centro': ['NJK', 'IPL'],
  'El Dorado': ['ELD'],
  'El Habala': ['FQB'],
  'El Monte': ['EMT'],
  'El Nido': ['ENI'],
  'El Paso': ['BIF', 'ELP'],
  'El Portillo Samana': ['EPS'],
  'El Salvador': ['ESR'],
  'El Vigia': ['VIG'],
  'El Yopal': ['EYP'],
  Elat: ['ETH'],
  Elazig: ['EZS'],
  'Elba Island': ['EBA'],
  Elblag: ['ZBG'],
  'Elcho Island': ['ELC'],
  Eldoret: ['EDL'],
  'Eldred Rock': ['ERO'],
  Elista: ['ESL'],
  'Elizabeth City': ['ECG'],
  Elizabethtown: ['EKX'],
  'Elk City': ['ELK'],
  Elkhart: ['EKI'],
  Elkins: ['EKN'],
  Elko: ['EKO'],
  Ellamar: ['ELW'],
  'Elliot Lake': ['YEL'],
  Ellisras: ['ELL'],
  Elmira: ['ELM'],
  Elverum: ['XUC'],
  'Ely Mn': ['LYU'],
  'Ely NV': ['ELY'],
  Embrach: ['QEQ'],
  Emden: ['EME'],
  Emerald: ['EMD'],
  EMPANGENI: ['EMG'],
  Emporia: ['EMP'],
  Ende: ['ENE'],
  Enfidha: ['NBE'],
  Engelberg: ['ZHB'],
  'English Bay': ['KEB'],
  Enid: ['END', 'WDG'],
  Enkoping: ['XWQ'],
  Enniskillen: ['ENK'],
  Enonekio: ['ENF'],
  Enschede: ['QYE', 'ENS'],
  Ensenada: ['ESE'],
  Enshi: ['ENH', 'EWV'],
  Entebbe: ['EBB'],
  Enterprise: ['ETS'],
  'Entry Not found in REFPUB': [
    'TUH',
    'DIE',
    'BOK',
    'GEO',
    'LGS',
    'DHM',
    'XRD',
    'FLD',
    'FRH',
    'GGE',
    'GOQ',
    'RME',
    'PTN',
    'XUG',
    'XHF',
    'HUL',
    'JMH',
    'TBI',
    'THA',
    'NOB',
    'OGL',
    'YOC',
    'XOR',
    'RUY',
    'XXX',
    'XWV',
    'WKL',
    'AYS',
    'XIC',
    'ACX',
    'BJL',
    'ZYI',
  ],
  Enugu: ['ENU'],
  Epinal: ['EPL'],
  Eqalugaarsuit: ['QFG'],
  Erbil: ['EBL'],
  Ercan: ['ECN'],
  Erdenet: ['ERT'],
  Erechim: ['ERM'],
  Erenhot: ['ERL'],
  Erfurt: ['ERF', 'XIU'],
  Erie: ['ERI'],
  Errachidia: ['ERH'],
  Erzincan: ['ERC'],
  Erzurum: ['ERZ'],
  Esbjerg: ['EBJ', 'ZBB'],
  Escanaba: ['ESC'],
  Eskilstuna: ['EKT', 'XFJ'],
  Eskisehir: ['AOE', 'ESK'],
  Esmeraldas: ['ESM'],
  Esperance: ['EPR'],
  'Espiritu Santo': ['SON'],
  Esquel: ['EQS'],
  Esquimalt: ['YPF'],
  Essaouira: ['ESU'],
  Essen: ['ESS', 'ESZ'],
  Estevan: ['YEN'],
  Eufaula: ['EUF'],
  Eugene: ['EUG'],
  Eureka: ['EKA'],
  Evanston: ['EVW'],
  Evansville: ['EVV'],
  Eveleth: ['EVM'],
  Evensk: ['SWV'],
  Everett: ['PAE'],
  Evreux: ['EVX'],
  'Excursion Inlet': ['EXI'],
  Exeter: ['EXT', 'EXS', 'TTY'],
  'Exmouth Gulf': ['EXM'],
  Faaite: ['FAC'],
  'Faeroe Islands': ['FAE'],
  Fagernes: ['VDB'],
  'Fair Isle': ['FIE'],
  Fairbanks: ['EIL', 'FAI', 'FBK', 'PII', 'MTX'],
  Fairfield: ['SUU'],
  Fairmont: ['FRM'],
  'Fairmont Springs': ['YCZ'],
  Fairview: ['ZFW'],
  Faisalabad: ['LYP'],
  Faizabad: ['FBD'],
  Fajardo: ['FAJ'],
  'Fak Fak': ['FKQ'],
  Fakarava: ['FAV'],
  Falkenberg: ['XYM'],
  Fallon: ['FLX', 'NFL'],
  'Falls Creek': ['FLC'],
  Falmouth: ['FMH'],
  'Falmouth UK': ['UFM'],
  'False Island': ['FAK'],
  Falun: ['XJF', 'XWF'],
  Fane: ['FNE'],
  Farewell: ['FWL'],
  Fargo: ['FAR'],
  Farmingdale: ['FRG'],
  Farmington: ['FMN'],
  'Farnborough Hampshire': ['FAB'],
  Faro: ['FAO'],
  Fauske: ['ZXO'],
  Fayetteville: ['FAY', 'FYV', 'POB', 'XNA'],
  Feijo: ['FEJ'],
  'Feira De Santana': ['FEC'],
  Fergana: ['FEG'],
  'Fergus Falls': ['FFM'],
  'Fernando De Noronha': ['FEN'],
  'Fez Ma': ['FEZ'],
  Ficksburg: ['FCB'],
  Figari: ['FSC'],
  Findlay: ['FDY'],
  'Five Finger': ['FIV'],
  Flagstaff: ['FLG'],
  Flam: ['XGH'],
  Flen: ['XYI'],
  Flensburg: ['FLF'],
  'Flin Flon': ['YFO'],
  Flint: ['FNT'],
  Flippin: ['FLP'],
  Florence: ['FIR', 'FLR', 'FLO', 'ZMS'],
  Florencia: ['FLA'],
  Flores: ['FRS'],
  Floriano: ['FLB'],
  Florianopolis: ['FLN'],
  Floro: ['FRO'],
  Fluelen: ['ZHD'],
  Foggia: ['FOG'],
  'Fonte Boa': ['FBA'],
  Forde: ['FDE'],
  Forli: ['FRL'],
  Formosa: ['FMA'],
  'Forrest City': ['FCY'],
  'Forrest River Mission': ['FVR'],
  Forster: ['FOT'],
  'Fort Albany': ['YFA'],
  'Fort Dauphin': ['FTU'],
  'Fort Dodge': ['FOD'],
  'Fort Frances': ['YAG'],
  'Fort Good Hope': ['YGH'],
  'Fort Hope': ['YFH'],
  'Fort Irwin': ['BYS'],
  'Fort MacKay': ['HZP'],
  'Fort Madison': ['FMS'],
  'Fort McPherson': ['ZFM'],
  'Fort Myers': ['FMY', 'RSW'],
  'Fort Nelson': ['YYE'],
  'Fort Pierce': ['FPR'],
  'Fort Polk': ['POE'],
  'Fort Severn': ['YER'],
  'Fort Simpson': ['YFS'],
  'Fort Stockton': ['FST'],
  'Fort William': ['FWM'],
  Fortaleza: ['FOR'],
  Fortuna: ['FON'],
  'Fox Harbour': ['YFX'],
  Franca: ['FRC'],
  'Franceville Mvengue': ['MVB'],
  'Francisco Beltrao': ['FBE'],
  Francistown: ['FRW'],
  Frankfurt: ['ZRB', 'FRA', 'QGV', 'HHN'],
  'Frankfurt An Der Oder': ['ZFR'],
  Franklin: ['FKL'],
  Frauenfeld: ['ZHE'],
  Fredericia: ['ZBJ'],
  Frederick: ['FDK'],
  Fredericton: ['YFC'],
  'Fredericton Junction': ['XFC'],
  Freeport: ['FPO'],
  Freetown: ['HGS', 'FNA', 'JMY'],
  Freiburg: ['QFB'],
  Frejus: ['FRJ'],
  Fremantle: ['JFM'],
  Fresno: ['FAT', 'FCH'],
  Fribourg: ['ZHF'],
  'Friday Harbor': ['FRD', 'FBS'],
  Friedrichshafen: ['FDH'],
  Fritzlar: ['FRZ'],
  'Front Royal': ['FRR'],
  Fryeburg: ['FRY'],
  'Ft Collins': ['QWF', 'QWH', 'FNL'],
  'Ft De France': ['FDF'],
  'Ft Huachuca': ['FHU'],
  'Ft Lauderdale': ['FXE', 'FLL'],
  'Ft Leonard Wood': ['TBN'],
  'Ft McMurray': ['YMM'],
  'Ft Smith': ['FSM', 'YSM'],
  'Ft St John': ['YXJ'],
  'Ft Wayne': ['FWA', 'SMD'],
  'Ft Worth': ['FTW'],
  Fuengirola: ['FGR'],
  'Fuerte Olimpo': ['OLK'],
  Fukue: ['FUJ'],
  Fukuoka: ['FUK'],
  Fukushima: ['FKS'],
  Fulda: ['ZEE'],
  Fullerton: ['FUL'],
  Funafuti: ['FUN'],
  Funchal: ['FNC'],
  Fuoshan: ['FUO'],
  'Fuvahmulak Island': ['FVM'],
  Fuyang: ['FUG'],
  Fuyun: ['FYN'],
  Fuzhou: ['FOC'],
  Gabala: ['GBB'],
  Gabes: ['GAE'],
  Gaborone: ['GBE'],
  Gadsden: ['GAD'],
  Gainesville: ['GLE', 'GNV', 'GVL'],
  Gaithersburg: ['GAI'],
  'Galapagos Is': ['GPS'],
  'Galbraith Lake': ['GBH'],
  Galena: ['GAL'],
  Galesburg: ['GBG'],
  'Galiano Island': ['YMF'],
  Galion: ['GQQ'],
  Gallivare: ['GEV'],
  Gallup: ['GUP'],
  Galveston: ['GLS'],
  Galway: ['GWY'],
  Gambela: ['GMB'],
  'Gambier Island': ['GMR'],
  Gameti: ['YRA'],
  'Gan Island': ['GAN'],
  Gananoque: ['XGW'],
  Gander: ['YQX'],
  'Ganes Creek': ['GEK'],
  Gangneung: ['KAG'],
  Ganzhou: ['KOW'],
  'Gap France': ['GAT'],
  Garaina: ['GAR'],
  'Garden City': ['GCK'],
  'Garden Hill Island Lake': ['YIV'],
  'Garmisch Partenkirchen': ['ZEI'],
  Garoua: ['GOU'],
  Gary: ['GYY'],
  Gaspe: ['YGP', 'XDD'],
  Gassim: ['ELQ'],
  'Gatineau Hull': ['YND'],
  Gatlinburg: ['GKT'],
  Gatokae: ['GTA'],
  'Gaua Island': ['ZGU'],
  Gavle: ['QYU', 'GVX'],
  Gaya: ['GAY'],
  Gaylord: ['GLR'],
  Gaza: ['GZA'],
  Gaziantep: ['GZT'],
  Gazipasa: ['GZP'],
  Gbangbatok: ['GBK'],
  Gdansk: ['RGD', 'GDN'],
  Gecitkale: ['GEC'],
  Geelong: ['GEX'],
  Geilo: ['DLD'],
  Gelendzhik: ['GDZ'],
  Gelsenkirchen: ['ZEJ'],
  'General Roca': ['GNR'],
  'General Santos': ['GES'],
  Geneva: ['ZHT', 'GVA'],
  Genoa: ['GOA'],
  George: ['GRJ'],
  'George Town': ['GGT'],
  Georgetown: ['XHM', 'GED'],
  Gera: ['ZGA'],
  Geraldton: ['GET', 'YGQ'],
  Gethsemanie: ['ZGS'],
  Gettysburg: ['GTY'],
  Ghardaia: ['GHA'],
  Gibraltar: ['GIB'],
  Gijon: ['QIJ'],
  Gilgit: ['GIL'],
  Gillam: ['YGX'],
  Gillette: ['GCC'],
  Girardot: ['GIR'],
  Girdwood: ['AQY'],
  Girona: ['GRO', 'GIA'],
  Gisborne: ['GIS'],
  Gisenyi: ['GYI'],
  Gizan: ['GIZ'],
  Gizo: ['GZO'],
  Gjerstad: ['XGS'],
  'Gjoa Haven': ['YHK'],
  Gjovik: ['ZYG'],
  'Glacier Bay': ['GST', 'BQV'],
  'Glacier Creek': ['KGZ'],
  Gladstone: ['GLT'],
  Glarus: ['ZHG'],
  Glasgow: ['ZGG', 'GLA', 'GLW', 'GGW', 'PIK'],
  'Glen Innes': ['GLI'],
  Glencoe: ['XZC'],
  Glendive: ['GDV'],
  'Glens Falls': ['GFL'],
  'Glenwood Springs': ['GWS'],
  Globe: ['GLB'],
  Gloucester: ['GLO'],
  'Goa In': ['GOI'],
  Gode: ['GDE'],
  'Gods Lake Narrows': ['YGO'],
  'Gods River': ['ZGI'],
  Goerlitz: ['ZGE'],
  Goettingen: ['ZEU'],
  Goiania: ['GYN'],
  'Gol City': ['GLL'],
  'GOLD BEACH': ['GOL'],
  'Gold Coast': ['OOL'],
  'Golden Horn': ['GDH'],
  Goldsboro: ['GSB'],
  Goma: ['GOM'],
  Gombe: ['GMO'],
  Gomel: ['GME'],
  Gondar: ['GDQ'],
  Goodland: ['GLD'],
  Goodyear: ['GYR'],
  Goondiwindi: ['GOO'],
  'Goose Bay': ['YYR'],
  Gorakhpur: ['GOP'],
  'Gorna Orjahovica': ['GOZ'],
  'Gorno Altaysk': ['RGK'],
  Goroka: ['GKA'],
  Gosford: ['GOS'],
  Gossau: ['ZHH'],
  Gotha: ['ZGO'],
  Gothenburg: ['XWL', 'GOT', 'GSE'],
  Gove: ['GOV'],
  'Governador Valadares': ['GVR'],
  'Governor S Harbour': ['GHB'],
  Gozo: ['GZM'],
  'Graciosa Island': ['GRW'],
  Grafton: ['GFN'],
  'Gran Canaria': ['LPA'],
  Granada: ['GRX', 'GBX', 'YJG'],
  'Grand Cache': ['YGC'],
  'Grand Canyon': ['GCN', 'NGC', 'JGC'],
  'Grand Canyon West': ['GCW'],
  'Grand Cayman Island': ['GCM'],
  'Grand Forks': ['GFK'],
  'Grand Island': ['GRI'],
  'Grand Junction': ['GJT'],
  'Grand Rapids': ['GRR', 'GPZ'],
  'Grand Turk': ['GDT'],
  'Grande Prairie': ['YQU'],
  'Grande Riviere': ['XDO'],
  'Granite Mountain': ['GMT'],
  Grants: ['GNT'],
  Gravatai: ['GCV'],
  Grayling: ['KGX'],
  Graz: ['GRZ', 'GGZ'],
  'Great Barrier Island': ['GBZ'],
  'Great Barrington': ['GBR'],
  'Great Bend': ['GBD'],
  'Great Falls': ['GTF', 'GFA'],
  Greeley: ['GXY'],
  'Green Bay': ['GRB'],
  'Green River': ['RVR'],
  Greeneville: ['GCY'],
  Greensboro: ['GSO'],
  Greenville: ['GRE', 'GMU', 'GDC', 'GSP', 'GLH', 'PGV', 'SPA'],
  Greenwood: ['YZX', 'GWO'],
  Greifswald: ['ZGW'],
  Grenada: ['GND'],
  Grenchen: ['ZHI'],
  Grenoble: ['GNB'],
  Greybull: ['GEY'],
  Griffith: ['GFF'],
  Grimsby: ['XGY', 'GSY'],
  Grindelwald: ['ZHJ'],
  Grodna: ['GNA'],
  Groennedal: ['JGR'],
  Groningen: ['GRQ'],
  'Groote Eylandt': ['GTE'],
  Grosseto: ['GRS'],
  GROZNY: ['GRV'],
  Gstaad: ['ZHK'],
  Guadalajara: ['GDU', 'GDL'],
  Guam: ['NGM', 'UAM', 'GUM', 'NRV'],
  Guanambi: ['GNM'],
  'Guang Yuan': ['GYS'],
  Guangzhou: ['CAN'],
  Guantanamo: ['GAO', 'NBW'],
  Guapi: ['GPI'],
  'Guatemala City': ['GUA'],
  Guayaquil: ['GYE'],
  Guaymas: ['GYM'],
  Guelph: ['XIA'],
  Guernsey: ['GCI'],
  Guettin: ['GTI'],
  Guilin: ['KWL', 'GKW'],
  Guiyang: ['KWE'],
  'Gulf Shores': ['GUF'],
  Gulfport: ['GPT'],
  Gulkana: ['GKN'],
  Gunnedah: ['GUH'],
  Gunnison: ['GUC'],
  Gunungsitoli: ['GNS'],
  Gurayat: ['URY'],
  Guriel: ['GUO'],
  Gutersloh: ['GUT', 'ZEX'],
  Guwahati: ['GAU'],
  Guymon: ['GUY'],
  'Guyuan Shi': ['GYU'],
  Gwadar: ['GWD'],
  Gwalior: ['GWL'],
  Gweru: ['GWE'],
  Gyandzhan: ['KVD'],
  Gympie: ['GYP'],
  Gyor: ['QGY'],
  'Ha Apai': ['HPA'],
  'Hachijo Jima': ['HAC'],
  Hachinohe: ['HHE'],
  Hagen: ['ZEY'],
  Hagerstown: ['HGR'],
  Hagfors: ['HFS'],
  Hahnweide: ['QHD'],
  Haifa: ['HFA'],
  Haikou: ['HAK'],
  Hail: ['HAS'],
  Hailar: ['HLD'],
  Haines: ['HNS'],
  Haiphong: ['HPH'],
  Hakodate: ['HKD'],
  'Half Moon Bay': ['HAF'],
  Halifax: ['YAW', 'YWF', 'YHZ', 'XDG'],
  'Hall Beach': ['YUX'],
  Halle: ['ZHZ'],
  'Halls Creek': ['HCQ'],
  Hallsberg: ['XWM'],
  Halmstad: ['HAD'],
  Hamadan: ['HDM'],
  Hamar: ['HMR'],
  Hambantota: ['HRI'],
  Hamburg: ['XFW', 'HAM', 'OBZ', 'ZMB'],
  Hami: ['HMI'],
  Hamilton: ['YHM', 'HLZ', 'HAB'],
  'Hamilton Island': ['HTI'],
  Hamm: ['ZNB'],
  Hammerfest: ['HFT'],
  Hampton: ['ZWW', 'LFI'],
  Hana: ['HNM'],
  Handan: ['HDG'],
  Hangzhou: ['HGH'],
  'Hanimaadhoo Island': ['HAQ'],
  Hanksville: ['HVE'],
  Hannover: ['HAJ', 'HBZ', 'ZVM', 'ZVR'],
  Hanoi: ['HAN'],
  Hanzhong: ['HZG'],
  'Hao Island': ['HOI'],
  Harare: ['HRE'],
  Harbin: ['HRB', 'HBV'],
  'Harbour Island': ['HBI'],
  Hargeisa: ['HGA'],
  Harlingen: ['HRL'],
  Harnosand: ['XYZ'],
  Harrisburg: ['HAR', 'MDT', 'ZUG'],
  Harrismith: ['HRS'],
  Harrison: ['HRO'],
  Harrogate: ['HRT'],
  'Harstad Narvik': ['EVE'],
  Hartford: ['BDL', 'HFD', 'ZRT'],
  Harwich: ['QQH'],
  'Hassi Messaoud': ['HME'],
  Hassleholm: ['XWP'],
  Hastings: ['HSI'],
  'Hat Yai': ['HDY'],
  Hatay: ['HTY'],
  Hatfield: ['HTF'],
  Hatteras: ['HNC'],
  Hattiesburg: ['HBG'],
  Haugastol: ['ZWJ'],
  Haugesund: ['HAU'],
  Havana: ['HAV', 'UPB'],
  HAVASUPAI: ['HAE'],
  Havre: ['HVR'],
  'Havre St Pierre': ['YGV'],
  Hawker: ['HWK'],
  Hawthorne: ['HHR'],
  'Hay River': ['YHY'],
  Haycock: ['HAY'],
  Hayden: ['HDN'],
  'Hayman Island': ['HIS'],
  Hays: ['HYS'],
  Hayward: ['HWD', 'HYR'],
  Hazebrouck: ['HZB'],
  Hazyview: ['HZV'],
  Headquarters: ['HDQ'],
  'Healy Lake': ['HKB'],
  Hearst: ['YHF'],
  Hechi: ['HCJ'],
  Hedemora: ['XXU'],
  Heerbrugg: ['ZHL'],
  Hefei: ['HFE'],
  Heho: ['HEH'],
  'Heide Buesum': ['HEI'],
  Heidelberg: ['HDB'],
  Heihe: ['HEK'],
  Heimdal: ['XUE'],
  Helena: ['HLN', 'HEE'],
  Helgoland: ['HGL'],
  Helsingborg: ['JHE', 'XYH'],
  Helsinki: ['HEL', 'HEC', 'HEM', 'HVT', 'HEN'],
  Hemavan: ['HMV'],
  Hengchun: ['HCN'],
  Hengelo: ['QYH'],
  Hengyang: ['HNY', 'HBP'],
  Heraklion: ['HER'],
  Herat: ['HEA'],
  Hereford: ['HFH'],
  Herendeen: ['HED'],
  Heringsdorf: ['HDF'],
  Herlong: ['AHC'],
  Hermosillo: ['HMO'],
  Herning: ['ZRY', 'XAK'],
  Herrljunga: ['XYC'],
  Hervey: ['XDU'],
  'Hervey Bay': ['HVB'],
  Herzogenbuchsee: ['ZHN'],
  Hibbing: ['HIB'],
  Hickory: ['HKY'],
  'High Level': ['YOJ'],
  'High Prairie': ['ZHP'],
  Hillsboro: ['HIO'],
  Hilo: ['ITO', 'UPP'],
  'Hilton Head': ['HHH'],
  Hilversum: ['QYI'],
  Hinton: ['YJP'],
  Hiroshima: ['HIJ', 'HIW'],
  Hjerkinn: ['YVH'],
  Hluhluwe: ['HLW'],
  'Ho Chi Minh City': ['SGN'],
  Hobart: ['HBA'],
  'Hobart Bay': ['HBH'],
  Hobbs: ['HBB', 'HOB'],
  Hodeidah: ['HOD'],
  Hoedspruit: ['HDS'],
  'Hof De': ['HOQ'],
  Hofuf: ['HOF'],
  Hogatza: ['HGZ'],
  Hohenems: ['HOH'],
  Hohhot: ['HET'],
  Hokitika: ['HKK'],
  Holguin: ['HOG'],
  Holland: ['HLM'],
  Hollis: ['HYL'],
  Hollister: ['HLI'],
  Holyhead: ['HLY'],
  Homer: ['HOM'],
  Homestead: ['HST'],
  'Hong Kong': ['HKC', 'HHP', 'HKG'],
  Honiara: ['HIR'],
  Honningsvag: ['HVG'],
  Honolulu: ['HNL'],
  Hoolehua: ['MKK'],
  Hoonah: ['HNH'],
  Hope: ['YHE'],
  Hopedale: ['YHO'],
  Hopkinsville: ['HOP'],
  Horizontina: ['HRZ'],
  'Horn Island': ['HID'],
  Hornafjordur: ['HFN'],
  Hornepayne: ['YHN'],
  Horta: ['HOR'],
  Hoskins: ['HKN'],
  'Hot Springs': ['HSP', 'HOT'],
  Hotan: ['HTN'],
  Houeisay: ['HOE'],
  Houghton: ['CMX'],
  Houma: ['HUM'],
  Houston: [
    'DWH',
    'JGP',
    'ZHO',
    'EFD',
    'JDX',
    'IAH',
    'JGQ',
    'JWH',
    'HOU',
    'IWS',
  ],
  Hsinchun: ['HSZ'],
  Hu: ['HUI'],
  'Hua Hin': ['HHQ'],
  Huahine: ['HUH'],
  'Huai An': ['HIA'],
  Hualien: ['HUN'],
  Huanghua: ['HHA'],
  Huangshi: ['HSD'],
  Huanuco: ['HUU'],
  Hubli: ['HBX'],
  Hudiksvall: ['HUV'],
  'Hudson Bay': ['YHB'],
  Huelva: ['HEV'],
  Huesca: ['XUA', 'HSK'],
  Hughenden: ['HGD'],
  Hughes: ['HUS'],
  Huizhou: ['HUZ'],
  Hultsfred: ['HLF'],
  Hulunbuir: ['XRQ'],
  Humacao: ['HUC', 'PPD'],
  Humberside: ['HUY'],
  Huntingburg: ['HNB'],
  Huntingdon: ['XHU'],
  Huntington: ['PMH', 'HTS'],
  Huntsville: ['HTV', 'HSV', 'HUA'],
  Hurghada: ['HRG'],
  Huron: ['HON'],
  Husavik: ['HZK'],
  Huslia: ['HSL'],
  Hutchinson: ['HUT'],
  Huzhou: ['HZC'],
  Hwange: ['HWN'],
  Hyannis: ['HYA'],
  Hyder: ['WHD'],
  Hyderabad: ['BPM', 'HDD', 'HYD'],
  Iasi: ['IAS'],
  Ibadan: ['IBA'],
  Ibague: ['IBE'],
  Ibaraki: ['IBR'],
  Ibiza: ['IBZ'],
  'Icy Bay': ['ICY'],
  'Idaho Falls': ['IDA'],
  Ifuru: ['IFU'],
  Igaliku: ['QFX'],
  Igarka: ['IAA'],
  Igdir: ['IGD'],
  Iginniarfik: ['QFI'],
  Igloolik: ['YGT'],
  IGRIM: ['IRM'],
  'Iguassu Falls': ['IGU'],
  Iguatu: ['QIG'],
  Iguazu: ['IGR'],
  Ikamiut: ['QJI'],
  'Ikaria Island': ['JIK'],
  Ikerasaarsuk: ['QRY'],
  Ikerasak: ['IKE'],
  'Iki Jp': ['IKI'],
  'Ile D Yeu': ['IDY'],
  'Ile Des Pins': ['ILP'],
  'Iles De Madeleine': ['YGR'],
  Ilford: ['ILF'],
  Ilheus: ['IOS'],
  Illizi: ['VVZ'],
  Illorsuit: ['IOT'],
  Iloilo: ['ILO'],
  Ilulissat: ['JAV'],
  Imperatriz: ['IMP'],
  'Imperial Beach': ['NRS'],
  Imphal: ['IMF'],
  Inagua: ['IGA'],
  Independence: ['INB'],
  Indiana: ['IDI'],
  Indianapolis: ['IND'],
  'Indigo Bay Lodge': ['IBL'],
  Indore: ['IDR'],
  Ingersoll: ['XIB'],
  Ingham: ['IGH'],
  Ingolstadt: ['IGS', 'ZNQ'],
  Inhambane: ['INH'],
  Injune: ['INJ'],
  Innarsuit: ['IUI'],
  Innisfail: ['IFL'],
  Innsbruck: ['INN', 'IOB'],
  Interlaken: ['ZIN'],
  'International Falls': ['INL'],
  Inukjuak: ['YPH'],
  Inuvik: ['YEV'],
  Invercargill: ['IVC'],
  Inverell: ['IVR'],
  Inverness: ['INV', 'ZIV'],
  Inyokern: ['IYK'],
  Ioannina: ['IOA'],
  'Iowa City': ['IOW'],
  Ipatinga: ['IPN'],
  Ipiales: ['IPI'],
  Ipiranga: ['IPG'],
  Ipoh: ['IPH'],
  Ipota: ['IPA'],
  Ipswich: ['IPW'],
  Iqaluit: ['YFB'],
  Iquique: ['IQQ'],
  Iquitos: ['IQT'],
  Irece: ['IRE'],
  Iringa: ['IRI'],
  Irkutsk: ['IKT'],
  'Iron Mountain': ['IMT'],
  Ironwood: ['IWD'],
  'Isabel Pass': ['ISL'],
  Isafjordur: ['IFJ'],
  Ischia: ['ISH'],
  Isfahan: ['IFN'],
  Ishigaki: ['ISG'],
  'Isla Grande': ['SIG'],
  'Isla Mujeres': ['ISJ'],
  Islamabad: ['ISB'],
  Islay: ['ILY'],
  'Isle Of Man': ['IOM'],
  'Isle Of Skye Hebrides Islands': ['SKL'],
  'Isles Of Scilly': ['ISC', 'TSO'],
  Islip: ['ISP'],
  Isortoq: ['IOQ'],
  Isparta: ['ISE'],
  Istanbul: ['IST', 'SAW'],
  Itaituba: ['ITB'],
  Itaperuna: ['ITP'],
  Ithaca: ['ITH'],
  Itilleq: ['QJG'],
  Itokama: ['ITK'],
  Ittoqqortoormiit: ['OBY'],
  'Iturup Island': ['BVV'],
  Ivalo: ['IVL'],
  'Ivano Frankovsk': ['IFO'],
  Ivanovo: ['IWA'],
  Ivishak: ['IVH'],
  Ivujivik: ['YIK'],
  Iwakuni: ['IWK'],
  Iwami: ['IWJ'],
  Ixtepec: ['IZT'],
  Izhevsk: ['IJK'],
  Izmir: ['ADB', 'IGL'],
  Izumo: ['IZO'],
  Jabalpur: ['JLR'],
  Jabiru: ['JAB'],
  Jackson: ['HKS', 'JAC', 'JAN', 'JXN', 'MKL'],
  Jacksonville: ['OAJ', 'JKV', 'CRG', 'JAX', 'IJX', 'NIP', 'LRF'],
  Jaen: ['JAE'],
  'Jaen ES': ['JEA'],
  Jaffna: ['JAF'],
  Jagdalpur: ['JGB'],
  Jaguaruna: ['JJG'],
  Jaipur: ['JAI'],
  Jakarta: ['HLP', 'CGK'],
  Jalapa: ['JAL'],
  Jambi: ['DJB'],
  Jamestown: ['JHW', 'JMS'],
  Jammu: ['IXJ'],
  Jamnagar: ['JGA'],
  Jamshedpur: ['IXW'],
  Janakpur: ['JKR'],
  Jandakot: ['JAD'],
  Jasper: ['YJA', 'XDH'],
  Jatai: ['JTI'],
  Jauja: ['JAU'],
  Jayapura: ['DJJ'],
  Jeddah: ['JED'],
  'Jefferson City': ['JEF'],
  'Jeju City': ['JCJ', 'CJU', 'JSP'],
  Jember: ['JBB'],
  Jena: ['ZJS'],
  Jeonju: ['CHN'],
  Jequie: ['JEQ'],
  'Jerez De La Frontera': ['YJW', 'XRY'],
  Jersey: ['JER'],
  Jerusalem: ['JRS'],
  Jessore: ['JSR'],
  'Ji Parana': ['JPR'],
  'Jia Ge Da Qi': ['JGD'],
  Jiamusi: ['JMU'],
  Jian: ['JGS'],
  'Jiang Men': ['ZBD'],
  Jiaxing: ['JXS'],
  Jiayuguan: ['JGN'],
  Jijiga: ['JIJ'],
  Jilin: ['JIL', 'JLL'],
  Jimma: ['JIM'],
  Jinan: ['TNA'],
  Jinchang: ['JIC'],
  Jingdezhen: ['JDZ'],
  Jinghong: ['JHG'],
  Jingzhou: ['EJJ'],
  JINING: ['JNG'],
  Jinjiang: ['JJN'],
  Jinzhou: ['JNZ'],
  Jiujiang: ['JIU'],
  Jixi: ['JXA'],
  Joacaba: ['JCB'],
  'Joao Pessoa': ['JPA'],
  Jodhpur: ['JDH'],
  Joensuu: ['JOE'],
  Johannesburg: ['GCJ', 'HLA', 'JNB', 'HCS', 'QRA'],
  Johnstown: ['JST'],
  'Johor Bahru': ['JHB'],
  Joinville: ['JOI'],
  Joliet: ['JOT'],
  Joliette: ['XJL'],
  Jolo: ['JOL'],
  Jolon: ['HGT'],
  Jonesboro: ['JBR'],
  Jonkoping: ['JKG', 'XYF'],
  Jonquiere: ['XJQ'],
  Joplin: ['JLN'],
  Jorhat: ['JRH'],
  'Jos NG': ['JOS'],
  'Juazeiro Do Norte': ['JDO'],
  Juba: ['JUB'],
  Juina: ['JIA'],
  Juist: ['JUI'],
  'Juiz De Fora': ['JDF', 'IZA'],
  Jujuy: ['JUJ'],
  'Julia Creek': ['JCK'],
  Juliaca: ['JUL'],
  Juneau: ['JNU'],
  Jurado: ['JUO'],
  Juzhou: ['JUZ'],
  Jyvaskyla: ['JYV'],
  Kabul: ['KBL'],
  'Kadhdhoo Island': ['KDO'],
  Kaduna: ['KAD'],
  Kagi: ['KGW'],
  Kagoshima: ['KOJ'],
  'Kagvik Creek': ['KKF'],
  Kahramanmaras: ['KCM'],
  Kahului: ['OGG'],
  Kaikoura: ['KBZ'],
  Kailashahar: ['IXH'],
  Kaili: ['KJH'],
  Kaiserslautern: ['KLT'],
  Kaitaia: ['KAT'],
  Kajaani: ['KAJ'],
  Kakamega: ['GGM'],
  Kake: ['KAE'],
  Kakhonak: ['KNK'],
  Kalakaket: ['KKK'],
  Kalamata: ['KLX'],
  Kalamazoo: ['AZO'],
  Kalaupapa: ['LUP'],
  Kalgoorlie: ['KGI'],
  Kalibo: ['KLO'],
  Kaliningrad: ['KGD'],
  Kalispell: ['FCA'],
  Kalmar: ['KLR'],
  Kalskag: ['KLG'],
  Kaluga: ['KLF'],
  'Kalymnos Island': ['JKL'],
  Kamalpur: ['IXQ'],
  Kamishly: ['KAC'],
  Kamloops: ['YKA'],
  Kamuela: ['MUE'],
  Kanab: ['KNB'],
  Kandahar: ['KDH'],
  Kandavu: ['KDV'],
  Kandersteg: ['ZHR'],
  Kandla: ['IXY'],
  'Kandy Polgolla Reservoir': ['KDZ'],
  Kangaamiut: ['QKT'],
  Kangaatsiaq: ['QPW'],
  Kangding: ['KGT'],
  Kangerluk: ['QGR'],
  Kangerlussuaq: ['SFJ'],
  Kangersuatsiaq: ['KGQ'],
  Kangiqsualujjuaq: ['XGR'],
  Kangiqsujuaq: ['YWB'],
  Kangirsuk: ['YKG'],
  Kankakee: ['IKK'],
  Kano: ['KAN'],
  Kanpur: ['KNU'],
  'Kansas City': ['OJC', 'JCI', 'MCI', 'MKC'],
  Kaohsiung: ['KHH'],
  Kapalua: ['JHM'],
  Kapolei: ['JRF'],
  Kapuskasing: ['YYU'],
  Karachi: ['KHI'],
  Karaganda: ['KGF'],
  Karamay: ['KRY'],
  'Kariba Dam': ['KAB'],
  'Karlovy Vary': ['KLV'],
  Karlshamn: ['XYO'],
  'Karlsruhe Baden Baden': ['ZCC', 'XET', 'FKB'],
  Karlstad: ['KSD'],
  'Karluk Lake': ['KKL'],
  Karpathos: ['AOK'],
  Karratha: ['KTA'],
  Kars: ['KSY'],
  Karshi: ['KSQ'],
  Karumba: ['KRB'],
  Karup: ['KRP'],
  'Kasabonika Lake': ['XKS'],
  Kasane: ['BBK'],
  Kashan: ['KKS'],
  Kashechewan: ['ZKE'],
  Kashi: ['KHG'],
  Kasigluk: ['KUK'],
  'Kasos Island': ['KSJ'],
  Kassel: ['KJR', 'KSF', 'KWQ'],
  Kastamonu: ['KFS'],
  Kastelorizo: ['KZS'],
  Kastoria: ['KSO'],
  Katherine: ['KTR'],
  Kathmandu: ['KTM'],
  'Katmai National Park': ['BKF'],
  Katowice: ['KTW'],
  Katrineholm: ['XXK'],
  Katsina: ['DKA'],
  Kaubang: ['KAZ'],
  Kaunas: ['KUN'],
  Kavalla: ['KVA'],
  Kavieng: ['KVG'],
  Kawthaung: ['KAW'],
  Kayseri: ['ASR'],
  Kazan: ['KZN'],
  Kearney: ['EAR'],
  Keekorok: ['KEU'],
  Keene: ['EEN'],
  Kefalonia: ['EFL'],
  Kegaska: ['ZKG'],
  Kelowna: ['YLW'],
  Kelso: ['KLS'],
  Kemble: ['GBA'],
  Kemerovo: ['KEJ'],
  Kemi: ['KEM'],
  Kempsey: ['KPS'],
  Kenai: ['ENA'],
  Kendari: ['KDI'],
  Kennett: ['KNT'],
  Kenora: ['YQK'],
  Kenosha: ['ENW'],
  Kentland: ['KKT'],
  Keokuk: ['EOK'],
  Keperveyem: ['KPW'],
  'Kepulauan Selayar': ['KSR'],
  Kerema: ['KMA'],
  Kerikeri: ['KKE'],
  Kerkyra: ['CFU'],
  Kerman: ['KER'],
  Kermanshah: ['KSH'],
  Kerrville: ['ERV'],
  'Kerry County': ['KIR'],
  Kerteh: ['KTE'],
  Keshod: ['IXK'],
  Ketapang: ['KTG'],
  Ketchikan: ['WFB', 'KTN'],
  'Key Largo': ['OCA'],
  'Key West': ['EYW', 'NQX'],
  Keystone: ['QKS'],
  Khabarovsk: ['KHV'],
  Khajuraho: ['HJR'],
  'Khamis Mushait': ['KMX'],
  Khandyga: ['KDY'],
  'Khanty Mansiysk': ['HMA'],
  Kharga: ['UVL'],
  Kharkov: ['HRK'],
  Khartoum: ['KRT'],
  Khasab: ['KHS'],
  Kherson: ['KHE'],
  'Khon Kaen': ['KKC'],
  Khonuu: ['MQJ'],
  Khorramabad: ['KHD'],
  khowai: ['IXN'],
  Khujand: ['LBD'],
  'Kichwa Tembo': ['KTJ'],
  Kiel: ['KEL'],
  Kieta: ['KIE'],
  Kiev: ['KBP', 'QOF', 'QOH', 'IEV'],
  Kigali: ['KGL'],
  Kigoma: ['TKQ'],
  Kihihi: ['KHX'],
  'Kil Sweden': ['XXN'],
  Kilimanjaro: ['JRO'],
  Killeen: ['HLR', 'GRK', 'ILE'],
  Kimberley: ['KIM'],
  'Kimmirut Lake Harbour': ['YLC'],
  Kincardine: ['YKD'],
  'King City': ['KIC'],
  'King Island': ['KNS'],
  'King Khalid Military City': ['KMC'],
  'King Salmon': ['AKN'],
  'Kingfisher Lake Indian Reserve': ['KIF'],
  Kingman: ['IGM'],
  'Kings Canyon': ['KBJ'],
  'Kings Lynn': ['KNF'],
  Kingscote: ['KGC'],
  Kingston: ['XEG', 'KIN', 'YGK', 'KTP'],
  Kingsville: ['NQI'],
  Kinmen: ['KNH'],
  Kinshasa: ['FIH', 'FIS', 'NLO'],
  Kinston: ['ISO'],
  Kipnuk: ['KPN'],
  Kirkenes: ['KKN'],
  Kirkland: ['YKX'],
  Kirksville: ['IRK'],
  Kirkwall: ['KOI'],
  'Kirov Pobedilovo': ['KVX'],
  Kirovsk: ['KVK'],
  Kiruna: ['KRN'],
  'Kiryat Shmona': ['KSW'],
  Kissimmee: ['ISM'],
  Kisumu: ['KIS'],
  'Kita Kyushu': ['KKJ'],
  Kitale: ['KTL'],
  Kitchener: ['YKF'],
  Kithira: ['KIT'],
  Kitsissuarsuit: ['QJE'],
  Kittila: ['KTT'],
  KITZBUEHEL: ['XJS'],
  Kiunga: ['UNG'],
  Kivalina: ['KVL'],
  Klagenfurt: ['KLU', 'KGV'],
  'Klamath Falls': ['LMT'],
  Klawock: ['KLW'],
  Klerksdorp: ['KXE'],
  Klosters: ['ZHS'],
  Knock: ['NOC'],
  Knoxville: ['TYS'],
  Kobe: ['UKB'],
  Kobuk: ['OBU'],
  Kocaeli: ['KCO'],
  Kochi: ['COK', 'KCZ'],
  Kodiak: ['ADQ', 'WDY', 'KDK'],
  Kogalym: ['KGP'],
  Koggala: ['KCT'],
  'Koh Samui': ['USM'],
  Kokkola: ['KOK'],
  Kokomo: ['OKK'],
  Kokoro: ['KOR'],
  Kokshetau: ['KOV'],
  Kolding: ['ZBT'],
  Kolhapur: ['KLH'],
  Kolkata: ['CCU'],
  KOMATIPOORT: ['KOF'],
  Komatsu: ['KMQ'],
  'Komsomolsk Na Amure': ['KXK'],
  Kona: ['KOA'],
  Kongsvinger: ['XZD'],
  Konya: ['KYA'],
  'Kooddoo Island': ['GKK'],
  Kopasker: ['OPA'],
  Korla: ['KRL'],
  'Koro Island': ['KXF'],
  Koror: ['ROR'],
  Kortrijk: ['KJK'],
  'Kos Gr': ['KGS'],
  Kosice: ['KSC'],
  Kosrae: ['KSA'],
  Kostanay: ['KSN'],
  Kostroma: ['KMW'],
  Kota: ['KTU'],
  'Kota Bharu': ['KBR'],
  'Kota Kinabalu': ['BKI'],
  Kotabaru: ['KBU'],
  Kotte: ['DWO'],
  Kotzebue: ['OTZ'],
  Koumac: ['KOC'],
  Koutaba: ['KOB'],
  Kouvola: ['UTI'],
  Koyuk: ['KKA'],
  Kozhikode: ['CCJ'],
  Krabi: ['KBV'],
  Krakow: ['VKR', 'KRK'],
  Kramfors: ['KRF'],
  Krasnodar: ['KRR'],
  Krasnoyarsk: ['KJA'],
  Kreuzlingen: ['ZHU'],
  Kristiansand: ['XGD', 'KRS', 'XKR'],
  Kristianstad: ['KID'],
  Kristiansund: ['XGI', 'KSU'],
  Kristinehamn: ['XYN'],
  'Krivoy Rog': ['KWG'],
  'Kuala Belait': ['KUB'],
  'Kuala Lumpur': ['KUL', 'XKL', 'SZB'],
  'Kuala Terengganu': ['TGG'],
  Kuantan: ['KUA'],
  Kuching: ['KCH'],
  Kudahuvadhoo: ['DDD'],
  Kudat: ['KUD'],
  'Kugluktuk Coppermine': ['YCO'],
  Kuito: ['SVP'],
  'Kulik Lake': ['LKK'],
  Kullorsuaq: ['KHQ'],
  Kulob: ['TJU'],
  Kulu: ['KUU'],
  Kulusuk: ['KUS'],
  Kumamoto: ['KMJ'],
  Kumasi: ['KMS'],
  'Kume Jima': ['UEO'],
  Kumla: ['XXV'],
  Kundiawa: ['CMU'],
  Kunming: ['KMG'],
  Kunsan: ['KUV'],
  Kunshan: ['KVN'],
  Kununurra: ['KNX'],
  Kuopio: ['KUO'],
  Kupang: ['KOE'],
  Kuparuk: ['UUK'],
  Kuqa: ['KCA'],
  Kuressaare: ['URE'],
  Kurgan: ['KRO'],
  Kursk: ['URS'],
  Kuruman: ['KMH'],
  Kushiro: ['KUH'],
  Kutahya: ['KZR'],
  Kutaisi: ['KUT'],
  Kuujjuaq: ['YVP'],
  Kuummiut: ['KUZ'],
  Kuusamo: ['KAO'],
  Kuwait: ['KWI'],
  Kwajalein: ['KWA'],
  Kwangju: ['KWJ'],
  Kyoto: ['UKY'],
  'Kzyl Orda': ['KZO'],
  'La Baule': ['LBY'],
  'La Ceiba': ['LCE'],
  'La Chaux de Fonds': ['ZHV'],
  'La Chorrera': ['LCR'],
  'La Coruna': ['YJC', 'LCG'],
  'La Crosse': ['LSE'],
  'La Desirade': ['DSD'],
  'La Grande': ['YGL', 'LGD'],
  'La Grange': ['LGC'],
  'La Palma del Condado': ['NDO'],
  'La Paz': ['LPB', 'LAP'],
  'La Pedrera': ['LPD'],
  'La Plata': ['LPG'],
  'La Porte': ['LPO'],
  'La Rioja': ['IRJ'],
  'La Roche': ['EDM'],
  'La Rochelle': ['LRH'],
  'La Romana': ['LRM'],
  'La Sarre': ['SSQ'],
  'La Serena': ['LSC'],
  'La Tabatiere': ['ZLT'],
  'La Tuque': ['YLQ'],
  'La Verne': ['POC'],
  Labasa: ['LBS'],
  'Labouchere Bay': ['WLB'],
  Labuan: ['LBU'],
  'Labuan Bajo': ['LBJ'],
  'Lac Brochet Indian Reserve': ['XLB'],
  'Lac Edouard': ['XEE'],
  'Lac La Biche': ['YLB'],
  Laconia: ['LCI'],
  Ladysmith: ['LAY', 'XEH'],
  'Lae Pg': ['LAE'],
  'Laeso Island': ['BYR'],
  Lafayette: ['LAF', 'LFT'],
  Lagos: ['LOS'],
  'Lagos De Moreno': ['LOM'],
  'Lahad Datu': ['LDU'],
  Lahat: ['PXA'],
  Lahore: ['LHE'],
  Lahr: ['LHA'],
  Lajes: ['LAJ'],
  'Lake Charles': ['LCH'],
  'Lake City': ['LCQ'],
  'Lake Geneva': ['XES'],
  'Lake Havasu Cty': ['HII'],
  'Lake Jackson': ['LJN'],
  'Lake Minchumina': ['LMA'],
  'Lake Of The Ozarks': ['AIZ'],
  'Lake Tahoe': ['TVL'],
  Lakeland: ['LAL'],
  Lakeview: ['LKV'],
  Lakselv: ['LKL'],
  Lalibela: ['LLI'],
  Lamacarena: ['LMC'],
  Lamap: ['LPM'],
  Lamar: ['LAA'],
  Lamassa: ['LMG'],
  'Lamen Bay': ['LNB'],
  'Lamezia Terme': ['SUF'],
  Lampang: ['LPT'],
  Lampedusa: ['LMP'],
  Lamu: ['LAU'],
  Lanai: ['LNY'],
  Lancaster: ['LNS', 'ZRL', 'WJF'],
  'Lands End': ['LEQ'],
  Landskrona: ['JLD'],
  Langcang: ['JMJ'],
  Langenthal: ['ZHW'],
  Langfang: ['LFA'],
  Langford: ['XEJ'],
  Langkawi: ['LGK'],
  Langley: ['YLY'],
  Lankaran: ['LLK'],
  Lannion: ['LAI'],
  Lansing: ['LAN'],
  Lanzarote: ['ACE'],
  Lanzhou: ['LZX', 'LZD', 'LHW'],
  Laoag: ['LAO'],
  Lappeenranta: ['LPP'],
  Laramie: ['LAR'],
  Laredo: ['LRD'],
  Larisa: ['LRA'],
  Larnaca: ['LCA'],
  'Larsen Bay': ['KLN'],
  'Las Cruces': ['LRU'],
  'Las Piedras': ['LSP'],
  'Las Termas De Rio Hondo': ['RHD'],
  'Las Vegas': ['HSH', 'LVS', 'VGT', 'LAS', 'LSV', 'TCC'],
  Latacunga: ['LTX'],
  Latakia: ['LTK'],
  Latrobe: ['LBE'],
  Latur: ['LTU'],
  Launceston: ['LST'],
  Laurel: ['LUL', 'PIB'],
  Lausanne: ['QLS'],
  Laval: ['LVA'],
  Laverton: ['LVO'],
  Lawas: ['LWY'],
  Lawrence: ['LWM', 'LWC'],
  Lawton: ['LAW'],
  'Lazaro Cardenas Michoacan': ['LZC'],
  'Le Creusot': ['XCC'],
  'Le Havre': ['LEH'],
  'Le Locle': ['ZJA'],
  'Le Mans': ['LME', 'ZLN'],
  'Le Puy': ['LPY'],
  'Le Touquet': ['LTQ'],
  Leadville: ['LXV'],
  Learmonth: ['LEA'],
  Lebanon: ['CNH', 'LEB'],
  Lecce: ['LCC'],
  Leeds: ['LBA'],
  Leesburg: ['LEE'],
  Leeton: ['QLE'],
  Legaspi: ['LGP'],
  'Leh IN': ['IXL'],
  Leicester: ['XGM', 'QEW'],
  Leinster: ['LER'],
  Leipzig: ['LEJ', 'XIT'],
  Leiria: ['QLR'],
  Leknes: ['LKN'],
  Leksand: ['XXO'],
  Lelystad: ['LEY'],
  Lemoore: ['NLC'],
  Lencois: ['LEC'],
  Lensk: ['ULK'],
  Lenzburg: ['ZJC'],
  Leon: ['BJX', 'LEN', 'EEU'],
  Leonora: ['LNO'],
  Lerida: ['QLQ'],
  Leros: ['LRS'],
  Lerwick: ['LSI', 'LWK'],
  'Les Sables': ['LSO'],
  Lesobeng: ['LES'],
  Lethbridge: ['YQL'],
  Leticia: ['LET'],
  Levanger: ['XUH'],
  Lewisburg: ['LWB'],
  Lewiston: ['LEW', 'LWS'],
  Lewistown: ['LWT'],
  Lexington: ['LEX', 'LSD', 'LXN'],
  Lhasa: ['LXA'],
  Lianping: ['LIA'],
  Lianyungang: ['LYG'],
  Liaoyang: ['LQQ'],
  Liberal: ['LBL'],
  Liberia: ['LIR'],
  Libo: ['LLB'],
  Libreville: ['LBV'],
  Lichfield: ['XQT'],
  Lichinga: ['VXC'],
  Lidkoping: ['LDK'],
  Liege: ['LGG', 'XHN'],
  Liepaja: ['LPX'],
  Lifou: ['LIF'],
  'Lightning Ridge': ['LHG'],
  'Lihir Island': ['LNV'],
  Lihue: ['LIH'],
  'Lijiang City': ['LJG'],
  Lilabari: ['IXI'],
  Lille: ['LIL', 'XDB', 'XFA'],
  'Lille Hammer': ['XXL'],
  Lilongwe: ['LLW'],
  Lima: ['AOH', 'LIM'],
  Limbang: ['LMN'],
  Limburg: ['ZNW'],
  'Lime Village': ['LVD'],
  Limnos: ['LXS'],
  Limoges: ['LIG'],
  Limon: ['LIO', 'LIC'],
  LINCANG: ['LNJ'],
  Lincoln: ['LNK'],
  Lindau: ['QII'],
  Linden: ['LDJ'],
  Linfen: ['LFQ'],
  'Ling Ling': ['LLF'],
  Lingshui: ['LQS'],
  Linhai: ['LHC'],
  Linkoping: ['LPI'],
  Linyi: ['LYI'],
  Linz: ['LNZ', 'LZS'],
  Lipetsk: ['LPK'],
  Liping: ['HZH'],
  Lisbon: ['LIS', 'ZYD'],
  Lismore: ['LSY'],
  'Little Cayman': ['LYB'],
  'Little Grand Rapids': ['ZGR'],
  'Little Port Walter': ['LPW'],
  'Little Rock': ['LIT'],
  Liupanshui: ['LPF'],
  Liuzhou: ['LZH', 'RLZ'],
  Livengood: ['LIV'],
  Livermore: ['LVK'],
  Liverpool: ['LPL', 'LXC', 'LXL'],
  Livingston: ['LVM'],
  Livingstone: ['LVI'],
  'Lizard Island': ['LZR'],
  Ljubljana: ['LJU', 'LJR'],
  Lleida: ['ILD'],
  Lloydminster: ['YLL'],
  Locarno: ['ZJI'],
  Lochgilphead: ['LPH'],
  Lockhart: ['IRG'],
  Lodwar: ['LOK'],
  Lodz: ['LKJ', 'LCJ'],
  Loei: ['LOE'],
  Logan: ['LGU'],
  Logrono: ['RJL', 'LWG', 'LGV'],
  Loja: ['LOH'],
  Lokichoggio: ['LKG'],
  Lome: ['LFW'],
  Lompoc: ['LPC', 'VBG'],
  London: [
    'LOZ',
    'XQE',
    'LGW',
    'LHR',
    'QQU',
    'QQK',
    'QQP',
    'ZEP',
    'QQW',
    'BQH',
    'LCY',
    'YXU',
    'ZLS',
    'XDQ',
    'XOF',
    'LTN',
    'SEN',
    'QQS',
    'STN',
  ],
  Londonderry: ['LDY'],
  Londrina: ['LDB'],
  Lonely: ['LNI'],
  'Long Akah': ['LKH'],
  'Long Banga': ['LBP'],
  'Long Beach': ['LGB'],
  'Long Island': ['HAP'],
  'Long Lellang': ['LGL'],
  'Long Seridan': ['ODN'],
  Longana: ['LOD'],
  Longreach: ['LRE'],
  Longview: ['GGG'],
  Longyan: ['LCX'],
  Longyearbyen: ['LYR'],
  Lonorore: ['LNE'],
  'Lopez Island': ['LPS'],
  'Lord Howe Island': ['LDH'],
  Loreto: ['LTO'],
  Lorient: ['LRT'],
  Loring: ['WLR'],
  'Los Alamos': ['LAM'],
  'Los Angeles': [
    'JBP',
    'LAX',
    'LSQ',
    'SMO',
    'JTO',
    'JLX',
    'VNY',
    'JWC',
    'WHP',
  ],
  'Los Banos': ['LSN'],
  'Los Mochis': ['LMM'],
  'Lost Harbour': ['LHB'],
  Losuia: ['LSA'],
  Louangphrabang: ['LPQ'],
  Loughborough: ['XQI'],
  Louisville: ['LOU', 'SDF', 'LMS'],
  Lourdes: ['LDE'],
  'Lower Zambezi Nat Park': ['JEK', 'RYL'],
  Loyangalani: ['LOY'],
  Luanda: ['LAD'],
  'Luang Namtha': ['LXG'],
  Lubango: ['SDD'],
  Lubbock: ['LBB', 'REE'],
  Lublin: ['LUZ', 'QLU'],
  Lubumbashi: ['FBM'],
  'Lucas Do Rio Verde': ['LVR'],
  Lucca: ['LCV'],
  Lucerne: ['QLJ'],
  Lucknow: ['LKO'],
  Luderitz: ['LUD'],
  Ludhiana: ['LUH'],
  Ludwigslust: ['ZLU'],
  Luebeck: ['LBC'],
  Luena: ['LUO'],
  Lueneburg: ['ZOG'],
  Lufkin: ['LFK'],
  Lugano: ['LUG'],
  Lugansk: ['VSG'],
  Lugo: ['LUY'],
  Lulea: ['LLA'],
  Luliang: ['LLV'],
  Lumberton: ['LBT'],
  'Lund C': ['XGC'],
  Luoyang: ['LYA'],
  Lusaka: ['LUN'],
  Lutselke: ['YSG'],
  Lutsk: ['UCK'],
  Luwuk: ['LUW'],
  Luxembourg: ['LUX'],
  Luxi: ['LUM'],
  Luxor: ['LXR'],
  Luzhou: ['LZO'],
  'Luzon Island': ['CRK', 'NCP'],
  Lviv: ['LWO'],
  Lycksele: ['LYC'],
  Lydd: ['LYX'],
  Lynchburg: ['LYH'],
  'Lynn Lake': ['YYL'],
  Lyon: ['LYN', 'XYD', 'XYL', 'LYS', 'XYV'],
  Lysaker: ['XUI'],
  Lyss: ['ZJL'],
  'Maamigili Island': ['VAM'],
  Maan: ['MPQ'],
  Maastricht: ['MST', 'ZYT'],
  Macae: ['MEA'],
  Macapa: ['MCP'],
  Macas: ['XMS'],
  Macau: ['XZM', 'MFM', 'YFT'],
  Macclesfield: ['XMZ'],
  Maceio: ['MCZ'],
  Macheng: ['HBM'],
  'Machu Picchu': ['MFT'],
  Mackay: ['MKY'],
  Macon: ['MCN', 'MAC', 'WRB'],
  Madang: ['MAG'],
  Madera: ['MAE'],
  Madinah: ['MED'],
  Madison: ['DXE', 'MSN'],
  Madras: ['MDJ'],
  Madrid: ['MAD', 'XOC', 'XTI', 'TOJ'],
  Madurai: ['IXM'],
  'Mae Hongson': ['HGN'],
  'Mae Sot': ['MAQ'],
  Magadan: ['GDX'],
  Magan: ['GYG'],
  Magas: ['IGT'],
  Magdeburg: ['ZMG'],
  'Magdeburg Cochstedt': ['CSO'],
  Magnitogorsk: ['MQF'],
  Magnolia: ['AGO'],
  Magong: ['MZG'],
  'Mahe Island': ['SEZ'],
  Mahenye: ['MJW'],
  Maiana: ['MNK'],
  Mainz: ['QMZ'],
  Maio: ['MMO'],
  Maitland: ['MTL'],
  Majunga: ['MJN'],
  Majuro: ['MAJ'],
  Makhachkala: ['MCX'],
  Makkovik: ['YMN'],
  Makurdi: ['MDI'],
  'Mala Mala': ['AAM'],
  Malabo: ['SSG'],
  Malacca: ['MKZ'],
  Malaga: ['AGP', 'YJM'],
  Malakal: ['MAK'],
  Malang: ['MLG'],
  Malatya: ['MLX'],
  Malda: ['LDA'],
  Male: ['KDM', 'MLE'],
  Malelane: ['LLE'],
  Malindi: ['MYD'],
  Malmo: ['XFP', 'JMM', 'MMA', 'XFR', 'MMX'],
  Malololailai: ['PTF'],
  Maloy: ['QFQ'],
  Malta: ['MLA'],
  Mamburao: ['MBO'],
  'Mammoth Lakes': ['MMH'],
  Manado: ['MDC'],
  Managua: ['MGA'],
  Manassas: ['MNZ'],
  Manaus: ['MAO'],
  Manchester: ['XVC', 'QQM', 'MHT', 'ZMP', 'MAN'],
  Mandalay: ['MDL'],
  Mandalgobi: ['MXW'],
  Mangalore: ['IXE'],
  Mangochi: ['MAI'],
  Mangunnyeon: ['MWX'],
  Manhattan: ['MHK'],
  Manicore: ['MNX'],
  Maniitsoq: ['JSU'],
  Manila: ['MXA', 'MNL', 'SGL'],
  Maningrida: ['MNG'],
  Manistee: ['MBL'],
  Manistique: ['ISQ'],
  Manitouwadge: ['YMG'],
  Manitowoc: ['MTW'],
  Manizales: ['MZL'],
  Mankato: ['MKT'],
  'Mannheim Germany': ['MHJ', 'MHG'],
  Manokotak: ['KMO'],
  Manokwari: ['MKW'],
  Manono: ['MNO'],
  Mansfield: ['MFD'],
  Manston: ['MSE'],
  Manta: ['MEC'],
  Manteo: ['MEO'],
  'Manus Island': ['MAS'],
  Manzanillo: ['ZLO', 'MZO'],
  Manzhouli: ['NZH'],
  Manzini: ['SHO', 'MTS'],
  Maota: ['MXS'],
  Maputo: ['MPM'],
  'Mar Del Plata': ['MDQ'],
  'Mara Lodges': ['MRE', 'OSJ', 'OLG'],
  Maraba: ['MAB'],
  Maracaibo: ['MAR'],
  Maracay: ['MYC'],
  Marana: ['MZJ'],
  Marathon: ['MTH', 'YSP'],
  Marbella: ['EVO'],
  'Marble Canyon': ['MYH'],
  Mardin: ['MQM'],
  'Margaret River': ['MQZ'],
  'Margaret River Station': ['MGV'],
  Margate: ['MGH'],
  'Marianske Lazne': ['MKA'],
  Maribor: ['MBX'],
  Mariehamn: ['MHQ'],
  Marietta: ['MGE'],
  Marilia: ['MII'],
  'Marina Di Massa': ['QMM'],
  Marinduque: ['MRQ'],
  Maringa: ['MGF'],
  Marion: ['MWA'],
  'Marion Downs': ['MXD'],
  Mariposa: ['RMY'],
  Mariupol: ['MPW'],
  'Market Harborough': ['XQM'],
  Markovo: ['KVM'],
  Marnardal: ['ZYY'],
  Maroantsetra: ['WMN'],
  Maroochydore: ['MCY'],
  Maroua: ['MVR'],
  Marquette: ['MQT'],
  Marrakech: ['RAK'],
  'Marsa Alam': ['RMF'],
  Marseille: ['MRS', 'XRF'],
  'Marsh Harbour': ['MHH'],
  Marshall: ['MLL', 'ASL', 'MML'],
  Marshalltown: ['MIW'],
  Marshfield: ['MFI'],
  'Martha S Vineyard': ['MVY'],
  Martigny: ['ZJM'],
  Martinsburg: ['MRB'],
  Marudi: ['MUR'],
  Mary: ['MYP'],
  Maryborough: ['MBH'],
  'Marys Harbour': ['YMH'],
  Marysville: ['BAB', 'MYV'],
  Masbate: ['MBT'],
  Maseru: ['MSU'],
  Mashad: ['MHD'],
  'Mason City': ['MCW'],
  Massena: ['MSS'],
  Masset: ['ZMT'],
  Masterton: ['MRO'],
  Masvingo: ['MVZ'],
  Matadi: ['MAT'],
  Matamoros: ['MAM'],
  Matane: ['YME'],
  Matanzas: ['VRO'],
  Matapedia: ['XLP'],
  Mataram: ['AMI'],
  Matsu: ['MFK'],
  'Matsu Nagan': ['LZN'],
  Matsumoto: ['MMJ'],
  Matsuyama: ['MYJ'],
  Mattoon: ['MTO'],
  Maturin: ['MUN'],
  Maues: ['MBZ'],
  Maumere: ['MOF'],
  Maun: ['MUB'],
  Maupiti: ['MAU'],
  Mauritius: ['MRU'],
  Maxville: ['XID'],
  'May Creek': ['MYK'],
  Mayaguez: ['MAZ'],
  Mazamari: ['MZA'],
  'Mazar I Sharif': ['MZR'],
  Mazatlan: ['MZT'],
  Mbeya: ['MBI'],
  McAlester: ['MLC'],
  McAllen: ['MFE'],
  'McArthur River': ['MCV'],
  Mccall: ['MYL'],
  Mccarthy: ['MXY'],
  McCook: ['MCK'],
  McKinley: ['MCL'],
  McMinnville: ['RNC'],
  McPherson: ['MPR'],
  'Meadow Lake': ['YLJ'],
  Meaulte: ['BYF'],
  Medan: ['KNO', 'MES'],
  Medellin: ['EOH', 'MDE'],
  Medford: ['MFR'],
  'Medicine Hat': ['YXH'],
  Meekathara: ['MKR'],
  Megeve: ['MVV'],
  Mehamn: ['MEH'],
  Meixian: ['MXZ'],
  Mekele: ['MQX'],
  Meknes: ['MEK'],
  Melbourne: ['AVV', 'KAH', 'MEB', 'MLB', 'MEL'],
  Melilla: ['MLN'],
  Melinda: ['MDB'],
  Melo: ['MLZ'],
  Melville: ['XEK'],
  Memambetsu: ['MMB'],
  Memphis: ['MEM', 'NQA'],
  Mende: ['MEN'],
  Mendi: ['MDU'],
  Mendoza: ['MDZ'],
  Menominee: ['MNM'],
  Menorca: ['MAH'],
  Merauke: ['MKQ'],
  Merced: ['MER', 'MCE'],
  Merida: ['MRD', 'MID', 'EXA'],
  Meridian: ['MEI'],
  Merimbula: ['MIM'],
  Merlo: ['RLO'],
  Merrill: ['RRL'],
  Merritt: ['YMB'],
  'Mersa Matruh': ['MUH'],
  Mesa: ['MSC', 'ZBS'],
  Mesquite: ['MFH'],
  Messina: ['MEZ'],
  Metz: ['MZM'],
  'Metz Nancy': ['ETZ', 'XZI'],
  Mexicali: ['MXL'],
  'Mexico City': ['AZP', 'MEX', 'NLU'],
  Mfuwe: ['MFU'],
  Miami: ['TNT', 'ZFT', 'MIO', 'TMB', 'MIA', 'MPB', 'HWO', 'OPF'],
  'Mian Yang': ['MIG'],
  'Michigan City': ['MGC'],
  Middlemount: ['MMM'],
  'Middleton Island': ['MDO'],
  Middletown: ['MWO'],
  Midland: ['MDD', 'MAF'],
  'Midway Island': ['MDY'],
  Mikkeli: ['MIK'],
  Milan: ['LIN', 'MXP', 'XNC', 'XIK', 'IPR', 'IMR'],
  Mildenhall: ['MHZ'],
  Mildura: ['MQL'],
  'Miles City': ['MLS'],
  Milford: ['MLF'],
  'Milford Sound': ['MFN'],
  Milingimbi: ['MGT'],
  Millinocket: ['MLT'],
  Millville: ['MIV'],
  Milton: ['NSE'],
  'Milton Keynes': ['KYN'],
  Milwaukee: ['MKE', 'MWC', 'ZML'],
  Minaki: ['YMI'],
  Minatitlan: ['MTT'],
  Minden: ['MEV', 'ZOM'],
  'MINERAL WELLS': ['MWL'],
  'Mineralnye Vody': ['MRV'],
  'Miners Bay': ['YAV'],
  Minneapolis: ['MSP', 'MIC', 'FCM'],
  Minocqua: ['ARV'],
  Minot: ['MIB', 'MOT'],
  Minsk: ['MHP', 'MSQ'],
  Minto: ['MNT'],
  Miramar: ['MJR'],
  Miramichi: ['YCH', 'XEY'],
  Miri: ['MYY'],
  Mirny: ['MJZ'],
  Misawa: ['MSJ'],
  'Misima Island': ['MIS'],
  Miskolc: ['MCQ'],
  Missoula: ['MSO'],
  Misurata: ['MRA'],
  Mitchell: ['MHE'],
  'Mitiaro Island': ['MOI'],
  Mittenwald: ['QWD'],
  Mitu: ['MVP'],
  Miyakejima: ['MYE'],
  'Miyako Jima': ['MMY'],
  Miyazaki: ['KMI'],
  Mjolby: ['XXM'],
  Mmabatho: ['MBD'],
  'Mo I Rana': ['MQN'],
  Moab: ['CNY'],
  Mobile: ['BFM', 'MOB'],
  Modesto: ['MOD'],
  Moelv: ['XUJ'],
  Mogadishu: ['MGQ'],
  Mogilev: ['MVQ'],
  'Mohe County': ['OHE'],
  Mohenjodaro: ['MJD'],
  Mojave: ['MHV'],
  Mokpo: ['MPK'],
  Molde: ['MOL'],
  Moline: ['MLI'],
  Mombasa: ['MBA'],
  Monastir: ['MIR'],
  Monclova: ['LOV'],
  Moncton: ['YQM', 'XDP'],
  Mongomo: ['GEM'],
  'Monkey Mia': ['MJK'],
  Monroe: ['MLU'],
  Monroeville: ['MVC'],
  Monrovia: ['ROB', 'MLW'],
  Mons: ['QMO'],
  'Mont Dauphin': ['SCP'],
  'Mont Joli': ['YYY'],
  'Mont Tremblant': ['YTM'],
  Montague: ['SIY'],
  Montauk: ['MTP'],
  'Monte Carlo': ['MCM'],
  'Monte Dourado': ['MEU'],
  'Montego Bay': ['MBJ'],
  Monterey: ['OAR', 'MRY'],
  Monteria: ['MTR'],
  Monterrey: ['NTR', 'MTY'],
  'Montes Claros': ['MOC'],
  Montevideo: ['MVD', 'MVE', 'VDT', 'FMV'],
  Montgomery: ['MGM', 'GUN', 'MXF', 'MGJ'],
  Monticello: ['MSV'],
  Montilla: ['OZU'],
  Montlucon: ['MCU'],
  Monto: ['MNQ'],
  Montpelier: ['MPV'],
  Montpellier: ['MPL', 'XPJ'],
  Montreal: ['YMX', 'XAX', 'YMY', 'YUL', 'XLM', 'YHU'],
  Montreux: ['ZJP'],
  Montrose: ['MTJ'],
  Montserrat: ['MNI'],
  Moorabbin: ['MBW'],
  Moorea: ['MOZ'],
  Moosonee: ['YMO'],
  Mopti: ['MZI'],
  Mora: ['MXX'],
  Moranbah: ['MOV'],
  Moree: ['MRZ'],
  Morelia: ['MLM'],
  Morganton: ['MRN'],
  Morgantown: ['MGW'],
  Morges: ['ZJQ'],
  Morioka: ['HNA'],
  Morlaix: ['MXN'],
  Mornington: ['ONG'],
  Morombe: ['MXM'],
  Morondava: ['MOQ'],
  Moroni: ['YVA', 'HAH'],
  Morowali: ['MOH'],
  Morristown: ['MOR', 'MMU'],
  Moruya: ['MYA'],
  Moscow: ['DME', 'ZKD', 'XRK', 'JQF', 'SVO', 'VKO'],
  'Moser Bay': ['KMY'],
  'Moses Lake': ['MWH'],
  Mosjoen: ['MJF'],
  Mossoro: ['MVF'],
  Mostar: ['OMO'],
  Mosul: ['OSM'],
  Motherwell: ['XQW'],
  Moultrie: ['MGR', 'MUL'],
  'Mount Cook': ['GTN', 'MON', 'TWZ'],
  'Mount Holly': ['LLY'],
  'Mount Hotham': ['MHU'],
  'Mount Isa': ['ISA'],
  'Mount Magnet': ['MMG'],
  'Mount Pleasant': ['MPN', 'MPS', 'MOP'],
  'Mountain Home': ['MUO', 'WMH'],
  'Mountain View': ['NUQ'],
  'Mountain Village': ['MOU'],
  'MT CLEMENS': ['MTC'],
  'Mt Gambier': ['MGB'],
  'Mt Hagen': ['HGU'],
  'Mt Vernon': ['MVN'],
  Mtwara: ['MYW'],
  'Muang Xay': ['ODY'],
  Mucuri: ['MVS'],
  Mudanjiang: ['MDG'],
  Mudgee: ['DGE'],
  'Muelheim An Der Ruhr': ['ZOO'],
  Muenster: ['FMO'],
  'Muharraq Town': ['GBQ'],
  Mukah: ['MKM'],
  Mukalla: ['MKX'],
  'Mulhouse Basel': ['ZBA', 'MLH'],
  Multan: ['MUX'],
  Mulu: ['MZV'],
  Mumbai: ['BOM'],
  Muncie: ['MIE'],
  Munda: ['MUA'],
  Munich: ['ZMU', 'MUC'],
  Murcia: ['XUT', 'MJV'],
  Murmansk: ['MMK'],
  Murray: ['CEY'],
  'Mus Tr': ['MSR'],
  Muscat: ['MCT'],
  'Muscle Shoals': ['MSL'],
  Muskegon: ['MKG'],
  Muskogee: ['MKO'],
  Muskoka: ['YQA'],
  'Muskrat Lake Dam Indian Rsve': ['MSA'],
  Musoma: ['MUZ'],
  Mustique: ['MQS'],
  Mutare: ['UTA'],
  Muzzafarpu: ['MZU'],
  Mwanza: ['MWZ'],
  Myeik: ['MGZ'],
  Mykonos: ['JMK'],
  Myrdal: ['XOL'],
  'Myrtle Beach': ['CRE', 'MYR'],
  'Mys Kamenny': ['YMK'],
  Mysore: ['MYQ'],
  Mytilene: ['MJT'],
  'N Djamena': ['NDJ'],
  'N Dola': ['NLA'],
  'N Gaoundere': ['NGE'],
  'Naberevnye Chelny': ['NBC'],
  Nacala: ['MNC'],
  Nacogdoches: ['OCH'],
  Nadi: ['NAN'],
  Nador: ['NDR'],
  Nadym: ['NYM'],
  Naga: ['WNP'],
  Nagasaki: ['NGS'],
  Nagoya: ['NGO', 'NKM', 'NGY'],
  Nagpur: ['NAG'],
  Nain: ['YDP'],
  Nairobi: ['NBO', 'WIL'],
  Nakashibetsu: ['SHB'],
  Nakchivan: ['NAJ'],
  'Nakhon Phanom': ['KOP'],
  'Nakhon Ratchasima': ['NAK'],
  'Nakhon Si Thammarat': ['NST'],
  Nakina: ['YQN'],
  Nakuru: ['NUU'],
  Nalchik: ['NAL'],
  Namangan: ['NMA'],
  Namdrik: ['NDK'],
  Namibe: ['MSZ'],
  Namlea: ['NAM'],
  Nampula: ['APL'],
  Namsos: ['OSY'],
  Namure: ['QNM'],
  'Nan Th': ['NNT'],
  Nanaimo: ['ZNA', 'YCD'],
  Nanchang: ['KHN', 'NHG'],
  Nanchong: ['NAO'],
  Nancy: ['ENC'],
  Nanded: ['NDC'],
  Nanjing: ['NKG', 'NKJ'],
  Nanning: ['NNG', 'ZNG'],
  Nanortalik: ['JNN'],
  Nantes: ['NTE', 'QJZ'],
  Nantucket: ['ACK'],
  Nanyang: ['NNY'],
  Nanyuki: ['NYK'],
  Napa: ['APC'],
  Napakiak: ['WNA'],
  Napanee: ['XIF'],
  Napaskiak: ['PKA'],
  Napasoq: ['QJT'],
  'Napier Hastings': ['NPE'],
  Naples: ['ZMF', 'NAP', 'ZMJ', 'INP', 'ZMI', 'APF'],
  Naracoorte: ['NAC'],
  Narathiwat: ['NAW'],
  Narbonne: ['NNE'],
  Nare: ['NAR'],
  Narrabri: ['NAA'],
  Narrandera: ['NRA'],
  Narsaq: ['JNS'],
  'Narsaq Kujalleq': ['QFN'],
  Narsarsuaq: ['UAK'],
  Narvik: ['NVK'],
  'Naryan Mar': ['NNM'],
  Nashua: ['ASH'],
  Nashville: ['BNA'],
  Nasik: ['ISK'],
  Nasiriyah: ['XNH'],
  Nassau: ['NAS', 'PID', 'WZY'],
  Nassjo: ['XWX'],
  Natal: ['NAT'],
  Natashquan: ['YNA'],
  Natchez: ['HEZ'],
  Natuashish: ['YNP'],
  'Naukiti Bay': ['NKI'],
  'Nauru Island': ['INU'],
  Navegantes: ['NVT'],
  Navoi: ['NVI'],
  Nawabshah: ['WNS'],
  Naxos: ['JNX'],
  'Nay Pyi Taw': ['NYT'],
  Nazca: ['NZC'],
  Necocli: ['NCI'],
  Needles: ['EED'],
  'Neerlerit Inaat': ['CNP'],
  Nefteyugansk: ['NFG'],
  Negril: ['NEG'],
  Neiva: ['NVA'],
  Nejran: ['EAM'],
  Nelaug: ['XHL'],
  Nelson: ['NSN'],
  'Nelson Lagoon': ['NLG'],
  Nelspruit: ['MQP', 'NLP'],
  Nemiscau: ['YNS'],
  Nepalgunj: ['KEP'],
  Neryungri: ['NER'],
  Nesbyen: ['XUL'],
  Neslandsvatn: ['XUM'],
  Neubrandenburg: ['FNB'],
  Neuchatel: ['QNC'],
  Neumuenster: ['EUM'],
  Neuquen: ['NQN'],
  Nevers: ['NVS'],
  Nevis: ['NEV'],
  Nevsehir: ['NAV'],
  'New Bedford': ['EWB'],
  'New Bern': ['EWN'],
  'New Carlisle': ['XEL'],
  'New Chenega': ['NCN'],
  'New Haven': ['ZVE', 'HVN'],
  'NEW IBERIA': ['ARA'],
  'New London': ['GON', 'ZGD'],
  'New Orleans': ['MSY', 'NEW', 'NBG'],
  'New Philadelphia': ['PHD'],
  'New Plymouth': ['NPL'],
  'New Richmond': ['RNH', 'XEM'],
  'New Ulm': ['ULM'],
  'New Westminster': ['YBD'],
  'New York': [
    'JRB',
    'TSS',
    'ZME',
    'JFK',
    'QNY',
    'NBP',
    'NES',
    'LGA',
    'ZYP',
    'ZRP',
    'NWK',
    'NWS',
    'NYS',
    'JRA',
  ],
  Newark: ['EWR', 'XNK'],
  Newburgh: ['SWF'],
  Newcastle: ['BEO', 'NCL', 'NCS', 'PHJ', 'NTL'],
  Newman: ['ZNE'],
  Newport: ['ONP', 'XNE', 'NPT'],
  'Newport News': ['ZWB', 'PHF'],
  Newquay: ['NQY'],
  Newton: ['TNU', 'EWK'],
  'Nha Trang': ['NHA'],
  'Niagara Falls': ['IAG', 'XLV'],
  Niamey: ['NIM'],
  Niaqornaarsuk: ['QMK'],
  Niaqornat: ['NIQ'],
  Nice: ['NCE'],
  Nicosia: ['NIC'],
  Niigata: ['KIJ'],
  'Nikolayevsk Na Amure': ['NLI'],
  Nikolski: ['IKO'],
  Nimes: ['FNI', 'ZYN'],
  Ningbo: ['NGB'],
  Ninglang: ['NLH'],
  Ninilchik: ['NIN'],
  Niort: ['NIT'],
  'Nis Rs': ['INI'],
  Niteroi: ['QNT'],
  'Niue Island': ['IUE'],
  Nizhnevartovsk: ['NJC'],
  'Nizhniy Novgorod': ['GOJ'],
  Noatak: ['WTK'],
  Nogales: ['NOG', 'OLS'],
  Nogeh: ['NUJ'],
  Nogliki: ['NGK'],
  Nojabrxsk: ['NOJ'],
  Nome: ['OME'],
  Nondalton: ['NNL'],
  Noorvik: ['ORV'],
  Noosa: ['NSA'],
  Nordagutu: ['XUO'],
  Norden: ['NOD'],
  'Nordholz Spieka': ['NDZ'],
  Norfolk: ['OFK', 'NGU', 'ORF'],
  'Norfolk Island': ['NLK'],
  Norilsk: ['NSK'],
  'Norman Wells': ['YVQ'],
  Normanton: ['NTN'],
  NORRIDGEWOCK: ['OWK'],
  Norrkoping: ['NRK', 'XYK'],
  Norseman: ['NSM'],
  Norsup: ['NUS'],
  'North Battleford': ['YQW'],
  'North Bay': ['YYB'],
  'North Bend': ['OTH'],
  'North Caicos': ['NCA'],
  'North Eleuthera': ['ELH'],
  'North Kingstown': ['NCO'],
  'North Platte': ['LBF'],
  'North Ronaldsay': ['NRL'],
  'North Spirit Indian Reserve': ['YNO'],
  Northallerton: ['XNO'],
  Northampton: ['ORM'],
  Northway: ['ORT'],
  'Norway House': ['YNE'],
  Norwich: ['NWI'],
  Norwood: ['OWD'],
  'Nossi Be': ['NOS'],
  Notodden: ['NTB'],
  Nottingham: ['XNM'],
  'Nottingham Uk': ['EMA', 'NQT', 'ZGB'],
  Nouadhibou: ['NDB'],
  Nouakchott: ['NKC'],
  Noumea: ['GEA', 'NOU'],
  Novato: ['NOT'],
  'Novo Aripuana': ['NVP'],
  'Novo Hamburgo': ['QHV'],
  Novokuznetsk: ['NOZ'],
  Novosibirsk: ['OVB'],
  'Novy Urengoy': ['NUX'],
  Nowra: ['NOA'],
  'Nueva Gerona': ['GER'],
  'Nuevo Laredo': ['NLD'],
  'Nuku Alofa': ['TBU'],
  'Nuku Hiva': ['NHV'],
  Nukus: ['NCU'],
  Nukutepipi: ['NKP'],
  Nulato: ['NUL'],
  Nullarbor: ['NUR'],
  Nuneaton: ['XNV'],
  Nuqui: ['NQU'],
  Nuremberg: ['NUE', 'ZAQ'],
  Nuugaatsiaq: ['JUU'],
  Nuuk: ['GOH'],
  Nuussuaq: ['NSQ'],
  'Nuwara Eliya': ['NUA'],
  Nyagan: ['NYA'],
  Nyaung: ['NYU'],
  Nyborg: ['ZIB'],
  Nyeri: ['NYE'],
  Nyingchi: ['LZY'],
  'Nykoing Sjaelland': ['ZIJ'],
  Nykoping: ['XWZ'],
  Nyngan: ['NYN'],
  Nyon: ['ZRN'],
  Nyurba: ['NYR'],
  'Oak Harbor': ['ODW'],
  Oakland: ['OAK', 'JCE'],
  OAKLAND: ['OBT'],
  Oakville: ['XOK'],
  Oamaru: ['OAM'],
  Oaxaca: ['OAX'],
  Oban: ['OBN', 'OHP'],
  Oberammergau: ['ZOX'],
  Oberhausen: ['ZOY'],
  Oberpfaffenhofen: ['OBF'],
  Obihiro: ['OBO'],
  Ocala: ['OCF'],
  Ocana: ['OCV'],
  'Ocean City': ['OCE'],
  Oceanside: ['OCN'],
  'Ocho Rios': ['OCJ'],
  'Odate Noshiro': ['ONJ'],
  Odense: ['ODE', 'ZIM', 'ZBQ'],
  Odessa: ['ODS'],
  Offenburg: ['ZPA'],
  Ogallala: ['OGA'],
  Ogden: ['HIF', 'OGD'],
  Ogdensburg: ['OGS'],
  Ogobsucum: ['OGM'],
  Ohakea: ['OHA'],
  Ohotsk: ['OHO'],
  Ohrid: ['OHD'],
  Oita: ['OIT'],
  Okayama: ['OKJ'],
  Okeechobee: ['OBE'],
  Okha: ['OHH'],
  Okinawa: ['DNA', 'AHA', 'OKA'],
  'Okino Erabu': ['OKE'],
  'Oklahoma City': ['TIK', 'PWA', 'OKC'],
  Olbia: ['OLB'],
  'Old Harbor': ['OLH'],
  Oldenburg: ['ZPD'],
  Olenek: ['ONK'],
  Olkiombo: ['OLX'],
  Olombo: ['OLL'],
  Olomouc: ['XJC'],
  Olsztyn: ['QYO'],
  Olten: ['ZJU'],
  Olympia: ['OLM'],
  'Olympic Dam': ['OLP'],
  Omaha: ['OMA', 'MIQ'],
  Omsk: ['OMS'],
  Ondangwa: ['OND'],
  Oneonta: ['ONH'],
  Onslow: ['ONS'],
  Ontario: ['ONO', 'ONT'],
  Oradea: ['OMR', 'OQR'],
  Oran: ['ORN', 'TAF'],
  Orange: ['OAG'],
  ORANGEBURG: ['OGB'],
  Oranjemund: ['OMD'],
  Ordos: ['DSN'],
  'Ordu Giresun': ['OGU'],
  'Orebro Bofors': ['ORB', 'XWR'],
  Orenburg: ['REN'],
  Orland: ['OLA'],
  Orlando: ['ORL', 'MCO'],
  Orleans: ['ORE'],
  Ornskoldsvik: ['OER'],
  OROVILLE: ['OVE'],
  'Orpheus Island': ['ORS'],
  Orsk: ['OSW'],
  'Orsta Volda': ['HOV'],
  Oruro: ['ORU'],
  Osaka: ['ITM', 'KIX'],
  Osan: ['OSN'],
  'Osbourne Mine': ['OSO'],
  Osceola: ['OEO'],
  'Osh Kg': ['OSS'],
  Oshawa: ['YOO'],
  Oshima: ['OIM'],
  Oshkosh: ['OSH'],
  Osijek: ['OSI'],
  Oskarshamn: ['OSK'],
  Oslo: [
    'XGU',
    'XND',
    'XKF',
    'XKD',
    'XKB',
    'XKI',
    'XKM',
    'OSL',
    'ZZN',
    'XZO',
    'XKE',
    'TRF',
    'XKQ',
  ],
  Osnabruck: ['ZPE'],
  Osorno: ['ZOS'],
  Ostende: ['OST'],
  Ostersund: ['OSD'],
  Ostrava: ['OSR', 'XJV'],
  Ottawa: ['ZIF', 'YOW', 'XDS', 'YRO'],
  Ottumwa: ['OTM'],
  'Otu Co': ['OTU'],
  Ouagadougou: ['OUA'],
  Oudtshoorn: ['OUH'],
  Oujda: ['OUD'],
  Oulu: ['OUL'],
  Ourense: ['OUQ'],
  Ourilandia: ['OIA'],
  Ourzazate: ['OZZ'],
  Ouzinkie: ['KOZ'],
  Ovda: ['VDA'],
  Owatonna: ['OWA'],
  'Owen Sound': ['YOS'],
  Owensboro: ['OWB'],
  Owerri: ['QOW'],
  Oxford: ['BZZ', 'OXF', 'OXQ'],
  'Oxford House': ['YOH'],
  Oxnard: ['OXR'],
  'Ozamis City': ['OZC'],
  OZARK: ['OZR', 'HEY', 'LOR'],
  'Pacific Harbour': ['PHR'],
  Padang: ['PDG'],
  Paderborn: ['PAD'],
  Padova: ['QPA'],
  Paducah: ['PAH'],
  Pagadian: ['PAG'],
  Page: ['PGA'],
  'Pago Pago': ['PPG'],
  'Pagosa Springs': ['PGO'],
  Pahokee: ['PHK'],
  'Pai City': ['PYY'],
  Paimiut: ['PMU'],
  Painesville: ['PVZ'],
  Pajala: ['PJA'],
  Pakse: ['PKZ'],
  Pakuashipi: ['YIF'],
  Palanga: ['PLQ'],
  Palangkaraya: ['PKY'],
  Palembang: ['PLM'],
  Palencia: ['PCI'],
  Palenque: ['PQM'],
  Palermo: ['PMO'],
  'Palm Desert': ['UDD'],
  'Palm Island': ['PMK'],
  'Palm Springs': ['PSP'],
  'Palma Mallorca': ['PMI'],
  Palmar: ['PMZ'],
  Palmas: ['PMW'],
  Palmdale: ['PMD'],
  Palmer: ['PAQ'],
  Palmerston: ['PMR'],
  'Palo Alto': ['PAO'],
  Palopo: ['LLO'],
  Palu: ['PLW'],
  Pamplona: ['PNA', 'EEP'],
  'Panama City': ['PAC', 'ECP', 'PTY', 'PAM'],
  Pangkalanbuun: ['PKN'],
  Pangkalpinang: ['PGK'],
  Pangkor: ['PKG'],
  Pangnirtung: ['YXP'],
  Panguitch: ['PNU'],
  Pantelleria: ['PNL'],
  Panzhihua: ['PZI'],
  Paonia: ['WPO'],
  'Papa Westray': ['PPW'],
  Papeete: ['PPT'],
  Paphos: ['PFO'],
  'Par GB': ['PCW'],
  Paraburdoo: ['PBO'],
  Paragould: ['PGR'],
  Paramaribo: ['PBM', 'ORG'],
  Parana: ['PRA'],
  Paranagua: ['PNG'],
  Paranavai: ['PVI'],
  Paraparaumu: ['PPQ'],
  Pardubice: ['PED', 'XJP'],
  Parent: ['XFE'],
  Parintins: ['PIN'],
  Paris: [
    'XEX',
    'CDG',
    'DLP',
    'XGB',
    'XPG',
    'PHT',
    'JDP',
    'LBG',
    'ORY',
    'PRX',
    'XHP',
    'XJY',
    'XCR',
    'VIY',
  ],
  'Park Rapids': ['PKD'],
  Parkersburg: ['PKB'],
  Parkes: ['PKE'],
  Parksville: ['XPB'],
  Parma: ['PMF'],
  Parnaiba: ['PHB'],
  Parnu: ['EPU'],
  Paro: ['PBH'],
  Paros: ['PAS'],
  'Parry Sound': ['YPD'],
  Parsons: ['PPF'],
  Pascagoula: ['PGL'],
  Pasco: ['PSC'],
  Pasewalk: ['ZSK'],
  Pasighat: ['IXT'],
  'Paso De Los Libres': ['AOL'],
  'Paso Robles': ['PRB'],
  'Pass Lake': ['XZP'],
  Passau: ['ZPF'],
  'Passo Fundo': ['PFB'],
  Pasto: ['PSO'],
  Pathankot: ['IXP'],
  Patna: ['PAT'],
  'Pato Branco': ['PTO'],
  'Patos De Minas': ['POJ'],
  Patras: ['GPA'],
  Pattaya: ['PYX'],
  'Pau Fr': ['PUF'],
  Pauk: ['PAU'],
  Paulatuk: ['YPC'],
  Pavlodar: ['PWQ'],
  Paysandu: ['PDU'],
  Payson: ['PJB'],
  'Peace River': ['YPE'],
  Peawanuck: ['YPO'],
  PECOS: ['PEQ'],
  Pecs: ['PEV'],
  Peenemuende: ['PEF'],
  Pekanbaru: ['PKU'],
  'Pell City': ['PLR'],
  Pellston: ['PLN'],
  'Pelly Bay': ['YBB'],
  Pelotas: ['PET'],
  Pemba: ['PMA', 'POL'],
  Pembroke: ['YTA'],
  Penang: ['PEN'],
  'Pender Harbour': ['YPT'],
  Pendleton: ['PDT'],
  Penglai: ['PNJ'],
  Penrith: ['XPF'],
  Pensacola: ['PNS', 'NPA', 'NUN'],
  Penticton: ['YYF'],
  Penza: ['PEZ'],
  Penzance: ['PZE', 'PZC'],
  Peoria: ['PIA'],
  Perce: ['XFG'],
  Pereira: ['PEI'],
  Perigueux: ['PGX'],
  Perm: ['PEE'],
  Perpignan: ['PGF'],
  'Perry Island': ['PYL'],
  Perryville: ['KPV'],
  Perth: ['PSL', 'PER', 'ZXP'],
  Peru: ['VYS'],
  Perugia: ['PXB', 'PEG'],
  Pescara: ['PSR', 'PXC'],
  Peschiei: ['PEJ'],
  Peshawar: ['PEW'],
  Peterborough: ['YPQ', 'XVH'],
  Petersburg: ['FLE', 'PSG', 'PTB'],
  Petrolina: ['PNZ'],
  Petropavlovsk: ['PPK'],
  'Petropavlovsk Kamchatskiy': ['PKC'],
  Petrozavodsk: ['PES'],
  Pevek: ['PWE'],
  Phalaborwa: ['PHW'],
  'Phan Thiet': ['PHH'],
  Phaplu: ['PPL'],
  Philadelphia: ['PSQ', 'PNE', 'ZFV', 'PHL', 'ZHC', 'ZTN'],
  Philipsburg: ['PSB'],
  Phitsanulok: ['PHS'],
  'Phnom Penh': ['PNH'],
  Phoenix: ['LUF', 'DVT', 'AZA', 'PHX'],
  Phrae: ['PRH'],
  'Phu Quoc': ['PQC'],
  Phuket: ['HKT'],
  'Pickle Lake': ['YPL'],
  'Pico Island': ['PIX'],
  Picos: ['PCS'],
  Picton: ['PCN'],
  'Piedras Negras': ['PDS'],
  Pierre: ['PIR'],
  Piestany: ['PZY'],
  Pietermaritzburg: ['PZB'],
  Pikeville: ['PVL'],
  Pikwitonei: ['PIW'],
  'Pilot Point': ['PIP', 'UGB'],
  'Pincher Creek': ['WPC'],
  'Pine Bluff': ['PBF'],
  Pinehurst: ['SOP'],
  Pingtung: ['PIF'],
  Pingxiang: ['PXG'],
  Pirapora: ['PIV'],
  Pisa: ['PSA'],
  Pisco: ['PIO'],
  'Pitts Town': ['PWN'],
  Pittsburg: ['PTS'],
  Pittsburgh: ['AGC', 'PIT'],
  Pittsfield: ['PSF'],
  Pituffik: ['THU'],
  Piura: ['PIU'],
  Placencia: ['PLJ'],
  Plainview: ['PVW'],
  Plattsburgh: ['PLB'],
  'Playa Del Carmen': ['PCM'],
  'Playa Grande': ['PKJ'],
  'Playa Samara': ['PLD'],
  'Playon Chico': ['PYC'],
  Pleiku: ['PXU'],
  'Plettenberg Bay': ['PBZ'],
  Plovdiv: ['PDV'],
  Plymouth: ['PYM', 'PLY', 'PLH'],
  Pocatello: ['PIH'],
  Podgorica: ['TGD'],
  Pohang: ['KPO'],
  Pohnpei: ['PNI'],
  'Point Baker': ['KPB'],
  'Point Hope': ['PHO'],
  'Point Lay': ['PIZ'],
  'Point Lookout': ['PLK'],
  'Pointe A Pitre': ['PTP'],
  'Pointe Aux Trembles': ['XPX'],
  'Pointe Noire': ['PNR'],
  Poitiers: ['PIS', 'XOP'],
  Pokhara: ['PKR'],
  Polacca: ['PXL'],
  Polokwane: ['PTG'],
  Poltava: ['PLV'],
  'Pompano Beach': ['PPM'],
  'Ponca City': ['PNC'],
  Ponce: ['PSE'],
  'Pond Inlet': ['YIO'],
  Pondicherry: ['PNY'],
  Ponferrada: ['PFE'],
  'Ponta Delgada': ['PDL'],
  'Ponta Pora': ['PMG'],
  Pontevedra: ['PTE'],
  Pontiac: ['PTK'],
  Pontianak: ['PNK'],
  Pontoise: ['POX'],
  Pontresina: ['ZJV'],
  Popayan: ['PPN'],
  'Poplar Bluff': ['POF'],
  'Poplar Hill Indian Reserve': ['YHP'],
  'Poplar River Indian Reserve': ['XPP'],
  Popondetta: ['PNP'],
  'Poprad Tatry': ['TAT'],
  Porbandar: ['PBD'],
  Pori: ['POR'],
  Porlamar: ['PMV'],
  'Port Alberni': ['YPB'],
  'Port Alexander': ['PTD'],
  'Port Alice': ['PTC'],
  'Port Angeles': ['CLM'],
  'Port Au Prince': ['PAP'],
  'Port Augusta': ['PUG'],
  'Port Blair': ['IXZ'],
  'Port Daniel': ['XFI'],
  'Port Douglas': ['PTI'],
  'Port Elizabeth': ['BQU', 'PLZ'],
  'Port Gentil': ['POG'],
  'Port Graham': ['PGM'],
  'Port Harcourt': ['PHC', 'PHG'],
  'Port Hardy': ['YZT'],
  'Port Hawkesbury': ['YPS'],
  'Port Hedland': ['PHE'],
  'Port Hope': ['XPH'],
  'Port Hope Simpson': ['YHA'],
  'Port Hueneme': ['NTD'],
  'Port Huron': ['PHN'],
  'Port Lincoln': ['PLO'],
  'Port Menier': ['YPN'],
  'Port Moller': ['PML'],
  'Port Moresby': ['POM'],
  'Port Of Spain': ['POS'],
  'Port Pirie': ['PPI'],
  'Port Protection': ['PPV'],
  'Port Sudan': ['PZU'],
  'Port Vila': ['VLI'],
  'Port Walter': ['PWR'],
  'Portage Creek': ['PCA'],
  'Portage La Prairie': ['YPG'],
  Porterville: ['PTV'],
  Portimao: ['PRM'],
  Portland: ['PTJ', 'PDX', 'PWM'],
  Porto: ['OPO'],
  'Porto Alegre': ['POA'],
  'Porto Santo': ['PXO'],
  'Porto Seguro': ['BPS'],
  'Porto Velho': ['PVH'],
  Portoheli: ['PKH'],
  Portoroz: ['POW'],
  Portsmouth: ['PME', 'PSM'],
  Posadas: ['PSS'],
  'Poste De La Baleine': ['YGW'],
  Postville: ['YSO'],
  Potosi: ['POI'],
  Pottstown: ['PTW'],
  Poughkeepsie: ['POU'],
  Poulsbo: ['PUL'],
  'Powell Lovell': ['POY'],
  'Powell River': ['YPW'],
  'Poza Rica': ['PAZ'],
  Poznan: ['RPZ', 'POZ'],
  Prague: ['PFL', 'XYJ', 'XYG', 'PRG'],
  Praia: ['RAI'],
  'Prairie Du Chien': ['PCD'],
  'Praslin Island': ['PRI'],
  Pratt: ['PTT'],
  Praya: ['LOP'],
  Prescott: ['PRC', 'XII'],
  'Presidente Prudente': ['PPB'],
  'Presque Isle': ['PQI'],
  Preston: ['XPT'],
  Pretoria: ['HPR', 'HIC', 'PRY'],
  Preveza: ['PVK'],
  Price: ['PUC'],
  'Prince Albert': ['YPA'],
  'Prince George': ['YXS', 'XDV'],
  'Prince Rupert': ['YPR', 'XDW', 'ZSW'],
  Princeton: ['PCT', 'ZTJ'],
  Princeville: ['HPV'],
  Pristina: ['PRN'],
  Propriano: ['PRP'],
  Proserpine: ['PPP'],
  Providence: ['ZRV', 'PVD'],
  Providencia: ['PVA'],
  Providenciales: ['PLS'],
  Provincetown: ['PVC'],
  Provo: ['PVU'],
  'Prudhoe Bay Deadhorse': ['SCC'],
  Pskov: ['PKV'],
  'Pt Macquarie': ['PQQ'],
  Pucallpa: ['PCL'],
  Pucon: ['ZPC'],
  Puebla: ['PBC'],
  Pueblo: ['TAD', 'PUB'],
  'Puente Genil': ['GEN'],
  'Puerto Aisen': ['WPA'],
  'Puerto Asis': ['PUU'],
  'Puerto Ayacucho': ['PYH'],
  'Puerto Barrios': ['PBR'],
  'Puerto Berrio': ['PBE'],
  'Puerto Cabello': ['PBL'],
  'Puerto Carreno': ['PCR'],
  'Puerto de Santa Maria': ['PXS'],
  'Puerto Del Rosario': ['FUE'],
  'Puerto Escondido': ['PXM'],
  'Puerto Inirida': ['PDA'],
  'Puerto Jimenez': ['PJM'],
  'Puerto La Victoria': ['PCJ'],
  'Puerto Leguizamo': ['LQM'],
  'Puerto Madryn': ['PMY'],
  'Puerto Maldonado': ['PEM'],
  'Puerto Montt': ['PMC'],
  'Puerto Natales': ['PNT'],
  'Puerto Ordaz': ['PZO'],
  'Puerto Penasco': ['PPE'],
  'Puerto Plata': ['POP'],
  'Puerto Princesa': ['PPS'],
  'Puerto Suarez': ['PSZ'],
  'Puerto Vallarta': ['PVR'],
  'Puerto Varas': ['PUX'],
  Puertollano: ['UER'],
  Pukatawagan: ['XPK'],
  Pula: ['PUY'],
  Pullman: ['PUW'],
  Pune: ['PNQ'],
  'Punta Arenas': ['PUQ'],
  'Punta Cana': ['PUJ'],
  'Punta Del Este': ['PDP', 'PVB'],
  'Punta Gorda': ['PGD', 'PND'],
  'Punta Islita': ['PBP'],
  'Punta Renes': ['JAP'],
  Purnea: ['PUI'],
  Puvirnituq: ['YPX'],
  Pyongyang: ['FNJ'],
  Qaanaaq: ['NAQ'],
  Qaarsut: ['JQA'],
  Qaisumah: ['AQI'],
  Qamdo: ['BPX'],
  Qaqortoq: ['JJU'],
  Qassiarsuk: ['QFT'],
  Qassimiut: ['QJH'],
  Qeqertaq: ['PQT'],
  Qeqertarsuatsiaat: ['QEY'],
  Qianjiang: ['JQJ', 'JIQ'],
  Qiemo: ['IQM'],
  Qikiqtarjuaq: ['YVM'],
  Qingdao: ['TAO'],
  Qingyang: ['IQN'],
  Qinhuangdao: ['BPE', 'SHP'],
  Qionghai: ['QHX'],
  Qiqihar: ['NDG'],
  Quakertown: ['UKT'],
  Qualicum: ['XQU'],
  Quaqtaq: ['YQC', 'BQC'],
  Quebec: ['XLK', 'XFZ', 'XLJ', 'YQB', 'XFY'],
  'Queen Charlotte Island': ['ZQS'],
  Queenstown: ['ZQN', 'UTW', 'UEE'],
  Quelimane: ['UEL'],
  Quepos: ['XQP'],
  Queretaro: ['QRO'],
  Quesnel: ['YQZ'],
  Quetta: ['UET'],
  Quetzaltenango: ['AAZ'],
  'Qui Nhon': ['UIH'],
  Quibdo: ['UIB'],
  Quilpie: ['ULP'],
  Quimper: ['UIP'],
  Quincy: ['GNF', 'UIN'],
  Quinhagak: ['KWN'],
  Quirindi: ['UIR'],
  Quito: ['UIO'],
  Qurghonteppa: ['KQT'],
  Rabat: ['RBA'],
  Rabaul: ['RAB'],
  'Rach Gia': ['VKG'],
  Racine: ['RAC'],
  Rade: ['ZXX'],
  Radom: ['RDO', 'VRD'],
  'Rae Bareli': ['BEK'],
  'Rahim Yar  Khan': ['RYK'],
  Raiatea: ['RFP'],
  'Railway Germany': ['QYG'],
  'Rainbow Lake': ['YOP'],
  Raipur: ['RPR'],
  Rajahmundry: ['RJA'],
  Rajkot: ['RAJ'],
  Raleigh: ['RDU'],
  'Ramadan City': ['TFR'],
  'Ramata Island': ['RBV'],
  Ramsgate: ['QQR'],
  Ramstein: ['RMS'],
  Ranchi: ['IXR'],
  Randers: ['ZIR'],
  Ranger: ['RGR'],
  'Rangiroa Island': ['RGI'],
  'Rankin Inlet': ['YRT'],
  Ranong: ['UNN'],
  'Rapid City': ['RCA', 'RAP'],
  Rapperswil: ['ZJW'],
  Rarotonga: ['RAR'],
  'Ras Al Khaimah': ['RKT'],
  Ratnagiri: ['RTC'],
  Raton: ['RTN'],
  Raufoss: ['ZMQ'],
  Ravensthorpe: ['RVT'],
  Rawlins: ['RWL'],
  Reading: ['XRE', 'RDG'],
  Rechlin: ['REB'],
  Recife: ['REC'],
  Recklinghausen: ['ZPL'],
  'Red Deer': ['YQF'],
  'Red Dog': ['RDB'],
  'Red Lake': ['YRL'],
  'Red Sucker Lake': ['YRS'],
  Redang: ['RDN'],
  Redding: ['RBL', 'RDD'],
  Redmond: ['RDM'],
  REFUGIO: ['RFG'],
  Regensburg: ['ZPM'],
  'Reggio Calabria': ['REG'],
  'Reggio di Calabria': ['RCC'],
  'Reggio Nell Emilia': ['ZRO', 'XRL'],
  Regina: ['YMJ', 'YQR'],
  Reims: ['RHE', 'XIZ'],
  Renmark: ['RMK'],
  Rennes: ['ZFJ', 'RNS'],
  Reno: ['RNO'],
  'Repulse Bay': ['YUT'],
  Resende: ['REZ'],
  Resistencia: ['RES'],
  Resolute: ['YRB'],
  Retalhuleu: ['RER'],
  'Reunion Island': ['RUN'],
  Reus: ['REU', 'EER'],
  Reutlingen: ['ZPP'],
  Revelstoke: ['YRV'],
  Reykjavik: ['RKV', 'KEF'],
  Reynosa: ['REX'],
  Rhinelander: ['RHI'],
  Rhodes: ['RHO'],
  'Ribeirao Preto': ['RAO'],
  Riberalta: ['RIB'],
  'Richards Bay': ['RCB'],
  Richfield: ['RIF'],
  Richland: ['RLD'],
  Richmond: ['RCM', 'RIC', 'ZRD'],
  Riesa: ['IES'],
  'Riesa DE': ['ZRX'],
  Rifle: ['RIL'],
  Riga: ['RIX'],
  Rigolet: ['YRG'],
  Rijeka: ['RJK'],
  Rikaze: ['RKZ'],
  Rimatara: ['RMT'],
  Rimini: ['RMI'],
  Rimouski: ['YXK'],
  Ringebu: ['XUQ'],
  Ringwood: ['RNW'],
  'Rio Branco': ['RBR'],
  'Rio Claro': ['QIQ'],
  'Rio Cuarto': ['RCU'],
  'Rio De Janeiro': ['GIG', 'SDU'],
  'Rio Dulce': ['LCF'],
  'Rio Gallegos': ['RGL'],
  'Rio Grande': ['RGA', 'RIG'],
  'Rio Hato': ['RIH'],
  'Rio Verde': ['RVD'],
  Riohacha: ['RCH'],
  Rivas: ['ECI'],
  Rivers: ['YYI'],
  Riverside: ['RAL'],
  Riverton: ['RIW'],
  'Riviere A Pierre': ['XRP'],
  'Riviere Du Loup': ['YRI'],
  Riyadh: ['RUH', 'RUJ'],
  Riyan: ['RIY'],
  Rizhao: ['RIZ'],
  Roanne: ['RNE'],
  Roanoke: ['ROA'],
  'Roanoke Rapids': ['RZZ'],
  Roatan: ['RTB'],
  Roberval: ['YRJ'],
  'Roche Harbor': ['RCE'],
  Rochefort: ['RCO'],
  Rochester: ['ROC', 'RCS', 'RST', 'ZTE'],
  'Rock Hill': ['RKH'],
  'Rock Sound': ['RSD'],
  'Rock Springs': ['RKS'],
  Rockford: ['ZRF', 'ZRK'],
  Rockhampton: ['ROK'],
  Rockland: ['RKD'],
  Rockport: ['RKP'],
  Rockwood: ['RKW'],
  'Rocky Mount': ['RWI'],
  Rodez: ['RDZ'],
  'Rodrigues Island': ['RRG'],
  Roervik: ['RVK'],
  Rogers: ['ROG'],
  'Roi Et': ['ROI'],
  Rolla: ['RLA'],
  Roma: ['RMA'],
  Rome: ['CIA', 'FCO', 'RMG', 'IRR', 'IRT', 'XRJ'],
  Ronda: ['RRA'],
  Rondon: ['RON'],
  Rondonopolis: ['ROO'],
  Ronneby: ['RNB', 'XXY', 'XWK'],
  Roosendaal: ['ZYO'],
  Roros: ['RRS'],
  Rorschach: ['ZJZ'],
  Rosario: ['ROS'],
  Roseau: ['ROX'],
  Roseburg: ['RBG'],
  'Rosh Pina': ['RHN', 'RPN'],
  Roskilde: ['RKE'],
  'Rostock Laage': ['RLG'],
  Rostov: ['ROV'],
  Roswell: ['ROW'],
  Rota: ['ROP', 'ROZ'],
  Rothesay: ['RAY'],
  Rotorua: ['ROT'],
  Rotterdam: ['RTM', 'QRH'],
  Rottnest: ['RTS'],
  'Rotuma Island': ['RTA'],
  Rouen: ['URO'],
  Rourkela: ['RRK'],
  'Rouyn Noranda': ['YUY'],
  Rovaniemi: ['RVN'],
  Rovno: ['RWN'],
  'Roxas City': ['RXS'],
  Rugby: ['XRU'],
  Rugeley: ['XRG'],
  Ruidoso: ['RUI'],
  'Rum Cay': ['RCY'],
  Runcorn: ['XRC'],
  Rundu: ['NDU'],
  Rupsi: ['RUP'],
  Rurutu: ['RUR'],
  Ruse: ['ROU'],
  'Russian Mission': ['RSH'],
  Ruston: ['RSN'],
  Rutland: ['RUT'],
  Rybinsk: ['RYB'],
  Rygge: ['ZXU'],
  Rzeszow: ['RZE'],
  Saarbruecken: ['SCN', 'SDA', 'QFZ'],
  Saarmelleek: ['SOB'],
  Saatut: ['SAE'],
  'Saba Island': ['SAB'],
  Sabetta: ['SBT'],
  'Sachigo Lake Indian Reserve': ['ZPB'],
  'Sachs Harbour': ['YSY'],
  Sackville: ['XKV'],
  Sacramento: ['SAC', 'SMF', 'MHR', 'MCC'],
  Sado: ['SDS'],
  Saga: ['HSG'],
  Saginaw: ['MBS', 'SGW'],
  Saidpur: ['SPD'],
  'Saint Cloud': ['STC'],
  'Saint Hyacinthe': ['XIM'],
  'Saint Laurent du Maroni': ['LDX'],
  'Saint Marie': ['SMS'],
  Saipan: ['SPN'],
  Sakkyryr: ['SUK'],
  'Sakon Nakhon': ['SNO'],
  'Sal Island': ['SID'],
  Sala: ['XYX'],
  Salalah: ['SLL'],
  Salamanca: ['SLM', 'SEJ'],
  Salamo: ['SAM'],
  'Saldanha Bay': ['SDB'],
  Sale: ['SXE'],
  Salekhard: ['SLY'],
  Salem: ['SLE', 'SXV'],
  Salerno: ['QSR', 'ISR'],
  Salida: ['SLT'],
  Salima: ['LMB'],
  Salina: ['SLN'],
  'Salina Cruz': ['SCX'],
  Salinas: ['SNC', 'SNS'],
  Salisbury: ['SRW', 'XSR'],
  'Salisbury Ocean City': ['SBY'],
  Salluit: ['YZG'],
  'Salmon Arm': ['YSN'],
  'Salt Lake City': ['SLC'],
  Salta: ['SLA'],
  Saltillo: ['SLW'],
  Salto: ['STY'],
  'Salton City': ['SAS'],
  Salvador: ['SSA'],
  Salzburg: ['ZSB', 'SZG'],
  Salzwedel: ['ZSQ'],
  Samana: ['AZS'],
  Samara: ['KUF'],
  Samarkand: ['SKD'],
  Sambava: ['SVB'],
  Samburu: ['UAS'],
  Samos: ['SMI'],
  Samsun: ['SZF'],
  'San Andres': ['ADZ'],
  'San Angelo': ['SJT'],
  'San Antonio': ['SVZ', 'MDA', 'RND', 'SAT', 'SKF', 'SSF'],
  'San Bernardino': ['SBD'],
  'San Carlos': ['SQL'],
  'San Carlos Bariloche': ['BRC'],
  'San Cristobal': ['SCY'],
  'San Crystobal': ['SCI'],
  'San Diego': ['SDM', 'CLD', 'SEE', 'MYF', 'SAN', 'NKX', 'NZY'],
  'San Domino Island': ['TQR'],
  'San Felipe': ['SNF', 'SFH'],
  'San Felix': ['SFX'],
  'San Fernando': ['SFE', 'FES'],
  'San Fernando De Apure': ['SFD'],
  'San Francisco': ['SFO'],
  'San Giovanni Rotondo': ['GBN'],
  'San Ignacio': ['SQS', 'CYD'],
  'San Jose': ['SJO', 'RHV', 'ZJO', 'SJC', 'ZSM', 'SYQ'],
  'San Jose Cabo': ['SJD'],
  'San Jose Guaviare': ['SJE'],
  'San Jose Island': ['SIC'],
  'San Juan': ['UAQ', 'SJU'],
  'San Luis': ['LUQ'],
  'San Luis Obispo': ['CSL', 'SBP'],
  'San Luis Potosi': ['SLP'],
  'San Luis Rio Colorado': ['UAC'],
  'San Matias': ['MQK'],
  'San Miguel': ['SYL'],
  'San Pablo': ['SPO'],
  'San Pedro': ['SPR'],
  'San Pedro Sula': ['SAP'],
  'San Quintin': ['SNQ'],
  'San Rafael': ['SRF', 'AFA', 'JSG'],
  'San Salvador': ['ZSA', 'SAL'],
  'San Sebastian': ['EAS', 'YJH'],
  'San Sebastian De La Gomera': ['GMZ'],
  'San Tome': ['SOM'],
  'San Vincent': ['SVI'],
  Sanaa: ['SAH'],
  'Sancti Spiritus': ['USS'],
  'Sand Point': ['SDP'],
  Sandakan: ['SDK'],
  Sandane: ['SDN'],
  Sanday: ['NDY'],
  Sandefjord: ['ZYS'],
  Sandnessjoen: ['SSJ'],
  Sandoway: ['SNW'],
  Sandspit: ['YZP'],
  Sandusky: ['SKY'],
  Sandvika: ['ZYW'],
  'Sandy Lake': ['ZSJ'],
  Sanford: ['SFB', 'SFM'],
  Sanikiluaq: ['YSK'],
  'Sanli Urfa': ['SFQ'],
  Sanliurfa: ['GNY'],
  'Santa Ana': ['SNA'],
  'Santa Barbara': ['STB', 'SBA', 'SZN'],
  'Santa Clara': ['SNU'],
  'Santa Cruz': ['SRZ', 'SPC', 'VVI'],
  'Santa Cruz Do Sul': ['CSU'],
  'Santa Cruz Flores': ['FLW'],
  'Santa Cruz Huatulco': ['HUX'],
  'Santa Cruz Island': ['SCZ'],
  'Santa Elena': ['SNV'],
  'Santa Fe': ['SFN', 'SAF'],
  'Santa Maria': ['RIA', 'SMX', 'SMA'],
  'Santa Marta': ['SMR'],
  'Santa Paula': ['SZP'],
  'Santa Rosa': ['STS', 'RSA', 'SRA', 'ETR'],
  'Santa Rosalia': ['SRL'],
  'Santa Ynez': ['SQA'],
  Santander: ['SDR', 'YJL'],
  Santarem: ['STM'],
  Santiago: ['SCU', 'SCL', 'STI', 'ULC'],
  'Santiago De Compostela': ['SCQ'],
  'Santiago Del Estero': ['SDE'],
  'Santo Angelo': ['GEL'],
  'Santo Antao': ['NTO'],
  'Santo Domingo': ['STD', 'JBQ', 'HEX', 'SDQ'],
  Santos: ['SSZ'],
  Sanya: ['SYX'],
  'Sao Carlos': ['QSC'],
  'Sao Filipe': ['SFL'],
  'Sao Gabriel Da Cachoeira': ['SJL'],
  'Sao Joao Del Rei': ['JDR'],
  'Sao Jorge Island': ['SJZ'],
  'Sao Jose Do Rio Preto': ['SJP'],
  'Sao Jose Dos Campos': ['SJK'],
  'Sao Luiz': ['SLZ'],
  'Sao Mateus': ['SBJ'],
  'Sao Miguel Do Oeste': ['SQX'],
  'Sao Nicolau': ['SNE'],
  'Sao Paulo': ['CGH', 'GRU', 'VCP'],
  'Sao Tome Is': ['TMS'],
  'Sao Vicente': ['VXE'],
  Sapporo: ['CTS', 'OKD'],
  Saqqaq: ['QUP'],
  Sara: ['SSR'],
  Sarajevo: ['SJJ'],
  'Saranac Lake': ['SLK'],
  Saransk: ['SKX'],
  Sarasota: ['SRQ'],
  Saratoga: ['SAA'],
  Saratov: ['RTW'],
  Saravena: ['RVE'],
  Sargans: ['ZKA'],
  Sargodha: ['BHW', 'SGI'],
  Sari: ['SRY'],
  Sarnen: ['ZKC'],
  Sarnia: ['YZR', 'XDX'],
  Sashylakh: ['SYS'],
  Saskatoon: ['YKY', 'YXE'],
  Sassnitz: ['ZSI'],
  Sasstown: ['SAZ'],
  Satna: ['TNI'],
  'Satu Mare': ['SUJ', 'STU'],
  'Saturna Island': ['YAJ'],
  Saudarkrokur: ['SAK'],
  'Sault Ste Marie': ['CIU', 'YAM'],
  Saurimo: ['VHC'],
  Savannah: ['SVN', 'SAV'],
  Savannakhet: ['ZVK'],
  Savonlinna: ['SVL'],
  Savusavu: ['SVU'],
  'Scammon Bay': ['SCM'],
  Schaffhausen: ['ZKJ'],
  Schefferville: ['YKL'],
  Schenectady: ['SCH', 'ZTD'],
  Schleswig: ['WBG'],
  Schoena: ['ZSC'],
  Schwanheide: ['ZSD'],
  Schwerin: ['SZW', 'ZSR'],
  Schwyz: ['ZKK'],
  Scone: ['NSO'],
  Scottsbluff: ['BFF'],
  Scottsdale: ['SCF'],
  Scranton: ['AVP', 'WBW'],
  'Seal Bay': ['SYB'],
  Searcy: ['SRC'],
  Seattle: ['KEH', 'LKE', 'BFI', 'SEA'],
  Sebring: ['SEF'],
  Sechelt: ['YHS'],
  Secunda: ['ZEC'],
  Sedalia: ['DMO'],
  Sedona: ['SDX'],
  Sege: ['EGM'],
  Segovia: ['XOU'],
  Seinajoki: ['SJY'],
  Selawik: ['WLK'],
  Seldovia: ['SOV'],
  'Selebi Phikwe': ['PKW'],
  Selibaby: ['SEY'],
  Selma: ['SEM'],
  Semarang: ['SRG'],
  Semera: ['SZE'],
  Semey: ['PLX'],
  'Sena Madureira': ['ZMD'],
  Sendai: ['SDJ'],
  Senneterre: ['XFK'],
  'Seo de Urgel': ['LEU'],
  Seosan: ['HMY'],
  Seoul: ['GMP', 'ICN', 'SSN'],
  'Sept Iles': ['YZV'],
  Sequim: ['SQV'],
  Seronera: ['SEU'],
  Seruyan: ['KLP'],
  Setif: ['QSF'],
  Sevastopol: ['UKS'],
  'Severac Le Chateau': ['ZBH'],
  Sevilla: ['SVQ', 'EVI', 'XQA'],
  Seward: ['SWD'],
  Sfax: ['SFA'],
  'Shahre Kord': ['CQD'],
  Shakhtersk: ['EKS'],
  Shaktoolik: ['SKK'],
  'Shamattawa Indian Reserve': ['ZTM'],
  Shanghai: ['SHA', 'PVG'],
  Shannon: ['SNN'],
  Shantou: ['SWA'],
  Shanzhou: ['SZO'],
  Shaoxing: ['RNX'],
  Shaoyang: ['SYG', 'WGN'],
  Sharjah: ['SHJ'],
  'Sharm El Sheik': ['SSH'],
  Sharurah: ['SHW'],
  Shashi: ['SHS'],
  Shawinigan: ['XFL'],
  Shawnee: ['SNL'],
  Shawnigan: ['XFM', 'XFH'],
  'She Kou': ['ZCU'],
  Sheboygan: ['SBM'],
  'Sheep Mountain': ['SMU'],
  Shelbyville: ['SYI'],
  'Sheldon Point': ['SXP'],
  Shennongjia: ['HPG'],
  Shenyang: ['SHE', 'VWA'],
  Shenzhen: ['SZX'],
  Shepparton: ['SHT'],
  Sherbrooke: ['YSC'],
  Sheridan: ['SHR'],
  Sherman: ['PNX'],
  'Shetland Islands Area': ['SCS'],
  'Shi Quan He': ['NGQ'],
  Shijiazhuang: ['QSJ', 'SJW'],
  Shillong: ['SHL'],
  Shimkent: ['CIT'],
  Shinyanga: ['SHY'],
  Shirahama: ['SHM'],
  Shiraz: ['SYZ'],
  'Shire Indaselassie': ['SHC'],
  Shiyan: ['EJQ', 'WDS'],
  'Shizuoka City': ['FSZ'],
  Sholapur: ['SSE'],
  Shonai: ['SYO'],
  'SHOREHAM BY SEA': ['ESH'],
  'Show Low': ['SOW'],
  Shreveport: ['BAD', 'DTN', 'SHV'],
  'Shute Hrb': ['JHQ'],
  Sialkot: ['SKT'],
  Sibiu: ['SBZ', 'SEI'],
  Sibolga: ['FLZ'],
  'Siborong Borong': ['DTB'],
  Sibu: ['SBW'],
  Sidney: ['SNY', 'SDY'],
  Siegburg: ['ZPY'],
  Siegen: ['SGE'],
  'Siem Reap': ['REP'],
  Siena: ['SAY'],
  Sierre: ['ZKO'],
  Siguanea: ['SZJ'],
  Sihanoukville: ['KOS'],
  Silchar: ['IXS'],
  Silkeborg: ['XAH'],
  'Siloam Springs': ['SLG'],
  'Silver City': ['SVC'],
  'Silver Creek': ['SVK'],
  Simao: ['SYM'],
  Simferopol: ['SIP'],
  Simla: ['SLV'],
  Simra: ['SIF'],
  Sindal: ['CNL'],
  Singapore: ['SIN', 'QPG', 'XSP'],
  Singleton: ['SIX'],
  Sinoe: ['SNI', 'XSA'],
  Sinop: ['OPS'],
  SINOP: ['NOP'],
  'Sintang Borneo Island': ['SQG'],
  Sion: ['SIR'],
  'Sioux City': ['SUX'],
  'Sioux Falls': ['FSD'],
  'Sioux Lookout': ['YXL'],
  Siping: ['OSQ'],
  Sirnak: ['NKT'],
  Sishen: ['SIS'],
  Sisimiut: ['JHS'],
  Sitia: ['JSH'],
  Sitka: ['SIT'],
  Sittwe: ['AKY'],
  Sivas: ['VAS'],
  Skagway: ['SGY'],
  Skardu: ['KDU'],
  Skelleftea: ['SFT'],
  'Ski Norway': ['YVS'],
  Skiathos: ['JSI'],
  Skien: ['XKK', 'XKP', 'SKE'],
  Skiros: ['SKU'],
  Skive: ['SQW', 'ZKN'],
  Skopje: ['SKP'],
  Skoppum: ['XUR'],
  Skovde: ['KVB'],
  Skrydstrup: ['SKS', 'XJE'],
  Skukuza: ['SZK'],
  Skwentna: ['SKW'],
  Sliac: ['SLD'],
  Sligo: ['SXL'],
  'Smith Falls': ['YSH'],
  Smithers: ['YYD'],
  Smithfield: ['SFZ'],
  Smyrna: ['MQY'],
  Snartemo: ['XUS'],
  Sobral: ['QBX'],
  Sochi: ['AER'],
  Socorro: ['ONM'],
  Soderhamn: ['SOO'],
  Sodertalje: ['JSO', 'XEZ'],
  Sofia: ['SOF'],
  Sogamoso: ['SOX'],
  Sogndal: ['SOG'],
  Sohag: ['HMB'],
  Sohar: ['OHS'],
  Soissons: ['XSS'],
  Sola: ['SLH'],
  Soldotna: ['SXQ'],
  Solingen: ['ZIO'],
  Solo: ['SOC'],
  Solomon: ['SOL'],
  Solothurn: ['ZKS'],
  Solovetsky: ['CSH'],
  Solvesborg: ['XYU'],
  Solwezi: ['SLI'],
  Somerset: ['SME'],
  'Son La': ['SQH'],
  Sonderborg: ['SGD', 'QSG'],
  Songpan: ['JZH'],
  Songyuan: ['YSQ'],
  Sonneberg: ['ZSG'],
  Soria: ['XJO', 'RII'],
  Sorocaba: ['SOD'],
  Sorong: ['SOQ'],
  Sorrento: ['RRO'],
  Sorriso: ['SMT'],
  'South Andros': ['TZN'],
  'South Bend': ['SBN'],
  'South Caicos': ['XSC'],
  'South Indian Lake': ['XSI'],
  'South Molle': ['SOI'],
  Southampton: ['SOU', 'WNC'],
  Sovetsky: ['OVS'],
  Soyo: ['SZA'],
  Spangdahlem: ['SPM'],
  Sparrevohn: ['SVW'],
  Sparta: ['CMY', 'SPJ', 'SAR'],
  Spearfish: ['SPF'],
  Spencer: ['SPW'],
  Spiddal: ['NNR'],
  Split: ['SPU'],
  Spokane: ['SKA', 'SFF', 'GEG'],
  Springbok: ['SBU'],
  Springdale: ['SPZ'],
  Springfield: ['SPI', 'SGH', 'SGF', 'ZSF', 'CEF'],
  Srednekolymsk: ['SEK'],
  Srinagar: ['SXR'],
  'St Andrews': ['ADX'],
  'St Anthony': ['YAY'],
  'St Anton am Arlberg': ['XOV'],
  'St Augustine': ['UST'],
  'St Austell': ['USX'],
  'St Barthelemy': ['SBH'],
  'St Brieuc': ['SBK'],
  'St Croix': ['STX', 'JCD', 'SSB'],
  'St Etienne': ['EBU'],
  'St Eustatius': ['EUX'],
  'St Gallen': ['QGL'],
  'St George': ['SGU', 'SGO'],
  'St George Island': ['STG'],
  'St Helens': ['HLS'],
  'St John': ['YSJ'],
  'St John Island': ['SJF'],
  'St Johns': ['YYT'],
  'St Joseph': ['STJ'],
  'St Kitts': ['SKB'],
  'St Leonard': ['YSL'],
  'St Louis': ['CPS', 'STL', 'SUS', 'XLS', 'ZSV'],
  'St Lucia': ['SLU'],
  'St Maarten': ['SXM'],
  'St Margrethen': ['ZKF'],
  'St Martin': ['SFG'],
  'St Marys': ['KSM', 'XIO'],
  'St Moritz': ['SMV', 'ZKH'],
  'St Nazaire': ['SNR'],
  'St Paul': ['STP'],
  'St Paul Is': ['SNP'],
  'St Petersburg': ['ZLK', 'LED', 'RVH', 'FVS'],
  'St Pierre': ['YPM', 'FSP'],
  'St Pierre Dela Reunion': ['ZSE'],
  'St Polton': ['POK'],
  'St Simons Is': ['SSI'],
  'St Theris Point': ['YST'],
  'St Thomas': ['STT', 'SPB', 'YQS'],
  'St Tropez': ['XPZ', 'LTT', 'JSZ'],
  'St Vincent': ['SVD'],
  Stafford: ['XVB'],
  Stamford: ['ZTF'],
  Stanthorpe: ['SNH'],
  'Stara Zagora': ['SZR'],
  'State College': ['SCE'],
  Stateside: ['FQA'],
  Statesville: ['SVH'],
  Staunton: ['SHD'],
  Stavanger: ['XKC', 'XOQ', 'SVG', 'ZWC'],
  Stavropol: ['STW'],
  'Steamboat Bay': ['WSB'],
  'Steamboat Springs': ['SBS'],
  Steinkjer: ['XKG', 'XKJ'],
  'Stella Maris': ['SML'],
  Stendal: ['ZSN'],
  Stephenville: ['SEP', 'YJT'],
  Sterling: ['SQI'],
  Stevenage: ['XVJ'],
  'Stevens Point': ['STE'],
  Stillwater: ['SWO'],
  Stirling: ['XWB'],
  Stjordal: ['XUU'],
  Stockholm: ['ARN', 'BMA', 'XEW', 'VST', 'NYO', 'XEV'],
  Stockport: ['XVA'],
  Stockton: ['SCK'],
  'Stoke On Trent': ['XWH'],
  Stokmarknes: ['SKN'],
  Stord: ['SRP'],
  Storekvina: ['XUV'],
  Storen: ['XUW'],
  'Storm Lake': ['SLB'],
  Stornoway: ['SYY'],
  Storuman: ['SQO'],
  Strahan: ['SRN'],
  Stralsund: ['ZSX'],
  Strasbourg: ['SXB', 'XER'],
  'Strasbourg City': ['XWG'],
  Stratford: ['XFD'],
  Strathroy: ['XTY'],
  Straubing: ['RBM'],
  Stronsay: ['SOY'],
  Stuart: ['SUA'],
  'Sturgeon Bay': ['SUE'],
  Sturgis: ['IRS'],
  Sturtevant: ['ZTV'],
  Stuttgart: ['STR', 'SGT', 'ZWS'],
  Suavanao: ['VAO'],
  'Subic Bay': ['SFS'],
  Suceava: ['SCV'],
  Sucre: ['SRE'],
  Sudbury: ['YSB', 'XDY'],
  Suffield: ['YSD'],
  'Sugar Land': ['SGR'],
  Suhl: ['ZSO'],
  Sukhothai: ['THS'],
  Sukkur: ['SKZ'],
  Sulaymaniyah: ['ISU'],
  Sumare: ['RWS'],
  Sumbawa: ['SWQ'],
  Sumbawanga: ['SUT'],
  'Summer Beaver': ['SUR'],
  Summit: ['UMM'],
  Sumter: ['SSC', 'SUM'],
  Sumy: ['UMY'],
  'Sun City': ['NTY'],
  'Sun Valley': ['SUN'],
  Sundsvall: ['XXZ', 'SDL'],
  Suntar: ['SUY'],
  Superior: ['SUW'],
  'Sur Om': ['SUH'],
  Surabaya: ['SUB'],
  Surat: ['STV'],
  'Surat Thani': ['URT'],
  Surgut: ['SGC'],
  Surigao: ['SUG'],
  Sursee: ['ZKU'],
  Suva: ['SUV'],
  Suwon: ['SWU'],
  Suzhou: ['SZV', 'YUQ'],
  Sveg: ['EVG'],
  Svendborg: ['QXV'],
  Svolvaer: ['SVJ'],
  Swakopmund: ['SWP', 'ZSZ'],
  'Swan Hill': ['SWH'],
  'Swan River': ['ZJN'],
  Swansea: ['SWS', 'WSS'],
  SWEETWATER: ['SWW'],
  'Swift Current': ['YYN'],
  Swindon: ['XWS'],
  Sydney: ['RSE', 'SYD', 'LBH', 'YQY', 'SWZ'],
  Syktyvkar: ['SCW'],
  Sylhet: ['ZYL'],
  Syracuse: ['SYR', 'ZYQ'],
  'Syros Island': ['JSY'],
  Szczecin: ['SZZ'],
  Szymany: ['SZY'],
  Taba: ['TCP'],
  Tabarka: ['TBJ'],
  Tabatinga: ['TBT'],
  'Tablas Island': ['TBH'],
  Tabora: ['TBO'],
  Tabriz: ['TBZ'],
  Tabuk: ['TUU'],
  Tacheng: ['TCG'],
  Tacloban: ['TAC'],
  Tacna: ['TCQ'],
  Tacoma: ['GRF', 'TCM', 'TIW'],
  Tacuarembo: ['TAW'],
  'Tadoule Lake': ['XTL'],
  Taganrog: ['TGK'],
  Tagbilaran: ['TAG'],
  Taichung: ['RMQ', 'TXG'],
  Taif: ['TIF'],
  Tainan: ['TNN'],
  Taipei: ['TSA', 'TPE'],
  Taitung: ['TTT'],
  Taiyuan: ['TYN'],
  Taiz: ['TAI'],
  Taizhou: ['HYN'],
  Takamatsu: ['TAK'],
  Takaroa: ['TKX'],
  Takengon: ['TXE'],
  Takoradi: ['TKD'],
  'Taku Lodge': ['TKL'],
  Talara: ['TYL'],
  'Taldy Kurgan': ['TDK'],
  Talkeetna: ['TKA'],
  Talladega: ['ASN'],
  Tallahassee: ['TLH'],
  Tallinn: ['TLL'],
  Taloyoak: ['YYH'],
  Tamale: ['TML'],
  Tamanrasset: ['TMR'],
  Tamarindo: ['TNO'],
  Tamatave: ['TMM'],
  Tambolaka: ['TMC'],
  Tambor: ['TMU'],
  Tambov: ['TBW'],
  Tamchy: ['IKU'],
  TAME: ['TME'],
  Tamky: ['VCL'],
  Tampa: ['MCF', 'PIE', 'TPA', 'KYO', 'TPF', 'SPG'],
  Tampere: ['TMP'],
  Tampico: ['TAM'],
  Tamworth: ['TMW'],
  Tanana: ['TAL'],
  Tandil: ['TDL'],
  'Tanega Shima': ['TNE'],
  Tanga: ['TGT'],
  'Tangara Da Serra': ['TGQ'],
  Tangier: ['TNG'],
  Tangshan: ['TVS'],
  'Tanjung Manis': ['TGC'],
  'Tanjung Pandan': ['TJQ'],
  'Tanjung Pinang': ['TNJ'],
  'Tanjung Redeb': ['BEJ'],
  Tanna: ['TAH'],
  Taos: ['TSM'],
  Tapachula: ['TAP'],
  Tarakan: ['TRK'],
  Taranto: ['TAR'],
  Tarapaca: ['TCD'],
  Tarapoto: ['TPP'],
  Tarauaca: ['TRQ'],
  Tarawa: ['TRW'],
  Taraz: ['DMB'],
  Taree: ['TRO'],
  'Targu Mures': ['TGY', 'TGM'],
  Tari: ['TIZ'],
  Tarija: ['TJA'],
  'Tarko Sale': ['TQL'],
  Tarragona: ['QGN'],
  Tartu: ['TAY'],
  Taschereau: ['XFO'],
  Tashkent: ['TAS'],
  Tasiilaq: ['AGM'],
  Tasiujaq: ['YTQ'],
  Tatalina: ['TLJ'],
  Tatitlek: ['TEK'],
  Taupo: ['TUO'],
  Tauramena: ['TAU'],
  Tauranga: ['TRG'],
  Taveuni: ['TVU'],
  Tawau: ['TWU'],
  Taylor: ['TYZ', 'TWE'],
  Tbessa: ['TEE'],
  Tbilisi: ['TBS'],
  Tchien: ['THC'],
  'Te Anau': ['TEU'],
  Tefe: ['TFF'],
  Tegucigalpa: ['TGU'],
  Tehachapi: ['TSP'],
  Teheran: ['IKA', 'THR'],
  Tehuacan: ['TCN'],
  'Teixeira De Freitas': ['TXF'],
  Tekirdag: ['TEQ'],
  'Tel Aviv': ['SDV', 'TLV'],
  Tela: ['TEA'],
  'Telemaco Borba': ['TEC'],
  Telida: ['TLF'],
  Telluride: ['TEX'],
  Temple: ['TPL'],
  Temuco: ['ZCO', 'PZS'],
  Tena: ['TNW'],
  Tenakee: ['TKE'],
  Tenerife: ['TFS', 'TFN'],
  Tengchong: ['TCZ'],
  'Tennant Creek': ['TCA'],
  Tepic: ['TPQ'],
  Terceira: ['TER'],
  Teresina: ['THE'],
  Termez: ['TMJ'],
  Ternate: ['TTE'],
  Terrace: ['YXT'],
  'Terrace Bay': ['YTJ'],
  'Terre Haute': ['HUF'],
  Terrell: ['TRL'],
  Teruel: ['TEJ'],
  Tete: ['TET'],
  'Tete A La Baleine': ['ZTB'],
  Teterboro: ['TEB'],
  Tetlin: ['TEH'],
  Tetuan: ['TTU'],
  Texarkana: ['TXK'],
  Tezpur: ['TEZ'],
  Tezu: ['TEI'],
  'Thaba Nchu': ['TCU'],
  Thalwil: ['ZKV'],
  Thames: ['TMZ'],
  Thangool: ['THG'],
  'Thanh Hoa': ['THD'],
  Thanjavur: ['TJV'],
  Thargomindah: ['XTG'],
  'The Dalles': ['DLS'],
  'The Hague': ['HAG', 'ZYH', 'ZXY'],
  'The Pas': ['YQD', 'XDZ'],
  Thermal: ['TRM'],
  Thermopolis: ['THP'],
  Thessaloniki: ['SKG'],
  'Thicket Portage': ['YTD'],
  'Thief River Falls': ['TVF'],
  Thimarafushi: ['TMF'],
  'Thira Island': ['JTR'],
  Thirsk: ['XTK'],
  Thiruvananthapuram: ['TRV'],
  Thisted: ['TED'],
  Thohoyandou: ['THY'],
  Thompson: ['YTH'],
  'Thorne Bay': ['KTB'],
  THUN: ['ZTK'],
  'Thunder Bay': ['YQT'],
  'Thursday Island': ['TIS'],
  Tiakur: ['JIO'],
  Tianjin: ['TSN'],
  Tianmen: ['TMV'],
  Tianshui: ['THQ'],
  Tieling: ['TTV'],
  Tierp: ['XFU'],
  Tifton: ['TMA'],
  Tijuana: ['TIJ'],
  Tikchik: ['KTH'],
  Tikehau: ['TIH'],
  Tikrit: ['XTV'],
  Tiksi: ['IKS'],
  Timaru: ['TIU'],
  Timika: ['TIM'],
  Timisoara: ['TSR'],
  Timmins: ['YTS'],
  'Tin City': ['TNC'],
  Tindouf: ['TIN'],
  Tingwon: ['TIG'],
  Tinian: ['TIQ'],
  Tiniteqilaaq: ['TQI'],
  Tioman: ['TOD'],
  Tirana: ['TIA'],
  Tiree: ['TRE'],
  Tiruchirappali: ['TRZ'],
  Tirupati: ['TIR'],
  Tisdale: ['YTT'],
  Titusville: ['TIX'],
  Tivat: ['TIV'],
  TIZIMIN: ['TZM'],
  Tlemcen: ['TLM'],
  Tobago: ['TAB'],
  Tobolsk: ['TOX'],
  Tobruk: ['TOB'],
  Tocumwal: ['TCW'],
  Tofino: ['YAZ', 'YTP'],
  Togiak: ['TOG'],
  'Tok Ak': ['TKJ'],
  Tokat: ['TJK'],
  Tokeen: ['TKI'],
  Tokunoshima: ['TKN'],
  Tokushima: ['TKS'],
  Tokyo: ['HND', 'NRT', 'OKO'],
  Toledo: ['TOW', 'TDZ', 'TOL', 'XTJ'],
  Tolu: ['TLU'],
  Toluca: ['TLC'],
  'Tom Price': ['TPR'],
  Tomanggong: ['TMG'],
  'Toms River': ['MJX'],
  Tomsk: ['TOF'],
  Tongcheng: ['TGX'],
  Tonghua: ['TNH'],
  Tongliao: ['TGO'],
  Tongren: ['TEN'],
  Tongxiang: ['TVX'],
  Tonopah: ['XSD', 'TPH'],
  Tonsberg: ['XKW'],
  Toowoomba: ['TWB', 'WTB'],
  Topeka: ['FOE', 'TOP'],
  Toronto: ['YKZ', 'XLQ', 'YYZ', 'YTZ', 'YBZ'],
  Torremolinos: ['UTL'],
  Torreon: ['TRC'],
  Torres: ['TOH'],
  Torrington: ['TOR'],
  Torsby: ['TYF'],
  Tortola: ['RAD'],
  Tortoli: ['TTB'],
  Tortuguero: ['TTQ'],
  Tottenham: ['TTK'],
  Tottori: ['TTJ'],
  Toulon: ['TLN', 'XZV'],
  Toulouse: ['TLS', 'TXQ'],
  Tours: ['TUF', 'XJT', 'XSH'],
  'Toussus Le Noble': ['TNF'],
  Townsville: ['TSV'],
  Toyama: ['TOY'],
  Toyooka: ['TJH'],
  Tozeur: ['TOE'],
  Trabzon: ['TZX'],
  Trang: ['TST'],
  Trapani: ['TPS'],
  Traralgon: ['TGN'],
  Trat: ['TDX'],
  'Traverse City': ['TVC'],
  'Treasure Cay': ['TCB'],
  Trelew: ['REL'],
  Trenton: ['TTN', 'YTR'],
  'Tres Arroyos': ['OYO'],
  'Tres Cruces': ['TCV'],
  'Tres Lagoas': ['TJL'],
  Treviso: ['TSF'],
  Trier: ['ZQF'],
  Trieste: ['TRS', 'TXB'],
  Trincomalee: ['TRR'],
  Trinidad: ['TND', 'TDD'],
  Tripoli: ['MJI', 'TIP'],
  'Trois Rivieres': ['YRQ'],
  Trollhattan: ['THN'],
  Trombetas: ['TMT'],
  Tromso: ['TOS'],
  Trona: ['TRH'],
  Trondheim: ['XOD', 'XZT', 'TRD'],
  Troutdale: ['TTD'],
  Troy: ['TOI'],
  Troyes: ['QYR'],
  Truckee: ['TKF'],
  Trujillo: ['TRU'],
  Truk: ['TKK'],
  Truro: ['XLZ'],
  'Truscott Mungalalu': ['TTX'],
  'Tsuen Wan': ['ZTW'],
  Tsushima: ['TSJ'],
  'Tuba City': ['TBC'],
  'Tubuai Island': ['TUB'],
  Tububil: ['TBG'],
  Tucson: ['DMA', 'TUS', 'AVW'],
  Tucuman: ['TUC'],
  Tucurui: ['TUR'],
  Tudela: ['EEL'],
  Tufi: ['TFI'],
  Tuguegarao: ['TUG'],
  Tuktoyaktuk: ['YUB'],
  Tulare: ['TLR'],
  Tulcan: ['TUA'],
  Tulcea: ['TCE'],
  Tulear: ['TLE'],
  'Tuli Block': ['TLD'],
  Tulita: ['ZFN'],
  Tulsa: ['RVS', 'TUL'],
  Tulum: ['TUY'],
  Tumaco: ['TCO'],
  Tumbes: ['TBP'],
  Tunica: ['UTM'],
  Tunis: ['TUN'],
  Tunxi: ['TXN'],
  Tupelo: ['TUP', 'OLV'],
  Turaif: ['TUI'],
  Turbat: ['TUK'],
  Turbo: ['TRB'],
  Turin: ['TRN', 'TPY', 'ITT'],
  Turkmanbashi: ['KRW'],
  Turkmenabad: ['CRZ'],
  Turku: ['TKU'],
  Turpan: ['TLQ'],
  Turukhansk: ['THX'],
  Tuscaloosa: ['TCL'],
  Tuskegee: ['TGE'],
  Tuticorin: ['TCR'],
  'Tuxtla Gutierrez': ['TGZ'],
  'Tuy Hoa': ['TBB'],
  Tuzla: ['TZL'],
  Tver: ['KLD'],
  'Twin Falls': ['TWF'],
  'Twin Hills': ['TWA'],
  Tyler: ['TYR'],
  Tynset: ['ZMX'],
  Tyonek: ['TYE'],
  Tyumen: ['TJM'],
  Tzaneen: ['LTA'],
  'U Tapao': ['UTP'],
  'Ua Pou': ['UAP'],
  'Ube Jp': ['UBJ'],
  Uberaba: ['UBA'],
  Uberlandia: ['UDI'],
  'Ubon Ratchathani': ['UBP'],
  Udaipur: ['UDR'],
  Uden: ['UDE'],
  Udine: ['UDN'],
  'Udon Thani': ['UTH'],
  'Ufa RU': ['UFA'],
  Uganik: ['UGI'],
  Ugashik: ['UGS'],
  'Uherske Hradiste': ['UHE'],
  Uiju: ['UJU'],
  'Ujung Pandang': ['UPG'],
  Ukhta: ['UCT'],
  Ukiah: ['UKI'],
  Ukkusissat: ['JUK'],
  Ukunda: ['UKA'],
  Ulaanbaatar: ['ULN'],
  'Ulan Ude': ['UUD'],
  Ulanhot: ['HLH'],
  Ulanqab: ['UCB'],
  Ulei: ['ULB'],
  Uliastai: ['ULZ'],
  Ulm: ['QUL'],
  Ulsan: ['USN'],
  Ulukhaktok: ['YHI'],
  Ulundi: ['ULD'],
  Ulyanovsk: ['ULV', 'ULY'],
  Umea: ['UME'],
  Umiat: ['UMT'],
  Umiujaq: ['YUD'],
  Umtata: ['UTT'],
  Umuarama: ['UMU'],
  'Una BR': ['UNA'],
  Unalakleet: ['UNK'],
  'Union Island': ['UNI'],
  'University Oxford': ['UOX'],
  Unst: ['UNT'],
  'Upernavik Kujalleq': ['UPK'],
  Upington: ['UTN'],
  Uppsala: ['QYX'],
  Uralsk: ['URA'],
  Uray: ['URJ'],
  Urdzhar: ['UZR'],
  Urgench: ['UGC'],
  Urrao: ['URR'],
  Uruapan: ['UPN'],
  Uruguaina: ['URG'],
  Urumqi: ['URC'],
  Usak: ['USQ'],
  'Useless Loop': ['USL'],
  Ushuaia: ['USH'],
  Usinsk: ['USK'],
  'Ust Kamenogorsk': ['UKK'],
  'Ust Kut': ['UKX'],
  'Ust Kuyga': ['UKG'],
  'Ust Maya': ['UMS'],
  'Ust Nera': ['USR'],
  Ustaoset: ['XUX'],
  Utarom: ['KNG'],
  Utica: ['ZUA'],
  Utrecht: ['UTC', 'ZYU'],
  Uummannaq: ['UMD'],
  'Uyo City': ['QUO'],
  Uyuni: ['UYU'],
  Uzhgorod: ['UDJ'],
  UZICE: ['UZC'],
  Uzwil: ['ZKX'],
  Vaasa: ['VAA'],
  Vadodara: ['BDQ'],
  Vadso: ['VDS'],
  'Val D Or': ['YVO'],
  Valdez: ['VDZ'],
  Valdivia: ['ZAL'],
  Valdosta: ['VAD', 'VLD'],
  Valenca: ['VAL'],
  Valence: ['VAF', 'XHK'],
  Valencia: ['VLN', 'YJV', 'VLC'],
  Valenciennes: ['XVS'],
  Valera: ['VLV'],
  Valladolid: ['VLL', 'XJN', 'XIV'],
  Valle: ['VLE'],
  Valledupar: ['VUP'],
  Vallemi: ['VMI'],
  VALLENAR: ['VLR'],
  Valparaiso: ['VPS', 'EGI', 'VPZ'],
  Valverde: ['VDE'],
  'Van Horn': ['VHN'],
  'Van TR': ['VAN'],
  Vancouver: ['YDT', 'CXH', 'YVR', 'XEA'],
  Vanimo: ['VAI'],
  Vannes: ['VNE'],
  Varadero: ['VRA'],
  Varanasi: ['VNS'],
  Vardoe: ['VAW'],
  Varginha: ['VAG'],
  Varkaus: ['VRK'],
  Varna: ['VAR'],
  'Vatulele Island': ['VTF'],
  'Vava U': ['VAV'],
  Vaxjo: ['VXO'],
  Vegarshei: ['ZYV'],
  Vejle: ['XVX'],
  'Veliky Ustyug': ['VUS'],
  Venice: ['VCE', 'XVY', 'VNC', 'XVQ'],
  Vennesla: ['XXE'],
  Ventspils: ['VNT'],
  Veracruz: ['VER'],
  Verdal: ['XXG'],
  Verkhnevilyuysk: ['VHV'],
  Vermilion: ['YVG'],
  Vernal: ['VEL'],
  Vernon: ['YVE'],
  'Vero Beach': ['VRB'],
  Verona: ['VBS', 'VRN', 'XIX'],
  Vestmannaeyjar: ['VEY'],
  Vevey: ['ZKZ'],
  Viborg: ['ZGX'],
  Vicenza: ['VIC'],
  Vichy: ['VHY'],
  Vicksburg: ['VKS'],
  Victoria: ['YWH', 'YYJ', 'VCT'],
  'Victoria Falls': ['VFA'],
  Victorville: ['VCV'],
  Vidalia: ['VDI'],
  Viedma: ['VDM'],
  Vienna: ['VDD', 'XWC', 'VIE', 'XWT', 'XWW'],
  Vientiane: ['VTE'],
  Vieques: ['VQS'],
  Vieste: ['VIF'],
  'Vieux Fort St Lucia': ['UVF'],
  Vigo: ['VGO', 'YJR'],
  Vijayawada: ['VGA'],
  'Vila Real': ['VRL'],
  Vilanculos: ['VNX'],
  Vilhelmina: ['VHM'],
  Vilhena: ['BVH'],
  'Villa Constitucion': ['VIB'],
  'Villa Gesell': ['VLG'],
  'Villa Mercedes': ['VME'],
  Villach: ['QVL'],
  Villagarzon: ['VGZ'],
  Villahermosa: ['VSA'],
  Villars: ['ZLA'],
  Villavicencio: ['VVC'],
  Vilnius: ['VNO'],
  Vilyuysk: ['VYI'],
  'Vina Del Mar': ['KNA'],
  Vinales: ['VNL'],
  Vincennes: ['OEA'],
  'Vinh City': ['VII'],
  Vinnitsa: ['VIN'],
  Vinstra: ['XKZ'],
  Vipingo: ['VPG'],
  Virac: ['VRC'],
  'Virgin Gorda': ['VIJ'],
  Visalia: ['VIS'],
  Visby: ['VBY'],
  Vishakhapatanam: ['VTZ'],
  Visp: ['ZLB'],
  Vitebsk: ['VTB'],
  Vitoria: ['VIX', 'VIT', 'VIO'],
  'Vitoria Da Conquista': ['VDC'],
  Vladikavkaz: ['OGZ'],
  Vladivostok: ['VVO'],
  Volgograd: ['VOG'],
  Vologda: ['VGD'],
  Volos: ['VOL'],
  Voronezh: ['VOZ'],
  Vryheid: ['VYD'],
  Vyborg: ['VBR'],
  Wabush: ['YWK'],
  Waco: ['ACT', 'CNW'],
  'Wadi Al Dawaser': ['WAE'],
  Waedenswil: ['ZLC'],
  'Wagga Wagga': ['WGA'],
  Wainwright: ['AIN'],
  Wajima: ['NTQ'],
  Wakayama: ['QKY'],
  'Wakefield Westgate': ['XWD'],
  Wakkanai: ['WKJ'],
  Walaha: ['WLH'],
  Walgett: ['WGE'],
  'Walla Walla': ['ALW'],
  'Wallis Island': ['WLS'],
  'Walnut Ridge': ['ARG'],
  Walterboro: ['RBW'],
  'Walvis Bay': ['WVB'],
  Wanaka: ['WKA'],
  Wanganui: ['WAG'],
  Wangaratta: ['WGT'],
  Wangerooge: ['AGE'],
  Wanning: ['WXJ'],
  Wanxian: ['WXN'],
  Wapakoneta: ['AXV'],
  Wapenamanda: ['WBM'],
  Warangal: ['WGC'],
  Warnemuende: ['ZWD'],
  Warri: ['QRW'],
  Warrington: ['XWN'],
  Warrnambool: ['WMB'],
  Warsaw: ['WMI', 'RWA', 'WAW'],
  Warwick: ['WAZ'],
  Washington: ['GBO', 'ZBP', 'BOF', 'MTN', 'ZRZ', 'DCA', 'WSG', 'ZWU', 'IAD'],
  Wasilla: ['WWA'],
  Waskaganish: ['YKQ'],
  Waterfall: ['KWF'],
  Waterford: ['WAT'],
  Waterkloof: ['WKF'],
  Waterloo: ['ALO'],
  Watertown: ['ART', 'ATY'],
  Waterville: ['WVL'],
  Watford: ['XWA'],
  'Watson Lake': ['YQH'],
  Watsonville: ['WVI'],
  'Wau City': ['WUU'],
  Waukegan: ['UGN'],
  Wausau: ['CWA', 'AUW'],
  Wawa: ['YXZ'],
  Waynesburg: ['WAY'],
  Weatherford: ['WEA'],
  'Webequie Indian Reserve': ['YWP'],
  Wedjh: ['EJH'],
  'Wee Waa': ['WEW'],
  Weerawila: ['WRZ'],
  Weihai: ['WEH'],
  Weinfelden: ['ZLD'],
  Weipa: ['WEI'],
  Welkom: ['WEL'],
  Wellingborough: ['XWE'],
  Wellington: ['WLG'],
  Wellsville: ['ELZ'],
  Welshpool: ['WHL'],
  Wemindji: ['YNC'],
  Wenatchee: ['EAT'],
  Wenchang: ['WEC'],
  Wendover: ['ENV'],
  Wengen: ['ZLE'],
  Wenling: ['WLI'],
  Wenshan: ['WNH'],
  Wenzhou: ['WNZ'],
  'West Bend': ['ETB'],
  'West Memphis': ['AWM'],
  'West Palm Beach': ['LNA', 'PBI', 'ZWP'],
  'West Point': ['KWP'],
  'West Yellowstone': ['WYS'],
  Westerland: ['GWT'],
  Westerly: ['WST'],
  Westhampton: ['FOK'],
  Westport: ['WSZ'],
  Westray: ['WRY'],
  Wettingen: ['ZLF'],
  Wetzikon: ['ZKW'],
  Wewak: ['WWK'],
  Wexford: ['WEX'],
  Weymont: ['XFQ'],
  Whakatane: ['WHK'],
  'Whale Cove': ['YXN'],
  'Whale Pass': ['WWP'],
  Whangarei: ['WRE'],
  Whati: ['YLE'],
  WHEATLAND: ['EAN'],
  Whistler: ['YWS'],
  'White Plains': ['HPN'],
  'White River': ['YWR'],
  Whitecourt: ['YZU'],
  Whitefield: ['HIE'],
  Whitehorse: ['YXY'],
  Whiteriver: ['WTR'],
  Whyalla: ['WYA'],
  Wichita: ['BEC', 'CEA', 'IAB', 'ICT'],
  'Wichita Falls': ['KIP', 'SPS'],
  Wick: ['WIC'],
  Wiesbaden: ['WIE', 'UWE'],
  Wigan: ['XWI'],
  'Wil CH': ['ZLH'],
  Wilhelmshaven: ['WVN'],
  'Williams Lake': ['YWL'],
  Williamsport: ['IPT'],
  Williston: ['ISN'],
  Willow: ['WOW'],
  WILLOWS: ['WLW'],
  Wilmington: ['ILM', 'ILG', 'ZWI'],
  Wiluna: ['WUN'],
  Winchester: ['WGO'],
  Windhoek: ['ERS', 'WDH', 'ZWH'],
  Windom: ['MWM'],
  Windorah: ['WNR'],
  Windsor: ['YQG', 'XEC'],
  Winfield: ['WLD'],
  Winnemucca: ['WMC'],
  Winnipeg: ['YWG', 'XEF'],
  Winslow: ['INW'],
  'Winston-Salem': ['INT'],
  'Winter Haven': ['GIF'],
  Winterthur: ['ZLI'],
  Wipim: ['WPM'],
  Wiscasset: ['ISS'],
  'Wisconsin Rapids': ['ISW'],
  Wiseman: ['WSM'],
  Wismar: ['ZWM'],
  Wittenberg: ['ZWT', 'ZWN'],
  Woking: ['XWO'],
  'Wolf Point': ['OLF'],
  Wolfsburg: ['ZQU'],
  Wollongong: ['WOL'],
  Wolverhampton: ['XVW'],
  Wonju: ['WJU'],
  Woodstock: ['XIP'],
  Woodward: ['WWR'],
  Wooster: ['BJJ'],
  Worcester: ['UWC', 'ORH'],
  Worland: ['WRL'],
  Worms: ['ZQV'],
  Worthington: ['OTG'],
  Wrangell: ['WRG'],
  Wrightstown: ['WRI'],
  Wroclaw: ['WRO'],
  Wudalianchi: ['DTU'],
  Wuhai: ['WUA'],
  Wuhan: ['WUH', 'WHZ'],
  'Wunnumin Lake': ['WNN'],
  Wuppertal: ['UWP'],
  Wurzburg: ['QWU'],
  Wuxi: ['WUX'],
  Wuyishan: ['WUS'],
  Wuzhou: ['WUZ'],
  Wyndham: ['WYN'],
  Wyoming: ['XWY'],
  'Xi An': ['XIY', 'SIA'],
  Xiahe: ['GXH'],
  Xiamen: ['XMN'],
  Xiangfan: ['XFN'],
  Xiangyang: ['KLJ'],
  Xianning: ['IUO'],
  Xiantao: ['XTQ'],
  Xiaogan: ['XJW'],
  'Xieng Khouang': ['XKH'],
  Xilinhot: ['XIL'],
  Xingtai: ['XNT', 'HXN'],
  Xining: ['XNN'],
  Xinyang: ['XYW'],
  'Xinyuan City': ['NLT'],
  Xinzhou: ['WUT'],
  Xuzhou: ['XUZ', 'XUK'],
  Yakima: ['YKM', 'FCT'],
  Yakutat: ['YAK'],
  Yakutsk: ['OLZ', 'YKS'],
  'Yam Island': ['XMY'],
  Yamagata: ['GAJ'],
  Yamoussouro: ['ASK'],
  Yanbo: ['YNB'],
  Yancheng: ['YNZ'],
  Yandicoogina: ['YNN'],
  Yangon: ['RGN'],
  Yangyang: ['YNY'],
  Yangzhou: ['YTY'],
  Yanji: ['YNJ'],
  Yankton: ['YKN'],
  Yantai: ['YNT'],
  Yaounde: ['NSI', 'YAO'],
  'Yap Fm': ['YAP'],
  Yarmouth: ['YQI'],
  Yaroslavl: ['IAR'],
  Yazd: ['AZD'],
  Yellowknife: ['YZF'],
  Yeosu: ['RSU'],
  Yeovilton: ['YEO'],
  Yerevan: ['EVN'],
  'Yes Bay': ['WYB'],
  Yeysk: ['EIK'],
  Yibin: ['YBP'],
  Yichang: ['YIH', 'WZE'],
  Yichun: ['YIC'],
  'Yichun Shi': ['LDS'],
  Yinchuan: ['INC'],
  Yingkou: ['YKH'],
  Yining: ['YIN'],
  Yiwu: ['YIW', 'YIU'],
  Ylivieska: ['YLI'],
  Yogyakarta: ['JOG'],
  Yokohama: ['YOK'],
  Yonago: ['YGJ'],
  Yonaguni: ['OGN'],
  York: ['THV', 'QQY'],
  'York Landing Indian Reserve': ['ZAC'],
  Yorkton: ['YQV'],
  'YOSHKAR OLA': ['JOK'],
  Yotvata: ['YOT'],
  Young: ['NGA'],
  Youngstown: ['YNG'],
  Yueyahu: ['YEH'],
  Yueyanng: ['YUG'],
  Yulin: ['UYN'],
  Yuma: ['LGF', 'YUM'],
  'Yun Cheng': ['YCU'],
  Yushu: ['YUS'],
  Yuyao: ['YYP'],
  'Yuzhno Kurilsk': ['DEE'],
  'Yuzhno Sakhalinsk': ['UUS'],
  Yverdon: ['ZLJ'],
  Zacatecas: ['ZCL'],
  Zadar: ['ZAD'],
  Zagora: ['OZG'],
  Zagreb: ['ZAG', 'ZGC'],
  Zakinthos: ['ZTH'],
  Zalengei: ['ZLX'],
  Zamboanga: ['ZAM'],
  Zamora: ['ZMM', 'ZOB'],
  Zanesville: ['ZZV'],
  Zanjan: ['JWN'],
  Zanzibar: ['ZNZ'],
  Zaporozhe: ['OZH'],
  Zaqatala: ['ZTU'],
  Zarafsan: ['AFS'],
  Zaragoza: ['ZAZ', 'RZG', 'XZZ'],
  Zaysan: ['SZI'],
  Zemio: ['IMO'],
  Zermatt: ['QZB'],
  Zero: ['ZER'],
  Zhalantun: ['NZL'],
  Zhangjiakou: ['ZQZ'],
  Zhangye: ['YZY'],
  Zhanjiang: ['ZHA'],
  Zhaotong: ['ZAT'],
  Zhengzhou: ['CGO', 'TTZ'],
  Zhenjiang: ['ZUJ'],
  Zhezkazgan: ['DZN'],
  'Zhi Jiang': ['HJJ'],
  Zhigansk: ['ZIX'],
  Zhijiang: ['GJN'],
  Zhongwei: ['ZHY'],
  Zhoushan: ['HSN'],
  Zhuhai: ['ZUH'],
  Zhukovsky: ['ZIA'],
  Zhuzhou: ['DHU'],
  Zielona: ['IEG'],
  Zigong: ['ZKL'],
  Ziguinchor: ['ZIG'],
  Zihuatanejo: ['ZIH'],
  Zilina: ['ILZ'],
  Zintan: ['ZIS'],
  Zittau: ['ZIT'],
  Zlin: ['GTW'],
  Zofingen: ['ZLL'],
  Zonguldak: ['ONQ'],
  Zouerate: ['OUZ'],
  'Zug Ch': ['ZLM'],
  Zurich: ['ZRH', 'ZLP', 'ZLQ'],
  Zyryanka: ['ZKP'],
};

@Pipe({
  name: 'flightCities',
})
export class FlightCitiesPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    let found_city = '';

    const keys = Object.keys(CITIES);

    for (let i = 0; i < keys.length; i++) {
      if (CITIES[keys[i]].indexOf(value) >= 0) {
        found_city = keys[i];
      }
    }

    return found_city + ' (' + value + ')';
  }

  /**
   * @description - get a city based on the airport code - returns null if not found
   * @param - airportCode - the airport code e.g. JFK (John F Kennedy Airport - should return New York)
   */
  getCityFromAirport(airportCode: string): any {
    const keys = Object.keys(CITIES);

    for (let i = 0; i < keys.length; i++) {
      if (CITIES[keys[i]].includes(airportCode.toUpperCase())) {
        return keys[i];
      }
    }

    return null;
  }
}
