<form [formGroup]="mform">
  <input
    formControlName="{{formControlName}}"
    autocomplete="off"
    class="{{class}}"
    i18n-placeholder
    placeholder="Select a Date"
    name="dp"
    [minDate]='minDate'
    [startDate]='startDate'
    [(appValidateDate)]='model'
    [(ngModel)]='model'
    [displayMonths]="deviceDetector.displayMonths"
    [navigation]="'arrows'"
    container="body"
    ngbDatepicker
    #dp
    #d="ngbDatepicker"
    (click)="d.toggle()"
    tabindex="0"
    id="{{componentId}}"
    (selectDate)="emit($event)"
    [disabled]="disabled"
    [maxDate]="maxDate"
    [required]="required"
    (dateSelect)="dateSelect($event)"
  />
</form>